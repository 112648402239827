import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateModalComponent {
  @Input() public title = '';
  @Input() public text = '';
  @Input() public buttonLabel = '';
  @Input() public titleWidth: number = null;

  constructor(private modalController: ModalController) {}

  public close(): void {
    this.modalController.dismiss();
  }
}
