import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { removeItem, updateItem } from '@ngxs/store/operators';
import { BookmarksModel } from '@oogShared/models/bookmarks/bookmarks.model';
import {
  RemoveBookmark,
  ResetBookmarks,
  SetBookmarks,
  UpdateBookmark,
} from '@store/resolution-store/bookmarks/bookmarks.action';

@Injectable()
@State<BookmarksModel[]>({
  name: 'bookmarks',
  defaults: [],
})
export class BookmarksState {
  @Selector()
  public static getBookmarks(state: BookmarksModel[]): BookmarksModel[] {
    return state;
  }

  @Action(SetBookmarks)
  public setBookmarks({ setState }: StateContext<BookmarksModel[]>, { images }: SetBookmarks): void {
    const bookmarks = images.map((i) => i.bookmarks, []);
    setState(bookmarks.flat());
  }

  @Action(ResetBookmarks)
  public resetBookmarks({ setState }: StateContext<BookmarksModel[]>): void {
    setState([]);
  }

  @Action(UpdateBookmark)
  public updateBookmark({ setState }: StateContext<BookmarksModel[]>, { bookmark }: UpdateBookmark): void {
    setState(updateItem((i) => i.id === bookmark.id, bookmark));
  }

  @Action(RemoveBookmark)
  public deleteBookmark({ setState }: StateContext<BookmarksModel[]>, { id }: RemoveBookmark): void {
    setState(removeItem((i) => i.id === id));
  }
}
