import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { DocumentProhibitionStatus } from '@npaShared/enums/document-prohibition-status.enum';
import {
  DocumentInfoResponseModel,
  DocumentVersionInfoModel,
} from '@npaShared/models/document/document-info.response.model';
import { scrollDown, scrollUp } from '@shared/functions/scrolling.function';
import { getPointByIdWithAdditionalInfo } from '@npaShared/helpers/route/point-helper';
import { getActualDocumentVersion } from '@npaShared/helpers/document-version.helper';
import { Route, RoutePoint } from '@npaShared/models/route/route.models';
import { DocumentPackagesStoreService } from '@npaCore/store/document-packages-store.service';
import { SelectedRoutePointService } from './selected-route-point.service';

/** Сервис используется для организации работы с ПДФ-просмотрщиком
 *
 * Сервис выносит в себя информацию о списке документов и текущей активной странице,
 * чтобы было удобно синхронизировать работу из нескольких компонентов
 *
 * Сервис не является синглтоном - он объявляется в провайдерах компонента и его экземпляр доступен потомкам
 */
@Injectable()
export class DocumentPackageViewerService {
  private activeDocument$ = new BehaviorSubject<DocumentInfoResponseModel>(null);
  private activeVersion$: BehaviorSubject<DocumentVersionInfoModel | null> = new BehaviorSubject(null);
  private documents$ = new BehaviorSubject<DocumentInfoResponseModel[]>([]);

  /** ПДФ готов к просмотру и взаимодействию только после того, как будет получен с сервера */
  private isReady$ = new BehaviorSubject<boolean>(false);
  private viewer: Element;

  constructor(
    private documentPackagesStoreService: DocumentPackagesStoreService,
    private selectedRoutePointService: SelectedRoutePointService,
  ) {}

  public setViewer(viewer: Element): void {
    this.viewer = viewer;
  }

  public getDocuments$(): Observable<DocumentInfoResponseModel[]> {
    return this.documents$.asObservable();
  }

  public getActiveDocument$(): Observable<DocumentInfoResponseModel | null> {
    return this.activeDocument$.pipe(distinctUntilChanged((prev, current) => prev?.id === current?.id));
  }

  public getActiveDocument(): DocumentInfoResponseModel | null {
    return this.activeDocument$.value;
  }

  public setActiveDocument(document: DocumentInfoResponseModel): void {
    this.activeDocument$.next(document);
    this.setActiveDocumentVersion(document);
  }

  public updateActiveDocumentVersionByActivePoint(): void {
    this.setActiveDocumentVersion(this.activeDocument$.value);
  }

  public getActiveDocumentVersion$(): BehaviorSubject<DocumentVersionInfoModel | null> {
    return this.activeVersion$;
  }

  public getActiveDocumentVersion(): DocumentVersionInfoModel | null {
    return this.activeVersion$.value;
  }

  public getActiveVersionFromDocument(document: DocumentInfoResponseModel): DocumentVersionInfoModel | undefined {
    const route = this.documentPackagesStoreService.getSelectedDocumentPackageSnapshot()?.route;
    const activePoint = this.selectedRoutePointService.getSelectedPoint();

    if (!document) {
      return;
    }

    if (!route || !activePoint) {
      return;
    }

    return this.getActualVersion(route, activePoint, document.versions);
  }

  public getActiveVersionFromDocument$(
    document: DocumentInfoResponseModel,
  ): Observable<DocumentVersionInfoModel | undefined> {
    const dp$ = this.documentPackagesStoreService.getSelectedDocumentPackage();
    const activePoint$ = this.selectedRoutePointService.getSelectedPoint$();

    return combineLatest([dp$, activePoint$]).pipe(
      map(([dp, activePoint]) => {
        const route = dp?.route;

        if (!route || !activePoint) {
          return;
        }

        return this.getActualVersion(route, activePoint, document.versions);
      }),
    );
  }

  public scrollDocDown(): void {
    scrollDown(this.viewer);
  }

  public scrollDocUp(): void {
    scrollUp(this.viewer);
  }

  public getIsReadyState$(): Observable<boolean> {
    return this.isReady$.asObservable();
  }

  public setIsReadyState(isReady: boolean): void {
    this.isReady$.next(isReady);
  }

  /** Установить список доступных для просмотра пользователю документов */
  public setDocumentsList(documents: DocumentInfoResponseModel[]): void {
    this.documents$.next(documents);
  }

  /** Получение предшествующего текущему документа, который доступен пользователю */
  public getPrevAccessibleDocument$(): Observable<DocumentInfoResponseModel | undefined> {
    return combineLatest([this.getActiveDocument$(), this.getDocuments$()]).pipe(
      map(([doc, table]) => this.getPrevAccessibleDoc(doc, table)),
    );
  }

  /** Получение следующего за текущим документа, который доступен пользователю */
  public getNextAccessibleDocument$(): Observable<DocumentInfoResponseModel | undefined> {
    return combineLatest([this.getActiveDocument$(), this.getDocuments$()]).pipe(
      map(([doc, table]) => this.getNextAccessibleDoc(doc, table)),
    );
  }

  /** Сбросит состояние сервиса до изначального */
  public toInitialState(): void {
    this.setDocumentsList([]);
    this.setIsReadyState(false);
  }

  private getPrevAccessibleDoc(
    currentDoc: DocumentInfoResponseModel,
    documents: DocumentInfoResponseModel[],
  ): DocumentInfoResponseModel | undefined {
    if (!currentDoc) {
      return;
    }

    if (!documents?.length) {
      return;
    }

    const currentDocIdx = documents.findIndex((doc) => doc.id === currentDoc.id);

    /** если документ первый в списке, то надо открыть первый доступный документ с конца списка */
    if (currentDocIdx === 0) {
      const lastDoc = documents[documents.length - 1];
      if (lastDoc?.prohibitionStatus === DocumentProhibitionStatus.CLOSED) {
        return this.getPrevAccessibleDoc(lastDoc, documents);
      }
      return lastDoc;
    }

    const prevDoc = documents[currentDocIdx - 1];
    if (prevDoc?.prohibitionStatus === DocumentProhibitionStatus.CLOSED) {
      return this.getPrevAccessibleDoc(prevDoc, documents);
    }

    return prevDoc;
  }

  private getNextAccessibleDoc(
    currentDoc: DocumentInfoResponseModel,
    documents: DocumentInfoResponseModel[],
  ): DocumentInfoResponseModel | undefined {
    if (!currentDoc) {
      return;
    }

    if (!documents?.length) {
      return;
    }

    const currentDocIdx = documents.findIndex((doc) => doc.id === currentDoc.id);

    /** если документ последний в списке, то надо открыть первый доступный из списка */
    if (currentDocIdx === documents.length - 1) {
      const firstDoc = documents[0];
      if (firstDoc.prohibitionStatus === DocumentProhibitionStatus.CLOSED) {
        return this.getNextAccessibleDoc(firstDoc, documents);
      }
      return firstDoc;
    }

    const nextDoc = documents[currentDocIdx + 1];
    if (nextDoc.prohibitionStatus === DocumentProhibitionStatus.CLOSED) {
      return this.getNextAccessibleDoc(nextDoc, documents);
    }

    return nextDoc;
  }

  private setActiveDocumentVersion(document: DocumentInfoResponseModel): void {
    this.activeVersion$.next(this.getActiveVersionFromDocument(document));
  }

  private getActualVersion(
    route: Route,
    activePoint: RoutePoint,
    versions: DocumentVersionInfoModel[],
  ): DocumentVersionInfoModel {
    const activePointWithAdditionalInfo = getPointByIdWithAdditionalInfo(route, activePoint.id);
    const actualVersion = getActualDocumentVersion(versions, activePointWithAdditionalInfo.phaseId);

    return actualVersion;
  }
}
