import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetStatusAboutUpdateNotification } from '@store/update-notification/update-notification.action';

@Injectable()
@State<boolean>({
  name: 'statusUpdateNotification',
  defaults: false,
})
export class UpdateNotificationState {
  @Selector()
  public static getStatus(state: boolean): boolean {
    return state;
  }

  @Action(SetStatusAboutUpdateNotification)
  public setStatusCurrentUserAssistant(
    { setState }: StateContext<boolean>,
    { skipUpdate }: SetStatusAboutUpdateNotification,
  ): void {
    setState(skipUpdate);
  }
}
