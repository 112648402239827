import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { SettingsCertificateService } from '@shared/services/certificates/settings-certificate.service';
import { SetSettingsCertificates } from '@store/settings/settings.action';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { RelevantWorkersService } from '@oogShared/services/relevant-workers.service';

@Injectable({ providedIn: 'root' })
export class BaseAuthGuardService {
  constructor(
    protected userApi: UserInfoApiService,
    // protected userIsproStore: CurrentUserIsproStoreService,
    // protected authUserIspro: AuthUserIsproStoreService,
    protected store: Store,
    protected settingsCertificateService: SettingsCertificateService,
    private userAissdStore: CurrentUserAissdStoreService,
    private relevantWorkersService: RelevantWorkersService,
  ) {}

  // TODO: NPA-16522 отключение запросов ИСПРО
  public getUsersFromStoreOrApi(
    aisSd$: Observable<UserInfoResponseModel | null>,
    // isPro$: Observable<CurrentUserModel | null>,
    // ): Observable<[UserInfoResponseModel, CurrentUserModel]> {
  ): Observable<UserInfoResponseModel> {
    return aisSd$.pipe(
      tap((npaUser) => {
        this.userAissdStore.setCurrentUser(npaUser);
        // this.userIsproStore.setCurrentUser(oogUser?.employee);

        const settingsCertificates = this.settingsCertificateService.allSettings;
        this.store.dispatch([
          //   new SetSettings(oogUser?.settings, oogUser?.hasAcceleratedReview),
          new SetSettingsCertificates(settingsCertificates),
          //   new SetRightsForRedirectionExpeditedReview(oogUser?.acceleratedReviewEmployees),
          //   new SetRightAcceleratedReview(oogUser?.hasAcceleratedReview),
          //   new SetStatusCurrentUserAssistant(oogUser?.hasAssistant),
          //   new SetHasDssCurrentUser(oogUser?.hasDss),
        ]);

        // if (!this.authUserIspro.getCurrentUserSnapshot()) {
        //   this.authUserIspro.setCurrentUser(oogUser?.employee);
        // }

        // this.replacedUser();
      }),
    );
  }

  public replacedUser(): void {
    this.userApi
      .replacedUser()
      .pipe(
        first(),
        filter((data) => !!data),
      )
      .subscribe((data) => this.relevantWorkersService.setRelevantWorkers(data));
  }
}
