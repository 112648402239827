import { Component, Input, ViewChild } from '@angular/core';

import { IonContent } from '@ionic/angular';

import { FilePoolModel } from '../../models/resolutions/file-pool.model';

@Component({
  selector: 'app-resolution-my-organization',
  templateUrl: './resolution-my-organization.component.html',
  styleUrls: ['./resolution-my-organization.component.scss'],
})
export class ResolutionMyOrganizationComponent {
  @Input() public files: FilePoolModel[] = [];
  @ViewChild(IonContent) public content: IonContent;

  /** Состояние списка файлов скрыто/раскрыто */
  public opened = true;
}
