import { VertexState } from '@npaShared/enums/vertex-state.enum';
import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { ToArrayNumericEnum } from '@npaShared/helpers/enums.helper';
import { RoutePoint } from '@npaShared/models/route/route.models';
import { RoutePhaseTypes } from '../../../document-agreement/enums/route-phase-types.enum';
import { RootActsLogic } from './root-acts-logic';

/** Логика работы с несколькими активными точками для категории "Проекты актов" */
export class StatisticDraftActsLogic extends RootActsLogic {
  private availableActiveTaskStatuses = [...EqualActiveResolutions];
  private availableActiveRoles = ToArrayNumericEnum(EmployeeRouteTypes);

  public getActivePointsList(): RoutePoint[] {
    return this.getUserPoints()
      .filter((point) =>
        point.taskResolution === VertexState.waitingForApprovement
          ? point.phaseTypeId === RoutePhaseTypes.oauPmInitiation
          : this.availableActiveTaskStatuses.includes(point.taskResolution),
      )
      .filter((point) =>
        point.taskResolution === VertexState.waitingForApprovement
          ? point.pointRoleId === EmployeeRouteTypes.leader
          : this.availableActiveRoles.includes(point.pointRoleId),
      );
  }
}
