import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseFiles } from '@oogShared/base-classes/base-files';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';

/** Компонент, отвечающий за отображение:
 * 1) Плашки типа созданного поручения в рамках вопроса.
 * 2) Признака подготовки ответа.
 * 3) Признака приложенных файлов. */
@Component({
  selector: 'app-resolution-questions-files',
  templateUrl: 'resolution-questions-files.component.html',
  styleUrls: ['resolution-questions-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResolutionQuestionsFilesComponent extends BaseFiles {
  @Input() public cardInfoMissions: MissionModel;

  public collapsedFileList = true;
  public resolutionTypeEnum = ResolutionTypeEnum;

  public showFileAndHideList(filePool: FilePoolModel): void {
    this.collapsedFileList = !this.collapsedFileList;
    this.showFile(filePool);
  }

  public toggleResolutionQuestionsFileListDisplay(): void {
    if (this.files?.length) {
      this.collapsedFileList = !this.collapsedFileList;
    }
  }

  public defineStyleOfCardInfoMissionsType(typeId: number): boolean {
    if (!this.cardInfoMissions?.type?.name && !this.cardInfoMissions?.type?.id) {
      return;
    }

    return this.cardInfoMissions?.type?.id === typeId;
  }
}
