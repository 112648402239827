import { ActionUrgencyModel } from '../models/urgency/action-urgency.model';

export const actionUrgency: ActionUrgencyModel[] = [
  {
    value: 'STOP',
    title: 'Снять с контроля',
  },
  {
    value: 'PROLONG',
    title: 'Продлить срок с сохранением контроля',
  },
  {
    value: 'PROLONG_NO_CONTROL',
    title: 'Продлить срок исполнения со снятием с контроля',
  },
  {
    value: 'NO_PROLONG',
    title: 'Срок не продлевать, оставить на контроле',
  },
  {
    value: 'NO_PROLONG_WITH_REPORT',
    title: 'Срок не продлевать, оставить на контроле, установить срок доклада',
  },
  {
    value: 'ADDITION',
    title: 'Дополнение к блоку',
  },
];
