import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { BaseControl } from '@components/controls/control-base';
import { RadioBorderEnum } from '@oogShared/enums/radio-border.enum';
import { RadioControlEnum } from '@oogShared/enums/radio-control.enum';
import { RadioFlexEnum } from '@oogShared/enums/radio-flex.enum';

@Component({
  selector: 'app-radio-control',
  templateUrl: './radio-control.component.html',
  styleUrls: ['./radio-control.component.scss'],
})
/** Компонент с кастомным радио баттоном */
export class RadioControlComponent extends BaseControl implements AfterViewInit {
  @Input() public name = '';
  @Input() public value: unknown = '';
  @Input() public label = '';
  @Input() public radioId = '';
  @Input() public needBackground = true;

  @HostBinding('attr.theme')
  @Input()
  public theme: RadioControlEnum;

  @HostBinding('attr.border')
  @Input()
  public border: RadioBorderEnum;

  @HostBinding('attr.flex')
  @Input()
  public flex: RadioFlexEnum;

  public uniqueKey: number = Math.random();

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
