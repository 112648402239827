import { Injectable } from '@angular/core';

import { MainMenuTypeEnum } from '@enums/main-screen/main-menu-type.enum';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetDocumentsCategory, SetDocumentsCategory } from './documents-category.action';

@Injectable()
@State<MainMenuTypeEnum>({
  name: 'documentsCategory',
  defaults: MainMenuTypeEnum.officialCorrespondence,
})
export class DocumentsCategoryState {
  @Selector()
  public static getMenuCategory(state: MainMenuTypeEnum): MainMenuTypeEnum {
    return state;
  }

  @Action(SetDocumentsCategory)
  public setMenuCategory({ setState }: StateContext<MainMenuTypeEnum>, { category }: SetDocumentsCategory): void {
    setState(category);
  }

  @Action(ResetDocumentsCategory)
  public resetDocumentsCategory({ setState }: StateContext<MainMenuTypeEnum>): void {
    setState(MainMenuTypeEnum.officialCorrespondence);
  }
}
