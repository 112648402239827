import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonLinkComponent } from '@components/controls/buttons/button-link/button-link.component';

import { CheckboxComponent } from '@components/controls/checkbox/checkbox.component';
import { DateCalendarInputComponent } from '@components/controls/date-calendar-input/date-calendar-input.component';
import { SearchInputComponent } from '@components/controls/search-input/search-input.component';
import { SelectCertificateComponent } from '@components/controls/select-certificate/select-certificate.component';
import { SelectComponent } from '@components/controls/select/select.component';
import { ToggleDaysComponent } from '@components/controls/toggle-days/toggle-days.component';
import { ToggleItemComponent } from '@components/controls/toggle-item/toggle-item.component';
import { ToggleTimeComponent } from '@components/controls/toggle-time/toggle-time.component';
import { IonicModule } from '@ionic/angular';

import { Components } from './components';
import { Directives } from './directives';
import { HttpInterceptorLoaderService } from './interceptors/loader/http-interceptor-loader.service';
import { Pipes } from './pipes';

export const Controls = [
  SearchInputComponent,
  CheckboxComponent,
  SelectComponent,
  ToggleItemComponent,
  ToggleTimeComponent,
  ToggleDaysComponent,
  SelectCertificateComponent,
  DateCalendarInputComponent,
];

export const Buttons = [ButtonLinkComponent];

@NgModule({
  imports: [IonicModule.forRoot(), FormsModule, ReactiveFormsModule, CommonModule],
  exports: [...Components, ...Directives, ...Pipes, ...Controls, ...Buttons],
  declarations: [...Components, ...Directives, ...Pipes, ...Controls, ...Buttons],
  providers: [
    ...Pipes,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorLoaderService,
      multi: true,
    },
  ],
})
export class SharedApplicationModule {}
