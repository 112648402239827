import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-delete-folder',
  templateUrl: './delete-folder.component.html',
  styleUrls: ['./delete-folder.component.scss'],
})
export class DeleteFolderComponent {
  @Input() public hasDocuments = true;

  constructor(private modalCtrl: ModalController) {}

  public confirm(): void {
    this.modalCtrl.dismiss({
      confirm: true,
    });
  }

  public cancel(): void {
    this.modalCtrl.dismiss({
      confirm: false,
    });
  }
}
