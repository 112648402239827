import { Injectable } from '@angular/core';
import { ProjectEnum } from '@enums/project.enum';

import { CertificateItemModel } from '@models/esigns/certificate-item.model';
import { CertificatesSettingsModel } from '@models/settings/certificates-settings.model';
import { logToSentry } from '@shared/functions/set-users-for-sentry.function';
import { NpaSettingsCertificates } from './npa-settings-certificate';
import { OogSettingsCertificates } from './oog-settings-certificate';

@Injectable({
  providedIn: 'root',
})
export class SettingsCertificateService {
  public get allSettings(): CertificatesSettingsModel {
    const settings = {
      oog: this.getSettings(ProjectEnum.oog).getAllSettings(),
      npa: this.getSettings(ProjectEnum.npa).getAllSettings(),
    } as CertificatesSettingsModel;

    logToSentry('получение настроек сертификата из стора', { settings });

    return settings;
  }

  public setDefaultCertificate(type: ProjectEnum, value: CertificateItemModel): void {
    this.getSettings(type).defaultCertificate = value;
  }

  public getDefaultCertificate(type: ProjectEnum): CertificateItemModel | null {
    try {
      return this.getSettings(type).defaultCertificate;
    } catch (er) {
      return null;
    }
  }

  public setSigningResolutions(type: ProjectEnum, value: boolean): void {
    this.getSettings(type).signingResolutions = value;
  }

  public getSigningResolutions(type: ProjectEnum): boolean {
    return this.getSettings(type).signingResolutions;
  }

  public setSingingWithoutConfirmation(type: ProjectEnum, value: boolean): void {
    this.getSettings(type).singingWithoutConfirmation = value;
  }

  public getSingingWithoutConfirmation(type: ProjectEnum): boolean {
    return this.getSettings(type).singingWithoutConfirmation;
  }

  public setCertificateAgreed(type: ProjectEnum, value: boolean): void {
    this.getSettings(type).certificateAgreed = value;
  }

  public getCertificateAgreed(type: ProjectEnum): boolean {
    return this.getSettings(type).certificateAgreed;
  }

  public getSettings(system: ProjectEnum): OogSettingsCertificates | NpaSettingsCertificates {
    switch (system) {
      case ProjectEnum.oog:
        return new OogSettingsCertificates();
      case ProjectEnum.npa:
        return new NpaSettingsCertificates();
    }
  }
}
