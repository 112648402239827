import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-scroll-arrows',
  templateUrl: './scroll-arrows.component.html',
  styleUrls: ['./scroll-arrows.component.scss'],
})
export class ScrollArrowsComponent {
  @Output() public up = new EventEmitter<void>();
  @Output() public down = new EventEmitter<void>();
}
