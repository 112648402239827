import { Component } from '@angular/core';

import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-confirm-approval',
  templateUrl: './confirm-approval.component.html',
  styleUrls: ['./confirm-approval.component.scss'],
})
export class ConfirmApprovalComponent extends DialogBase {
}
