import { Injectable } from '@angular/core';
import { filter, first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { DateHelperService } from '@shared/services/date-helper.service';
import { DictionariesAllUrgencyState } from '@store/dictionaries/dictionaries-all-urgency/dictionaries-all-urgency.state';
import { ResetForm } from '@store/forms/forms.action';
import { FormsState } from '@store/forms/forms.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetDossier } from '@store/resolution-store/dossier/dossier.action';
import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { modalIdCreateResolution } from '@const/modals-id.const';
import { DraftsInteropService } from '@oogShared/rest/drafts-interop.service';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { SetStatusCurrentUserAssistant } from '@store/status-current-user-assistant/status-current-user-assistant.action';
import { ResolutionDraftStorageService } from '@oogShared/rest/resolution-draft-storage.service';
import { performerType } from '../../consts/performer-type.const';
import { reviewerType } from '../../consts/reviewer-type.const';
import { FormHeaderEnum } from '../../enums/resolution-form/form-header.enum';
import { returnAuthUserSession } from '../../functions/return-user-session.function';
import { CreateResolutionModel } from '../../models/create-resolution/create-resolution.model';
import { FormResolutionModel } from '../../models/form-pages/form-resolution.model';
import { FormHeaderModel } from '../../models/forms-input/form-header.model';
import { CommissionFormModel } from '../../models/forms/commission-form.model';
import { ResolutionEditModel } from '../../models/forms/forms.model';
import { PerformerFormModel } from '../../models/forms/performer-form.model';
import { MissionModel } from '../../models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '../../models/resolutions/resolution/resolution-addressee.model';
import { ResolutionInteropService } from '../../rest/resolution-interop.service';
import { DocumentNavigationService } from '../document-navigation.service';
import { MapperBaseService } from './mapper-base.service';

@Injectable({
  providedIn: 'root',
})
export class FormCreateResolutionService extends MapperBaseService {
  public dataForm!: FormResolutionModel;

  constructor(
    store: Store,
    resolutionInterop: ResolutionInteropService,
    modalCtrl: ModalController,
    private dateHelper: DateHelperService,
    private currentUser: CurrentUserIsproStoreService,
    private authUser: AuthUserIsproStoreService,
    private userInfoService: UserInfoApiService,
    draftsInteropService: DraftsInteropService,
    documentNavigationService: DocumentNavigationService,
    resolutionDraftStorageService: ResolutionDraftStorageService,
  ) {
    super(
      resolutionInterop,
      store,
      modalCtrl,
      draftsInteropService,
      documentNavigationService,
      resolutionDraftStorageService,
    );
  }

  /** Смапить данные в модель шапки формы */
  public mapFormHeader(data: FormResolutionModel): FormHeaderModel {
    return {
      title: FormHeaderEnum.create,
      numberCardDocument: data.numberResolution,
      date: data.resolutionDate,
      isShowSwitch: true,
    };
  }

  /** Смапить данные в модель формы */
  public mapDataToForm(): void {
    const date = this.store.selectSnapshot(ActiveCardState.dateResolution);
    const number = this.store.selectSnapshot(ActiveCardState.numberResolution);
    const user = this.currentUser.getCurrentUserSnapshot();

    this.dataForm = {
      author: user,
      onBehalfOf: user,
      resolutionDate: date,
      numberResolution: number,
    };
  }

  public createResolution(): void {
    const resolution = this.newResolution();
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);

    this.resolutionInterop
      .createResolution(resolution, card)
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() => {
        returnAuthUserSession(this.store, this.currentUser, this.authUser);
        this.documentNavigationService.nextDocument();
        this.store.dispatch([ResetForm, ResetResolution, ResetDossier]);
        this.modalCtrl.dismiss(undefined, undefined, modalIdCreateResolution);
      });
  }

  private newResolution(): CreateResolutionModel {
    const resolution = JSON.parse(JSON.stringify(this.store.selectSnapshot(ResolutionState.getResolutionProjectCard)));
    const form = this.store.selectSnapshot(FormsState.getFormEditResolution);

    const newResolution = {
      author: form.author,
      fromEmployee: form.onBehalfOf,
      childResolutions: resolution.childResolutions,
      creationDate: new Date(),
      files: resolution.files,
      isDraft: resolution.isDraft,
      missions: [],
      number: resolution.number,
      options: {},
      parentResolutionId: resolution.id,
      type: form.isRequest
        ? {
            id: 6,
            name: 'Запрос',
          }
        : {
            id: 2,
            name: 'Резолюция',
          },
      fileResolutionId: form.commissions[0].fileId,
      resolutionKind: form.commissions[0].resolutionKind,
    };

    newResolution.missions = this.commissionResolution(form);

    return newResolution;
  }

  public checkHasAssistant(): void {
    this.userInfoService
      .checkHasAssistant()
      .pipe(first())
      .subscribe((value) => this.store.dispatch(new SetStatusCurrentUserAssistant(value)));
  }

  private commissionResolution(form: ResolutionEditModel): MissionModel[] {
    const commissions = form.commissions.map((c: CommissionFormModel, i: number) => ({
      curator: null,
      dossiers: [],
      privateComment: c.fileId ? '' : c.text,
      serialNumber: i + 1,
      forPrepareAnswer: false,
      missionAction: null,
      isWithoutExecutorOrAddressee: c.executorSign || null,
      addressee: c.performers.map((p: PerformerFormModel, j: number) => this.addresseeResolution(j, p)),
    }));

    return this.addEmptyExecutor(form, commissions);
  }

  private addresseeResolution(j: number, performer: PerformerFormModel): ResolutionAddresseeModel {
    const addresseeType = performer.inPlus ? reviewerType : performerType;
    const date = this.dateHelper.dateToSmallFormat(performer.date);
    const urgency = this.store.selectSnapshot(DictionariesAllUrgencyState.currentUrgency(performer.urgency?.id));
    return {
      isControl: performer.control || false,
      personalNote: performer.note,
      serialNumber: j + 1,
      isResponsibleByMission: performer.responsible,
      checkDateType: performer.inPlus ? null : urgency || null,
      urgencyReviewType: !performer.inPlus ? null : performer.urgency || null,
      addresseeType,
      dueDate: date ? new Date(date) : null,
      initialDueDate: null,
      decontrolDate: null,
      employee: performer.performer,
      forPrepareAnswer: null,
    };
  }
}
