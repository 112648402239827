import { Pipe, PipeTransform } from '@angular/core';

import { DelegatingTypeEnum } from '@npaShared/enums/delegating-type.enum';

@Pipe({
  name: 'npaDelegatingType',
})
export class NpaDelegatingTypePipe implements PipeTransform {
  public transform(value: DelegatingTypeEnum): string | null {
    if (!value) {
      return null;
    }

    switch (value) {
      case DelegatingTypeEnum.ACTING:
        return 'исполнение обязанностей';
      case DelegatingTypeEnum.ON_BEHALF_OF:
        return 'работа от имени';
      default:
        return 'неизвестный тип';
    }
  }
}
