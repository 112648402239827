/** Сами параметры конфигурации Interceptor */
export class InterceptorConfig {
  constructor(
    /** Переключаетель для отображения прелоадера */
    public isShowPreloaderAnimation: boolean = true,
    /** Флаг кэширования запроса */
    public cache: boolean = true,
    /** сообщение лоадера */
    public message: string = defaultMessage,
  ) {}
}

export class InterceptorErrorConfig extends InterceptorConfig {
  constructor(
    public ignoreError: boolean = false,
    public arg: InterceptorConfig = new InterceptorConfig(false, false, ''),
  ) {
    super(arg.isShowPreloaderAnimation, arg.cache, arg.message);
  }
}

const defaultMessage = 'Пожалуйста подождите...';
