import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';

import { getParentPointOnMainVertical, pointIsParallel } from '@npaShared/helpers/route/point-helper';
import { PointLogicModel } from './point-logic.interface';
import { RootPointLogic } from './root-point-logic';

/** В классе описано поведение общее для все точек, принадлежащих подэтапу "Поручение" */
export abstract class InitiationDefaultLogic extends RootPointLogic implements PointLogicModel {
  public canStartDevelopment(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader, EmployeeRouteTypes.executor, EmployeeRouteTypes.coexecutor];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    const isCoexecutorPoint = this.isContainsPointRole([EmployeeRouteTypes.coexecutor]);
    const parentPoint = getParentPointOnMainVertical(this.getRoute(), this.getPoint().id);
    const isResponsibleExecutor = Boolean(parentPoint?.responsibleExecutor);
    if (isCoexecutorPoint && isResponsibleExecutor) {
      return false;
    }

    return true;
  }

  public canAddExecutor(): boolean {
    const allowedRoles = [EmployeeRouteTypes.executor, EmployeeRouteTypes.leader];
    const point = this.getPoint();

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (pointIsParallel(this.getRoute(), point.id)) {
      return false;
    }

    return true;
  }

  public canSendRemarksToAuthor(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.hasSiblingPointsInSubphase()) {
      return false;
    }

    return true;
  }

  public canApprove(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.hasSiblingPointsInSubphase()) {
      return false;
    }

    return true;
  }

  public canApproveWithRemarks(): boolean {
    return this.canApprove();
  }
}
