import { Pipe, PipeTransform } from '@angular/core';
import { SignerCategoryEnum } from '@npaDP/enums/signer-category.enum';
import { SignerCategory } from '@npaShared/models/route/signer-category.model';

@Pipe({
  name: 'categoryWidth',
})
export class CategoryWidthPipe implements PipeTransform {
  public transform(value: SignerCategory): string {
    if (
      [
        SignerCategoryEnum.approver,
        SignerCategoryEnum.externalApprover,
        SignerCategoryEnum.documentManager,
        SignerCategoryEnum.leader,
      ].includes(value.name)
    ) {
      return '210px';
    }

    if ([SignerCategoryEnum.projectManager, SignerCategoryEnum.legalExpertiseHeader].includes(value.name)) {
      return '325px';
    }

    return '';
  }
}
