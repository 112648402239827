/** Сохранить активную карточку проекта резолюции */
export class SetActiveCardProjectResolution {
  public static readonly type = '[Active Card] Set Active Card Project Resolution';
  constructor(public numberCard: number) {}
}

/** Удалить активную карточку проекта резолюции */
export class ResetActiveCardProjectResolution {
  public static readonly type = '[Active Card] Reset Active Card Project Resolution';
}
