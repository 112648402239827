import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';

import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';
import { InterceptorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { MoveToPersonalFolderModel } from '../models/folders/move-to-personal-folder.model';
import { PersonalFolder } from '../models/folders/personal-folders.model';

@Injectable({
  providedIn: 'root',
})
export class FoldersInteropService {
  constructor(private http: HttpClient) {}

  public getUserFolders(userId: number, preloaderFlag: boolean = false): Observable<PersonalFolder[]> {
    const url = Urls.folders.getFolders(userId);
    return this.http
      .get<PaginationBaseModel<PersonalFolder>>(url, {
        params: new InterceptorHttpParams(new InterceptorConfig(preloaderFlag)),
      })
      .pipe(
        map((val) => val.data.content),
        catchError(() => of([])),
      );
  }

  public editOrCreateNewFolder(body: Partial<PersonalFolder>): Observable<void> {
    const url = Urls.folders.editOrCreateFolder;
    return this.http.post<void>(url, body);
  }

  public deleteFolder(folderId: number): Observable<void> {
    const url = Urls.folders.deleteFolder(folderId);
    return this.http.delete<void>(url);
  }

  public deleteAppealFromFolder(folderId: number, appealId: number): Observable<void> {
    const url = Urls.folders.deleteAppealFromFolder(folderId, appealId);
    return this.http.delete<void>(url);
  }

  public moveToPersonalFolder(body: MoveToPersonalFolderModel): Observable<void> {
    const url = Urls.folders.moveToPersonalFolder;
    return this.http.post<void>(url, body);
  }

  public findFolderByDocument(appealId: number, userId: number): Observable<PersonalFolder[]> {
    const url = Urls.folders.findPersonalFolderByDocument(appealId, userId);
    return this.http.get<PaginationBaseModel<PersonalFolder>>(url).pipe(map((val) => val.data.content));
  }
}
