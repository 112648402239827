import { Pipe, PipeTransform } from '@angular/core';
import { AppealResolutionEnum } from '@oogShared/enums/resolution-form/appeal-resolution-type.enum';

/** Возвразает строкове представления типа поручения */
@Pipe({
  name: 'commissionType',
})
export class CommissionTypePipe implements PipeTransform {
  public transform(type: AppealResolutionEnum): string {
    switch (type) {
      case AppealResolutionEnum.resolution:
        return 'резолюция';
      case AppealResolutionEnum.project:
        return 'запрос';
      case AppealResolutionEnum.redirect:
        return 'переадресация';

      default:
        throw new Error('Не передан тип Поручения');
    }
  }
}
