import { Injectable } from '@angular/core';

import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { reviewerType } from '@oogShared/consts/reviewer-type.const';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { ProjectResolutionHelperService } from '@oogShared/services/state-project-resolution/project-resolution-helper.service';
import { FormsState } from '@store/forms/forms.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ActiveResolutionProjectState } from '@store/resolution-store/active-resolution-project/active-resolution-project.state';

import {
  DeleteProjectResolution,
  RecalculateSerialNumber,
  ResetResolution,
  SetResolution,
  SortChildResolutionMissions,
  SwapProject,
} from './resolution.action';

@Injectable()
@State<ResolutionModel | null>({
  name: 'resolution',
  defaults: null,
})
export class ResolutionState {
  constructor(private projectResolutionHelper: ProjectResolutionHelperService, private store: Store) {}

  public static addresseeId(id: number): (state: ResolutionModel) => number[] {
    return createSelector([ResolutionState], (state: ResolutionModel): number[] => {
      const missions = state.missions;
      const ids: number[] = [];
      missions.forEach((m) => {
        const currentUser = m.addressee.find((a) => a.employee?.id === id);
        if (currentUser) {
          ids.push(currentUser.id);
        }
      });
      return ids;
    });
  }

  @Selector()
  public static getResolutionProjectCard(state: ResolutionModel): ResolutionModel {
    return state;
  }

  @Selector()
  public static getFamiliarizationPerformers(state: ResolutionModel): ResolutionAddresseeModel[] {
    return state.missions[0].addressee.filter((a) => a.addresseeType.id === reviewerType.id);
  }

  @Selector()
  public static authorResolution(state: ResolutionModel): EmployeeModel {
    return state.author;
  }

  @Selector()
  public static getFirstMissionFromResolution(state: ResolutionModel): MissionModel {
    return state.missions && state.missions[0];
  }

  @Selector()
  public static questionId(state: ResolutionModel): number {
    return state.question.id;
  }

  @Selector()
  public static numberResolution(state: ResolutionModel): string {
    return state.number;
  }

  @Selector()
  public static dateResolution(state: ResolutionModel): Date {
    return state.creationDate;
  }

  @Selector()
  public static addresseeResolution(state: ResolutionModel): ResolutionAddresseeModel {
    return state.missions[0].addressee.find((a) => a.addresseeType.id === 1);
  }

  @Selector()
  public static authorsProjects(state: ResolutionModel): EmployeeModel[] {
    return state.childResolutions.map((c) => c.author);
  }

  @Selector()
  public static countProjectsInResolution(state: ResolutionModel): number {
    return state.childResolutions.length;
  }

  @Selector()
  public static resolutionIsDirectionType(state: ResolutionModel): boolean {
    return state.type.id === ResolutionTypeEnum.heading;
  }

  @Selector()
  public static isOneProjectForRedirect(state: ResolutionModel): boolean {
    return state.childResolutions?.length === 1;
  }

  @Selector([FormsState.getAddresseeForRedirection])
  public static compareAuthorWithAddressee(state: ResolutionModel, author: EmployeeModel): boolean {
    return state.childResolutions[0].author.id === author.id;
  }

  @Selector([ActiveResolutionProjectState.activeProject])
  public static getActiveProject(state: ResolutionModel, projectIndex: number): ResolutionModel {
    return state.childResolutions[projectIndex];
  }

  @Selector([ActiveResolutionProjectState.activeProject])
  public static getAllProjects(state: ResolutionModel): ResolutionModel[] {
    return state.childResolutions;
  }

  @Selector([ActiveResolutionProjectState.activeProject])
  public static getAllMissions(state: ResolutionModel): MissionModel[] {
    return state.missions;
  }

  @Selector()
  public static isResolutionRequestProject(state: ResolutionModel): boolean {
    return state.type?.id === ResolutionTypeEnum.requestProject;
  }

  @Selector()
  public static getResolutionHasProjectLetter(state: ResolutionModel): boolean {
    return state.missions[0]?.hasProjectLetter;
  }

  @Selector()
  public static getResolutionProjectLetterAuthor(state: ResolutionModel): EmployeeModel {
    return state.missions[0]?.projectLetterAuthor;
  }

  @Selector()
  public static getResolutionProjectChildResolutionsAddressee(state: ResolutionModel): ResolutionAddresseeModel[] {
    const missions: MissionModel[] = state.childResolutions.reduce((acc, el) => [...acc, ...el.missions], []);
    return missions.reduce((acc, el) => [...acc, ...el.addressee], []);
  }

  @Action(SetResolution)
  public setResolution({ setState }: StateContext<ResolutionModel>, { card }: SetResolution): void {
    if (card) {
      card.uniqueId = this.store.selectSnapshot(ActiveCardState.getUniqueId);
    }
    setState(card);
  }

  @Action(ResetResolution)
  public resetResolution({ setState }: StateContext<ResolutionModel>): void {
    setState(null);
  }

  @Action(DeleteProjectResolution)
  public deleteProjectResolution({ setState }: StateContext<ResolutionModel>, { id }: DeleteProjectResolution): void {
    setState(
      patch({
        childResolutions: removeItem((c: ResolutionModel) => {
          if (!c.id) {
            return c.serialNumber === id;
          }
          return c.id === id;
        }),
      }),
    );
  }

  @Action(SwapProject)
  public swapCards(
    { getState, setState }: StateContext<ResolutionModel>,
    { firstCard, secondCard }: SwapProject,
  ): void {
    const resolution = { ...getState() };
    const cards = [...resolution.childResolutions];

    [cards[firstCard], cards[secondCard]] = [cards[secondCard], cards[firstCard]];

    resolution.childResolutions = [...cards];
    resolution.childResolutions = this.projectResolutionHelper.setChildrenResolution(resolution);
    setState(resolution);
  }

  @Action(RecalculateSerialNumber)
  public recalculateSerialNumber({ getState, setState }: StateContext<ResolutionModel>): void {
    const state = { ...getState() };

    state.childResolutions = this.projectResolutionHelper.setChildrenResolution(state);
    setState(state);
  }

  @Action(SortChildResolutionMissions)
  public sortChildResolutionMissions({ getState, setState }: StateContext<ResolutionModel>): void {
    const newState = { ...getState() };

    if (!newState.childResolutions) {
      return;
    }

    newState.childResolutions = newState.childResolutions.map((resolution) =>
      this.projectResolutionHelper.setChildrenResolutionMissions(resolution),
    );

    setState(newState);
  }
}
