import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, finalize, first, takeUntil, tap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { CertificateItemModel } from '@models/esigns/certificate-item.model';
import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { getDefaultCertificate } from '@shared/functions/get-default-certificate';
import { SettingsCertificateUserNamePipe } from '@shared/pipes/settings-certificate-user-name.pipe';
import { FileCertificateHelperService } from '@shared/services/file-certificate-helper.service';
import { SettingsState } from '@store/settings/settings.state';
import { EsignOogService } from '@oogShared/services/esign-oog.service';
import { OogEsignInteropService } from '@oogShared/rest/oog-esign-interop.service';
import { ApprovalHelperService } from '@oogShared/services/approval-helper.service';
import { needChangingSavedCertificate } from '@shared/functions/certificates.functions';
import { ApprovalModalBase } from '../approval-modal-base/approval-modal-base';
import { ApprovalSignService } from './approval-sign.service';

@Component({
  selector: 'app-approval-modal-sign',
  templateUrl: './approval-modal-sign.component.html',
  styleUrls: ['./approval-modal-sign.component.scss'],
})
export class ApprovalModalSignComponent extends ApprovalModalBase implements OnInit, OnDestroy {
  public form: FormGroup = new FormGroup({});
  public certs: CertificateItemModel[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    currentUser: CurrentUserIsproStoreService,
    modalCtrl: ModalController,
    approvalHelperService: ApprovalHelperService,
    store: Store,
    private esignApi: OogEsignInteropService,
    private fb: FormBuilder,
    private certificateUserNamePipe: SettingsCertificateUserNamePipe,
    private fileCertificateHelper: FileCertificateHelperService,
    private approvalSignService: ApprovalSignService,
    private esignService: EsignOogService,
  ) {
    super(currentUser, modalCtrl, approvalHelperService, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.headerData.title = 'Подписать';
    this.initForm();
    this.getListCertificates();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public submitForm(): void {
    const certItem = this.form.value.cert;

    if (this.esignService.isInvalidCertificateSigningResolution()) {
      this.esignService.showModalInvalidCertificate();
      return;
    }

    if (certItem.isCloud) {
      this.approvalSignService.signCloud(this.approvalData, this.form.value);
      return;
    }

    const cert = this.certs[0];
    const text = this.form.value.text;
    this.approvalSignService.signLocal(this.approvalData.id, cert, text);
  }

  private async getListCertificates(): Promise<void> {
    this.esignApi
      .getCerts()
      .pipe(
        first(),
        tap(
          (data) =>
            (this.certs = [
              ...this.certs,
              ...data.map((c) => ({
                id: c.id,
                serialNumber: c.serialNumber,
                name: c.dname,
                validFrom: c.validFrom,
                validUntil: c.validUntil,
                isDefault: c.isDefault,
                isCloud: true,
              })),
            ]),
        ),
        finalize(() => this.setDefaultCertificate()),
        catchError(() => of([])),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    const list = await this.fileCertificateHelper.getListCertificates();
    this.certs = [
      ...this.certs,
      ...list.map((c) => ({
        id: c.serialNumber,
        name: this.certificateUserNamePipe.transform(c.subject),
        validFrom: c.validAfter,
        validUntil: c.validBefore,
        isDefault: false,
        isCloud: false,
      })),
    ];
    this.setDefaultCertificate();
  }

  private setDefaultCertificate(): void {
    const savedCertificate = this.store.selectSnapshot(SettingsState.certificateSettingsOog).defaultCertificateOog;
    const defaultEsign = getDefaultCertificate(this.certs);

    const needChange = needChangingSavedCertificate(savedCertificate, this.certs);
    const cert = needChange ? defaultEsign : savedCertificate;

    this.form.patchValue({ cert });
  }

  /** Инициализация формы */
  private initForm(): void {
    const agreed = this.store.selectSnapshot(SettingsState.certificateSettingsOog).agreedOog;

    this.form = this.fb.group({
      cert: ['', Validators.required],
      agreed: [agreed, Validators.requiredTrue],
      text: [''],
    });
  }
}
