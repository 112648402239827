import { Pipe, PipeTransform } from '@angular/core';

import { BehalfAccessTypeEnum } from '@oogShared/enums/behalf-access-type.enum';
import { ActingTypeEnum } from '@oogShared/enums/main-screen/acting-type-names.enum';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { OogUserShortNamePipe } from '@shared/pipes/oog-user-short-name/oog-user-short-name.pipe';

@Pipe({
  name: 'behalfOfDisplayAddressee',
})
export class BehalfOfDisplayAddresseePipe implements PipeTransform {
  constructor(private shortNamePipe: OogUserShortNamePipe) {}

  public transform(value: string, addressee: ResolutionAddresseeModel): string {
    return this.getResolutionActingName(value, addressee);
  }

  private getResolutionActingName(value: string, addressee: ResolutionAddresseeModel): string {
    switch (addressee.whoGaveRightsType) {
      case ActingTypeEnum.fromName:
        return `${value} / ${this.shortNamePipe.transform(addressee.whoGaveRights)}`;
      case ActingTypeEnum.assistant:
        return `${value} / ${this.shortNamePipe.transform(addressee.whoGaveRights)}`;
      case ActingTypeEnum.dischargeOfDuties:
        if (
          addressee.behalfAccessType?.id === BehalfAccessTypeEnum.availableToSpecificEmployee &&
          addressee.whoGaveRightsTemplateSignature
        ) {
          return addressee.whoGaveRightsTemplateSignature;
        }
        return value;
      default:
        return value;
    }
  }
}
