import { AfterViewInit, ChangeDetectorRef, Directive, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { DateHelperService } from '@shared/services/date-helper.service';
import { DictionariesHotEmployeeState } from '@store/dictionaries/dictionaries-hot-employee/dictionaries-hot-employee.state';
import { FormsState } from '@store/forms/forms.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetActiveCardProjectResolution } from '@store/resolution-store/active-resolution-project/active-resolution-project.action';

import { DischargeDutiesService } from '@oogShared/services/discharge-of-duties/discharge-duties.service';
import { FormActionControlService } from '@oogShared/services/forms/form-action-control.service';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { FormHeaderModel } from '@oogShared/models/forms-input/form-header.model';
import { AcceptProjectEnum } from '@oogShared/enums/accept-project/accept-project.enum';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { UrgencyReviewTypeModel } from '@oogShared/models/resolutions/urgency-review-type.model';
import { ActionUrgencyModel } from '@oogShared/models/urgency/action-urgency.model';
import { ActionUrgencyEnum } from '@oogShared/enums/action-urgency/action-urgency.enum';
import { actionUrgency } from '@oogShared/consts/action-urgency.const';
import { defaultValueUrgency } from '@oogShared/consts/default-value-urgency.const';
import { SearchMissionModel } from '@oogShared/models/resolutions/mission/search-mission.model';
import { AppealResolutionEnum } from '@oogShared/enums/resolution-form/appeal-resolution-type.enum';
import { modalIdSpreadExecutor } from '@const/modals-id.const';
import { CommissionForSpread } from '@oogShared/models/resolutions/mission/mission-for-spread.model';
import { QuestionModel } from '@oogShared/models/resolutions/question/question.model';
import { RadioControlEnum } from '@oogShared/enums/radio-control.enum';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { CreateResolutionDraftService } from '@oogShared/services/forms/draft/create-resolution-draft.service';
import { SelectUserTypesEnum } from '@oogShared/enums/select-user-types.enum';
import { textareaTextConsts } from '@oogShared/consts/textarea-text.consts';
import { SpreadExecutorModalComponent } from './components/spread-executor-modal/spread-executor-modal.component';
import { FormHelper } from './form-helper';

@Directive()
export class AppealFormBase extends FormHelper implements AfterViewInit {
  @Input() public draft: CreateResolutionModel | null = null;

  @Select(DictionariesHotEmployeeState.getHotListEmployee)
  public hotListEmployee$!: Observable<EmployeeModel[]>;

  @Select(FormsState.clonePerformerCreateAppealResolution)
  public clonePerformer$!: Observable<boolean>;

  @Select(FormsState.appealDisabledByWhiteListPerformer)
  public disabledByWhiteList$!: Observable<boolean>;

  @Select(ActiveCardState.canApproveResolutionProject)
  public canApproveResolutionProject$: Observable<boolean>;

  public form: FormGroup = new FormGroup({});
  /** переменная для выбранных исполнителей  */
  public commissionWithoutPerformers: number[] = [];
  public headerData!: FormHeaderModel;
  public saveAndAcceptEnum = AcceptProjectEnum;
  public countDays = 0;
  public dispatchForm = false;
  public readonly types = AppealResolutionEnum;
  public readonly radioTheme = RadioControlEnum;
  public selectUserTypes = SelectUserTypesEnum;

  public questionIds: number[] = [];

  constructor(
    protected store: Store,
    protected fb: FormBuilder,
    protected dateHelper: DateHelperService,
    protected currentUser: CurrentUserIsproStoreService,
    protected restoreFormService: CreateResolutionDraftService,
    private modalCtrl: ModalController,
    private actionControlService: FormActionControlService,
    private cdr: ChangeDetectorRef,
    private dutiesService: DischargeDutiesService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  /** Уйти назад */
  public previousStep(): void {
    this.store.dispatch([ResetActiveCardProjectResolution]);
    this.modalCtrl.dismiss().then();
  }

  /** Получить список вопросов */
  public getQuestionsArray(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  /** Получить список поручений по вопросу */
  public getCommissionArrayByQuestion(question: AbstractControl): FormArray {
    const commissions = question.get('commissions') as FormArray;
    commissions?.controls?.sort((a, b) => a.value?.serialNumber - b.value?.serialNumber);
    return commissions;
  }

  /** Получить список исполнителей */
  public getPerformersArray(commission: AbstractControl): FormArray {
    return commission.get('performers') as FormArray;
  }

  /** Удалить поручение */
  public deleteCommission(question: AbstractControl, commissionIdx: number): void {
    const commissions = this.getCommissionArrayByQuestion(question);
    commissions.removeAt(commissionIdx);
  }

  /** Добавить исполнителя в поручение */
  public addPerformer(commissionGroup: FormGroup, employee: EmployeeModel, minDate: string = ''): void {
    const actionControl = commissionGroup.controls?.isAction?.value;

    const performers = commissionGroup.get('performers') as FormArray;

    this.createNewPerformerGroupOrPatchValueToExisting(performers, commissionGroup, employee, minDate);
    const performerGroup = performers.controls[performers.length - 1] as FormGroup;

    const employees = performers.value.map((p) => p.performer).filter((p) => p?.id === employee?.id);
    this.setCloneControl(performers, employees);

    this.dutiesService.dischargeOfDuties(performerGroup, employee?.id, performers, performers.length - 1, 'performer');

    if (actionControl && !this.findPerformerInRelatedOrder(employee, commissionGroup)) {
      this.setPerformerForFamiliarization(performerGroup);
    }
  }

  /** Показать модально окно для добавления исполнителя из первого поручения в остальные */
  public async showSpreadExecutorModal(employee: EmployeeModel, minDate: string = ''): Promise<void> {
    const commissions = this.getAllCommissions();
    commissions.shift();

    const commissionForSpread = this.commissionsForSpreadExecutor();

    if (!commissionForSpread.length) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: SpreadExecutorModalComponent,
      componentProps: {
        executor: employee,
        commissions: commissionForSpread,
      },
      cssClass: 'full-screen-with-background',
      id: modalIdSpreadExecutor,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    commissions.forEach((commission, index) => {
      if (data?.sprededCommissionIdxs.includes(index)) {
        this.addPerformer(commission, employee, minDate);
      }
    });
  }

  /** При смене типа поручения на перенаправления, очистить контролы исполнителя */
  public commissionTypeChange(commissionGroup: AbstractControl, type: AppealResolutionEnum): void {
    if (type !== AppealResolutionEnum.redirect) {
      return;
    }

    const performers = commissionGroup.get('performers') as FormArray;

    performers.controls.forEach((performer) => {
      performer.get('responsible')?.setValue(false);
      performer.get('inPlus')?.setValue(false);
      performer.get('date')?.setValue(null);
      performer.get('control')?.setValue(false);
      performer.get('urgency')?.setValue(null);
    });
  }

  /** Добавить новое поручение в вопрос */
  public addCommissionToQuestion(question: AbstractControl): void {
    const commissions = this.getCommissionArrayByQuestion(question);
    commissions.push(this.createNewCommissionGroupByQuestion(question));
  }

  /** Выбрать поручение из шаблона */
  public chooseCommissionTemplate(question: AbstractControl, commissionIdx: number, value: string): void {
    const commissions = this.getCommissionArrayByQuestion(question);
    const text = commissions.controls[commissionIdx].get('text') as AbstractControl;
    const savedTextControl = commissions.controls[commissionIdx].get('savedText') as AbstractControl;
    text.setValue(`${text.value || ''} ${value}`);
    savedTextControl?.setValue(`${text.value || ''} ${value}`);
  }

  /** Очистить поручение */
  public clearCommission(question: AbstractControl, commissionIdx: number): void {
    const commissions = this.getCommissionArrayByQuestion(question);
    const text = commissions.controls[commissionIdx].get('text') as AbstractControl;
    text.reset();
  }

  /** Перемещение поручений между собой */
  public swapCommissions(question: FormGroup | AbstractControl, commissionIdx: number, action: string): void {
    const commissions = this.getCommissionArrayByQuestion(question);
    this.swapElements(this.form, commissions, commissionIdx, action);
  }

  /** очистить форму "commissions"(поручения) */
  public clearCommissionsForm(): void {
    (this.form.get('questions') as FormArray).controls.forEach((question) => {
      (question.get('commissions') as FormArray).clear();
      this.addCommissionToQuestion(question);
    });
  }

  /** Получить список вопросов по резолюции */
  public getAllQuestions(): QuestionModel[] {
    const questions: QuestionModel[] = [];
    const currentUser = this.currentUser.getCurrentUserSnapshot();

    this.store.selectSnapshot(ResolutionState.getAllMissions)?.forEach((mission) => {
      const missionAddressee = mission.addressee.map((a) => a.employee?.id);
      if (!this.questionIds.includes(mission.questions[0].id) && missionAddressee?.includes(currentUser.id)) {
        questions.push(mission.questions[0]);
        this.questionIds.push(mission.questions[0].id);
      }
    });

    return questions.sort((a, b) => Number(a?.serialNumber) - Number(b?.serialNumber));
  }

  /** Отследить переключение признака "Без исполнителей" */
  public handleExecutorSignChange(formGroup: FormGroup): void {
    const performers = formGroup.controls.performers as FormArray;
    performers.controls.forEach((p: FormGroup) => {
      const executorSign = formGroup.controls.executorSign.value;
      p.controls.inPlus.setValue(executorSign);
      p.controls.responsible?.setValue(false);
      formGroup.controls.isAction?.value
        ? this.removeAndAddControlsWithActionControl(p)
        : this.removeAndAddControlsWithSaveDate(p, true);
    });
  }

  /** Возвращает индекс поручения из списка всех поручений */
  public getCommissionIndex(commission: AbstractControl): number {
    const commissions = this.getAllCommissions();
    return commissions.indexOf(commission as FormGroup);
  }

  /** Создать форму "вопросы" */
  protected createQuestionsArray(): FormGroup[] {
    const questions: QuestionModel[] = this.getAllQuestions();

    return questions.map((question) =>
      this.fb.group({
        question: [question],
        commissions: this.fb.array(
          this.draft ? this.restoreCommissionsFromDraft(question) : [this.createNewCommissionGroup()],
        ),
      }),
    );
  }

  protected getPerformerFormArray(commissions: FormArray, commissionIdx: number): FormArray {
    return commissions.controls[commissionIdx].get('performers') as FormArray;
  }

  /** Есть ли исполнители в поручении */
  protected addresseeInCommissions(): boolean {
    const commissions = this.getAllCommissions();
    const emptyAddressee = commissions.filter((c) => {
      const executorSign = c.get('executorSign');
      const performers = c.get('performers') as FormArray;
      return !executorSign?.value && !performers.length;
    });

    return this.onlyFamiliarization() || !!emptyAddressee.length;
  }

  /** Найти поручения без исполнителей */
  protected emptyCommissions(): void {
    const commissions = this.getAllCommissions();
    const commissionIdx: number[] = [];
    commissions.forEach((c: AbstractControl, index: number) => {
      const executorSign = c.get('executorSign');
      const performers = c.get('performers') as FormArray;
      if ((!executorSign?.value && !performers.length) || this.onlyFakePerformers(c)) {
        commissionIdx.push(index);
      }
      if (!executorSign?.value && performers?.controls?.every((p: FormGroup) => p.controls?.inPlus?.value)) {
        commissionIdx.push(index);
      }
      this.commissionWithoutPerformers = commissionIdx;
    });
    this.clearCommissionWithoutPerformers();
  }

  /** Инициализировать контроль исполнителя для формы действий с контролем */
  protected initControlType(
    group: FormGroup,
    defaultUrgency: UrgencyReviewTypeModel,
    actionValue?: ActionUrgencyModel,
    dueDate?: string,
    decontrolDate?: string,
    reportDate?: string,
  ): void {
    if (group.controls.inPlus.value) {
      this.removeControls(group, ['actionControl', 'date', 'decontrolDate', 'reportDate']);
      return;
    }
    const initDate = group.controls.initDueDate?.value;

    this.addControls(group, [
      { name: 'urgency', value: defaultUrgency, validators: [] },
      { name: 'date', value: dueDate || initDate, validators: [Validators.required] },
    ]);

    const action = group.controls.actionControl.value as ActionUrgencyModel;
    const patchControls: { [key: string]: () => void } = {
      [ActionUrgencyEnum.noPrologWithReport]: () =>
        this.actionControlService.noPrologWithReport(group, actionValue, reportDate),
      [ActionUrgencyEnum.addition]: () => this.actionControlService.addition(group, actionValue, dueDate || initDate),
      [ActionUrgencyEnum.noProlog]: () => this.actionControlService.addition(group, actionValue, dueDate || initDate),
      [ActionUrgencyEnum.prolog]: () => this.actionControlService.baseScheme(group, actionValue, dueDate || initDate),
      [ActionUrgencyEnum.stop]: () => this.actionControlService.stop(group, actionValue, decontrolDate),
      [ActionUrgencyEnum.prologNoControl]: () =>
        this.actionControlService.prologNoControl(group, actionValue, dueDate, decontrolDate),
    };
    patchControls[action.value]();
  }

  protected clearFormDispatching(): void {
    setTimeout(() => (this.dispatchForm = false), 2000);
  }

  /** Изменение контрола "Контроль" */
  protected controlChange(performerGroup: FormGroup | AbstractControl): void {
    const group = performerGroup as FormGroup;
    const control = group.controls.control?.value;
    const date = group.controls.date;
    if (control && !date?.value) {
      date?.setErrors({ invalid: true });
      return;
    }
    date?.setErrors(null);
  }

  protected createNewCommissionGroup(): FormGroup {
    return this.fb.group({
      type: [this.types.resolution, Validators.required],
      text: [''],
      hiddenText: [textareaTextConsts.hiddenCommissionText],
      canEditComment: true,
      executorSign: false,
      serialNumber: [1],
      performers: this.fb.array([]),
      savedText: [''],
      parentResolutionCommentText: [''],
      fileId: [],
      resolutionKind: [null],
      forPrepareAnswer: [true],
      isCommentConfidential: [false],
    });
  }

  protected createNewCommissionGroupByQuestion(question: AbstractControl): FormGroup {
    return this.fb.group({
      type: [this.types.resolution, Validators.required],
      text: [''],
      hiddenText: [textareaTextConsts.hiddenCommissionText],
      canEditComment: true,
      executorSign: false,
      serialNumber: [this.getCommissionArrayByQuestion(question)?.length + 1],
      performers: this.fb.array([]),
      savedText: [''],
      parentResolutionCommentText: [''],
      fileId: [],
      resolutionKind: [null],
      forPrepareAnswer: [true],
      isCommentConfidential: [false],
    });
  }

  /** Удалить или добавить контролы для формы действий с контролем */
  protected removeAndAddControlsWithActionControl(group: FormGroup): void {
    if (!group.controls.urgency) {
      this.addControls(group, [{ name: 'urgency', value: '', validators: [] }]);
    }

    if (group.controls.inPlus.value) {
      this.removeControls(group, ['actionControl', 'date', 'decontrolDate', 'reportDate']);
      group.controls.urgency.setValue(defaultValueUrgency);
      group.controls.responsible.setValue(false);
      return;
    }
    group.controls.urgency?.reset();
    this.addControlsForActionControl(group);
  }

  /**
   * Либо создаем новый контрол для исполнителя (контролы для исполнителя тоже разные, в зависимости от условия),
   * либо вставляем исполнителя в текущий контрол
   * */
  private createNewPerformerGroupOrPatchValueToExisting(
    performers: FormArray,
    commissionGroup: FormGroup,
    employee: EmployeeModel,
    minDate: string,
  ): void {
    // берем последнюю форму поручений
    const lastPerformer = this.getLastPerformerControl(performers);
    const lastPerformerGroup = this.getLastPerformerGroup(performers);
    const commissionId = commissionGroup.controls?.id?.value;
    const executorSign = commissionGroup.controls?.executorSign?.value;

    // Если последний контрол с исполнителем есть и исполнитель не заполнен - заполняем его
    if (lastPerformer && !lastPerformer?.value) {
      // Устанавливаем сотрудника в форму где исполнитель не заполнен
      lastPerformer.setValue(employee);
      const inPlusControl = lastPerformerGroup.controls?.inPlus;
      executorSign ? inPlusControl?.setValue(true) : inPlusControl?.setValue(false);
      this.removeAndAddControlsWithSaveDate(lastPerformerGroup, true);
      return;
    }

    const newPerformerGroup = commissionGroup.get('isAction')?.value
      ? this.createNewPerformerGroupWithActionControl(employee, commissionId)
      : this.createNewPerformerGroup(employee, commissionGroup, minDate, commissionId);

    // Добавляем новую форму с исполнителем
    performers.push(newPerformerGroup);
  }

  private getLastPerformerControl(performers: FormArray): AbstractControl {
    const lastPerformerFormGroup = performers.controls[performers.controls.length - 1] as FormGroup;
    // берем контрол исполнителя из последней формы
    return lastPerformerFormGroup?.get('performer');
  }

  private getLastPerformerGroup(performers: FormArray): FormGroup {
    return performers.controls[performers.controls.length - 1] as FormGroup;
  }

  /** Обнулить массив с поручениями без исполнителя через интервал времени */
  private clearCommissionWithoutPerformers(): void {
    setTimeout(() => (this.commissionWithoutPerformers = []), 2000);
  }

  /** Создать новую (пустую) группу исполнителей */
  private createNewPerformerGroup(
    employee: EmployeeModel,
    commission: FormGroup,
    minDate: string,
    commissionId: number,
  ): FormGroup {
    const inPlus = commission.controls.executorSign?.value;

    const group = this.fb.group(
      {
        performer: [employee, Validators.required],
        responsible: [false],
        inPlus: [inPlus || false],
        note: [''],
        date: [minDate],
        control: [false],
        urgency: [''],
        serialNumber: [],
        commissionId: [commissionId],
      },
      {
        validators: this.controlChange,
      },
    );

    this.removeAndAddControlsWithSaveDate(group);

    return group;
  }

  /** Создать новую (пустую) группу исполнителей с контролем */
  private createNewPerformerGroupWithActionControl(employee: EmployeeModel, commissionId: number): FormGroup {
    return this.fb.group({
      performer: [employee, Validators.required],
      responsible: [false],
      inPlus: [false],
      note: [''],
      actionControl: [actionUrgency[1]],
      date: ['', Validators.required],
      dateControl: [''],
      dateReport: [''],
      urgency: [null],
      serialNumber: [''],
      commissionId: [commissionId],
    });
  }

  private onlyFakePerformers(commission: AbstractControl): boolean {
    const performers = commission.get('performers') as FormArray;
    const fakePerformers = performers?.controls?.filter((p: FormGroup) => !p.value.performer);
    return fakePerformers.length === performers.value?.length;
  }

  /** Проверить, что в поручении исполнители только для ознакомления */
  private onlyFamiliarization(): boolean {
    const commissions = this.getAllCommissions();
    const emptyAddressee = commissions.filter((c) => {
      const executorSign = c.get('executorSign');
      const performers = c.get('performers') as FormArray;
      const performersFamiliarization = performers.controls.filter((p: FormGroup) => !!p.controls.inPlus.value);
      return !executorSign?.value && performersFamiliarization.length === performers.length;
    });
    return !!emptyAddressee.length;
  }

  private removeAndAddControlsWithSaveDate(group: FormGroup, saveState: boolean = false): void {
    if (group.controls.inPlus.value) {
      group.controls.urgency.setValue(defaultValueUrgency);
      if (saveState) {
        this.addDate(group);
        this.disableControls(group, ['date']);
        this.removeControls(group, ['control']);
        return;
      }
      this.removeControls(group, ['date', 'control']);
      return;
    }
    if (saveState) {
      this.enableControls(group, ['date']);
      this.addDate(group);
      this.addControls(group, [{ name: 'control', value: false, validators: [] }]);
      return;
    }
    this.addDateAndControl(group);
  }

  private addDate(group: FormGroup): void {
    if (!group.controls.date) {
      this.addControls(group, [{ name: 'date', value: false, validators: [] }]);
    }
  }

  private addDateAndControl(group: FormGroup): void {
    this.addControls(group, [
      { name: 'date', value: '', validators: [] },
      { name: 'control', value: false, validators: [] },
    ]);
  }

  /** Найти исполнителя в связанном поручении */
  private findPerformerInRelatedOrder(employee: EmployeeModel, commissionGroup: FormGroup): boolean {
    const actionValue: SearchMissionModel = commissionGroup.controls.isAction.value;
    return actionValue.addressee.some((a) => a.employee.id === employee.id);
  }

  private setPerformerForFamiliarization(performerGroup: FormGroup): void {
    const inPlusControl = performerGroup.controls.inPlus;
    inPlusControl.setValue(true);
    inPlusControl.disable();

    const responsibleControl = performerGroup.controls.responsible;
    responsibleControl.setValue(false);
    responsibleControl.disable();

    this.removeAndAddControlsWithActionControl(performerGroup);
  }

  /** создает массив из поручений без первого элемента
   *
   * @returns CommissionForSpread[]
   */
  private commissionsForSpreadExecutor(): CommissionForSpread[] {
    const questions = (this.form.get('questions') as FormArray).controls;

    const commissionsForSpread: CommissionForSpread[] = questions
      .map((question) => {
        const commissions = question.get('commissions').value;
        return commissions.reduce(
          (acc, cur) => acc.concat({ question: question.get('question').value, commissionType: cur.type }),
          [],
        );
      })
      .flat();

    commissionsForSpread.shift();

    return commissionsForSpread;
  }

  private getAllCommissions(): FormGroup[] {
    return (this.form.get('questions') as FormArray).controls
      .reduce((acc, cur) => acc.concat(cur.get('commissions')), [])
      .map((c) => c.controls)
      .flat();
  }

  /** Добавить контролы для формы действий с контролем */
  private addControlsForActionControl(group: FormGroup): void {
    this.addControls(group, [
      { name: 'actionControl', value: actionUrgency[1], validators: [] },
      { name: 'date', value: '', validators: [Validators.required] },
    ]);
  }

  private restoreCommissionsFromDraft(question: QuestionModel): FormGroup[] {
    const missions = this.draft?.missions.filter((m) => m.questions[0]?.id === question.id);

    return missions.map((m) => this.restoreFormService.restoreCommissionGroup(m, true));
  }
}
