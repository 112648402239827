import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Urls } from '@const/urls';
import { AddToTreeRequestModel } from '@npaShared/models/add-to-tree.request.model';
import { AddIntroducerRequestModel } from '@npaShared/models/document-package/add-introducer.request.model';
import { ApproveIntroductionRequestModel } from '@npaShared/models/document-package/approve-introduction.request.model';
import { DocumentPackageAdditionalInfoResponseModel } from '@npaShared/models/document-package/document-package-additional-info.response.model';
import { DocumentPackageApproveModel } from '@npaShared/models/document-package/document-package-approve.model';
import { DocumentPackageResponseModel } from '@npaShared/models/document-package/document-package.response.model';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { DocumentRevisionModel } from '@npaShared/models/document/document-revision.model';
import { DocumentRevisionRequestModel } from '@npaShared/models/document/document-revision.request.model';
import { DocumentsControlAlternativesResponse } from '@npaShared/models/document/documents-control-alternatives.model';
import { EmployeeBaseModel } from '@npaShared/models/employee/employee-base.model';
import { Route } from '@npaShared/models/route/route.models';
import { SignDataResponseModel } from '@npaShared/models/sign-data-response.model';
import { SendToOuterApprovementRequestModel } from '@npaShared/models/document-package/send-to-outer-approvement.request.model';
import { StartApprovementRequestModel } from '@npaShared/models/document-package/start-approvement-request.model';
import { map } from 'rxjs/operators';
import { sortPhasesByOrder } from '@npaShared/functions/phases-sort-order';
import { SendToApprovementResponseModel } from '../document-agreement/models/send-to-approvement-response.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentPackageApiService {
  constructor(private http: HttpClient) {}

  /**
   * Проверка актуальности маршрута
   *
   * @returns true - надо обновить маршрут
   */
  public getHasDiff(dpId: number, version: number): Observable<boolean> {
    const url = Urls.routeEndpoints.hasDiff(dpId);
    return this.http.post<boolean>(url, version);
  }

  /** получить информацию о ПД */
  public getDocumentPackage(dpId: number): Observable<DocumentPackageResponseModel> {
    const url = Urls.npaDocumentPackageEndpoints.getDocumentPackage(dpId);
    return this.http.get<DocumentPackageResponseModel>(url);
  }

  /** получить детальную информацию о ПД */
  public getDocumentPackageAdditionalInfo(dpId: number): Observable<DocumentPackageAdditionalInfoResponseModel> {
    const url = Urls.npaDocumentPackageEndpoints.getDocumentPackageAdditionalInfo(dpId);
    return this.http.get<DocumentPackageAdditionalInfoResponseModel>(url);
  }

  public getDocumentRoute(dpId: number): Observable<Route> {
    const url = Urls.npaDocumentPackageEndpoints.getDocumentRoute(dpId);
    return this.http.get<Route>(url);
  }

  public markDocumentPackageAsReadable(documentPackageId: number): Observable<DocumentInfoResponseModel[]> {
    const url = Urls.npaDocumentEndpoints.markDocumentPackageAsReadable;
    const params = new HttpParams().append('documentPackageId', documentPackageId.toString());
    const body = null;

    return this.http.post<DocumentInfoResponseModel[]>(url, body, { params });
  }

  /** Согласование пакета документов */
  public approveDocumentPackage(dpId: number, body: DocumentPackageApproveModel): Observable<Route> {
    const url = Urls.npaDocumentPackageEndpoints.approveDocumentPackage(dpId);
    return this.http.put<Route>(url, body);
  }

  /** Согласование пакета документов с замечаниями */
  public approveDocumentPackageWithRemark(dpId: number, body: DocumentPackageApproveModel): Observable<Route> {
    const url = Urls.npaDocumentPackageEndpoints.approveDocumentPackageWithRemark(dpId);
    return this.http.put<Route>(url, body);
  }

  public getDocumentSignData(ldeId: string, ldeRevision: string): Observable<SignDataResponseModel> {
    const url = Urls.npaDocumentPackageEndpoints.sign(ldeId, ldeRevision);

    return this.http.get<SignDataResponseModel>(url);
  }

  /** Направить замечания без согласования */
  public routeExternalReturn(id: number, body: DocumentPackageApproveModel): Observable<Route> {
    const url = Urls.routeEndpoints.routeExternalReturn(id);
    return this.http.post<Route>(url, body);
  }

  /** Получение списка документов со списком альтернативных документов и списком документов с замечаниями */
  public documentAlternatives(
    documents: DocumentRevisionRequestModel[],
  ): Observable<DocumentsControlAlternativesResponse> {
    const url = Urls.npaDocumentEndpoints.documentAlternatives;
    return this.http.post<DocumentsControlAlternativesResponse>(url, documents);
  }

  /**
   * добавление точки после указанной
   *
   * @param routeId идентификатор маршрута
   * @param pid идентификатор точки после которой будет создана новая точка
   * @param metadata
   */
  public addAfter(routeId: number, pid: number, metadata: AddToTreeRequestModel): Observable<Route> {
    const url = Urls.npaDocumentPackageEndpoints.addAfter(routeId, pid);
    return this.http.put<Route>(url, metadata);
  }

  /**
   * Взять в работу
   *
   * @param routeId идентификатор маршрута
   * @param revisions список документов
   * @param pointId
   */
  public startDevelopment(routeId: number, revisions: DocumentRevisionModel[], pointId: number): Observable<Route> {
    const url = Urls.routeEndpoints.startDevelopment(routeId);
    return this.http.post<Route>(url, { revisions, pointId });
  }

  // // FIXME: МО-3320 выпилить как только актуализируется бэкенд на проде
  /** Вцыпилить, как только актуализируется бэк - оставить startDevelopment */
  public startDevelopmentOld(routeId: number, revisions: DocumentRevisionModel[]): Observable<Route> {
    const url = Urls.routeEndpoints.startDevelopment(routeId);
    return this.http.post<Route>(url, revisions);
  }

  /**
   * Отправить на согласование
   *
   * @param routeId идентификатор маршрута
   * @param body
   */
  public startApprovement(routeId: number, body: StartApprovementRequestModel): Observable<Route> {
    const url = Urls.routeEndpoints.startApprovement(routeId);
    return this.http.post<Route>(url, body);
  }

  /**
   * Отправить на согласование с замечаниями
   *
   * @param routeId идентификатор маршрута
   * @param body
   */
  public startApprovementWithRemarks(routeId: number, body: StartApprovementRequestModel): Observable<Route> {
    body.withRemarks = true;
    const url = Urls.routeEndpoints.startApprovement(routeId);
    return this.http.post<Route>(url, body);
  }

  /** Подтверждение ознакомления с ПД */
  public approveIntroduction(dpId: number, body: ApproveIntroductionRequestModel): Observable<Route> {
    const url = Urls.npaDocumentPackagesEndpoints.approveIntroduction(dpId);
    return this.http.post<Route>(url, body);
  }

  /** Подтверждение ознакомления с комментарием */
  public approveIntroductionWithComment(dpId: number, body: ApproveIntroductionRequestModel): Observable<Route> {
    const url = Urls.npaDocumentPackagesEndpoints.approveIntroductionWithComment(dpId);
    return this.http.post<Route>(url, body);
  }

  /** Добавление ознакомителя к переданной точке */
  public addIntroducer(routeId: number, pointId: number, body: AddIntroducerRequestModel): Observable<Route> {
    const url = Urls.routeEndpoints.addIntroducer(routeId, pointId);
    return this.http.put<Route>(url, body);
  }

  /** Возвращает сотрудника из БД для финального этапа */
  public getFinalUserData(): Observable<EmployeeBaseModel> {
    const url = Urls.npaDocumentPackageEndpoints.getFinalUserDataRoute();
    return this.http.get<EmployeeBaseModel>(url);
  }

  /** Получение информации о этапах внешнего согласования */
  public getOuterApprovementsInfo(routeId: number): Observable<SendToApprovementResponseModel[]> {
    const url = Urls.routeEndpoints.getOuterApprovementsInfo(routeId);
    return this.http
      .get<SendToApprovementResponseModel[]>(url)
      .pipe(map((response) => response.sort(sortPhasesByOrder)));
  }

  /** Отправить на внешнее согласование */
  public startOuterApprovement(routeId: number, body: SendToOuterApprovementRequestModel): Observable<Route> {
    const url = Urls.routeEndpoints.startOuterApprovement(routeId);
    return this.http.post<Route>(url, body);
  }

  /** Начать доработку для автора */
  public startRework(routeId: number, pointId: number): Observable<void> {
    let params = new HttpParams();
    params = params.set('pointId', pointId.toString());
    const url = Urls.routeEndpoints.startRework(routeId);
    return this.http.post<void>(url, null, { params });
  }

  /** Создать доработку для автора */
  public createRework(routeId: number, pointId: number): Observable<void> {
    let params = new HttpParams();
    params = params.set('pointId', pointId.toString());
    const url = Urls.routeEndpoints.createRework(routeId);
    return this.http.post<void>(url, null, { params });
  }
}
