import { Component } from '@angular/core';

import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-confirm-group-document',
  templateUrl: './confirm-group-document.component.html',
  styleUrls: ['./confirm-group-document.component.scss'],
})
export class ConfirmGroupDocumentComponent extends DialogBase {}
