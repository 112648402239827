export enum DictionaryTemplateTypes {
  // Направление
  heading = 1,
  // Резолюция
  resolution = 2,
  // Проект резолюции
  resolutionProject = 3,
  // Отметка об исполнении
  executionMark = 4,
  // Общее(по умолчанию)
  commonDefault = 5,
  // Запрос
  request = 6,
  // Переадресация
  forwarding = 7,
  // Проект запроса
  requestProject = 8,
  // Проект переадресации
  forwardingProject = 9,
}
