import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'searchMatch',
})
export class SearchMatchHighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(value: string, predicate: string): SafeHtml {
    const stringParts = predicate.split('#');
    let html = '';

    value = value
      ?.replace(/[<]/g, '&lt')
      .replace(/[>]/g, '&gt')
      .replace(/[.*+?^${}()|[\]\\]/g, '$&')
      .replace(/[\n\r]/g, ' ');

    const searchArgs = stringParts.map((item) =>
      item
        .trim()
        .replace(/[|\\{}()[\]^$+*?]/g, '\\$&')
        .replace(/-/g, '\\x2d'),
    );

    let matchIndex = -1;

    //Если в results больше одного элемента - после первого совпадения прекратить перезапись
    searchArgs.forEach((item, i) => {
      const regExp = new RegExp(item.toLowerCase());
      if ((regExp.test(value?.toLowerCase()) && item.length > 0) || searchArgs.length === 1) {
        if (matchIndex < 0) {
          matchIndex = i;
        } else {
          if (
            searchArgs[matchIndex].toLowerCase().includes(item.toLowerCase()) &&
            searchArgs[matchIndex].length > item.length
          ) {
            item = searchArgs[matchIndex];
            matchIndex = i;
          }
        }
        html = value?.replace(new RegExp(item, 'gi'), (val) => `<mark class="highlighted-text">${val}</mark>`);
      }
    });

    if (!!html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
