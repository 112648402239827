import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';

/** Сохранить меню ускоренного рассмотрения */
export class SetMenuExpeditedReview {
  public static readonly type = '[ExpeditedReview] Set Menu Expedited Review';
  constructor(public data: ExpeditedReviewModel[]) {}
}

/** Сбросить меню ускоренного рассмотрения */
export class ResetMenuExpeditedReview {
  public static readonly type = '[ExpeditedReview] Reset Menu Expedited Review';
}

/** Удалить карточку из реестра */
export class DeleteCardExpeditedReview {
  public static readonly type = '[ExpeditedReview] Delete Card From Menu Expedited Review';
  constructor(public id: number) {}
}

/** Удалить группу из реестра */
export class DeleteGroupExpeditedReview {
  public static readonly type = '[ExpeditedReview] Delete Group From Menu Expedited Review';
  constructor(public id: number) {}
}
