import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';

/** Компонент для отображения заголовка страницы в левом меню */
@Component({
  selector: 'app-title-page',
  templateUrl: './title-page.component.html',
  styleUrls: ['./title-page.component.scss'],
})
export class TitlePageComponent {
  @Input() public namePage = '';
  @Input() public notesEmployee = '';
  @Input() public userInfo!: SharedInfoAboutEmployee;
  /** Флаг работы за всех */
  @Input() public workForEveryone = false;
  @Input() public shownAcceptAllButton = false;
  @Input() public shownAcceptSelectedButton = false;

  @Output() public backPage = new EventEmitter<void>();
  @Output() public acceptAll = new EventEmitter<void>();
  @Output() public acceptSelected = new EventEmitter<void>();
}
