import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MainMenuTypeEnum } from '@enums/main-screen/main-menu-type.enum';
import { NavController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { SideBarBase } from '@oogShared/components/side-bar-base';
import { ApprovalListState } from '@store/approval-list-store/approval-list-store.state';
import { ResetRelevantWorkers } from '@store/main-screen/relevant-workers/relevant-workers.action';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetDocumentList, SetCountDocuments } from '@store/menu/count-documents-menu/count-documents-menu.action';
import { ResetDocumentsCards, SetDocumentsCardsFromSearch } from '@store/menu/documents-cards/documents-cards.action';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';
import { DocumentsCategoryState } from '@store/menu/documents-category/documents-category.state';
import {
  ResetSearch,
  SetFolderId,
  SetFolderName,
  SetSearch,
  SetShownFolders,
  SetTransitionFromDocumentsFolder,
  SetTransitionFromFolders,
} from '@store/menu/sidebar/sidebar.action';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';
import { ResetResolutionFiles } from '@store/resolution-store/resolution-files/resolution-files.action';
import { ResetResolutionTree } from '@store/resolution-store/resolution-tree/resolution-tree.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { SettingsState } from '@store/settings/settings.state';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActionFiltersEnum } from '@enums/action-filters.enum';
import { SidebarScrollService } from '@oogShared/services/sidebar/sidebar-scroll.service';
import { retrieveDocumentsInDepth } from '../../functions/document-folder/document-folder.function';
import { CardDocumentModel } from '../../models/documents/card-document.model';
import { MenuDocumentModel } from '../../models/documents/menu-document.model';
import { FiltersRequest } from '../../models/filter/filter-request.model';
import { PersonalFolderDocuments } from '../../models/folders/personal-folder-documents.model';
import { EmployeeModel } from '../../models/resolutions/employee/employee.model';
import { ResolutionAddresseeModel } from '../../models/resolutions/resolution/resolution-addressee.model';
import { FilterService } from '../../services/filter.service';
import { FoldersStateService } from '../../services/folders/folders-state.service';
import { MenuHelperService } from '../../services/menu-helper.service';
import { SwipeMenuService } from '../../services/swipe-menu.service';
import { routeNames } from '../../../../app-routing/route-names.const';

/** Компонент для отображения левого меню */
@UntilDestroy()
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent extends SideBarBase implements OnInit, AfterViewInit, OnDestroy {
  @Input() public namePage = '';
  @Input() public documentCards: CardDocumentModel[] = [];
  @Input() public disableButtons = false;

  @Output() public document = new EventEmitter<CardDocumentModel>();

  @Select(SettingsState.showButtonFamiliarization)
  public buttonFamiliarization$: Observable<boolean>;

  @Select(SidebarState.folderNameIfComeFromFolders)
  public folderName$: Observable<string>;

  @Select(SidebarState.shownFolders)
  public shownFolders$: Observable<boolean>;

  @Select(ResolutionState.getFamiliarizationPerformers)
  public familiarizationPerformers$!: Observable<ResolutionAddresseeModel[]>;

  @Select(ActiveCardState.canApproveResolutionProject)
  public canApproveResolutionProject$: Observable<boolean>;

  @Select(DocumentsCategoryState.getMenuCategory)
  public currentCategory$: Observable<MainMenuTypeEnum>;

  @Select(SidebarState.actionWithFilters)
  public actionWithFilters$: Observable<ActionFiltersEnum>;

  @Select(ActiveCardState.isChildCardInGroup)
  public isChildCardInGroup$: Observable<boolean>;

  @Select(SidebarState.searchValue)
  public search$: Observable<string>;

  @Select(ApprovalListState.canOnlyReturnApprovalList)
  public showOnlyReturnButton$: Observable<boolean>;

  @Select(SidebarState.isVisibleEmptyMessage)
  public isVisibleEmptyMessage$: Observable<boolean>;

  @ViewChildren('sideBarItem', { read: ElementRef }) public sideBarItems: QueryList<ElementRef>;

  public folders$ = this.foldersState.getFolders();
  public folderDocumentsLoader$ = this.menuHelper.isLoad$;
  public actionFilters = ActionFiltersEnum;
  public activeFilterStatuses = [ActionFiltersEnum.apply, ActionFiltersEnum.openFilters];
  public idActiveDocument = null;
  public isMatchHighlight = false;
  public matchedText = '';
  public authUser: EmployeeModel;
  public displaySearchBar = true;

  private firstEnter = true;

  constructor(
    store: Store,
    swipeMenu: SwipeMenuService,
    elementRef: ElementRef,
    public menuHelper: MenuHelperService,
    private navController: NavController,
    private filterService: FilterService,
    private authUserService: AuthUserIsproStoreService,
    private foldersState: FoldersStateService,
    private sidebarScroll: SidebarScrollService,
  ) {
    super(store, swipeMenu, elementRef);
  }

  public get transitionFromFolders(): boolean {
    return this.store.selectSnapshot(SidebarState.transitionFromFolders);
  }

  public get transitionToGroup(): boolean {
    return this.store.selectSnapshot(SidebarState.shownGroup);
  }

  public get isDirtyFormFilters(): boolean {
    return this.filterService.form?.dirty;
  }

  public ngOnInit(): void {
    this.subscribeToActiveCard();
    this.subscribeToFilterSubmit();
    this.subscribeToAuthUser();
    this.subscribeToToggleDisplayHeader();

    // При изменении строки поиска документов произвести запрос списка документов
    this.search$
      .pipe(
        skip(1), // Пропустить значение при инициализации компонента
        switchMap(() => {
          const request = this.filterService.prepareFilterData();
          return this.filterService.searchData(request);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public ngAfterViewInit(): void {
    this.subscribeToScrollToActiveElement();
  }

  public ngOnDestroy(): void {
    if (!this.transitionToGroup) {
      this.filterService.close();
      this.store.dispatch(ResetSearch);
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (!this.transitionToGroup) {
      this.resetStores();
    }
  }

  public previousPage(): void {
    if (this.transitionFromFolders) {
      this.store.dispatch(new SetShownFolders(true));
      return;
    }

    if (!this.transitionFromFolders) {
      this.navController.navigateBack([routeNames.mainScreen]).then();
      return;
    }
  }

  public searchByShortContent(text: string): void {
    this.store.dispatch([new SetSearch(text)]);
    this.matchedText = text;
    this.isMatchHighlight = !!this.matchedText;
  }

  public openFilters(showFilter: boolean): void {
    showFilter === true ? this.filterService.openFilters() : this.filterService.closeWithSaveFormValues();
  }

  public doRefresh(event: any): void {
    const filterData = this.filterService.prepareFilterData();
    this.transitionFromFolders
      ? this.refreshToFoldersList(filterData, event)
      : this.refreshToMenuList(filterData, event);
  }

  public loadData(event: any): void {
    const request = this.filterService.prepareFilterData();
    this.menuHelper.loadToMenuList(request, event);
  }

  private refreshToMenuList(filterData: FiltersRequest, event: any): void {
    this.menuHelper
      .refreshToMenuList(false, filterData)
      .pipe(
        first(),
        tap((res) => this.correctExistedActiveCard(res)),
      )
      .subscribe(
        (r) => this.setDataToStore(r, event),
        () => event.target.complete(),
      );
  }

  private refreshToFoldersList(filterData: FiltersRequest, event: any): void {
    const docs: PersonalFolderDocuments[] = [];
    retrieveDocumentsInDepth(this.foldersState.getFolderToViewSnapshot(), docs);
    this.menuHelper
      .refreshToFoldersList(docs, filterData)
      .pipe(first())
      .subscribe((r) => this.setDataToStore(r, event));
  }

  private subscribeToAuthUser(): void {
    this.authUserService
      .getCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((a) => (this.authUser = a));
  }

  /** Подписка на сабмит фильтра. Всегда скроллить к первой карточке */
  private subscribeToFilterSubmit(): void {
    this.menuHelper.scrollTop$.pipe(filter(Boolean), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.documentList?.scrollToTop(0).then();
    });
  }

  private subscribeToActiveCard(): void {
    this.store
      .select(ActiveCardState.getActiveCard)
      .pipe(
        filter((data) => !!data),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((card) => {
        this.idActiveDocument = card.uniqueId;
      });
  }

  private setDataToStore(data: MenuDocumentModel, event: any): void {
    this.store.dispatch(new SetDocumentsCardsFromSearch(data.cards));
    this.store.dispatch(new SetCountDocuments(data.totalCount));
    event.target.complete();
  }

  /** Обнуляет стор при дестрое компонента*/
  private resetStores(): void {
    if (this.transitionFromFolders) {
      this.store.dispatch([
        new SetShownFolders(true),
        new SetTransitionFromFolders(false),
        new SetFolderName(''),
        new SetTransitionFromDocumentsFolder(true),
        new SetCountDocuments(0),
        new SetFolderId(null),
        ResetDocumentList,
        ResetDocumentsCards,
      ]);
      return;
    }

    this.store.dispatch([
      ResetDocumentList,
      ResetDocumentsCards,
      ResetRelevantWorkers,
      ResetResolutionFiles,
      ResetResolutionTree,
    ]);
  }

  /** Подписка для установки флага отображения нужного блока в шапке*/
  private subscribeToToggleDisplayHeader(): void {
    combineLatest([this.menuHelper.isLoad$, this.countDocuments$, this.search$, this.actionWithFilters$])
      .pipe(untilDestroyed(this))
      .subscribe(([loading, countDocuments, search, actionWithFilters]) => {
        if (loading) {
          this.firstEnter = false;
        }
        this.displaySearchBar =
          this.firstEnter ||
          !!loading ||
          !!countDocuments ||
          !!search ||
          this.activeFilterStatuses.includes(actionWithFilters);
      });
  }

  /** Проверка наличия активной карточки в реестре
   *  если карточка отсутствует - устанавливает активной ближайшую по индексу в реестре
   *
   *  @param response - обновленный реестр*/
  private correctExistedActiveCard(response: MenuDocumentModel): void {
    if (!response?.cards.length || !response?.totalCount) {
      return;
    }
    const { cards, totalCount } = response;
    const currentCardIndex = this.store.selectSnapshot(DocumentsCardsState.currentDocument);

    if (!cards.find((card) => card.uniqueId === this.idActiveDocument.uniqueId)) {
      const newIndex = currentCardIndex > totalCount - 1 ? totalCount - 1 : currentCardIndex;
      this.document.emit(cards[newIndex]);
    }
  }

  /** Скролл до активного элемента
   *  при переходе с группового реестра
   */
  private subscribeToScrollToActiveElement(): void {
    this.sidebarScroll
      .onScroll$()
      .pipe(
        filter((activeCardIndex) => activeCardIndex > 0),
        untilDestroyed(this),
      )
      .subscribe((activeCardIndex) => {
        this.scrollToActiveCard(activeCardIndex);
        this.sidebarScroll.resetElement();
      });
  }

  private scrollToActiveCard(activeIndex: number): void {
    setTimeout(() => {
      const activeItem = this.sideBarItems.find((_, index) => index === activeIndex);
      if (!activeItem) {
        return;
      }

      const { offsetTop, clientHeight } = activeItem.nativeElement;
      this.documentList?.scrollToPoint(0, offsetTop - clientHeight);
    }, 300);
  }
}
