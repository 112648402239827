import { Injectable } from '@angular/core';
import { LoadStatusEnum } from '@enums/load-status.enum';
import { DocumentGroupStateModel } from '@models/sidebar/documents-group/documents-group.model';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import {
  DeleteCardFromGroup,
  ResetDocumentGroupState,
  ResetGroupCards,
  SetGroupCards,
  SetGroupCardStatus,
  SetViewedCardInGroup,
} from './documents-group.action';

const initialState: DocumentGroupStateModel = {
  cards: [],
  count: 0,
  status: null,
};

@Injectable()
@State<DocumentGroupStateModel>({
  name: 'documentsGroup',
  defaults: initialState,
})
export class DocumentsGroupState {
  @Selector()
  public static getGroupCards(state: DocumentGroupStateModel): CardDocumentModel[] {
    return state.cards;
  }

  @Selector()
  public static getMainCardInGroup(state: DocumentGroupStateModel): CardDocumentModel {
    return state.cards.find((c) => c.appealMovementId === c.groupProjects?.groupProjects?.mainAppealMovementId);
  }

  @Selector([ActiveCardState.getActiveCard])
  public static getActiveCardCount(state: DocumentGroupStateModel, activeCard: CardDocumentModel): number {
    return state.cards.findIndex((c) => c.uniqueId === activeCard.uniqueId) + 1;
  }

  @Selector([ActiveCardState.getActiveCard])
  public static getActiveCardIndex(state: DocumentGroupStateModel, activeCard: CardDocumentModel): number {
    return state.cards.findIndex((c) => c.uniqueId === activeCard.uniqueId);
  }

  @Selector()
  public static countDocuments(state: DocumentGroupStateModel): number {
    return state.count;
  }

  @Selector()
  public static getStatus(state: DocumentGroupStateModel): LoadStatusEnum {
    return state.status;
  }

  @Action(SetGroupCards)
  public setGroupCards({ patchState }: StateContext<DocumentGroupStateModel>, { cards }: SetGroupCards): void {
    patchState({ cards, count: cards.length });
  }

  @Action(ResetGroupCards)
  public resetGroupCards({ setState }: StateContext<DocumentGroupStateModel>): void {
    setState({ cards: [], count: 0, status: null });
  }

  @Action(DeleteCardFromGroup)
  public deleteCardFromGroup(
    { getState, setState }: StateContext<DocumentGroupStateModel>,
    { id }: DeleteCardFromGroup,
  ): void {
    const cards = getState().cards.filter((c) => c.uniqueId !== id);
    setState({ cards, count: cards.length, status: getState().status });
  }

  @Action(SetViewedCardInGroup)
  public setViewedCardInGroup(
    { getState, patchState }: StateContext<DocumentGroupStateModel>,
    { id }: SetViewedCardInGroup,
  ): void {
    const cards = [...getState().cards];
    const viewedCards = cards.filter((c) => c.uniqueId === id);

    cards.forEach((card, index) => {
      if (viewedCards.includes(card)) {
        cards[index] = {
          ...card,
          isNew: false,
        };
      }
    });

    patchState({ cards });
  }

  @Action(SetGroupCardStatus)
  public setGroupCardStatus(
    { patchState }: StateContext<DocumentGroupStateModel>,
    { status }: SetGroupCardStatus,
  ): void {
    patchState({ status });
  }

  @Action(ResetDocumentGroupState)
  public resetDocumentGroupState({ setState }: StateContext<DocumentGroupStateModel>): void {
    setState(initialState);
  }
}
