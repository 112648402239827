import { CertificatesSettingsModel } from '@models/settings/certificates-settings.model';
import { SettingsResponseModel } from '@models/settings/settings-response/settings-response.model';

/** Сохранить настройки */
export class SetSettings {
  public static readonly type = '[Settings] Set Settings';
  constructor(public settingsForm: SettingsResponseModel, public acceleratedRight: boolean) {}
}

/** Сохранить настройки сертификатов */
export class SetSettingsCertificates {
  public static readonly type = '[Settings] Set Settings certificates';
  constructor(public settingsFormCertificate: CertificatesSettingsModel) {}
}

/** Сбросить настройку ускоренного рассмотрения */
export class ResetSettingsAcceleratedReview {
  public static readonly type = '[Settings] Reset Settings Accelerated Review';
}
