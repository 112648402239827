import { Component } from '@angular/core';

import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-confirm-redirect-project',
  templateUrl: './confirm-redirect-project.component.html',
  styleUrls: ['./confirm-redirect-project.component.scss'],
})
export class ConfirmRedirectProjectComponent extends DialogBase {}
