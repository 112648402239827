import { Injectable } from '@angular/core';

import { State } from '@ngxs/store';
import { DictionariesAllUrgencyState } from '@store/dictionaries/dictionaries-all-urgency/dictionaries-all-urgency.state';
import { DictionariesHotEmployeeState } from '@store/dictionaries/dictionaries-hot-employee/dictionaries-hot-employee.state';
import { DictionariesSpecialEmployeeState } from '@store/dictionaries/dictionaries-special-employee/dictionaries-special-employee.state';
import { DictionariesTemplateState } from '@store/dictionaries/dictionaries-templates/dictionaries-template.state';
import { DictionariesUrgencyState } from '@store/dictionaries/dictionaries-urgency/dictionaries-urgency.state';
import { DictionariesWeekendsState } from '@store/dictionaries/dictionaries-weekends/dictionaries-weekends.state';

@Injectable()
@State<[]>({
  name: 'dictionaries',
  children: [
    DictionariesUrgencyState,
    DictionariesTemplateState,
    DictionariesHotEmployeeState,
    DictionariesAllUrgencyState,
    DictionariesWeekendsState,
    DictionariesSpecialEmployeeState,
  ],
})
export class DictionariesState {}
