import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { UrgencyModel } from '../../../../oog/shared/models/dictionaries/urgency.model';
import { DictionariesInteropService } from '../../../../oog/shared/rest/dictionaries-interop.service';
import { SetDictionariesUrgency } from './dictionaries-urgency.action';

@Injectable()
@State<UrgencyModel[]>({
  name: 'dictionariesUrgency',
  defaults: [],
})
export class DictionariesUrgencyState {
  constructor(private dictionariesUrgency: DictionariesInteropService) {}

  @Selector()
  public static urgencyList(state: UrgencyModel[]): UrgencyModel[] {
    return state;
  }

  @Action(SetDictionariesUrgency)
  public setDictionariesUrgency({ getState, setState }: StateContext<UrgencyModel[]>): void {
    if (getState().length) {
      return;
    }
    this.dictionariesUrgency.getDictionariesUrgency().pipe(first()).subscribe(setState);
  }
}
