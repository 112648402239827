import { Injectable, OnDestroy } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ConfirmSignComponent } from '@oogShared/components/confirm/confirm-sign/confirm-sign.component';
import { ApprovalSignFormDataModel } from '@oogShared/models/approval-modals/approval-sign-form-data.model';
import { OogEsignInteropService } from '@oogShared/rest/oog-esign-interop.service';
import { EsignOogService } from '@oogShared/services/esign-oog.service';

import { ModalService } from '@shared/services/modal.service';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { SettingsState } from '@store/settings/settings.state';
import { of, Subject } from 'rxjs';
import { catchError, filter, first, takeUntil } from 'rxjs/operators';

import { SettingsCertificateService } from '@shared/services/certificates/settings-certificate.service';
import { ProjectEnum } from '@enums/project.enum';
import { modalIdApprovalActions, modalIdCreateResolution } from '@const/modals-id.const';
import { GroupDocumentsService } from '@oogShared/services/group-documents.service';
import { DocumentsGroupState } from '@store/menu/documents-group/documents-group.state';
import { ApprovalSignService } from '../../documents-page/approval-list/components/approval-modal-sign/approval-sign.service';
import { ConfirmApprovalComponent } from '../components/confirm/confirm-approval/confirm-approval.component';
import { ConfirmFamiliarizationComponent } from '../components/confirm/confirm-familiarization/confirm-familiarization.component';
import { ApprovalModalType } from '../consts/approval-modal-type.const';
import { modalText } from '../consts/modal-text.const';
import { ApprovalActionBtnTypeEnum } from '../enums/approval-list/approval-action-btn-type.enum';
import { ApprovalHelperService } from './approval-helper.service';
import { ConfirmDialogService } from './confirm-dialog.service';
import { FamiliarizationResolutionService } from './familiarization/familiarization-resolution.service';

@Injectable({
  providedIn: 'root',
})
export class SwipeMenuService implements OnDestroy {
  /** отписка */
  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private dialog: ConfirmDialogService,
    private familiarizationService: FamiliarizationResolutionService,
    private currentUser: CurrentUserIsproStoreService,
    private modalCtrl: ModalController,
    private approvalHelper: ApprovalHelperService,
    private settingsCertificateService: SettingsCertificateService,
    private esignService: EsignOogService,
    private esignApi: OogEsignInteropService,
    private modalService: ModalService,
    private approvalSignService: ApprovalSignService,
    private groupDocuments: GroupDocumentsService,
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Показать диалоговое окно при ознакомлении с проектом */
  public showFamiliarizationDialog(project: boolean): void {
    const notificationControl = this.store.selectSnapshot(SettingsState.notificationValidDoc);
    if (!notificationControl) {
      this.familiarizationService.familiarization();
      return;
    }
    const user = this.currentUser.getCurrentUserSnapshot();

    let familiarizationResolutionText =
      this.store.selectSnapshot(ResolutionState.addresseeId(user.id)).length > 1
        ? modalText.familiarizationResolutionAssignaments
        : modalText.familiarizationResolution;

    if (
      this.groupDocuments.isGroupDocument() &&
      this.groupDocuments.isGroupDocumentActive() &&
      !this.store.selectSnapshot(ActiveCardState.isMainCardInGroup)
    ) {
      this.openConfirmDialog(familiarizationResolutionText, project);
      return;
    }

    if (
      this.groupDocuments.isGroupDocument() &&
      this.groupDocuments.isGroupDocumentActive() &&
      this.store.selectSnapshot(ActiveCardState.isMainCardInGroup)
    ) {
      this.groupDocuments.groupViewFlags().subscribe((viewed) => {
        const data = this.store.selectSnapshot(DocumentsGroupState.getGroupCards);
        const filtered = viewed.filter((i) => data.find((d) => d.mainId === i.resolutionId) && !i.isRead);

        familiarizationResolutionText = filtered.length
          ? `${modalText.skippedDocuments} ${familiarizationResolutionText}`
          : familiarizationResolutionText;
        this.openConfirmDialog(familiarizationResolutionText, project);
      });
    } else {
      this.openConfirmDialog(familiarizationResolutionText, project);
    }
  }

  /** Показать модальное окно для создания резолюции */
  public showModalCreateResolution(component: any): void {
    this.modalCtrl
      .create({
        component,
        cssClass: 'modal-fullscreen',
        id: modalIdCreateResolution,
      })
      .then((modal) => modal.present());
  }

  /** Показать модальное окно для перенаправления резолюции/СПР */
  public showModalRedirectResolution(component: any): void {
    this.modalCtrl
      .create({
        component,
        cssClass: 'modal-fullscreen',
      })
      .then((modal) => modal.present());
  }

  /** Показать модальное окно с действиями ЛС */
  public showModalActionApproval(actionType: ApprovalActionBtnTypeEnum): void {
    this.modalCtrl
      .create({
        component: ApprovalModalType[actionType],
        cssClass: 'modal-fullscreen',
        id: modalIdApprovalActions,
        componentProps: {
          approvalData: this.approvalHelper.approvalData,
        },
      })
      .then((modal) => modal.present());
  }

  /** Показать диалоговое окно с подтверждением согласования */
  public showCoordinateApproval(): void {
    const notificationControl = this.store.selectSnapshot(SettingsState.notificationValidDoc);
    if (!notificationControl) {
      this.approvalHelper.coordinateSwipe$.next();
      return;
    }
    const number = this.store.selectSnapshot(ActiveCardState.numberResolution);
    const date = this.store.selectSnapshot(ActiveCardState.dateResolution);
    this.dialog
      .show(ConfirmApprovalComponent, 'app-approval-list', { docNumber: number, date })
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() => this.approvalHelper.coordinateSwipe$.next());
  }

  public sign(): void {
    const confirm = this.store.selectSnapshot(SettingsState.certificateSettingsOog).singingWithoutConfirmationOog;
    const notificationControl = this.store.selectSnapshot(SettingsState.notificationValidDoc);

    if (!confirm) {
      this.showModalActionApproval(ApprovalActionBtnTypeEnum.approveSign);
      return;
    }

    if (notificationControl && confirm) {
      this.dialog
        .show(ConfirmSignComponent, 'app-approval-list', {
          docNumber: this.approvalHelper.approvalData.number,
          date: this.approvalHelper.approvalData.creationDate,
        })
        .pipe(
          first(),
          filter((r) => !!r),
        )
        .subscribe(() => this.signingWithoutConfirmation());
      return;
    }

    this.signingWithoutConfirmation();
  }

  private signingWithoutConfirmation(): void {
    const defaultCertificate = this.store.selectSnapshot(SettingsState.certificateSettingsOog).defaultCertificateOog;

    if (this.esignService.isInvalidCertificateSigningApprovalList()) {
      this.esignService.showModalInvalidCertificate();
      return;
    }

    if (!defaultCertificate) {
      this.modalService.presentTemplateModal({
        text: 'Подписание недоступно, необходимо добавить сертификат ЭП',
        buttonLabel: 'Закрыть',
      });
      return;
    }

    if (!defaultCertificate.isCloud) {
      this.approvalSignService.signLocal(this.approvalHelper.approvalData.id, defaultCertificate);
      return;
    }

    const data: ApprovalSignFormDataModel = {
      cert: defaultCertificate,
      agreed: true,
      text: '',
    };

    this.esignApi
      .getCerts()
      .pipe(
        catchError(() => {
          this.modalService.presentModalError('Нет облачных сертификатов');
          return of([]);
        }),
        filter((certs) => !!certs.length),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => this.approvalSignService.signCloud(this.approvalHelper.approvalData, data));
  }

  private openConfirmDialog(familiarizationResolutionText: string, project: boolean): void {
    const number = this.store.selectSnapshot(ActiveCardState.numberResolution);
    const date = this.store.selectSnapshot(ActiveCardState.dateResolution);
    const canSign = this.settingsCertificateService.getSigningResolutions(ProjectEnum.oog);

    this.dialog
      .show(ConfirmFamiliarizationComponent, project ? 'app-project-resolution' : 'app-resolution', {
        title: familiarizationResolutionText,
        number,
        date,
        canSign,
      })
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() => this.familiarizationService.familiarization());
  }
}
