import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingController } from '@ionic/angular';
import { InterceptorHttpParams } from './interceptor-http-params.model';

/**
 * Перехватчик http запросов, показывает лоадер
 */
@Injectable()
export class HttpInterceptorLoaderService implements HttpInterceptor {
  constructor(private loadingController: LoadingController) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isDone = false;
    if (!(req.params instanceof InterceptorHttpParams)) {
      return next.handle(req);
    }

    const isNoPreloaderRequest = req.params.interceptorConfig.isShowPreloaderAnimation;

    if (isNoPreloaderRequest) {
      const message = req.params.interceptorConfig.message;
      this.loadingController.create({ message }).then((loader) => {
        loader.present();

        if (isDone) {
          loader.dismiss();
          isDone = false;
        }
      });
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (isNoPreloaderRequest) {
          isDone = true;
          this.loadingController.dismiss();
        }
      }),
    );
  }
}
