import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AudioFormService } from '../../../../document-forms/audio-form/services/audio-form.service';

@UntilDestroy()
@Component({
  selector: 'app-audio-and-graphic-action',
  templateUrl: './audio-and-graphic-action.component.html',
  styleUrls: ['./audio-and-graphic-action.component.scss'],
})
export class AudioAndGraphicActionComponent implements OnInit {
  @Input() public picture = false;
  @Input() public form: FormGroup = new FormGroup({});
  @Input() public showAudio = false;
  @Input() public showGraphic = false;

  @Output() public createPicture = new EventEmitter<void>();
  @Output() public editPicture = new EventEmitter<void>();
  @Output() public deletePicture = new EventEmitter<void>();

  public isMobile = false;
  public showAudioUi = false;

  constructor(private platform: Platform, private audioFormService: AudioFormService) {}

  public ngOnInit(): void {
    if (this.platform.is('ipad')) {
      this.isMobile = true;
      this.subscribeToAudioStoreChanges();
    }
  }

  public startRecord(): void {
    this.showAudioUi = !this.showAudioUi;
  }

  private subscribeToAudioStoreChanges(): void {
    this.audioFormService.blobAudio$.pipe(untilDestroyed(this)).subscribe((audio) => {
      if (audio) {
        this.showAudioUi = true;
      }
    });
  }
}
