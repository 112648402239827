import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EmployeeModel } from '../../models/resolutions/employee/employee.model';

@Component({
  selector: 'app-hot-list-employee',
  templateUrl: './hot-list-employee.component.html',
  styleUrls: ['./hot-list-employee.component.scss'],
})
export class HotListEmployeeComponent {
  @Input() public listEmployee: EmployeeModel[] = [];

  @Output() public chooseUser: EventEmitter<EmployeeModel> = new EventEmitter<EmployeeModel>();
}
