import { Component, Input } from '@angular/core';

import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';

@Component({
  selector: 'app-resolution-history',
  templateUrl: './resolution-history.component.html',
  styleUrls: ['./resolution-history.component.scss'],
})

/** Компонент-оболочка для дерева вышестоящих проектов */
export class ResolutionHistoryComponent {
  @Input() public resolution: ResolutionModel;
  @Input() public tree: ResolutionModel[] = [];

  public isResolutionsCollapsed = true;

  public get treePreview(): ResolutionModel[] {
    if (this.isResolutionsCollapsed) {
      if (this.resolution.type.id === ResolutionTypeEnum.resolutionProject) {
        return [this.tree[this.tree.length - 1]];
      }
      return [this.tree.find((item) => item.id === this.resolution.id)];
    }
    return this.tree.filter((item) => item.type.id !== ResolutionTypeEnum.projectResolution);
  }
}
