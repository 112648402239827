import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { BaseControl } from '@components/controls/control-base';
import { ReportsFolderModel } from '@oogShared/models/reports/reports-folder.model';
import { DepartmentModel } from '@oogShared/models/resolutions/department/department.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

@Directive()
export abstract class SelectBase extends BaseControl {
  @Input() public showClearButton = false;
  @Input() public controlStatus = false;
  @Input() public showValueOnInit = false;
  @Input() public abstract options: EmployeeModel[] | DepartmentModel[] | ReportsFolderModel[];
  @Input() public showOptions = false;

  @Output() public valueSearch = new EventEmitter<string>();
  @Output() public focusField = new EventEmitter<HTMLElement>();

  @ViewChild('inputControl', { static: true }) public inputControl: ElementRef;

  public filterInput = '';
  public activeOption = false;
  public showValue = '';

  constructor(private elementRef: ElementRef) {
    super();
  }

  @HostListener('document:click', ['$event.target'])
  public hideSelect(event: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(event)) {
      this.activeOption = false;
    }
  }

  /** Показать дропдаун */
  public openDropdown(): void {
    if (!this.activeOption) {
      this.focusField.emit(this.inputControl.nativeElement);
      if (!this.filterInput && !this.showOptions) {
        this.options = [];
      }
      // нужен для установки фокуса
      setTimeout(() => {
        this.inputControl.nativeElement.focus();
      }, 0);
    }

    this.activeOption = !this.activeOption;
  }

  /** Переключает видимость дропдауна */
  public toggleOptions(): void {
    if (this.parentFormGroup.controls[this.controlName].disabled) {
      return;
    }

    this.openDropdown();
  }

  public clearControl(): void {
    this.parentFormGroup.controls[this.controlName].reset();
  }

  /** Установить значение контрола */
  protected setControlValue(value: any): void {
    const control = this.parentFormGroup.controls[this.controlName];
    control.setValue(value);
    control.markAsDirty();
    control.markAsTouched();
  }
}
