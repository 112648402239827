import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { DocumentCardTypeEnum } from '@oogShared/enums/side-bar/document-card-type.enum';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';

@Component({
  selector: 'app-clone-performer',
  templateUrl: './clone-performer.component.html',
  styleUrls: ['./clone-performer.component.scss'],
})
export class ClonePerformerComponent implements OnInit {
  @Input() public performer = new FormGroup({});
  @Input() public controlName = 'performer';
  @Input() public isReviewer = false;

  @Output() public delete = new EventEmitter<void>();
  @Output() public chooseOther = new EventEmitter<void>();

  public cardType = 'resolution';
  public message = 'В поручении дублируется исполнитель';

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.cardType = this.store.selectSnapshot(ActiveCardState.cardType);

    if (this.cardType === DocumentCardTypeEnum.approval) {
      this.message = 'В согласовании дублируется исполнитель';
    }

    if (this.isReviewer) {
      this.message = 'В поручении дублируется ознакомитель';
    }
  }
}
