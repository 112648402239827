import {
  CertificatesSettingsFormModel,
  DocumentsSettingsFormModel,
  FoldersSettingsFormModel,
  FormsModel,
  NotificationFormModel,
  ReportsSettingsFormModel,
  SubstituteFormModel,
} from '@models/settings/settings-form-data.model';

const defaultFormDataConst: FormsModel = {
  dirty: false,
  status: '',
};

const settingsDataFormConst: NotificationFormModel = {
  ...defaultFormDataConst,
  model: {
    notification: false,
    notificationUrgentResolution: {
      push: false,
      standard: false,
    },
    notificationUrgentProject: {
      push: false,
      standard: false,
    },
    notificationUrgentDocument: {
      push: false,
      standard: false,
      time: new Date(),
    },
    notificationControl: {
      push: false,
    },
    notificationValidDoc: {
      standard: false,
    },
    notificationHide: {
      time: null,
    },
    notificationErrorMessage: {
      push: false,
      standard: false,
    },
    notificationSystemMessage: {
      push: false,
      standard: false,
    },
  },
};

const documentsDataFormConst: DocumentsSettingsFormModel = {
  ...defaultFormDataConst,
  model: {
    showDocumentReviewed: false,
    showCommentsFullyOpened: false,
    mainCommentInCardFullyOpened: false,
    commentInApprovalBranchFullyOpened: false,
    commentsInDossierAndDocumentBlocksFullyOpened: false,
    showPerformerListFullyOpened: false,
    showAcceleratedReviewCategory: false,
    executionPeriodInheritance: true,
    minimumDueDateAutoPreFill: false,
    reduceExecutionPeriod: 2,
    copyAllResolutionMissionBlocks: false,
    choiceActionOnCopyMission: false,
    controlTypeInheritance: false,
    recordAudioResolution: true,
    paintGraphicResolution: true,
    typeTextResolution: true,
  },
};

const substituteDataFormConst: SubstituteFormModel = {
  ...defaultFormDataConst,
  model: {
    showSubLeader: false,
    showSubName: false,
    autoCorrectSubActingName: false,
  },
};

const foldersDataFormConst: FoldersSettingsFormModel = {
  ...defaultFormDataConst,
  model: {
    editPersonalFolder: false,
    showPersonalFolder: false,
  },
};

const reportsDataFormConst: ReportsSettingsFormModel = {
  ...defaultFormDataConst,
  model: {
    showReportsButton: false,
  },
};

const certificatesDataFormConst: CertificatesSettingsFormModel = {
  ...defaultFormDataConst,
  model: {
    oog: {
      singingWithoutConfirmationOog: false,
      defaultCertificateOog: null,
      agreedOog: false,
      signingResolutionOog: true,
    },
    npa: {
      singingWithoutConfirmationNpa: false,
      defaultCertificateNpa: null,
      agreedNpa: false,
    },
  },
};

export const defaultSettingsFormState = {
  notification: settingsDataFormConst,
  document: documentsDataFormConst,
  substitute: substituteDataFormConst,
  folders: foldersDataFormConst,
  reports: reportsDataFormConst,
  certificates: certificatesDataFormConst,
};
