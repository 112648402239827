import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { BookmarksModel } from '@oogShared/models/bookmarks/bookmarks.model';
import { BookmarkInteropService } from '@oogShared/rest/bookmark.interop.service';
import { RemoveBookmark } from '@store/resolution-store/bookmarks/bookmarks.action';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-bookmark-delete',
  templateUrl: './bookmark-delete.component.html',
  styleUrls: ['./bookmark-delete.component.scss'],
})
/** Компонент с модальным окном для удаления закладки */
export class BookmarkDeleteComponent {
  @Input() public bookmark: BookmarksModel;

  constructor(
    private modalCtrl: ModalController,
    private bookmarkInterop: BookmarkInteropService,
    private store: Store,
  ) {}

  public delete(): void {
    this.bookmarkInterop
      .delete(this.bookmark.id)
      .pipe(first())
      .subscribe(() => {
        this.store.dispatch(new RemoveBookmark(this.bookmark.id));
        this.modalCtrl.dismiss();
      });
  }

  public cancel(): void {
    this.modalCtrl.dismiss();
  }
}
