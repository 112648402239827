import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ResetWorkForEveryone,
  SetWorkForEveryone,
} from '@store/main-screen/work-for-everyone/work-for-everyone.action';

@Injectable()
@State<boolean>({
  name: 'workForEveryone',
  defaults: false,
})
export class WorkForEveryoneState {
  @Selector()
  public static workForEveryone(state: boolean): boolean {
    return state;
  }

  @Action(SetWorkForEveryone)
  public setWorkForEveryone({ setState }: StateContext<boolean>, { value }: SetWorkForEveryone): void {
    setState(value);
  }

  @Action(ResetWorkForEveryone)
  public resetWorkForEveryone({ setState }: StateContext<boolean>): void {
    setState(false);
  }
}
