import { Injectable } from '@angular/core';
import { FCMNotificationTypeEnum } from '@enums/fcm-notification-type.enum';
import { logToSentry } from '@shared/functions/set-users-for-sentry.function';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { LoggerService } from './logger.service';

@Injectable({ providedIn: 'root' })
export class FCMNotificationService {
  private fcmNotificationStream = new Subject<PushNotificationSchema>();

  constructor(private logger: LoggerService) {}

  public fcmSubscribe(): void {
    PushNotifications.addListener('registration', (token: Token) => {
      localStorage.setItem('token', JSON.stringify(token));
    });

    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      logToSentry(`fcm-${notification}`, notification);
      this.logger.debugLog('fcm onNotification event data:', notification);
      this.fcmNotificationStream.next(notification);
    });
  }

  public getFcmEventsFilteredByTypes(...types: FCMNotificationTypeEnum[]): Observable<PushNotificationSchema> {
    this.logger.debugLog('subscribe on fcmNotificationStream with type: ', types);

    return this.fcmNotificationStream.pipe(
      tap((data) => this.logger.debugLog('fcmNotificationStream data: ', data)),
      filter((data) => !data?.data?.wasTapped),
      filter((data) => types.includes(data?.data?.type)),
    );
  }
}
