import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { ResetResolutionTree, SetResolutionTree } from '@store/resolution-store/resolution-tree/resolution-tree.action';

@Injectable()
@State<ResolutionModel[]>({
  name: 'resolutionTree',
  defaults: [],
})
export class ResolutionTreeState {
  @Selector()
  public static getResolutionTree(state: ResolutionModel[]): ResolutionModel[] {
    return state;
  }

  @Action(SetResolutionTree)
  public setResolutionTree({ setState }: StateContext<ResolutionModel[]>, { tree }: SetResolutionTree): void {
    setState(tree);
  }

  @Action(ResetResolutionTree)
  public resetResolutionTree({ setState }: StateContext<ResolutionModel[]>): void {
    setState([]);
  }
}
