import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { ResolutionAttributeNames } from '@oogShared/enums/resolution/resolution-attribute-name.enum';
import { SignificantPropsNames } from '@oogShared/enums/resolution/resolution-e-sign.enum';
import { ResolutionTypeModel } from '@oogShared/models/resolutions/resolution/resolution-type.model';
import { combinedResolutionTypeIdsConst } from '@oogShared/consts/combined-resolution-type-ids.const';

export const reasonsInvalidESign = {
  [SignificantPropsNames.AuthorId]: 'Несоответствие автора и владельца сертификата ЭП',
  [SignificantPropsNames.MissionsLength]: 'Несоответствие подписанных данных содержанию полей БД',
  [SignificantPropsNames.Comments]: 'Несоответствие подписанных данных содержанию полей БД',
  [SignificantPropsNames.AddresseeIds]: 'Несоответствие подписанных данных содержанию полей БД',
  [SignificantPropsNames.ExecutionComment]: 'Несоответствие подписанных данных содержанию полей БД',
  [SignificantPropsNames.Executor]: 'Несоответствие автора и владельца сертификата ЭП',
};

export const eSignBrokenFieldsNames = {
  direction: {
    [SignificantPropsNames.AuthorId]: 'Автор',
    [SignificantPropsNames.MissionsLength]: 'Количество блоков',
    [SignificantPropsNames.Comments]: 'Комментарий',
    [SignificantPropsNames.AddresseeIds]: 'Кому',
  },
  resolutionLike: {
    [SignificantPropsNames.AuthorId]: 'Автор',
    [SignificantPropsNames.MissionsLength]: 'Количество блоков',
    [SignificantPropsNames.Comments]: 'Поручение',
    [SignificantPropsNames.AddresseeIds]: 'Исполнитель',
  },
  projectLike: {
    [SignificantPropsNames.AuthorId]: 'От кого',
    [SignificantPropsNames.MissionsLength]: 'Количество блоков',
    [SignificantPropsNames.Comments]: 'Комментарий',
    [SignificantPropsNames.AddresseeIds]: 'Кому',
  },
  executeResolution: {
    [SignificantPropsNames.Executor]: 'Автор',
    [SignificantPropsNames.ExecutionComment]: 'Описание исполнения',
  },
};

export const eSignDssFieldsNames = {
  direction: {
    [ResolutionAttributeNames.Author]: 'Автор',
    [ResolutionAttributeNames.Addressee]: 'Кому',
    [ResolutionAttributeNames.PrivateComment]: 'Комментарий',
  },
  resolutionLike: {
    [ResolutionAttributeNames.Author]: 'Автор',
    [ResolutionAttributeNames.Addressee]: 'Исполнитель',
    [ResolutionAttributeNames.PrivateComment]: 'Поручение',
  },
  projectLike: {
    [ResolutionAttributeNames.Author]: 'От кого',
    [ResolutionAttributeNames.Addressee]: 'Кому',
    [ResolutionAttributeNames.PrivateComment]: 'Комментарий',
  },
};

export const getESignBrokenFieldsNames = (type: ResolutionTypeModel): { [key: string]: string } => {
  if (type.id === ResolutionTypeEnum.resolution) {
    return eSignBrokenFieldsNames.resolutionLike;
  } else if (type.id === ResolutionTypeEnum.projectResolution) {
    return eSignBrokenFieldsNames.projectLike;
  }
  return eSignBrokenFieldsNames.direction;
};

export const getESignDssFieldsNames = (type: ResolutionTypeModel): { [key: string]: string } => {
  const isTypeResolution = combinedResolutionTypeIdsConst.resolutionLikeTypeIds.includes(type.id);
  const isTypeProjectResolution = combinedResolutionTypeIdsConst.projectResolutionLikeTypeIds.includes(type.id);

  if (isTypeResolution) {
    return eSignDssFieldsNames.resolutionLike;
  } else if (isTypeProjectResolution) {
    return eSignDssFieldsNames.projectLike;
  }
  return eSignDssFieldsNames.direction;
};
