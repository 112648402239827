import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { DocumentPackageApproveModel } from '@npaShared/models/document-package/document-package-approve.model';
import { DocumentPackageStoreModel } from '@npaShared/models/document-package/document-package-store.model';
import { EmployeeBaseModel } from '@npaShared/models/employee/employee-base.model';
import { ModalReturnConfirmationModel } from '@npaShared/models/modal/modal-return-confirmation.model';
import { Route } from '@npaShared/models/route/route.models';
import { Observable } from 'rxjs';
import { ParamsForPreparationSigning } from '../agreement-sign.service';
import { AgreementService } from '../../../../document-agreement/services/agreement.service';
import { BaseAgreements } from './base-agreements';

export class AgreementSendToRemarksToAuthor implements BaseAgreements {
  constructor(protected agreementService: AgreementService) {}

  public getRequest(
    params: ParamsForPreparationSigning,
    paramsForRequest: DocumentPackageApproveModel,
  ): Observable<Route> {
    return this.agreementService.routeExternalReturn(params.documentPackage.route.route.id, paramsForRequest);
  }

  public getParamsForModalConfirmation(
    selectedDocumentPackage: DocumentPackageStoreModel,
    description: string,
    author: EmployeeBaseModel,
  ): ModalReturnConfirmationModel {
    const fio = `${author.lastName} ${author.firstName} ${author.patronymic}`;
    const jobPosition = author.jobPosition ? author.jobPosition.name : '';

    return {
      approveType: ApproveTypeEnum.sendRemarksToAuthor,
      documentPackageName: selectedDocumentPackage.documentPackage.documentPackageName,
      packageNumber: selectedDocumentPackage.documentPackageAdditionalInfo.packageNumber,
      redirectToFio: `${fio}, ${jobPosition}`,
      description,
    };
  }
}
