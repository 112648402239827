import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { defaultValueUrgency } from '@oogShared/consts/default-value-urgency.const';
import { changeUserSession } from '@oogShared/functions/change-user-session.function';
import { returnAuthUserSession } from '@oogShared/functions/return-user-session.function';
import { ApprovalBlockModel } from '@oogShared/models/approval-list/approval-block.model';
import { ApprovalParticipantModel } from '@oogShared/models/approval-list/approval-participant.model';
import { UrgencyModel } from '@oogShared/models/dictionaries/urgency.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { UrgencyReviewTypeModel } from '@oogShared/models/resolutions/urgency-review-type.model';
import { ApprovalHelperService } from '@oogShared/services/approval-helper.service';
import { DischargeDutiesService } from '@oogShared/services/discharge-of-duties/discharge-duties.service';
import { DateHelperService } from '@shared/services/date-helper.service';
import { DictionariesHotEmployeeState } from '@store/dictionaries/dictionaries-hot-employee/dictionaries-hot-employee.state';
import { DictionariesUrgencyState } from '@store/dictionaries/dictionaries-urgency/dictionaries-urgency.state';

import { DocumentNavigationService } from '@oogShared/services/document-navigation.service';
import { DocumentTypeEnum } from '@npaShared/enums/document-type.enum';
import { ApprovalModalBase } from '../approval-modal-base/approval-modal-base';

/** Страница для перенаправления листа согласования */
@Component({
  selector: 'app-approval-modal-redirect',
  templateUrl: './approval-modal-redirect.component.html',
  styleUrls: ['./approval-modal-redirect.component.scss'],
})
export class ApprovalModalRedirectComponent extends ApprovalModalBase implements OnInit {
  @Select(DictionariesUrgencyState.urgencyList)
  public dictionariesUrgency$!: Observable<UrgencyModel[]>;

  @Select(DictionariesHotEmployeeState.getHotListEmployee)
  public hotListEmployee$!: Observable<EmployeeModel[]>;

  public form: FormGroup = new FormGroup({});
  public countDays = 0;
  public showChooseAddressee = false;
  public readonly documentTypeEnum = DocumentTypeEnum;

  constructor(
    currentUser: CurrentUserIsproStoreService,
    store: Store,
    approvalHelperService: ApprovalHelperService,
    modalCtrl: ModalController,
    private fb: FormBuilder,
    private dutiesService: DischargeDutiesService,
    private authUser: AuthUserIsproStoreService,
    private dateHelper: DateHelperService,
    private documentNavigationService: DocumentNavigationService,
  ) {
    super(currentUser, modalCtrl, approvalHelperService, store);
  }

  public get disabledRedirectButton(): boolean {
    const addressees = this.form.controls.addressees as FormArray;
    return this.form.invalid || addressees.value.some((addr) => addr.addressee.disabledByWhiteList);
  }

  public ngOnInit(): void {
    changeUserSession(this.store, this.currentUser);
    super.ngOnInit();
    this.headerData.title = 'Перенаправление';
    this.initForm();
    super.getPreviousComment();
  }

  public submitForm(): void {
    this.approvalHelperService
      .redirectApproval(this.form.value, this.approvalData)
      .pipe(
        first(),
        filter((r) => r.success),
      )
      .subscribe(() => {
        returnAuthUserSession(this.store, this.currentUser, this.authUser);
        this.documentNavigationService.nextDocument();
        this.previousStep();
      });
  }

  /** Очистить комментарий */
  public clearComment(): void {
    this.form.controls.text.reset();
  }

  /** Вставить выбранного пользователя в форму */
  public chooseAddressee(addressee: EmployeeModel): void {
    const addressees = this.form.controls.addressees as FormArray;
    const userParticipant = this.getUserParticipant();
    const urgency = userParticipant?.urgencyReviewType || defaultValueUrgency;
    const dueDate = userParticipant?.dueDate;
    addressees.push(this.createAddresseeFormGroup(addressee, urgency, dueDate));
    const employees = addressees.value.map((p) => p.addressee).filter((p) => p.id === addressee.id);
    this.showChooseAddressee = false;

    if (this.approvalHelperService.isAddresseeSigner(addressee)) {
      this.addControls(addressees.controls[addressees.length - 1] as FormGroup, [
        { name: 'signer', value: true, validators: [] },
      ]);
    }

    this.setCloneControl(addressees, employees);

    this.dutiesService.dischargeOfDuties(
      addressees.controls[addressees.length - 1] as FormGroup,
      addressee.id,
      addressees,
      addressees.length - 1,
      'addressee',
    );
  }

  /** Вставить предыдущий */
  public getPreviousComment(): void {
    const text = this.form.controls.text;
    text.setValue(this.previousComment);
  }

  /** Получить список адресатов */
  public getAddresseeArray(): FormArray {
    return this.form.get('addressees') as FormArray;
  }

  /** Инициализация формы */
  private initForm(): void {
    this.form = this.fb.group(
      {
        addressees: this.fb.array([], Validators.required),
        confidential: [false],
        text: [''],
      },
      {
        validators: [this.noCloneValidator, this.noSignerValidator],
      },
    );
  }

  /** Создать пустую группу контролов для добавления адресата */
  private createAddresseeFormGroup(
    addressee?: EmployeeModel,
    urgency?: UrgencyReviewTypeModel,
    date?: string,
  ): FormGroup {
    return this.fb.group(
      {
        addressee: [addressee || ''],
        urgency: [urgency || ''],
        date: [date || null],
      },
      {
        validators: this.dateDiffValidator.bind(this),
      },
    );
  }

  private getUserParticipant(): ApprovalParticipantModel | null {
    const participant = this.findUserParticipant(this.approvalData?.blocks, 'participants');

    if (participant) {
      return participant;
    }

    // ищем среди подписантов
    return this.findUserParticipant(this.approvalData?.signerBlocks, 'signers');
  }

  private findUserParticipant(approvalBlock: ApprovalBlockModel[], purpose: string): ApprovalParticipantModel | null {
    let participant = null;

    /** Массив повторений пользоватля в ЛС. Частый кейс когда пользователь является одним из первых
     *  участников согласования, и затем через несколько шагов согласований вновь появляется в списке и
     *  данные о нем успели устареть
     * */
    const participantRepetitionList = [];

    if (approvalBlock.length) {
      participant = approvalBlock[0][purpose]?.find((p) => p.employee.id === this.user.id);

      if (participant) {
        participantRepetitionList.push(participant);
      }

      // спускаемся ещё на уровень и ищем у всех в redirectBlocks
      approvalBlock[0][purpose].forEach((p) => {
        if (p?.redirectBlocks?.length) {
          participant = this.findUserParticipant(p?.redirectBlocks, purpose);

          if (participant) {
            participantRepetitionList.push(participant);
          }
        }
      });
    }

    return participantRepetitionList[participantRepetitionList.length - 1];
  }

  /** Валидор отсутствия повторяющихся исполнителей */
  private noCloneValidator(control: AbstractControl): ValidationErrors | null {
    const addressees = control.get('addressees').value;

    if (addressees.find((item) => item.clone)) {
      return { hasDuplicates: true };
    }

    return null;
  }

  /** Валидор отсутствия подписантов */
  private noSignerValidator(control: AbstractControl): ValidationErrors | null {
    const addressees = control.get('addressees').value;

    if (addressees.find((item) => item.signer)) {
      return { hasSigner: true };
    }

    return null;
  }

  private dateDiffValidator(group: FormGroup): void {
    const dateValue = group.get('date')?.value;

    if (!dateValue) {
      return;
    }

    const today = this.dateHelper.today();
    const date = this.dateHelper.dateToSmallFormat(dateValue);

    if (date < today) {
      group.controls['date'].setErrors({ incorrect: true });
    } else {
      group.controls['date'].setErrors(null);
    }
  }
}
