import { BookmarksModel } from '@oogShared/models/bookmarks/bookmarks.model';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';

/** Сохранить закладки */
export class SetBookmarks {
  public static readonly type = '[Bookmarks] Set Bookmarks';
  constructor(public images: FilePoolModel[]) {}
}

/** Сбросить закладки */
export class ResetBookmarks {
  public static readonly type = '[Bookmarks] Reset Bookmarks';
}

/** Редактировать закладку */
export class UpdateBookmark {
  public static readonly type = '[Bookmarks] Update Bookmark';
  constructor(public bookmark: BookmarksModel) {}
}

/** Удалить закладку */
export class RemoveBookmark {
  public static readonly type = '[Bookmarks] Remove Bookmark';
  constructor(public id: number) {}
}
