/** id модального окна для создания резолюции */
export const modalIdCreateResolution = 'modal-id-create-resolution';
/** id модального окна для создания, редактирования, копирования СПР и резолюций */
export const modalIdSaveResolution = 'modal-id-save-resolution';
/** id модального окна для перенаправления резолюций и спр */
export const modalIdRedirectResolution = 'modal-id-redirect-resolution';
/** id модального окна с ошибкой подключения к серверу */
export const modalIdNetwork = 'modal-id-network';
/** id модального окна для удаления закладки */
export const modalIdDeleteBookmark = 'modal-id-delete-bookmark';
/** id модального окна для редактирования закладки */
export const modalIdEditBookmark = 'modal-id-edit-bookmark';
/** id модального окна для создания закладки */
export const modalIdCreateBookmark = 'modal-id-create-bookmark';
/** id модального окна подтверждения действия */
export const modalIdConfirmation = 'modal-id-confirmation';
/** id модального окна для добавления исполнителя из первого поручения в остальные */
export const modalIdSpreadExecutor = 'modal-id-spread-executor';
/** id модального окна уведомления об обновлении */
export const modalIdNotificationUpdate = 'modal-id-notification-update';
/** id модального окна удаления аудиорезолюции */
export const modalIdDeleteAudio = 'modal-id-delete-audio';
/** id модального окна удаления графической резолюции */
export const modalIdDeletePicture = 'modal-id-delete-picture';
/** id модального окна создания папки */
export const modalIdCreateFolder = 'modal-id-create-folder';
/** id модального окна создания подпапки */
export const modalIdCreateSubFolder = 'modal-id-create-subfolder';
/** id модального окна перемещения папки */
export const modalIdMoveFolder = 'modal-id-move-folder';
/** id модального окна переименования папки */
export const modalIdRenameFolder = 'modal-id-rename-folder';
/** id модального окна удаления папки */
export const modalIdDeleteFolder = 'modal-id-delete-folder';
/** id модального окна подписания approval */
export const modalIdSignApproval = 'modal-id-sign-approval';
/** id модального окна действий approval */
export const modalIdApprovalActions = 'modal-id-approval-actions';
/** id модального окна отсутствия документа из уведомления */
export const modalIdNotificationNoDocument = 'modal-id-notification-no-document';
