import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RadioBorderEnum } from '@oogShared/enums/radio-border.enum';
import { RadioControlEnum } from '@oogShared/enums/radio-control.enum';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { FileViewerHelperService } from '@oogShared/components/file-viewer/services/file-viewer-helper.service';
import { BookmarkEnum } from '@oogShared/enums/bookmark.enum';
import { RadioFlexEnum } from '@oogShared/enums/radio-flex.enum';
import { BookmarksModel } from '@oogShared/models/bookmarks/bookmarks.model';
import { BookmarkInteropService } from '@oogShared/rest/bookmark.interop.service';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { SetBookmarks, UpdateBookmark } from '@store/resolution-store/bookmarks/bookmarks.action';
import { ResolutionFilesState } from '@store/resolution-store/resolution-files/resolution-files.state';
import { first, switchMap } from 'rxjs/operators';
import { emptyWhiteSpaceValidator } from '@oogShared/functions/form-validators/empty-white-space.validator';

@Component({
  selector: 'app-bookmark-create',
  templateUrl: './bookmark-create.component.html',
  styleUrls: ['./bookmark-create.component.scss'],
})
/** Компонент с модальным окном для создания/редактирования закладки */
export class BookmarkCreateComponent implements OnInit {
  @Input() public bookmark: BookmarksModel;
  @Input() public isEdit = false;

  public readonly radioTheme = RadioControlEnum;
  public readonly radioBorderType = RadioBorderEnum;
  public readonly bookmarkType = BookmarkEnum;
  public readonly radioFlexType = RadioFlexEnum;
  public form = new FormGroup({});

  constructor(
    private modalCtrl: ModalController,
    private bookmarkInterop: BookmarkInteropService,
    private fb: FormBuilder,
    private currentUser: CurrentUserIsproStoreService,
    private fileViewerHelperService: FileViewerHelperService,
    private store: Store,
    private cardService: ResolutionInteropService,
  ) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public edit(): void {
    const params: BookmarksModel = this.getParamsForBookmark();
    this.bookmarkInterop
      .edit(params)
      .pipe(first())
      .subscribe(() => {
        this.store.dispatch(new UpdateBookmark(params));
        this.modalCtrl.dismiss();
      });
  }

  public create(): void {
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    const params: BookmarksModel = this.getParamsForBookmark();
    this.bookmarkInterop
      .create(params)
      .pipe(
        first(),
        switchMap(() => this.cardService.getImagesFiles(card.appealId, true, false)),
      )
      .subscribe((result) => {
        this.store.dispatch(new SetBookmarks(result));
        this.modalCtrl.dismiss();
      });
  }

  public cancel(): void {
    this.modalCtrl.dismiss();
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: [this.bookmark?.name || '', [Validators.required, emptyWhiteSpaceValidator]],
      addressee: [this.bookmark?.type || '', [Validators.required]],
    });
  }

  private getParamsForBookmark(): BookmarksModel {
    const user = this.currentUser.getCurrentUserSnapshot();
    const pageNumber = this.fileViewerHelperService.getCurrentPage();
    return {
      author: user,
      created: this.bookmark?.created || null,
      id: this.bookmark?.id || null,
      name: this.form.controls.name.value,
      pageId: this.bookmark?.pageId || this.store.selectSnapshot(ResolutionFilesState.getPageId(pageNumber)) || null,
      type: this.form.controls.addressee.value,
    };
  }
}
