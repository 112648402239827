import { Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive()
export class BaseControl {
  @Input() public placeholder = '';
  @Input() public controlName = '';
  @Input() public parentFormGroup: FormGroup = new FormGroup({});
  @Input() public id = '';
  @Input() public readonly: boolean | undefined;

  /** убрать крайние пробелы из значения контрола */
  protected trimControlValue(): void {
    if (!this.controlName) {
      return;
    }
    const control = this.parentFormGroup.controls[this.controlName];
    if (control?.value) {
      control.setValue(control.value.trim(), { emitEvent: false });
    }
  }
}
