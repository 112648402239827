import { Component, Input } from '@angular/core';

import { SearchMissionModel } from '../../models/resolutions/mission/search-mission.model';

@Component({
  selector: 'app-commission-related',
  templateUrl: './commission-related.component.html',
  styleUrls: ['./commission-related.component.scss'],
})
export class CommissionRelatedComponent {
  @Input() public missionAction!: SearchMissionModel;
  @Input() public commissionIdx = 0;
}
