import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { modalIdConfirmAction } from '@npaShared/consts/modals-id.const';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmActionComponent {
  @Input() public dpId: number;
  @Input() public type: ApproveTypeEnum;

  constructor(private modalController: ModalController) {}

  public confirm(): void {
    this.modalController.dismiss(true, undefined, modalIdConfirmAction);
  }

  public close(): void {
    this.modalController.dismiss(undefined, undefined, modalIdConfirmAction);
  }
}
