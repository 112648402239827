import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonIconEnum } from '@enums/button-icon.enum';
import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { Select } from '@ngxs/store';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { SettingsState } from '@store/settings/settings.state';
import { Observable } from 'rxjs';
import { AppealRedirectResolutionPage } from '../../../document-forms/appeal-redirect-resolution/appeal-redirect-resolution.page';

import { DocumentRedirectPage } from '../../../document-forms/document-redirect/document-redirect.page';
import { RedirectResolutionPage } from '../../../document-forms/redirect-resolution/redirect-resolution.component';
import { ApprovalActionBtnTypeEnum } from '../../enums/approval-list/approval-action-btn-type.enum';
import { UrgencyTypeEnum } from '../../enums/document-card/urgency-type.enum';
import { ProtocolTypeEnum } from '../../enums/protocol-type.enum';
import { DocumentCardTypeEnum } from '../../enums/side-bar/document-card-type.enum';
import { CardDocumentModel } from '../../models/documents/card-document.model';
import { PersonalFolder } from '../../models/folders/personal-folders.model';
import { EmployeeModel } from '../../models/resolutions/employee/employee.model';
import { ResolutionAddresseeModel } from '../../models/resolutions/resolution/resolution-addressee.model';

/** Компонент для отображения карточки документа */
@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent {
  @Input() public activeDocument!: number | null;
  @Input() public document!: CardDocumentModel;
  @Input() public highlightMatch = false;
  @Input() public matchedText = '';
  @Input() public workForEveryone = false;
  @Input() public authUser: EmployeeModel;
  @Input() public disableButtons = false;
  @Input() public buttonFamiliarization = true;
  @Input() public folders: PersonalFolder[];
  @Input() public familiarization: ResolutionAddresseeModel[] = [];
  @Input() public canApproveResolutionProject = false;
  @Input() public showOnlyReturnButton = false;
  @Input() public isChildCardInGroup = false;
  @Input() public defaultBorder = true;
  @Input() public isMainCardInGroup = false;

  @Output() public chooseDocument = new EventEmitter<CardDocumentModel>();
  @Output() public createResolution = new EventEmitter<void>();
  @Output() public redirectResolution = new EventEmitter<any>();
  @Output() public familiarizationResolution = new EventEmitter<boolean>();
  @Output() public actionApproval = new EventEmitter<ApprovalActionBtnTypeEnum>();
  @Output() public coordinateApproval = new EventEmitter<void>();

  @Select(SettingsState.showButtonFolder)
  public displayingButtonFolder$: Observable<boolean>;

  public urgencyType = UrgencyTypeEnum;
  public buttonTheme = ButtonThemeEnum;
  public buttonIcon = ButtonIconEnum;
  public redirectProjectComponent = DocumentRedirectPage;
  public actionType = ApprovalActionBtnTypeEnum;
  public protocolType = ProtocolTypeEnum;
  public documentCardType = DocumentCardTypeEnum;
  public isSummaryExpanded = false;

  public resolutionTypeEnum = ResolutionTypeEnum;

  public chooseComponentForRedirectResolution(): void {
    const component = this.document.isCitizenAppeal ? AppealRedirectResolutionPage : RedirectResolutionPage;
    this.redirectResolution.emit(component);
  }
}
