import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { RelevantWorkersState } from '@store/main-screen/relevant-workers/relevant-workers.state';
import { WorkForEveryoneState } from '@store/main-screen/work-for-everyone/work-for-everyone.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';

/** Изменить сессию на юзера, за которого совершаем действие */
export const changeUserSession = (store: Store, currentUser: CurrentUserIsproStoreService): void => {
  const workForEveryone = store.selectSnapshot(WorkForEveryoneState.workForEveryone);
  if (!workForEveryone) {
    return;
  }
  const card = store.selectSnapshot(ActiveCardState.getActiveCard);
  const relevantWorkers = store.selectSnapshot(RelevantWorkersState.relevantWorkers);
  const activeEmployee = card.transferredRight
    ? relevantWorkers.find((w) => w.id === card.transferredRight.onBehalfOfEmployeeId)
    : relevantWorkers[0];
  currentUser.setCurrentUser(activeEmployee);
};
