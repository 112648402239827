import { Component, HostBinding, Input } from '@angular/core';

import { CheckboxThemeEnum } from '@enums/checkbox-theme.enum';

import { BaseControl } from '../control-base';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends BaseControl {
  /** Тема оформления чекбокса */
  @HostBinding('attr.theme')
  @Input()
  public theme = CheckboxThemeEnum.checkboxBlue;
}
