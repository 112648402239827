import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentName',
})

/** Убирает расширение из оригинального наименования файла */
export class DocumentNamePipe implements PipeTransform {
  public transform(name: string): string {
    const finalArr = name.split('.');
    finalArr.pop();
    return finalArr.join('.');
  }
}
