import { Pipe, PipeTransform } from '@angular/core';

import { DateHelperService } from '../../services/date-helper.service';

@Pipe({
  name: 'countDays',
})
export class CountDaysPipe implements PipeTransform {
  constructor(private dateHelper: DateHelperService) {}

  public transform(date: Date): number {
    return this.dateHelper.subtractDate(date) ? Math.abs(this.dateHelper.subtractDate(date)) + 1 : 0;
  }
}
