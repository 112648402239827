export enum RadioControlEnum {
  /** Зелёный радио баттон */
  radioGreen = 'radio-green',
  /** Желтый радио батон */
  radioOrange = 'radio-orange',
  /** Серый радио баттон */
  radioGray = 'radio-gray',
  /** Коричневый радио баттон */
  radioBrown = 'radio-brown',
  /** Голубой радио баттон */
  radioBlue = 'radio-blue',
}
