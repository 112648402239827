import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const version = environment.mobileVersion;
    const oogUrl = environment.oogUrl;

    const modifiedRequest =
      req.url.indexOf(oogUrl) !== -1 ? req.clone({ headers: req.headers.set('X-mo-version', version) }) : req;

    return next.handle(modifiedRequest);
  }
}
