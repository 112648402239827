import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonThemeEnum } from '@enums/button-theme.enum';

@Component({
  selector: 'app-simple-button',
  templateUrl: './simple-button.component.html',
  styleUrls: ['./simple-button.component.scss'],
})
export class SimpleButtonComponent {
  /** название кнопки */
  @Input() public title: string;
  @Input() public bigButton = false;

  /** Активность кнопки */
  @HostBinding('class.disabled')
  @Input()
  public disabled = false;

  /** Тема оформления кнопки */
  @HostBinding('attr.theme')
  @Input()
  public theme: ButtonThemeEnum;

  @Input()
  @HostBinding('class.disabled-with-loader')
  public disabledWithLoader = false;

  @Output() public clickButton = new EventEmitter();

  public click(): void {
    if (this.disabled || this.disabledWithLoader) {
      return;
    }

    this.clickButton.emit();
  }
}
