import { Injectable } from '@angular/core';
import { FileTypeEnum } from '@enums/file-type.enum';

import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import {
  ResetResolutionFiles,
  SetResolutionFiles,
} from '@store/resolution-store/resolution-files/resolution-files.action';

@Injectable()
@State<FilePoolModel[]>({
  name: 'resolutionFiles',
  defaults: [],
})
export class ResolutionFilesState {
  public static getPageId(pageNumber: number): (state: FilePoolModel[]) => number | undefined {
    return createSelector([ResolutionFilesState], (state: FilePoolModel[]): number => {
      const filePages = state.find((f) => f.fileType === FileTypeEnum.pages);
      return filePages?.pages[pageNumber - 1]?.id;
    });
  }

  public static getPageNumber(pageId: number): (state: FilePoolModel[]) => number {
    return createSelector([ResolutionFilesState], (state: FilePoolModel[]): number => {
      const filePages = state.find((f) => f.fileType === FileTypeEnum.pages);
      const pageNumber = filePages?.pages?.findIndex((p) => p.id === pageId) + 1;
      return isNaN(pageNumber) ? 1 : pageNumber;
    });
  }

  @Selector()
  public static getResolutionFiles(state: FilePoolModel[]): FilePoolModel[] {
    return state?.filter((item) => !item?.invisible) ?? [];
  }

  @Action(SetResolutionFiles)
  public setResolutionFiles({ setState }: StateContext<FilePoolModel[]>, { files, images }: SetResolutionFiles): void {
    if (!files?.length) {
      return;
    }
    if (images?.length) {
      const sharedFilesList: FilePoolModel = {
        extension: 'pdf',
        fileName: 'Страницы документа.pdf',
        filePath: null,
        fileSize: 0,
        fileType: FileTypeEnum.pages,
        pageCount: files?.find((f) => f?.fileType === FileTypeEnum.main)?.pageCount || null,
        id: null,
        storageFileId: files?.find((f) => f?.fileType === FileTypeEnum.main)?.storageFileId,
        pages: images,
        invisible: false,
      };
      setState([sharedFilesList, ...files]);
      return;
    }

    setState(files);
  }

  @Action(ResetResolutionFiles)
  public resetResolutionFiles({ setState }: StateContext<FilePoolModel[]>): void {
    setState([]);
  }
}
