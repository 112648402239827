import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { DocumentPackageApproveModel } from '@npaShared/models/document-package/document-package-approve.model';
import { DocumentPackageStoreModel } from '@npaShared/models/document-package/document-package-store.model';
import { ModalReturnConfirmationModel } from '@npaShared/models/modal/modal-return-confirmation.model';
import { Route } from '@npaShared/models/route/route.models';
import { Observable } from 'rxjs';
import { ParamsForPreparationSigning } from '../agreement-sign.service';
import { AgreementService } from '../../../../document-agreement/services/agreement.service';
import { BaseAgreements } from './base-agreements';

export class AgreementLuz implements BaseAgreements {
  constructor(protected agreementService: AgreementService) {}

  public getRequest(
    params: ParamsForPreparationSigning,
    paramsForRequest: DocumentPackageApproveModel,
  ): Observable<Route> {
    return this.agreementService.approveDocumentWithRemark(params.documentPackage.id, paramsForRequest);
  }

  public getParamsForModalConfirmation(
    selectedDocumentPackage: DocumentPackageStoreModel,
    description: string,
  ): ModalReturnConfirmationModel {
    return {
      approveType: ApproveTypeEnum.approveLuz,
      documentPackageName: selectedDocumentPackage.documentPackage.documentPackageName,
      packageNumber: selectedDocumentPackage.documentPackageAdditionalInfo.packageNumber,
      redirectToFio: null,
      description,
    };
  }
}
