import { CompletedState } from '@npaShared/consts/completed-state.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { Route, RoutePoint } from '@npaShared/models/route/route.models';

import { VertexState } from '@npaShared/enums/vertex-state.enum';
import { getPointByIdWithAdditionalInfo } from './point-helper';
import { getSubphaseById } from './subphase-helper';

/**
 * получить список согласующих (с завершенными резолюциями), которым можно вернуть.
 * предыдущему согласующему или нескольким (если они параллельные) и перенаправленным.
 */
export const getPointsCoordinatorsToReturns = (route: Route, point: RoutePoint): RoutePoint[] => {
  const allPointsPreviousCurrentPoint = getPreviousPointsCluster(route, point) || [];

  const completedRedirectedPoints = point.childrenPoint
    .filter((childPoint) => CompletedState.includes(childPoint.taskResolution))
    .filter((childPoint) => childPoint.pointRoleId !== EmployeeRouteTypes.introducer);

  const completedPreviousPointOnMainVertical = allPointsPreviousCurrentPoint
    .filter((p) => CompletedState.includes(p.taskResolution))
    .filter((p) => ![EmployeeRouteTypes.author, EmployeeRouteTypes.executor].includes(p.pointRoleId));

  const pointsForReturn = [...completedPreviousPointOnMainVertical, ...completedRedirectedPoints];
  const pointsWithoutCompletedAutomaticallyState = getPointsWithoutCompletedAutomaticallyState(pointsForReturn);
  return pointsWithoutCompletedAutomaticallyState;
};

/**
 * автор есть только на этапе Проект создан
 *
 * @returns автор на этапе или null - если его нет или согласующий не с главной вертикали
 * */
export const getPointAuthorToReturns = (route: Route, point: RoutePoint): RoutePoint | null => {
  if (point.level > 1) {
    return null;
  }

  const extendedPoint = getPointByIdWithAdditionalInfo(route, point.id);

  const subPhase = getSubphaseById(route, extendedPoint.subPhaseId);
  const subPhasePoints = subPhase.points;
  const arrayAuthor = subPhasePoints.find((cluster: RoutePoint[]) =>
    cluster.find((clusterPoint) => clusterPoint.pointRoleId === EmployeeRouteTypes.author),
  );

  return arrayAuthor ? arrayAuthor[0] : null;
};

/** получить список точек исполнителей, исключая точки закрытые автоматически */
export const getExecutorPointsToReturns = (route: Route, point: RoutePoint): RoutePoint[] => {
  if (point.level > 1) {
    return [];
  }

  if (point.pointRoleId === EmployeeRouteTypes.executor) {
    return [];
  }

  const extendedPoint = getPointByIdWithAdditionalInfo(route, point.id);
  const subPhase = getSubphaseById(route, extendedPoint.subPhaseId);
  const subPhasePoints = subPhase.points;
  let executorPoints: RoutePoint[] = [];

  subPhasePoints.forEach((pointsArray: RoutePoint[]) => {
    if (executorPoints.length) {
      return;
    }

    executorPoints = pointsArray.filter((p) => p.pointRoleId === EmployeeRouteTypes.executor);
  });

  const pointsWithoutCompletedAutomaticallyState = getPointsWithoutCompletedAutomaticallyState(executorPoints);
  return pointsWithoutCompletedAutomaticallyState;
};

/** Получение точки подэтапа перед переданной точкой или несколько точек, если они параллельные */
const getPreviousPointsCluster = (route: Route, point: RoutePoint): RoutePoint[] | undefined => {
  const extendedPoint = getPointByIdWithAdditionalInfo(route, point.id);

  const subphase = getSubphaseById(route, extendedPoint.subPhaseId);
  const subphasePoints = subphase.points;
  const currentPointClusterIdx = subphasePoints.findIndex((cluster: RoutePoint[]) =>
    cluster.some((clusterPoint) => clusterPoint.id === point.id),
  );

  return subphasePoints[currentPointClusterIdx - 1];
};

const getPointsWithoutCompletedAutomaticallyState = (points: RoutePoint[]): RoutePoint[] =>
  points.filter((p) => p.taskResolution !== VertexState.completedAutomatically);
