import { Pipe, PipeTransform } from '@angular/core';

import { DelegatingTypeEnum } from '@npaShared/enums/delegating-type.enum';
import { DelegatingInfoModel } from '@npaShared/models/delegating-info.model';

@Pipe({
  name: 'delegatingsSort',
})
export class DelegatingsSortPipe implements PipeTransform {
  public transform(delegatings: DelegatingInfoModel[]): DelegatingInfoModel[] {
    const actngDelegatings = delegatings.filter(
      (delegating) => delegating.delegatingType === DelegatingTypeEnum.ACTING,
    );
    const onBehalfOfDelegatings = delegatings.filter(
      (delegating) => delegating.delegatingType === DelegatingTypeEnum.ON_BEHALF_OF,
    );
    return [...actngDelegatings, ...onBehalfOfDelegatings];
  }
}
