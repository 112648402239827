import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

/** Директива для расчета позиции по вертикали для фильтра */
@Directive({
  selector: '[appTopFilterPosition]',
})
export class TopFilterPositionDirective implements OnInit {
  @Input()
  public parentElement: HTMLElement;

  // если название категории реестра без переноса
  public defaultTopValue = 98;

  constructor(private ref: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    const value = this.parentElement?.clientHeight ? this.parentElement.clientHeight : this.defaultTopValue;
    this.setStyle('top', value + 'px');
    this.setStyle('height', `calc(100% - ${value}px)`);
  }

  private setStyle(propName: string, value: string): void {
    this.renderer.setStyle(this.ref.nativeElement, propName, value);
  }
}
