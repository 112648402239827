import { NotificationExportEventModel } from '@npaShared/models/websocket/notification-export-event.model';
import { SocketChannel } from '@npaShared/models/websocket/socket-channel';

export class SocketChannels {
  public static documentToPrintChanel: SocketChannel<NotificationExportEventModel> = {
    address: '/user/ldeMassPrintEvents',
  };

  public static exportChanel: SocketChannel<NotificationExportEventModel> = {
    address: '/user/ldeExportEvents',
  };
}
