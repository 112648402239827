import { PersonalFolder } from '@oogShared/models/folders/personal-folders.model';

/** Возвращает путь до текущей папке в дереве */
export const getPathToCurrentFolder = (folders: PersonalFolder[], currentFolder: PersonalFolder): number[] => {
  const path: number[] = [];

  buildPathToCurrentFolder(folders, currentFolder.id, path);
  path.reverse();

  return path;
};

/** функция строит путь до текущий папки в дереве */
const buildPathToCurrentFolder = (
  folders: PersonalFolder[],
  currentFolderId: number,
  path: number[],
): PersonalFolder => {
  for (let index = 0; index < folders.length; index++) {
    const folder = folders[index];

    if (folder.id === currentFolderId) {
      path.push(index);

      return folder;
    }

    if (folder.folders?.length) {
      const fendedFolder = buildPathToCurrentFolder(folder.folders, currentFolderId, path);

      if (fendedFolder) {
        path.push(index);
        return fendedFolder;
      }
    }
  }
};
