import { Pipe, PipeTransform } from '@angular/core';

import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';

import { ResolutionAddresseeModel } from '../../models/resolutions/resolution/resolution-addressee.model';

@Pipe({
  name: 'compareCurrentUserWithAcquaintance',
})
export class CompareCurrentUserWithAcquaintancePipe implements PipeTransform {
  constructor(private user: CurrentUserIsproStoreService) {}

  public transform(familiarization: ResolutionAddresseeModel[]): boolean {
    const user = this.user.getCurrentUserSnapshot();
    return familiarization?.some((f) => f.employee.id === user.id);
  }
}
