import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';

/** Заполнить стор вышестоящих проектов */
export class SetResolutionTree {
  public static readonly type = '[Resolution Tree] Set Resolution Tree';

  constructor(public tree: ResolutionModel[]) {}
}

/** Очистить стор вышестоящих проектов */
export class ResetResolutionTree {
  public static readonly type = '[Resolution Tree] Reset Resolution Tree';
}
