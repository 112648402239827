export enum FilterCodesEnum {
  all = 'ALL',
  clear = 'clear',
  today = 'today',
  week = 'week',
  urgently = 'URGENT',
  immediately = 'IMMEDIATE',
  new = 'new',
  old = 'old',
}
