import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { RoutePoint } from '@npaShared/models/route/route.models';
import { getAllApprovementSubhases } from '../../../document-agreement/enums/route-phase-types.enum';
import { RootActsLogic } from './root-acts-logic';

/** Логика работы с несколькими активными точками для категории "На ознакомлении" */
export class IntroducerActsLogic extends RootActsLogic {
  private availableTaskStatuses = [...EqualActiveResolutions];
  private availableRoles = [EmployeeRouteTypes.introducer];
  private availableSubphases = getAllApprovementSubhases();

  public getActivePointsList(): RoutePoint[] {
    return this.getUserPoints()
      .filter((point) => this.availableTaskStatuses.includes(point.taskResolution))
      .filter((point) => this.availableRoles.includes(point.pointRoleId))
      .filter((point) => this.availableSubphases.includes(point.phaseTypeId));
  }
}
