import { Component, Input } from '@angular/core';
import { first, switchMap, tap } from 'rxjs/operators';

import { ModalController, PopoverController } from '@ionic/angular';
import { countFoldersNestedLevels, isChildFolder } from '@oogShared/functions/document-folder/document-folder.function';
import { ModalService } from '@shared/services/modal.service';

import { Store } from '@ngxs/store';
import { SetArrowMovement } from '@store/arrow-movement/arrow-movement.action';
import { ActionsFooterWithArrow } from '@enums/actions-footer-with-arrows.enum';
import {
  modalIdCreateSubFolder,
  modalIdDeleteFolder,
  modalIdMoveFolder,
  modalIdRenameFolder,
} from '@const/modals-id.const';
import { FolderDataModelEnum } from '../../../enums/folders/personal-folder-page-modal.enum';
import { ConfirmModalModel } from '../../../models/folders/confirm-modal.model';
import { NewFolderModalModel } from '../../../models/folders/new-folder-modal.model';
import { PersonalPageModalModel } from '../../../models/folders/personal-folder-page-modal.model';
import { PersonalFolder } from '../../../models/folders/personal-folders.model';
import { FoldersInteropService } from '../../../rest/folders-interop.service';
import { FoldersStateService } from '../../../services/folders/folders-state.service';
import { DeleteFolderComponent } from '../../folder-modals/delete-folder/delete-folder.component';
import { FoldersPageComponent } from '../../folder-modals/folders-page/folders-page.component';
import { EditCreateFolderComponent } from '../../folder-modals/new-folder/edit-create-folder.component';

@Component({
  selector: 'app-folders-edit-menu',
  templateUrl: './folders-edit-menu.component.html',
  styleUrls: ['./folders-edit-menu.component.scss'],
})
export class FoldersEditMenuComponent {
  @Input() public folder: PersonalFolder;

  constructor(
    private modalCtrl: ModalController,
    private foldersApi: FoldersInteropService,
    private foldersState: FoldersStateService,
    private popoverController: PopoverController,
    private modalService: ModalService,
    private store: Store,
  ) {}

  public async moveFolder(): Promise<void> {
    this.popoverController.dismiss().then();
    this.foldersState.setSelectedFolders([this.folder]);
    const modal = await this.modalCtrl.create({
      componentProps: { editMode: true },
      backdropDismiss: false,
      component: FoldersPageComponent,
      cssClass: 'modal-folder-page',
      id: modalIdMoveFolder,
    });

    await modal.present();

    await modal.onDidDismiss().then((res: PersonalPageModalModel) => {
      const clickedFolder = this.foldersState.getClickedFolderSnapshot();
      const selectedFolder = this.foldersState.getSelectedFoldersSnapshot()
        ? this.foldersState.getSelectedFoldersSnapshot()[0]
        : null;

      if (selectedFolder && isChildFolder(selectedFolder, clickedFolder?.id)) {
        this.modalService.presentModalError(
          'Вы не можете выполнить перемещение в свою же дочернюю папку. Укажите другую папку',
        );
        return;
      }

      const selectedFolderNestedLevel = countFoldersNestedLevels(selectedFolder, 1);

      if (selectedFolderNestedLevel + clickedFolder?.nestedLevel > 3) {
        this.modalService.presentModalError(
          'Вы не можете выполнить перемещение в дочернюю папку. Структура папок будет превышать установленный лимит',
        );
        return;
      }

      if (res.data?.type === FolderDataModelEnum.makeRoot && selectedFolder) {
        this.foldersState
          .editOrCreateNewFolder({
            id: selectedFolder.id,
            name: selectedFolder.name,
            nestedLevel: 1,
          })
          .pipe(
            tap(() => {
              this.foldersState.setEditMode(true);
              this.resetSelectedFolders();
            }),
            switchMap(() => this.foldersState.getFoldersFromApi()),
            first(),
          )
          .subscribe();

        return;
      }

      if (res.data?.type === FolderDataModelEnum.move && selectedFolder) {
        this.foldersState
          .editOrCreateNewFolder({
            id: selectedFolder.id,
            pid: clickedFolder.id,
            name: selectedFolder.name,
            rootId: clickedFolder.rootId ?? clickedFolder.id,
            folderIsMoved: true,
          })
          .pipe(
            tap(() => {
              this.foldersState.setEditMode(true);
              this.resetSelectedFolders();
            }),
            switchMap(() => this.foldersState.getFoldersFromApi()),
            first(),
          )
          .subscribe();
      }
    });
  }

  public async createSubFolder(): Promise<void> {
    this.popoverController.dismiss().then();
    const modal = await this.modalCtrl.create({
      componentProps: { title: 'Новая папка' },
      backdropDismiss: false,
      component: EditCreateFolderComponent,
      cssClass: 'modal-new-folder',
      id: modalIdCreateSubFolder,
    });

    await modal.present();

    await modal.onDidDismiss().then((res: NewFolderModalModel) => {
      if (res.data?.name) {
        this.foldersState
          .editOrCreateNewFolder({
            name: res.data.name,
            pid: this.folder.id,
            rootId: this.folder.rootId ?? this.folder.id,
          })
          .pipe(
            switchMap(() => this.foldersState.getFoldersFromApi()),
            first(),
          )
          .subscribe();
      }
    });
  }

  public async renameFolder(): Promise<void> {
    this.popoverController.dismiss().then();
    const modal = await this.modalCtrl.create({
      componentProps: { title: 'Переименовать папку', folderName: this.folder.name },
      backdropDismiss: false,
      component: EditCreateFolderComponent,
      cssClass: 'modal-new-folder',
      id: modalIdRenameFolder,
    });

    await modal.present();

    await modal.onDidDismiss().then((res: NewFolderModalModel) => {
      this.resetSelectedFolders();
      if (res.data?.name) {
        this.foldersState
          .editOrCreateNewFolder({
            name: res.data.name,
            id: this.folder.id,
            pid: this.folder.pid,
            rootId: this.folder.rootId,
          })
          .pipe(
            switchMap(() => this.foldersState.getFoldersFromApi()),
            first(),
          )
          .subscribe();
      }
    });
  }

  public async deleteFolder(): Promise<void> {
    this.popoverController.dismiss().then();
    const modal = await this.modalCtrl.create({
      componentProps: { hasDocuments: this.folder.size },
      component: DeleteFolderComponent,
      backdropDismiss: false,
      cssClass: 'modal-new-folder',
      id: modalIdDeleteFolder,
    });

    await modal.present();

    await modal.onDidDismiss().then((res: ConfirmModalModel) => {
      this.resetSelectedFolders();
      const confirm = res.data.confirm;
      if (confirm) {
        this.foldersApi
          .deleteFolder(this.folder.id)
          .pipe(
            switchMap(() => this.foldersState.getFoldersFromApi()),
            first(),
          )
          .subscribe(() => {
            this.store.dispatch(new SetArrowMovement({ action: ActionsFooterWithArrow.right }));
          });
      }
    });
  }

  private resetSelectedFolders(): void {
    this.foldersState.setSelectedFolders(null);
    this.foldersState.setClickedFolder(null);
  }
}
