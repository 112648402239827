import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { VertexState } from '@npaShared/enums/vertex-state.enum';
import { Route, RoutePoint } from '@npaShared/models/route/route.models';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { RoutePhaseTypes } from '../../../document-agreement/enums/route-phase-types.enum';
import { getPointsCurrentUser } from './get-points-current-user';

/** вернет активные точки текущего пользователя */
export const getActivePointsCurrentUser = (route: Route, currentUser: UserInfoResponseModel): RoutePoint[] => {
  const pointsCurrentUser = getPointsCurrentUser(route, currentUser);

  return pointsCurrentUser.filter((p) => EqualActiveResolutions.includes(p.taskResolution)) || [];
};

/** вернет самую активную точку текущего пользователя */
export const getMostActivePointCurrentUser = (route: Route, currentUser: UserInfoResponseModel): RoutePoint | null => {
  const pointsCurrentUser = getPointsCurrentUser(route, currentUser);
  const activePoint = pointsCurrentUser.find((p) => EqualActiveResolutions.includes(p.taskResolution));
  const waitingPoint = getPointWaitingForApprovement(pointsCurrentUser);

  return activePoint || waitingPoint;
};

/** вернет точку со статусом WAITING_FOR_APPROVEMENT, НО если эта точка находится на Проект создан Поручение, то игнорируем её */
const getPointWaitingForApprovement = (pointsCurrentUser: RoutePoint[]): RoutePoint | null => {
  const waitingPoint = pointsCurrentUser
    .filter((p) => p.taskResolution === VertexState.waitingForApprovement)
    .find((p) => p.phaseTypeId !== RoutePhaseTypes.projectCreatedInitiation);

  return waitingPoint || null;
};
