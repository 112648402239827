import { Injectable } from '@angular/core';

import { StatisticEmployeeFrom } from '@models/main-screen/statistic-employee-from.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetStatisticEmployeeFrom } from '@store/main-screen/statistic-employee-from/statistic-employee-from.action';

@Injectable()
@State<StatisticEmployeeFrom[]>({
  name: 'statisticsEmployeeFrom',
  defaults: [],
})
export class StatisticEmployeeFromState {
  @Selector()
  public static getStatistics(state: StatisticEmployeeFrom[]): StatisticEmployeeFrom[] {
    return state;
  }

  @Action(SetStatisticEmployeeFrom)
  public setStatisticEmployeeFrom(
    { setState }: StateContext<StatisticEmployeeFrom[]>,
    { statistics }: SetStatisticEmployeeFrom,
  ): void {
    setState(statistics);
  }
}
