/** Перечень стилей кнопки */
export enum ButtonThemeEnum {
  /** Голубая кнопка */
  buttonLightblue = 'button-lightblue',
  /** Синяя кнопка */
  buttonBlue = 'button-blue',
  /** Зелёная кнопка */
  buttonGreen = 'button-green',
  /** Бирюзовая кнопка */
  buttonTurquoise = 'button-turquoise',
  /** Коричневая кнопка */
  buttonBrown = 'button-brown',
  /** Красная кнопка */
  buttonRed = 'button-red',
  /** Оранжевая кнопка */
  buttonOrange = 'button-orange',
  /** Серая кнопка */
  buttonGray = 'button-gray',
  /** Светлосерая кнопка */
  buttonLightGray = 'button-light-gray',
  /** Белая кнопка */
  buttonWhite = 'button-white',
  /** Шрифт 12px для кнопки */
  buttonSmall = 'button-small',
  /** Красная кнопка удаления */
  buttonDeleted = 'button-deleted',
}
