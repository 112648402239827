import { Injectable } from '@angular/core';

import { State } from '@ngxs/store';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ArrowButtonsState } from '@store/menu/arrow-buttons/arrow-buttons.state';
import { CountDocumentsMenuState } from '@store/menu/count-documents-menu/count-documents-menu.state';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';
import { DocumentsCategoryState } from '@store/menu/documents-category/documents-category.state';
import { PreviousActiveDocumentState } from '@store/menu/previous-active-document/previous-active-document.state';

import { DocumentsGroupState } from '@store/menu/documents-group/documents-group.state';
import { SidebarState } from './sidebar/sidebar.state';

@Injectable()
@State<[]>({
  name: 'menu',
  children: [
    ActiveCardState,
    ArrowButtonsState,
    DocumentsCategoryState,
    DocumentsCardsState,
    SidebarState,
    PreviousActiveDocumentState,
    CountDocumentsMenuState,
    DocumentsGroupState,
  ],
})
export class MenuState {}
