import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SettingsState } from '@store/settings/settings.state';
import { BaseFiles } from '../../../base-classes/base-files';

@Component({
  selector: 'app-files-my-organization',
  templateUrl: './files-my-organization.component.html',
  styleUrls: ['./files-my-organization.component.scss'],
})
export class FilesMyOrganizationComponent extends BaseFiles implements OnInit, OnDestroy {
  @Input() public container: HTMLElement;
  @Input() public hideExpandButton = false;
  @Input() public docComment = '';
  @Input() public loadingFiles = false;

  public fileCommentCollapsed = true;
  public commentIsFullyCollapsed = true;
  public threeLinesHeight = 47;

  public get positionTop(): string {
    if (!this.container) {
      return '0';
    }
    return this.container.offsetHeight + 'px';
  }

  public ngOnInit(): void {
    this.handleSidebarStateChange();
    this.processCommentOpenedSetting();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private processCommentOpenedSetting(): void {
    // Получаем настройку из хранилища
    const commentBlockIsFullyOpenedSetting = this.store.selectSnapshot(
      SettingsState.commentsInDossierAndDocumentBlocksFullyOpened,
    );

    // Если настройка установлена, то открываем оба комментария, если нет - то оба скрываем
    this.fileCommentCollapsed = !commentBlockIsFullyOpenedSetting;
    this.commentIsFullyCollapsed = !commentBlockIsFullyOpenedSetting;
  }
}
