import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { FormHelper } from '../../../document-forms/form-helper';
import { ActionUrgencyModel } from '../../models/urgency/action-urgency.model';

@Injectable({
  providedIn: 'root',
})
/** Сервис для создания и удаления необходимых контролов у формы */
export class FormActionControlService extends FormHelper {
  constructor(private store: Store) {
    super();
  }

  /** Снять с контроля */
  public stop(group: FormGroup, actionValue?: ActionUrgencyModel, decontrolDate?: string): void {
    this.removeControls(group, ['date', 'reportDate', 'urgency']);
    this.addControls(group, [
      { name: 'actionControl', value: actionValue || '', validators: [] },
      { name: 'decontrolDate', value: decontrolDate || '', validators: [Validators.required] },
    ]);
  }

  /** Базовая схема контроллов, которая используется почти во всех типах */
  public baseScheme(group: FormGroup, actionValue?: ActionUrgencyModel, dueDate?: string): void {
    this.removeControls(group, ['decontrolDate', 'reportDate']);
    this.addControls(group, [
      { name: 'actionControl', value: actionValue || '', validators: [] },
      { name: 'date', value: dueDate || '', validators: [Validators.required] },
    ]);
    group.controls.date.enable();
  }

  /** Срок не продлевать, оставить на контроле, установить срок доклада */
  public prologNoControl(group: FormGroup, actionValue: ActionUrgencyModel, dueDate?: string, decDate?: string): void {
    this.removeControls(group, ['reportDate']);
    this.addControls(group, [
      { name: 'actionControl', value: actionValue || '', validators: [] },
      { name: 'date', value: dueDate || '', validators: [Validators.required] },
      { name: 'decontrolDate', value: decDate || '', validators: [Validators.required] },
    ]);
    group.controls.date.enable();
  }

  /** Срок не продлевать, оставить на контроле, установить срок доклада */
  public noPrologWithReport(group: FormGroup, actionValue?: ActionUrgencyModel, reportDate?: string): void {
    this.removeControls(group, ['decontrolDate', 'date', 'urgency']);
    this.addControls(group, [
      { name: 'actionControl', value: actionValue || '', validators: [] },
      { name: 'reportDate', value: reportDate || '', validators: [Validators.required] },
    ]);
  }

  /** Дополнение к блоку */
  public addition(group: FormGroup, actionValue: ActionUrgencyModel, dueDate: string): void {
    this.baseScheme(group, actionValue, dueDate);
    group.controls.date.setValue(dueDate);

    if (!dueDate) {
      const performerId = group.controls.performer.value.id;
      const commissionId = group.controls.commissionId?.value;
      const activeProject = this.store.selectSnapshot(ResolutionState.getActiveProject);
      const mission = activeProject.missions.find((m) => m.id === commissionId);
      const addressee = mission.missionAnswer.addressee.find((a) => a.employee.id === performerId);
      /** FIXME Почему-то не сеттится значение в контрол без setTimeout */
      setTimeout(() => group.controls.date.setValue(addressee.dueDate));
    }

    group.controls.date.disable();
  }
}
