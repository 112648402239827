import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appNotificationsBar]',
})
/** Директива, которая по скроллу подгружает уведомления */
export class NotificationsBarDirective {
  @Output() public canViewIndex = new EventEmitter<number>();

  private defaultViewNotifications = 30;

  constructor(private elementRef: ElementRef) {}

  @HostListener('scroll')
  public onScrollEvent(): void {
    const scrollHeight = this.elementRef.nativeElement.scrollHeight;
    const scrollTop = this.elementRef.nativeElement.scrollTop;
    const heightElement = this.elementRef.nativeElement.offsetHeight;
    if (scrollHeight - scrollTop === heightElement) {
      this.defaultViewNotifications += 30;
      this.canViewIndex.emit(this.defaultViewNotifications);
    }
  }
}
