import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CloseContextMenuInButtonsDecision,
  OpenContextMenuInButtonsDecision,
  ToggleContextMenuInButtonsDecision,
} from './visibility-context-menu-in-buttons-decision.action';

/** открытие/закрытие контекстного меню с кнопками вынесения решения в компоненте ButtonsDecisionComponent */
@Injectable()
@State<boolean>({
  name: 'visibilityContextMenuInButtonsDecision',
  defaults: false,
})
export class VisibilityContextMenuInButtonsDecisionState {
  @Selector()
  public static isVisibleContextMenu(state: boolean): boolean {
    return state;
  }

  @Action(OpenContextMenuInButtonsDecision)
  public openContextMenu({ setState }: StateContext<boolean>): void {
    setState(true);
  }

  @Action(CloseContextMenuInButtonsDecision)
  public closeContextMenu({ setState }: StateContext<boolean>): void {
    setState(false);
  }

  @Action(ToggleContextMenuInButtonsDecision)
  public toggleContextMenu({ setState }: StateContext<boolean>, { value }: ToggleContextMenuInButtonsDecision): void {
    setState(!value);
  }
}
