import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Route } from '@npaShared/models/route/route.models';

import { SetRoute } from './route.action';

@Injectable()
@State<Route | null>({
  name: 'route',
  defaults: null,
})
export class RouteState {
  @Selector()
  public static route(state: Route): Route | null {
    return state;
  }

  @Action(SetRoute)
  public setRoute({ setState }: StateContext<Route>, { action }: SetRoute): void {
    setState(action);
  }
}
