import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeResponseModel } from '@oogShared/models/approval-list/tree-response.model';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';
import { InterceptorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';

import { ResolutionESignService } from '@oogShared/services/resolution/resolution-e-sign.service';
import { FamiliarizationESignService } from '@oogShared/services/familiarization/familiarization-e-sign.service copy';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { Store } from '@ngxs/store';
import { SettingsState } from '@store/settings/settings.state';
import { canSignResolutionWithEsign } from '@oogShared/functions/checking-ability-to-sign-with-esign';
import { ResolutionDraftModel } from '@oogShared/models/resolutions/resolution/resolution-draft.model';
import { CacheTimingState } from '@store/cache-timing/cache-timing.state';
import { GroupViewFlagsModel } from '@oogShared/models/group-view-flags/group-view-flags.model';
import { CreateResolutionModel } from '../models/create-resolution/create-resolution.model';
import { CardDocumentModel } from '../models/documents/card-document.model';
import { DossierResponseModel } from '../models/dossier/dossier-response.model';
import { FamiliarizationModel } from '../models/familiarization/familiarization.model';
import { FilePoolModel } from '../models/resolutions/file-pool.model';
import { ResolutionModel } from '../models/resolutions/resolution/resolution.model';

@Injectable({
  providedIn: 'root',
})
export class ResolutionInteropService {
  constructor(
    private http: HttpClient,
    private resolutionESignService: ResolutionESignService,
    private familiarizationESignService: FamiliarizationESignService,
    private store: Store,
  ) {}

  /** Получить резолюцию по id */
  public getResolution(id: number, loader: boolean = false): Observable<ResolutionModel> {
    const url = Urls.resolutions.find(id);

    return this.http
      .get<BaseApiResponseModel<ResolutionModel>>(url, {
        params: new InterceptorHttpParams(new InterceptorConfig(loader)),
      })
      .pipe(map((val) => val.data));
  }

  /** Получить черновик формы резолюции по id */
  public getResolutionDraftById(id: number): Observable<CreateResolutionModel> {
    const url = Urls.resolutions.draft(id);

    return this.http.get<BaseApiResponseModel<ResolutionDraftModel>>(url).pipe(map((val) => val.data?.resolution));
  }

  /** Получить список вышестоящих проектов */
  public getResolutionTree(id: number, citizenAppeal: boolean = false): Observable<ResolutionModel[]> {
    const url = Urls.resolutions.tree(id);
    const params = new HttpParams().set('isCitizenAppeal', String(citizenAppeal));
    return this.http.get<PaginationBaseModel<ResolutionModel>>(url, { params }).pipe(map((val) => val.data.content));
  }

  /** Утвердить проект резолюции */
  public acceptProjectResolution(resolution: ResolutionModel, card: CardDocumentModel): Observable<boolean> {
    const data: ResolutionModel = card.groupProjects?.groupProjects?.isActive
      ? { ...resolution, isGroup: card.groupProjects?.groupProjects?.isActive }
      : { ...resolution };
    return this.signResolution(data, card, this.sendAcceptProjectResolution.bind(this));
  }

  /** Создание резолюции c подписанием если флаг "Подписывать резолюции ЭП" установлен*/
  public createResolution(resolution: CreateResolutionModel, card: CardDocumentModel): Observable<boolean> {
    return this.signResolution(resolution, card, this.sendRequestToCreateResolution.bind(this));
  }

  /** Создание резолюции по обращению граждан c подписанием если флаг "Подписывать резолюции ЭП" установлен*/
  public createAppealResolution(
    resolution: CreateResolutionModel,
    card: CardDocumentModel,
    questionIds: number[],
  ): Observable<boolean> {
    return this.signAppealResolution(
      resolution,
      card,
      questionIds,
      this.sendRequestToCreateAppealResolution.bind(this),
    );
  }

  /** Ознакомление с резолюцией */
  public reviewResolution(
    body: FamiliarizationModel,
    card: CardDocumentModel,
    user: EmployeeModel,
  ): Observable<boolean> {
    return this.familiarizationResolution(body, card, user);
  }

  /** Получить файлы */
  public getFiles(id: number): Observable<FilePoolModel[]> {
    const url = Urls.resolutions.files(id);
    return this.http.get<PaginationBaseModel<FilePoolModel>>(url).pipe(map((val) => val.data.content));
  }

  /** Получить конкретный файл для просмотра */
  public showFile(id: number, appealMovementId: number): Observable<Blob> {
    const url = Urls.resolutions.file(id, appealMovementId);
    return this.http.get(url, { responseType: 'blob' });
  }

  /** Получить конкретный файл для скачивания */
  public getFile(fileId: number, appealMovementId: number): Observable<Blob> {
    const url = Urls.resolutions.download(fileId, appealMovementId);
    return this.http.get(url, { responseType: 'blob' });
  }

  /** Скачать склеенный файл */
  public downloadMergedFile(appealId: number, appealMovementId: number): Observable<Blob> {
    const url = Urls.resolutions.downloadMerged(appealId, appealMovementId);
    return this.http.get(url, { responseType: 'blob' });
  }

  /** Проверить все ли резолюции в группе просмотрены */
  public isViewedAll(id: number): Observable<boolean> {
    const url = Urls.resolutions.isViewedAll(id);
    return this.http
      .get<BaseApiResponseModel<boolean>>(url, { params: new InterceptorHttpParams(new InterceptorConfig(true)) })
      .pipe(map((val) => val.data));
  }

  /** Проверить все ли резолюции в группе просмотрены */
  public groupViewFlags(id: number): Observable<GroupViewFlagsModel[]> {
    const url = Urls.resolutions.groupViewFlags(id);
    return this.http
      .get<PaginationBaseModel<GroupViewFlagsModel>>(url, {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      })
      .pipe(map((val) => val.data.content));
  }

  /** Получить блок дело */
  public getDossier(id: number): Observable<DossierResponseModel | null> {
    const url = Urls.resolutions.dossier(id);
    if (!id) {
      return of(null);
    }
    return this.http.get<BaseApiResponseModel<DossierResponseModel>>(url).pipe(map((val) => val.data));
  }

  /** Получить файлы в виде картинок */
  public getImagesFiles(id: number, loader: boolean = false, cache: boolean = true): Observable<FilePoolModel[]> {
    const uniqueKey = this.store.selectSnapshot(CacheTimingState.getUniqueKey);
    const url = `${Urls.resolutions.listImages(id)}?frl=${uniqueKey}`;
    return this.http
      .get<PaginationBaseModel<FilePoolModel>>(url, {
        params: new InterceptorHttpParams(new InterceptorConfig(loader, cache)),
      })
      .pipe(map((val) => val.data.content));
  }

  /** Получить дерево для блока дело */
  public getDossierTree(id: number, fullHistory: boolean = false): Observable<TreeResponseModel> {
    const url = Urls.resolutions.dossierTree(id, fullHistory);
    return this.http.get<BaseApiResponseModel<TreeResponseModel>>(url).pipe(map((val) => val.data));
  }

  /** Загрузить файл */
  public uploadFile(blob: Blob, fileName: string, extension: string): Observable<FilePoolModel> {
    const url = Urls.resolutions.upload;
    const formData: FormData = new FormData();
    formData.append('file', blob, `${fileName}.${extension}`);
    return this.http
      .post<BaseApiResponseModel<FilePoolModel>>(url, formData, {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      })
      .pipe(map((val) => val.data));
  }

  private familiarizationResolution(
    body: FamiliarizationModel,
    card: CardDocumentModel,
    user: EmployeeModel,
  ): Observable<boolean> {
    const settings = this.store.selectSnapshot(SettingsState.certificateSettingsOog);

    if (canSignResolutionWithEsign(settings)) {
      return this.familiarizationESignService.injectESign(body.execution, user).pipe(
        first(),
        switchMap((success) => {
          if (success) {
            return this.sendReviewResolution(body, card);
          }
          return of(false);
        }),
      );
    }
    return this.sendReviewResolution(body, card);
  }

  private signResolution(
    resolution: CreateResolutionModel | ResolutionModel,
    card: CardDocumentModel,
    callback: (resolution, card) => Observable<boolean>,
  ): Observable<boolean> {
    const settings = this.store.selectSnapshot(SettingsState.certificateSettingsOog);
    const newResolution = { ...resolution };

    if (canSignResolutionWithEsign(settings)) {
      return this.resolutionESignService.injectESign(newResolution).pipe(
        first(),
        switchMap((success) => {
          if (success) {
            return callback(newResolution, card);
          }
          return of(false);
        }),
      );
    }
    return callback(newResolution, card);
  }

  private signAppealResolution(
    resolution: CreateResolutionModel | ResolutionModel,
    card: CardDocumentModel,
    questionIds: number[],
    callback: (resolution, card, questionIds) => Observable<boolean>,
  ): Observable<boolean> {
    const settings = this.store.selectSnapshot(SettingsState.certificateSettingsOog);
    const newResolution = { ...resolution };

    if (canSignResolutionWithEsign(settings)) {
      return this.resolutionESignService.injectESign(newResolution).pipe(
        first(),
        switchMap((success) => {
          if (success) {
            return callback(newResolution, card, questionIds);
          }
          return of(false);
        }),
      );
    }
    return callback(newResolution, card, questionIds);
  }

  private sendAcceptProjectResolution(resolution: ResolutionModel, card: CardDocumentModel): Observable<boolean> {
    const url = Urls.resolutions.accept(card.appealMovementId);

    return this.http
      .post<BaseApiResponseModel<boolean>>(url, resolution, {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      })
      .pipe(map((val) => val.success));
  }

  private sendRequestToCreateResolution(
    resolution: CreateResolutionModel,
    card: CardDocumentModel,
  ): Observable<boolean> {
    const url =
      card.groupProjects && card.groupProjects?.groupProjects?.isActive
        ? Urls.resolutions.actionWithGroupResolution(card.appealMovementId)
        : Urls.resolutions.create(card.appealMovementId);
    const body = {
      options: {},
      questionIds: [card.questionMovementId],
      resolution,
    };

    return this.http
      .post<BaseApiResponseModel<boolean>>(url, body, {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      })
      .pipe(map((val) => val.success));
  }

  private sendRequestToCreateAppealResolution(
    resolution: CreateResolutionModel,
    card: CardDocumentModel,
    questionIds: number[] = [],
  ): Observable<boolean> {
    const url =
      card.groupProjects && card.groupProjects?.groupProjects?.isActive
        ? Urls.resolutions.actionWithGroupResolution(card.appealMovementId)
        : Urls.resolutions.create(card.appealMovementId);
    const body = {
      options: {},
      questionIds,
      resolution,
    };

    return this.http
      .post<BaseApiResponseModel<boolean>>(url, body, {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      })
      .pipe(map((val) => val.success));
  }

  private sendReviewResolution(body: FamiliarizationModel, card: CardDocumentModel): Observable<boolean> {
    const url = Urls.resolutions.review(card.appealMovementId);
    return this.http
      .post<BaseApiResponseModel<boolean>>(url, body, {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      })
      .pipe(map((val) => val.success));
  }
}
