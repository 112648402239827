/** Установить флаг canOnlyReturnApprovalList */
export class SetCanOnlyReturnApprovalList {
  public static readonly type = '[ApprovalListStore] set canOnlyReturnApprovalList flag';
  constructor(public canOnlyReturnApprovalList: boolean) {}
}

/** Сбросить стор ApprovalListStore */
export class ResetApprovalListStore {
  public static readonly type = '[ApprovalListStore] reset approval list store';
}
