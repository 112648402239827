export enum StatisticsEnum {
  mobileAllPackage = 'mobileAllPackage',

  mobileInternal = 'mobileInternal',

  mobileExternal = 'mobileExternal',

  mobileNotRead = 'mobileNotRead',

  mobileNotReadExternal = 'mobileNotReadExternal',

  mobileNotReadInternal = 'mobileNotReadInternal',

  mobileImmediately = 'mobileImmediately',

  mobileImmediatelyExternal = 'mobileImmediatelyExternal',

  mobileImmediatelyInternal = 'mobileImmediatelyInternal',

  mobileUrgently = 'mobileUrgently',

  mobileUrgentlyExternal = 'mobileUrgentlyExternal',

  mobileUrgentlyInternal = 'mobileUrgentlyInternal',

  /** Все проекты актов */
  mobileOfficeStatisticDraftActs = 'mobileOfficeStatisticDraftActs',
  /** Незамедлительные акты */
  mobileOfficeImmediately = 'mobileOfficeImmediately',
  /** Срочные акты */
  mobileOfficeUrgently = 'mobileOfficeUrgently',
  /** Вынести решение */
  mobileOfficeMakeDecision = 'mobileOfficeMakeDecision',
  /** Вынести решение -> На подпись */
  mobileOfficeMakeDecisionWithSign = 'mobileOfficeMakeDecisionWithSign',
  /** Вынести решение -> Без подписи */
  mobileOfficeMakeDecisionWithoutSign = 'mobileOfficeMakeDecisionWithoutSign',
  /** Новые */
  mobileOfficeStatisticNotReadDraftActs = 'mobileOfficeStatisticNotReadDraftActs',
  /** Расписать */
  mobileOfficeApprovementWrite = 'mobileOfficeApprovementWrite',
  /** На Исполнении */
  mobileOfficeWorkingExecutor = 'mobileOfficeWorkingExecutor',
  /** На ознакомлении */
  mobileOfficeIntroducer = 'mobileOfficeIntroducer',
  /** Правовые акты Мэра Москвы и ПМ */
  mobileLegalActs = 'mobileLegalActs',
  /** Локальные правовые акты и документы */
  mobileLocalLegalActs = 'mobileLocalLegalActs',
}
