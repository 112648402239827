export enum ResolutionTypeEnum {
  // Направление
  heading = 1,
  // Резолюция
  resolution = 2,
  // Проект резолюции
  resolutionProject = 3,
  // Проектная резолюция
  projectResolution = 4,
  // Утвержденная резолюция
  approvedResolution = 5,
  // Запрос
  request = 6,
  // Переадресация
  forwarding = 7,
  // Проект запроса
  requestProject = 8,
  // Проект переадресации
  forwardingProject = 9,
  // Проектный запрос
  projectRequest = 10,
  // Проектная переадресация
  projectForwarding = 11,
  // Утвержденный запрос
  approvedRequest = 12,
  // Утвержденная переадресация
  approvedForwarding = 13,
  // Системное направление
  systemDirection = 14,
  // Общее (по умолчанию)
  main = 15,
}
