import { CertificateItemModel } from '@models/esigns/certificate-item.model';

/**
 * Надо ли заменить сохранённый сертификат на дефолтный на форме подписания/согласования
 *
 * @param savedCert сохранённый в настройках сертификат
 * @param certificates список всех сертификатов
 *
 * @returns
 * - true если дефолтный сертификат должен быть пересчитан
 * - false если дефолтный сертификат это тот серт который в настройках сохранён
 */
export const needChangingSavedCertificate = (
  savedCert: CertificateItemModel,
  certificates: CertificateItemModel[],
): boolean => {
  if (!savedCert) {
    return true;
  }

  if (!certificates.length) {
    return true;
  }

  /** у локальных сертификатов id строка, а у облачных число */
  return !certificates.some((cert) => cert.id.toString() === savedCert.id.toString());
};
