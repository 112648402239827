import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectBase } from '@oogShared/components/controls/select-base';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DepartmentModel } from '@oogShared/models/resolutions/department/department.model';

@UntilDestroy()
@Component({
  selector: 'app-select-department',
  templateUrl: './select-department.component.html',
  styleUrls: ['./select-department.component.scss'],
})
export class SelectDepartmentComponent extends SelectBase implements OnInit {
  @Input() public options: DepartmentModel[] = [];

  @Output() public selectDepartment = new EventEmitter<DepartmentModel>();

  public ngOnInit(): void {
    this.subscribeToSelect();
  }

  /** Выбор значения из списка */
  public selectOption(value: DepartmentModel): void {
    this.setControlValue(value);
    this.filterInput = '';
    this.showValue = `${value.fullName}`;
    this.selectDepartment.emit(value);
    this.activeOption = false;
  }

  private subscribeToSelect(): void {
    const control = this.parentFormGroup.controls[this.controlName];

    if (this.showValueOnInit && !!control.value) {
      this.showValue = `${control.value.fullName}`;
    }

    control.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
      this.showValue = data ? `${data.fullName}` : '';
    });
  }
}
