import { FileEventModel } from '../models/resolutions/file-event.model';

export const fileDefault: FileEventModel = {
  id: null,
  url: '',
  name: '',
  size: 0,
  extension: '',
  pages: [],
};
