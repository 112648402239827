export enum SettingsMenuEnum {
  notification = 'notification',
  document = 'document',
  substitute = 'substitute',
  about = 'about',
  folders = 'personalFolder',
  certificate = 'certificate',
  reports = 'reports',
  mobileModeration = 'mobileModeration',
}
