/**
 * енам кодов для фильтров
 */
export enum FilterCodesEnum {
  all = 'all',
  clear = 'clear',
  today = 'today',
  week = 'week',
  external = 'external',
  internal = 'internal',
  urgently = 'urgently',
  immediately = 'immediately',
}
