import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SelectTypeModel } from '@models/dictionaries/select-type.model';

import { Select, Store } from '@ngxs/store';
import { ModalController } from '@ionic/angular';
import { CheckboxThemeEnum } from '@enums/checkbox-theme.enum';
import { ConfirmDialogService } from '@oogShared/services/confirm-dialog.service';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { DischargeDutiesService } from '@oogShared/services/discharge-of-duties/discharge-duties.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EsignOogService } from '@oogShared/services/esign-oog.service';
import { changeUserSession } from '@oogShared/functions/change-user-session.function';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { UrgencyReviewTypeModel } from '@oogShared/models/resolutions/urgency-review-type.model';
import { defaultValueUrgency } from '@oogShared/consts/default-value-urgency.const';
import { modalIdSpreadExecutor } from '@const/modals-id.const';
import { FormRedirectAppealResolutionService } from '@oogShared/services/forms/form-redirect-appeal-resolution.service';
import { DictionariesTemplateState } from '@store/dictionaries/dictionaries-templates/dictionaries-template.state';
import { Observable } from 'rxjs';
import { RedirectResolutionDraftService } from '@oogShared/services/forms/draft/redirect-resolution-draft.service';
import { DictionaryTemplateTypes } from '@enums/dictionary-template-types/dictionary-template-types.enums';
import { SpreadExecutorModalComponent } from '../components/spread-executor-modal/spread-executor-modal.component';

import { AppealRedirectFormBase } from '../appeal-redirect-form-base';

@Component({
  selector: 'app-appeal-redirect-resolution',
  templateUrl: './appeal-redirect-resolution.page.html',
  styleUrls: ['./appeal-redirect-resolution.page.scss'],
})
export class AppealRedirectResolutionPage extends AppealRedirectFormBase implements OnInit, AfterViewInit {
  @Select(DictionariesTemplateState.templates([DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.heading]))
  public templates$!: Observable<SelectTypeModel[]>;

  public checkboxTheme = CheckboxThemeEnum;

  constructor(
    dialog: ConfirmDialogService,
    formRedirect: FormRedirectAppealResolutionService,
    dictionariesInterop: DictionariesInteropService,
    modalCtrl: ModalController,
    store: Store,
    currentUser: CurrentUserIsproStoreService,
    dutiesService: DischargeDutiesService,
    fb: FormBuilder,
    restoreFormService: RedirectResolutionDraftService,
    private cdr: ChangeDetectorRef,
    private esignService: EsignOogService,
  ) {
    super(
      store,
      currentUser,
      formRedirect,
      dialog,
      dictionariesInterop,
      dutiesService,
      modalCtrl,
      fb,
      restoreFormService,
    );
  }

  public ngOnInit(): void {
    changeUserSession(this.store, this.currentUser);
    this.userInfo();
    this.headerData = this.formRedirect.mapRedirectFormHeader();
    this.initForm();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public submitForm(): void {
    if (this.esignService.isInvalidCertificateSigningResolution()) {
      this.esignService.showModalInvalidCertificate();
      return;
    }

    this.addresseeInCommissions() ? this.emptyCommissions() : this.formRedirect.redirectResolution(this.questionIds);
  }

  /** Получить список адресатов */
  public getAddresseeArray(question: AbstractControl): FormArray {
    const commission = this.getCommissionByQuestion(question);
    return commission.get('addressee') as FormArray;
  }

  /** Вставить выбранного пользователя в форму */
  public chooseAddressee(question: AbstractControl, addressee: EmployeeModel, questionIndex: number): void {
    const commission = this.getCommissionByQuestion(question);
    const addresseesFormArray = commission.controls.addressee as FormArray;
    addresseesFormArray.push(this.createAddresseeFormGroup(addressee, defaultValueUrgency));
    const employees = addresseesFormArray.value.map((p) => p.addressee).filter((p) => p.id === addressee.id);
    commission.controls.performer.setValue(addressee);
    this.showChooseAddressee = false;

    this.setCloneControl(addresseesFormArray, employees);

    this.dutiesService.dischargeOfDuties(
      addresseesFormArray.controls[addresseesFormArray.length - 1] as FormGroup,
      addressee.id,
      addresseesFormArray,
      addresseesFormArray.length - 1,
      'addressee',
    );

    if (questionIndex === 0) {
      // если исполнитель добавлен в первый вопрос
      // Вызываем модальное окно добавления пользователей во все поручения
      this.showSpreadExecutorModal(addressee);
    }
  }

  /** Отследить переключение признака "Без исполнителей" */
  public handleExecutorSignChange(formGroup: FormGroup): void {
    const addressee = formGroup.controls.addressee as FormArray;
    addressee.controls.forEach((p: FormGroup) => {
      const executorSign = formGroup.controls.executorSign.value;
      p.controls.familiarization.setValue(executorSign);
      this.removeAndAddControls(p);
    });
  }

  /** Показать модально окно для добавления исполнителя из первого поручения в остальные */
  private async showSpreadExecutorModal(addressee: EmployeeModel): Promise<void> {
    const questions = [...this.getQuestionArray().controls];
    questions.shift();

    const commissionForSpread = this.commissionsForSpreadExecutor();

    if (!commissionForSpread.length) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: SpreadExecutorModalComponent,
      componentProps: {
        executor: addressee,
        commissions: commissionForSpread,
      },
      cssClass: 'full-screen-with-background',
      id: modalIdSpreadExecutor,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    questions.forEach((question, index) => {
      if (data?.sprededCommissionIdxs.includes(index)) {
        this.chooseAddressee(question, addressee, 1);
      }
    });
  }

  /** Инициализация формы */
  private initForm(): void {
    this.form = this.fb.group({
      questions: this.fb.array(this.createQuestionsArray()),
    });
  }

  /** Создать группу контролов для добавления адресата */
  private createAddresseeFormGroup(addressee: EmployeeModel, urgency: UrgencyReviewTypeModel): FormGroup {
    const familiarization = this.form.controls.executorSign?.value;
    const group = this.fb.group({
      addressee: [addressee || ''],
      urgency: [urgency || ''],
      familiarization: [familiarization || false],
      notes: [''],
    });

    this.removeAndAddControls(group);

    return group;
  }

  /** Есть ли исполнители в поручении */
  private addresseeInCommissions(): boolean {
    const commissions = this.getQuestionArray().controls.map((question) => question.get('commission'));
    const emptyAddressee = commissions.filter((c) => {
      const executorSign = c.get('executorSign');
      const addressee = c.get('addressee') as FormArray;
      return !executorSign?.value && !addressee.length;
    });

    return this.onlyFamiliarization() || !!emptyAddressee.length;
  }

  /** Проверить, что в поручении исполнители только для ознакомления */
  private onlyFamiliarization(): boolean {
    const commissions = this.getQuestionArray().controls.map((question) => question.get('commission'));
    const emptyAddressee = commissions.filter((c) => {
      const executorSign = c.get('executorSign');
      const addressee = c.get('addressee') as FormArray;
      const performersFamiliarization = addressee.controls.filter((p: FormGroup) => !!p.controls.familiarization.value);
      return !executorSign?.value && performersFamiliarization.length === addressee.length;
    });
    return !!emptyAddressee.length;
  }

  private emptyCommissions(): void {
    const commissions = this.getQuestionArray().controls.map((question) => question.get('commission'));
    const commissionIdx: number[] = [];
    commissions.forEach((c: AbstractControl, index: number) => {
      const executorSign = c.get('executorSign');
      const addressee = c.get('addressee') as FormArray;
      if (!executorSign?.value && !addressee.length) {
        commissionIdx.push(index);
      }
      if (!executorSign?.value && addressee?.controls?.every((a: FormGroup) => a.controls?.inPlus?.value)) {
        commissionIdx.push(index);
      }
    });
    this.commissionWithoutPerformers = commissionIdx;
    this.clearCommissionWithoutPerformers();
  }

  /** Обнулить массив с поручениями без исполнителя через интервал времени */
  private clearCommissionWithoutPerformers(): void {
    setTimeout(() => (this.commissionWithoutPerformers = []), 2000);
  }
}
