import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'responsible',
})
export class ResponsiblePipe implements PipeTransform {
  public transform(value: boolean): string {
    return value ? ' (отв)' : '';
  }
}
