import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngxs/store';
import { OogUserShortNamePipe } from '@shared/pipes/oog-user-short-name/oog-user-short-name.pipe';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';

@Pipe({
  name: 'returnAuthor',
})
/** Пайп возвращает строку с названием кнопки и ФИО */
export class ReturnAuthorPipe implements PipeTransform {
  constructor(private store: Store, private shortPerformer: OogUserShortNamePipe) {}

  public transform(title: string): string {
    const author = this.store.selectSnapshot(ActiveCardState.author);
    return `${title} (${this.shortPerformer.transform(author)})`;
  }
}
