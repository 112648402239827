import { ProjectGroupTypeEnum } from '@enums/project-group-type.enum';
import { DocumentPackageResponseModel } from '@npaShared/models/document-package/document-package.response.model';

/** является ли переданный ПД нормативно правовым актом - НПА */
export const isLegalActDocumentPackage = (documentPackage: DocumentPackageResponseModel): boolean =>
  documentPackage?.projectType?.projectGroupType?.id === ProjectGroupTypeEnum.LEGAL_ACTS;

/** является ли переданный ПД локально правовым актом - ЛПА */
export const isLocalLegalActDocumentPackage = (documentPackage: DocumentPackageResponseModel): boolean =>
  documentPackage?.projectType?.projectGroupType?.id === ProjectGroupTypeEnum.LOCAL_LEGAL_ACTS;
