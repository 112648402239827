import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetHasDssCurrentUser } from './has-dss-current-user.action';

@Injectable()
@State<boolean>({
  name: 'hasDssCurrentUser',
  defaults: false,
})
export class HasDssCurrentUserState {
  @Selector()
  public static hasDss(state: boolean): boolean {
    return state;
  }

  @Action(SetHasDssCurrentUser)
  public setHasDssCurrentUser({ setState }: StateContext<boolean>, { value }: SetHasDssCurrentUser): void {
    setState(value);
  }
}
