import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FoldersStateService } from '@oogShared/services/folders/folders-state.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, finalize, first, mapTo, takeUntil, tap } from 'rxjs/operators';

import { Store } from '@ngxs/store';
import { ResetDocumentList, SetCountDocuments } from '@store/menu/count-documents-menu/count-documents-menu.action';
import {
  ResetDocumentsCards,
  SetDocumentsCards,
  SetDocumentsCardsFromSearch,
} from '@store/menu/documents-cards/documents-cards.action';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';
import { ResetMainIdSearch, ResetSearch, SetShownGroup } from '@store/menu/sidebar/sidebar.action';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';

import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { ResetResolutionTree } from '@store/resolution-store/resolution-tree/resolution-tree.action';
import { ResetResolutionFiles } from '@store/resolution-store/resolution-files/resolution-files.action';
import { ResetDossier } from '@store/resolution-store/dossier/dossier.action';
import { ResetActiveCardProjectResolution } from '@store/resolution-store/active-resolution-project/active-resolution-project.action';
import { ResetForm } from '@store/forms/forms.action';
import { SetActiveDocumentCard } from '@store/menu/active-card/active-card.action';
import { ModalController } from '@ionic/angular';
import { LoadStatusEnum } from '@enums/load-status.enum';
import {
  ResetDocumentGroupState,
  SetGroupCards,
  SetGroupCardStatus,
} from '@store/menu/documents-group/documents-group.action';
import { ModalService } from '@shared/services/modal.service';
import { SetPreviousActiveDocument } from '@store/menu/previous-active-document/previous-active-document.action';
import { ResetArrowState } from '@store/menu/arrow-buttons/arrow-buttons.action';
import { ResetDocumentsCategory } from '@store/menu/documents-category/documents-category.action';
import { DocumentCardsInteropService } from '../rest/document-cards-interop.service';
import { PersonalFolderDocuments } from '../models/folders/personal-folder-documents.model';
import { FiltersRequest } from '../models/filter/filter-request.model';
import { MenuDocumentModel } from '../models/documents/menu-document.model';
import { CardDocumentModel } from '../models/documents/card-document.model';
import { routeNames } from '../../../app-routing/route-names.const';

@Injectable({
  providedIn: 'root',
})
export class MenuHelperService implements OnDestroy {
  public isLoad$ = new BehaviorSubject<boolean>(true);
  public isLoadRefresh$ = new BehaviorSubject<boolean>(false);
  public scrollTop$ = new BehaviorSubject<boolean>(false);
  public lengthPreviousData$ = new BehaviorSubject<number>(1);
  public filteredLoad$ = new Subject<CardDocumentModel>();

  private requestSubscribe = new Subscription();
  private newFolderRequest$ = new Subject();

  constructor(
    private store: Store,
    private cardsInterop: DocumentCardsInteropService,
    private router: Router,
    private foldersInterop: FoldersStateService,
    private modalCtrl: ModalController,
    private modalService: ModalService,
  ) {}

  public ngOnDestroy(): void {
    this.store.dispatch(ResetSearch);
    this.requestSubscribe.unsubscribe();
  }

  public refreshToMenuList(refresh: boolean, body?: FiltersRequest): Observable<MenuDocumentModel> {
    const searchValue = this.store.selectSnapshot(SidebarState.searchValue) || '';
    const length = this.store.selectSnapshot(DocumentsCardsState.getCards)?.length;
    this.isLoadRefresh$.next(true);
    return this.cardsInterop
      .getResolutionsCards(true, 0, length < 30 ? 30 : length, searchValue, body, refresh)
      .pipe(finalize(() => this.isLoadRefresh$.next(false)));
  }

  public refreshToFoldersList(docs: PersonalFolderDocuments[], body?: FiltersRequest): Observable<MenuDocumentModel> {
    const searchValue = this.store.selectSnapshot(SidebarState.searchValue) || '';
    const length = this.store.selectSnapshot(DocumentsCardsState.getCards)?.length;
    const docsId = docs.map((d) => d.documentId);
    if (!docsId.length) {
      return;
    }
    return this.cardsInterop.getResolutionsCardsFromFolders(
      docsId,
      true,
      0,
      length < 30 ? 30 : length,
      searchValue,
      body,
    );
  }

  public refreshDocumentGroup(groupId: number): Observable<MenuDocumentModel> {
    return this.cardsInterop.getResolutionsGroupCards(groupId).pipe(
      first(),
      tap((res) => {
        const status = !!res.cards?.length ? LoadStatusEnum.success : LoadStatusEnum.error;
        this.store.dispatch([new SetGroupCards(res.cards), new SetGroupCardStatus(status)]);

        if (status === LoadStatusEnum.error) {
          this.presentErrorGroup();
        }
      }),
    );
  }

  public firstLoadMenu(): void {
    this.isLoad$.next(true);
    this.foldersInterop.getFoldersFromApi().pipe(first()).subscribe();
    this.getResolutionCards()
      .pipe(
        first(),
        filter(() => Boolean(this.isLoad$.value)),
      )
      .subscribe((cards) => {
        this.finishLoadingDocuments(cards);
      });
  }

  public loadToMenuList(body?: FiltersRequest, refresher?: any): void {
    this.isLoad$.next(true);
    this.requestSubscribe.unsubscribe();

    this.requestSubscribe = this.getResolutionCards(body)
      .pipe(first())
      .subscribe((result) => {
        this.finishLoadingDocuments(result);
        refresher?.target.complete();
      });
  }

  public async loadToMenuListAsync(body?: FiltersRequest): Promise<void> {
    const result = await this.getResolutionCards(body).toPromise();

    this.finishLoadingDocuments(result);
  }

  public presentErrorGroup(): void {
    this.modalService
      .presentModalError('Группа резолюций уже утверждена. Необходимо обновить страницу', true)
      .then((result) => {
        if (!result) {
          return;
        }

        this.refreshToMenuListAfterError();
      });
  }

  public refreshToMenuListAfterError(): void {
    this.refreshToMenuList(true)
      .pipe(first())
      .subscribe((result) => {
        this.store.dispatch([
          new SetShownGroup(false),
          ResetResolution,
          ResetResolutionTree,
          ResetResolutionFiles,
          ResetDossier,
          ResetActiveCardProjectResolution,
          ResetForm,
        ]);
        this.modalCtrl.dismiss().then();
        this.setDataToStore(result);
      });
  }

  public getSearchData(scrollTop: boolean, body?: FiltersRequest): Observable<void> {
    const searchValue = this.store.selectSnapshot(SidebarState.searchValue);
    this.isLoad$.next(true);
    return this.cardsInterop.getResolutionsCards(true, 0, 30, searchValue, body).pipe(
      tap((result) => {
        this.isLoad$.next(false);
        this.store.dispatch([new SetDocumentsCardsFromSearch(result.cards), new SetCountDocuments(result.totalCount)]);
        scrollTop ? this.scrollTop$.next(scrollTop) : this.scrollTop$.next(false);
        this.filteredLoad$.next(this.cardToOpen(result.cards));
        this.resetMainIdSearch();
        this.lengthPreviousData$.next(result.cards?.length);
      }),
      mapTo(void 0),
    );
  }

  public loadDocumentsInMenuFromPersonalFolder(
    docs: PersonalFolderDocuments[],
    search?: boolean,
    body?: FiltersRequest,
  ): Observable<void> {
    const searchValue = this.store.selectSnapshot(SidebarState.searchValue) || '';

    const docsId = docs.map((d) => d.documentId);
    if (!docsId.length) {
      return;
    }
    this.isLoad$.next(true);
    this.newFolderRequest$.next();

    return this.cardsInterop.getResolutionsCardsFromFolders(docsId, true, 0, 30, searchValue, body).pipe(
      tap((result) => this.finishLoadingDocuments(result, search)),
      mapTo(void 0),
      takeUntil(this.newFolderRequest$),
    );
  }

  public resetMenuStateToDefault(): void {
    // reset all menu state to initial values
    this.store.dispatch([
      new SetActiveDocumentCard(null),
      new SetPreviousActiveDocument(null),
      ResetArrowState,
      ResetDocumentsCategory,
      ResetDocumentsCards,
      ResetDocumentList,
      ResetDocumentGroupState,
    ]);
  }

  private getResolutionCards(body?: FiltersRequest): Observable<MenuDocumentModel> {
    const searchValue = this.store.selectSnapshot(SidebarState.searchValue) || '';
    const length = this.store.selectSnapshot(DocumentsCardsState.getCards).length;

    return this.cardsInterop.getResolutionsCards(false, length, 30, searchValue, body);
  }

  private finishLoadingDocuments(result: MenuDocumentModel, search?: boolean): void {
    if (this.router.isActive(routeNames.documents, false)) {
      search
        ? this.store.dispatch(new SetDocumentsCardsFromSearch(result.cards))
        : this.store.dispatch(new SetDocumentsCards(result.cards));
      this.store.dispatch(new SetCountDocuments(result.totalCount));
    }
    this.isLoad$.next(false);
    this.lengthPreviousData$.next(result.cards?.length);
  }

  private setDataToStore(data: MenuDocumentModel): void {
    this.store.dispatch(new SetDocumentsCardsFromSearch(data.cards));
    this.store.dispatch(new SetCountDocuments(data.totalCount));
    const firstCard = this.store.selectSnapshot(DocumentsCardsState.getFirstCard);
    this.store.dispatch(new SetActiveDocumentCard(firstCard));
  }

  private cardToOpen(cards: CardDocumentModel[]): CardDocumentModel {
    const mainIdSearch = this.store.selectSnapshot(SidebarState.mainIdSearch);

    return cards.find((c) => c.mainId === mainIdSearch) || cards[0];
  }

  private resetMainIdSearch(): void {
    const mainIdSearch = this.store.selectSnapshot(SidebarState.mainIdSearch);

    if (mainIdSearch) {
      this.store.dispatch(ResetMainIdSearch);
    }
  }
}
