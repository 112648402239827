import { Directive, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IonContent, IonList } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { WorkForEveryoneState } from '@store/main-screen/work-for-everyone/work-for-everyone.state';
import { CountDocumentsMenuState } from '@store/menu/count-documents-menu/count-documents-menu.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ApprovalActionBtnTypeEnum } from '@oogShared/enums/approval-list/approval-action-btn-type.enum';
import { SwipeMenuService } from '@oogShared/services/swipe-menu.service';
import { AppealResolutionCreatePage } from '../../document-forms/appeal-resolution-create/appeal-resolution-create.page';
import { ResolutionCreatePage } from '../../document-forms/resolution-create/resolution-create.page';

@Directive()
export class SideBarBase {
  @Input() public notesEmployee = '';
  @Input() public userInfo!: EmployeeModel;

  @Select(WorkForEveryoneState.workForEveryone)
  public workForEveryone$: Observable<boolean>;

  @Select(CountDocumentsMenuState.countDocuments)
  public countDocuments$: Observable<number>;

  @ViewChild('sideBarList') public sideBarList: IonList;
  @ViewChild('documentList') public documentList: IonContent;

  protected unsubscribe$: Subject<void> = new Subject<void>();

  constructor(protected store: Store, protected swipeMenu: SwipeMenuService, private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public hideSliding(event: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(event)) {
      this.sideBarList?.closeSlidingItems()?.then();
    }
  }

  public trackByFn(index: number): number {
    return index;
  }

  /** Показать диалоговое окно для ознакомления */
  public showFamiliarizationDialog(project: boolean): void {
    this.sideBarList.closeSlidingItems().then();
    this.swipeMenu.showFamiliarizationDialog(project);
  }

  /** Показать модальное окно для создания резолюции */
  public showModalCreateResolution(): void {
    const citizenAppeal = this.store.selectSnapshot(ActiveCardState.isCitizenAppeal);
    const component = citizenAppeal ? AppealResolutionCreatePage : ResolutionCreatePage;
    this.sideBarList.closeSlidingItems().then();
    this.swipeMenu.showModalCreateResolution(component);
  }

  /** Показать модальное окно для перенаправления резолюции/СПР */
  public showModalRedirectResolution(component: any): void {
    this.sideBarList.closeSlidingItems().then();
    this.swipeMenu.showModalRedirectResolution(component);
  }

  /** Показать модальное окно для перенаправления ЛС */
  public showModalActionApproval(actionType: ApprovalActionBtnTypeEnum): void {
    this.sideBarList.closeSlidingItems().then();
    if (actionType === ApprovalActionBtnTypeEnum.approveSign) {
      this.swipeMenu.sign();
      return;
    }
    this.swipeMenu.showModalActionApproval(actionType);
  }

  /** Показать диалоговое окно для согласования ЛС */
  public showCoordinateApproval(): void {
    this.sideBarList.closeSlidingItems().then();
    this.swipeMenu.showCoordinateApproval();
  }
}
