import { Pipe, PipeTransform } from '@angular/core';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';

/** Проверяет является ли карточка главной в групповом согласовании */
@Pipe({
  name: 'isMainCardInGroup',
})
export class IsMainCardInGroupPipe implements PipeTransform {
  public transform(card: CardDocumentModel): boolean {
    if (!card?.groupProjects) {
      return false;
    }
    return (
      card.groupProjects.groupProjects.isActive &&
      card.groupProjects.groupProjects.mainAppealMovementId === card.appealMovementId
    );
  }
}
