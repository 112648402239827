import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { CheckboxThemeEnum } from '@enums/checkbox-theme.enum';
import { SelectTypeModel } from '@models/dictionaries/select-type.model';

/** Компонент для отображения событий, которые можно совершать с поручением */
@Component({
  selector: 'app-commission-action',
  templateUrl: './commission-action.component.html',
  styleUrls: ['./commission-action.component.scss'],
})
export class CommissionActionComponent implements OnInit {
  @Input() public title = '';
  @Input() public first = false;
  @Input() public templates: SelectTypeModel[] = [];
  @Input() public disabledPrevious = false;
  @Input() public disabledClearButton = true;
  @Input() public showTemplateButton = true;
  @Input() public showPrevious = true;
  @Input() public showControlExecutorSign = false;
  @Input() public formGroup: FormGroup = new FormGroup({});
  @Input() public formIdx = 0;
  @Input() public showSignNeedAttention = false;
  @Input() public showSignExpeditedReview = false;
  @Input() public showCommentConfidential = false;
  @Input() public showAudioAndGraphic = false;
  @Input() public showCopyPreviousCommission = false;
  @Input() public formHasAudioOrGraphic = false;
  @Input() public statusShowingGraphicFromSettings = false;
  @Input() public statusShowingAudioFromSettings = false;
  @Input() public showPrivateComment = false;
  @Input() public templateGrid = false;

  @Output() public clear: EventEmitter<void> = new EventEmitter<void>();
  @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
  @Output() public template: EventEmitter<string> = new EventEmitter<string>();
  @Output() public executeSign: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() public expeditedReviewChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() public createPicture: EventEmitter<void> = new EventEmitter<void>();
  @Output() public editPicture: EventEmitter<void> = new EventEmitter<void>();
  @Output() public deletePicture: EventEmitter<void> = new EventEmitter<void>();
  @Output() public copyPreviousCommission: EventEmitter<number> = new EventEmitter<number>();

  public buttonTheme = ButtonThemeEnum;
  public form = new FormGroup({});
  public showForm = false;
  public checkboxTheme = CheckboxThemeEnum;
  public audioRecord = false;
  public uniqueKey = Math.random();

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public selectTemplate(value: SelectTypeModel): void {
    this.showForm = !this.showForm;
    this.template.emit(value.name);
  }

  public toggleForm(event: MouseEvent): void {
    event.stopPropagation();
    this.showForm = !this.showForm;
  }

  public closeForm(): void {
    this.showForm = false;
  }

  private initForm(): void {
    this.form = this.fb.group({
      template: [''],
    });
  }
}
