import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserCardService } from '@shared/services/user-card.service';

/** Компонент для формы поиска карточек документов */
@Component({
  selector: 'app-document-search',
  templateUrl: './document-search.component.html',
  styleUrls: ['./document-search.component.scss'],
})
export class DocumentSearchComponent implements OnInit {
  /** задержка на отправку автоматического запроса */
  @Input() public delayOnAutoSubmit: number = null;
  @Input() public searchText = '';
  @Input() public isDisabled = false;

  @Output() public search: EventEmitter<string> = new EventEmitter<string>();

  public form: FormGroup = new FormGroup({});

  constructor(private userCardService: UserCardService) {}

  public ngOnInit(): void {
    this.form.addControl('search', new FormControl(this.searchText));
  }

  public submitForm(searchValue: string): void {
    this.userCardService.destroyCard();
    this.search.emit(searchValue);
  }
}
