import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AddToTreeStoreModel } from '@npaShared/models/agreements/add-to-tree-store.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentAddToTreeEmployeeStoreService {
  private readonly defaultState: AddToTreeStoreModel = {
    activePoint: null,
  };

  private state$: BehaviorSubject<AddToTreeStoreModel> = new BehaviorSubject({ ...this.defaultState });

  public reset(): void {
    this.state$.next({ ...this.defaultState });
  }

  public setState(state: Partial<AddToTreeStoreModel>): void {
    this.state$.next({ ...this.state$.value, ...state });
  }

  public getState(): Observable<AddToTreeStoreModel> {
    return this.state$;
  }

  public getStateSnapshot(): AddToTreeStoreModel {
    return this.state$.value;
  }
}
