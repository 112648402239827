import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';
import { WorkForEveryoneState } from '@store/main-screen/work-for-everyone/work-for-everyone.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';

import { routeNames } from '../../../../app-routing/route-names.const';

/**
 * Перехватчик http запросов для подмены сессии
 * Интерсептор проверяет работает ли пользователь в режиме "Работа за всех"
 * Если работаем за всех, то в запрос подставляются параметр:
 * transferredRightId: id пользователя на которого нужно подменить сессию, null - если текущий
 */
@Injectable()
export class HttpInterceptorSessionService implements HttpInterceptor {
  constructor(private store: Store, private router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !req.url.includes('oog-mobile') ||
      this.findInvalidUrl(req.url) ||
      !this.router.isActive(routeNames.documents, false)
    ) {
      return next.handle(req).pipe();
    }

    const workForEveryone = this.store.selectSnapshot(WorkForEveryoneState.workForEveryone);
    const activeCard = this.store.selectSnapshot(ActiveCardState.getActiveCard);

    if (workForEveryone && activeCard) {
      const id = activeCard.transferredRight ? activeCard.transferredRight.id.toString() : null;
      if (id) {
        req = req.clone({
          params: req.params.set('transferredRightId', id),
        });
      }
      return next.handle(req).pipe();
    }
    return next.handle(req).pipe();
  }

  private findInvalidUrl(url: string): boolean {
    /** Массив с урлами, на которые не нужно вешать дополнительные параметры */
    const array = [
      '/mobile/documents/',
      '/dictionaries/',
      '/mobile/frequently-used-executors',
      '/mobile/special-employee',
      '/mobile/date/',
      '/resolution/resolution-templates',
      '/transfer-rights/current',
      '/npa/api/',
    ];
    return array.some((u) => url.includes(u));
  }
}
