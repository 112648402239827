import { ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { zoomSettings } from '@oogShared/components/file-viewer/consts/zoom-settings.const';
import { FileViewerHelperService } from '@oogShared/components/file-viewer/services/file-viewer-helper.service';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';
import { AndroidPermissionService } from '@shared/services/permissions/android-permission.service';
import { ModalService } from '@shared/services/modal.service';
import { ActiveProjectExpeditedReviewState } from '@store/expedited-review/active-project/active-project-expedited-review.state';
import { MenuExpeditedReviewState } from '@store/expedited-review/menu/menu-expedited-review.state';
import { SetStateFullScreen } from '@store/file-full-screen/file-full-screen.action';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { FileTypeEnum } from '@enums/file-type.enum';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { IonContent, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';

import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { fileDefault } from '../consts/file-default.const';
import { FileEventModel } from '../models/resolutions/file-event.model';
import { FilePoolModel } from '../models/resolutions/file-pool.model';
import { ResolutionInteropService } from '../rest/resolution-interop.service';

@Directive()
export class BaseFiles {
  @Input() public content: IonContent;
  /** appealId для переключения документов в "Блок на №"  */
  @Input() public appealId: number;
  /** appealMovementId для переключения документов в "Блок Дело"  */
  @Input() public dossierAppealMovementId: number;

  @Input() public set dataFiles(value: FilePoolModel[]) {
    if (!value?.length) {
      this.files = [];
      this.file = { ...fileDefault };
      return;
    }

    this.files = this.sortByFileTypeAndUploadDate(value, [
      FileTypeEnum.pages,
      FileTypeEnum.main,
      FileTypeEnum.annex,
      FileTypeEnum.coverLetter,
      FileTypeEnum.reference,
      FileTypeEnum.additional,
    ]);

    if (this.files?.length) {
      const initialDocument = this.files[0];
      this.showFile(initialDocument);
      return;
    }

    this.files = [...value].sort(
      this.sortByFileType([
        FileTypeEnum.pages,
        FileTypeEnum.main,
        FileTypeEnum.annex,
        FileTypeEnum.coverLetter,
        FileTypeEnum.reference,
        FileTypeEnum.additional,
      ]),
    );
  }

  /** Список файлов */
  public files: FilePoolModel[] = [];
  /** Активный файл */
  public file: FileEventModel = { ...fileDefault };
  /** Состояние списка файлов скрыто/раскрыто */
  public collapsedFileList = true;
  /** Отображать документ по ширине блока или оригинальный размер */
  public displayFullWidth = false;
  public fileTypes = FileTypeEnum;

  /** Подписка на состояние левого меню */
  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected store: Store,
    protected platform: Platform,
    protected cdr: ChangeDetectorRef,
    protected modalService: ModalService,
    protected fileViewer: FileViewerHelperService,
    private cardService: ResolutionInteropService,
    private sanitizer: DomSanitizer,
    private fileService: File,
    private socialSharing: SocialSharing,
    private androidPermissions: AndroidPermissionService,
  ) {}

  public get filePreview(): FilePoolModel[] {
    if (!this.collapsedFileList) {
      return [...this.files];
    }
  }

  /** Масштабирование документа с сохранением выбора текущей страницы */
  public toggleDocumentFullSize(): void {
    this.displayFullWidth = !this.displayFullWidth;

    this.setFullWidthFlagToStore();
    this.setZoom();

    setTimeout(() => this.fileViewer.setCurrentPage(this.fileViewer.getCurrentPage()));
  }

  public showFile(filePool: FilePoolModel): void {
    this.file = {
      name: filePool.fileName,
      size: filePool.fileSize,
      url: '',
      id: filePool.id,
      extension: filePool.extension,
      pages: filePool.pages,
    };

    this.displayFullWidth = false;
  }

  public scrollTop(el: ElementRef): void {
    el.nativeElement.firstChild.children[1].scrollTop = 0;
  }

  public downloadFile(filePool: Partial<FilePoolModel>): void {
    const card: CardDocumentModel | ExpeditedReviewModel = this.getActiveCard();
    if (filePool.fileType === FileTypeEnum.pages) {
      this.downloadMergedDocument(
        filePool.id,
        this.appealId || card.appealId,
        this.dossierAppealMovementId || card.appealMovementId,
        filePool.fileName,
      );
      return;
    }
    this.downloadOriginalDocument(filePool.id, card.appealMovementId, filePool.fileName);
  }

  public findMainFile(): FilePoolModel {
    // Добавил такую проверку, потому что в УР приходит склейка типа PAGES
    if (this.files.length === 1 && this.files[0].fileType === FileTypeEnum.pages) {
      return this.files[0].pages.find((f) => f.fileType === FileTypeEnum.main);
    }
    return this.files.find((f) => f.fileType === FileTypeEnum.main);
  }

  public switchActiveFile(direction: number): void {
    const currentFileIndex = this.files.findIndex((f) => f.id === this.file.id && f.fileName === this.file.name);
    if (currentFileIndex + direction > this.files.length - 1) {
      this.showFile(this.files[0]);
      return;
    }
    if (currentFileIndex + direction < 0) {
      this.showFile(this.files[this.files.length - 1]);
      return;
    }
    this.showFile(this.files[currentFileIndex + direction]);
  }

  public toggleFileListDisplay(): void {
    if (this.files?.length > 1) {
      this.collapsedFileList = !this.collapsedFileList;
    }
  }

  public sanitize(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public expandFileList(): void {
    this.collapsedFileList = false;
  }

  public collapseFileList(): void {
    this.collapsedFileList = true;
  }

  protected setFullWidthFlagToStore(): void {
    this.store.dispatch(new SetStateFullScreen(this.displayFullWidth));
  }

  protected createUrl(file: Blob): string {
    const newBlob = new Blob([file], { type: file.type });
    return window.URL.createObjectURL(newBlob);
  }

  protected handleSidebarStateChange(): void {
    this.store
      .select(SidebarState.isHidden)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        setTimeout(() => {
          this.displayFullWidth = !this.displayFullWidth;
        });
        setTimeout(() => {
          this.displayFullWidth = !this.displayFullWidth;
        });
      });
  }

  /** Определить тип скачивания */
  protected defineTypeDownload(url: string, name: string, file: Blob): void {
    if (this.platform.is('ios')) {
      this.downloadFileToIos(name, file);
      return;
    }
    if (this.platform.is('android')) {
      this.checkAndroidPermission(name, file);
      return;
    }
    this.download(url, name);
  }

  protected checkAndroidPermission(name: string, file: Blob): void {
    this.androidPermissions.hasPermissionWriteExternalStorage().then((status) => {
      if (status.hasPermission) {
        this.downloadFileToAndroid(name, file);
        return;
      }
      this.androidPermissions.requestPermissionWriteExternalStorage().then((state) => {
        state.hasPermission && this.downloadFileToAndroid(name, file);
      });
    });
  }

  /** Скачать файл на ios */
  protected downloadFileToIos(name: string, file: Blob): void {
    const path = this.fileService.tempDirectory;
    this.fileService.writeExistingFile(path, name, file)?.then((saved: any) => {
      this.share(saved);
    });
  }

  protected download(url: string, name: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
  }

  private setZoom(): void {
    this.displayFullWidth
      ? this.fileViewer.setZoom(zoomSettings.increased)
      : this.fileViewer.setZoom(zoomSettings.default);
  }

  /** Сортировка файлов по типу и алфавиту внутри типа. Аргументы в массиве должны быть записаны в порядке приоритета */
  private sortByFileTypeAndUploadDate(files: FilePoolModel[], filesTypes: string[]): FilePoolModel[] {
    const sortedFiles = [];
    filesTypes.forEach((type) => {
      const filesByType = files
        .filter((file) => file.fileType === type)
        .sort((a, b) => new Date(a.uploadDate).getTime() - new Date(b.uploadDate).getTime());
      sortedFiles.push(...filesByType);
    });
    return sortedFiles;
  }

  /** Сортировка файлов по типу. Аргументы в массиве должны быть записаны в порядке приоритета */
  private sortByFileType(fileTypes: string[]): any {
    return (a, b) => {
      if (!fileTypes.length) {
        return;
      }
      const key = fileTypes[0];

      if (a.fileType === key && b.fileType !== key) {
        return -1;
      } else if (a.fileType !== key && b.fileType === key) {
        return 1;
      }

      return this.sortByFileType(fileTypes.slice(1))(a, b);
    };
  }

  /** Скачать файл на android */
  private async downloadFileToAndroid(name: string, file?: Blob): Promise<void> {
    const directory = 'Download';
    const filePath = `${this.fileService.externalRootDirectory}${directory}`;
    this.fileService
      .writeFile(filePath, name, file, { replace: true })
      .catch((err) => this.modalService.presentModalError(JSON.stringify(err)));
  }

  private share(file: any): void {
    this.socialSharing.shareWithOptions({
      files: [decodeURI(file.toURL())],
    });
  }

  /** Скачать оригинал документа */
  private downloadOriginalDocument(fileId: number, appealMovementId: number, fileName: string): void {
    this.cardService
      .getFile(fileId, appealMovementId)
      .pipe(first())
      .subscribe((file) => this.generateCredsForDownload(file, fileId, fileName));
  }

  /** Скачать склеенный документ */
  private downloadMergedDocument(fileId: number, appealId: number, appealMovementId: number, fileName: string): void {
    this.cardService
      .downloadMergedFile(appealId, appealMovementId)
      .pipe(first())
      .subscribe((file) => this.generateCredsForDownload(file, fileId, fileName));
  }

  /** Сгенерировать данные для скачивания */
  private generateCredsForDownload(file: Blob, fileId: number, fileName: string): void {
    const url = this.createUrl(file);
    const name = this.files?.find((item) => item.id === fileId && item.fileName === fileName)?.fileName;
    this.defineTypeDownload(url, name, file);
  }

  private getActiveCard(): CardDocumentModel | ExpeditedReviewModel {
    const expeditedReviewProject = this.store.selectSnapshot(ActiveProjectExpeditedReviewState.getActiveProjectId);
    return expeditedReviewProject
      ? this.store.selectSnapshot(MenuExpeditedReviewState.getActiveCard)
      : this.store.selectSnapshot(ActiveCardState.getActiveCard);
  }
}
