import { ResolutionModel } from '../../../../oog/shared/models/resolutions/resolution/resolution.model';

/** Сохранить резолюцию */
export class SetResolution {
  public static readonly type = '[Resolution Project] Set Resolution Project';
  constructor(public card: ResolutionModel) {}
}

/** Обнулить резолюцию */
export class ResetResolution {
  public static readonly type = '[Resolution Project] Reset Resolution Project';
}

/** Удалить проект резолюции */
export class DeleteProjectResolution {
  public static readonly type = '[Resolution Project] Delete Resolution Project';
  constructor(public id: number) {}
}

/** Изменить порядок проектов резолюции */
export class SwapProject {
  public static readonly type = '[Resolution Project] Swap Resolution Project';
  constructor(public firstCard: number, public secondCard: number) {}
}

/** Пересчитать serialNumber у проектов */
export class RecalculateSerialNumber {
  public static readonly type = '[Resolution Project] Recalculate Serial Number';
}

/** Сортирует проекты по serialNumber */
export class SortChildResolutionMissions {
  public static readonly type = '[Resolution Project] SortChildResolutionMissions';
}
