import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DocumentPackagesStoreService } from '@npaCore/store/document-packages-store.service';
import { isLegalActDocumentPackage } from '@npaShared/functions/project-type-document-package.functions';

/** Отобразит элемент, если текущий ПД в сторе является Нормативно Правовым Актом (НПА) */
@Directive({
  selector: '[appShowIfLegalAct]',
})
export class ShowIfLegalActDirective implements OnInit {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private packagesStore: DocumentPackagesStoreService,
  ) {}

  public ngOnInit(): void {
    const dp = this.packagesStore.getSelectedDocumentPackageSnapshot();
    const isLegalActCurrentDocumentPackage = isLegalActDocumentPackage(dp?.documentPackage);

    if (!isLegalActCurrentDocumentPackage) {
      this.viewContainer.clear();
      this.hasView = false;
      return;
    }

    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }
}
