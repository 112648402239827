import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

@Pipe({
  name: 'requestHotListEmployee',
})
export class RequestHotListEmployeePipe implements PipeTransform {
  constructor(private store: Store, private currentUser: CurrentUserIsproStoreService) {}

  public transform(list: EmployeeModel[]): any {
    const isRequest = this.store.selectSnapshot(ResolutionState.isResolutionRequestProject);
    const user = this.currentUser.getCurrentUserSnapshot();

    if (isRequest) {
      return list.filter((emp) => emp.department?.id !== user.department?.id);
    }

    return list;
  }
}
