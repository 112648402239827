import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeResponseModel } from '@oogShared/models/approval-list/tree-response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';

import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';
import { InterceptorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { ApprovalRequestModel } from '../models/approval-list/approval-request.model';
import { ApprovalModel } from '../models/approval-list/approval.model';
import { ToNumberModel } from '../models/to-number/to-number.model';

@Injectable({
  providedIn: 'root',
})
export class ApprovalListInteropService {
  constructor(private http: HttpClient) {}

  /** Получить лист согласования */
  public getApprovalData(id: number): Observable<ApprovalModel> {
    const url = Urls.approvals.getApprovalList(id);
    return this.http.get<BaseApiResponseModel<ApprovalModel>>(url).pipe(map((val) => val.data));
  }

  /** Получить лист согласования */
  public getToNumberData(id: number): Observable<ToNumberModel[]> {
    const url = Urls.approvals.getToNumber(id);
    return this.http.get<PaginationBaseModel<ToNumberModel>>(url).pipe(map((val) => val.data.content));
  }

  /** Получить дерево резолюций для блока на № */
  public getTree(id: number): Observable<TreeResponseModel> {
    const url = Urls.approvals.getTree(id);
    return this.http.get<BaseApiResponseModel<TreeResponseModel>>(url).pipe(map((val) => val.data));
  }

  /** Перенаправить лист согласования */
  public redirectApproval(data: ApprovalRequestModel): Observable<BaseApiResponseModel<null>> {
    const url = Urls.approvals.redirectApproval;
    return this.http.post<BaseApiResponseModel<null>>(url, data, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }

  /** Вернуть лист согласования */
  public returnApproval(data: ApprovalRequestModel): Observable<BaseApiResponseModel<null>> {
    const url = Urls.approvals.returnApproval;
    return this.http.post<BaseApiResponseModel<null>>(url, data, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }

  /** Согласовать лист согласования */
  public approveApproval(data: ApprovalRequestModel, appealMovementId: number): Observable<BaseApiResponseModel<null>> {
    const url = Urls.approvals.approveApproval(appealMovementId);
    return this.http.post<BaseApiResponseModel<null>>(url, data, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }

  /** Запрос на завершение согласования */
  public cancelApprovalInDraft(id: number): Observable<BaseApiResponseModel<void>> {
    const url = Urls.approvals.cancelApprovalInDraft(id);
    return this.http.delete<BaseApiResponseModel<void>>(url, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }

  /** Запрос на направление согласования */
  public directApprovalInDraft(id: number): Observable<BaseApiResponseModel<void>> {
    const url = Urls.approvals.directApprovalInDraft(id);
    return this.http.post<BaseApiResponseModel<void>>(
      url,
      {},
      {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      },
    );
  }
}
