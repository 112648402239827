import { AfterViewInit, ChangeDetectorRef, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchMissionModel } from '@oogShared/models/resolutions/mission/search-mission.model';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { DateHelperService } from '@shared/services/date-helper.service';
import { DictionariesAllUrgencyState } from '@store/dictionaries/dictionaries-all-urgency/dictionaries-all-urgency.state';
import { DictionariesHotEmployeeState } from '@store/dictionaries/dictionaries-hot-employee/dictionaries-hot-employee.state';
import { DictionariesUrgencyState } from '@store/dictionaries/dictionaries-urgency/dictionaries-urgency.state';
import { FormsState } from '@store/forms/forms.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetActiveCardProjectResolution } from '@store/resolution-store/active-resolution-project/active-resolution-project.action';

import { textareaTextConsts } from '@oogShared/consts/textarea-text.consts';
import { actionUrgency } from '../shared/consts/action-urgency.const';
import { defaultValueUrgency } from '../shared/consts/default-value-urgency.const';
import { AcceptProjectEnum } from '../shared/enums/accept-project/accept-project.enum';
import { ActionUrgencyEnum } from '../shared/enums/action-urgency/action-urgency.enum';
import { UrgencyModel } from '../shared/models/dictionaries/urgency.model';
import { FormHeaderModel } from '../shared/models/forms-input/form-header.model';
import { EmployeeModel } from '../shared/models/resolutions/employee/employee.model';
import { UrgencyReviewTypeModel } from '../shared/models/resolutions/urgency-review-type.model';
import { ActionUrgencyModel } from '../shared/models/urgency/action-urgency.model';
import { DictionariesInteropService } from '../shared/rest/dictionaries-interop.service';
import { DischargeDutiesService } from '../shared/services/discharge-of-duties/discharge-duties.service';
import { FormActionControlService } from '../shared/services/forms/form-action-control.service';
import { FormHelper } from './form-helper';

@Directive()
export class FormBase extends FormHelper implements AfterViewInit {
  @Select(DictionariesUrgencyState.urgencyList)
  public urgency$!: Observable<UrgencyModel[]>;

  @Select(DictionariesAllUrgencyState.allUrgency)
  public allUrgency$!: Observable<UrgencyModel[]>;

  @Select(DictionariesHotEmployeeState.getHotListEmployee)
  public hotListEmployee$!: Observable<EmployeeModel[]>;

  @Select(FormsState.clonePerformerCreateResolution)
  public clonePerformer$!: Observable<boolean>;

  @Select(FormsState.disabledByWhiteListPerformer)
  public disabledByWhiteList$!: Observable<boolean>;

  @Select(ActiveCardState.canApproveResolutionProject)
  public canApproveResolutionProject$: Observable<boolean>;

  public form: FormGroup = new FormGroup({});
  /** переменная для выбранных исполнителей  */
  public commissionWithoutPerformers: number[] = [];
  public headerData!: FormHeaderModel;
  public saveAndAcceptEnum = AcceptProjectEnum;
  public dispatchForm = false;
  public isChangeDeadline = false;

  constructor(
    protected store: Store,
    protected fb: FormBuilder,
    protected dateHelper: DateHelperService,
    protected currentUser: CurrentUserIsproStoreService,
    private modalCtrl: ModalController,
    private actionControlService: FormActionControlService,
    private dictionariesInterop: DictionariesInteropService,
    private cd: ChangeDetectorRef,
    private dutiesService: DischargeDutiesService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  /** Уйти назад */
  public previousStep(): void {
    this.store.dispatch([ResetActiveCardProjectResolution]);
    this.modalCtrl.dismiss().then();
  }

  /** Получить список поручений */
  public getCommissionArray(): FormArray {
    const commissions = this.form.get('commissions') as FormArray;
    commissions?.controls?.sort((a, b) => a.value?.serialNumber - b.value?.serialNumber);
    return commissions;
  }

  /** Получить список исполнителей */
  public getPerformersArray(commission: AbstractControl, fieldName = 'performers'): FormArray {
    return commission.get(fieldName) as FormArray;
  }

  /** Удалить поручение */
  public deleteCommission(commissionIdx: number): void {
    const commissions = this.getCommissionArray();
    commissions.removeAt(commissionIdx);
  }

  /** Удалить исполнителя из поручения */
  public deletePerformer(
    commission: FormGroup | AbstractControl,
    performerIdx: number,
    fieldName = 'performers',
  ): void {
    if (!this.checkFormGroup(commission)) {
      return;
    }
    const performers = commission.controls[fieldName] as FormArray;
    const performer = performers.controls[performerIdx] as FormGroup;
    performers.removeAt(performerIdx);
    this.checkClonePerformer(commission, performer, fieldName);
  }

  /** Добавить исполнителя в поручение */
  public addPerformer(commissionIdx: number, employee: EmployeeModel, minDate: string = ''): void {
    const commissions = this.getCommissionArray();
    const currentCommission = commissions.controls[commissionIdx] as FormGroup;
    const actionControl = currentCommission?.controls?.isAction?.value;

    const performers = this.getPerformerFormArray(commissions, commissionIdx);

    this.createNewPerformerGroupOrPatchValueToExisting(performers, commissions, commissionIdx, employee, minDate);
    const performerGroup = performers.controls[performers.length - 1] as FormGroup;

    const employees = performers.value.map((p) => p.performer).filter((p) => p?.id === employee?.id);
    this.setCloneControl(performers, employees);

    this.dutiesService.dischargeOfDuties(performerGroup, employee?.id, performers, performers.length - 1, 'performer');

    if (actionControl && !this.findPerformerInRelatedOrder(employee, currentCommission)) {
      this.setPerformerForFamiliarization(performerGroup);
    }
  }

  /** Добавить новое поручение в проект */
  public addCommission(): void {
    const commissions = this.getCommissionArray();
    commissions.push(this.createNewCommissionGroup());
  }

  /** Выбрать поручение из шаблона */
  public chooseCommissionTemplate(commissionIdx: number, value: string): void {
    const commissions = this.getCommissionArray();
    const text = commissions.controls[commissionIdx].get('text') as AbstractControl;
    const savedTextControl = commissions.controls[commissionIdx].get('savedText') as AbstractControl;
    text.setValue(`${text.value || ''} ${value}`);
    savedTextControl?.setValue(`${text.value || ''} ${value}`);
  }

  /** Очистить поручение */
  public clearCommission(commissionIdx: number): void {
    const commissions = this.getCommissionArray();
    const text = commissions.controls[commissionIdx].get('text') as AbstractControl;
    text.reset();
  }

  /** Перемещение поручений между собой */
  public swapCommissions(commissionIdx: number, action: string): void {
    const commissions = this.getCommissionArray();
    this.swapElements(this.form, commissions, commissionIdx, action);
  }

  /** Перемещение исполнителей между собой */
  public swapPerformers(
    commission: FormGroup | AbstractControl,
    performerIdx: number,
    action: string,
    fieldName = 'performers',
  ): void {
    if (!this.checkFormGroup(commission)) {
      return;
    }
    const performers = commission.controls[fieldName] as FormArray;
    this.swapElements(this.form, performers, performerIdx, action);
  }

  /** Изменение контрола "Срочность" */
  public urgencyChange(performerGroup: FormGroup | AbstractControl): void {
    if (!this.checkFormGroup(performerGroup)) {
      return;
    }

    this.applyOtherDate(performerGroup);
  }

  /** Изменение контрола "Ответственный" */
  public responsibleChange(commission: AbstractControl, performer: AbstractControl, id: number): void {
    const commissionGroup = commission as FormGroup;
    const performerGroup = performer as FormGroup;

    if (performerGroup.controls.inPlus?.value) {
      performerGroup.controls.urgency?.reset();
    }

    if (performerGroup.controls.responsible.value) {
      performerGroup.controls.inPlus.setValue(false);
      commissionGroup.controls.executorSign?.setValue(false);
      this.removeAndAddControls(performerGroup);
    }
    this.activatedOnlyOneResponsible(commissionGroup, id);
  }

  /** Изменение контрола "В плюсе" */
  public plusChange(performerGroup: AbstractControl, commissionGroup: AbstractControl): void {
    const performer = performerGroup as FormGroup;
    const commission = commissionGroup as FormGroup;
    this.isChangeDeadline = false;

    if (performer.controls.inPlus.value) {
      performer.controls.responsible.setValue(false);
    } else {
      performer?.controls?.urgency?.reset();
      commission.controls.executorSign?.setValue(false);
    }
    this.removeAndAddControls(performer);
  }

  public resetUrgency(performerGroup: FormGroup | AbstractControl): void {
    if (!this.checkFormGroup(performerGroup) || !performerGroup.controls.urgency) {
      return;
    }
    this.isChangeDeadline = false;
    performerGroup.controls.urgency.reset();
  }

  public changePerformer(
    commission: FormGroup,
    performer: EmployeeModel,
    performerGroup: FormGroup,
    index: number,
    fieldName = 'performers',
  ): void {
    const actionControl = commission?.controls?.isAction?.value;

    const performerGroups = this.getPerformersArray(commission, fieldName);
    const performers = performerGroups.controls.map((p: FormGroup) => p.controls.performer);
    const performerClones = performers.filter((p) => p.value?.id === performer?.id);
    this.dutiesService.dischargeOfDuties(performerGroup, performer.id, performerGroups, index, 'performer');
    if (performerClones.length > 1) {
      this.addControls(performerGroup, [{ name: 'clone', value: true, validators: [] }]);
      return;
    }
    this.removeClones(performerGroups);

    if (actionControl && !this.findPerformerInRelatedOrder(performer, commission)) {
      this.setPerformerForFamiliarization(performerGroup);
      return;
    }
    if (actionControl && this.findPerformerInRelatedOrder(performer, commission)) {
      this.setPerformerForExecutor(performerGroup);
    }
  }

  /** очистить форму "commissions"(поручения)  */
  public clearCommissionsForm(): void {
    (this.form.controls.commissions as FormArray).clear();
    this.addCommission();
  }

  /** Отследить переключение признака "Без исполнителей" */
  public handleExecutorSignChange(formGroup: FormGroup): void {
    const performers = formGroup.controls.performers as FormArray;
    performers.controls.forEach((p: FormGroup) => {
      const executorSign = formGroup.controls.executorSign.value;
      p.controls.inPlus.setValue(executorSign);
      p.controls.responsible?.setValue(false);
      formGroup.controls.isAction?.value
        ? this.removeAndAddControlsWithActionControl(p)
        : this.removeAndAddControlsWithSaveDate(p, false);
    });
  }

  protected getPerformerFormArray(commissions: FormArray, commissionIdx: number): FormArray {
    return commissions.controls[commissionIdx].get('performers') as FormArray;
  }

  protected activatedOnlyOneResponsible(commissionGroup: FormGroup, performerIdx: number): void {
    const performers = commissionGroup.controls.performers as FormArray;
    performers.controls.forEach((p: FormGroup, index: number) => {
      if (index !== performerIdx) {
        p.controls.responsible.setValue(false);
      }
    });
  }

  /** Есть ли исполнители в поручении */
  protected addresseeInCommissions(): boolean {
    const emptyAddressee = this.getCommissionArray().controls.filter((c) => {
      const executorSign = c.get('executorSign');
      const performers = c.get('performers') as FormArray;
      return !executorSign?.value && !performers.length;
    });
    return this.onlyFamiliarization() || !!emptyAddressee.length;
  }

  /** Найти поручения без исполнителей */
  protected emptyCommissions(): void {
    const commissionIdx: number[] = [];
    this.getCommissionArray().controls.forEach((c: AbstractControl, index: number) => {
      const executorSign = c.get('executorSign');
      const performers = c.get('performers') as FormArray;
      if ((!executorSign?.value && !performers.length) || this.onlyFakePerformers(c)) {
        commissionIdx.push(index);
      }
      if (!executorSign?.value && performers?.controls?.every((p: FormGroup) => p.controls?.inPlus?.value)) {
        commissionIdx.push(index);
      }
      this.commissionWithoutPerformers = commissionIdx;
    });
    this.clearCommissionWithoutPerformers();
  }

  /** Инициализировать тип контроля для формы действий с контролем */
  protected initControlType(
    group: FormGroup,
    defaultUrgency: UrgencyReviewTypeModel,
    actionValue?: ActionUrgencyModel,
    dueDate?: string,
    decontrolDate?: string,
    reportDate?: string,
  ): void {
    if (group.controls.inPlus.value) {
      this.removeControls(group, ['actionControl', 'date', 'decontrolDate', 'reportDate']);
      return;
    }
    const initDate = group.controls.initDueDate?.value;

    this.addControls(group, [
      { name: 'urgency', value: defaultUrgency, validators: [] },
      { name: 'date', value: dueDate || initDate, validators: [Validators.required] },
    ]);

    const action = group.controls.actionControl.value as ActionUrgencyModel;
    const patchControls: { [key: string]: () => void } = {
      [ActionUrgencyEnum.noPrologWithReport]: () =>
        this.actionControlService.noPrologWithReport(group, actionValue, reportDate),
      [ActionUrgencyEnum.addition]: () => this.actionControlService.addition(group, actionValue, dueDate || initDate),
      [ActionUrgencyEnum.noProlog]: () => this.actionControlService.addition(group, actionValue, dueDate || initDate),
      [ActionUrgencyEnum.prolog]: () => this.actionControlService.baseScheme(group, actionValue, dueDate || initDate),
      [ActionUrgencyEnum.stop]: () => this.actionControlService.stop(group, actionValue, decontrolDate),
      [ActionUrgencyEnum.prologNoControl]: () =>
        this.actionControlService.prologNoControl(group, actionValue, dueDate, decontrolDate),
    };
    patchControls[action.value]();
  }

  /** Очистка контрола Срок, при переключении контроля Контроль в некоторые конкретные значения */
  protected clearDateValue(group: FormGroup): void {
    const action = group.controls.actionControl.value.value;
    if (action === ActionUrgencyEnum.prolog || action === ActionUrgencyEnum.prologNoControl) {
      group.patchValue({ date: '' });
      return;
    }
    const initDate = group.controls.initDueDate?.value;
    group.patchValue({ date: initDate });
  }

  protected clearFormDispatching(): void {
    setTimeout(() => (this.dispatchForm = false), 2000);
  }

  /** Удалить или добавить контролы для формы действий с контролем */
  protected removeAndAddControlsWithActionControl(group: FormGroup): void {
    if (!group.controls.urgency) {
      this.addControls(group, [{ name: 'urgency', value: '', validators: [] }]);
    }

    if (group.controls.inPlus.value) {
      this.removeControls(group, ['actionControl', 'date', 'decontrolDate', 'reportDate']);
      group.controls.urgency.setValue(defaultValueUrgency);
      group.controls.responsible.setValue(false);
      return;
    }
    group.controls.urgency?.reset();
    this.addControlsForActionControl(group);
  }

  /** Изменение контрола "Контроль" */
  protected controlChange(performerGroup: FormGroup | AbstractControl): void {
    const group = performerGroup as FormGroup;
    const control = group.controls.control?.value;
    const date = group.controls.date;
    if (control && !date?.value) {
      date?.setErrors({ invalid: true });
      return;
    }
    date?.setErrors(null);
  }

  /** Создать новую (пустую) группу поручения */
  protected createNewCommissionGroup(): FormGroup {
    return this.fb.group({
      text: [''],
      hiddenText: [textareaTextConsts.hiddenCommissionText],
      canEditComment: true,
      executorSign: false,
      serialNumber: [this.getCommissionArray()?.length + 1],
      performers: this.fb.array([]),
      picture: [''],
      savedText: [''],
      parentResolutionCommentText: [''],
      fileId: [],
      resolutionKind: [null],
    });
  }

  /**
   * Либо создаем новый контрол для исполнителя (контролы для исполнителя тоже разные, в зависимости от условия),
   * либо вставляем исполнителя в текущий контрол
   * */
  private createNewPerformerGroupOrPatchValueToExisting(
    performers: FormArray,
    commissions: FormArray,
    commissionIdx: number,
    employee: EmployeeModel,
    minDate: string,
  ): void {
    // берем последнюю форму поручений
    const lastPerformer = this.getLastPerformerControl(performers);
    const lastPerformerGroup = this.getLastPerformerGroup(performers);
    const commissionGroup = commissions.controls[commissionIdx] as FormGroup;
    const commissionId = commissionGroup.controls?.id?.value;
    const executorSign = commissionGroup.controls?.executorSign?.value;

    // Если последний контрол с исполнителем есть и исполнитель не заполнен - заполняем его
    if (lastPerformer && !lastPerformer?.value) {
      // Устанавливаем сотрудника в форму где исполнитель не заполнен
      lastPerformer.setValue(employee);
      const inPlusControl = lastPerformerGroup.controls?.inPlus;
      executorSign ? inPlusControl?.setValue(true) : inPlusControl?.setValue(false);
      this.removeAndAddControlsWithSaveDate(lastPerformerGroup, true);
      return;
    }

    const newPerformerGroup = commissions.controls[commissionIdx].get('isAction')?.value
      ? this.createNewPerformerGroupWithActionControl(employee, commissionId)
      : this.createNewPerformerGroup(employee, commissions.controls[commissionIdx] as FormGroup, minDate, commissionId);

    // Добавляем новую форму с исполнителем
    performers.push(newPerformerGroup);
  }

  private getLastPerformerControl(performers: FormArray): AbstractControl {
    const lastPerformerFormGroup = performers.controls[performers.controls.length - 1] as FormGroup;
    // берем контрол исполнителя из последней формы
    return lastPerformerFormGroup?.get('performer');
  }

  private getLastPerformerGroup(performers: FormArray): FormGroup {
    return performers.controls[performers.controls.length - 1] as FormGroup;
  }

  /** Удалить клонов */
  private removeClones(performerGroups: FormArray): void {
    const performersRepetitionsCount = performerGroups.controls.reduce((acc: {}, pGroup: FormGroup) => {
      const id = pGroup.controls.performer?.value?.id;
      if (id) {
        acc[id] = (acc[id] || 0) + 1;
      }
      return acc;
    }, {});

    performerGroups.controls.forEach((pGroup: FormGroup) => {
      const id = pGroup.controls.performer?.value?.id;
      if (performersRepetitionsCount[id] < 2) {
        pGroup.removeControl('clone');
      }
    });
  }

  /** Добавить контролы для формы действий с контролем */
  private addControlsForActionControl(group: FormGroup): void {
    this.addControls(group, [
      { name: 'actionControl', value: actionUrgency[1], validators: [] },
      { name: 'date', value: '', validators: [Validators.required] },
    ]);
  }

  /** Обнулить массив с поручениями без исполнителя через интервал времени */
  private clearCommissionWithoutPerformers(): void {
    setTimeout(() => (this.commissionWithoutPerformers = []), 2000);
  }

  /** Создать новую (пустую) группу исполнителей */
  private createNewPerformerGroup(
    employee: EmployeeModel,
    commission: FormGroup,
    minDate: string,
    commissionId: number,
  ): FormGroup {
    const inPlus = commission.controls.executorSign?.value;

    const group = this.fb.group(
      {
        performer: [employee, Validators.required],
        responsible: [false],
        inPlus: [inPlus || false],
        note: [''],
        date: [minDate],
        control: [false],
        urgency: [''],
        serialNumber: [],
        commissionId: [commissionId],
      },
      {
        validators: this.controlChange,
      },
    );

    this.removeAndAddControlsWithSaveDate(group);

    return group;
  }

  /** Создать новую (пустую) группу исполнителей с контролем */
  private createNewPerformerGroupWithActionControl(employee: EmployeeModel, commissionId: number): FormGroup {
    return this.fb.group({
      performer: [employee, Validators.required],
      responsible: [false],
      inPlus: [false],
      note: [''],
      actionControl: [actionUrgency[1]],
      date: ['', Validators.required],
      dateControl: [''],
      dateReport: [''],
      urgency: [null],
      serialNumber: [''],
      commissionId: [commissionId],
    });
  }

  private onlyFakePerformers(commission: AbstractControl): boolean {
    const performers = commission.get('performers') as FormArray;
    const fakePerformers = performers?.controls?.filter((p: FormGroup) => !p.value.performer);
    return fakePerformers.length === performers.value?.length;
  }

  /** Проверить, что в поручении исполнители только для ознакомления */
  private onlyFamiliarization(): boolean {
    const emptyAddressee = this.getCommissionArray().controls.filter((c) => {
      const executorSign = c.get('executorSign');
      const performers = c.get('performers') as FormArray;
      const performersFamiliarization = performers.controls.filter((p: FormGroup) => !!p.controls.inPlus.value);
      return !executorSign?.value && performersFamiliarization.length === performers.length;
    });
    return !!emptyAddressee.length;
  }

  private applyOtherDate(performerGroup: FormGroup): void {
    if (performerGroup.controls.inPlus.value) {
      return;
    }
    const urgencyId = performerGroup.controls.urgency.value.id;
    const authorId = this.findAuthorControl(performerGroup)?.value?.id;
    this.dictionariesInterop
      .getDeadLineDate(urgencyId, authorId)
      .pipe(first())
      .subscribe((data) => {
        this.isChangeDeadline = data.change;
        performerGroup.controls.date.setValue(data.deadlineDate, { emitEvent: true });
      });
  }

  private findAuthorControl(form: AbstractControl): AbstractControl | null {
    if (!form) {
      return null;
    }

    const authorControl = form.get('author');
    if (authorControl) {
      return authorControl;
    }

    return form?.parent ? this.findAuthorControl(form.parent) : null;
  }

  private removeAndAddControlsWithSaveDate(group: FormGroup, saveState: boolean = false): void {
    if (group.controls.inPlus.value) {
      group.controls.urgency.setValue(defaultValueUrgency);
      if (saveState) {
        this.addDate(group);
        this.disableControls(group, ['date']);
        this.removeControls(group, ['control']);
        return;
      }
      this.removeControls(group, ['date', 'control']);
      return;
    }
    if (saveState) {
      this.enableControls(group, ['date']);
      this.addDate(group);
      this.addControls(group, [{ name: 'control', value: false, validators: [] }]);
      return;
    }
    group.controls.urgency.reset();
    this.addDateAndControl(group);
  }

  private removeAndAddControls(group: FormGroup): void {
    if (group.controls.inPlus.value) {
      group.controls.urgency.setValue(defaultValueUrgency);
      this.removeControls(group, ['date', 'control']);
      return;
    }
    this.addDateAndControl(group);
    if (group.controls.date.disabled) {
      this.enableControls(group, ['date']);
    }
  }

  private addDate(group: FormGroup): void {
    if (!group.controls.date) {
      this.addControls(group, [{ name: 'date', value: null, validators: [] }]);
    }
  }

  private addDateAndControl(group: FormGroup): void {
    this.addControls(group, [
      { name: 'date', value: '', validators: [] },
      { name: 'control', value: false, validators: [] },
    ]);
  }

  private checkClonePerformer(commissionGroup: FormGroup, performerGroup: FormGroup, fieldName = 'performers'): void {
    const performers = commissionGroup.controls[fieldName] as FormArray;
    const employees = performers.controls.filter(
      (p: FormGroup) => p.controls.performer.value?.id === performerGroup.controls.performer.value?.id,
    ) as FormGroup[];
    if (employees.length < 2 && employees.length) {
      this.removeControls(employees[0], ['clone']);
    }
  }

  /** Найти исполнителя в связанном поручении */
  private findPerformerInRelatedOrder(employee: EmployeeModel, commissionGroup: FormGroup): boolean {
    const actionValue: SearchMissionModel = commissionGroup.controls.isAction.value;
    return actionValue.addressee.some((a) => a.employee.id === employee.id);
  }

  private setPerformerForFamiliarization(performerGroup: FormGroup): void {
    const inPlusControl = performerGroup.controls.inPlus;
    inPlusControl.setValue(true);
    inPlusControl.disable();

    const responsibleControl = performerGroup.controls.responsible;
    responsibleControl.setValue(false);
    responsibleControl.disable();

    this.removeAndAddControlsWithActionControl(performerGroup);
  }

  private setPerformerForExecutor(performerGroup: FormGroup): void {
    const inPlusControl = performerGroup.controls.inPlus;
    inPlusControl.setValue(false);
    inPlusControl.enable();

    const responsibleControl = performerGroup.controls.responsible;
    responsibleControl.setValue(false);
    responsibleControl.enable();

    this.removeAndAddControlsWithActionControl(performerGroup);
  }
}
