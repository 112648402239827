import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { SettingsMenuEnum } from '@enums/settings/settings.enum';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { MobileModeration } from '@models/mobile-moderation.model';
import { TypeLoggedInEnum } from '@enums/type-logged-in.enum';

@Injectable({
  providedIn: 'root',
})
export class SettingsInteropService {
  constructor(private http: HttpClient) {}

  public logoutIsPro(deviceGuid: string): Observable<BaseApiResponseModel<null>> {
    const url = Urls.auth.logoutIsPro;
    return this.http.post<BaseApiResponseModel<null>>(url, deviceGuid);
  }

  public logoutAisSd(deviceGuid: string): Observable<TypeLoggedInEnum> {
    const url = Urls.auth.logoutAisSd;
    return this.http.post<TypeLoggedInEnum>(url, deviceGuid);
  }

  /** сохранения настроек текущего пользователя */
  public saveSettings<T>(category: SettingsMenuEnum, form: T): Subscription {
    const url = Urls.settings[category];

    return this.http
      .post(url, form)
      .pipe(catchError((error) => throwError(error)))
      .subscribe((data) => console.log(data));
  }

  public getMobileModeration(): Observable<MobileModeration> {
    const url = Urls.settings.mobileModeration;
    return this.http.get<MobileModeration>(url).pipe(catchError((error) => throwError(error)));
  }
}
