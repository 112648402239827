import { Pipe, PipeTransform } from '@angular/core';

import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';

import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionModel } from '../../models/resolutions/resolution/resolution.model';

/** Пайп проверки наличия/доступа комментария резолюции */
@Pipe({
  name: 'havePrivateComment',
})
export class PrivateCommentResolutionPipe implements PipeTransform {
  constructor(private authUser: AuthUserIsproStoreService) {}

  public transform(resolution: ResolutionModel): boolean {
    if (!resolution?.missions?.length) {
      return false;
    }
    const mission = resolution.missions[0];

    return mission.isCommentConfidential
      ? !!mission.privateComment && this.hasPermissionConfidentialComment(mission)
      : !!mission.privateComment;
  }

  private hasPermissionConfidentialComment(mission: MissionModel): boolean {
    const authUser = this.authUser.getCurrentUserSnapshot();

    return mission.addressee.some((a) => a.employee.id === authUser.id);
  }
}
