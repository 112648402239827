import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetWeekends } from '@store/dictionaries/dictionaries-weekends/dictionaries-weekends.action';

import { DictionariesInteropService } from '../../../../oog/shared/rest/dictionaries-interop.service';

@Injectable()
@State<string[]>({
  name: 'dictionariesWeekends',
  defaults: [],
})
export class DictionariesWeekendsState {
  constructor(private dictionariesUrgency: DictionariesInteropService) {}

  @Selector()
  public static weekends(state: string[]): string[] {
    return state;
  }

  @Action(SetWeekends)
  public setWeekends({ getState, setState }: StateContext<string[]>): void {
    if (getState().length) {
      return;
    }
    this.dictionariesUrgency.getWeekends().pipe(first()).subscribe(setState);
  }
}
