import { Pipe, PipeTransform } from '@angular/core';

import { ResolutionAddresseeTypeEnum } from '@oogShared/enums/resolution-addressee/resolution-addressee-type.enum';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';

@Pipe({
  name: 'addresseeForReviewer',
})
export class AddresseeForReviewerPipe implements PipeTransform {
  public transform(addressees: ResolutionAddresseeModel[]): ResolutionAddresseeModel[] {
    return addressees?.filter((a) => a?.addresseeType?.id === ResolutionAddresseeTypeEnum.reviewer);
  }
}
