import { Injectable } from '@angular/core';

import { File } from '@awesome-cordova-plugins/file/ngx';

/** вспомогательный сервис для работы с файлами и папками на устройстве */
@Injectable({
  providedIn: 'root',
})
export class FileHelperService {
  constructor(private file: File) {}

  public async checkFile(path: string, fileName: string): Promise<boolean> {
    try {
      await this.file.checkFile(path, fileName);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async checkDir(path: string, folderName: string): Promise<boolean> {
    try {
      await this.file.checkDir(path, folderName);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async checkAndRemoveFile(path: string, fileName: string): Promise<void> {
    const checkFile = await this.checkFile(path, fileName);

    if (!checkFile) {
      return;
    }

    console.log('try to remove', path, fileName);
    const res = await this.file.removeFile(path, fileName);
    console.log('remove file', res);
  }
}
