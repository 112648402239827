import { InputWithRemovalComponent } from '@oogShared/components/controls/input-with-removal/input-with-removal.component';
import { QuestionCheckboxComponent } from '@oogShared/components/controls/question-checkbox/question-checkbox.component';
import { RadioControlComponent } from '@oogShared/components/controls/radio-control/radio-control.component';
import { SelectDepartmentComponent } from '@oogShared/components/controls/select-department/select-department.component';
import { SelectFolderComponent } from '@oogShared/components/controls/select-folder/select-folder.component';
import { SelectUserComponent } from '@oogShared/components/controls/select-user/select-user.component';
import { SelectWithSearchComponent } from '@oogShared/components/controls/select-with-search/select-with-search.component';
import { TextInputComponent } from '@oogShared/components/controls/text-input/text-input.component';
import { TextareaComponent } from '@oogShared/components/controls/textarea/textarea.component';

export const ControlsOog = [
  SelectWithSearchComponent,
  SelectDepartmentComponent,
  SelectFolderComponent,
  SelectUserComponent,
  InputWithRemovalComponent,
  RadioControlComponent,
  TextInputComponent,
  TextareaComponent,
  QuestionCheckboxComponent,
];
