import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import * as _ from 'lodash';

@Pipe({
  name: 'highLightDocumentPackageIncludeSymbols',
})
export class HighLightDocumentPackageIncludeSymbolsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(string: string, value: string): SafeHtml {
    if (!string) {
      return;
    }
    string = _.escape(string);
    if (!value) {
      return this.sanitizer.bypassSecurityTrustHtml(string);
    }
    value = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const html = string.replace(new RegExp(value, 'gi'), (match) => `<span class="highlightText_dp">${match}</span>`);
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
