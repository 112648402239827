import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EmployeeReplacedTypeEnum } from '@oogShared/enums/employee-replaced-type.enum';
import {
  ResetEmployeeReplaced,
  SetEmployeeReplaced,
} from '@store/main-screen/employee-replaced/employee-replaced.action';

@Injectable()
@State<string>({
  name: 'employeeReplaced',
  defaults: '',
})
export class EmployeeReplacedState {
  @Selector()
  public static employeeNotes(state: string): string {
    return state;
  }

  @Selector()
  public static replacedUser(state: string): boolean {
    return state === EmployeeReplacedTypeEnum.fromName;
  }

  @Action(SetEmployeeReplaced)
  public setEmployeeReplaced({ setState }: StateContext<string>, { value }: SetEmployeeReplaced): void {
    setState(value);
  }

  @Action(ResetEmployeeReplaced)
  public resetEmployeeReplaced({ setState }: StateContext<string>): void {
    setState('');
  }
}
