import { Pipe, PipeTransform } from '@angular/core';

import { ApprovalBlockTypeEnum } from '../../enums/approval-list/approval-block-type.enum';
import { ApprovalParticipantStatusNamesEnum } from '../../enums/approval-list/approval-participant-status-names.enum';
import { ApprovalBlockModel } from '../../models/approval-list/approval-block.model';

@Pipe({
  name: 'approvalParallelStatus',
})
export class ApprovalParallelStatusPipe implements PipeTransform {
  public transform(block: ApprovalBlockModel): ApprovalParticipantStatusNamesEnum {
    const approvalBlock = !!block.participants.length ? block.participants : block.signers;
    if (block.type !== ApprovalBlockTypeEnum.parallel) {
      return;
    }
    if (
      approvalBlock.some((p) =>
        [ApprovalParticipantStatusNamesEnum.notAgree, ApprovalParticipantStatusNamesEnum.comebackToInitiator].includes(
          p.status,
        ),
      )
    ) {
      return ApprovalParticipantStatusNamesEnum.notAgree;
    }
    if (
      approvalBlock.some((p) =>
        [ApprovalParticipantStatusNamesEnum.onAgreement, ApprovalParticipantStatusNamesEnum.signed].includes(p.status),
      )
    ) {
      return ApprovalParticipantStatusNamesEnum.onAgreement;
    }

    return ApprovalParticipantStatusNamesEnum.agree;
  }
}
