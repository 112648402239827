import { DocumentPackageShortResponseModel } from '@npaShared/models/document-package/document-package-short.response.model';

export class SetDocumentPackages {
  public static readonly type = '[Document Packages] Set list document packages';
  constructor(public documents: DocumentPackageShortResponseModel[]) {}
}

/** добавить в конец списка массив ПД */
export class AppendDocumentPackages {
  public static readonly type = '[Document Packages] Append list';
  constructor(public documents: DocumentPackageShortResponseModel[]) {}
}

/** Обновление объекта в списке ПД */
export class UpdateDocumentInList {
  public static readonly type = '[Document Packages] Update document in list';
  constructor(public dpId: number, public updates: Partial<DocumentPackageShortResponseModel>) {}
}

export class ClearDocumentPackages {
  public static readonly type = '[Document Packages] Clear list document packages';
}

export class DeleteDocumentPackage {
  public static readonly type = '[Document Packages] Delete document package';
  constructor(public dpId: number) {}
}

export class SetTotalCountDocumentPackages {
  public static readonly type = '[Document Packages] Set total count document packages';
  constructor(public totalCount: number) {}
}
