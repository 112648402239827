import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

@Component({
  selector: 'app-add-performer-from-commission',
  templateUrl: './add-performer-from-commission.component.html',
  styleUrls: ['./add-performer-from-commission.component.scss'],
})
/** Компонент, который добавляет исполнителя в поручения из связанной резолюции */
export class AddPerformerFromCommissionComponent implements OnInit {
  @Input() public userList: EmployeeModel[] = [];

  @Output() public chooseUser: EventEmitter<EmployeeModel> = new EventEmitter<EmployeeModel>();

  public form: FormGroup = new FormGroup({});
  public showChoosePerformer = false;

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public setUserControl(user: EmployeeModel): void {
    this.showChoosePerformer = false;
    this.chooseUser.emit(user);
  }

  private initForm(): void {
    this.form = this.fb.group({
      performer: [''],
    });
  }
}
