module.exports = {

    launchPane: function () {
        return new Promise((resolve, reject) => {
            cordova.exec(() => resolve(), () => reject(), "cprocsp", "launchPane", []);
        })
    },

    importPxf: function (path, pfx, password) {
        return new Promise((resolve, reject) => {
            cordova.exec(data => resolve({result: "OK"}), (error => {
                console.log('importPxf error', error);
                let r;
                try {
                    r = JSON.parse(error)[0]
                } catch (e) {
                    r = {result: 'ERROR'}
                }
                reject(r);
            }), "cprocsp", "addCertificate", [path, pfx, password]);
        });
    },

    clear: function () {
        return new Promise((resolve, reject) => {
            cordova.exec(() => resolve({result: "OK"}), (error) => reject(error), "cprocsp", "clear", []);
        })
    },

    getCertificates: function () {
        return new Promise((resolve, reject) => {
            cordova.exec(data => {
                    console.log('CPROCSP data', data);
                    let certs = JSON.parse(data);
                    certs = certs.map(c => certInfo(c));
                    console.log('CPROCSP certs map', certs);
                    resolve(certs);
                },
                (error) => reject(error), "cprocsp", "viewCertificates", []);
        });
    },

    sign: function (filepath) {
        return new Promise((res, rej) => {
            cordova.exec(data => res(JSON.parse(data)), (error) => rej(JSON.parse(error)[0]), "cprocsp", "sign", [filepath]);
        })
    }
};

function certInfo(d) {
    let result = {};
    result.serialNumber = d.serialNumber;
    result.issuer = d.issuer;
    result.subject = parseSubject(d.subject);
    result.subject.str = d.subject;
    result.validAfter = new Date(d.validAfter * 1000);
    result.validBefore = new Date(d.validBefore * 1000);
    return result;
}

function parseSubject(s) {
    let pairs = s.split('; ');
    let result = {};
    pairs.forEach((p) => {
        let a = p.split('=');
        result[a[0]] = a[1];
    });
    return result;
}
