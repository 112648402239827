import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SessionCookieInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = request.clone({
      withCredentials: true,
    });

    return next.handle(modified);
  }
}

export const SessionCookieInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: SessionCookieInterceptor,
  multi: true,
};
