import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NetworkService } from '@shared/services/network.service';
import { throwError } from 'rxjs';

@Injectable()
export class InternetInterceptor implements HttpInterceptor {
  private oogConnectionStatus$ = this.network.connectionToOogStatus$;
  private npaConnectionStatus$ = this.network.connectionToNpaStatus$;

  constructor(private network: NetworkService, private router: Router) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.router.routerState.snapshot.root.children[0]?.url[0].path === 'auth') {
      return next.handle(request);
    }
    if (!this.oogConnectionStatus$.getValue() && request.url.includes('oog') && !request.url.includes('health')) {
      return throwError({ error: 'No Internet Connection To OOG' });
    }
    if (!this.npaConnectionStatus$.getValue() && request.url.includes('npa') && !request.url.includes('health')) {
      return throwError({ error: 'No Internet Connection To NPA' });
    }

    return next.handle(request);
  }
}
