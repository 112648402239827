import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import {
  ResetWorkForEveryone,
  SetWorkForEveryone,
} from '@store/main-screen/work-for-everyone/work-for-everyone.action';
import { ResetEmployeeReplaced } from '@store/main-screen/employee-replaced/employee-replaced.action';

import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ReplacedUserModel } from '@oogShared/models/user/replaced-user.model';

@Injectable({
  providedIn: 'root',
})
export class RelevantWorkersService {
  constructor(
    private store: Store,
    private currentUser: CurrentUserIsproStoreService,
    private authUser: AuthUserIsproStoreService,
  ) {}

  public setRelevantWorkers(data: ReplacedUserModel): void {
    if (!data) {
      return;
    }
    if (data.workForEveryone) {
      this.store.dispatch(new SetWorkForEveryone(true));
      return;
    }
    if (data.transferredRight) {
      this.store.dispatch(ResetWorkForEveryone);
      this.currentUser.setCurrentUser(data.transferredRight?.employeeFrom);
      return;
    }

    this.store.dispatch(ResetEmployeeReplaced);
    this.store.dispatch(ResetWorkForEveryone);
    this.currentUser.setCurrentUser(this.authUser.getCurrentUserSnapshot());
  }
}
