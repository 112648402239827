import { LoadStatusEnum } from '@enums/load-status.enum';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';

export class SetGroupCards {
  public static readonly type = '[Documents Group] Set Documents Group Cards';
  constructor(public cards: CardDocumentModel[]) {}
}

export class ResetGroupCards {
  public static readonly type = '[Documents Group] Reset Documents Group Cards';
}

export class DeleteCardFromGroup {
  public static readonly type = '[Documents Group] Delete Card From Group';
  constructor(public id: number) {}
}

export class SetViewedCardInGroup {
  public static readonly type = '[Documents Group] Set Viewed Card In Group';
  constructor(public id: number) {}
}

export class SetGroupCardStatus {
  public static readonly type = '[Documents Group] Set Group Card Status';
  constructor(public status: LoadStatusEnum) {}
}

export class ResetDocumentGroupState {
  public static readonly type = '[Documents Group] Reset Documents Group State';
}
