import { Component } from '@angular/core';

import { Urls } from '@const/urls';

@Component({
  selector: 'app-update-notification',
  templateUrl: './update-notification.component.html',
  styleUrls: ['./update-notification.component.scss'],
})
export class UpdateNotificationComponent {
  public openAppStore(): void {
    window.open(Urls.appStoreUrl, '_system');
  }
}
