import { MainMenuTypeEnum } from '@enums/main-screen/main-menu-type.enum';

/** Установить текущий тип документа при переходе с главного экрана */
export class SetDocumentsCategory {
  public static readonly type = '[Documents Category] Set Documents Category';
  constructor(public category: MainMenuTypeEnum) {}
}

/** Сбросить текущий тип документа */
export class ResetDocumentsCategory {
  public static readonly type = '[Documents Category] Reset Documents Category';
}
