import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { SetLeftArrowState, SetRightArrowState } from '@store/menu/arrow-buttons/arrow-buttons.action';

import { PersonalFolderDocuments } from '../../models/folders/personal-folder-documents.model';
import { PersonalFolder } from '../../models/folders/personal-folders.model';

export const findFirstNotEmptyFolder = (folders: PersonalFolder[]): PersonalFolder => {
  for (const folder of folders) {
    if (folder.personalFolderDocuments?.length && folder.size) {
      return folder;
    }

    if (folder.folders?.length) {
      const notEmptyFolder = findFirstNotEmptyFolder(folder.folders);

      if (notEmptyFolder) {
        return notEmptyFolder;
      }
    }
  }

  return null;
};

export const retrieveDocumentsInDepth = (folder: PersonalFolder, docs: PersonalFolderDocuments[]): void => {
  if (!folder) {
    return;
  }

  if (folder.personalFolderDocuments && folder.personalFolderDocuments.length && folder.size) {
    folder.personalFolderDocuments.forEach((d) => docs.push(d));
  }

  if (folder.folders) {
    for (const f of folder.folders) {
      retrieveDocumentsInDepth(f, docs);
    }
  }
};

export const isChildFolder = (folder: PersonalFolder, childId: number): boolean => {
  if (folder?.id === childId) {
    return true;
  }

  if (folder?.folders) {
    return !!folder.folders.find((child) => isChildFolder(child, childId));
  }

  return false;
};

// Посчитать уровни вложенности для выбранной папки
export const countFoldersNestedLevels = (folder: PersonalFolder, level = 1): number => {
  let maxNestedLevel = 1;

  const countLevels = (f: PersonalFolder, l: number): void => {
    if (f?.folders) {
      for (let i = 0; i < f.folders.length; i++) {
        countLevels(f.folders[i], l + 1);
      }
    } else {
      if (l > maxNestedLevel) {
        maxNestedLevel = l;
      }
    }
  };

  countLevels(folder, level);

  return maxNestedLevel;
};

/** отфильтровать папку на документы, которые были перемещены в другую папку */
export const filterFolder = (folder: PersonalFolder, docIds: number[]): PersonalFolder => {
  let newPersonalDocuments = [];
  let folders = [];
  if (folder?.personalFolderDocuments?.length) {
    newPersonalDocuments = folder?.personalFolderDocuments?.filter((doc) => docIds.includes(doc.documentId));
  }

  if (folder?.folders?.length) {
    folders = folder.folders.map((innerFolder) => filterFolder(innerFolder, docIds));
  }

  return {
    ...folder,
    personalFolderDocuments: newPersonalDocuments,
    folders,
  };
};

export const setPersonalFolderButtonsState = (
  cards: CardDocumentModel[],
  cardIdx: number,
  foldersState,
  store,
): void => {
  const notEmptyFolders = foldersState?.getFoldersSnapshot()?.filter((folder) => folder.size > 0);

  store.dispatch(new SetLeftArrowState(true));
  store.dispatch(new SetRightArrowState(true));

  //Если папка только одна
  if (notEmptyFolders.length === 1) {
    if (cardIdx === 0) {
      store.dispatch(new SetLeftArrowState(false));
    }
    if (cardIdx === cards.length - 1) {
      store.dispatch(new SetRightArrowState(false));
    }
  }
};

/** Получить папку из списка по её id */
export const getFolderById = (folders: PersonalFolder[], folderId: number): PersonalFolder | undefined => {
  let necessaryFolder: PersonalFolder | undefined;

  if (!folders.length) {
    return necessaryFolder;
  }

  necessaryFolder = folders.find((f) => f.id === folderId);

  if (necessaryFolder) {
    return necessaryFolder;
  }

  const folderLength = folders.length;
  for (let i = 0; i < folderLength; i++) {
    const folder = folders[i];

    if (necessaryFolder) {
      break;
    }

    necessaryFolder = getChildFolderById(folder, folderId, necessaryFolder);
  }

  return necessaryFolder;
};

const getChildFolderById = (
  parentFolder: PersonalFolder,
  childFolderId: number,
  necessaryFolder: PersonalFolder | undefined,
): PersonalFolder | undefined => {
  if (necessaryFolder) {
    return necessaryFolder;
  }

  if (parentFolder.id === childFolderId) {
    return parentFolder;
  }

  if (isChildFolder(parentFolder, childFolderId)) {
    parentFolder.folders.forEach((folder) => {
      necessaryFolder = getChildFolderById(folder, childFolderId, necessaryFolder);
    });
  }

  return necessaryFolder;
};
