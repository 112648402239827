import { ApprovementDefaultLogic } from '@npaCore/point-logic/approvement-default-logic';
import { PointLogicModel } from '@npaCore/point-logic/point-logic.interface';
import { getPhaseByPointId } from '@npaShared/helpers/route/phases-helper';

/**
 * Этап с типом "Пользовательский этап"
 * Подэтап "Согласование"
 */
export class UserPhaseApprovementPointLogic extends ApprovementDefaultLogic implements PointLogicModel {
  public canSendRemarksToAuthor(): boolean {
    const phaseByPointId = getPhaseByPointId(this.getRoute(), this.getPoint()?.id);

    if (!phaseByPointId.phaseTemplate.returnAllowed) {
      return false;
    }

    return super.canSendRemarksToAuthor();
  }
}
