import { Directive } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { SettingsState } from '@store/settings/settings.state';
import { StatusCurrentUserAssistantState } from '@store/status-current-user-assistant/status-current-user-assistant.state';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { DictionariesHotEmployeeState } from '@store/dictionaries/dictionaries-hot-employee/dictionaries-hot-employee.state';
import { DictionariesUrgencyState } from '@store/dictionaries/dictionaries-urgency/dictionaries-urgency.state';
import { FormsState } from '@store/forms/forms.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { defaultValueUrgency } from '../shared/consts/default-value-urgency.const';
import { UrgencyModel } from '../shared/models/dictionaries/urgency.model';
import { FormHeaderModel } from '../shared/models/forms-input/form-header.model';
import { EmployeeModel } from '../shared/models/resolutions/employee/employee.model';
import { ResolutionModel } from '../shared/models/resolutions/resolution/resolution.model';
import { DictionariesInteropService } from '../shared/rest/dictionaries-interop.service';
import { ConfirmDialogService } from '../shared/services/confirm-dialog.service';
import { DischargeDutiesService } from '../shared/services/discharge-of-duties/discharge-duties.service';
import { FormRedirectResolutionService } from '../shared/services/forms/form-redirect-resolution.service';
import { FormHelper } from './form-helper';

@Directive()
export class RedirectFormBase extends FormHelper {
  @Select(DictionariesUrgencyState.urgencyList)
  public dictionariesUrgency$!: Observable<UrgencyModel[]>;

  @Select(DictionariesHotEmployeeState.getHotListEmployee)
  public hotListEmployee$!: Observable<EmployeeModel[]>;

  @Select(ResolutionState.getResolutionProjectCard)
  public resolution$!: Observable<ResolutionModel>;

  @Select(FormsState.cloneAddresseeRedirectResolution)
  public cloneAddressee$!: Observable<boolean>;

  @Select(FormsState.disabledByWhiteListAddressee)
  public disabledByWhitelist$!: Observable<boolean>;

  @Select(StatusCurrentUserAssistantState.getStatus)
  public statusAssistant$!: Observable<boolean>;

  public user!: EmployeeModel;
  public headerData!: FormHeaderModel;
  public form: FormGroup = new FormGroup({});
  public userList: EmployeeModel[] = [];
  public showInvalidPerformers = false;
  public showChooseAddressee = false;
  public statusShowingGraphicFromSettings = this.store.selectSnapshot(SettingsState.getGraphicStatus);
  public statusShowingAudioFromSettings = this.store.selectSnapshot(SettingsState.getAudioStatus);

  constructor(
    protected store: Store,
    protected currentUser: CurrentUserIsproStoreService,
    protected formRedirect: FormRedirectResolutionService,
    protected dialog: ConfirmDialogService,
    protected dictionariesInterop: DictionariesInteropService,
    protected dutiesService: DischargeDutiesService,
    protected modalCtrl: ModalController,
  ) {
    super();
  }

  /** Уйти назад */
  public previousStep(): void {
    this.modalCtrl.dismiss().then();
  }

  /** Получить список пользователей по поиску */
  public getUserList(value: string): void {
    if (value.length < 3) {
      return;
    }

    const params = {
      search: value,
      whitelistEmployeeId: this.currentUser.getCurrentUserSnapshot()?.id,
      whitelistDepartmentId: this.currentUser.getCurrentUserSnapshot()?.department?.id,
    };

    this.dictionariesInterop
      .getUserList(params)
      .pipe(first())
      .subscribe((u) => (this.userList = u));
  }

  /** Очистить поручение */
  public clearCommission(): void {
    this.form.controls.text.reset('');
  }

  /** Вставить предыдущий */
  public previousCommission(): void {
    const resolution = this.store.selectSnapshot(ResolutionState.getResolutionProjectCard);
    if (!resolution.missions?.length) {
      return;
    }
    const text = this.form.controls.text;
    text.setValue(resolution.missions[0].privateComment || '');
    this.form.controls.savedText?.setValue(resolution.missions[0].privateComment);
  }

  /** Вставить из шаблона */
  public chooseCommissionTemplate(value: string): void {
    const text = this.form.controls.text;
    text.setValue(`${text.value || ''} ${value}`);
    this.form.controls.savedText?.setValue(`${text.value || ''} ${value}`);
  }

  /** Изменение контрола "В плюсе" */
  public plusChange(parentForm: AbstractControl, addresseeGroup: AbstractControl): void {
    const parent = parentForm as FormGroup;
    const addressee = addresseeGroup as FormGroup;

    if (!addressee.controls.familiarization.value) {
      parent.controls.executorSign.setValue(false);
    }
    this.removeAndAddControls(addressee);
  }

  public removeAndAddControls(formGroup: FormGroup): void {
    if (formGroup.controls.familiarization.value) {
      this.removeControls(formGroup, ['notes']);
      formGroup.controls.urgency.setValue(defaultValueUrgency);
      return;
    }
    this.addControls(formGroup, [{ name: 'notes', value: '', validators: [] }]);
  }

  public changePerformer(
    performer: EmployeeModel,
    performerGroups: FormArray,
    addressee: FormGroup,
    idx: number,
  ): void {
    const performers = performerGroups.controls.map((p: FormGroup) => p.controls.addressee);
    const performerClones = performers.filter((p) => p.value.id === performer.id);
    this.dutiesService.dischargeOfDuties(addressee, performer.id, performerGroups, idx, 'addressee');
    if (performerClones.length > 1) {
      this.addControls(addressee, [{ name: 'clone', value: true, validators: [] }]);
      return;
    }
    this.removeControls(addressee, ['clone']);
  }

  protected checkClonePerformer(performers: FormArray, addressee: FormGroup): void {
    const employees = performers.controls.filter(
      (p: FormGroup) => p.controls.addressee.value.id === addressee.controls.addressee.value.id,
    ) as FormGroup[];
    if (employees.length < 2 && employees.length) {
      this.removeControls(employees[0], ['clone']);
    }
  }

  protected emptyPerformer(): void {
    this.showInvalidPerformers = true;
    this.clearCommissionWithoutPerformers();
  }

  protected userInfo(): void {
    this.formRedirect.checkHasAssistant();
    this.user = this.currentUser.getCurrentUserSnapshot();
  }

  /** Обнулить массив с поручениями без исполнителя через интервал времени */
  private clearCommissionWithoutPerformers(): void {
    setTimeout(() => (this.showInvalidPerformers = false), 2000);
  }
}
