/** Сохранить заметку и том, как работает пользователь (от имени или ИО) */
export class SetEmployeeReplaced {
  public static readonly type = '[Employee Replaced] Set Employee Replaced';
  constructor(public value: string) {}
}

/** Удалить значение и стора */
export class ResetEmployeeReplaced {
  public static readonly type = '[Employee Replaced] Reset Employee Replaced';
}
