import { Pipe, PipeTransform } from '@angular/core';

import { DateHelperService } from '@shared/services/date-helper.service';

@Pipe({
  name: 'dateExpired',
})
export class DateExpiredPipe implements PipeTransform {
  constructor(private dateHelper: DateHelperService) {}

  public transform(date: string): boolean | null {
    return this.dateHelper.subtractDateFromToday(this.daysCount(date)) < -1 || null;
  }

  private daysCount(date: string): Date | null {
    return date ? new Date(date) : null;
  }
}
