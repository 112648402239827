import { Injectable } from '@angular/core';
import { CertificateErrorValidatorType } from '@enums/settings/certificate-error-validator-type.enum';
import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { certificateValidation, textErrorValidationCertificate } from '@shared/functions/certificate-validation';
import { AboutEmployeeOogPipe } from '@shared/pipes/about-employee-oog/about-employee-oog.pipe';
import { ModalService } from '@shared/services/modal.service';
import { SettingsState } from '@store/settings/settings.state';
import { Observable } from 'rxjs';

import {
  canSignApprovalListWithEsign,
  canSignResolutionWithEsign,
} from '@oogShared/functions/checking-ability-to-sign-with-esign';
import { ApprovalCommentTypeNamesEnum } from '../enums/approval-list/approval-comment-type-names.enum';
import { ApprovalModel } from '../models/approval-list/approval.model';
import { ApprovalSignFormDataModel } from '../models/approval-modals/approval-sign-form-data.model';
import { EsignRequestModel } from '../models/esign/esign-request.model';
import { EsignTransactionStatusModel } from '../models/esign/esign-transaction-status.model';
import { OogEsignInteropService } from '../rest/oog-esign-interop.service';

@Injectable({
  providedIn: 'root',
})
export class EsignOogService {
  constructor(
    private esignApi: OogEsignInteropService,
    private modalService: ModalService,
    private store: Store,
    private authUser: AuthUserIsproStoreService,
    private aboutEmployeePipe: AboutEmployeeOogPipe,
  ) {}

  /** Подписать лист согласования */
  public signApproval(
    approvalData: ApprovalModel,
    appealMovementId: number,
    formData?: ApprovalSignFormDataModel,
  ): Observable<EsignTransactionStatusModel> {
    const data = this.mapDataForSign(approvalData, appealMovementId, formData);
    return this.esignApi.createSign(data);
  }

  /** проверка валидности сертификата, сохраненного в настройках, для подписания резолюций ЭП */
  public isInvalidCertificateSigningResolution(): boolean {
    const settings = this.store.selectSnapshot(SettingsState.certificateSettingsOog);
    const isValidCert = this.getTypeValidationCertificate();

    return canSignResolutionWithEsign(settings) && isValidCert !== CertificateErrorValidatorType.success;
  }

  /** проверка валидности сертификата, сохраненного в настройках, для подписания листов согласования */
  public isInvalidCertificateSigningApprovalList(): boolean {
    const settings = this.store.selectSnapshot(SettingsState.certificateSettingsOog);
    const isValidCert = this.getTypeValidationCertificate();

    return canSignApprovalListWithEsign(settings) && isValidCert !== CertificateErrorValidatorType.success;
  }

  /** показать модалку с ошибкой валидации */
  public showModalInvalidCertificate(): void {
    const isValidCert = this.getTypeValidationCertificate();
    const textTitle = textErrorValidationCertificate(isValidCert);
    this.modalService.presentModalWithAlarmIcon('Внимание!', textTitle, 'ОК');
  }

  /** получить тип ошибки валидации сертификата */
  private getTypeValidationCertificate(): CertificateErrorValidatorType {
    const settings = this.store.selectSnapshot(SettingsState.certificateSettingsOog);
    const currentUser = this.aboutEmployeePipe.transform(this.authUser.getCurrentUserSnapshot());

    return certificateValidation(settings.defaultCertificateOog, currentUser);
  }

  /** смаппить данные для подписания */
  private mapDataForSign(
    approvalData: ApprovalModel,
    appealMovementId: number,
    formData?: ApprovalSignFormDataModel,
  ): EsignRequestModel {
    const cert = formData.cert;

    return {
      appealMovementId,
      approvalId: approvalData.id,
      certificate: {
        id: +cert.id,
        isDefault: cert.isDefault,
        serialNumber: cert.serialNumber,
        dname: cert.name,
        validFrom: cert.validFrom,
        validUntil: cert.validUntil,
        certificateIssuer: cert.certificateIssuer,
      },
      approvalComment: {
        comment: formData.text,
        type: ApprovalCommentTypeNamesEnum.signing,
        isCommentConfidential: false,
        creationDate: null,
      },
      filePool: null,
    };
  }
}
