import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-edit-create-folder',
  templateUrl: './edit-create-folder.component.html',
  styleUrls: ['./edit-create-folder.component.scss'],
})
export class EditCreateFolderComponent implements OnInit {
  @Input() public title = '';
  @Input() public folderName = '';

  public formControl = new FormControl('');

  constructor(private modalCtrl: ModalController) {}

  public ngOnInit(): void {
    this.formControl.setValue(this.folderName);
  }

  public save(): void {
    this.modalCtrl
      .dismiss({
        name: this.formControl.value ?? null,
      })
      .then();
  }

  public cancel(): void {
    this.modalCtrl
      .dismiss({
        dismissed: true,
      })
      .then();
  }
}
