/**
 * Статус точки маршрута.
 */
export enum VertexState {
  /**
   * Задача и находится в статусе "в работе"
   */
  active = 'ACTIVE',
  /**
   * Задача и находится в статусе "новая" или пользователь, добавлен на маршрут, но не получил задачу.
   */
  inactive = 'INACTIVE',
  /**
   * Задача закрыта с резолюцией "согласовано".
   */
  completed = 'COMPLETED',
  /**
   * Задача закрыта автоматически.
   */
  completedAutomatically = 'COMPLETED_AUTOMATICALLY',
  /**
   * Задача закрыта с резолюцией "согласовано с замечаниями".
   */
  completedWithRemarks = 'COMPLETED_WITH_REMARKS',
  /**
   * Пакет отправлен на согласование
   */
  waitingForApprovement = 'WAITING_FOR_APPROVEMENT',
  /**
   * Задача закрыта с резолюцией "возврат на доработку".
   */
  returned = 'RETURNED',
  /**
   * Все остальные не описанные варианты
   */
  undefined = 'UNDEFINED',
  /**
   * Отозванная задача
   */
  withdrawn = 'WITHDRAWN',
  /**
   * Точка пропущена без задачи
   */
  skipped = 'SKIPPED',
  /**
   * Перенаправить
   */
  redirected = 'REDIRECTED',
}
