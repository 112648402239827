import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { EmployeeModel } from '../../../../oog/shared/models/resolutions/employee/employee.model';
import { DictionariesInteropService } from '../../../../oog/shared/rest/dictionaries-interop.service';
import { SetDictionariesHotEmployee } from './dictionaries-hot-employee.action';

interface HotEmployeeState {
  employeeDictionary: EmployeeModel[];
  lastUserId: number;
}

@Injectable()
@State<HotEmployeeState>({
  name: 'dictionariesHotEmployee',
  defaults: {
    employeeDictionary: [],
    lastUserId: null,
  },
})
export class DictionariesHotEmployeeState {
  constructor(private dictionariesInterop: DictionariesInteropService) {}

  @Selector()
  public static getHotListEmployee(state: HotEmployeeState): EmployeeModel[] {
    return state.employeeDictionary;
  }

  @Action(SetDictionariesHotEmployee)
  public setDictionariesHotEmployee(
    { getState, setState }: StateContext<HotEmployeeState>,
    { employeeId, employeeDeparmentId }: SetDictionariesHotEmployee,
  ): void {
    if (getState().lastUserId === employeeId) {
      return;
    }

    this.dictionariesInterop
      .getHotEmployee(employeeId, employeeDeparmentId)
      .pipe(first())
      .subscribe((employeeDictionary) => {
        setState({
          ...getState(),
          employeeDictionary,
          lastUserId: employeeId,
        });
      });
  }
}
