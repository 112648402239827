import { Injectable } from '@angular/core';

import { ApprovalListStateModel } from '@models/approval-list-state.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetApprovalListStore, SetCanOnlyReturnApprovalList } from './approval-list-store.actions';

@Injectable()
@State<ApprovalListStateModel>({
  name: 'approvalListState',
  defaults: { canOnlyReturnApprovalList: false },
})
export class ApprovalListState {
  @Selector()
  public static canOnlyReturnApprovalList(state: ApprovalListStateModel): boolean {
    return state.canOnlyReturnApprovalList;
  }

  @Action(SetCanOnlyReturnApprovalList)
  public setCanOnlyReturnApprovalListFlag(
    { patchState }: StateContext<ApprovalListStateModel>,
    { canOnlyReturnApprovalList }: SetCanOnlyReturnApprovalList,
  ): void {
    patchState({ canOnlyReturnApprovalList });
  }

  @Action(ResetApprovalListStore)
  public resetApprovalListStore({ patchState }: StateContext<ApprovalListStateModel>): void {
    patchState({ canOnlyReturnApprovalList: false });
  }
}
