import { Pipe, PipeTransform } from '@angular/core';

import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';

@Pipe({
  name: 'childDocumentInGroup',
})
export class ChildDocumentInGroupPipe implements PipeTransform {
  public transform(card: ExpeditedReviewModel): boolean {
    return (
      card?.resolutionDto.groupProjects?.groupProjects.isActive &&
      card?.resolutionDto.groupProjects?.groupProjects.mainAppealMovementId !== card.appealMovementId
    );
  }
}
