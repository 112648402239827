import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { CloseDocumentRoute, OpenDocumentRoute, ToggleDocumentRoute } from './document-route-visibility.action';

/** открытие/закрытие маршрута согласования */
@Injectable()
@State<boolean>({
  name: 'documentRouteVisibility',
  defaults: false,
})
export class DocumentRouteVisibilityState {
  @Selector()
  public static isVisibleDocumentRoute(state: boolean): boolean {
    return state;
  }

  @Action(OpenDocumentRoute)
  public openDocumentRoute({ setState }: StateContext<boolean>): void {
    setState(true);
  }

  @Action(CloseDocumentRoute)
  public closeDocumentRoute({ setState }: StateContext<boolean>): void {
    setState(false);
  }

  @Action(ToggleDocumentRoute)
  public toggleDocumentRoute({ setState }: StateContext<boolean>, { value }: ToggleDocumentRoute): void {
    setState(!value);
  }
}
