import { PhaseSortOrder } from '@npaShared/consts/phase-sort-order.const';
import { SendToApprovementResponseModel } from '../../document-agreement/models/send-to-approvement-response.model';

/** отсортировать порядок этапов */
export const sortPhasesByOrder = (a: SendToApprovementResponseModel, b: SendToApprovementResponseModel): number => {
  if (PhaseSortOrder[a.phaseTypeId] > PhaseSortOrder[b.phaseTypeId]) {
    return 1;
  }

  if (PhaseSortOrder[b.phaseTypeId] > PhaseSortOrder[a.phaseTypeId]) {
    return -1;
  }

  return 0;
};
