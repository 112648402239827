import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ConfirmFamiliarizationText } from '@const/confirm-familiarization-text.const';

@Component({
  selector: 'app-confirm-familiarization-esign',
  templateUrl: './confirm-familiarization-esign.component.html',
  styleUrls: ['./confirm-familiarization-esign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmFamiliarizationEsignComponent {
  @Input() public parentForm: FormGroup;
  @Input() public controlName = '';

  public confirmFamiliarizationText = ConfirmFamiliarizationText;
}
