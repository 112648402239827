import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { DocumentsCategoryState } from '@store/menu/documents-category/documents-category.state';

import { groupDocumentCards } from '@oogShared/functions/group-documents-cards.function';
import { MainMenuTypeEnum } from '@enums/main-screen/main-menu-type.enum';
import { retrieveEmployees } from '../functions/retrieve-employees.function';
import { MenuDocumentModel } from '../models/documents/menu-document.model';
import { MenuRequestModel } from '../models/documents/menu-request.model';
import { FiltersRequest } from '../models/filter/filter-request.model';

/** Сервис для получения карточек документов */
@Injectable({
  providedIn: 'root',
})
export class DocumentCardsInteropService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private user: CurrentUserIsproStoreService,
    private authUser: AuthUserIsproStoreService,
  ) {}

  public getResolutionsCards(
    refresh: boolean,
    skip: number,
    take: number,
    text: string,
    body?: FiltersRequest,
    refreshList: boolean = false,
    searchForNotifications: boolean = false,
  ): Observable<MenuDocumentModel> {
    const category = searchForNotifications
      ? MainMenuTypeEnum.officialCorrespondence
      : this.store.selectSnapshot(DocumentsCategoryState.getMenuCategory);

    const arr: MenuRequestModel[] = retrieveEmployees(this.store, this.user, this.authUser, refresh);

    const url = Urls.menu[category];
    const params = {
      documentFilterParams: body || null,
      skipBlocks: arr,
      skip,
      take,
      search: text,
    };

    return this.http
      .post<BaseApiResponseModel<MenuDocumentModel>>(url, params, {
        params: new InterceptorHttpParams(new InterceptorConfig(refreshList)),
      })
      .pipe(map((val) => val.data));
  }

  public getResolutionsGroupCards(groupId: number): Observable<MenuDocumentModel> {
    const category = this.store.selectSnapshot(DocumentsCategoryState.getMenuCategory);

    const arr: MenuRequestModel[] = retrieveEmployees(this.store, this.user, this.authUser, true);

    const url = Urls.menu[category];
    const params = {
      documentFilterParams: {
        groupId,
      },
      skipBlocks: arr,
      skip: 0,
      search: '',
      take: 1000,
    };

    return this.http.post<BaseApiResponseModel<MenuDocumentModel>>(url, params).pipe(
      map((val) => val.data),
      map((val) => ({
        ...val,
        cards: groupDocumentCards(val.cards),
      })),
    );
  }

  public getResolutionsCardsFromFolders(
    docs: number[],
    refresh: boolean,
    skip: number,
    take: number,
    text: string,
    body?: FiltersRequest,
  ): Observable<MenuDocumentModel> {
    const arr: MenuRequestModel[] = retrieveEmployees(this.store, this.user, this.authUser, refresh);

    const url = Urls.folders.getDocumentsInPersonalFolders;
    const params = {
      appealMovements: docs,
      documentFilterParams: body || null,
      skipBlocks: arr,
      skip,
      take,
      search: text,
    };

    return this.http.post<BaseApiResponseModel<MenuDocumentModel>>(url, params).pipe(map((val) => val.data));
  }
}
