import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reversePipe',
})
export class ReversePipe implements PipeTransform {
  public transform(value: any[]): any[] {
    if (!value?.length) {
      return;
    }
    if (value.length >= 1) {
      return value.reverse();
    }
    return value;
  }
}
