import { Route, RoutePoint } from '@npaShared/models/route/route.models';

import { ConciliationApprovementPoint } from '@npaCore/point-logic/conciliation/conciliation.approvement';
import { ConciliationInitiationPoint } from '@npaCore/point-logic/conciliation/conciliation.initiation';
import { OauPmApprovmentPoint } from '@npaCore/point-logic/oau-pm/oau-pm.approvement';
import { OauPmExpertisePoint } from '@npaCore/point-logic/oau-pm/oau-pm.expertise';
import { OauPmInitiationPoint } from '@npaCore/point-logic/oau-pm/oau-pm.initiation';
import { OauRegistrationApprovmentPoint } from '@npaCore/point-logic/oau-registration/oau-registration.approvement';
import { OauRegistrationInitiationPoint } from '@npaCore/point-logic/oau-registration/oau-registration.initiation';
import { PointLogicModel } from '@npaCore/point-logic/point-logic.interface';
import { PreliminaryApprovementApprovmentPoint } from '@npaCore/point-logic/preliminary-approvement/preliminary-approvement.approvement';
import { PreliminaryApprovementInitiationPoint } from '@npaCore/point-logic/preliminary-approvement/preliminary-approvement.initiation';
import { ProjectApprovedApprovmentPoint } from '@npaCore/point-logic/project-approved/project-approved.approvement';
import { ProjectApprovedInitiationPoint } from '@npaCore/point-logic/project-approved/project-approved.initiation';
import { ProjectCreatedReworkPoint } from '@npaCore/point-logic/project-created/project-create.rework';
import { ProjectCreatedApprovementPoint } from '@npaCore/point-logic/project-created/project-created.approvement';
import { ProjectCreatedDevelopmentPoint } from '@npaCore/point-logic/project-created/project-created.development';
import { ProjectCreatedInitiationPoint } from '@npaCore/point-logic/project-created/project-created.initiation';
import { ProjectProvidedApprovementPoint } from '@npaCore/point-logic/project-provided/project-provided.approvement';
import { PuPmApprovmentPoint } from '@npaCore/point-logic/pu-pm/pu-pm.approvement';
import { PuPmInitiationPoint } from '@npaCore/point-logic/pu-pm/pu-pm.initiation';
import { QuestionProvidedApprovmentPoint } from '@npaCore/point-logic/question-provided/question-provided.approvement';
import { QuestionProvidedInitiationPoint } from '@npaCore/point-logic/question-provided/question-provided.initiation';
import { ResponsibleExecutorApprovmentPoint } from '@npaCore/point-logic/responsible-executor/responsible-executor.approvement';
import { ResponsibleExecutorInitiationPoint } from '@npaCore/point-logic/responsible-executor/responsible-executor.initiation';
import { UserPhaseApprovementPointLogic } from '@npaCore/point-logic/user-phase/user-phase-approvement-point-logic';
import { UserPhaseInitiationPointLogic } from '@npaCore/point-logic/user-phase/user-phase-initiation-point-logic';
import { RoutePhaseTypes } from '../../document-agreement/enums/route-phase-types.enum';

/** Отдает нужную имплементацию класса в зависимости от phaseTypeId */
export class PointLogicFactory {
  public static getLogicImplementation(point: RoutePoint, route: Route): PointLogicModel {
    if (!point) {
      throw new Error('must provide point');
    }
    switch (point?.phaseTypeId) {
      // ------------ Проект создан ------------ //
      case RoutePhaseTypes.projectCreatedInitiation:
        return new ProjectCreatedInitiationPoint(point, route);

      case RoutePhaseTypes.projectCreatedDevelopment:
        return new ProjectCreatedDevelopmentPoint(point, route);

      case RoutePhaseTypes.projectCreatedApprovement:
        return new ProjectCreatedApprovementPoint(point, route);

      case RoutePhaseTypes.projectCreatedRework:
        return new ProjectCreatedReworkPoint(point, route);

      // ------------ Ответственный исполнитель ------------ //
      case RoutePhaseTypes.responsibleExecutorInitiation:
        return new ResponsibleExecutorInitiationPoint(point, route);

      case RoutePhaseTypes.responsibleExecutorApprovement:
        return new ResponsibleExecutorApprovmentPoint(point, route);

      // ------------ Вопрос внесен ------------ //
      case RoutePhaseTypes.questionProvidedInitiation:
        return new QuestionProvidedInitiationPoint(point, route);

      case RoutePhaseTypes.questionProvidedApprovement:
        return new QuestionProvidedApprovmentPoint(point, route);

      // ------------ ПУ ПМ ------------ //
      case RoutePhaseTypes.puPmInitiation:
        return new PuPmInitiationPoint(point, route);

      case RoutePhaseTypes.puPmApprovement:
        return new PuPmApprovmentPoint(point, route);

      // ------------ Проект представлен ------------ //
      case RoutePhaseTypes.projectProvidedApprovement:
        return new ProjectProvidedApprovementPoint(point, route);

      // ------------ ОАУ ПМ ------------ //
      case RoutePhaseTypes.oauPmInitiation:
        return new OauPmInitiationPoint(point, route);

      case RoutePhaseTypes.oauPmApprovement:
        return new OauPmApprovmentPoint(point, route);

      case RoutePhaseTypes.oauPmExpertise:
        return new OauPmExpertisePoint(point, route);

      // ------------ Проект согласован ------------ //
      case RoutePhaseTypes.projectApprovedInitiation:
        return new ProjectApprovedInitiationPoint(point, route);

      case RoutePhaseTypes.projectApprovedApprovement:
        return new ProjectApprovedApprovmentPoint(point, route);

      // ------------ Оформление в ОАУ ------------ //
      case RoutePhaseTypes.oauRegistrationInitiation:
        return new OauRegistrationInitiationPoint(point, route);

      case RoutePhaseTypes.oauRegistrationApprovement:
        return new OauRegistrationApprovmentPoint(point, route);

      // ------------ Предварительное согласование ------------ //
      case RoutePhaseTypes.preliminaryApprovementInitiation:
        return new PreliminaryApprovementInitiationPoint(point, route);

      case RoutePhaseTypes.preliminaryApprovementApprovement:
        return new PreliminaryApprovementApprovmentPoint(point, route);

      // ------------ Согласительное совещание ------------ //
      case RoutePhaseTypes.conciliationInitiation:
        return new ConciliationInitiationPoint(point, route);

      case RoutePhaseTypes.conciliationApprovement:
        return new ConciliationApprovementPoint(point, route);

      // ------------ Пользовательские этапы ------------ //
      case RoutePhaseTypes.userPhaseInitiation:
        return new UserPhaseInitiationPointLogic(point, route);
      case RoutePhaseTypes.userPhaseApprovement:
        return new UserPhaseApprovementPointLogic(point, route);

      default:
        throw new Error('not implemented point logic');
    }
  }
}
