import { Injectable } from '@angular/core';
import { interval, merge, Subject, timer } from 'rxjs';
import { filter, finalize, first, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { LoadingController, ModalController } from '@ionic/angular';
import { CertificateItemModel } from '@models/esigns/certificate-item.model';
import { Store } from '@ngxs/store';
import { ConfirmSignComponent } from '@components/confirm-sign/confirm-sign.component';
import { ApprovalCommentTypeNamesEnum } from '@oogShared/enums/approval-list/approval-comment-type-names.enum';
import { CodeErrorsEnum } from '@oogShared/enums/code-errors.enum';
import { ApprovalModel } from '@oogShared/models/approval-list/approval.model';
import { ApprovalSignFormDataModel } from '@oogShared/models/approval-modals/approval-sign-form-data.model';
import { PrepareDataResponseModel } from '@oogShared/models/esign/prepare-data-response.model';
import { SigningRequestModel } from '@oogShared/models/esign/signing-request.model';
import { OogEsignInteropService } from '@oogShared/rest/oog-esign-interop.service';
import { EsignOogService } from '@oogShared/services/esign-oog.service';
import { FileCertificateHelperService } from '@shared/services/file-certificate-helper.service';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { DocumentNavigationService } from '@oogShared/services/document-navigation.service';
import { EsignTransactionStatusModel } from '@oogShared/models/esign/esign-transaction-status.model';
import { modalIdApprovalActions, modalIdSignApproval } from '@const/modals-id.const';

@Injectable({
  providedIn: 'root',
})
export class ApprovalSignService {
  private unsubscribe$ = new Subject<void>();
  private certificate: CertificateItemModel;

  constructor(
    private store: Store,
    private esignApi: OogEsignInteropService,
    private esignHelper: EsignOogService,
    private modalCtrl: ModalController,
    private fileCertificateHelper: FileCertificateHelperService,
    private loadingController: LoadingController,
    private documentNavigationService: DocumentNavigationService,
  ) {}

  /**
   * Облачное подписание
   *
   * @param approvalData Данные листа согласования
   * @param formValue Данные сертификата и флага
   */
  public signCloud(approvalData: ApprovalModel, formValue: ApprovalSignFormDataModel): void {
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    this.esignHelper
      .signApproval(approvalData, card.appealMovementId, formValue)
      .pipe(first())
      .subscribe((val) => this.subscribeToWaitForAccept(val?.transactionId));
  }

  public async signLocal(approvalId: number, cert: CertificateItemModel, comment = ''): Promise<void> {
    this.certificate = cert;

    this.esignApi
      .prepareData(approvalId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => this.prepareDataAndSendToSign(r, approvalId, comment));
  }

  /** Подписка на проверку статуса подписания */
  private subscribeToWaitForAccept(transactionId: string): void {
    if (!transactionId) {
      return;
    }

    const repeatTimeout = timer(1000 * 120);
    let resultSignStatus: EsignTransactionStatusModel = null;

    this.showSignConfirm();

    interval(7000)
      .pipe(
        switchMap(() => this.esignApi.getSignStatus(transactionId)),
        tap((res) => (resultSignStatus = res)),
        takeWhile((val) => !val.isFinal),
        finalize(async () => {
          await this.modalCtrl.dismiss(undefined, undefined, modalIdSignApproval).then();

          /**
           * закрываем модалку подписания и переходим к след доку, только если подтвердили в myDss.
           * - отменили - isFinal=true и isCancel=true,
           * - подтвердили - isFinal=true и isCancel=null,
           * - ничего не делали - isFinal=null и isCancel=null,
           */
          if (resultSignStatus.isFinal && !resultSignStatus.isCancel) {
            this.documentNavigationService.nextDocument();
            await this.modalCtrl.dismiss(undefined, undefined, modalIdApprovalActions).then();
          }
        }),
        takeUntil(merge(this.unsubscribe$, repeatTimeout)),
      )
      .subscribe();
  }

  private async showSignConfirm(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ConfirmSignComponent,
      backdropDismiss: false,
      cssClass: 'confirm-cloud-sign',
      id: modalIdSignApproval,
    });

    modal.present();
  }

  private async prepareDataAndSendToSign(
    r: PrepareDataResponseModel,
    approvalId: number,
    comment: string,
  ): Promise<void> {
    const base64 = r?.data?.dataToSign;
    const dataToSend = await this.fileCertificateHelper.writeToFileAndSign(base64);
    const fileCode = r?.data?.signedFileUUID;

    if (!dataToSend) {
      return;
    }

    const data = this.mapResponseData(dataToSend, fileCode, approvalId, comment);
    this.saveSignedData(data);
  }

  private async saveSignedData(data: SigningRequestModel): Promise<void> {
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    const loading = await this.loadingController.create({ message: 'Подписание' });
    loading.present();

    this.esignApi
      .saveData(card.appealMovementId, data)
      .pipe(
        filter((res) => res.error?.code !== CodeErrorsEnum.validation),
        finalize(() => loading.dismiss()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.documentNavigationService.nextDocument();
        this.modalCtrl.dismiss(undefined, undefined, modalIdApprovalActions).then();
      });
  }

  /**
   * Смапить данные
   *
   * @param signed Подписанные данные
   * @param filePoolCode Код файла
   * @param approvalId Ид листа согдасования
   * @param comment Комент
   * @returns
   */
  private mapResponseData(
    signed: string,
    filePoolCode: string,
    approvalId: number,
    comment: string,
  ): SigningRequestModel {
    return {
      certificateNumber: `${this.certificate.id}`,
      certificateOwner: this.certificate.name,
      certificateIssuer: this.certificate.certificateIssuer,
      validFrom: this.certificate.validFrom,
      validUntil: this.certificate.validUntil,
      signed,
      filePoolCode,
      approvalId,
      approvalComment: {
        comment,
        type: ApprovalCommentTypeNamesEnum.signing,
        isCommentConfidential: false,
        creationDate: null,
      },
    };
  }
}
