import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { BaseControl } from '@components/controls/control-base';
import { PickerController } from '@ionic/angular';
import { PickerOptions } from '@ionic/core';
import { DatePickerColumnModel } from '@models/settings/date-picker-column.model';
import { Store } from '@ngxs/store';
import { SettingsState } from '@store/settings/settings.state';

@Component({
  selector: 'app-toggle-days',
  templateUrl: './toggle-days.component.html',
  styleUrls: ['./toggle-days.component.scss'],
})
export class ToggleDaysComponent extends BaseControl implements OnInit {
  @Input() public label = '';
  public daysValue = '1 д';

  public showDayPicker = false;
  private days = 1;
  private picker: HTMLIonPickerElement;

  constructor(private pickerController: PickerController, private elementRef: ElementRef, private store: Store) {
    super();
  }

  public ngOnInit(): void {
    this.days = this.store.selectSnapshot(SettingsState.reduceExecutionPeriod);
    this.updateDaysValue();
  }

  public async togglePicker(): Promise<void> {
    this.showDayPicker = !this.showDayPicker;

    if (!this.showDayPicker) {
      this.hidePicker();
      return;
    }

    await this.showPicker();
  }

  private hidePicker(): void {
    if (!this.picker) {
      return;
    }

    this.picker.dismiss();
  }

  private async showPicker(): Promise<void> {
    this.picker = await this.pickerController.create(this.createPickerOptions());

    this.picker.columns[0].selectedIndex = this.days;
    this.elementRef.nativeElement.querySelector('#picker-wrapper').append(this.picker);
    this.picker.present();

    // Слушаем изменения значений барабанов и обновляем форму
    this.picker.addEventListener('ionPickerColChange', async () => {
      this.selectDaysValue();
    });

    this.picker.addEventListener('ionBackdropTap', async () => {
      this.togglePicker();
    });
  }

  private async selectDaysValue(): Promise<void> {
    const daysCol = await this.picker.getColumn('Days');
    this.days = +daysCol.options[daysCol.selectedIndex].value;

    this.parentFormGroup.controls[this.controlName].setValue(this.days);

    this.updateDaysValue();
  }

  private updateDaysValue(): void {
    this.daysValue = `${this.days} д`;
  }

  private createPickerOptions(): PickerOptions {
    const columnOptions: DatePickerColumnModel[] = [...Array(100)].map((_, i) => ({ text: `${i} д`, value: `${i}` }));

    return {
      columns: [
        {
          name: 'Days',
          options: columnOptions,
        },
      ],
      showBackdrop: false,
      animated: false,
    };
  }
}
