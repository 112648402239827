import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SelectTypeModel } from '@models/dictionaries/select-type.model';
import { DocumentPackageApiService } from '@npaApi/document-package-info.api.service';
import { CoordinatorsConnectionType } from '@npaShared/enums/coordinators-connection-type.enum';
import { OibRolesEnum } from '@npaShared/enums/oib-roles.enum';
import { getDocumentRevision } from '@npaShared/helpers/document-revision';
import { AddToTreeRequestModel } from '@npaShared/models/add-to-tree.request.model';
import {
  DocumentPackageApproveModel,
  DocumentSignature,
} from '@npaShared/models/document-package/document-package-approve.model';
import { DocumentPackageSendToOauLeaderModel } from '@npaShared/models/document-package/document-package-send-to-oau-leader.model';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { DocumentRevisionModel } from '@npaShared/models/document/document-revision.model';
import { DocumentRevisionRequestModel } from '@npaShared/models/document/document-revision.request.model';
import { DocumentsControlAlternativesResponse } from '@npaShared/models/document/documents-control-alternatives.model';
import { ModalDocumentRedirectionModel } from '@npaShared/models/modal/modal-document-redirection.model';
import { ComplexAgreementRoutePointModel } from '@npaShared/models/route/complex-agreement-route-point.model';
import { MultiplePointsModel } from '@npaShared/models/route/multiple-points.model';
import { PointMetadataModel } from '@npaShared/models/route/point-metadata.model';
import { RedirectPointModel } from '@npaShared/models/route/redirect-point.model';
import { Route, RoutePhase, RoutePoint, RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';
import { SignDataResponseModel } from '@npaShared/models/sign-data-response.model';

import { CryptoSignatureTypeEnum } from '@npaShared/enums/crypto-signature-type.enum';
import { SendToOuterApprovementRequestModel } from '@npaShared/models/document-package/send-to-outer-approvement.request.model';
import { StartApprovementRequestModel } from '@npaShared/models/document-package/start-approvement-request.model';
import { SharedDataForSendApprovementOnRework } from '@npaShared/models/document-package/shared-data-for-send-approvement.model';
import { getSubPhasesBelongingToIterationOfPoint } from '@npaShared/helpers/route/subphase-helper';
import { Store } from '@ngxs/store';
import { RouteState } from '@npaCore/store/route/route.state';
import { filteringBlankDocuments } from '@npaShared/functions/filtering-documents';
import { needShowDocumentVersionOnPhase } from '@npaShared/helpers/document-version.helper';
import { tap } from 'rxjs/operators';
import {
  AlternativesIsLoadingForDecisionActions,
  AlternativesIsSuccessForDecisionActions,
} from '@npaShared/store/decision-actions-availability/decision-actions-availability.action';
import { allRedirectTypes, approveRedirectType, reworkRedirectType } from '../const/all-redirect-types.conts';
import { RoutePhaseTypes } from '../enums/route-phase-types.enum';
import { AddFormValueModel, AddToTreeValueForm } from '../models/add-to-tree-item.model';

@Injectable({
  providedIn: 'root',
})
export class AgreementService {
  constructor(private documentPackageApi: DocumentPackageApiService, private store: Store) {}

  /** получить данные для подписания по одному документу */
  public signData(ldeDocumentId: string, ldeRevisionId: string): Observable<SignDataResponseModel> {
    return this.documentPackageApi.getDocumentSignData(ldeDocumentId, ldeRevisionId);
  }

  /** Выполнить согласование */
  public approveDocument(dpId: number, data: DocumentPackageApproveModel): Observable<Route> {
    return this.documentPackageApi.approveDocumentPackage(dpId, data);
  }

  /** Выполнить согласование с замечаниями */
  public approveDocumentWithRemark(dpId: number, data: DocumentPackageApproveModel): Observable<Route> {
    return this.documentPackageApi.approveDocumentPackageWithRemark(dpId, data);
  }

  /** Направить замечания без согласования */
  public routeExternalReturn(routeId: number, requestBody: DocumentPackageApproveModel): Observable<Route> {
    return this.documentPackageApi.routeExternalReturn(routeId, requestBody);
  }

  /** Сформировать тело для запроса для локального подписания */
  public getParamsForApproveLocal(
    pointId: number,
    text: string,
    signature: string,
    revisions: DocumentRevisionModel[],
    documentsToSignature: DocumentSignature[],
  ): DocumentPackageApproveModel {
    return this.getParamsClientSide(pointId, text, signature, revisions, documentsToSignature);
  }

  /** Сформировать тело для запроса обычного согласования */
  public getParamsForApproveLocalWithoutEsign(
    pointId: number,
    text: string,
    revisions: DocumentRevisionModel[],
  ): DocumentPackageApproveModel {
    return {
      certificateId: null,
      pointId,
      text,
      revisions,
    };
  }

  /** Сформировать тело для запроса для облачного подписания */
  public getParamsForApproveCloud(
    pointId: number,
    text: string,
    certificateId: number,
    revisions: DocumentRevisionModel[],
  ): DocumentPackageApproveModel {
    return {
      certificateId,
      revisions,
      text,
      pointId,
      signature: {
        signatureType: CryptoSignatureTypeEnum.cloudDss,
        dssCertificateId: certificateId,
      },
    };
  }

  /** Сформировать тело запроса для Направить замечания без согласования локальное подписание */
  public getParamsForExternalReturnLocal(
    text: string,
    signature: string,
    revisions: DocumentRevisionModel[],
    documentsToSignature: DocumentSignature[],
    pointId: number,
  ): DocumentPackageApproveModel {
    return this.getParamsClientSide(pointId, text, signature, revisions, documentsToSignature);
  }

  /** Сформировать тело запроса для перенаправления */
  public getRedirectRequestBody(
    redirectionData: ModalDocumentRedirectionModel,
    documents: DocumentInfoResponseModel[],
    activePointWithAdditionalInfo: RoutePointWithAdditionalInfo,
  ): RedirectPointModel {
    const documentsForDecisionAction = filteringBlankDocuments(documents, activePointWithAdditionalInfo.phaseId);
    const revisions = documentsForDecisionAction.map((doc) => getDocumentRevision(doc, activePointWithAdditionalInfo));
    const approverTypeInShortLoop = redirectionData?.approverType;

    const pontMetadata: PointMetadataModel = {
      employeeId: redirectionData.employeeId,
      jobPosition: redirectionData.positionId,
      deadlineDate: redirectionData.deadline,
      description: redirectionData.description,
      oauEmployeeType: approverTypeInShortLoop,
      approverTypeInShortLoop,
    };

    const coordinators: MultiplePointsModel = {
      connectionType: CoordinatorsConnectionType.serial,
      pointsMetadata: [pontMetadata],
    };

    const pointsMetadata: ComplexAgreementRoutePointModel = {
      agreementRoutePhaseId: activePointWithAdditionalInfo.subPhaseId,
      coordinators,
    };

    return {
      pointsMetadata,
      revisions,
    };
  }

  /**
   * Сформировать тело запроса для направления ПД руководителю в ОАУ
   *
   * @param text сопроводительный текст
   * @param documents документы
   */
  public getSendToOauLeaderRequestBody(
    text: string,
    documents: DocumentInfoResponseModel[],
    activePointWithAdditionalInfo: RoutePointWithAdditionalInfo,
  ): DocumentPackageSendToOauLeaderModel {
    const documentsForDecisionAction = filteringBlankDocuments(documents, activePointWithAdditionalInfo.phaseId);
    const revisions = documentsForDecisionAction.map((doc) => getDocumentRevision(doc, activePointWithAdditionalInfo));

    return {
      text,
      revisions,
    };
  }

  /** Сформировать тело запроса для добавления точек исполнителя и соисполнителей */
  public getAddToTreeExecutorsRequestBody(
    formValue: AddFormValueModel,
    phaseId: number,
    phaseTypeId: number,
    employeeQuantity: { coexecutors: number; executors: number },
    documents: DocumentInfoResponseModel[],
    activePointWithAdditionalInfo: RoutePointWithAdditionalInfo,
  ): AddToTreeRequestModel {
    const getPointMetadata = (arr): any =>
      arr.map((e) => ({
        deadlineDate: e.deadline,
        description: e.description,
        employeeId: e.employee.id,
      }));
    const revisions = documents.map((doc) => getDocumentRevision(doc, activePointWithAdditionalInfo));

    return {
      revisions,
      pointsMetadata: {
        agreementRoutePhaseId: phaseId,
        agreementRoutePhaseTypeId: phaseTypeId,
        executors: {
          connectionType: formValue.executors.connectionType,
          pointsMetadata: employeeQuantity.executors ? getPointMetadata(formValue.executors.pointsMetadata) : [],
        },
        coexecutors: {
          connectionType: formValue.coexecutors.connectionType,
          pointsMetadata: employeeQuantity.coexecutors ? getPointMetadata(formValue.coexecutors.pointsMetadata) : [],
        },
        coordinators: {
          connectionType: null,
          pointsMetadata: [],
        },
      },
    };
  }

  /** Сформировать тело запроса для добавления точек согласующих */
  public getAddToTreeCoordinatorsRequestBody(
    formValue: AddToTreeValueForm,
    phaseId: number,
    phaseTypeId: number,
    documents: DocumentInfoResponseModel[],
    activePointWithAdditionalInfo: RoutePointWithAdditionalInfo,
  ): AddToTreeRequestModel {
    const pointsMetadata = formValue.pointsMetadata.map((e) => ({
      deadlineDate: e.deadline,
      description: e.description,
      employeeId: e.employee.id,
    }));
    const revisions = documents.map((doc) => getDocumentRevision(doc, activePointWithAdditionalInfo));

    return {
      revisions,
      pointsMetadata: {
        agreementRoutePhaseId: phaseId,
        agreementRoutePhaseTypeId: phaseTypeId,
        executors: {
          connectionType: null,
          pointsMetadata: [],
        },
        coexecutors: {
          connectionType: null,
          pointsMetadata: [],
        },
        coordinators: {
          connectionType: formValue.connectionType,
          pointsMetadata,
        },
      },
    };
  }

  /** Получение списка документов со списком альтернативных документов и списком документов с замечаниями */
  public documentAlternatives(
    documents: DocumentInfoResponseModel[],
    activePoint: RoutePointWithAdditionalInfo,
  ): Observable<DocumentsControlAlternativesResponse> {
    this.store.dispatch(new AlternativesIsLoadingForDecisionActions());

    const revisions = this.getDocumentRevision(documents, activePoint.phaseId);
    return this.documentPackageApi
      .documentAlternatives(revisions)
      .pipe(tap(() => this.store.dispatch(new AlternativesIsSuccessForDecisionActions())));
  }

  public getPackageMetadata(documentsToSignatureList: DocumentSignature[]): string {
    return JSON.stringify(
      documentsToSignatureList.map((d) => ({ ldeRevisionId: d.ldeRevisionId, ldeDocumentId: d.ldeDocumentId })),
    );
  }

  public getRedirectTypes(activePoint: RoutePoint, phase: RoutePhase): SelectTypeModel[] {
    const disallowedReworkSubPhases = [
      RoutePhaseTypes.projectCreatedApprovement,
      RoutePhaseTypes.projectApprovedApprovement,
    ];

    if (!activePoint) {
      return [];
    }

    if (disallowedReworkSubPhases.includes(activePoint.phaseTypeId)) {
      return [approveRedirectType];
    }

    /** для ЛПА еть настройки этапа */
    if (phase.phaseTemplate) {
      return this.getRedirectTypesForLocalLegalAct(activePoint, phase);
    }

    if (activePoint?.oauEmployeeType) {
      return [reworkRedirectType];
    }

    return allRedirectTypes;
  }

  public canSend(
    hasEsign: boolean,
    canSendWithoutEsignSetting: boolean,
    activeRoutePoint: RoutePoint,
    userRoles: string[],
  ): boolean {
    if (hasEsign) {
      return true;
    }
    // есть роль позволяющая подписывать ПД без ЭП
    const hasRequiredRole = userRoles?.includes(OibRolesEnum.NPA_RETURN_EMPLOYEE);

    if (hasRequiredRole) {
      return true;
    }

    if (canSendWithoutEsignSetting) {
      return true;
    }

    // в данных этапах ЭП требуем только если сотрудник в точке имеет категорию подписанта supphasesWithRequiredEsignOnlyForSigner
    const supphasesWithRequiredEsignOnlyForSigner = [
      RoutePhaseTypes.preliminaryApprovementInitiation,
      RoutePhaseTypes.preliminaryApprovementApprovement,
      RoutePhaseTypes.oauRegistrationInitiation,
      RoutePhaseTypes.oauRegistrationApprovement,
    ];

    if (supphasesWithRequiredEsignOnlyForSigner.includes(activeRoutePoint?.phaseTypeId)) {
      const canSignerCategory = activeRoutePoint?.signerCategories?.length > 0;
      return !canSignerCategory;
    }

    return false;
  }

  /**
   * - если автор начал доработку, то будет отправка на обычное согласование
   * - если автор не брал на доработку, то будет отправка на внешнее согласование
   *
   * @returns запрос для отправки на согласование автором при доработки
   */
  public getRequestForOuterApprovement(sharedData: SharedDataForSendApprovementOnRework): Observable<Route> {
    const route = this.store.selectSnapshot(RouteState.route);
    const subPhases = getSubPhasesBelongingToIterationOfPoint(route, sharedData.pointId);
    const hasApprovement = Boolean(subPhases.find((s) => s.phaseTypeId === RoutePhaseTypes.projectCreatedApprovement));

    if (hasApprovement) {
      const dataForApprovement: StartApprovementRequestModel = {
        phases: sharedData.phases,
        revisions: sharedData.revisions,
        pointId: sharedData.pointId,
        signature: null,
        description: '',
      };
      return this.documentPackageApi.startApprovement(route.route.id, dataForApprovement);
    }

    const dataForOuterApprovement: SendToOuterApprovementRequestModel = {
      phases: sharedData.phases,
      revisions: sharedData.revisions,
      substitutionPointId: sharedData.pointId,
      signature: null,
      descriptionForNextApprovers: '',
    };
    return this.documentPackageApi.startOuterApprovement(route.route.id, dataForOuterApprovement);
  }

  private getDocumentRevision(documents: DocumentInfoResponseModel[], phaseId: number): DocumentRevisionRequestModel[] {
    if (!documents) {
      return [];
    }

    return (
      documents
        // не включаем пустые документы, у которых нет ревизии
        .filter((doc) => !doc?.isBlank)
        .map((doc) => doc?.versions?.find((v) => needShowDocumentVersionOnPhase(v, phaseId)))
        .filter(Boolean)
        .map((version) => ({ documentId: version.ldeDocumentId, revision: version.revision }))
    );
  }

  private getRedirectTypesForLocalLegalAct(activePoint: RoutePoint, phase: RoutePhase): SelectTypeModel[] {
    if (activePoint?.oauEmployeeType && phase.phaseTemplate?.loopAllowed) {
      return [reworkRedirectType];
    }

    if (!phase.phaseTemplate?.loopAllowed) {
      return [approveRedirectType];
    }

    return allRedirectTypes;
  }

  /** Сформировать тело для запроса для локального подписания */
  private getParamsClientSide(
    pointId: number,
    text: string,
    signature: string,
    revisions: DocumentRevisionModel[],
    documentsToSignature: DocumentSignature[],
  ): DocumentPackageApproveModel {
    return {
      revisions,
      text,
      pointId,
      signature: {
        signatureType: CryptoSignatureTypeEnum.clientSide,
        documentPackageSignature: {
          packageMetadata: this.getPackageMetadata(documentsToSignature),
          signature,
        },
        documentSignaturesList: documentsToSignature,
      },
    };
  }
}
