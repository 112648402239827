import { Pipe, PipeTransform } from '@angular/core';

import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

import { Urls } from '@const/urls';
import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';

@Pipe({
  name: 'aboutEmployeeOog',
})
export class AboutEmployeeOogPipe implements PipeTransform {
  public transform(employee: EmployeeModel): SharedInfoAboutEmployee {
    const oogPhotoUrl = employee?.personPhotoId ? Urls.pictures.oogProfilePic(employee?.personPhotoId) : '';
    return {
      firstName: employee?.firstName,
      lastName: employee?.lastName,
      middleName: employee?.middleName || '',
      phone: employee?.phone || '',
      photoUrl: oogPhotoUrl,
      jobPosition: {
        name: employee?.position?.name || '',
      },
      organization: {
        name: employee?.department?.fullName || '',
      },
    };
  }
}
