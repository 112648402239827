import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';

/** Сервис для создание данных формы перенаправления резолюции из черновика */
@Injectable({
  providedIn: 'root',
})
export class RedirectResolutionDraftService {
  constructor(private fb: FormBuilder) {}

  public restoreCommissionGroup(mission: MissionModel, fileId: number | null = null): FormGroup {
    const addressee = mission?.addressee ? [...mission?.addressee] : [];
    if (mission?.isWithoutExecutorOrAddressee) {
      // удалить последнего пользователя,
      // который был добавлен перед отправкой
      addressee.pop();
    }

    return this.fb.group({
      text: [mission?.privateComment],
      performer: [
        this.createAddresseeControlFromDraft(
          mission?.addressee[mission?.addressee?.length - 1],
          mission?.isWithoutExecutorOrAddressee || false,
        ) || '',
      ],
      executorSign: mission?.isWithoutExecutorOrAddressee,
      needAttention: mission?.needAttention,
      hasProjectLetter: mission?.hasProjectLetter || false,
      projectLetterAuthor: mission?.projectLetterAuthor || null,
      addressee: this.fb.array(
        addressee.map((a) => this.createAddresseeControlFromDraft(a, mission?.isWithoutExecutorOrAddressee || false)),
      ),
      picture: [''],
      savedText: [''],
      fileId: [fileId],
    });
  }

  /** Создать контрол адресата из данных черновика
   *
   * @param addressee - данные адресата
   * @param familiarization - флаг без исполнителей*/
  private createAddresseeControlFromDraft(addressee: ResolutionAddresseeModel, familiarization: boolean): FormGroup {
    return this.fb.group({
      addressee: [addressee?.employee || ''],
      urgency: [addressee?.urgencyReviewType || ''],
      familiarization: [familiarization || false],
      ...(!familiarization && { notes: [addressee?.personalNote || ''] }),
    });
  }
}
