import { ApproveTypeEnum } from '../../enums/approve-type.enum';

/** Модель данных для модального окна подтверждения перенаправления */
export class ModalReturnConfirmationModel {
  /** Тип согласования */
  public approveType: ApproveTypeEnum;
  /** Описание проекта */
  public documentPackageName: string;
  /** Учетный номер */
  public packageNumber: string;
  /** Кому отправлен */
  public redirectToFio: string;
  /** Сопроводительный текст */
  public description: string;
}
