import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() public title: string;
  @Input() public isChecked = false;

  @Output() public changeEvent: EventEmitter<boolean> = new EventEmitter();
}
