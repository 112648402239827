import { ActionFiltersEnum } from '@enums/action-filters.enum';

/** Сворачивает/разворачивает меню реестра документов */
export class ToggleSidebar {
  public static readonly type = '[Sidebar] toggle sidebar';
}

/** Сбрасывает значение поискового запроса */
export class ResetSearch {
  public static readonly type = '[SearchInput] reset search value';
}

/** Записывает значение поискового запроса */
export class SetSearch {
  public static readonly type = '[SearchInput] set search value';
  constructor(public value: string) {}
}

/** Записывает значение айди из ответа поиска */
export class SetMainIdSearch {
  public static readonly type = '[SearchMainId] set main id';
  constructor(public value: number) {}
}

/** Очищает значение айди из ответа поиска */
export class ResetMainIdSearch {
  public static readonly type = '[SearchMainId] reset main id';
}

/** Сохранить состояние открытия/закрытия личных папок */
export class SetShownFolders {
  public static readonly type = '[Sidebar] show folders';
  constructor(public newState: boolean) {}
}

/** Сохранить состояние перехода из личных папок */
export class SetTransitionFromFolders {
  public static readonly type = '[Sidebar] Transition From Folders';
  constructor(public value: boolean) {}
}

/** Сохранить состояние перехода из доков личных папок */
export class SetTransitionFromDocumentsFolder {
  public static readonly type = '[Sidebar] Transition From Documents Folder';
  constructor(public value: boolean) {}
}

/** Флаг для перехода из пуша */
export class SetTransitionFromToast {
  public static readonly type = '[Sidebar] Transition From Toast';
  constructor(public value: boolean) {}
}

/** Сохранить название папки */
export class SetFolderName {
  public static readonly type = '[Sidebar] Folder Name';
  constructor(public value: string) {}
}

/** Обновить состояние действия с фильтром */
export class SetFilterAction {
  public static readonly type = '[Sidebar] Set Filter Action';
  constructor(public value: ActionFiltersEnum) {}
}

/** Сбросить состояние личных папок */
export class ResetFolderState {
  public static readonly type = '[Sidebar] Reset Folder State';
}

/** Сохранить состояние открытия/закрытия группы документов */
export class SetShownGroup {
  public static readonly type = '[Sidebar] show group';
  constructor(public newState: boolean) {}
}

export class ResetSidebarState {
  public static readonly type = '[Sidebar] reset sidebar state';
}

/** Сохранить id папки */
export class SetFolderId {
  public static readonly type = '[Sidebar] Folder id';
  constructor(public id: number | null) {}
}

export class SetVisibleEmptyMessage {
  public static readonly type = '[Sidebar] set visible empty message';
  constructor(public isVisible: boolean) {}
}
