import { modalIdConfirmation } from '@const/modals-id.const';
import { ModalConfirmationComponent } from '../../document-agreement/components/modal-confirmation/modal-confirmation.component';

/** Получить параметры для модального окна подтверждения действия */
export const getParamsModalConfirmation = (): any => ({
  backdropDismiss: false,
  component: ModalConfirmationComponent,
  id: modalIdConfirmation,
  cssClass: 'approve-modal',
});
