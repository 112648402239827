import { RouteApprovementPhaseModel } from '@npaShared/models/document-package/send-to-outer-approvement.request.model';
import { SendToApprovementResponseModel } from '../../document-agreement/models/send-to-approvement-response.model';

/**
 * получить список этапов на которые необходимо отправить для согласования
 *
 * @param phases
 * @returns
 */
export const getPhasesForRequestOuterApprovement = (
  phases: SendToApprovementResponseModel[],
): RouteApprovementPhaseModel[] => {
  const selectedPhases = [];

  phases.forEach((p) => {
    if (p.selected && p.phaseId) {
      selectedPhases.push({ phaseId: p.phaseId, deadlineDate: p.deadline } as RouteApprovementPhaseModel);
    }

    if (p.phases) {
      p.phases
        .filter((org) => org.selected)
        .map((org) => ({ phaseId: org.phaseId, deadlineDate: org.deadline }))
        .forEach((org) => selectedPhases.push(org));
    }
  });

  return selectedPhases;
};
