import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { DocumentAddToTreeEmployeeStoreService } from '@npaCore/store/document-add-tree-store.service';
import { DocumentAgreementStoreService } from '@npaCore/store/document-agreement-store.service';
import { DocumentPackagesStoreService } from '@npaCore/store/document-packages-store.service';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { RoutePoint } from '@npaShared/models/route/route.models';

import { Store } from '@ngxs/store';
import {
  DeleteDocumentPackage,
  SetTotalCountDocumentPackages,
} from '@npaShared/store/document-packages/document-packages.action';
import { DocumentPackagesState } from '@npaShared/store/document-packages/document-packages.state';
import { DocumentPackageStoreModel } from '@npaShared/models/document-package/document-package-store.model';
import { CloseDocumentRoute } from '@npaShared/store/document-route-visibility/document-route-visibility.action';
import { ActiveCategoryState } from '@npaShared/store/active-category/active-category.state';
import { routeNames } from '../../../app-routing/route-names.const';
import { RoutePhaseTypes } from '../../document-agreement/enums/route-phase-types.enum';
import { SelectedRoutePointService } from '../../document-package/services/selected-route-point.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementNavigateService {
  constructor(
    private store: Store,
    private agreementStore: DocumentAgreementStoreService,
    private addToTreeEmployeeStore: DocumentAddToTreeEmployeeStoreService,
    private dpStore: DocumentPackagesStoreService,
    private navController: NavController,
    private activeRoutePointService: SelectedRoutePointService,
  ) {}

  /**
   * Согласовать
   *
   * @param documentPackageId идентификатор ПД
   * @param hasApprovmentValidation
   * @param activeRoutePoint активная точка
   * @param routeId
   */
  public onApprove(
    documentPackageId: number,
    hasApprovmentValidation: boolean,
    activeRoutePoint: RoutePoint,
    routeId: number,
  ): void {
    const type = ApproveTypeEnum.approve;
    this.agreementStore.setState({
      documentPackageId,
      pointId: activeRoutePoint.id,
      approveType: type,
      hasApprovmentValidation,
      activeRoutePoint,
    });

    const url = [...this.getCommonAgreementPath(routeId, documentPackageId, activeRoutePoint.id), routeNames.approve];
    this.navController.navigateForward(url);
  }

  /**
   * Согласовать с ЛУЗ (с замечаниями)
   *
   * @param documentPackageId идентификатор ПД
   * @param hasApprovmentValidation
   * @param activeRoutePoint активная точка
   * @param routeId
   */
  public onApproveLuz(
    documentPackageId: number,
    hasApprovmentValidation: boolean,
    activeRoutePoint: RoutePoint,
    routeId: number,
  ): void {
    const type = ApproveTypeEnum.approveLuz;
    this.agreementStore.setState({
      documentPackageId,
      pointId: activeRoutePoint.id,
      approveType: type,
      hasApprovmentValidation,
      activeRoutePoint,
    });

    const url = [
      ...this.getCommonAgreementPath(routeId, documentPackageId, activeRoutePoint.id),
      routeNames.approveLuz,
    ];
    this.navController.navigateForward(url);
  }

  /** Вернуть */
  public onReturn(routeId: number, dpId: number, pointId: number): void {
    const url = [...this.getCommonAgreementPath(routeId, dpId, pointId), routeNames.return];
    this.navController.navigateForward(url);
  }

  /** Перенаправить */
  public onRedirect(routeId: number, dpId: number, pointId: number): void {
    const url = [...this.getCommonAgreementPath(routeId, dpId, pointId), routeNames.redirect];
    this.navController.navigateForward(url);
  }

  /**
   * Направить ПД руководителю
   *
   * @param routeId
   * @param dpId
   * @param pointId
   */
  public onSendToOauLeader(routeId: number, dpId: number, pointId: number): void {
    const type = ApproveTypeEnum.sendToOauLeader;
    this.agreementStore.setState({ documentPackageId: dpId, approveType: type });

    const url = [...this.getCommonAgreementPath(routeId, dpId, pointId), routeNames.sendToOauLeader];
    this.navController.navigateForward(url);
  }

  /**
   * Направить замечания без согласования
   *
   * @param documentPackageId идентификатор ПД
   */
  public onSendRemarksToAuthor(documentPackageId: number, activeRoutePoint: RoutePoint, routeId: number): void {
    const type = ApproveTypeEnum.sendRemarksToAuthor;
    this.agreementStore.setState({
      documentPackageId,
      approveType: type,
      activeRoutePoint,
    });

    const url = [
      ...this.getCommonAgreementPath(routeId, documentPackageId, activeRoutePoint.id),
      routeNames.sendRemarksToAuthor,
    ];

    this.navController.navigateForward(url);
  }

  /** Добавить исполнителя на маршрут */
  public onAddToTreeExecutor(
    routeId: number,
    phaseId: number,
    phaseTypeId: RoutePhaseTypes,
    activePoint: RoutePoint,
    dpId: number,
  ): void {
    this.addToTreeEmployeeStore.setState({ activePoint });

    const url = [
      ...this.getCommonAgreementPath(routeId, dpId, activePoint.id),
      routeNames.phaseId,
      phaseId,
      routeNames.phaseTypeId,
      phaseTypeId,
      routeNames.organizationId,
      activePoint.approver.organization.id,
      routeNames.addToTreeExecutor,
    ];
    this.navController.navigateForward(url);
  }

  /** Добавить согласующего */
  public onAddToTreeCoordinators(
    routeId: number,
    phaseId: number,
    phaseTypeId: RoutePhaseTypes,
    activePoint: RoutePoint,
    dpId: number,
  ): void {
    this.addToTreeEmployeeStore.setState({ activePoint });

    const url = [
      ...this.getCommonAgreementPath(routeId, dpId, activePoint.id),
      routeNames.phaseId,
      phaseId,
      routeNames.phaseTypeId,
      phaseTypeId,
      routeNames.organizationId,
      activePoint.approver.organization.id,
      routeNames.addToTreeCoordinators,
    ];
    this.navController.navigateForward(url);
  }

  /** Отправить на согласование */
  public onStartApprovement(routeId: number, activePointId: number, dpId: number): void {
    const url = [...this.getCommonAgreementPath(routeId, dpId, activePointId), routeNames.startApprovement];
    this.navController.navigateForward(url);
  }

  /** Отправить на согласование с замечаниями */
  public onStartApprovementWithRemarks(routeId: number, activePointId: number, dpId: number): void {
    const url = [...this.getCommonAgreementPath(routeId, dpId, activePointId), routeNames.startApprovementWithRemarks];
    this.navController.navigateForward(url);
  }

  /** Ознакомлен */
  public onApproveIntroduction(routeId: number, dpId: number, pointId: number): void {
    const url = [...this.getCommonAgreementPath(routeId, dpId, pointId), routeNames.approveIntroduction];
    this.navController.navigateForward(url);
  }

  /** Передать на ознакомление */
  public onAddIntroducer(routeId: number, dpId: number, pointId: number): void {
    const url = [...this.getCommonAgreementPath(routeId, dpId, pointId), routeNames.addIntroducer];
    this.navController.navigateForward(url);
  }

  /** Отправить на внешнее согласование */
  public onStartOuterApprovement(routeId: number, dpId: number, pointId: number): void {
    const url = [...this.getCommonAgreementPath(routeId, dpId, pointId), routeNames.startOuterApprovement];
    this.navController.navigateForward(url);
  }

  public redirectAfterDecision(): void {
    const hasAnotherActivePoints = this.activeRoutePointService.hasAnotherActivePoints();

    if (hasAnotherActivePoints) {
      this.onUpdateSelectedDocumentAndGoBack();
      return;
    }

    this.resetPackage();
    this.goToListDocumentPackagesOrMainScreen();
  }

  /** обновить выбранный ПД и переход к списку ПД */
  public onUpdateSelectedDocumentAndGoBack(): void {
    this.dpStore.updateSelectedDocumentPackage();
    this.goToListDocumentPackagesOrMainScreen();
  }

  public goToMainScreen(): void {
    this.navController.navigateRoot(routeNames.mainScreen);
  }

  /** переход к списку ПД или на ГЭ */
  public goToListDocumentPackagesOrMainScreen(): void {
    const listDP = this.store.selectSnapshot(DocumentPackagesState.getList);
    if (!listDP.length) {
      this.goToMainScreen();
      return;
    }

    const category = this.store.selectSnapshot(ActiveCategoryState.getActiveCategory);
    this.navController.navigateBack(routeNames.documentPackage, { queryParams: { category } });
  }

  /** переход назад */
  public goBack(): void {
    this.navController.back();
  }

  private getCommonAgreementPath(routeId: number, dpId: number, pointId: number): (string | number)[] {
    return [
      routeNames.documentAgreement,
      routeNames.route,
      routeId,
      routeNames.documentPackage,
      dpId,
      routeNames.point,
      pointId,
    ];
  }

  private resetPackage(): void {
    const dp = this.dpStore.getSelectedDocumentPackageSnapshot();
    this.openNeededDocument(dp);
    const oldTotalCount = this.store.selectSnapshot(DocumentPackagesState.getTotalCount);
    this.store.dispatch([
      new DeleteDocumentPackage(dp.id),
      new SetTotalCountDocumentPackages(oldTotalCount - 1),
      new CloseDocumentRoute(),
    ]);
  }

  /** открытие нужного документа после удаления отработанного ПД */
  private openNeededDocument(deletedDP: DocumentPackageStoreModel): void {
    this.dpStore.resetSelectedDocumentPackage();

    const nextDP = this.store.selectSnapshot(DocumentPackagesState.getNextPackage(deletedDP.shortResponseModel));
    if (nextDP) {
      this.dpStore.setSelectedDocumentPackage(nextDP.id);
      return;
    }

    const firstDP = this.store.selectSnapshot(DocumentPackagesState.getFirstPackage());
    if (firstDP) {
      this.dpStore.setSelectedDocumentPackage(firstDP.id);
    }
  }
}
