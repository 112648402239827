import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Store } from '@ngxs/store';
import { DictionariesSpecialEmployeeState } from '@store/dictionaries/dictionaries-special-employee/dictionaries-special-employee.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { EmployeeModel } from '../../models/resolutions/employee/employee.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationRedirectProjectService {
  constructor(private store: Store) {}

  public compareAuthorWithAddressee(form: FormGroup, user: EmployeeModel): boolean {
    const addressee = form.controls.addressee?.value;
    return user.id === addressee?.id;
  }

  public compareMayorAuthorResolution(employee: EmployeeModel): boolean {
    const authors = this.store.selectSnapshot(ResolutionState.authorsProjects);
    return !!this.isMayor(employee) && !authors.every((a) => a?.id === employee?.id);
  }

  public compareRedirectMayor(form: FormGroup, employee: EmployeeModel): boolean {
    const comment = form.controls.text.value;
    return !!this.isMayor(employee) && !!comment.length;
  }

  private isMayor(employee: EmployeeModel): boolean {
    const mayors = this.store.selectSnapshot(DictionariesSpecialEmployeeState.specialEmployees);
    return !!mayors.find((m) => m?.employeeId === employee?.id);
  }
}
