import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DocumentPackageViewerService } from '@npaDP/services/document-package-viewer.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent {
  @Input() public src = '';

  @Output() public loaded = new EventEmitter<void>();
  @Output() public failed = new EventEmitter<void>();

  constructor(@Inject(DOCUMENT) private document: Document, private pdfService: DocumentPackageViewerService) {}

  public pdfLoaded(): void {
    /** viewerContainer - id, который устанавливается в либе ngx-extended-pdf-viewer */
    const elViewerContainer = this.document.getElementById('viewerContainer');
    this.pdfService.setViewer(elViewerContainer);

    this.loaded.emit();
  }
}
