import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { Store } from '@ngxs/store';
import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';
import { ExpeditedReviewInteropService } from '@oogShared/rest/expedited-review.interop.service';
import { SetMenuExpeditedReview } from '@store/expedited-review/menu/menu-expedited-review.action';

import { routeNames } from '../../../app-routing/route-names.const';

@Injectable({
  providedIn: 'root',
})
export class MenuExpeditedReviewService implements OnDestroy {
  public loading$ = new BehaviorSubject<boolean>(true);
  public lengthPreviousData$ = new BehaviorSubject<number>(1);

  private requestSubscribe = new Subscription();

  constructor(private store: Store, private expeditedInterop: ExpeditedReviewInteropService, private router: Router) {}

  public ngOnDestroy(): void {
    this.requestSubscribe.unsubscribe();
  }

  public getExpeditedReviewMenu(): void {
    this.requestSubscribe.unsubscribe();
    this.loading$.next(true);

    this.requestSubscribe = this.expeditedInterop
      .getMenu()
      .pipe(first())
      .subscribe((result) => {
        this.loading$.next(false);
        this.lengthPreviousData$.next(result?.length || 0);
        this.mapNewObjectExpeditedReview(result);
      });
  }

  public refreshToMenuList(): Observable<ExpeditedReviewModel[]> {
    return this.expeditedInterop.getMenu();
  }

  public mapNewObjectExpeditedReview(data: ExpeditedReviewModel[]): void {
    if (this.router.isActive(routeNames.expeditedReview, false)) {
      this.store.dispatch(
        new SetMenuExpeditedReview(
          data
            ? data.map((r) => ({
                appealMovementId: r.appealMovementId,
                appealId: r.appealId,
                docComment: r.docComment,
                documentNumber: r.documentNumber,
                id: r.id,
                registrationDate: r.registrationDate,
                resolutionDto: r.resolutionDto,
                files: r.files.filter((file) => file.pages.length),
              }))
            : [],
        ),
      );
    }
  }
}
