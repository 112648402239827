import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthToken } from '@const/auth-token.const';
import { NavController } from '@ionic/angular';
import { AuthApiService } from '@npaApi/auth.api.service';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { routeNames } from 'src/app/app-routing/route-names.const';

@Injectable({ providedIn: 'root' })
export class JwtInUrlGuard implements CanActivate {
  constructor(
    private navController: NavController,
    private api: AuthApiService,
    private deviceInfoService: DeviceInfoService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const token: string = route.queryParams?.[AuthToken];

    if (token) {
      this.api.setNpaCredentials(token);
      this.deviceInfoService.saveDeviceInfo();
      this.navController.navigateRoot(routeNames.mainScreen);
      return false;
    }

    return true;
  }
}
