import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';

import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';

import { Observable, Subject } from 'rxjs';
import { DocumentTypeEnum } from '@npaShared/enums/document-type.enum';
import { SelectUserTypesEnum } from '@oogShared/enums/select-user-types.enum';
import { SuggestEmployeeParams } from '@const/urls';
import { EmployeeModel } from '../../models/resolutions/employee/employee.model';
import { DictionariesInteropService } from '../../rest/dictionaries-interop.service';

/** Компонент для отображения информации о исполнителе */
@Component({
  selector: 'app-performer-info',
  templateUrl: './performer-info.component.html',
  styleUrls: ['./performer-info.component.scss'],
})
export class PerformerInfoComponent implements OnInit, OnDestroy {
  @Input() public form: FormGroup = new FormGroup({});
  @Input() public controlName = 'performer';
  @Input() public title = '';
  @Input() public hiddenButtonChoose = false;
  @Input() public showChoosePerformer = false;
  @Input() public typeDocument: DocumentTypeEnum = DocumentTypeEnum.resolution;
  @Input() public rowDirection = false;
  @Input() public performerDisabledByWhiteList = false;
  @Input() public isNotSameDepartment = false;

  @Input() public placeholder = '';
  @Input() public selectUserType: SelectUserTypesEnum = SelectUserTypesEnum.AUTOSELECT;
  @Input() public selectUserLabel = 'Новый исполнитель';

  @Output() public toggleSearchInput = new EventEmitter<boolean>();
  @Output() public selectPerformer = new EventEmitter<EmployeeModel>();
  @Output() public cancelChoosingPerformer = new EventEmitter<void>();

  public userList: EmployeeModel[] = [];
  public selectUserTypes = SelectUserTypesEnum;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private dictionariesInterop: DictionariesInteropService,
    private currentUser: CurrentUserIsproStoreService,
  ) {}

  public ngOnInit(): void {
    this.subscribeOnFormControlChangeSetShowPerformerFlag();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public setUserList(value: string): void {
    if (value.length < 3) {
      return;
    }

    this.getUserList(value)
      .pipe(first())
      .subscribe((u) => (this.userList = u));
  }

  public setUserControl(user: EmployeeModel): void {
    this.form.controls[this.controlName].setValue(user);
    this.toggleSearchInput.emit(!this.showChoosePerformer);
    this.selectPerformer.emit(user);
  }

  // Создаем подписку - при изменении значения в formControl устанавливаем соответствующее значение в showChoosePerformer
  private subscribeOnFormControlChangeSetShowPerformerFlag(): void {
    this.form.controls[this.controlName].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      setTimeout(() => {
        this.toggleSearchInput.emit(!value);
      });
    });
  }

  private getUserList(value: string): Observable<EmployeeModel[]> {
    const userId = this.currentUser.getCurrentUserSnapshot()?.id;
    const departmentId = this.currentUser.getCurrentUserSnapshot()?.department?.id;
    const isAmpmUser = this.currentUser.getCurrentUserSnapshot()?.isAmpm;

    const params: SuggestEmployeeParams = {
      search: value,
      whitelistEmployeeId: userId,
      whitelistDepartmentId: departmentId,
    };

    if (this.typeDocument === DocumentTypeEnum.approvalList) {
      if (!isAmpmUser) {
        params.searchType = 'not-in-ampm';
      }
    } else {
      if (this.isNotSameDepartment) {
        params.searchType = 'not-in-dep';
        params.departmentId = departmentId;
      }
    }

    return this.dictionariesInterop.getUserList(params);
  }
}
