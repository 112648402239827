import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { AgreementStoreModel } from '@npaShared/models/agreements/agreement-store.model';
import { ApproveType } from '@npaShared/models/agreements/approve.type';

@Injectable({
  providedIn: 'root',
})
export class DocumentAgreementStoreService {
  private readonly defaultState: AgreementStoreModel = {
    documentPackageId: null,
    pointId: null,
    approveType: ApproveTypeEnum.approve,
    hasApprovmentValidation: false,
    activeRoutePoint: null,
  };

  private state$: BehaviorSubject<AgreementStoreModel> = new BehaviorSubject({ ...this.defaultState });

  public reset(): void {
    this.state$.next({ ...this.defaultState });
  }

  public setState(state: Partial<AgreementStoreModel>): void {
    this.state$.next({ ...this.state$.value, ...state });
  }

  public getState(): Observable<AgreementStoreModel> {
    return this.state$;
  }

  public getStateSnapshot(): AgreementStoreModel {
    return this.state$.value;
  }

  public getDocumentPackageId(): Observable<number> {
    return this.state$.pipe(map((s) => s.documentPackageId));
  }

  public getApproveType(): Observable<ApproveType> {
    return this.state$.pipe(map((s) => s.approveType));
  }
}
