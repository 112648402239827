import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearActiveProjectType, SetActiveProjectType } from './project-group-type.action';

/** активная категория */
@Injectable()
@State<number | null>({
  name: 'projectGroupType',
  defaults: null,
})
export class ProjectGroupTypeState {
  @Selector()
  public static getActiveProjectGroupType(state: number): number {
    return state;
  }

  @Action(SetActiveProjectType)
  public setActiveProjectType({ setState }: StateContext<number>, { type }: SetActiveProjectType): void {
    setState(type);
  }

  @Action(ClearActiveProjectType)
  public clearActiveProjectType({ setState }: StateContext<number>): void {
    setState(null);
  }
}
