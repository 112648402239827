import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true }],
})
export class InputComponent implements ControlValueAccessor {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public type: 'text' | 'number' | 'password';
  @Input() public id: string;

  @HostBinding('class.no-value')
  public get noValue(): boolean {
    return !this.value;
  }

  public disabled: boolean;
  public value: string;

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
    this.onChange(value);
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private onChange = (value: string): any => {};

  public onTouched = (): any => {};
}
