export enum PositionRadiusEnum {
  top = 'top',
  right = 'right',
  left = 'left',
  bottom = 'bottom',
  topLeft = 'top-left',
  topRight = 'top-right',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
  topLeftBottomLeft = 'top-left-bottom-left',
  topRightBottomRight = 'top-right-bottom-right',
}
