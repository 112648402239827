import { Component, ElementRef, Input } from '@angular/core';

import { BaseControl } from '@components/controls/control-base';
import { PickerController } from '@ionic/angular';
import { PickerOptions } from '@ionic/core';
import { DatePickerColumnModel } from '@models/settings/date-picker-column.model';
import { Store } from '@ngxs/store';
import { SettingsState } from '@store/settings/settings.state';

@Component({
  selector: 'app-toggle-time',
  templateUrl: './toggle-time.component.html',
  styleUrls: ['./toggle-time.component.scss'],
})
export class ToggleTimeComponent extends BaseControl {
  @Input() public label = '';
  public timerValue = '0м 00с';

  public showTimer = false;
  private time = 60000;
  private minutes = 1;
  private seconds = 0;
  private picker: HTMLIonPickerElement;

  constructor(private pickerController: PickerController, private elementRef: ElementRef, private store: Store) {
    super();
    this.initTimer();
  }

  public async showPicker(): Promise<void> {
    if (!this.showTimer) {
      return;
    }

    const minutes = [];
    const seconds = [];
    for (let i = 0; i < 60; i++) {
      minutes.push(i.toString());
    }
    for (let i = 0; i < 60; i++) {
      seconds.push(i < 10 ? `0${i}` : i.toString());
    }

    const options: PickerOptions = {
      columns: [
        {
          name: 'Minutes',
          options: this.getColumnOptions(minutes),
        },
        {
          name: 'Seconds',
          options: this.getColumnOptions(seconds),
        },
      ],
      showBackdrop: false,
      backdropDismiss: true,
      animated: false,
    };

    this.picker = await this.pickerController.create(options);

    this.picker.columns[0].selectedIndex = this.minutes;
    this.picker.columns[1].selectedIndex = this.seconds;
    this.elementRef.nativeElement.querySelector('.timer-wrapper').append(this.picker);
    this.picker.present();

    // Слушаем изменения значений барабанов и обновляем форму
    this.picker.addEventListener('ionPickerColChange', async () => {
      this.selectTimeValue();
    });

    this.picker.addEventListener('ionBackdropTap', async () => {
      this.showTimer = !this.showTimer;
    });
  }

  private async selectTimeValue(): Promise<void> {
    const minutesCol = await this.picker.getColumn('Minutes');
    const secondsCol = await this.picker.getColumn('Seconds');

    const minVal = minutesCol.options[minutesCol.selectedIndex].value;
    const secVal = secondsCol.options[secondsCol.selectedIndex].value;

    this.minutes = minVal;
    this.seconds = secVal;

    this.parentFormGroup.controls[this.controlName].setValue(this.formatToMilisec(minVal, secVal));
    this.timerValue = `${minVal}м ${secVal}с`;
  }

  private getColumnOptions(time: string[]): DatePickerColumnModel[] {
    return time.map((x) => ({ text: x, value: x }));
  }

  private initTimer(): void {
    this.time = this.store.selectSnapshot(SettingsState.settingsNotification).notificationHide.time;
    this.timerValue = this.formatToShorthand(this.time);
  }

  // Вычисляем и храним значение таймера в милисекундах
  private formatToMilisec(min: string, sec: string): number {
    return (+min * 60 + +sec) * 1000;
  }

  // Вычисляем и храним значение таймера в м cc
  private formatToShorthand(time: number): string {
    // минуты - целая часть после форматирования милисекунд
    this.minutes = Math.trunc(time / 1000 / 60);
    //секунды - остаток от деления
    this.seconds = (time / 1000) % 60;

    return `${this.minutes}м ${this.seconds}с`;
  }
}
