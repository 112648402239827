import { CertificateErrorValidatorType } from '@enums/settings/certificate-error-validator-type.enum';
import { CertificateItemModel } from '@models/esigns/certificate-item.model';
import { SettingsResponseCertificateModel } from '@models/settings/settings-response/settings-response-certificate.model';
import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';
import { transformSettingsCertificateUserName } from '@shared/pipes/settings-certificate-user-name.pipe';
import * as dayjs from 'dayjs';

/** проверка валидности локального сертификата на странице настройки сертификата */
export const localCertificateInSettingsValidation = (
  cert: SettingsResponseCertificateModel,
  user: SharedInfoAboutEmployee,
): CertificateErrorValidatorType => {
  const sharedInfoCert = {
    name: transformSettingsCertificateUserName(cert.subject),
    dateBefore: cert.validBefore,
  };
  return checkCertificateValidation(sharedInfoCert, user);
};

/** проверка валидности сертификата из стора при подписании чего либо */
export const certificateValidation = (
  cert: CertificateItemModel,
  user: SharedInfoAboutEmployee,
): CertificateErrorValidatorType => {
  const sharedInfoCert = {
    name: cert?.name || null,
    dateBefore: cert?.validUntil || null,
  };
  return checkCertificateValidation(sharedInfoCert, user);
};

/** текст ошибки валидации сертификата для модалки */
export const textErrorValidationCertificate = (type: CertificateErrorValidatorType): string => {
  switch (type) {
    case CertificateErrorValidatorType.errorDate:
      return 'Срок действия сертификата электронной подписи, указанного в настройках, истек';
    case CertificateErrorValidatorType.errorOnBehalf:
      return 'Подпись ЭЦП при работе "От имени" запрещен';

    default:
      return '';
  }
};

const checkCertificateValidation = (
  cert: { name: string; dateBefore: string },
  user: SharedInfoAboutEmployee,
): CertificateErrorValidatorType => {
  const dateBefore = dayjs(cert?.dateBefore) || null;
  if (dateBefore?.diff(dayjs()) < 0) {
    return CertificateErrorValidatorType.errorDate;
  }

  return CertificateErrorValidatorType.success;
};
