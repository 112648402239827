export enum ApprovalParticipantStatusNamesEnum {
  // Согласовано
  agree = 'AGREE',
  // Не солласовано
  notAgree = 'NOT_AGREE',
  // Перенаправлено
  redirect = 'REDIRECT',
  // Находится на согласовании
  onAgreement = 'ON_AGREEMENT',
  // Возвращено инициатору
  comebackToInitiator = 'COMEBACK_TO_INITIATOR',
  // Подписано
  signed = 'SIGNED',
  // У помощника
  forDecision = 'FOR_DECISION',
}
