import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateInputService {
  public closePikaday = new BehaviorSubject<boolean>(false);

  public returnClosePikadayFlag(): Observable<boolean> {
    return this.closePikaday.asObservable();
  }
}
