import { Injectable } from '@angular/core';
import { LoadStatusEnum } from '@enums/load-status.enum';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AlternativesIsSuccessForDecisionActions,
  AlternativesIsLoadingForDecisionActions,
} from './decision-actions-availability.action';

interface DecisionActionsAvailability {
  alternatives: LoadStatusEnum;
}

@Injectable()
@State<DecisionActionsAvailability | null>({
  name: 'decisionActionsAvailability',
  defaults: {
    alternatives: LoadStatusEnum.loading,
  },
})
export class DecisionActionsAvailabilityState {
  @Selector()
  public static decisionActionsIsAvailable(state: DecisionActionsAvailability): boolean {
    return state.alternatives === LoadStatusEnum.success;
  }

  @Action(AlternativesIsLoadingForDecisionActions)
  public alternativesIsLoadingForDecisionActions({
    setState,
    getState,
  }: StateContext<DecisionActionsAvailability>): void {
    setState({ ...getState(), alternatives: LoadStatusEnum.loading });
  }

  @Action(AlternativesIsSuccessForDecisionActions)
  public alternativesIsSuccessForDecisionActions({
    setState,
    getState,
  }: StateContext<DecisionActionsAvailability>): void {
    setState({ ...getState(), alternatives: LoadStatusEnum.success });
  }
}
