import { InitiationDefaultLogic } from '../initiation-default-logic';
import { PointLogicModel } from '../point-logic.interface';

/** Логика точки согласования "Проект создан. Поручение" */
export class ProjectCreatedInitiationPoint extends InitiationDefaultLogic implements PointLogicModel {
  public canSendRemarksToAuthor(): boolean {
    return false;
  }

  public canStartDevelopment(): boolean {
    return false;
  }
}
