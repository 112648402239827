import { CertificateItemModel } from '@models/esigns/certificate-item.model';
import {
  CertificatesSettingsOogModel,
  CertificatesSettingsNpaModel,
} from '@models/settings/certificates-settings.model';

export abstract class BaseSettingsCertificates {
  public get certificateAgreed(): boolean {
    return this.getItem('agreed') === 'true';
  }

  public set certificateAgreed(value: boolean) {
    this.setItem('agreed', `${value}`);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get defaultCertificate(): CertificateItemModel | null {
    try {
      return JSON.parse(this.getItem('defaultCertificate'));
    } catch (er) {
      return null;
    }
  }

  public set defaultCertificate(value: CertificateItemModel) {
    this.setItem('defaultCertificate', JSON.stringify(value));
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get signingResolutions(): boolean {
    return this.getItem('signingResolutions') === 'true';
  }

  public set signingResolutions(value: boolean) {
    this.setItem('signingResolutions', `${value}`);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get singingWithoutConfirmation(): boolean {
    return this.getItem('singingWithoutConfirmation') === 'true';
  }

  public set singingWithoutConfirmation(value: boolean) {
    this.setItem('singingWithoutConfirmation', `${value}`);
  }

  public abstract getAllSettings(): CertificatesSettingsOogModel | CertificatesSettingsNpaModel;
  protected abstract getItem(key: string): string;
  protected abstract setItem(key: string, value: string): void;
}
