import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectBase } from '@oogShared/components/controls/select-base';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ApprovalParticipantTypeNamesEnum } from '@oogShared/enums/approval-list/approval-participant-type-names.enum';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { SelectUserTypesEnum } from '@oogShared/enums/select-user-types.enum';
import { getUserName } from '@shared/functions/user.function';

@UntilDestroy()
@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
})
export class SelectUserComponent extends SelectBase implements OnInit {
  @Input() public label = '';
  @Input() public type = SelectUserTypesEnum.AUTOSELECT;
  @Input() public options: EmployeeModel[] = [];
  @Input() public performerDisabledByWhiteList = false;

  @Output() public selectUser = new EventEmitter<EmployeeModel>();
  @Output() public cancel = new EventEmitter<void>();

  public approvalType = ApprovalParticipantTypeNamesEnum;
  public types = SelectUserTypesEnum;

  public choosenUser: EmployeeModel;

  public ngOnInit(): void {
    this.subscribeToSelect();
  }

  /** Выбор значения из списка */
  public selectOption(value: EmployeeModel): void {
    this.filterInput = '';
    this.showValue = getUserName(value);
    this.activeOption = false;
    this.choosenUser = value;

    if (this.type !== this.types.WITHCONFIRM) {
      this.confirmOption();
    }
  }

  /** добавить выбранное значение в форму */
  public confirmOption(): void {
    if (!this.choosenUser) {
      return;
    }

    this.setControlValue(this.choosenUser);
    this.selectUser.emit(this.choosenUser);
  }

  private subscribeToSelect(): void {
    const control = this.parentFormGroup.controls[this.controlName];

    if (this.showValueOnInit && !!control.value) {
      this.showValue = getUserName(control.value);
    }

    control.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
      this.showValue = data ? getUserName(data) : '';
    });
  }
}
