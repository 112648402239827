import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** компонент с сообщением о ненайденном результате документов с помощью поиска или фильтра */
@Component({
  selector: 'app-message-no-result-search-and-filter',
  templateUrl: './message-no-result-search-and-filter.component.html',
  styleUrls: ['./message-no-result-search-and-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageNoResultSearchAndFilterComponent {
  @Input() public text = '';
  @Input() public isShowMessageForFilters = false;
}
