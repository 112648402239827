import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectBase } from '@oogShared/components/controls/select-base';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReportsFolderModel } from '@oogShared/models/reports/reports-folder.model';

@UntilDestroy()
@Component({
  selector: 'app-select-folder',
  templateUrl: './select-folder.component.html',
  styleUrls: ['./select-folder.component.scss'],
})
export class SelectFolderComponent extends SelectBase implements OnInit {
  @Input() public disabledForm = false;
  @Input() public options: ReportsFolderModel[] = [];

  @Output() public selectFolder = new EventEmitter<ReportsFolderModel>();

  public ngOnInit(): void {
    this.subscribeToSelect();
  }

  /** Выбор значения из списка */
  public selectOption(value: ReportsFolderModel): void {
    this.setControlValue(value);
    this.filterInput = '';
    this.showValue = `${value.name}`;
    this.selectFolder.emit(value);
    this.activeOption = false;
  }

  private subscribeToSelect(): void {
    const control = this.parentFormGroup.controls[this.controlName];

    if (this.showValueOnInit && !!control.value) {
      this.showValue = `${control.value.name}`;
    }

    control.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
      this.showValue = data ? `${data.name}` : '';
      !data && this.clearFilterInput();
    });
  }

  private clearFilterInput(): void {
    if (this.inputControl?.nativeElement) {
      this.inputControl.nativeElement.value = '';
    }
    if (this.filterInput) {
      this.filterInput = '';
    }
  }
}
