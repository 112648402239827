import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { getUserName } from '@shared/functions/user.function';
import { EmployeeBaseModel } from '../models/employee/employee-base.model';

@Pipe({
  name: 'highLightEmployeeIncludeSymbols',
})
export class HighLightEmployeeIncludeSymbolsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(employee: EmployeeBaseModel, value: string): SafeHtml {
    const name = getUserName(employee);
    if (!value) {
      return this.sanitizer.bypassSecurityTrustHtml(name);
    }
    value = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const html = name.replace(new RegExp(value, 'gi'), (match) => `<span class="highlightText">${match}</span>`);
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
