import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngxs/store';
import { zoomSettings } from '@oogShared/components/file-viewer/consts/zoom-settings.const';
import { FileViewerPropsModel } from '@oogShared/components/file-viewer/models/file-viewer-props.model';
import { FileFullScreenState } from '@store/file-full-screen/file-full-screen.state';

@Injectable({ providedIn: 'root' })
export class FileViewerHelperService {
  private currentZoom = 0;
  private isMountedNode: { [nodeRef: string]: boolean } = {};

  constructor(private zone: NgZone, private store: Store) {}

  public fileViewerMount(refNode: string, params: FileViewerPropsModel): void {
    this.zone.runOutsideAngular(() => {
      if (!oogFileViewer) {
        return;
      }
      if (this.isMountedNode[refNode]) {
        this.fileViewerUnmount(refNode);
      }
      oogFileViewer.mount(document.getElementById(refNode), params);
      this.setMounted(refNode);
    });
  }

  public getCurrentPage(): number {
    return (window as any).getCurrentPage();
  }

  public setCurrentPage(value: number): void {
    (window as any).setCurrentPage(value);
  }

  public setZoom(value: number): void {
    this.currentZoom = value;
    (window as any).setZoom(value);
  }

  public fileViewerUnmount(refNode: string): void {
    if (!(oogFileViewer && this.isMounted(refNode))) {
      return;
    }

    oogFileViewer?.unmount(document.getElementById(refNode));
    this.setUnmounted(refNode);
  }

  public returnCurrentZoom(): number {
    return this.currentZoom;
  }

  /**
   * При изменении окна просмотрщика страницы растягиваются
   * Костыль для перерисовки
   * выпилить когда нам сделают метод
   */
  public fileViewerRefresh(refNode: string, params: FileViewerPropsModel): void {
    this.fileViewerUnmount(refNode);
    this.fileViewerMount(refNode, params);
    const zoomState = this.store.selectSnapshot(FileFullScreenState.getStatusButton);
    zoomState ? this.setZoom(zoomSettings.increased) : this.setZoom(zoomSettings.default);
  }

  private setUnmounted(refNode: string): void {
    delete this.isMountedNode[refNode];
  }

  private isMounted(refNode: string): boolean {
    return this.isMountedNode[refNode];
  }

  private setMounted(refNode: string): void {
    this.isMountedNode[refNode] = true;
  }
}
