import { Component, HostBinding, Input } from '@angular/core';
import { ModalConnectionButtonEnum } from '@enums/modal-connection-button.enum';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-connection',
  templateUrl: './modal-connection.component.html',
  styleUrls: ['./modal-connection.component.scss'],
})
export class ModalConnectionComponent {
  @Input() public buttonText = 'Перейти на главный экран';
  @Input() public needReloadWindow = false;

  @HostBinding('attr.theme')
  @Input()
  public buttonTheme = ModalConnectionButtonEnum.long;

  constructor(private modalController: ModalController) {}

  public async closeModal(): Promise<void> {
    await this.modalController.dismiss(true);

    if (this.needReloadWindow) {
      window.location.reload();
    }
  }
}
