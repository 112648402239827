import { Pipe, PipeTransform } from '@angular/core';
import { MAX_DOCX_SIZE, MAX_FILE_SIZE } from '@oogShared/consts/max-file-sizes.consts';
import { FilesExtensionsEnum } from '@oogShared/enums/files-extensions/files-extensions.enum';
import { FileEventModel } from '@oogShared/models/resolutions/file-event.model';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';

@Pipe({
  name: 'downloadDocument',
})
export class DownloadDocumentPipe implements PipeTransform {
  public transform(file: FilePoolModel | FileEventModel): boolean {
    const filePool = this.getFileSizeAndFileType(file);

    switch (filePool.extension) {
      case FilesExtensionsEnum.pdf:
        if (filePool.size > MAX_FILE_SIZE) {
          return true;
        }
        break;
      case FilesExtensionsEnum.docx:
        if (filePool.size > MAX_DOCX_SIZE) {
          return true;
        }
        break;
    }
    return false;
  }

  private getFileSizeAndFileType(file: FilePoolModel | FileEventModel): {
    size: number;
    extension: FilesExtensionsEnum;
  } {
    return {
      size: (file as FilePoolModel).fileSize ?? (file as FileEventModel).size,
      extension: file.extension as FilesExtensionsEnum,
    };
  }
}
