import { FormGroup, ValidationErrors } from '@angular/forms';

export const dateRangeValidator = (form: FormGroup): ValidationErrors | null => {
  const dateBefore = form?.get('dateBefore')?.value?.split('T')[0];
  const dateAfter = form?.get('dateAfter')?.value?.split('T')[0];

  if (!dateBefore || !dateAfter) {
    return null;
  }

  if (dateBefore >= dateAfter) {
    return null;
  }

  return { tooSmallDateBefore: 'Дата "до" должна быть больше, чем дата "после"!' };
};
