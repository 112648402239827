import { Injectable } from '@angular/core';
import { DateHelperConst } from '@const/date-helper.const';
import { ProjectEnum } from '@enums/project.enum';

import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { eSignBrokenFieldsNames } from '@oogShared/consts/resolution-e-sign.const';
import { ExecutionModel } from '@oogShared/models/familiarization/execution.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ResolutionExecutionModel } from '@oogShared/models/resolutions/resolution/resolution-execution.model';
import { OogEsignInteropService } from '@oogShared/rest/oog-esign-interop.service';
import { SettingsCertificateService } from '@shared/services/certificates/settings-certificate.service';
import { DateHelperService } from '@shared/services/date-helper.service';
import { FileCertificateHelperService } from '@shared/services/file-certificate-helper.service';
import { ModalService } from '@shared/services/modal.service';
import { Observable } from 'rxjs';
import { ResolutionESignBaseService } from '../resolution/resolution-e-sign-base-service';

/**
 * Сервис для подписания ознакомлений
 */
@Injectable({
  providedIn: 'root',
})
export class FamiliarizationESignService extends ResolutionESignBaseService {
  constructor(
    currentUser: CurrentUserIsproStoreService,
    oogEsignInteropService: OogEsignInteropService,
    fileCertificateHelperService: FileCertificateHelperService,
    modalService: ModalService,
    private settingsCertificateService: SettingsCertificateService,
    private dateHelperService: DateHelperService,
  ) {
    super(oogEsignInteropService, currentUser, fileCertificateHelperService, modalService);
  }

  /**
   * Метод для подписания
   *
   * @param execution Обьект исполнения с подписанными данными
   * @param user Текущий пользователь
   * @returns
   */
  public injectESign(execution: ExecutionModel, user: EmployeeModel): Observable<boolean> {
    const certificate = this.settingsCertificateService.getDefaultCertificate(ProjectEnum.oog);
    const resolutionJson = this.getJson(this.curtailResolution(user));
    const resolutionSignDssInfo = this.getResolutionSignDssInfo(execution, user);
    return this.eSign(execution, certificate, resolutionJson, resolutionSignDssInfo);
  }

  /**
   * Упрощенная модель резолюции
   * Только данные для подписания
   *
   * @param user
   */
  public curtailResolution(user: EmployeeModel): Partial<ResolutionExecutionModel> {
    const executor = this.mapToEmployeeSimpleModel(user);
    const executionComment = 'Ознакомлен';

    return { executor, executionComment } as Partial<ResolutionExecutionModel>;
  }

  /**
   * Формирует информацию о резолюции для отображения в myDSS
   *
   * @param execution
   * @param user
   * @private
   */
  protected getResolutionSignDssInfo(execution: ExecutionModel, user: EmployeeModel): string {
    const result = [];

    const resolutionDate = this.dateHelperService.dateFormatting(
      new Date().toLocaleString(),
      DateHelperConst.dateTimeSecFormat,
    );
    const signDate = this.dateHelperService.dateFormatting(new Date().toLocaleString(), DateHelperConst.dateFormat);

    const signTitle = `Подпись отметки об исполнении от ${resolutionDate}`;
    const signFooter = `Дата отправки на подписание: ${signDate}`;
    const getFullName = (person: EmployeeModel): string => {
      if (!person) {
        return '';
      }
      return [person?.lastName, person?.firstName, person?.middleName].filter(Boolean).join(' ') || '';
    };

    const dssFieldsNames = eSignBrokenFieldsNames.executeResolution;
    const resolutionMapFn = (r: ExecutionModel): { key: string; value: string }[] => [
      { key: 'executor', value: getFullName(user) },
      { key: 'executionComment', value: r.executionComment },
    ];

    const signBody = this.prepareDssData(resolutionMapFn(execution), dssFieldsNames);

    result.push(signTitle, signBody, signFooter);

    return result.join('\r\n');
  }
}
