/** Сохранить id активного проекта */
export class SetActiveProject {
  public static readonly type = '[ExpeditedReview] Set Active Project';
  constructor(public id: number) {}
}

/** Сбросить id активного проекта */
export class ResetActiveProject {
  public static readonly type = '[ExpeditedReview] Reset Active Project';
}
