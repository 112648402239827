import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { documentsGroupSearch } from '@oogShared/consts/documents-group-search.const';

import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { FoldersStateService } from '@oogShared/services/folders/folders-state.service';
import { MenuHelperService } from '@oogShared/services/menu-helper.service';
import { ApprovalListState } from '@store/approval-list-store/approval-list-store.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetGroupCards, SetGroupCardStatus } from '@store/menu/documents-group/documents-group.action';
import { DocumentsGroupState } from '@store/menu/documents-group/documents-group.state';
import { SetShownGroup } from '@store/menu/sidebar/sidebar.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { SettingsState } from '@store/settings/settings.state';
import { Observable } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { SetActiveDocumentCard } from '@store/menu/active-card/active-card.action';
import { SwipeMenuService } from '@oogShared/services/swipe-menu.service';
import { LoadStatusEnum } from '@enums/load-status.enum';
import { DocumentNavigationService } from '@oogShared/services/document-navigation.service';
import { SideBarBase } from '../side-bar-base';

/** Компонент сайдбара для отображения группового согласования */
@Component({
  selector: 'app-side-bar-group',
  templateUrl: './side-bar-group.component.html',
  styleUrls: ['./side-bar-group.component.scss'],
})
export class SideBarGroupComponent extends SideBarBase implements OnInit, OnDestroy {
  @Input() public namePage = '';
  @Input() public documentCards: CardDocumentModel[] = [];
  @Input() public disableButtons = false;

  @Output() public document = new EventEmitter<CardDocumentModel>();

  @Select(SettingsState.showButtonFamiliarization)
  public buttonFamiliarization$: Observable<boolean>;

  @Select(ApprovalListState.canOnlyReturnApprovalList)
  public showOnlyReturnButton$: Observable<boolean>;

  @Select(ResolutionState.getFamiliarizationPerformers)
  public familiarizationPerformers$!: Observable<ResolutionAddresseeModel[]>;

  @Select(ActiveCardState.canApproveResolutionProject)
  public canApproveResolutionProject$: Observable<boolean>;

  @Select(ActiveCardState.isChildCardInGroup)
  public isChildCardInGroup$: Observable<boolean>;

  @Select(DocumentsGroupState.countDocuments)
  public countDocuments$: Observable<number>;

  @Select(DocumentsGroupState.getStatus)
  public status$: Observable<LoadStatusEnum>;

  public folders$ = this.foldersState.getFolders();
  public idActiveDocument = null;
  public authUser: EmployeeModel;
  public searchText = documentsGroupSearch;
  public statuses = LoadStatusEnum;

  constructor(
    store: Store,
    swipeMenu: SwipeMenuService,
    elementRef: ElementRef,
    private menuHelper: MenuHelperService,
    private documentNavigation: DocumentNavigationService,
    private foldersState: FoldersStateService,
    private authUserService: AuthUserIsproStoreService,
  ) {
    super(store, swipeMenu, elementRef);
  }

  public ngOnInit(): void {
    this.subscribeToActiveCard();
    this.subscribeToAuthUser();
  }

  public ngOnDestroy(): void {
    const currentStatus = this.store.selectSnapshot(DocumentsGroupState.getStatus);
    if (currentStatus === this.statuses.error) {
      this.documentNavigation.nextDocument();
    }
    this.store.dispatch([new ResetGroupCards()]);
  }

  public previousPage(): void {
    const activeCardInGroup = this.store.selectSnapshot(DocumentsGroupState.getMainCardInGroup);
    this.store.dispatch([new SetShownGroup(false), new SetActiveDocumentCard(activeCardInGroup)]);
    this.document.emit(activeCardInGroup);
  }

  public doRefresh(event: any): void {
    const groupId = this.store.selectSnapshot(ActiveCardState.getActiveCard).groupProjects?.groupProjects?.id;
    if (!groupId) {
      return;
    }

    this.store.dispatch(new SetGroupCardStatus(LoadStatusEnum.loading));

    this.menuHelper
      .refreshDocumentGroup(groupId)
      .pipe(finalize(() => event.target.complete()))
      .subscribe({
        error: () => this.store.dispatch(new SetGroupCardStatus(LoadStatusEnum.error)),
      });
  }

  public resetSearch(value: string): void {
    if (value.length) {
      return;
    }

    this.previousPage();
  }

  private subscribeToActiveCard(): void {
    this.store
      .select(ActiveCardState.getActiveCard)
      .pipe(
        filter((data) => !!data),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((card) => {
        this.idActiveDocument = card.uniqueId;
      });
  }

  private subscribeToAuthUser(): void {
    this.authUserService
      .getCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((a) => (this.authUser = a));
  }
}
