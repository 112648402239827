import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { RelevantWorkersState } from '@store/main-screen/relevant-workers/relevant-workers.state';
import { WorkForEveryoneState } from '@store/main-screen/work-for-everyone/work-for-everyone.state';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';

import { MenuRequestModel } from '../models/documents/menu-request.model';

/** Возвращает сессию в "Авторизованного пользователя" */
export const retrieveEmployees = (
  store: Store,
  currentUser: CurrentUserIsproStoreService,
  authUserService: AuthUserIsproStoreService,
  refresh: boolean,
): MenuRequestModel[] => {
  const authUser = authUserService.getCurrentUserSnapshot();
  const workForEveryone = store.selectSnapshot(WorkForEveryoneState.workForEveryone);
  if (!workForEveryone) {
    return store.selectSnapshot(DocumentsCardsState.workForSelf(currentUser.getCurrentUserSnapshot()?.id, refresh));
  } else {
    const relevant = store.selectSnapshot(RelevantWorkersState.relevantWorkers);
    return store.selectSnapshot(DocumentsCardsState.workForEveryone(refresh, relevant, authUser));
  }
};
