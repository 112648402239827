import { LoadingController, ModalController } from '@ionic/angular';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { CryptoSignatureTypeEnum } from '@npaShared/enums/crypto-signature-type.enum';
import { DocumentPackageApproveModel } from '@npaShared/models/document-package/document-package-approve.model';
import { ParamsForSigning } from '../agreement-sign.service';
import { SignConfirmType } from '../../../../document-agreement/enums/sign-confirm-type.enum';
import { BaseSign } from './base-signing';

export class CloudSigning extends BaseSign {
  constructor(
    protected loadingController: LoadingController,
    protected modalController: ModalController,
    private userStore: CurrentUserAissdStoreService,
  ) {
    super(loadingController, modalController);
  }

  public async getModalOrLoading(hasCloudEsign: boolean): Promise<HTMLIonLoadingElement | HTMLIonModalElement> {
    const isMyDSSTypeEsign = this.userStore.getCurrentUserSnapshot().signConfirmType === SignConfirmType.myDSS;

    const modal =
      hasCloudEsign && isMyDSSTypeEsign ? await this.createConfirmEsignPopup() : await this.createApproveLoader();

    await modal.present();
    return modal;
  }

  public async getParams(data: ParamsForSigning): Promise<DocumentPackageApproveModel> {
    return {
      certificateId: data.certificateId,
      revisions: data.revisions,
      text: data.text,
      pointId: data.pointId,
      signature: {
        signatureType: CryptoSignatureTypeEnum.cloudDss,
        dssCertificateId: data.certificateId,
      },
    };
  }
}
