import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GraphicHelperService } from '../../../document-forms/graphic-form/services/graphic-helper.service';
import { AudioFormService } from '../../../document-forms/audio-form/services/audio-form.service';

/** Сервис для восстановления графического или аудио файла резолюции */
@Injectable({
  providedIn: 'root',
})
export class RestoreResolutionFileService {
  constructor(private graphicHelperService: GraphicHelperService, private audioFormService: AudioFormService) {}

  public restoreFile(blob: Blob, form: FormGroup): void {
    if (blob.type === 'image/png') {
      this.graphicHelperService.restoreImage(blob, form);
    }

    if (blob.type === 'application/octet-stream') {
      this.audioFormService.restoreAudio(blob, form);
    }
  }
}
