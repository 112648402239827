import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RoutePoint } from '@npaShared/models/route/route.models';
import { map } from 'rxjs/operators';
import { isPointInChild } from '@npaShared/helpers/route/point-helper';

@Injectable({
  providedIn: 'root',
})
export class SelectedRoutePointService {
  private activePoint$ = new BehaviorSubject<RoutePoint>(null);
  private potentialActivePointsList$ = new BehaviorSubject<RoutePoint[]>([]);

  public getSelectedPoint$(): Observable<RoutePoint> {
    return this.activePoint$.asObservable();
  }

  public getSelectedPoint(): RoutePoint {
    return this.activePoint$.getValue();
  }

  public getPotentialActivePointsList$(): Observable<RoutePoint[]> {
    return this.potentialActivePointsList$.asObservable();
  }

  public getPotentialActivePointsList(): RoutePoint[] {
    return this.potentialActivePointsList$.getValue();
  }

  public getActivePointsCount$(): Observable<number> {
    return this.getPotentialActivePointsList$().pipe(map((points) => points.length));
  }

  public getSelectedPointIdx$(): Observable<number> {
    return this.getSelectedPoint$().pipe(
      map((selectedPoint) => this.getPotentialActivePointsList().indexOf(selectedPoint)),
    );
  }

  public setSelectedPoint(point: RoutePoint): void {
    this.activePoint$.next(point);
  }

  public setPotentialActivePointsList(potentialActivePoints: RoutePoint[]): void {
    this.potentialActivePointsList$.next(potentialActivePoints);
  }

  public next(): void {
    const activePoints = this.getPotentialActivePointsList();
    const currentIdx = this.getPotentialActivePointsList().indexOf(this.getSelectedPoint());

    let newActivePoint: RoutePoint;
    if (currentIdx < activePoints.length - 1) {
      newActivePoint = activePoints[currentIdx + 1];
    } else {
      newActivePoint = activePoints[0];
    }
    this.setSelectedPoint(newActivePoint);
  }

  public hasAnotherActivePoints(): boolean {
    const activePointsWithoutSelected = this.getPotentialActivePointsList().filter(
      (point) => point.id !== this.getSelectedPoint().id,
    );

    const pointsWithoutSelectedPointChildren = activePointsWithoutSelected.filter(
      (point) => !isPointInChild(this.getSelectedPoint(), point?.id),
    );

    return !!pointsWithoutSelectedPointChildren.length;
  }
}
