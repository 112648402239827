import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationInformationService {
  private readonly isLoggedOutFieldName = 'is-logged-out';
  private readonly needToShowAuthFormDuringModerationFieldName = 'need-to-show-moderation-form';

  public get isLoggedOut(): boolean {
    return localStorage.getItem(this.isLoggedOutFieldName) === 'true';
  }

  public get needToShowModerationForm(): boolean {
    return localStorage.getItem(this.needToShowAuthFormDuringModerationFieldName) === 'true';
  }

  public loggedOut(): void {
    localStorage.setItem(this.isLoggedOutFieldName, 'true');
  }

  public loggedIn(): void {
    localStorage.setItem(this.isLoggedOutFieldName, 'false');
  }

  public dontShowModerationForm(): void {
    localStorage.setItem(this.needToShowAuthFormDuringModerationFieldName, 'false');
  }

  public deleteNecessityShowModerationForm(): void {
    localStorage.removeItem(this.needToShowAuthFormDuringModerationFieldName);
  }
}
