import { InitiationDefaultLogic } from '@npaCore/point-logic/initiation-default-logic';
import { getPhaseByPointId } from '@npaShared/helpers/route/phases-helper';

/**
 * Этап с типом "Пользовательский этап"
 * Подэтап "Поручение"
 */
export class UserPhaseInitiationPointLogic extends InitiationDefaultLogic {
  public canSendRemarksToAuthor(): boolean {
    const phaseByPointId = getPhaseByPointId(this.getRoute(), this.getPoint()?.id);

    if (!phaseByPointId.phaseTemplate.returnAllowed) {
      return false;
    }

    return super.canSendRemarksToAuthor();
  }
}
