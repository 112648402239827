import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { ModalController } from '@ionic/angular';
import { AppealResolutionEnum } from '@oogShared/enums/resolution-form/appeal-resolution-type.enum';

import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { CommissionForSpread } from '@oogShared/models/resolutions/mission/mission-for-spread.model';

@Component({
  selector: 'app-spread-executor-modal',
  templateUrl: './spread-executor-modal.component.html',
  styleUrls: ['./spread-executor-modal.component.scss'],
})
/** Модальное окно для добавления исполнителя во все поручения
 * вызывается при выборе исполнителя в первом вопросе и первом поручении
 */
export class SpreadExecutorModalComponent implements OnInit {
  @Input() public executor: EmployeeModel;
  @Input() public commissions: CommissionForSpread[];

  public form: FormGroup = new FormGroup({});

  public buttonTheme = ButtonThemeEnum;
  /** Тип поручения */
  public types = AppealResolutionEnum;

  constructor(private modalCtrl: ModalController, private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public confirm(): void {
    this.modalCtrl.dismiss({
      sprededCommissionIdxs: this.getSprededCommissionIdxs(),
    });
  }

  public cancel(): void {
    this.modalCtrl.dismiss();
  }

  public getCommissionArray(): FormArray {
    return this.form.controls.commissions as FormArray;
  }

  public commissionCheckedToggle(commission: AbstractControl): void {
    const currentValue = commission.get('checked').value;
    commission.get('checked').setValue(!currentValue);
  }

  private initForm(): void {
    this.form = this.fb.group({
      commissions: this.fb.array(this.createFormArray()),
    });
  }

  private createFormArray(): FormGroup[] {
    return this.commissions.map((commission) =>
      this.fb.group({
        question: commission.question,
        commissionType: commission.commissionType,
        checked: false,
      }),
    );
  }

  /** Возвращает массив индексов поручений, у которых установлен флаг добавления */
  private getSprededCommissionIdxs(): number[] {
    const checkedCommissionIdxs = this.getCommissionArray().controls.map((control) => control.get('checked').value);

    return checkedCommissionIdxs.reduce((acc, cur, index) => {
      if (cur) {
        return acc.concat(index);
      }
      return acc;
    }, []);
  }
}
