import { ArrowMovementState } from '@store/arrow-movement/arrow-movement.state';
import { DictionariesAllUrgencyState } from '@store/dictionaries/dictionaries-all-urgency/dictionaries-all-urgency.state';
import { DictionariesSpecialEmployeeState } from '@store/dictionaries/dictionaries-special-employee/dictionaries-special-employee.state';
import { DictionariesWeekendsState } from '@store/dictionaries/dictionaries-weekends/dictionaries-weekends.state';
import { DictionariesState } from '@store/dictionaries/dictionaries.state';
import { ActiveProjectExpeditedReviewState } from '@store/expedited-review/active-project/active-project-expedited-review.state';
import { ChosenDocumentInGroupState } from '@store/expedited-review/choosen-document-in-group/chosen-document-in-group.state';
import { ExpeditedReviewState } from '@store/expedited-review/expedited-review.state';
import { MenuExpeditedReviewState } from '@store/expedited-review/menu/menu-expedited-review.state';
import { FileFullScreenState } from '@store/file-full-screen/file-full-screen.state';
import { EmployeeReplacedState } from '@store/main-screen/employee-replaced/employee-replaced.state';
import { MainScreenState } from '@store/main-screen/main-screen.state';
import { RelevantWorkersState } from '@store/main-screen/relevant-workers/relevant-workers.state';
import { StatisticEmployeeFromState } from '@store/main-screen/statistic-employee-from/statistic-employee-from.state';
import { WorkForEveryoneState } from '@store/main-screen/work-for-everyone/work-for-everyone.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ArrowButtonsState } from '@store/menu/arrow-buttons/arrow-buttons.state';
import { CountDocumentsMenuState } from '@store/menu/count-documents-menu/count-documents-menu.state';
import { MenuState } from '@store/menu/menu.state';
import { PreviousActiveDocumentState } from '@store/menu/previous-active-document/previous-active-document.state';
import { ActiveResolutionProjectState } from '@store/resolution-store/active-resolution-project/active-resolution-project.state';
import { BookmarksState } from '@store/resolution-store/bookmarks/bookmarks.state';
import { DossierState } from '@store/resolution-store/dossier/dossier.state';
import { ResolutionFilesState } from '@store/resolution-store/resolution-files/resolution-files.state';
import { ResolutionStoreState } from '@store/resolution-store/resolution-store.state';
import { ResolutionTreeState } from '@store/resolution-store/resolution-tree/resolution-tree.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { RightAcceleratedReviewState } from '@store/right-accelerated-review/right-accelerated-review.state';
import { RightsForRedirectionExpeditedReviewState } from '@store/right-for-redirection-expedited-review/rights-for-redirection-expedited-review.state';
import { SettingsState } from '@store/settings/settings.state';
import { StatusCurrentUserAssistantState } from '@store/status-current-user-assistant/status-current-user-assistant.state';
import { UpdateNotificationState } from '@store/update-notification/update-notification.state';
import { DocumentsGroupState } from '@store/menu/documents-group/documents-group.state';
import { CacheTimingState } from '@store/cache-timing/cache-timing.state';
import { ApprovalListState } from './approval-list-store/approval-list-store.state';
import { DictionariesHotEmployeeState } from './dictionaries/dictionaries-hot-employee/dictionaries-hot-employee.state';
import { DictionariesTemplateState } from './dictionaries/dictionaries-templates/dictionaries-template.state';
import { DictionariesUrgencyState } from './dictionaries/dictionaries-urgency/dictionaries-urgency.state';
import { FormsState } from './forms/forms.state';
import { DocumentsCardsState } from './menu/documents-cards/documents-cards.state';
import { DocumentsCategoryState } from './menu/documents-category/documents-category.state';
import { SidebarState } from './menu/sidebar/sidebar.state';
import { NotificationsState } from './notifications/notifications.state';
import { VisibilityBackdropState } from './visibility-backdrop/visibility-backdrop.state';
import { HasDssCurrentUserState } from './has-dss-current-user/has-dss-current-user.state';
import { PublicResolverDocumentsState } from './public-resolver-documents/public-resolver-documents.state';

export const StoreList = [
  MainScreenState,
  RelevantWorkersState,
  WorkForEveryoneState,
  StatisticEmployeeFromState,
  SettingsState,
  MenuState,
  ActiveCardState,
  ArrowButtonsState,
  DocumentsCategoryState,
  DocumentsCardsState,
  SidebarState,
  PreviousActiveDocumentState,
  CountDocumentsMenuState,
  DictionariesState,
  DictionariesUrgencyState,
  DictionariesHotEmployeeState,
  DictionariesTemplateState,
  DictionariesAllUrgencyState,
  DictionariesWeekendsState,
  DictionariesSpecialEmployeeState,
  ResolutionStoreState,
  ResolutionState,
  ResolutionTreeState,
  ResolutionFilesState,
  BookmarksState,
  ActiveResolutionProjectState,
  DossierState,
  FormsState,
  ArrowMovementState,
  EmployeeReplacedState,
  NotificationsState,
  ExpeditedReviewState,
  MenuExpeditedReviewState,
  ActiveProjectExpeditedReviewState,
  VisibilityBackdropState,
  ChosenDocumentInGroupState,
  ApprovalListState,
  RightsForRedirectionExpeditedReviewState,
  StatusCurrentUserAssistantState,
  FileFullScreenState,
  UpdateNotificationState,
  RightAcceleratedReviewState,
  DocumentsGroupState,
  HasDssCurrentUserState,
  CacheTimingState,
  PublicResolverDocumentsState,
];
