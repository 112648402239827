import { Pipe, PipeTransform } from '@angular/core';

import { ResolutionAddresseeTypeEnum } from '../../enums/resolution-addressee/resolution-addressee-type.enum';
import { ResolutionTypeEnum } from '../../enums/resolution-type/resolution-type.enum';
import { DocumentCardTypeEnum } from '../../enums/side-bar/document-card-type.enum';
import { CardDocumentModel } from '../../models/documents/card-document.model';

@Pipe({
  name: 'documentShowRightDays',
})
export class DocumentShowRightDaysPipe implements PipeTransform {
  public transform(document: CardDocumentModel): boolean {
    return !!(
      (document.cardType === DocumentCardTypeEnum.approval && document.dueDate) ||
      (document.cardType === DocumentCardTypeEnum.resolution &&
        [ResolutionTypeEnum.resolution, ResolutionTypeEnum.request].includes(document.resolutionType.id) &&
        document.addresseeType.id !== ResolutionAddresseeTypeEnum.reviewer)
    );
  }
}
