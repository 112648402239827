import { Pipe, PipeTransform } from '@angular/core';

import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';
import { StatisticsResponseModel } from '@npaShared/models/statistics.response.model';

/** Пайп для подсчета категорий ПД в предоставленных юнитах по типу категории в приложении НПА
 *
 * @param statistics массив юнитов в котором производим поиск
 * @param statisticName имя категории, чье кол-во интересует
 * @returns кол-во ПД
 */
@Pipe({
  name: 'npaCategoryCount',
})
export class NpaCategoryCountPipe implements PipeTransform {
  public transform(statistics: StatisticsResponseModel[], statisticName: StatisticsEnum): number | null {
    if (!statistics || !statisticName) {
      return null;
    }

    const requiredStatistic = statistics.find((stat) => stat.name === statisticName);

    return requiredStatistic ? requiredStatistic.count : null;
  }
}
