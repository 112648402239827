import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Urls } from '@const/urls';

/** Интерсептор таймаута на запрос health для избежания белого экрана при загрузке */
@Injectable()
export class HealthTimeoutInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === Urls.backendStatus.checkConnectionOog) {
      const timeoutInMilliseconds = 30000;
      return next.handle(req).pipe(timeout(timeoutInMilliseconds));
    }
    return next.handle(req);
  }
}
