import { Pipe, PipeTransform } from '@angular/core';
import { DisplayingMessageAboutTaskCompletionUnavailableService } from '@npaShared/services/to-decide-modals/displaying-message-about-task-completion-unavailable.service';

/** Отображение сообщения "Выполнение задачи недоступно без электронной подписи. Обратитесь к администратору системы" */
@Pipe({
  name: 'displayingMessageAboutTaskCompletionUnavailable',
})
export class DisplayingMessageAboutTaskCompletionUnavailablePipe implements PipeTransform {
  constructor(
    private displayingMessageAboutAbsenceOfSignature: DisplayingMessageAboutTaskCompletionUnavailableService,
  ) {}

  public transform(isLocalLegalActsType: boolean, hasSignature: boolean): boolean {
    return this.displayingMessageAboutAbsenceOfSignature.displayingMessage(isLocalLegalActsType, hasSignature);
  }
}
