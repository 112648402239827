import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionsFooterWithArrow } from '@enums/actions-footer-with-arrows.enum';

/** Компонент для отображения стрелок для перехода меджу ПД и кнопок между этими стрелками */
@Component({
  selector: 'app-footer-with-arrow',
  templateUrl: './footer-with-arrow.component.html',
  styleUrls: ['./footer-with-arrow.component.scss'],
})
export class FooterWithArrowComponent {
  @Input() public isShowArrows = true;
  @Input() public isEnabledLeftArrow = true;
  @Input() public isEnabledRightArrow = true;
  @Input() public actionWithFolders = false;

  @Output() public action: EventEmitter<ActionsFooterWithArrow> = new EventEmitter();

  public readonly actions = ActionsFooterWithArrow;
}
