import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';

import { DischargeOfDutiesModel } from '../models/discharge-of-duties/discharge-of-duties.model';

@Injectable({
  providedIn: 'root',
})
export class DischargeOfDutiesInteropService {
  constructor(private http: HttpClient) {}

  public listOfDuties(id: number): Observable<DischargeOfDutiesModel[]> {
    const url = Urls.dischargeOfDuties(id);

    return this.http.get<PaginationBaseModel<DischargeOfDutiesModel>>(url).pipe(map((val) => val.data.content));
  }
}
