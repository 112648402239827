import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { CollectionResponse } from '@models/collection-response';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';
import { EsignRequestModel } from '@oogShared/models/esign/esign-request.model';
import { EsignResponseModel } from '@oogShared/models/esign/esign-response.model';
import { EsignTransactionStatusModel } from '@oogShared/models/esign/esign-transaction-status.model';
import { PrepareDataResponseModel } from '@oogShared/models/esign/prepare-data-response.model';
import { SigningRequestModel } from '@oogShared/models/esign/signing-request.model';
import { InterceptorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';
import { ResolutionESignSettingsRequestModel } from '@oogShared/models/esign/resolution-esign-settings-request.model';

@Injectable({
  providedIn: 'root',
})
export class OogEsignInteropService {
  constructor(private http: HttpClient) {}

  /** Получение сертификатов */
  public getCerts(): Observable<EsignResponseModel[]> {
    const url = Urls.oogEsign.getCerts;
    return this.http.get<PaginationBaseModel<EsignResponseModel>>(url).pipe(map((val) => val.data.content));
  }

  /** Создание подписи */
  public createSign(data: EsignRequestModel): Observable<EsignTransactionStatusModel> {
    const url = Urls.oogEsign.createSign;
    return this.http
      .post<BaseApiResponseModel<EsignTransactionStatusModel>>(url, data, {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      })
      .pipe(map((val) => val.data));
  }

  /** Получение статуса подписания */
  public getSignStatus(transactionId: string): Observable<EsignTransactionStatusModel> {
    const url = Urls.oogEsign.signStatus(transactionId);
    return this.http.get<BaseApiResponseModel<EsignTransactionStatusModel>>(url).pipe(map((val) => val.data));
  }

  public prepareData(approvalId: number): Observable<PrepareDataResponseModel> {
    const url = Urls.oogEsign.prepareData();
    return this.http.post<PrepareDataResponseModel>(
      url,
      { approvalId },
      {
        params: new InterceptorHttpParams(new InterceptorConfig(true, true, 'Подготовка данных для подписи')),
      },
    );
  }

  public saveData(appealMovementId: number, data: SigningRequestModel): Observable<CollectionResponse<any>> {
    const url = Urls.oogEsign.saveData(appealMovementId);
    return this.http.post<CollectionResponse<any>>(url, data);
  }

  public saveResolutionESignSettings(data: ResolutionESignSettingsRequestModel): Observable<unknown> {
    const url = Urls.oogEsign.resolutionEsignSettingsSave;
    const params = Object.entries(data || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) as unknown as HttpParams;
    return this.http.post<unknown>(url, null, { params });
  }
}
