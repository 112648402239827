import { AddPerformerFromCommissionComponent } from '@oogShared/components/add-performer-from-commission/add-performer-from-commission.component';
import { CardExpeditedReviewComponent } from '@oogShared/components/card-expedited-review/card-expedited-review.component';
import { ProjectsExpeditedReviewComponent } from '@oogShared/components/card-expedited-review/projects-expedited-review/projects-expedited-review.component';
import { AudioAndGraphicActionComponent } from '@oogShared/components/commission-action/audio-and-graphic-action/audio-and-graphic-action.component';
import { ConfirmModalComponent } from '@oogShared/components/confirm-modal/confirm-modal.component';
import { FileViewerComponent } from '@oogShared/components/file-viewer/file-viewer.component';
import { InvalidRedirectModalComponent } from '@oogShared/components/invalid-redirect-modal/invalid-redirect-modal.component';
import { ResolutionTreeComponent } from '@oogShared/components/resolution-tree/resolution-tree.component';
import { SideBarExpeditedReviewComponent } from '@oogShared/components/side-bar-expedited-review/side-bar-expedited-review.component';
import { AgendaComponent } from '@oogShared/components/agenda/agenda.component';
import { SideBarGroupComponent } from '@oogShared/components/side-bar-group/side-bar-group.component';
import { ProjectLetterComponent } from '@oogShared/components/project-letter/project-letter.component';
import { NotificationNoDocumentComponent } from '@oogShared/components/notification-no-document/notification-no-document.component';
import { CommentComponent } from '@oogShared/components/comment/comment.component';
import { ResolutionTreeFilterComponent } from '@oogShared/components/resolution-tree/resolution-tree-filter/resolution-tree-filter.component';
import { AudioFormComponent } from '../../document-forms/audio-form/audio-form.component';

import { ResolutionFileComponent } from '../../documents-page/resolution-project-cards/components/resolution-file/resolution-file.component';
import { ResolutionFilesComponent } from '../../documents-page/resolution-project-cards/components/resolution-files/resolution-files.component';
import { ResolutionHistoryComponent } from '../../documents-page/resolution-project-cards/components/resolution-history/resolution-history.component';
import { ResolutionInfoHistoryElementComponent } from '../../documents-page/resolution-project-cards/components/resolution-info-history-element/resolution-info-history-element.component';
import { ResolutionQuestionsFilesComponent } from '../../documents-page/resolution-project-cards/components/resolution-questions-files/resolution-questions-files.component';
import { AddPerformerComponent } from './add-performer/add-performer.component';
import { CardReviewersComponent } from './card-expedited-review/card-reviewers/card-reviewers.component';
import { CommissionActionComponent } from './commission-action/commission-action.component';
import { CommissionRelatedComponent } from './commission-related/commission-related.component';
import { DocumentCardComponent } from './document-card/document-card.component';
import { FilterActionsComponent } from './filter/components/filter-actions/filter-actions.component';
import { FilterSortButtonsComponent } from './filter/components/filter-sort-buttons/filter-sort-buttons.component';
import { FilterComponent } from './filter/filter.component';
import { DeleteFolderComponent } from './folder-modals/delete-folder/delete-folder.component';
import { FoldersPageComponent } from './folder-modals/folders-page/folders-page.component';
import { EditCreateFolderComponent } from './folder-modals/new-folder/edit-create-folder.component';
import { FoldersEditMenuComponent } from './folders-item/folders-edit-menu/folders-edit-menu.component';
import { FoldersItemComponent } from './folders-item/folders-item.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { HotListEmployeeComponent } from './hot-list-employee/hot-list-employee.component';
import { OogSelectComponent } from './oog-select/oog-select.component';
import { PerformerInfoComponent } from './performer-info/performer-info.component';
import { ResolutionDossierComponent } from './resolution-dossier/resolution-dossier.component';
import { FilesMyOrganizationComponent } from './resolution-my-organization/files-my-organization/files-my-organization.component';
import { ResolutionMyOrganizationComponent } from './resolution-my-organization/resolution-my-organization.component';
import { SideBarFoldersComponent } from './side-bar-folders/side-bar-folders.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { ClonePerformerComponent } from './clone-performer/clone-performer.component';
import { SideBarEmptyMessageComponent } from './side-bar-empty-message/side-bar-empty-message.component';

export const ComponentsOog = [
  AudioFormComponent,
  FormHeaderComponent,
  HotListEmployeeComponent,
  AddPerformerComponent,
  CommissionActionComponent,
  PerformerInfoComponent,
  SideBarComponent,
  DocumentCardComponent,
  OogSelectComponent,
  CommissionRelatedComponent,
  FilterComponent,
  FilterActionsComponent,
  FilterSortButtonsComponent,
  SideBarFoldersComponent,
  FoldersItemComponent,
  EditCreateFolderComponent,
  FoldersEditMenuComponent,
  DeleteFolderComponent,
  FoldersItemComponent,
  FoldersPageComponent,
  ResolutionMyOrganizationComponent,
  FilesMyOrganizationComponent,
  ResolutionFileComponent,
  ResolutionDossierComponent,
  SideBarExpeditedReviewComponent,
  CardExpeditedReviewComponent,
  CardReviewersComponent,
  ProjectsExpeditedReviewComponent,
  ResolutionFilesComponent,
  ResolutionInfoHistoryElementComponent,
  ResolutionHistoryComponent,
  ResolutionTreeComponent,
  InvalidRedirectModalComponent,
  AudioAndGraphicActionComponent,
  ConfirmModalComponent,
  AgendaComponent,
  FileViewerComponent,
  AddPerformerFromCommissionComponent,
  ResolutionQuestionsFilesComponent,
  SideBarGroupComponent,
  ProjectLetterComponent,
  NotificationNoDocumentComponent,
  ClonePerformerComponent,
  CommentComponent,
  SideBarEmptyMessageComponent,
  ResolutionTreeFilterComponent,
];
