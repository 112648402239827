import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

/** Сохранить замещаемых работников */
export class SetRelevantWorkers {
  public static readonly type = '[Relevant Workers] Set Relevant Workers';
  constructor(public workers: EmployeeModel[]) {}
}

/** Очистить список с ИО */
export class ResetRelevantWorkers {
  public static readonly type = '[Relevant Workers] Reset Relevant Workers';
}
