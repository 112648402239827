import { Pipe, PipeTransform } from '@angular/core';

import { EmployeeBaseModel } from '@npaShared/models/employee/employee-base.model';

/** пайп возвращает строку с фамилией и инициалами пользователя АИС СД */
@Pipe({
  name: 'npaUserShortName',
})
export class NpaUserShortNamePipe implements PipeTransform {
  public transform(employee: EmployeeBaseModel): string {
    if (!employee) {
      return '';
    }

    const lastName = employee.lastName || '';
    const shortFirstName = employee.firstName ? `${employee.firstName[0]}.` : '';
    const shortPatronymic = employee.patronymic ? `${employee.patronymic[0]}.` : '';

    return `${lastName} ${shortFirstName}${shortPatronymic}`;
  }
}
