import { Pipe, PipeTransform } from '@angular/core';

import { SettingsResponseCertificateSubjectModel } from '@models/settings/settings-response/settings-response-certificate.model';

@Pipe({
  name: 'settingsCertificateUserName',
})
export class SettingsCertificateUserNamePipe implements PipeTransform {
  public transform(subject: SettingsResponseCertificateSubjectModel): string {
    return transformSettingsCertificateUserName(subject);
  }
}

/** вернет ФИО пользователя сертификата */
export const transformSettingsCertificateUserName = (subject: SettingsResponseCertificateSubjectModel): string => {
  if (!subject) {
    return '';
  }

  const lastName = subject.SN;
  const firstNameAndMiddleName = subject.GN || subject.G;
  const fio = [lastName, firstNameAndMiddleName];

  return fio.filter(Boolean).join(' ') || subject.CN || '';
};
