import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'departmentShortPipe',
})
/** Превращение названия департамента в аббревиатуру */
export class DepartmentShortPipe implements PipeTransform {
  public transform(departmentFullName: string): string {
    const allWords = departmentFullName.split(' ');
    return allWords.map((word) => word[0]).join('');
  }
}
