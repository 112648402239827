import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { ActionsFooterWithArrow } from '@enums/actions-footer-with-arrows.enum';
import { IonContent, ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { ExistInSelectedFoldersPipe } from '@oogShared/pipes/exist-in-selected-folders/exist-in-selected.pipe';
import { SetArrowMovement } from '@store/arrow-movement/arrow-movement.action';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { SetDocumentsCardsFromSearch } from '@store/menu/documents-cards/documents-cards.action';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';
import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { SettingsState } from '@store/settings/settings.state';
import { modalIdCreateFolder } from '@const/modals-id.const';
import { FoldersStateService } from '@oogShared/services/folders/folders-state.service';
import { EditCreateFolderComponent } from '@oogShared/components/folder-modals/new-folder/edit-create-folder.component';
import { getFolderById, isChildFolder } from '@oogShared/functions/document-folder/document-folder.function';
import { FolderDataModelEnum } from '../../../enums/folders/personal-folder-page-modal.enum';
import { MoveToPersonalFolderModel } from '../../../models/folders/move-to-personal-folder.model';
import { NewFolderModalModel } from '../../../models/folders/new-folder-modal.model';
import { PersonalFolder } from '../../../models/folders/personal-folders.model';
import { FoldersInteropService } from '../../../rest/folders-interop.service';

@Component({
  selector: 'app-folders-page',
  templateUrl: './folders-page.component.html',
  styleUrls: ['./folders-page.component.scss'],
})
export class FoldersPageComponent implements AfterViewInit {
  @Input() public editMode = false;

  @Select(SettingsState.editFolders)
  public editFolders$: Observable<boolean>;

  @ViewChild('list') public list: IonContent;
  @ViewChild('main') public main: ElementRef;

  public contentShown = true;

  public folders$ = this.foldersState.getFolders();

  public clickedFolder$ = this.foldersState.getClickedFolder();

  public selectedFolder$ = this.foldersState.getSelectedFolders();

  public loggedUserId = this.loggedUserService.getCurrentUserSnapshot()?.id;

  public showDeleteButton$ = combineLatest([this.clickedFolder$, this.selectedFolder$]).pipe(
    map(
      ([clickedFolder, selectedFolder]) =>
        ((!clickedFolder && selectedFolder?.length) ||
          this.existInSelectedFolderPipe.transform(selectedFolder, clickedFolder?.id)) &&
        !this.editMode,
    ),
  );

  constructor(
    private foldersState: FoldersStateService,
    private foldersInterop: FoldersInteropService,
    private modalCtrl: ModalController,
    private loggedUserService: AuthUserIsproStoreService,
    private store: Store,
    private existInSelectedFolderPipe: ExistInSelectedFoldersPipe,
  ) {}

  public ngAfterViewInit(): void {
    setTimeout(() => this.scrollToSelected(), 700);
  }

  public moveToFolder(): void {
    this.modalCtrl.dismiss({
      type: FolderDataModelEnum.move,
    });
  }

  public moveToRootFolder(): void {
    this.modalCtrl.dismiss({
      type: FolderDataModelEnum.makeRoot,
    });
  }

  public isRootFolder(folders: PersonalFolder[]): boolean {
    if (folders) {
      return folders[0]?.nestedLevel === 1;
    }

    return false;
  }

  public removeDocumentFromFolder(): void {
    const actionWithFolders = this.store.selectSnapshot(SidebarState.actionWithFolders);
    const resultActionsOrder = this.getActionsWithFolderPipe();
    const clickedFolder = this.foldersState.getClickedFolderSnapshot();

    this.resetSelectedFolders();
    this.closeModal();

    resultActionsOrder
      .pipe(
        // только для действий с личными папками
        filter(() => actionWithFolders),
        // удалять документ надо, только если удаляем из текущей папки или из нескольких папок
        filter((personalFolders) => {
          const currentFolderId = this.store.selectSnapshot(SidebarState.folderId);
          const currentFolder = getFolderById(personalFolders, currentFolderId);

          return !clickedFolder || clickedFolder.id === currentFolder.id;
        }),
      )
      .subscribe(() => {
        this.removeCardsFromCurrentView();
      });
  }

  public getActionsWithFolderPipe(): Observable<PersonalFolder[]> {
    const selectedFolders = this.foldersState.getSelectedFoldersSnapshot();
    const clickedFolder = this.foldersState.getClickedFolderSnapshot();
    const activeCard = this.store.selectSnapshot(ActiveCardState.getActiveCard);

    if (!clickedFolder) {
      return forkJoin(
        selectedFolders.map((folder) =>
          this.foldersInterop.deleteAppealFromFolder(folder.id, activeCard.appealMovementId),
        ),
      ).pipe(
        switchMap(() => this.foldersState.getFoldersFromApi()),
        first(),
      );
    } else {
      return this.foldersInterop.deleteAppealFromFolder(clickedFolder.id, activeCard.appealMovementId).pipe(
        switchMap(() => this.foldersState.getFoldersFromApi()),
        first(),
      );
    }
  }

  public async createFolderOrSub(): Promise<void> {
    this.contentShown = false;
    const modal = await this.modalCtrl.create({
      componentProps: { title: 'Новая папка' },
      backdropDismiss: false,
      component: EditCreateFolderComponent,
      cssClass: 'modal-new-folder',
      id: modalIdCreateFolder,
      animated: false,
    });
    await modal.present();
    await modal.onWillDismiss().then((res: NewFolderModalModel) => {
      const clickedFolder = this.foldersState.getClickedFolderSnapshot();
      if (clickedFolder && res.data?.name) {
        this.createSubFolder(clickedFolder, res);
      } else if (res.data?.name) {
        this.createFolder(res);
      }
      this.contentShown = true;
    });
  }

  public cancel(): void {
    this.resetSelectedFolders();
    this.closeModal();
  }

  public addOrMoveDocumentToFolder(move: boolean): void {
    const dto = this.createDtoToAddOrMove(move);

    this.resetSelectedFolders();
    move ? this.closeModalWithMove() : this.closeModal();

    let resultOperationOrder = this.foldersInterop
      .moveToPersonalFolder(dto)
      .pipe(switchMap(() => this.foldersState.getFoldersFromApi()));

    if (move) {
      resultOperationOrder = resultOperationOrder.pipe(
        tap((personalFolders) => {
          const currentFolderId = this.store.selectSnapshot(SidebarState.folderId);
          const folder = getFolderById(personalFolders, currentFolderId);
          const isChild = isChildFolder(folder, dto.folderId);
          // удалять документы из списка надо только если переносим их не в дочернюю папку
          if (this.store.selectSnapshot(SidebarState.transitionFromFolders) && !isChild) {
            this.removeCardsFromCurrentView();
          }
        }),
      );
    }

    resultOperationOrder.pipe(first()).subscribe();
  }

  private scrollToSelected(): void {
    let heightForScroll = 0;
    const children = this.main?.nativeElement?.firstChild?.children;
    for (let i = 0; i < children.length; i++) {
      const firstChild = children[i].firstChild;
      if (firstChild?.classList.contains('selected-folder')) {
        break;
      }
      heightForScroll += children[i].offsetHeight;
      if (!firstChild?.classList.contains('selected-folder')) {
        heightForScroll = 0;
      }
    }
    this.list?.scrollToPoint(0, heightForScroll, 500);
  }

  private removeCardsFromCurrentView(): void {
    const activeCard = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    const documentCards = this.store.selectSnapshot(DocumentsCardsState.getCards);
    const filteredDocumentCards = documentCards.filter((card) => card.appealMovementId !== activeCard.appealMovementId);
    const documentIndex = documentCards.findIndex((doc) => doc.appealMovementId === activeCard.appealMovementId);

    // Если еще есть документы в текущей папке, то переходим то обновления состояния
    if (filteredDocumentCards.length) {
      this.arrowMovementRight();
    }

    if (documentIndex !== documentCards.length - 1) {
      this.store.dispatch([ResetResolution, new SetDocumentsCardsFromSearch(filteredDocumentCards)]);
    }

    // А если уже нет, то после
    if (!filteredDocumentCards.length) {
      this.arrowMovementRight();
    }
  }

  private arrowMovementRight(): void {
    this.store.dispatch(new SetArrowMovement({ action: ActionsFooterWithArrow.right }));
  }

  private createDtoToAddOrMove(move: boolean): MoveToPersonalFolderModel {
    const selectedFolderId = this.foldersState.getClickedFolderSnapshot().id;
    const selectedDoc = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    return {
      appealMovementIds: [selectedDoc.appealMovementId],
      employeeId: this.loggedUserId,
      folderId: selectedFolderId,
      removeFromOldPlace: move,
    };
  }

  private createFolder(res: NewFolderModalModel): void {
    this.foldersState
      .editOrCreateNewFolder({ ownerId: this.loggedUserId, name: res.data.name })
      .pipe(
        switchMap(() => this.foldersState.getFoldersFromApi()),
        first(),
      )
      .subscribe();
  }

  private createSubFolder(clickedFolder: PersonalFolder, res: NewFolderModalModel): void {
    this.foldersState
      .editOrCreateNewFolder({
        name: res.data.name,
        pid: clickedFolder.id,
        rootId: clickedFolder.rootId ?? clickedFolder.id,
      })
      .pipe(
        switchMap(() => this.foldersState.getFoldersFromApi()),
        first(),
      )
      .subscribe();
  }

  private resetSelectedFolders(): void {
    this.foldersState.setSelectedFolders(null);
    this.foldersState.setClickedFolder(null);
  }

  private closeModalWithMove(): void {
    this.modalCtrl.dismiss(null, FolderDataModelEnum.move);
  }

  private closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
