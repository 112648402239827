/** Константа с именами роутов */
export const routeNames = {
  /** общие */
  auth: 'auth',
  login: 'login',
  logout: 'logout',
  mainScreen: 'main-screen',
  settings: 'settings',
  userGuide: 'user-guide',
  appUpdate: 'app-update',

  /** оог */
  documents: 'documents',
  expeditedReview: 'expedited-review',
  documentForm: 'document-form',
  projectResolution: 'project-resolution',
  resolution: 'resolution',
  approvalList: 'approval-list',
  fullResolutionInfo: 'full-resolution-info',
  reports: 'reports',
  reportsList: 'reports-list',

  /** нпа */
  esignLogin: 'esign-login',
  documentPackage: 'document-package',
  documentPreview: 'document-preview',
  documentAgreement: 'document-agreement',
  approve: 'approve',
  approveLuz: 'approve-luz',
  redirect: 'redirect',
  return: 'return',
  sendRemarksToAuthor: 'send-remarks-to-author',
  sendToOauLeader: 'send-to-oau-leader',
  addToTreeExecutor: 'add-to-tree-executor',
  addToTreeCoordinators: 'add-to-tree-coordinators',
  addIntroducer: 'add-introducer',
  approveIntroduction: 'approve-introduction',
  route: 'route',
  point: 'point',
  phaseId: 'phase-id',
  phaseTypeId: 'phase-type-id',
  organizationId: 'organization-id',
  startApprovement: 'start-approvement',
  startApprovementWithRemarks: 'start-approvement-with-remarks',
  startOuterApprovement: 'start-outer-approvement',
};
