export enum MainMenuTypeEnum {
  // Служебная корреспонденция
  officialCorrespondence = 'officialCorrespondence',
  // Незамедлительно
  immediateCorrespondence = 'immediateCorrespondence',
  // Срочные
  urgentCorrespondence = 'urgentCorrespondence',
  // Поручения Мэра
  mayorMission = 'mayorMission',
  // Проекты поручений
  projectsMission = 'projectsMission',
  // Проекты писем
  projectsMail = 'projectsMail',
  // Служебные документы
  officialDocuments = 'officialDocuments',
  // Проекты актов
  actProjects = 'actProjects',
  // Незамедлительные акты
  actImmediate = 'actImmediate',
  // Срочные акты
  actUrgent = 'actUrgent',
  // Расписать
  schedule = 'schedule',
  // Вынести решение
  makeDecision = 'makeDecision',
  // Ускоренное рассмотрение
  acceleratedReview = 'acceleratedReview',
}
