import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectTypeModel } from '@models/dictionaries/select-type.model';

@Component({
  selector: 'app-resolution-tree-filter',
  templateUrl: './resolution-tree-filter.component.html',
  styleUrls: ['./resolution-tree-filter.component.scss'],
})
export class ResolutionTreeFilterComponent {
  @Input() public options: SelectTypeModel[];
  @Input() public form: FormGroup;

  public uniqueKey = Math.random();
}
