/** Варианты согласования */
export enum ApproveTypeEnum {
  /** Согласовать */
  approve = 'approve',
  /** Согласовать с ЛУЗ (с замечаниями) */
  approveLuz = 'approve-luz',
  /** Вернуть на доработку */
  return = 'return',
  /** Перенаправить */
  redirect = 'redirect',
  /** Направить замечания без согласования */
  sendRemarksToAuthor = 'send-remarks-to-author',
  /** Направить руководителю */
  sendToOauLeader = 'send-to-oau-leader',
  /** Добавить исполнителей/соисполнителей */
  addToTreeExecutors = 'add-to-tree-executors',
  /** Добавить согласующих */
  addToTreeCoordinators = 'add-to-tree-coordinators',
  /** Добавить ознакомителя */
  addIntroducer = 'add-introducer',
  /** Ознакомлен */
  approveIntroduction = 'approve-introduction',
  /** Отправить на согласование */
  startApprovement = 'start-approvement',
  /** Отправить на согласование с замечаниями*/
  startApprovementWithRemarks = 'start-approvement-with-remarks',
  /** Отправить на внешнее согласование */
  startOuterApprovement = 'start-outer-approvement',
}
