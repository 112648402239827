import { Pipe, PipeTransform } from '@angular/core';

import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';

@Pipe({
  name: 'findActiveFile',
})
export class FindActiveFilePipe implements PipeTransform {
  public transform(files: FilePoolModel[], fileId: number, fileName: string): FilePoolModel {
    return files?.find((f) => f.id === fileId && f.fileName === fileName);
  }
}
