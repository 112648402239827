import { Injectable } from '@angular/core';

/** Сервис для отображения индикатора загрузки в форме */
@Injectable({
  providedIn: 'root',
})
export class FormModalService {
  public async showLoading(): Promise<void> {
    const form = document.querySelector('form');

    const existingIonLoading = form.querySelector('ion-loading');
    if (existingIonLoading) {
      return;
    }

    const loading = document.createElement('ion-loading');

    loading.message = 'Пожалуйста, подождите...';

    form.appendChild(loading);

    await loading.present();
  }

  public dismissLoading(): void {
    const form = document.querySelector('form');

    const existingIonLoading = form.querySelector('ion-loading');

    if (existingIonLoading) {
      existingIonLoading.dismiss();
    }
  }
}
