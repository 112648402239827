import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge, Observable } from 'rxjs';

import * as dayjs from 'dayjs';

import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';

import { ActionFiltersEnum } from '@enums/action-filters.enum';
import { filter } from 'rxjs/operators';
import { DateHelperConst } from '@const/date-helper.const';
import { FilterCodesEnum } from '../enums/filter-codes.enum';
import { FiltersRequest } from '../models/filter-request.model';
import { dateRangeValidator } from '../validators/date-range.validator';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  /** форма с фильтрами */
  public form: FormGroup;
  /** действие с фильтрами */
  public isActionFilters: ActionFiltersEnum;

  /** необходимо ли открыть форму со старыми значениями */
  private isNeedToOpenFormWithOldValues = false;
  /** изменены ли фильтры */
  private isChangedFilterData = false;
  /** автоматически изменили дату */
  private isAutomaticChangingDate = false;

  constructor(private fb: FormBuilder) {}

  /** создание формы, если не надо открыть форму со старыми значениями */
  public initForm(): void {
    if (this.isNeedToOpenFormWithOldValues) {
      return;
    }

    this.form = this.fb.group(
      {
        /** статус срочности: Незамедлительные, Срочные, Все */
        urgency: [null],
        /** признак ДСП */
        restrictedDocuments: [false],
        /** признак непрочитанности */
        notRead: [false],
        /**  категория */
        category: [null],
        /** тип этапа (внешние, внутренние) */
        phaseType: [null],
        /** период создания задачи текущего пользователя */
        createTasks: FilterCodesEnum.all,
        /** дата с */
        dateAfter: [null],
        /** дата по */
        dateBefore: [null],
        /** признак перенаправленности */
        redirected: [false],
        /** признак просроченности */
        overdue: [false],
      },
      {
        validators: [dateRangeValidator.bind(this)],
      },
    );
  }

  /**
   * очистка формы
   *
   * - флаг = false: при следующем открытии форма будет создаваться новая
   * - создание формы заново
   */
  public clearForm(): void {
    this.isNeedToOpenFormWithOldValues = false;
    this.form.setValue({
      urgency: null,
      restrictedDocuments: false,
      notRead: false,
      category: null,
      phaseType: null,
      createTasks: FilterCodesEnum.all,
      dateAfter: null,
      dateBefore: null,
      redirected: false,
      overdue: false,
    });
  }

  /**
   * переключение отображения фильтров
   *
   * @param showFilter true - открыть фильтры, false - закрыть
   */
  public toggleFilters(showFilter: boolean): void {
    if (showFilter) {
      this.isActionFilters = ActionFiltersEnum.openFilters;
      return;
    }

    this.closeWithSaveFormValues();
  }

  /**
   * Применение фильтров:
   *
   * - изменить действие с фильтрами на Применить, если выбраны фильтры
   * - изменить действие с фильтрами на Отмена, если не выбраны никакие фильтры
   * - флаг = true: при следующем открытии форма будет со старыми значениями
   */
  public apply(): void {
    this.isChangedFilterData = this.checkChangedFilterData();
    this.isChangedFilterData
      ? (this.isActionFilters = ActionFiltersEnum.apply)
      : (this.isActionFilters = ActionFiltersEnum.applyWithDefaultData);

    this.isNeedToOpenFormWithOldValues = true;
  }

  /**
   * отмена действий с фильтрами:
   *
   * - флаг = false: при следующем открытии форма будет создаваться новая
   * - изменить действие с фильтрами на Отмена
   */
  public cancelFilters(): void {
    this.isActionFilters = ActionFiltersEnum.cancel;
    this.isNeedToOpenFormWithOldValues = false;
    this.isChangedFilterData = false;
    this.initForm();
  }

  /** формирование параметров для запроса поиска по фильтрам */
  public getFiltersRequest(
    searchText: string,
    statisticName: StatisticsEnum,
    projectGroupTypeId: number,
  ): FiltersRequest {
    const formValue = this.form?.value;

    const statisticNameOrCategoryFromFilter = formValue?.category?.id || statisticName;
    const createTaskBefore = formValue?.dateBefore
      ? dayjs(formValue.dateBefore).format(DateHelperConst.dateUrgencyFormat)
      : '';
    const createTaskAfter = formValue?.dateAfter
      ? dayjs(formValue.dateAfter).format(DateHelperConst.dateUrgencyFormat)
      : '';

    return {
      urgency: this.getUrgency(statisticName, formValue),
      phaseType: formValue?.phaseType !== FilterCodesEnum.all ? formValue?.phaseType : null,
      notRead: formValue?.notRead,
      redirected: formValue?.redirected,
      restrictedDocuments: formValue?.restrictedDocuments,
      createTaskBefore,
      createTaskAfter,
      text: searchText,
      statisticName: statisticNameOrCategoryFromFilter,
      projectGroupTypeId,
      overdue: formValue?.overdue,
    };
  }

  /** подписка на ручное изменение контролов дат "с" "по" */
  public subscribeOnFormDates(): Observable<string> {
    return merge(this.form?.get('dateBefore')?.valueChanges, this.form?.get('dateAfter')?.valueChanges).pipe(
      filter(() => !this.isAutomaticChangingDate),
    );
  }

  /**
   * переключения дат
   * - today - сегодня: от «Сегодня» до «Сегодня»
   * - week - за 7 дней: от «Сегодня» - 7 дней после «Сегодня»
   * - за все время: период отключен
   *
   * @param code код фильтра
   */
  public changeFilterCreateTask(code: FilterCodesEnum): void {
    this.isAutomaticChangingDate = true;
    const now = dayjs();

    this.form.patchValue({ dateBefore: null, dateAfter: null });

    switch (code) {
      case FilterCodesEnum.clear:
        this.form.patchValue({ createTasks: FilterCodesEnum.all });
        break;
      case FilterCodesEnum.today:
        this.form.patchValue({
          dateBefore: now.toISOString(),
          dateAfter: now.toISOString(),
        });
        break;
      case FilterCodesEnum.week:
        this.form.patchValue({
          dateBefore: now.toISOString(),
          dateAfter: now.subtract(7, 'd').toISOString(),
        });
        break;
    }

    this.isAutomaticChangingDate = false;
  }

  /** закрыть фильтры */
  private closeWithSaveFormValues(): void {
    if (!this.isNeedToOpenFormWithOldValues) {
      this.isActionFilters = ActionFiltersEnum.applyWithDefaultData;
      return;
    }

    this.isChangedFilterData
      ? (this.isActionFilters = ActionFiltersEnum.apply)
      : (this.isActionFilters = ActionFiltersEnum.applyWithDefaultData);
    this.isNeedToOpenFormWithOldValues = true;
  }

  /**
   * при выборе категории в фильтрах должен проставлять срочность
   * если основная категория Срочно (перешли на неё с ГЭ) - срочность должна быть urgently
   * если основная категория Незамедлительно (перешли на неё с ГЭ) - срочность должна быть immediately
   */
  private getUrgency(statisticName: StatisticsEnum, formValue: any): FilterCodesEnum {
    if (statisticName === StatisticsEnum.mobileOfficeUrgently) {
      return FilterCodesEnum.urgently;
    }

    if (statisticName === StatisticsEnum.mobileOfficeImmediately) {
      return FilterCodesEnum.immediately;
    }

    return formValue?.urgency !== FilterCodesEnum.all ? formValue?.urgency : null;
  }

  /** фильтры отличаются от фильтров по умолчанию */
  private checkChangedFilterData(): boolean {
    const formValue = this.form?.value;

    const data = {
      urgency: formValue?.urgency,
      restrictedDocuments: formValue?.restrictedDocuments,
      notRead: formValue?.notRead,
      category: formValue?.category,
      phaseType: formValue?.phaseType,
      createTasks: formValue?.createTasks ? formValue.createTasks !== FilterCodesEnum.all : false,
      dateAfter: formValue?.dateAfter,
      dateBefore: formValue?.dateBefore,
      redirected: formValue?.redirected,
      overdue: formValue?.overdue,
    };

    return Object.values(data).filter((item) => Boolean(item)).length >= 1;
  }
}
