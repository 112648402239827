import { Component, Input } from '@angular/core';
import { GENERAL_TEXT_ERRORS } from '@const/global-text-errors.const';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent {
  @Input() public title = GENERAL_TEXT_ERRORS.systemError;
  @Input() public label = GENERAL_TEXT_ERRORS.unknownError;
  @Input() public buttonLabel = 'ok';
  @Input() public refresh = false;

  constructor(private modalController: ModalController) {}

  public closeModal(): void {
    this.modalController.dismiss({ refresh: false }).then();
  }

  public refreshMenu(): void {
    this.modalController.dismiss({ refresh: true }).then();
  }
}
