import { DossierResponseModel } from '@oogShared/models/dossier/dossier-response.model';

/** Сохранить дело */
export class SetDossier {
  public static readonly type = '[Dossier] Set Dossier';
  constructor(public dossier: DossierResponseModel) {}
}

/** Обнулить дело */
export class ResetDossier {
  public static readonly type = '[Dossier] Reset Dossier';
}
