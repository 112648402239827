import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';

/** Сохранить карточки документов */
export class SetDocumentsCards {
  public static readonly type = '[Documents Cards] Set Documents Cards';
  constructor(public cards: CardDocumentModel[]) {}
}

/** Сохранить карточки документов возвращеные после поиска */
export class SetDocumentsCardsFromSearch {
  public static readonly type = '[Documents Cards] Set Documents Cards From Search';
  constructor(public cards: CardDocumentModel[]) {}
}

/** Установить карточку просмотренной */
export class SetViewedCard {
  public static readonly type = '[Documents Cards] Set Viewed Card';
  constructor(public id: number) {}
}

/** Обнулить массив с карточками документов */
export class ResetDocumentsCards {
  public static readonly type = '[Documents Cards] Reset Documents Cards';
}

/** Удалить карточку */
export class DeleteCard {
  public static readonly type = '[Documents Cards] Delete Card';
  constructor(public id: number) {}
}
