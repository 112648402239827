import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { CollectionResponse } from '@models/collection-response';
import { CollectionData } from '@models/notifications/collection-data.model';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';
import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';
import { InterceptorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';

/** Сервис для получения карточек документов в ускоренном рассмотрении */
@Injectable({
  providedIn: 'root',
})
export class ExpeditedReviewInteropService {
  constructor(private http: HttpClient) {}

  public getMenu(loader: boolean = false): Observable<ExpeditedReviewModel[]> {
    const url = Urls.expeditedReview.get;
    return this.http
      .get<PaginationBaseModel<ExpeditedReviewModel>>(url, {
        params: new InterceptorHttpParams(new InterceptorConfig(loader)),
      })
      .pipe(map((val) => val.data.content));
  }

  public switchProject(value: boolean, id: number): Observable<CollectionResponse<CollectionData>> {
    const url = Urls.expeditedReview.switch(value, id);
    return this.http.post<CollectionResponse<CollectionData>>(url, null, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }

  public acceptProjects(projects: ExpeditedReviewModel[]): Observable<CollectionResponse<CollectionData>> {
    const url = Urls.expeditedReview.acceptAll;
    return this.http.post<CollectionResponse<CollectionData>>(url, projects, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }

  public getRightExpeditedReview(): Observable<CollectionResponse<boolean>> {
    const url = Urls.expeditedReview.rightExpeditedReview;
    return this.http.get<CollectionResponse<boolean>>(url, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }
}
