import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';

/** Заполнение стора документов */
export class SetResolutionFiles {
  public static readonly type = '[Resolution Files] Set Resolution Files';
  constructor(public files: FilePoolModel[], public images: FilePoolModel[] = []) {}
}

/** Очистка стора документов */
export class ResetResolutionFiles {
  public static readonly type = '[Resolution Files] Reset Resolution Files';
}
