import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appScrollCatcher]',
})
export class ScrollCatcherDirective implements AfterViewInit {
  @Output() public appScrollCatcher = new EventEmitter();

  private scrollElement: HTMLElement;

  constructor(private el: ElementRef) {}

  public ngAfterViewInit(): void {
    this.scrollElement = this.el.nativeElement;
    this.scrollElement.addEventListener('touchstart', (event: TouchEvent) => {
      this.appScrollCatcher.emit();
      if (this.scrollElement.scrollTop > 10) {
        event.stopImmediatePropagation();
        event.cancelBubble = true;
      }
    });
  }
}
