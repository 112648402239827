import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetArrowMovement } from '@store/arrow-movement/arrow-movement.action';
import { ActionsFooterWithArrow } from '@enums/actions-footer-with-arrows.enum';

@Component({
  selector: 'app-side-bar-empty-message',
  templateUrl: './side-bar-empty-message.component.html',
  styleUrls: ['./side-bar-empty-message.component.scss'],
})
export class SideBarEmptyMessageComponent implements OnInit, OnDestroy {
  private timer: any;
  constructor(protected store: Store) {}

  public ngOnInit(): void {
    this.timer = setTimeout(() => {
      this.store.dispatch([new SetArrowMovement({ action: ActionsFooterWithArrow.right })]);
    }, 5000);
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timer);
  }
}
