import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { RoutePoint } from '@npaShared/models/route/route.models';
import { RoutePhaseTypes } from '../../../document-agreement/enums/route-phase-types.enum';
import { RootActsLogic } from './root-acts-logic';

/** Логика работы с несколькими активными точками для категории "Расписать" */
export class ApprovementWriteActsLogic extends RootActsLogic {
  private availableTaskStatuses = [...EqualActiveResolutions];
  private availableRoles = [EmployeeRouteTypes.leader];

  public getActivePointsList(): RoutePoint[] {
    return this.getUserPoints()
      .filter((point) => this.availableTaskStatuses.includes(point.taskResolution))
      .filter((point) => this.availableRoles.includes(point.pointRoleId))
      .filter((point) => point.phaseTypeId !== RoutePhaseTypes.projectCreatedInitiation);
  }
}
