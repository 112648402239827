import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { SettingsState } from '@store/settings/settings.state';

import { DocumentTypeEnum } from '@npaShared/enums/document-type.enum';
import { TOGGLE_LIST_OFFSET_HEIGHT } from '@oogShared/consts/toggle-list-min-height.const';
import { SuggestEmployeeParams } from '@const/urls';
import { EmployeeModel } from '../../models/resolutions/employee/employee.model';
import { DictionariesInteropService } from '../../rest/dictionaries-interop.service';

@Component({
  selector: 'app-add-performer',
  templateUrl: './add-performer.component.html',
  styleUrls: ['./add-performer.component.scss'],
})
export class AddPerformerComponent implements OnInit, AfterViewInit {
  @Input() public hotList: EmployeeModel[] = [];
  @Input() public emptyCommissions: number[] = [];
  @Input() public index = 0;
  @Input() public titleLabel = 'Добавить исполнителя:';
  @Input() public sameDepartment = false;
  @Input() public isNotSameDepartment = false;
  @Input() public typeDocument: DocumentTypeEnum = DocumentTypeEnum.resolution;
  @Input() public noPerformers = false;

  @Output() public chooseUser: EventEmitter<EmployeeModel> = new EventEmitter<EmployeeModel>();

  public form: FormGroup = new FormGroup({});
  public showChoosePerformer = false;
  public userList: EmployeeModel[] = [];
  public showAllList = false;
  public toggleListOffsetHeight = TOGGLE_LIST_OFFSET_HEIGHT;

  constructor(
    private dictionariesInterop: DictionariesInteropService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private currentUser: CurrentUserIsproStoreService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.initForm();

    this.processShowPerformerListSetting();
  }

  public ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  public setUserList(value: string): void {
    if (value.length < 3) {
      return;
    }

    this.getUserList(value)
      .pipe(first())
      .subscribe((u) => (this.userList = u));
  }

  public setUserControl(user: EmployeeModel): void {
    this.showChoosePerformer = false;
    this.chooseUser.emit(user);
  }

  public toggleChoosePerformer(event: MouseEvent): void {
    event.stopPropagation();
    this.showChoosePerformer = !this.showChoosePerformer;
  }

  public closeChoosePerformer(): void {
    this.showChoosePerformer = false;
  }

  /** Обрабатывает значение настройки на полное отображение списка представителей */
  private processShowPerformerListSetting(): void {
    this.showAllList = this.store.selectSnapshot(SettingsState.showPerformerListFullyOpened);
  }

  private initForm(): void {
    this.form = this.fb.group({
      performer: [''],
    });
  }

  private getUserList(value: string): Observable<EmployeeModel[]> {
    const userId = this.currentUser.getCurrentUserSnapshot()?.id;
    const departmentId = this.currentUser.getCurrentUserSnapshot()?.department?.id;
    const isAmpmUser = this.currentUser.getCurrentUserSnapshot()?.isAmpm;

    const params: SuggestEmployeeParams = {
      search: value,
      whitelistEmployeeId: userId,
      whitelistDepartmentId: departmentId,
    };

    if (this.typeDocument === DocumentTypeEnum.approvalList) {
      if (!isAmpmUser) {
        params.searchType = 'not-in-ampm';
      }
    } else {
      if (this.isNotSameDepartment) {
        params.searchType = 'not-in-dep';
        params.departmentId = departmentId;
      }
    }

    return this.dictionariesInterop.getUserList(params);
  }
}
