import { Injectable } from '@angular/core';
import { CertificateErrorValidatorType } from '@enums/settings/certificate-error-validator-type.enum';
import { CertificateItemModel } from '@models/esigns/certificate-item.model';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { textErrorValidationCertificate, certificateValidation } from '@shared/functions/certificate-validation';
import { AboutEmployeeNpaPipe } from '@shared/pipes/about-employee-npa/about-employee.pipe';
import { ModalService } from '@shared/services/modal.service';

@Injectable({
  providedIn: 'root',
})
export class SigningValidationHelperService {
  constructor(
    private currentUserStore: CurrentUserAissdStoreService,
    private aboutEmployeeNpaPipe: AboutEmployeeNpaPipe,
    private modalService: ModalService,
  ) {}

  /** @returns true если сертификат валидный */
  public isValidCertificate(certificate: CertificateItemModel): boolean {
    const isValid = this.getTypeValidationCertificate(certificate);
    return isValid === CertificateErrorValidatorType.success;
  }

  /** показать модалку с ошибкой валидации */
  public showModalInvalidCertificate(certificate: CertificateItemModel): void {
    const isValidCert = this.getTypeValidationCertificate(certificate);
    const textTitle = textErrorValidationCertificate(isValidCert);
    this.modalService.presentModalWithAlarmIcon('Внимание!', textTitle, 'ОК');
  }

  /** получить тип ошибки валидации сертификата */
  private getTypeValidationCertificate(certificate: CertificateItemModel): CertificateErrorValidatorType {
    const currentUser = this.currentUserStore.getCurrentUserSnapshot();

    const userSharedModel = currentUser?.realUser
      ? this.aboutEmployeeNpaPipe.transform(currentUser.realUser.employee)
      : null;
    return certificateValidation(certificate, userSharedModel);
  }
}
