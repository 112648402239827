import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-invalid-redirect-modal',
  templateUrl: './invalid-redirect-modal.component.html',
  styleUrls: ['./invalid-redirect-modal.component.scss'],
})
export class InvalidRedirectModalComponent {
  @Input() public rightsValid = false;
  @Input() public singleProjectValid = false;
  @Input() public authorAndAddresseeValid = false;
  @Input() public isRequest = false;

  constructor(private modalController: ModalController) {}

  public async closeModal(): Promise<void> {
    await this.modalController.dismiss();
  }
}
