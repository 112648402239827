import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { ResolutionAuthorModel } from '@oogShared/models/documents/resolution-author.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ResetActiveDocumentCard, SetActiveDocumentCard } from '@store/menu/active-card/active-card.action';

@Injectable()
@State<CardDocumentModel | null>({
  name: 'activeDocumentCard',
  defaults: null,
})
export class ActiveCardState {
  @Selector()
  public static getActiveCard(state: CardDocumentModel): CardDocumentModel {
    return state;
  }

  @Selector()
  public static resolutionTypeId(state: CardDocumentModel): number {
    return state.resolutionType.id;
  }

  @Selector()
  public static numberResolution(state: CardDocumentModel): string {
    return state?.number;
  }

  @Selector()
  public static dateResolution(state: CardDocumentModel): Date {
    return state?.registrationDate;
  }

  @Selector()
  public static author(state: CardDocumentModel): ResolutionAuthorModel {
    return state?.resolutionAuthor;
  }

  @Selector()
  public static cardType(state: CardDocumentModel): string {
    return state?.cardType;
  }

  @Selector()
  public static canApproveResolutionProject(state: CardDocumentModel): boolean {
    return state?.canApproveResolutionProject;
  }

  @Selector()
  public static previousParticipantsEmployee(state: CardDocumentModel): EmployeeModel[] {
    return state?.previousParticipants.map((p) => {
      const employee = p?.employee;
      const type = p?.type;
      return { ...employee, type };
    });
  }

  @Selector()
  public static canSign(state: CardDocumentModel): boolean {
    return state?.canSign;
  }

  @Selector()
  public static isMainCardInGroup(state: CardDocumentModel): boolean {
    const group = state.groupProjects?.groupProjects;
    if (!state.groupProjects || !group.isActive) {
      return true;
    }
    return state.appealMovementId === group?.mainAppealMovementId && group?.isActive;
  }

  @Selector()
  public static isChildCardInGroup(state: CardDocumentModel): boolean {
    const group = state.groupProjects?.groupProjects;
    if (!state.groupProjects || !group?.isActive) {
      return false;
    }
    return state.appealMovementId !== group?.mainAppealMovementId && group?.isActive;
  }

  @Selector()
  public static isGroupActive(state: CardDocumentModel): boolean {
    if (!state.groupProjects) {
      return false;
    }
    return state.groupProjects.groupProjects.isActive;
  }

  @Selector()
  public static appealMovementId(state: CardDocumentModel): number {
    return state.mainId;
  }

  @Selector()
  public static getUniqueId(state: CardDocumentModel): number {
    return state.uniqueId;
  }

  @Selector()
  public static isCitizenAppeal(state: CardDocumentModel): boolean {
    return state.isCitizenAppeal;
  }

  @Action(SetActiveDocumentCard)
  public setActiveCard({ setState }: StateContext<CardDocumentModel>, { card }: SetActiveDocumentCard): void {
    setState(card);
  }

  @Action(ResetActiveDocumentCard)
  public resetActiveDocumentCard({ setState }: StateContext<CardDocumentModel>): void {
    setState(null);
  }
}
