import { AfterContentChecked, AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Select } from '@ngxs/store';
import { BaseFiles } from '@oogShared/base-classes/base-files';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResolutionFilesState } from '@store/resolution-store/resolution-files/resolution-files.state';
import { SettingsState } from '@store/settings/settings.state';
import { Observable } from 'rxjs';
import { MenuExpeditedReviewState } from '@store/expedited-review/menu/menu-expedited-review.state';
import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';
import { BookmarkSelectionComponent } from '../../../../bookmarks/popaps/bookmark-selection/bookmark-selection.component';

@Component({
  selector: 'app-resolution-files',
  templateUrl: './resolution-files.component.html',
  styleUrls: ['./resolution-files.component.scss'],
})
/** Компонент для отображения списка файлов резолюции */
export class ResolutionFilesComponent
  extends BaseFiles
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  @Select(ActiveCardState.getActiveCard)
  public activeCard$: Observable<CardDocumentModel>;

  @Select(MenuExpeditedReviewState.getActiveCard)
  public activeExpeditedReviewCard$: Observable<ExpeditedReviewModel>;

  @Input() public disableComment = false;
  @Input() public hideExpandButton = false;
  @Input() public showBookmark = false;

  public threeLinesHeight = 47;

  public fileCommentCollapsed = true;

  public commentIsFullyCollapsed = true;

  public ngOnInit(): void {
    this.handleSidebarStateChange();
    this.processCommentOpenedSetting();

    // устанавливаем значение по умолчанию флага для отображения увеличенного масштаба в стор
    this.setFullWidthFlagToStore();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showFileAndHideList(filePool: FilePoolModel): void {
    this.collapsedFileList = !this.collapsedFileList;
    this.showFile(filePool);
  }

  public async chooseBookmark(): Promise<void> {
    const modal = await this.modalService.showAnyModal(BookmarkSelectionComponent, 'full-screen-with-background', true);
    const res = await modal.onDidDismiss();
    if (res.data?.confirm) {
      const numberPage = this.store.selectSnapshot(ResolutionFilesState.getPageNumber(res.data.pageId));
      this.fileViewer.setCurrentPage(numberPage);
    }
  }

  /** Обрабатывает настройку открытых комментариев, и открывает их в случае необходимости */
  private processCommentOpenedSetting(): void {
    // Получаем настройку из хранилища
    const commentBlockIsFullyOpenedSetting = this.store.selectSnapshot(
      SettingsState.commentsInDossierAndDocumentBlocksFullyOpened,
    );

    // Если настройка установлена, то открываем оба комментария, если нет - то оба скрываем
    this.fileCommentCollapsed = !commentBlockIsFullyOpenedSetting;
    this.commentIsFullyCollapsed = !commentBlockIsFullyOpenedSetting;
  }
}
