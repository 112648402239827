import { Injectable } from '@angular/core';
import { ActionFiltersEnum } from '@enums/action-filters.enum';

import { SidebarModel } from '@models/sidebar/sidebar.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  ResetFolderState,
  ResetMainIdSearch,
  ResetSearch,
  ResetSidebarState,
  SetFolderId,
  SetFilterAction,
  SetFolderName,
  SetMainIdSearch,
  SetSearch,
  SetShownFolders,
  SetShownGroup,
  SetTransitionFromDocumentsFolder,
  SetTransitionFromFolders,
  SetTransitionFromToast,
  ToggleSidebar,
  SetVisibleEmptyMessage,
} from './sidebar.action';

const initialState: SidebarModel = {
  isHidden: false,
  searchValue: '',
  mainIdSearch: null,
  shownFolders: false,
  shownGroup: false,
  transitionFromFolders: false,
  transitionFromDocumentsFolder: false,
  transitionFromToast: false,
  folderName: '',
  folderId: null,
  actionWithFilters: ActionFiltersEnum.cancel,
  isVisibleEmptyMessage: false,
};

@Injectable()
@State<SidebarModel>({
  name: 'sidebar',
  defaults: initialState,
})
export class SidebarState {
  @Selector()
  public static searchValue(state: SidebarModel): string {
    return state.searchValue;
  }

  @Selector()
  public static mainIdSearch(state: SidebarModel): number {
    return state.mainIdSearch;
  }

  @Selector()
  public static isHidden(state: SidebarModel): boolean {
    return state.isHidden;
  }

  @Selector()
  public static shownFolders(state: SidebarModel): boolean {
    return state.shownFolders;
  }

  @Selector()
  public static shownGroup(state: SidebarModel): boolean {
    return state.shownGroup;
  }

  @Selector()
  public static transitionFromFolders(state: SidebarModel): boolean {
    return state.transitionFromFolders;
  }

  @Selector()
  public static transitionFromDocumentsFolder(state: SidebarModel): boolean {
    return state.transitionFromDocumentsFolder;
  }

  @Selector()
  public static transitionFromToast(state: SidebarModel): boolean {
    return state.transitionFromToast;
  }

  @Selector()
  public static folderName(state: SidebarModel): string {
    return state.folderName;
  }

  @Selector()
  public static folderNameIfComeFromFolders(state: SidebarModel): string {
    return state.transitionFromFolders ? state.folderName : '';
  }

  @Selector()
  public static actionWithFolders(state: SidebarModel): boolean {
    return state.transitionFromFolders || !!state.folderName || state.shownFolders;
  }

  @Selector()
  public static actionWithFilters(state: SidebarModel): ActionFiltersEnum {
    return state.actionWithFilters;
  }

  @Selector()
  public static folderId(state: SidebarModel): number | null {
    return state.folderId;
  }

  @Selector()
  public static isVisibleEmptyMessage(state: SidebarModel): boolean {
    return state.isVisibleEmptyMessage;
  }

  @Action(SetShownFolders)
  public setShownFolders({ patchState }: StateContext<SidebarModel>, { newState }: SetShownFolders): void {
    patchState({ shownFolders: newState });
  }

  @Action(ToggleSidebar)
  public toggleSidebar({ getState, patchState }: StateContext<SidebarModel>): void {
    const state = getState();
    patchState({ isHidden: !state.isHidden });
  }

  @Action(ResetSearch)
  public resetSearch({ patchState }: StateContext<SidebarModel>): void {
    patchState({ searchValue: '' });
  }

  @Action(SetSearch)
  public setSearchInput({ patchState }: StateContext<SidebarModel>, { value }: SetSearch): void {
    patchState({ searchValue: value });
  }

  @Action(SetMainIdSearch)
  public setMainId({ patchState }: StateContext<SidebarModel>, { value }: SetMainIdSearch): void {
    patchState({ mainIdSearch: value });
  }

  @Action(ResetMainIdSearch)
  public resetMainId({ patchState }: StateContext<SidebarModel>): void {
    patchState({ mainIdSearch: null });
  }

  @Action(SetTransitionFromFolders)
  public setTransitionFromFolders(
    { patchState }: StateContext<SidebarModel>,
    { value }: SetTransitionFromFolders,
  ): void {
    patchState({ transitionFromFolders: value });
  }

  @Action(SetTransitionFromDocumentsFolder)
  public setTransitionFromDocumentsFolders(
    { patchState }: StateContext<SidebarModel>,
    { value }: SetTransitionFromDocumentsFolder,
  ): void {
    patchState({ transitionFromDocumentsFolder: value });
  }

  @Action(SetTransitionFromToast)
  public setTransitionFromToast({ patchState }: StateContext<SidebarModel>, { value }: SetTransitionFromToast): void {
    patchState({ transitionFromToast: value });
  }

  @Action(SetFolderName)
  public setFolderName({ patchState }: StateContext<SidebarModel>, { value }: SetFolderName): void {
    patchState({ folderName: value });
  }

  @Action(SetFolderId)
  public setFolderId({ patchState }: StateContext<SidebarModel>, { id }: SetFolderId): void {
    patchState({ folderId: id });
  }

  @Action(SetFilterAction)
  public setFilterAction({ patchState }: StateContext<SidebarModel>, { value }: SetFilterAction): void {
    patchState({ actionWithFilters: value });
  }

  @Action(ResetFolderState)
  public resetFolderState({ patchState }: StateContext<SidebarModel>): void {
    patchState({ shownFolders: false, transitionFromFolders: false, folderName: '' });
  }

  @Action(SetShownGroup)
  public setShownGroup({ patchState }: StateContext<SidebarModel>, { newState }: SetShownFolders): void {
    patchState({ shownGroup: newState });
  }

  @Action(ResetSidebarState)
  public resetSidebarState({ setState }: StateContext<SidebarModel>): void {
    setState(initialState);
  }

  @Action(SetVisibleEmptyMessage)
  public setVisibleEmptyMessage(
    { patchState }: StateContext<SidebarModel>,
    { isVisible }: SetVisibleEmptyMessage,
  ): void {
    patchState({ isVisibleEmptyMessage: isVisible });
  }
}
