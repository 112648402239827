import { Component, Input } from '@angular/core';

import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';

import { ResolutionAddresseeTypeEnum } from '../../../enums/resolution-addressee/resolution-addressee-type.enum';

@Component({
  selector: 'app-projects-expedited-review',
  templateUrl: './projects-expedited-review.component.html',
  styleUrls: ['./projects-expedited-review.component.scss'],
})
export class ProjectsExpeditedReviewComponent {
  @Input() public projects: MissionModel[] = [];

  public addresseeType = ResolutionAddresseeTypeEnum;
}
