import { Component } from '@angular/core';

import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-confirm-familiarization',
  templateUrl: './confirm-familiarization.component.html',
  styleUrls: ['./confirm-familiarization.component.scss'],
})
export class ConfirmFamiliarizationComponent extends DialogBase {}
