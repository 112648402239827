import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { DeviceInfoModel } from '@models/login/device-info.model';
import { EmployeeBaseModel } from '@npaShared/models/employee/employee-base.model';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';
import { CredentialStorageService } from '@shared/services/credential-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private http: HttpClient, private credentialsStorage: CredentialStorageService) {}

  public getUsersAisSd(value: string): Observable<EmployeeBaseModel[]> {
    const url = Urls.auth.usersUrlAisSd(value);
    return this.http.get<EmployeeBaseModel[]>(url).pipe(catchError(() => of(null)));
  }

  public getUsersIsPro(value: string): Observable<EmployeeBaseModel[]> {
    const url = Urls.auth.usersUrlIsPro(value);
    return this.http.get<PaginationBaseModel<EmployeeBaseModel>>(url).pipe(
      map((val) => val.data.content),
      catchError(() => of(null)),
    );
  }

  public loginAisSd(data: string): Observable<string> {
    const url = Urls.auth.loginUrlAisSd;
    return this.http.post(url, data, { responseType: 'text' }).pipe(
      tap(() => this.setNpaCredentials(data)),
      catchError((error) => {
        this.credentialsStorage.removeNpaCredentials();
        return throwError(error);
      }),
    );
  }

  public setNpaCredentials(data: string): void {
    this.credentialsStorage.setNpaCredentials(data);
  }

  public saveDeviceInfoIsPro(deviceInfo: DeviceInfoModel): Observable<BaseApiResponseModel<null>> {
    const url = Urls.auth.saveDeviceInfoIsPro;
    return this.http.post<BaseApiResponseModel<null>>(url, deviceInfo);
  }

  public saveDeviceInfoAisSd(deviceInfo: DeviceInfoModel): Observable<BaseApiResponseModel<null>> {
    const url = Urls.auth.saveDeviceInfoAisSd;
    return this.http.post<BaseApiResponseModel<null>>(url, deviceInfo);
  }

  public loginIsPro(data: string): Observable<string> {
    const url = Urls.auth.loginUrlIsPro;
    return this.http.post(url, data, { responseType: 'text' }).pipe(
      tap(() => this.credentialsStorage.setOogCredentials(data)),
      catchError((error) => {
        this.credentialsStorage.removeOogCredentials();
        return throwError(error);
      }),
    );
  }
}
