import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { logToSentry } from '@shared/functions/set-users-for-sentry.function';

/* Сервис для работы с счетчиком документов иконки приложения **/
@Injectable({
  providedIn: 'root',
})
export class BadgeService implements OnDestroy {
  public oogBadgeCounter$ = new BehaviorSubject<number>(0);
  public npaBadgeCounter$ = new BehaviorSubject<number>(0);

  private unsubscribe$ = new Subscription();

  constructor(private badge: Badge) {
    this.subscribeToCountersChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  public clear(): void {
    this.badge.clear();
  }

  public setBadge(value: number): void {
    logToSentry('[Сервис установки бейджей BadgeService] Устанавливаем новый бейдж ', {
      'setBadge(value) value: ': value,
      'счетчик оог': this.oogBadgeCounter$.value,
      'счетчик нпа': this.npaBadgeCounter$.value,
    });
    this.badge.set(value);
  }

  private subscribeToCountersChanges(): void {
    this.unsubscribe$.add(
      this.oogBadgeCounter$.subscribe((value) => {
        this.setBadge(value + this.npaBadgeCounter$.value);
      }),
    );

    this.unsubscribe$.add(
      this.npaBadgeCounter$.subscribe((value) => {
        this.setBadge(value + this.oogBadgeCounter$.value);
      }),
    );
  }
}
