import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { mediaCommissionText } from '@const/commissions-text.const';
import { ResolutionKindEnum } from '@oogShared/enums/resolution-kind/resolution-kind.enum';
import { Directory, Filesystem } from '@capacitor/filesystem';

/** Сервис для работы с аудио-резолюцией  */
@Injectable({ providedIn: 'root' })
export class AudioFormService {
  public fileName = '';
  public blobAudio$ = new BehaviorSubject<Blob>(null);

  constructor(private resolutionService: ResolutionInteropService) {}

  // Загрузить файл
  public sendFile(): Observable<FilePoolModel> {
    const fileName = this.fileName.replace('.wav', '');
    return this.resolutionService.uploadFile(this.blobAudio$.value, fileName, 'wav');
  }

  // Конвертируем Audio в Blob
  public async convertAudioToBlob(fileName: string): Promise<void> {
    const audioFile = await Filesystem.readFile({
      path: fileName,
      directory: Directory.Data,
    });

    const base64Sound = audioFile.data;
    const audioData = await fetch(`data:audio/wav;base64, ${base64Sound}`);
    const blob = await audioData.blob();
    this.setAudioInStore(blob);
    this.fileName = fileName;
  }

  // Получаем аудио из стора
  public getAudioFromStore(): Blob | null {
    return this.blobAudio$.value;
  }

  // Удаляем аудио из стора
  public deleteAudio(): void {
    this.blobAudio$.next(null);
  }

  // Записать айдишник и признак загруженного аудио в форму
  public saveFileData(data: FilePoolModel, form: FormGroup): void {
    form.controls.fileId?.setValue(data.id);
    form.controls.resolutionKind?.setValue(ResolutionKindEnum.Audio);
  }

  public restoreAudio(audio: Blob, form: FormGroup): void {
    /* в audio форм контрол можно записать любое значение - главное чтоб оно было
     * При его наличии дизейблится поле ввода комментария **/
    form.addControl('audio', new FormControl('record'));
    form.get('text')?.setValue(mediaCommissionText);
    form.get('resolutionKind')?.setValue(ResolutionKindEnum.Audio);
    this.setAudioInStore(audio);
  }

  // Добавляем аудио-контрол в форму
  public addAudioControlToForm(form: FormGroup): void {
    form.addControl('audio', new FormControl('Audio'));
    form.controls.audio.setErrors({ recordingAudio: true });
  }

  // Установить текст поручения когда есть аудио-файл
  public changeCommissionText(form: FormGroup): void {
    const textControl = form.controls.text;
    textControl.setValue(mediaCommissionText);
  }

  // Восстановить предыдущий текст поручения
  public setCommissionText(form: FormGroup): void {
    const textControl = form.controls.text;
    const saveTextControl = form.controls.savedText;
    textControl.setValue(saveTextControl.value);
  }

  // Сохраняем аудио в стор
  private setAudioInStore(audio: Blob): void {
    this.blobAudio$.next(audio);
  }
}
