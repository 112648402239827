import { Pipe, PipeTransform } from '@angular/core';
import { MenuListNpaItemModel } from '@models/main-screen/menu-list-npa-item.model';
import { OibRolesEnum } from '@npaShared/enums/oib-roles.enum';
import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';

@Pipe({
  name: 'showLocalActs',
})
export class ShowLocalActsPipe implements PipeTransform {
  public transform(userInfo: UserInfoResponseModel, menuItem: MenuListNpaItemModel): boolean {
    if (menuItem.type !== StatisticsEnum.mobileOfficeStatisticDraftActs) {
      return !!userInfo;
    }

    return userInfo?.currentUser
      ? userInfo.currentUser.roles.includes(OibRolesEnum.NPA_GOVERNMENT_USER) &&
          userInfo.currentUser.roles.includes(OibRolesEnum.NPA_INTERNAL_USER)
      : !!userInfo;
  }
}
