import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';

export class SetActiveCategory {
  public static readonly type = '[Active Category] Set';
  constructor(public category: StatisticsEnum) {}
}

export class ClearActiveCategory {
  public static readonly type = '[Active Category] Clear';
}
