import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { getPhaseByPointId, isAllParallelPhasesCompleted } from '@npaShared/helpers/route/phases-helper';

import { ApprovementDefaultLogic } from '../approvement-default-logic';
import { PointLogicModel } from '../point-logic.interface';

/** Логика точки согласования "ОАУ ПМ. Согласование" */
export class OauPmApprovmentPoint extends ApprovementDefaultLogic implements PointLogicModel {
  public canStartApprovement(): boolean {
    return false;
  }

  public canSendToLeader(): boolean {
    const allowedRoles = [EmployeeRouteTypes.coordinator];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (!this.isMainLevelPoint()) {
      return false;
    }

    return true;
  }

  public canSendRemarksToAuthor(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.isPointInParallelPhase()) {
      return this.canSendRemarksToAuthorForParallelPhase();
    }

    return true;
  }

  public canApprove(): boolean {
    const allowedRoles = [EmployeeRouteTypes.coordinator, EmployeeRouteTypes.leader];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.isPointInParallelPhase()) {
      return this.canApproveInParallelPhase();
    }

    return true;
  }

  public canApproveWithRemarks(): boolean {
    return this.canApprove();
  }

  private canApproveInParallelPhase(): boolean {
    const point = this.getPoint();
    const phase = getPhaseByPointId(this.getRoute(), point.id);
    const isAllPhasesCompleted = isAllParallelPhasesCompleted(this.getRoute(), phase);

    /** Руководитель может согласовать, только если параллельные этапы согласованы */
    if (this.isContainsPointRole([EmployeeRouteTypes.leader]) && !isAllPhasesCompleted) {
      return false;
    }

    return true;
  }

  private canSendRemarksToAuthorForParallelPhase(): boolean {
    const point = this.getPoint();
    const phase = getPhaseByPointId(this.getRoute(), point.id);
    const isAllPhasesCompleted = isAllParallelPhasesCompleted(this.getRoute(), phase);

    if (!isAllPhasesCompleted) {
      return false;
    }

    return true;
  }
}
