export class OpenDocumentRoute {
  public static readonly type = '[Document route visibility] Open';
}

export class CloseDocumentRoute {
  public static readonly type = '[Document route visibility] Close';
}

export class ToggleDocumentRoute {
  public static readonly type = '[Document route visibility] Toggle';
  constructor(public value: boolean) {}
}
