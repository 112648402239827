import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

@Pipe({
  name: 'filterArrayString',
})
/** Фильтрует массив employees по вхождению подстроки */
export class FilterArrayStringPipe implements PipeTransform {
  public transform(value: EmployeeModel[], filterValue: string): EmployeeModel[] {
    if (!filterValue) {
      return value;
    }

    return value.filter((i) => i.lastName.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1);
  }
}
