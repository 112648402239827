import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { BookmarksModel } from '@oogShared/models/bookmarks/bookmarks.model';
import { InterceptorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/** Сервис для работы с api закладок */
export class BookmarkInteropService {
  constructor(private httpClient: HttpClient) {}

  /** Удалить закладку */
  public delete(bookmarkId: number): Observable<void> {
    const url = Urls.bookmarks.delete(bookmarkId);
    return this.httpClient.delete<void>(url, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }

  /** Редактировать закладку */
  public edit(params: BookmarksModel): Observable<BookmarksModel> {
    const url = Urls.bookmarks.create;
    return this.httpClient.put<BookmarksModel>(url, params, {
      params: new InterceptorHttpParams(new InterceptorConfig(true)),
    });
  }

  /** Создать закладку */
  public create(params: BookmarksModel): Observable<BookmarksModel> {
    const url = Urls.bookmarks.create;
    return this.httpClient
      .post<BaseApiResponseModel<BookmarksModel>>(url, params, {
        params: new InterceptorHttpParams(new InterceptorConfig(true)),
      })
      .pipe(map((value) => value.data));
  }
}
