import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export abstract class BaseUnsubscribe implements OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
