import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { IonContent } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { DossierLinksModel } from '@oogShared/models/dossier/dossier-links.model';
import { DossierResponseModel } from '@oogShared/models/dossier/dossier-response.model';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { SettingsState } from '@store/settings/settings.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { Observable } from 'rxjs';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';

@Component({
  selector: 'app-resolution-dossier',
  templateUrl: './resolution-dossier.component.html',
  styleUrls: ['./resolution-dossier.component.scss'],
})
export class ResolutionDossierComponent implements OnInit {
  @Input() public document: DossierResponseModel;
  @Input() public parentContent: IonContent;
  @Input() public treeMyDepartment: ResolutionModel[] = [];
  @Input() public treeOtherDepartment: ResolutionModel[] = [];

  @Select(ActiveCardState.getActiveCard)
  public activeCard$: Observable<CardDocumentModel>;

  @Output() public chooseDocument = new EventEmitter<{ id: number; fullHistory: boolean }>();

  @ViewChild(IonContent) public content: IonContent;

  @Input() public set refresh(value: boolean) {
    if (value) {
      this.files = [];
      this.activatedFirstValidDocument();
    }
  }

  /** Состояние списка файлов скрыто/раскрыто */
  public openedFiles = true;
  /** Состояние кнопки развернуть/свернуть весь список */
  public openedAllList = false;
  public activeDocument: number;
  public appealId: number;
  public openComment = true;
  public openAllComment = false;
  public files: FilePoolModel[] = [];
  public heightThreeLines = 59;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.processCommentOpenedSetting();
  }

  public setActiveDocument(doc: DossierLinksModel, activeDoc: boolean): void {
    if (!activeDoc && doc.canUserViewAppeal) {
      this.activeDocument = doc.appealMovementId;
      this.appealId = doc.appealId;
      this.files = doc.files.filter((file) => file.pages.length);
      this.chooseDocument.emit({ id: doc.appealMovementId, fullHistory: doc.isFullHistory });
    }
  }

  public openDossier(): void {
    if (this.document?.dossierLinks?.length > 1) {
      this.openedFiles = !this.openedFiles;
    }
  }

  public filterResolution(value: any): void {
    console.log(value);
  }

  /** Обрабатывает настройку открытых комментариев, и открывает их в случае необходимости */
  private processCommentOpenedSetting(): void {
    // Получаем настройку из хранилища
    const commentBlockFullyOpenedSetting = this.store.selectSnapshot(
      SettingsState.commentsInDossierAndDocumentBlocksFullyOpened,
    );

    // Если настройка установлена, то открываем оба комментария, если нет - то оба скрываем
    this.openComment = commentBlockFullyOpenedSetting;
    this.openAllComment = commentBlockFullyOpenedSetting;
  }

  private activatedFirstValidDocument(): void {
    for (const doc of this.document.dossierLinks) {
      if (doc.canUserViewAppeal) {
        this.activeDocument = doc.appealMovementId;
        this.appealId = doc.appealId;
        this.files = doc.files.filter((file) => file.pages.length);
        this.chooseDocument.emit({ id: doc.appealMovementId, fullHistory: doc.isFullHistory });
        break;
      }
    }
  }
}
