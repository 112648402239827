import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { FilterService } from '@oogShared/services/filter.service';

import VanillaSwipe, { EventData } from 'vanilla-swipe';

import { PickerController, ToastController } from '@ionic/angular';
import { NotificationBannerModel } from '@models/notifications/notifications-banner.model';
import { Store } from '@ngxs/store';
import { SettingsState } from '@store/settings/settings.state';

import { NotificationService } from '@oogShared/services/notification.service';
import { SetTransitionFromToast } from '@store/menu/sidebar/sidebar.action';

@Injectable({
  providedIn: 'root',
})
export class NotificationToastService {
  constructor(
    private toastController: ToastController,
    private store: Store,
    private notificationsService: NotificationService,
    private filterService: FilterService,
    private pickerCtrl: PickerController,
  ) {}

  public async show(data: NotificationBannerModel): Promise<void> {
    const toastDuration = this.store.selectSnapshot(SettingsState.settingsNotification).notificationHide.time;
    if (!toastDuration) {
      return;
    }

    const creationDateFromNanoToMilli = data.creationDate * 1000;

    const time = formatDate(creationDateFromNanoToMilli, 'H:mm', 'en_EN').toString();
    const toast = await this.toastController.create({
      position: 'top',
      message: data.body,
      duration: toastDuration,
      cssClass: data.isUrgent ? 'urgently' : '',
      header: data.title,
    });

    this.setOnClickHandler(toast, data);
    toast.style.setProperty('--time', `"${time}"`);
    this.setSwipeableUp(toast);
    toast.present().then();
  }

  private setSwipeableUp(el: HTMLIonToastElement): void {
    const swipeable = new VanillaSwipe({
      element: el,
      onSwiped: this.onSwipeUp,
      onSwiping: this.onSwiping,
      mouseTrackingEnabled: true,
      touchTrackingEnabled: true,
      directionDelta: 40,
    });
    swipeable.init();
  }

  private onSwipeUp(ev: Event | any, data: EventData): void {
    if (data.directionY === 'TOP') {
      ev.target.dismiss();
    }
  }

  private onSwiping(ev: Event | any, data: EventData): void {
    const { deltaY, directionY } = data;
    if (directionY === 'TOP') {
      ev.target.style.transform = `translateY(${-deltaY}px)`;
    }
  }

  private setOnClickHandler(toast: HTMLIonToastElement, data: NotificationBannerModel): void {
    toast.onclick = async () => {
      const { appealMovementNumber, mainId, mobileNoticeType } = data;

      if (!appealMovementNumber) {
        await toast.dismiss();
        return;
      }

      this.store.dispatch(new SetTransitionFromToast(true));
      await this.notificationsService.openDocumentCard(appealMovementNumber, mainId, null, mobileNoticeType?.id);
      this.filterService.resetFilter();
      await toast.dismiss();
      await this.closeDatePicker();
    };
  }

  /** закрыть выбор даты */
  private async closeDatePicker(): Promise<void> {
    await this.pickerCtrl?.dismiss();
  }
}
