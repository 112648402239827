export enum ActionUrgencyEnum {
  /** Срок не продлевать, оставить на контроле, установить срок доклада */
  noPrologWithReport = 'NO_PROLONG_WITH_REPORT',
  /** Дополнение к блоку */
  addition = 'ADDITION',
  /** Срок не продлевать, оставить на контроле */
  noProlog = 'NO_PROLONG',
  /** Продлить срок с сохранением контроля */
  prolog = 'PROLONG',
  /** Снять с контроля */
  stop = 'STOP',
  /** Продлить срок исполнения со снятием с контроля */
  prologNoControl = 'PROLONG_NO_CONTROL',
}
