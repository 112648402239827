import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { TreeHelperService } from '@oogShared/components/resolution-tree/tree-helper.service';

@Directive({
  selector: '[appLineBranch]',
})
/** Директива рассчитывает высоту соединяющей линии для ветки */
export class LineBranchDirective implements AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private el: ElementRef, private treeHelper: TreeHelperService) {}

  public ngAfterViewInit(): void {
    this.calculatedHeightForLine();
    this.subscribeToToggle();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToToggle(): void {
    this.treeHelper.toggleTriggered.pipe(delay(10), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.calculatedHeightForLine();
    });
  }

  private calculatedHeightForLine(): void {
    const lastElementInCurrentElement = this.el.nativeElement.lastChild;
    const childrenLastElement = lastElementInCurrentElement.children;

    if (childrenLastElement?.length) {
      const heightLastElement = childrenLastElement[childrenLastElement.length - 1]?.offsetHeight / 2;
      this.el.nativeElement.style.setProperty('--height', `${heightLastElement}px`);
    }

    if (childrenLastElement?.length && childrenLastElement[childrenLastElement.length - 1]?.children?.length) {
      const children = childrenLastElement[childrenLastElement.length - 1].children;

      if (!children[children.length - 1].querySelector('li')) {
        return;
      }

      const parentFirstChildren = children[0].parentElement;

      const calc =
        lastElementInCurrentElement.offsetHeight - (parentFirstChildren.offsetHeight - children[0].offsetHeight / 2);
      const heightLine = lastElementInCurrentElement.offsetHeight - calc;

      this.el.nativeElement.style.setProperty('--height', `${heightLine}px`);
    }
  }
}
