import { Pipe, PipeTransform } from '@angular/core';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';

/** Возвращает строкове представление для СПР / СПЗ */
@Pipe({
  name: 'resolutionProjectType',
})
export class ResolutionProjectTypePipe implements PipeTransform {
  public transform(type: ResolutionTypeEnum): string {
    switch (type) {
      case ResolutionTypeEnum.resolutionProject:
        return 'СПР';
      case ResolutionTypeEnum.requestProject:
        return 'СПЗ';
      default:
        throw new Error('Не передан тип Поручения');
    }
  }
}
