import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetPreviousActiveDocument } from '@store/menu/previous-active-document/previous-active-document.action';

@Injectable()
@State<number | null>({
  name: 'previousActiveDocument',
  defaults: null,
})
export class PreviousActiveDocumentState {
  @Selector()
  public static previousDocument(state: number): number {
    return state;
  }

  @Action(SetPreviousActiveDocument)
  public setPreviousActiveDocument({ setState }: StateContext<number>, { card }: SetPreviousActiveDocument): void {
    setState(card);
  }
}
