import { Component } from '@angular/core';
import { WordCasesEnum } from '@enums/word-cases.enum';
import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-confirm-mayor-with-comment',
  templateUrl: './confirm-mayor-with-comment.component.html',
  styleUrls: ['./confirm-mayor-with-comment.component.scss'],
})
export class ConfirmMayorWithCommentComponent extends DialogBase {
  public wordCasesEnum = WordCasesEnum;
}
