import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { VertexState } from '@npaShared/enums/vertex-state.enum';
import { getParallelPhasesOfPhase, getPhaseByPointId } from '@npaShared/helpers/route/phases-helper';
import { getOtherMainVerticalPointsOnSubphaseByPointId } from '@npaShared/helpers/route/subphase-helper';
import { Route, RoutePoint } from '@npaShared/models/route/route.models';

import { PointLogicModel } from './point-logic.interface';

/** Базовый абстрактный класс для наследования логики точки согласования
 * логика, реализация которой не зависит от этапа должна лежать здесь
 */
export abstract class RootPointLogic implements PointLogicModel {
  constructor(private point: RoutePoint, private route: Route) {}

  public getPoint(): RoutePoint {
    return this.point;
  }

  public getRoute(): Route {
    return this.route;
  }

  /** Проверка на то, что текущая точка содержит одну из переданных "ролей"  */
  public isContainsPointRole(allowedRoles: EmployeeRouteTypes[]): boolean {
    return allowedRoles.includes(this.getPoint().pointRoleId);
  }

  /** Проверка на то, что текущая точка имеет одну из переданных резолюций */
  public isContainsPointResolution(allowedResolutions: VertexState[]): boolean {
    return allowedResolutions.includes(this.getPoint().taskResolution);
  }

  /** Точка находится в подэтапе, где еще есть другие точки ( не дочерние )  */
  public hasSiblingPointsInSubphase(): boolean {
    return getOtherMainVerticalPointsOnSubphaseByPointId(this.getRoute(), this.getPoint().id).length > 0;
  }

  /** Точка принадлежит параллельному этапу */
  public isPointInParallelPhase(): boolean {
    const phase = getPhaseByPointId(this.getRoute(), this.getPoint().id);
    return getParallelPhasesOfPhase(this.getRoute(), phase).length > 0;
  }

  public canAddExecutor(): boolean {
    return false;
  }

  public canStartApprovement(): boolean {
    return false;
  }

  public canStartDevelopment(): boolean {
    return false;
  }

  public canApprove(): boolean {
    return false;
  }

  public canApproveWithRemarks(): boolean {
    return false;
  }

  public canAddCoordinator(): boolean {
    return false;
  }

  public canRedirect(): boolean {
    return false;
  }

  public canReturn(): boolean {
    return false;
  }

  public canSendRemarksToAuthor(): boolean {
    return false;
  }

  public canSendToLeader(): boolean {
    return false;
  }

  public canAddIntroducer(): boolean {
    return false;
  }

  public canApproveIntroduction(): boolean {
    return false;
  }

  public canStartRework(): boolean {
    return false;
  }

  public canStartOuterApprovement(): boolean {
    return false;
  }
}
