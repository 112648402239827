import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { VertexState } from '@npaShared/enums/vertex-state.enum';
import { getPhaseByPointId, isAllParallelPhasesCompleted } from '@npaShared/helpers/route/phases-helper';
import { InitiationDefaultLogic } from '../initiation-default-logic';
import { PointLogicModel } from '../point-logic.interface';

/** Логика точки согласования "ОАУ ПМ. Поручение" */
export class OauPmInitiationPoint extends InitiationDefaultLogic implements PointLogicModel {
  public canSendRemarksToAuthor(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader];
    const allowedResolutions = [...EqualActiveResolutions, VertexState.waitingForApprovement];

    if (!this.isContainsPointResolution(allowedResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.isPointInParallelPhase()) {
      return this.canSendRemarksToAuthorForParallelPhase();
    }

    return true;
  }

  public canApprove(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader];
    const allowedResolutions = [...EqualActiveResolutions, VertexState.waitingForApprovement];

    if (!this.isContainsPointResolution(allowedResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.isPointInParallelPhase()) {
      return this.canApproveInParallelPhase();
    }

    return true;
  }

  public canApproveWithRemarks(): boolean {
    return this.canApprove();
  }

  private canApproveInParallelPhase(): boolean {
    const point = this.getPoint();
    const phase = getPhaseByPointId(this.getRoute(), point.id);
    const isAllPhasesCompleted = isAllParallelPhasesCompleted(this.getRoute(), phase);

    if (!isAllPhasesCompleted) {
      return false;
    }

    return true;
  }

  private canSendRemarksToAuthorForParallelPhase(): boolean {
    const point = this.getPoint();
    const phase = getPhaseByPointId(this.getRoute(), point.id);
    const isAllPhasesCompleted = isAllParallelPhasesCompleted(this.getRoute(), phase);

    if (!isAllPhasesCompleted) {
      return false;
    }

    return true;
  }
}
