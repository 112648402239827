import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { delay, first } from 'rxjs/operators';

import { MainMenuTypeEnum } from '@enums/main-screen/main-menu-type.enum';
import { RadioButtonThemeEnum } from '@enums/radio-button-theme.enum';

import { Platform } from '@ionic/angular';
import { VisibleDocumentKindFilterByMenuType } from '@const/visible-document-kind-filter-by-menu-type.const';
import { DocumentKindSelectOptions } from '@const/document-kind-select-options.const';
import { ExcludeDocumentKindByMenuType } from '@const/exclude-document-kind-by-menu-type.const';
import { AppealDocumentKind } from '@enums/appeal-document-kind.enum';
import { DatesFilter } from '../../consts/filter-codes.const';
import { filtersUrgency } from '../../consts/filter-urgency.const';
import { FilterCodesEnum } from '../../enums/side-bar/filter-codes.enum';
import { ItemFilters } from '../../models/filter/item-filters.model';
import { DepartmentModel } from '../../models/resolutions/department/department.model';
import { EmployeeModel } from '../../models/resolutions/employee/employee.model';
import { DictionariesInteropService } from '../../rest/dictionaries-interop.service';
import { FilterService } from '../../services/filter.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input()
  public headerElement: HTMLElement;

  @Input() public filterType: MainMenuTypeEnum;

  @Output() public cancel = new EventEmitter();

  @ViewChild('filter', { static: true }) public filter: ElementRef;

  public filtersUrgency: ItemFilters[] = filtersUrgency;
  public radioButtonThemeEnum = RadioButtonThemeEnum;
  public filterCodesEnum = FilterCodesEnum;
  public datesFilter: ItemFilters[] = DatesFilter;

  public userList: EmployeeModel[] = [];
  public departmentList: DepartmentModel[] = [];

  public activeUrgencyFilter = false;

  public activeAuthorFilter = false;
  public activeReceivedFromFilter = false;
  public activeSignedProjectFilter = false;
  public activeNeedAttentionToggle = true;

  /** Помогает определить руками мы изменили дату или по кнопке */
  public isDatePickerFocused = false;

  public visibleDocumentKindFilter = VisibleDocumentKindFilterByMenuType;
  public documentKindSelectOptions = [];

  constructor(
    private dictionariesInterop: DictionariesInteropService,
    private filterService: FilterService,
    private platform: Platform,
  ) {}

  public get form(): FormGroup {
    return this.filterService.form;
  }

  public ngOnInit(): void {
    this.filterService.initForm();
    this.prepareFiltersList();
    this.setDocumentKindSelectOptions();
  }

  public getUserList(value: string): void {
    if (value.length < 3) {
      return;
    }

    this.dictionariesInterop
      .getUserList({ search: value })
      .pipe(first())
      .subscribe((u) => (this.userList = u));
  }

  public getDepartmentsList(value: string): void {
    this.dictionariesInterop
      .getDeparmentsList(value)
      .pipe(first())
      .subscribe((u) => (this.departmentList = u));
  }

  public focusDatePicker(): void {
    this.isDatePickerFocused = true;
  }

  public blurDatePicker(): void {
    setTimeout(() => (this.isDatePickerFocused = false), 100);
  }

  public changeFilterDates(code: FilterCodesEnum): void {
    this.filterService.changeFilterDates(code);
  }

  public submit(): void {
    this.filterService.submit();
  }

  public clearFilter(): void {
    this.filterService.clear();
  }

  public closeFilter(): void {
    this.filterService.resetFilter();
  }

  public focusField(field: HTMLElement): void {
    const keyboardShow$ = this.platform.keyboardDidShow.pipe(first(), delay(400));

    keyboardShow$.subscribe(() => {
      const top = (field.closest('.group') as HTMLElement)?.offsetTop;
      this.filter.nativeElement.scrollTo({ top: top - 100 });
    });
  }

  private hideAuthorFilter(): boolean {
    return this.filterType === MainMenuTypeEnum.projectsMail;
  }

  private hideReceivedFromFilter(): boolean {
    return this.filterType === MainMenuTypeEnum.mayorMission || this.filterType === MainMenuTypeEnum.projectsMail;
  }

  private hideSignedProjectFilter(): boolean {
    return this.filterType === MainMenuTypeEnum.mayorMission || this.filterType === MainMenuTypeEnum.officialDocuments;
  }

  private showUrgencyFilter(): boolean {
    return this.filterType === MainMenuTypeEnum.officialCorrespondence;
  }

  private showNeedAttentionToggle(): boolean {
    return this.filterType === MainMenuTypeEnum.projectsMail;
  }

  private prepareFiltersList(): void {
    this.activeUrgencyFilter = this.showUrgencyFilter();
    this.activeAuthorFilter = this.hideAuthorFilter();
    this.activeReceivedFromFilter = this.hideReceivedFromFilter();
    this.activeSignedProjectFilter = this.hideSignedProjectFilter();
    this.activeNeedAttentionToggle = this.showNeedAttentionToggle();
  }

  private setDocumentKindSelectOptions(): void {
    this.documentKindSelectOptions = DocumentKindSelectOptions.filter((itemOption) => {
      const excludedOptions = ExcludeDocumentKindByMenuType.get(this.filterType) ?? [];
      return !excludedOptions.includes(itemOption.id as string as AppealDocumentKind);
    });
  }
}
