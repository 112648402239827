import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as SockJS from 'sockjs-client';

import { SocketChannel } from '@npaShared/models/websocket/socket-channel';
import { CompatClient, Message, Stomp } from '@stomp/stompjs';

import { UrlsService } from '@shared/services/urls.service';
import { CurrentUserAissdStoreService } from '../../store/current-user-aissd-store.service';
import { environment } from '../../../../../environments/environment';

/**
 * Сервис для работы с websocket
 * При инициализации подключается к бэкэнду по вебсоккету
 * и предоставляет клиентам возможность подписки на уведомления от сервера
 */
@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  // константы
  /** Точка входа для подключения SockJs */
  private entryPointUrl = '/sockJs';
  /** Время через которое клиент и сервер обмениваются пингами для подтверждения соединения*/
  private heartbeat = {
    incoming: 5000,
    outgoing: 5000,
  };

  /** Задержка между попытками повторного подключения */
  private reconnectDelay = 5000;
  /** StompClient - клиент реализующий протокол Stomp */
  private stompClient: CompatClient;
  /** Флаг для включения отладочного вывода */
  private debug = false;

  /** Мапа для сопоставления адреса с уже существующей подпискойno
   * Нужна для восстановления подписок при реконнекте и кеширования подписок
   */
  private subjectsMap: { [key: string]: Subject<any> } = {};

  constructor(private store: CurrentUserAissdStoreService, private urlsService: UrlsService) {
    // Подключение по websocket должно быть привязано к пользователю для корректной работы
    // поэтому выбираем текущего пользователя.
    this.store
      .getCurrentUser()
      .pipe(filter((userData) => !!(userData && userData.currentUser && userData.currentUser.employee)))
      .subscribe(() => {
        this.initializeWebSocketConnection();
      });
  }

  /**
   * Выбор канала для подписки
   * Если канал начинается с /user, то по нему будут
   * отправляться персональные уведомления для текущего пользователя
   * иначе - все сообщения независимо от пользователя
   *
   * @returns subject для подписки на соообщения
   * @param chanel
   */
  public selectChanel<T>(chanel: SocketChannel<T>): Observable<T> {
    const address = chanel.address;
    if (this.subjectsMap[address]) {
      return this.subjectsMap[address];
    }

    const result = new Subject<T>();
    this.subjectsMap[address] = result;
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.subscribe(address, (message) => {
        result.next(this.convertMessage(message));
      });
    }

    return result;
  }

  /**
   * Метод для отправки сообщений через webSocket
   *
   * @param dest - адрес назначения (например /app/hello)
   * @param message - сообщение для отправки
   */
  public sendMessage(dest: string, message: any): void {
    const stringMessage = JSON.stringify(message);
    this.stompClient.send(dest, {}, stringMessage);
  }

  private initializeWebSocketConnection(): void {
    this.tryConnect();
  }

  private tryConnect(): void {
    // инициализируем Stomp через созданный ранее канал
    this.stompClient = Stomp.over(() => new SockJS(this.getConnectionUrl()));
    this.stompClient.reconnectDelay = this.reconnectDelay;

    // Настраиваем stomp
    this.stompClient.heartbeat = this.heartbeat;
    if (!this.debug) {
      this.stompClient.debug = () => {};
    }

    // Коннектимся
    this.stompClient.connect({}, this.onConnect.bind(this), this.onError.bind(this));
  }

  /** Обработчик успешного подключения
   * Пока что только инициализирует подписки
   */
  private onConnect(): void {
    this.initSubscriptions();
  }

  /** Обработчик ошибок при передаче
   * Ошибкой может быть только потеря связи, поэтому в случае ошибки планируется реконнект через заданное время.
   */
  private onError(): void {
    console.log(`Scheduled reconnect after ${this.reconnectDelay}ms`);
  }

  /** Функция для инициализации подписок
   * Если вызывается при первом подключении то подписок нет и нечего инициализированно не будет
   * Если вызывается после реконнекта то будут восстановлены все старые подписки
   */
  private initSubscriptions(): void {
    Object.keys(this.subjectsMap).forEach((dest) => {
      this.stompClient.subscribe(dest, (message) => {
        this.subjectsMap[dest].next(this.convertMessage(message));
      });
    });
  }

  /** Функция для приведения сообщений к одному формату */
  private convertMessage(message: Message): any {
    return JSON.parse(message.body);
  }

  /** В начале url должен быть пользователь в формате userId:password@
   * поэтому приходится формировать url так
   * пока что пользователи авторизуются без пароля
   */
  private getConnectionUrl(): string {
    const hostFromLocalstorage = this.urlsService.getHost;
    const websoketUrl = environment.npaWebsocketUrl + this.entryPointUrl;

    if (hostFromLocalstorage) {
      return `${hostFromLocalstorage}${websoketUrl}`;
    }

    return `${environment.npaWebsocketHost}${websoketUrl}`;
  }
}
