import { Pipe, PipeTransform } from '@angular/core';

import { PersonalFolder } from '../../models/folders/personal-folders.model';

@Pipe({
  name: 'existInSelectedFolders',
})
export class ExistInSelectedFoldersPipe implements PipeTransform {
  public transform(folders: PersonalFolder[], id: number): boolean {
    if (!folders) {
      return false;
    }
    return !!folders.find((folder) => folder.id === id);
  }
}
