import { Component, Input } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { FormHeaderModel } from '../../models/forms-input/form-header.model';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
})
export class FormHeaderComponent {
  @Input() public data!: FormHeaderModel;
  @Input() public form: FormGroup;
}
