import { Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { ButtonFontSizeEnum } from '@enums/button-font-size.enum';
import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { PositionRadiusEnum } from '@enums/position-radius.enum';

/**
 * Базовый родительский класс для компонент кнопок.
 * Помогает вынести одинаковую логику из потомков.
 */
@Directive()
export class ButtonBase {
  /** идентификатор кнопки для тестирования */
  @HostBinding('attr.id')
  @Input()
  public id: string | undefined;

  /** Тема оформления кнопки */
  @HostBinding('attr.theme')
  @Input()
  public theme: ButtonThemeEnum;

  @HostBinding('attr.font-size')
  @Input()
  public fontSize: ButtonFontSizeEnum;

  @HostBinding('attr.position-radius')
  @Input()
  public radiusPosition: PositionRadiusEnum;

  /** флаг того, что эта кнопка сабмитит форму */
  @Input() public isSubmitType = false;

  /** надпись кнопки */
  @Input() public label: string | undefined;

  /** флаг выключения активности */
  @Input() public disabled: boolean | undefined;

  /** Название для тега title */
  @Input() public tooltip: string | undefined;

  @Output() public clickButton = new EventEmitter();
}
