import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { ResolutionTypeModel } from '@oogShared/models/resolutions/resolution/resolution-type.model';
import { AppealResolutionEnum } from '@oogShared/enums/resolution-form/appeal-resolution-type.enum';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';

/** Сервис для создание данных формы создания резолюции из черновика */
@Injectable({
  providedIn: 'root',
})
export class CreateResolutionDraftService {
  constructor(private fb: FormBuilder) {}

  public restoreCommissionGroup(
    mission: MissionModel,
    isAppeal: boolean = false,
    fileId: number | null = null,
  ): FormGroup {
    const addressee = mission?.addressee ? [...mission?.addressee] : [];
    if (mission?.isWithoutExecutorOrAddressee) {
      // удалить последнего пользователя,
      // который был добавлен перед отправкой
      addressee.pop();
    }

    return this.fb.group({
      ...(isAppeal && {
        type: [this.getCommissionType(mission?.type) || AppealResolutionEnum.resolution, Validators.required],
      }),
      text: [mission?.privateComment || ''],
      executorSign: [mission?.isWithoutExecutorOrAddressee || false],
      serialNumber: [mission?.serialNumber],
      performers: this.fb.array(
        addressee.map(
          (a) => this.createAddresseeControlFromDraft(a, mission?.isWithoutExecutorOrAddressee || false) || [],
        ),
      ),
      savedText: [''],
      picture: [''],
      parentResolutionCommentText: [''],
      fileId: [fileId],
      ...(isAppeal && { forPrepareAnswer: [mission?.forPrepareAnswer || true] }),
      ...(isAppeal && { isCommentConfidential: [mission?.isCommentConfidential || false] }),
    });
  }

  /** Создать контрол адресата из данных черновика
   *
   * @param addressee - данные адресата
   * @param familiarization - флаг без исполнителей*/
  private createAddresseeControlFromDraft(addressee: ResolutionAddresseeModel, familiarization: boolean): FormGroup {
    const inPlus = !addressee?.checkDateType && !!addressee?.urgencyReviewType;

    return this.fb.group({
      performer: [addressee?.employee, Validators.required],
      responsible: [addressee?.isResponsibleByMission || false],
      inPlus: [inPlus || familiarization || false],
      note: [addressee?.personalNote || ''],
      urgency: [addressee?.urgencyReviewType || addressee?.checkDateType || ''],
      serialNumber: [addressee?.serialNumber],
      commissionId: [null],
      ...(!inPlus && !familiarization && { date: [addressee?.dueDate || ''] }),
      ...(!inPlus && !familiarization && { control: [addressee?.isControl || false] }),
    });
  }

  private getCommissionType(value: ResolutionTypeModel): AppealResolutionEnum {
    switch (value?.id) {
      case ResolutionTypeEnum.resolution:
        return AppealResolutionEnum.resolution;
      case ResolutionTypeEnum.request:
        return AppealResolutionEnum.project;
      case ResolutionTypeEnum.forwarding:
        return AppealResolutionEnum.redirect;

      default:
        return AppealResolutionEnum.resolution;
    }
  }
}
