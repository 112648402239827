import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetStatusCurrentUserAssistant } from '@store/status-current-user-assistant/status-current-user-assistant.action';

@Injectable()
@State<boolean>({
  name: 'statusCurrentUserAssistant',
  defaults: false,
})
export class StatusCurrentUserAssistantState {
  @Selector()
  public static getStatus(state: boolean): boolean {
    return state;
  }

  @Action(SetStatusCurrentUserAssistant)
  public setStatusCurrentUserAssistant(
    { setState }: StateContext<boolean>,
    { value }: SetStatusCurrentUserAssistant,
  ): void {
    setState(value);
  }
}
