import { AuthRequestModel } from '@models/auth.request.model';
import * as Sentry from '@sentry/angular';

export const setUsersForSentry = (uuid?: string): void => {
  const oogCredentials = getOogCredentials() && (JSON.parse(atob(getOogCredentials())) as AuthRequestModel);
  const npaCredentials = getNpaCredentials() && (JSON.parse(atob(getNpaCredentials())) as AuthRequestModel);

  hasOogCredentials()
    ? setUserSentry(oogCredentials, oogCredentials?.username, '', uuid)
    : setUserSentry(npaCredentials, '', npaCredentials?.username, uuid);

  if (hasOogCredentials() && hasNpaCredentials()) {
    setUserSentry(oogCredentials, oogCredentials?.username, npaCredentials?.username, uuid);
  }
};

export const hasOogCredentials = (): boolean => Boolean(getOogCredentials());

export const hasNpaCredentials = (): boolean => Boolean(getNpaCredentials());

const getOogCredentials = (): string | null => getItem('oogCredentials');

const getNpaCredentials = (): string | null => getItem('npaCredentials');

const getItem = (key: string): string | null => localStorage.getItem(key);

const setUserSentry = (value: AuthRequestModel, oogName: string, npaName: string, uuid: string): void => {
  oogName && Sentry.setTag('user-oog', oogName);
  npaName && Sentry.setTag('user-npa', npaName);
  uuid && Sentry.setTag('uuid', uuid);
  Sentry.setUser({ id: value?.username });
};

export const breadcrumbSentry = (message: string, data?: any): void => {
  Sentry.addBreadcrumb({
    message,
    data,
  });
};

export const logToSentry = (message: string, extra?: any): void => {
  Sentry.captureMessage(message, {
    extra,
  });
};
