import { Injectable } from '@angular/core';
import { ActionsFooterWithArrow } from '@enums/actions-footer-with-arrows.enum';
import { Store } from '@ngxs/store';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { SetArrowMovement } from '@store/arrow-movement/arrow-movement.action';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { RemoveDocumentFromList } from '@store/menu/count-documents-menu/count-documents-menu.action';
import { DeleteCard } from '@store/menu/documents-cards/documents-cards.action';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';
import { SetPreviousActiveDocument } from '@store/menu/previous-active-document/previous-active-document.action';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';
import { SetShownGroup } from '@store/menu/sidebar/sidebar.action';
import { DocumentsGroupState } from '@store/menu/documents-group/documents-group.state';
import { DeleteCardFromGroup } from '@store/menu/documents-group/documents-group.action';
import { FoldersStateService } from './folders/folders-state.service';

@Injectable({ providedIn: 'root' })
export class DocumentNavigationService {
  constructor(private store: Store, private foldersState: FoldersStateService) {}

  public nextDocument(familiarization: boolean = false): void {
    const actionWithFolders = this.store.selectSnapshot(SidebarState.actionWithFolders);

    if (actionWithFolders) {
      this.foldersState.getFoldersFromApi(true).subscribe(() => this.moveToNextDocument(familiarization));
      return;
    }

    this.moveToNextDocument(familiarization);
  }

  private moveToNextDocument(familiarization: boolean): void {
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    const activeIndex = this.store.selectSnapshot(DocumentsCardsState.currentDocument);
    const showGroup = this.store.selectSnapshot(SidebarState.shownGroup);

    if (card?.groupProjects && (!familiarization || showGroup)) {
      const activeIndexInGroup = this.store.selectSnapshot(DocumentsGroupState.getActiveCardIndex);
      const resultIndex = this.isCardMainInGroup(card) ? activeIndex : activeIndexInGroup;
      this.nextDocumentAfterGroup(card, resultIndex);
      return;
    }

    this.nextDocumentAfterDocument(card, activeIndex);
  }

  private nextDocumentAfterGroup(card: CardDocumentModel, activeIndex: number): void {
    if (this.isCardMainInGroup(card)) {
      this.store.dispatch([
        new SetShownGroup(false),
        new SetPreviousActiveDocument(activeIndex),
        new SetArrowMovement({ action: ActionsFooterWithArrow.right }),
        new DeleteCard(card.uniqueId),
        new RemoveDocumentFromList(),
      ]);
      return;
    }

    const arrowMovement = this.getArrowMovementForGroupCards(activeIndex);

    this.store.dispatch([
      new SetPreviousActiveDocument(activeIndex),
      new SetArrowMovement({ action: arrowMovement }),
      new DeleteCardFromGroup(card.uniqueId),
    ]);
  }

  private nextDocumentAfterDocument(card: CardDocumentModel, activeIndex: number): void {
    this.store.dispatch([
      new DeleteCard(card.uniqueId),
      new SetPreviousActiveDocument(activeIndex),
      new SetArrowMovement({ action: ActionsFooterWithArrow.right }),
      RemoveDocumentFromList,
    ]);
  }

  private isCardMainInGroup(card: CardDocumentModel): boolean {
    return card.groupProjects?.groupProjects?.mainAppealMovementId === card.appealMovementId;
  }

  /** Определить направление для следующего документа */
  private getArrowMovementForGroupCards(activeIndex: number): ActionsFooterWithArrow {
    const countGroupCards = this.store.selectSnapshot(DocumentsGroupState.countDocuments);

    if (activeIndex + 1 === countGroupCards) {
      return ActionsFooterWithArrow.left;
    }
    return ActionsFooterWithArrow.right;
  }
}
