import { Component, Input } from '@angular/core';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { first } from 'rxjs/operators';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-project-letter',
  templateUrl: './project-letter.component.html',
  styleUrls: ['./project-letter.component.scss'],
})
export class ProjectLetterComponent {
  @Input() public form = new FormGroup({});

  public shownChooseAuthor = false;
  public userList: EmployeeModel[] = [];

  constructor(
    private currentUser: CurrentUserIsproStoreService,
    private dictionariesInterop: DictionariesInteropService,
  ) {}

  /** Получить список пользователей по поиску */
  public getUserList(value: string): void {
    if (value.length < 3) {
      return;
    }

    const params = {
      search: value,
      whitelistEmployeeId: this.currentUser.getCurrentUserSnapshot()?.id,
      whitelistDepartmentId: this.currentUser.getCurrentUserSnapshot()?.department?.id,
    };

    this.dictionariesInterop
      .getUserList(params)
      .pipe(first())
      .subscribe((u) => (this.userList = u));
  }

  public changeProjectLetter(): void {
    this.shownChooseAuthor = false;
    const control = this.form.get('hasProjectLetter');
    !control.value && this.form.get('projectLetterAuthor')?.reset();
  }

  public chooseAuthor(employee: EmployeeModel): void {
    const control = this.form.get('projectLetterAuthor');
    control.setValue(employee);
    this.shownChooseAuthor = false;
  }

  public clearAuthor(): void {
    this.form.get('projectLetterAuthor')?.reset();
  }
}
