import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedOogModule } from '@oogShared/shared-oog.module';
import { SharedApplicationModule } from '@shared/shared-application.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { IonicModule } from '@ionic/angular';
import { BookmarkCreateComponent } from './popaps/bookmark-create/bookmark-create.component';
import { BookmarkDeleteComponent } from './popaps/bookmark-delete/bookmark-delete.component';
import { BookmarkSelectionComponent } from './popaps/bookmark-selection/bookmark-selection.component';

const components = [BookmarkSelectionComponent, BookmarkDeleteComponent, BookmarkCreateComponent];

@NgModule({
  imports: [
    CommonModule,
    SharedOogModule,
    IonicModule.forRoot(),
    SharedApplicationModule,
    PdfViewerModule,
    ReactiveFormsModule,
  ],
  declarations: [...components],
  entryComponents: [],
})
export class BookmarksModule {}
