import { ApprovalParticipantModel } from '../models/approval-list/approval-participant.model';

export const DefaultNullApprovalParticipantConst: ApprovalParticipantModel = {
  employee: null,
  dueDate: null,
  urgencyReviewType: null,
  numberOrder: 0,
  id: null,
  pid: null,
  actionTime: null,
  actionDate: null,
  creationDate: null,
  startWorkDate: null,
  whoGaveRights: null,
  approvalComments: [],
  files: [],
  redirectBlocks: [],
  comeback: null,
  type: null,
  actionType: null,
  status: null,
  whoComeback: null,
};
