import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { DocumentRevisionModel } from '@npaShared/models/document/document-revision.model';
import { RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';
import { getActualDocumentVersion } from './document-version.helper';

/**
 * Получение модели ревизий документа
 *
 * @param document документ
 * @returns модель ревизий документа
 */
export const getDocumentRevision = (
  document: DocumentInfoResponseModel,
  activePoint: RoutePointWithAdditionalInfo,
): DocumentRevisionModel | null => {
  if (!document || !activePoint) {
    return null;
  }

  const actualVersion = getActualDocumentVersion(document.versions, activePoint.phaseId);

  if (!actualVersion) {
    return null;
  }

  return {
    documentId: document.id,
    ldeId: document.ldeDocumentId,
    revision: actualVersion.revision,
  };
};
