import { Component } from '@angular/core';

import { ButtonBase } from '../button-base';

@Component({
  selector: 'app-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent extends ButtonBase {}
