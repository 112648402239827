import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalService } from '@shared/services/modal.service';
import { NavController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { PermissionsEnum } from '@oogShared/enums/permissions.enum';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { routeNames } from '../../app-routing/route-names.const';

/** Гард для проверки прав доступа к разделу загрузки отчетов */
@Injectable({ providedIn: 'root' })
export class ReportsGuard implements CanActivate {
  constructor(
    private userApi: UserInfoApiService,
    private modalService: ModalService,
    private navController: NavController,
  ) {}

  public canActivate(): Observable<boolean> {
    return this.userApi.getIsProInfo().pipe(
      map((user) => {
        if (user.employee.permissions?.includes(PermissionsEnum.uploadReport)) {
          return true;
        }

        this.modalService.presentModalError('Отсутствуют необходимые права');
        this.navController.navigateRoot(routeNames.mainScreen);
        return false;
      }),
    );
  }
}
