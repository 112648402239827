import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetStateFullScreen } from '@store/file-full-screen/file-full-screen.action';

@Injectable()
@State<boolean>({
  name: 'fileFullScreen',
  defaults: false,
})
export class FileFullScreenState {
  @Selector()
  public static getStatusButton(state: boolean): boolean {
    return state;
  }

  @Action(SetStateFullScreen)
  public setStateFullScreen({ setState }: StateContext<boolean>, { state }: SetStateFullScreen): void {
    setState(state);
  }
}
