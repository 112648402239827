import { Pipe, PipeTransform } from '@angular/core';

import { ResolutionAddresseeTypeEnum } from '../../enums/resolution-addressee/resolution-addressee-type.enum';
import { ResolutionAddresseeModel } from '../../models/resolutions/resolution/resolution-addressee.model';

@Pipe({
  name: 'appEmployeeReviewersResolver',
})
export class EmployeeReviewersResolverPipe implements PipeTransform {
  public transform(addressee: ResolutionAddresseeModel[], onlyReviewers: boolean = true): ResolutionAddresseeModel[] {
    return addressee.filter((a) =>
      onlyReviewers
        ? a.addresseeType.id === ResolutionAddresseeTypeEnum.reviewer
        : a.addresseeType.id !== ResolutionAddresseeTypeEnum.reviewer,
    );
  }
}
