import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'selectFilter' })
export class SelectFilterPipe implements PipeTransform {
  public transform(value: any[], label: string | ((value: any) => string), filterString: string): any[] {
    if (!value || !label) {
      return [];
    }

    const getLabel = typeof label === 'string' ? (v) => v[label] : label;

    if (!filterString) {
      return value;
    }

    return value.filter((i) =>
      getLabel(i) ? getLabel(i).toLowerCase().includes(filterString.toLocaleLowerCase()) : '',
    );
  }
}
