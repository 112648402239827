/** Текст кнопок вынесения решения */
export enum ButtonsTextEnum {
  close = 'Закрыть',
  back = 'Назад',
  cancel = 'Отмена',
  save = 'Сохранить',
  sendComment = 'Направить комментарий',
  continue = 'Продолжить',
  refresh = 'Обновить',

  decide = 'Вынести решение',
  approve = 'Согласовать',
  approveWithRemark = 'Согласовать с замечаниями',
  redirect = 'Перенаправить',
  return = 'Вернуть',
  sendRemarksToAuthor = 'Направить замечания без согласования',
  sendRemarksToAuthorShortName = 'Направить замечания',
  sendToOauLeader = 'Направить руководителю',
  addExecutor = 'Добавить исполнителя',
  addCoordinator = 'Добавить согласующего',
  startDevelopment = 'Взять в работу',
  startApprovement = 'Отправить на согласование',
  startApprovementWithRemarks = 'Отправить на согласование с замечаниями',
  addIntroducer = 'Передать на ознакомление',
  approveAsIntroduced = 'Ознакомлен',
  startRework = 'Начать доработку',
  startOuterApprovement = 'Отправить на согласование',
}
