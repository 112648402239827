import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonThemeEnum } from '@enums/button-theme.enum';

@Component({
  selector: 'app-filter-actions',
  templateUrl: './filter-actions.component.html',
  styleUrls: ['./filter-actions.component.scss'],
})
export class FilterActionsComponent {
  @Input() public buttonsActive = false;
  @Input() public formDirty = false;

  @Output() public submitFilter = new EventEmitter();
  @Output() public clearFilter = new EventEmitter();
  @Output() public cancelFilter = new EventEmitter();

  public buttonTheme = ButtonThemeEnum;
}
