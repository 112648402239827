import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { BUTTON_ROLE } from '@oogShared/consts/button-role.const';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() public text = '';

  public buttonRole = BUTTON_ROLE;

  constructor(private modalCtrl: ModalController) {}

  public closeModal(action: string): void {
    this.modalCtrl.dismiss({
      action,
    });
  }
}
