import { Injectable } from '@angular/core';
import { DecideModalsHelperBaseService } from './decide-modal-helper-base-service';

/** Отображение сообщения "Выполнение задачи недоступно без электронной подписи. Обратитесь к администратору системы" */

@Injectable({
  providedIn: 'root',
})
export class DisplayingMessageAboutTaskCompletionUnavailableService extends DecideModalsHelperBaseService {
  public displayingMessage(isLocalLegalActsType: boolean, hasSignature: boolean): boolean {
    return this.calculate(isLocalLegalActsType, hasSignature);
  }

  protected lpa(): boolean {
    if (this.isLeader) {
      return this.lpaLeader();
    }

    return this.lpaCoordinator();
  }

  protected npa(): boolean {
    if (this.isLeader) {
      return this.npaLeader();
    }

    return this.npaCoordinator();
  }

  private lpaLeader(): boolean {
    if (this.phaseIs.projectCreated) {
      return this.leaderShared();
    }

    if (this.canApproveWithoutSignatureOnPhase) {
      return false;
    }

    if (this.hasSignature) {
      return false;
    }

    return true;
  }

  private lpaCoordinator(): boolean {
    if (this.phaseIs.projectCreated) {
      return this.coordinatorShared();
    }

    return false;
  }

  private coordinatorShared(): boolean {
    if (!this.hasSignerCategory) {
      return false;
    }

    if (this.canApproveWithoutSignatureOnPhase) {
      return false;
    }

    if (this.hasSignature) {
      return false;
    }

    return true;
  }

  private npaLeader(): boolean {
    if (this.phaseIs.preliminaryApprovement && !this.hasSignerCategory) {
      return false;
    }

    return this.leaderShared();
  }

  private npaCoordinator(): boolean {
    return this.coordinatorShared();
  }

  private leaderShared(): boolean {
    if (this.canApproveWithoutSignatureOnPhase) {
      return false;
    }

    if (this.hasSignature) {
      return false;
    }

    return true;
  }
}
