import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { getPointAuthorToReturns } from '@npaShared/helpers/route/get-points-to-return';
import { isSomeActiveParallelPhase } from '@npaShared/helpers/route/phases-helper';

import { ApprovementDefaultLogic } from '../approvement-default-logic';
import { PointLogicModel } from '../point-logic.interface';

/** Логика точки согласования "Проект создан. Согласование" */
export class ProjectCreatedApprovementPoint extends ApprovementDefaultLogic implements PointLogicModel {
  public canSendRemarksToAuthor(): boolean {
    return false;
  }

  public canApprove(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader, EmployeeRouteTypes.coordinator];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (!this.hasSiblingPointsInSubphase()) {
      return this.canApproveForSinglePointInProjectCreatedApprovmentSubphase();
    }

    return true;
  }

  public canApproveWithRemarks(): boolean {
    const disallowedRoles = [EmployeeRouteTypes.leader];

    if (this.isContainsPointRole(disallowedRoles)) {
      return false;
    }

    return this.canApprove();
  }

  public canAddCoordinator(): boolean {
    return false;
  }

  public canReturn(): boolean {
    const point = this.getPoint();
    const pointAuthor = getPointAuthorToReturns(this.getRoute(), point);
    const allowedRoles = [EmployeeRouteTypes.coordinator, EmployeeRouteTypes.leader];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (pointAuthor) {
      return true;
    }

    return super.canReturn();
  }

  public canStartOuterApprovement(): boolean {
    const allowedRoles = [EmployeeRouteTypes.author];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    const haveActivePointOnParallelPhases = isSomeActiveParallelPhase(this.getRoute());

    return !haveActivePointOnParallelPhases;
  }

  /** Имеет ли точка полномочия, чтобы "Согласовать", если является единственной в подэтапе */
  private canApproveForSinglePointInProjectCreatedApprovmentSubphase(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader];

    return this.isContainsPointRole(allowedRoles);
  }
}
