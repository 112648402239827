import { Pipe, PipeTransform } from '@angular/core';

import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';

/**
 * пайп возвращает полное имя пользователя
 */
@Pipe({
  name: 'employeeFullName',
})
export class EmployeeFullNamePipe implements PipeTransform {
  public transform(employee: SharedInfoAboutEmployee): string {
    return transformEmployeeFullNamePipe(employee);
  }
}

export const transformEmployeeFullNamePipe = (employee: SharedInfoAboutEmployee): string => {
  const lastName = employee?.lastName || '';
  const firstName = employee?.firstName || '';
  const middleName = employee?.middleName || '';

  return `${lastName} ${firstName} ${middleName}`.trim();
};
