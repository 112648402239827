import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import {
  getExecutorPointsToReturns,
  getPointsCoordinatorsToReturns,
} from '@npaShared/helpers/route/get-points-to-return';
import { pointIsParallel } from '@npaShared/helpers/route/point-helper';
import { getOtherMainVerticalPointsOnSubphaseByPointId } from '@npaShared/helpers/route/subphase-helper';

import { PointLogicModel } from './point-logic.interface';
import { RootPointLogic } from './root-point-logic';

/** В классе описано поведение общее для все точек, принадлежащих подэтапу "Согласование" */
export abstract class ApprovementDefaultLogic extends RootPointLogic implements PointLogicModel {
  public canStartApprovement(): boolean {
    const allowedRoles = [EmployeeRouteTypes.author, EmployeeRouteTypes.executor];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (!this.hasCoordinatorOnSameSubphase()) {
      return false;
    }

    return true;
  }

  public canApprove(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader, EmployeeRouteTypes.coordinator, EmployeeRouteTypes.executor];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.isContainsPointRole([EmployeeRouteTypes.executor]) && this.hasSiblingPointsInSubphase()) {
      return false;
    }

    return true;
  }

  public canApproveWithRemarks(): boolean {
    return this.canApprove();
  }

  public canAddCoordinator(): boolean {
    const allowedRoles = [EmployeeRouteTypes.executor, EmployeeRouteTypes.coordinator];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.isParallelPoint()) {
      return false;
    }

    if (!this.isMainLevelPoint()) {
      return false;
    }

    return true;
  }

  public canSendRemarksToAuthor(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader, EmployeeRouteTypes.executor];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (this.hasSiblingPointsInSubphase() && this.isContainsPointRole([EmployeeRouteTypes.executor])) {
      return false;
    }

    return true;
  }

  public canReturn(): boolean {
    const point = this.getPoint();
    const allowedRoles = [EmployeeRouteTypes.coordinator, EmployeeRouteTypes.leader, EmployeeRouteTypes.executor];
    const pointsCoordinators = getPointsCoordinatorsToReturns(this.getRoute(), point);
    const executorToReturnsPoints = getExecutorPointsToReturns(this.getRoute(), point);
    const pointToReturns = [...pointsCoordinators];
    if (executorToReturnsPoints.length) {
      pointToReturns.push(...executorToReturnsPoints);
    }

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    if (!pointToReturns.length) {
      return false;
    }

    return true;
  }

  public canRedirect(): boolean {
    const allowedRoles = [EmployeeRouteTypes.leader, EmployeeRouteTypes.coordinator, EmployeeRouteTypes.executor];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    return true;
  }

  public canAddIntroducer(): boolean {
    const allowedRoles = [
      EmployeeRouteTypes.leader,
      EmployeeRouteTypes.coordinator,
      EmployeeRouteTypes.introducer,
      EmployeeRouteTypes.executor,
    ];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    return true;
  }

  public canApproveIntroduction(): boolean {
    const allowedRoles = [EmployeeRouteTypes.introducer];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    return true;
  }

  public hasCoordinatorOnSameSubphase(): boolean {
    return getOtherMainVerticalPointsOnSubphaseByPointId(this.getRoute(), this.getPoint().id).some((subphasePoint) =>
      [EmployeeRouteTypes.coordinator, EmployeeRouteTypes.leader].includes(subphasePoint.pointRoleId),
    );
  }

  public isParallelPoint(): boolean {
    return pointIsParallel(this.getRoute(), this.getPoint().id);
  }

  public isMainLevelPoint(): boolean {
    return this.getPoint().level === 1;
  }
}
