import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { UserSettingDto } from '@npaShared/models/user-setting.model';
import { CurrentUserModel } from '@oogShared/models/current-user/current-user.model';
import { ReplacedUserModel } from '@oogShared/models/user/replaced-user.model';

@Injectable({
  providedIn: 'root',
})
export class UserInfoApiService {
  private readonly url = Urls.auth.currentUserAisSd;

  constructor(private http: HttpClient) {}

  public getAisSdInfo(): Observable<UserInfoResponseModel> {
    return this.http.get<UserInfoResponseModel>(this.url);
  }

  public getIsProInfo(): Observable<CurrentUserModel> {
    const oogUrl = Urls.auth.currentUserIsPro;
    return this.http.get<BaseApiResponseModel<CurrentUserModel>>(oogUrl).pipe(map((value) => value.data));
  }

  public replacedUser(): Observable<ReplacedUserModel> {
    const url = Urls.replacedUser;
    return this.http.get<BaseApiResponseModel<ReplacedUserModel>>(url).pipe(map((value) => value.data));
  }

  public getAisSdUserPermissions(): Observable<UserSettingDto[]> {
    const url = Urls.auth.aisSdUserPermissions;
    return this.http.get<UserSettingDto[]>(url);
  }

  public checkHasAssistant(): Observable<boolean> {
    const url = Urls.auth.hasAssistant;
    return this.http.get<BaseApiResponseModel<boolean>>(url).pipe(map((value) => value.data));
  }
}
