import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ChoiceMainProjectModel } from '@oogShared/components/card-expedited-review/choice-main-project.model';
import {
  ResetChosenDocumentInGroup,
  SetChosenDocumentInGroup,
} from '@store/expedited-review/choosen-document-in-group/chosen-document-in-group.action';

@Injectable()
@State<ChoiceMainProjectModel | null>({
  name: 'choseDocumentInGroup',
  defaults: null,
})
export class ChosenDocumentInGroupState {
  @Selector()
  public static getChosenDocument(state: ChoiceMainProjectModel): ChoiceMainProjectModel {
    return state;
  }

  @Action(SetChosenDocumentInGroup)
  public setChosenDocumentInGroup(
    { setState }: StateContext<ChoiceMainProjectModel>,
    { data }: SetChosenDocumentInGroup,
  ): void {
    setState(data);
  }

  @Action(ResetChosenDocumentInGroup)
  public resetChosenDocumentInGroup({ setState }: StateContext<ChoiceMainProjectModel>): void {
    setState(null);
  }
}
