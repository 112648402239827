import { Pipe, PipeTransform } from '@angular/core';

import { DateHelperService } from '@shared/services/date-helper.service';

@Pipe({
  name: 'expiredDaysString',
})
export class ExpiredDaysStringPipe implements PipeTransform {
  constructor(private dateHelper: DateHelperService) {}

  public transform(date: Date): string {
    const difference = this.dateHelper.subtractDate(date) ? Math.abs(this.dateHelper.subtractDate(date)) + 1 : 0;
    const lastFigure = difference % 10;
    let dayString = '';

    if (this.dateHelper.subtractClearDays(date) === -1) {
      return '(просрочено: 1 день)';
    }

    if (
      difference === 0 ||
      !date ||
      this.dateHelper.dateToSmallFormat(date) === this.dateHelper.dateToSmallFormat(new Date())
    ) {
      return '';
    }

    if (difference >= 11 && difference < 15) {
      dayString = 'дней';
    } else {
      if (lastFigure > 1 && lastFigure < 5) {
        dayString = 'дня';
      }
      if (lastFigure === 0 || lastFigure >= 5) {
        dayString = 'дней';
      }
    }
    const differenceTwoDate = this.dateHelper.differenceDates(date, new Date());

    return differenceTwoDate < 0 ? `(просрочено: ${difference} ${dayString})` : '';
  }
}
