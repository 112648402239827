import { Pipe, PipeTransform } from '@angular/core';

import { Urls } from '@const/urls';

@Pipe({
  name: 'photoAddressee',
})
/** Пайп для получения фотографии исполнителя/адресата */
export class PhotoAddresseePipe implements PipeTransform {
  public transform(photoId: string): string {
    return Urls.pictures.oogProfilePic(photoId);
  }
}
