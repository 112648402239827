import { Injectable } from '@angular/core';

import { ResolutionModel } from '../../models/resolutions/resolution/resolution.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectResolutionHelperService {
  public setChildrenResolution(state: ResolutionModel): ResolutionModel[] {
    return (state.childResolutions = state.childResolutions
      .map((r, index) => {
        const array: ResolutionModel[] = [];
        if (index + 1 !== r.serialNumber) {
          array.push({ ...r, serialNumber: index + 1, isChangeResolution: true });
          return array;
        }
        array.push({ ...r, serialNumber: index + 1 });
        return array;
      })
      .flat());
  }

  public setChildrenResolutionMissions(state: ResolutionModel): ResolutionModel {
    const missions = [...state.missions].sort((a, b) => a?.serialNumber - b?.serialNumber);

    return { ...state, missions };
  }
}
