import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  DisplayPdfViewerOnMainDocumentPage,
  HiddenPdfViewerOnMainDocumentPage,
} from './visibility-pdf-viewer-on-main-document-page.action';

@Injectable()
@State<boolean>({
  name: 'visibilityPdfViewerOnMainDocumentPage',
  defaults: true,
})
export class VisibilityPdfViewerOnMainDocumentPageState {
  @Selector()
  public static visibilityPdfViewer(state: boolean): boolean {
    return state;
  }

  @Action(DisplayPdfViewerOnMainDocumentPage)
  public displayPdfViewerOnMainDocumentPage({ setState }: StateContext<boolean>): void {
    setState(true);
  }

  @Action(HiddenPdfViewerOnMainDocumentPage)
  public hiddenPdfViewerOnMainDocumentPage({ setState }: StateContext<boolean>): void {
    setState(false);
  }
}
