import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { FileTypeEnum } from '@enums/file-type.enum';
import { TreeResponseModel } from '@oogShared/models/approval-list/tree-response.model';
import { SetBookmarks } from '@store/resolution-store/bookmarks/bookmarks.action';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { ResolutionComponentNameEnum } from '@oogShared/enums/resolution-component-name.enum';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { DossierResponseModel } from '@oogShared/models/dossier/dossier-response.model';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { GroupDocumentsService } from '@oogShared/services/group-documents.service';
import { SwipeMenuService } from '@oogShared/services/swipe-menu.service';
import { scrollingToOog } from '@shared/functions/scrolling-to-oog.function';
import { scrollDown, scrollUp } from '@shared/functions/scrolling.function';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { CountDocumentsMenuState } from '@store/menu/count-documents-menu/count-documents-menu.state';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';
import { SetDossier } from '@store/resolution-store/dossier/dossier.action';
import { DossierState } from '@store/resolution-store/dossier/dossier.state';
import { SetResolutionFiles } from '@store/resolution-store/resolution-files/resolution-files.action';
import { ResolutionFilesState } from '@store/resolution-store/resolution-files/resolution-files.state';
import { SetResolutionTree } from '@store/resolution-store/resolution-tree/resolution-tree.action';
import { ResolutionTreeState } from '@store/resolution-store/resolution-tree/resolution-tree.state';
import { SetResolution, SortChildResolutionMissions } from '@store/resolution-store/resolution/resolution.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { modalIdCreateResolution, modalIdRedirectResolution } from '@const/modals-id.const';
import { QuestionMissionModel } from '@oogShared/models/resolutions/mission/question-mission.model';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { DocumentsGroupState } from '@store/menu/documents-group/documents-group.state';
import { ModalService } from '@shared/services/modal.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { logToSentry } from '@shared/functions/set-users-for-sentry.function';
import { UpdateKeyCache } from '@store/cache-timing/cache-timing.action';
import { CacheImageService } from '@oogShared/services/cache-image.service';
import { LoadStatusEnum } from '@enums/load-status.enum';

@Directive()
export abstract class ResolutionBase {
  @Select(ResolutionState.getResolutionProjectCard)
  public resolutionProjectCard$!: Observable<ResolutionModel>;

  @Select(ResolutionState.getFirstMissionFromResolution)
  public firstResolutionMission$!: Observable<MissionModel>;

  @Select(ResolutionState.getFamiliarizationPerformers)
  public familiarizationPerformers$!: Observable<ResolutionAddresseeModel[]>;

  @Select(ResolutionTreeState.getResolutionTree)
  public tree$!: Observable<ResolutionModel[]>;

  @Select(ResolutionFilesState.getResolutionFiles)
  public files$!: Observable<FilePoolModel[]>;

  @Select(CountDocumentsMenuState.countDocuments)
  public numberOfDocuments$: Observable<number>;

  @Select(ActiveCardState.isMainCardInGroup)
  public isMainCardInGroup$: Observable<boolean>;

  @Select(ActiveCardState.isGroupActive)
  public isMainCardInGroupAndActive$: Observable<boolean>;

  @Select(DossierState.getDossier)
  public dossier$: Observable<DossierResponseModel>;

  @Select(ActiveCardState.getActiveCard)
  public activeCard$: Observable<CardDocumentModel>;

  @Select(DocumentsCardsState.countDocuments)
  public documentsCount$: Observable<number>;

  @Select(SidebarState.actionWithFolders)
  public actionWithFolders$: Observable<boolean>;

  @Select(SidebarState.shownGroup)
  public shownGroup$: Observable<boolean>;

  @Select(DocumentsGroupState.countDocuments)
  public countGroupDocuments$: Observable<number>;

  @Select(DocumentsGroupState.getActiveCardCount)
  public countActiveDocumentInGroupCount$: Observable<number>;

  @Select(DocumentsCardsState.currentDocument)
  public currentDocumentNumber$: Observable<number>;

  public resolutionComponentName = ResolutionComponentNameEnum;
  public refreshStatus = false;
  public readonly resolutionType = ResolutionTypeEnum;
  public readonly fileTypes = FileTypeEnum;
  public readonly loadingStatuses = LoadStatusEnum;

  public resolutionMyDepartmentTree: ResolutionModel[] = [];
  public resolutionOtherDepartmentTree: ResolutionModel[] = [];

  public resolutionDraft: CreateResolutionModel | null;

  public scrollingToUp = scrollUp;
  public scrolling = scrollingToOog;
  public scrollingToDown = scrollDown;

  public status: LoadStatusEnum;

  protected unsubscribeCardDownload$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected store: Store,
    protected modalCtrl: ModalController,
    protected modalService: ModalService,
    private cardService: ResolutionInteropService,
    private groupDocuments: GroupDocumentsService,
    private swipeMenu: SwipeMenuService,
    private cacheImageService: CacheImageService,
  ) {}

  public showModalInGroup(component: any, componentName: string, create: boolean): void {
    if (!this.groupDocuments.isGroupDocument() || !this.groupDocuments.isGroupDocumentActive()) {
      create ? this.goToCreate(component) : this.goToRedirectForm(component);
      return;
    }
    this.checkViewedAllInGroup(component, componentName, create);
  }

  public showModalAppealInGroup(component: any, componentName: string, create: boolean): void {
    if (!this.groupDocuments.isGroupDocument() || !this.groupDocuments.isGroupDocumentActive()) {
      create ? this.goToCreate(component) : this.goToRedirectForm(component);
      return;
    }
    this.checkViewedAllInGroup(component, componentName, create);
  }

  public doRefresh(refresher?: any): void {
    this.resetResolutionTrees();
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    const citizenApeal = this.store.selectSnapshot(ActiveCardState.isCitizenAppeal);
    this.refreshStatus = false;
    forkJoin([
      this.cardService.getResolution(card.mainId).pipe(catchError(() => of(null))),
      this.cardService.getResolutionTree(card.mainId, citizenApeal).pipe(catchError(() => of(null))),
      this.cardService.getFiles(card.appealMovementId).pipe(
        catchError((err: HttpErrorResponse) => {
          logToSentry('Ошибка загрузки файла', {
            'code: ': err.status,
            'url: ': err.url,
          });

          if (err.status === HttpStatusCode.NotFound) {
            this.modalService.presentModalError('Не удалось загрузить файл. Попробуйте повторить позднее.');
          }

          return of(null);
        }),
      ),
      this.cardService.getDossier(card.dossierId).pipe(catchError(() => of(null))),
      this.cardService.getImagesFiles(card.appealId, false, !refresher).pipe(catchError(() => of([]))),
      this.cardService.getResolutionDraftById(card.mainId).pipe(catchError(() => of(null))),
    ])
      .pipe(first(), takeUntil(this.unsubscribeCardDownload$))
      .subscribe(([resolution, resolutionTree, files, dossier, images, resolutionDraft]) => {
        this.refreshStatus = true;
        if (refresher) {
          this.store.dispatch(UpdateKeyCache);
        }
        this.store.dispatch([
          new SetResolution(resolution),
          SortChildResolutionMissions,
          new SetResolutionTree(resolutionTree),
          new SetResolutionFiles(files, images),
          new SetDossier(dossier),
          new SetBookmarks(images),
        ]);
        this.resolutionDraft = resolutionDraft;
        refresher?.target?.complete();
        this.status = LoadStatusEnum.success;
        this.cacheImageService.getActiveAndNextCards();
      });
  }

  /** Показать диалоговое окно при ознакомлении с проектом */
  public showConfirmDialog(project: boolean): void {
    this.swipeMenu.showFamiliarizationDialog(project);
  }

  /** Выбор документа из блока "Дело" */
  public chooseDocument(data: { id: number; fullHistory: boolean }): void {
    this.resetResolutionTrees();
    this.cardService
      .getDossierTree(data.id, data.fullHistory)
      .pipe(first())
      .subscribe((result) => this.setTreeDepartment(result));
  }

  /** Поменять структуру missions */
  public getQuestionMissionResponse(cardInfoMissions: MissionModel[]): QuestionMissionModel[] {
    const questionMission: QuestionMissionModel[] = [];
    cardInfoMissions.forEach((item1) => {
      const missions: MissionModel[] = [];
      const cardInfoMissionsQuestions = item1?.questions?.length ? item1.questions[0] : null;

      if (item1?.questions?.length) {
        cardInfoMissions.forEach((item2) => {
          if (!item2?.questions?.length) {
            return;
          }

          if (
            cardInfoMissionsQuestions &&
            item2.questions[0] &&
            cardInfoMissionsQuestions.id === item2.questions[0].id
          ) {
            missions.push(item2);
            item2.questions[0] = null;
          }
        });
      }

      if (!item1?.questions?.length) {
        missions.push(item1);
      }

      if (missions.length) {
        questionMission.push({
          question: item1?.questions?.length ? cardInfoMissionsQuestions : null,
          missions,
        });
      }
    });

    questionMission.sort((a, b) => Number(a?.question?.serialNumber) - Number(b?.question?.serialNumber));

    return questionMission;
  }

  /** Показать модальное окно для перенаправления */
  protected goToRedirectForm(component: any): void {
    const draft = this.resolutionDraft?.type?.id === ResolutionTypeEnum.heading ? this.resolutionDraft : null;

    this.modalCtrl
      .create({
        component,
        componentProps: {
          draft,
        },
        cssClass: 'modal-fullscreen',
        id: modalIdRedirectResolution,
      })
      .then((modal) => modal.present());
  }

  /** Проверить, что все документы в группе просмотрены */
  private checkViewedAllInGroup(component: any, componentName: string, create: boolean): void {
    this.groupDocuments
      .isViewedAllInGroup()
      .pipe(first())
      .subscribe((r) => {
        if (!r) {
          this.showConfirmDialogAboutGroups(component, componentName, create);
          return;
        }
        create ? this.goToCreate(component) : this.goToRedirectForm(component);
      });
  }

  /** Показать диалоговое окно для создания резолюции */
  private goToCreate(component: any): void {
    const draft = this.resolutionDraft?.type?.id === ResolutionTypeEnum.resolution ? this.resolutionDraft : null;

    this.modalCtrl
      .create({
        component,
        componentProps: {
          draft,
        },
        cssClass: 'modal-fullscreen',
        id: modalIdCreateResolution,
      })
      .then((modal) => modal.present());
  }

  /** Показать модальное окно для отображения информации, что не все доки в группе просмотрены */
  private showConfirmDialogAboutGroups(component: any, componentName: string, create: boolean): void {
    this.groupDocuments
      .showConfirmDialogInGroup(componentName)
      .pipe(first())
      .subscribe(() => {
        create ? this.goToCreate(component) : this.goToRedirectForm(component);
      });
  }

  /** Засетить значения для деревьев у блока "Дело" */
  private setTreeDepartment(data: TreeResponseModel): void {
    if (data.myDepartment) {
      this.resolutionMyDepartmentTree = data.myDepartment.map((m) => ({
        ...m,
        depth: 0,
      }));
    }
    if (data.ampmDepartment) {
      this.resolutionOtherDepartmentTree = data.ampmDepartment.map((a) => ({
        ...a,
        depth: 0,
      }));
    }
  }

  private resetResolutionTrees(): void {
    this.resolutionMyDepartmentTree = [];
    this.resolutionOtherDepartmentTree = [];
  }
}
