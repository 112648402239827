import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';

import { UserCardComponent } from '@components/user-card/user-card.component';
import { CssOptionsModel } from '@models/css-options.model';
import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';

@Injectable({
  providedIn: 'root',
})
export class UserCardService {
  private userCardComponent!: ComponentRef<UserCardComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public show(employee: SharedInfoAboutEmployee, employeeId: number, options: CssOptionsModel): void {
    this.userCardComponent?.destroy();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserCardComponent);
    this.userCardComponent = componentFactory.create(this.injector);
    this.userCardComponent.instance.employee = employee;
    this.userCardComponent.instance.employeeId = employeeId;
    this.userCardComponent.instance.options = options;
    this.appRef.attachView(this.userCardComponent.hostView);

    const domElem = (this.userCardComponent.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.insertBefore(domElem, document.body.firstChild);
  }

  public destroyCard(): void {
    if (this.userCardComponent) {
      this.appRef.detachView(this.userCardComponent.hostView);
      this.userCardComponent.destroy();
    }
  }
}
