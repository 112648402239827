import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { MainCategoriesModel } from '@models/main-screen/main-categories-model';
import { StatisticOnTransferredRight } from '@models/main-screen/statistic-transferred-right.model';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

@Injectable({
  providedIn: 'root',
})
export class MainScreenInteropService {
  constructor(private http: HttpClient) {}

  public getCategoriesCounts(): Observable<MainCategoriesModel> {
    const url = Urls.mainScreenCategoriesUrl;
    return this.http.get<BaseApiResponseModel<MainCategoriesModel>>(url).pipe(map((value) => value.data));
  }

  public saveRightsToSession(transferredRightId: number): Observable<BaseApiResponseModel<null>> {
    const url = Urls.mainScreen.saveRightsToSession(transferredRightId);
    return this.http.post<BaseApiResponseModel<null>>(url, null);
  }

  public resetSessionRights(): Observable<BaseApiResponseModel<null>> {
    const url = Urls.mainScreen.resetSessionRights;
    return this.http.post<BaseApiResponseModel<null>>(url, null);
  }

  public getCountsEmployeeFrom(): Observable<StatisticOnTransferredRight> {
    const url = Urls.mainScreen.countsEmployeeFrom;
    return this.http.get<BaseApiResponseModel<StatisticOnTransferredRight>>(url).pipe(map((value) => value.data));
  }

  public saveWorkForEveryone(): Observable<void> {
    const url = Urls.mainScreen.saveWorkForEveryone;
    return this.http.post<void>(url, null);
  }

  public getCurrentEmployeeFrom(): Observable<EmployeeModel[]> {
    const url = Urls.getCurrentEmployeeFrom;
    return this.http.get<PaginationBaseModel<EmployeeModel>>(url).pipe(map((val) => val.data.content));
  }
}
