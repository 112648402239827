import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  public debugLog(...message: any[]): void {
    if (!environment.production) {
      console.log(...message);
    }
  }
}
