import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';

import { ApprovalHelperService } from '@oogShared/services/approval-helper.service';
import { DocumentNavigationService } from '@oogShared/services/document-navigation.service';
import { ApprovalModalBase } from '../approval-modal-base/approval-modal-base';

@Component({
  selector: 'app-approval-modal-with',
  templateUrl: './approval-modal-with.component.html',
  styleUrls: ['./approval-modal-with.component.scss'],
})
export class ApprovalModalWithComponent extends ApprovalModalBase implements OnInit {
  public form: FormGroup = new FormGroup({});

  constructor(
    currentUser: CurrentUserIsproStoreService,
    modalCtrl: ModalController,
    approvalHelperService: ApprovalHelperService,
    store: Store,
    private fb: FormBuilder,
    private documentNavigationService: DocumentNavigationService,
  ) {
    super(currentUser, modalCtrl, approvalHelperService, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.headerData.title = 'Согласовать с комментарием';
    this.initForm();
    super.getPreviousComment();
  }

  public submitForm(): void {
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    this.approvalHelperService
      .approveApproval(this.approvalData, card.appealMovementId, this.form.value)
      .pipe(first())
      .subscribe(() => {
        this.documentNavigationService.nextDocument();
        this.previousStep();
      });
  }

  /** Вставить предыдущий */
  public getPreviousComment(): void {
    const text = this.form.controls.text;
    text.setValue(this.previousComment);
  }

  /** Очистить комментарий */
  public clearComment(): void {
    this.form.controls.text.reset();
  }

  /** Инициализация формы */
  private initForm(): void {
    this.form = this.fb.group({
      confidential: [false],
      text: [null],
    });
  }
}
