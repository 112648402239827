import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ResetActiveProject,
  SetActiveProject,
} from '@store/expedited-review/active-project/active-project-expedited-review.action';

@Injectable()
@State<number | null>({
  name: 'activeProject',
  defaults: null,
})
export class ActiveProjectExpeditedReviewState {
  @Selector()
  public static getActiveProjectId(state: number): number {
    return state;
  }

  @Action(SetActiveProject)
  public setActiveProject({ setState }: StateContext<number>, { id }: SetActiveProject): void {
    setState(id);
  }

  @Action(ResetActiveProject)
  public resetActiveProject({ setState }: StateContext<number>): void {
    setState(null);
  }
}
