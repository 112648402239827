import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StartTimerCache, UpdateKeyCache } from '@store/cache-timing/cache-timing.action';

/** Интервал обновления ключа для запросов, которые закэшированы (5 минут) */
const UNIQUE_KEY_UPDATE_INTERVAL = 300000;

@Injectable()
@State<number>({
  name: 'cacheTiming',
  defaults: Math.random(),
})
export class CacheTimingState {
  @Selector()
  public static getUniqueKey(state: number): number {
    return state;
  }

  @Action(StartTimerCache)
  public setStateFullScreen({ setState }: StateContext<number>): void {
    setInterval(() => {
      setState(Math.random());
    }, UNIQUE_KEY_UPDATE_INTERVAL);
  }

  @Action(UpdateKeyCache)
  public updateKeyCache({ setState }: StateContext<number>): void {
    setState(Math.random());
  }
}
