import { Pipe, PipeTransform } from '@angular/core';

import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';

@Pipe({ name: 'checkEsignResolutionValid' })
export class CheckEsignResolutionValidPipe implements PipeTransform {
  public transform(resolution: ResolutionModel): boolean {
    return !!resolution?.esign?.isValid;
  }
}
