import { ArrowMovementModel } from '@models/documents/arrow-movement.model';

/** Сохранить активное нажатие на стрелочку */
export class SetArrowMovement {
  public static readonly type = '[Arrow Movement] Set action';

  constructor(public action: ArrowMovementModel) {}
}

/** Сбросить активное нажатие на стрелочку */
export class ResetArrowMovement {
  public static readonly type = '[Arrow Movement] Reset action';
}
