import { Component, EventEmitter, Output } from '@angular/core';

import { BaseControl } from '@components/controls/control-base';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent extends BaseControl {
  @Output() public focusEvent = new EventEmitter<boolean>();

  /** убрали фокус с контрола */
  public onBlurAction(): void {
    this.trimControlValue();
    this.focusEvent.emit(false);
  }

  /** контрол получил фокус */
  public onFocusAction(): void {
    this.focusEvent.emit(true);
  }
}
