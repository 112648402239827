import { Injectable } from '@angular/core';

import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

import { AbstractCurrentUserStore } from './abstract-current-user-store';

@Injectable({
  providedIn: 'root',
})
export class AuthUserIsproStoreService extends AbstractCurrentUserStore<EmployeeModel> {}
