import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BaseApiResponseModel } from '@models/base-api-response.model';
import { DateHelperService } from '@shared/services/date-helper.service';

import { DefaultNullApprovalParticipantConst } from '../consts/default-null-approval-participant.const';
import { ApprovalBlockTypeEnum } from '../enums/approval-list/approval-block-type.enum';
import { ApprovalCommentTypeNamesEnum } from '../enums/approval-list/approval-comment-type-names.enum';
import { ApprovalParticipantActionTypeNamesEnum } from '../enums/approval-list/approval-participant-action-type-names.enum';
import { ApprovalParticipantStatusNamesEnum } from '../enums/approval-list/approval-participant-status-names.enum';
import { ApprovalBlockModel } from '../models/approval-list/approval-block.model';
import { ApprovalParticipantModel } from '../models/approval-list/approval-participant.model';
import { ApprovalRequestModel } from '../models/approval-list/approval-request.model';
import { ApprovalModel } from '../models/approval-list/approval.model';
import { ApprovalFormBaseModel } from '../models/approval-modals/approval-form-base.model';
import { ApprovalFormModel } from '../models/approval-modals/approval-form.model';
import { ApprovalRedirectFormDataModel } from '../models/approval-modals/approval-redirect-form-data.model';
import { EmployeeModel } from '../models/resolutions/employee/employee.model';
import { ApprovalListInteropService } from '../rest/approval-list-interop.service';

@Injectable({
  providedIn: 'root',
})
export class ApprovalHelperService {
  public coordinateSwipe$ = new Subject<void>();
  public approvalData: ApprovalModel = null;
  /** участник, который перенаправил лист согласования текущему */
  private resultParticipant: ApprovalParticipantModel = null;

  constructor(private approvalApi: ApprovalListInteropService, private dateHelper: DateHelperService) {}

  /** Перенаправление согласования */
  public redirectApproval(
    formData: ApprovalRedirectFormDataModel,
    approvalData: ApprovalModel,
  ): Observable<BaseApiResponseModel<null>> {
    const data = this.mapDataForRedirect(formData, approvalData);
    return this.approvalApi.redirectApproval(data);
  }

  /** Возврат листа согласования */
  public returnApproval(
    formData: ApprovalFormModel,
    approvalData: ApprovalModel,
  ): Observable<BaseApiResponseModel<null>> {
    const data = this.mapDataForReturn(formData, approvalData);
    return this.approvalApi.returnApproval(data);
  }

  /** Согласовать лист согласования */
  public approveApproval(
    approvalData: ApprovalModel,
    appealMovementId: number,
    formData?: ApprovalFormModel,
  ): Observable<BaseApiResponseModel<null>> {
    const data = this.mapDataForApprove(approvalData, formData);
    return this.approvalApi.approveApproval(data, appealMovementId);
  }

  /** Создание листа сотрудников, которым можно вернуть лист согласования */
  public createRedirectList(data: ApprovalModel): EmployeeModel[] {
    const redirectList = [];
    this.resultParticipant = null;

    switch (data?.currentParticipant.actionType) {
      case ApprovalParticipantActionTypeNamesEnum.redirected:
        this.findWhoRedirectedParticipant(data.blocks, data.currentParticipant);

        data.signerBlocks && this.findWhoRedirectedSigner(data.signerBlocks, data.currentParticipant);

        redirectList.push(this.resultParticipant?.employee);
        break;
      case ApprovalParticipantActionTypeNamesEnum.added:
        const all = [
          data.initiatorParticipant?.employee,
          ...this.collectAllApprovalEmployees(data.blocks),
          ...this.collectAllApprovalSigners(data.signerBlocks),
        ];
        const allBeforeCurrent = this.collectAllApprovalEmployeesBeforeCurrent(all, data.currentParticipant.employee);
        redirectList.push(...allBeforeCurrent);
        break;
    }
    return redirectList.reverse();
  }

  /** Поиск адресата в списке подписантов */
  public isAddresseeSigner(addressee: EmployeeModel): boolean {
    return !!this.approvalData.signerBlocks.find((signerBlock: ApprovalBlockModel) =>
      signerBlock.signers.find((signer: ApprovalParticipantModel) => signer.employee.id === addressee.id),
    );
  }

  /** Найти участника согласования по employeeId */
  public findParticipantByEmployeeId(approvalData: ApprovalModel, employeeId: number): ApprovalParticipantModel {
    const allParticipants = [
      approvalData.initiatorParticipant,
      ...this.collectAllApprovalParticipants(approvalData.blocks),
      ...this.collectAllApprovalParticipants(approvalData.signerBlocks),
    ];
    return allParticipants.reverse().find((val) => val?.employee?.id === employeeId);
  }

  /** Спаить данные для перенаправления согласования */
  private mapDataForRedirect(
    formData: ApprovalRedirectFormDataModel,
    approvalData: ApprovalModel,
  ): ApprovalRequestModel {
    return {
      id: approvalData.id,
      addedParticipants: [],
      comebackParticipant: null,
      filePools: [],
      currentParticipantId: approvalData.currentParticipant.id,
      approvalComment: {
        isCommentConfidential: formData.confidential,
        comment: formData.text,
        type: ApprovalCommentTypeNamesEnum.redirection,
        creationDate: null,
      },
      redirectedBlock: {
        id: null,
        numberOrder: 1,
        type: ApprovalBlockTypeEnum.consecutive,
        participants: this.createParticipantsList(formData),
        signers: [],
      },
    };
  }

  /** Смапить данные для Возврата согласования */
  private mapDataForReturn(formData: ApprovalFormModel, approvalData: ApprovalModel): ApprovalRequestModel {
    return {
      id: approvalData.id,
      addedParticipants: [],
      comebackParticipant: {
        ...DefaultNullApprovalParticipantConst,
        employee: formData.addressee,
        id: this.findParticipantByEmployeeId(approvalData, formData.addressee.id)?.id,
      },
      filePools: [],
      currentParticipantId: approvalData.currentParticipant.id,
      approvalComment: {
        isCommentConfidential: formData.confidential,
        comment: formData.text,
        type: ApprovalCommentTypeNamesEnum.comeback,
        creationDate: null,
      },
      redirectedBlock: {
        id: null,
        numberOrder: 1,
        participants: [],
        signers: [],
        type: ApprovalBlockTypeEnum.consecutive,
      },
    };
  }

  /**  Смапить данные для подтверждения согласования */
  private mapDataForApprove(approvalData: ApprovalModel, formData?: ApprovalFormBaseModel): ApprovalRequestModel {
    return {
      id: approvalData.id,
      addedParticipants: [],
      comebackParticipant: DefaultNullApprovalParticipantConst,
      filePools: [],
      currentParticipantId: approvalData.currentParticipant.id,
      approvalComment: {
        isCommentConfidential: formData?.confidential,
        comment: formData?.text,
        type: ApprovalCommentTypeNamesEnum.approval,
        creationDate: null,
      },
      redirectedBlock: null,
    };
  }

  /** Создание листа участников для перенаправления */
  private createParticipantsList(formData: ApprovalRedirectFormDataModel): ApprovalParticipantModel[] {
    return formData.addressees.map((value, idx) => ({
      ...DefaultNullApprovalParticipantConst,
      employee: value.addressee,
      dueDate: this.dateHelper.dateToSmallFormat(value.date),
      urgencyReviewType: value.urgency,
      numberOrder: idx + 1,
    }));
  }

  /** Выборка всех согласующих */
  private collectAllApprovalEmployees(blocks: ApprovalBlockModel[]): EmployeeModel[] {
    const employees = [];

    blocks &&
      blocks.forEach((block) => {
        block.participants.forEach((participant) => {
          employees.push(participant.employee);
        });
      });

    return employees;
  }

  /** Выборка всех подписантов */
  private collectAllApprovalSigners(blocks: ApprovalBlockModel[]): EmployeeModel[] {
    const employees = [];

    blocks &&
      blocks.forEach((block) => {
        block.signers.forEach((signer) => {
          employees.push(signer.employee);
        });
      });

    return employees;
  }

  /** Поиск участника, который перенаправил лист согласования на текущего согласованта*/
  private findWhoRedirectedParticipant(
    blocks: ApprovalBlockModel[],
    current: ApprovalParticipantModel,
    parentParticipant?: ApprovalParticipantModel,
  ): ApprovalParticipantModel {
    blocks &&
      blocks.forEach((block) => {
        block.participants.forEach((participant) => {
          if (participant.status === ApprovalParticipantStatusNamesEnum.onAgreement && participant.id === current.id) {
            this.resultParticipant = parentParticipant;
          }
          if (!this.resultParticipant) {
            this.findWhoRedirectedParticipant(participant.redirectBlocks, current, participant);
          }
        });
      });
    return this.resultParticipant;
  }

  /** Поиск участника, который перенаправил лист согласования на текущего в блоке подписантов */
  private findWhoRedirectedSigner(
    blocks: ApprovalBlockModel[],
    current: ApprovalParticipantModel,
    parentParticipant?: ApprovalParticipantModel,
  ): ApprovalParticipantModel {
    blocks &&
      blocks.forEach((block) => {
        block.signers.forEach((signer) => {
          if (signer.status === ApprovalParticipantStatusNamesEnum.onAgreement && signer.id === current.id) {
            this.resultParticipant = parentParticipant;
          }
          if (!this.resultParticipant) {
            this.findWhoRedirectedSigner(signer.redirectBlocks, current, signer);
          }
        });
      });
    return this.resultParticipant;
  }

  /** Формирование списка участников для возврата */
  private collectAllApprovalEmployeesBeforeCurrent(all: EmployeeModel[], current: EmployeeModel): EmployeeModel[] {
    const employees = [];
    const initiatorParticipantIndex = 1; // 1 потому что итерация идет перед проверкой условия и 0 нельзя присвоить
    let i = 0;

    all.some((employee) => {
      const empId = employee.id;
      const result =
        empId === current.id || // этот пользователь
        (current.whoGaveRights && empId === current.whoGaveRights.id); // или передача прав этому пользователю

      !result && !employees.find((emp) => emp.id === empId) && employees.push(employee);
      i++;

      return result && i !== initiatorParticipantIndex;
    });

    return employees;
  }

  /** Выборка всех участников согласования */
  private collectAllApprovalParticipants(blocks: ApprovalBlockModel[]): ApprovalParticipantModel[] {
    let participants = [];

    blocks &&
      blocks.forEach((block) => {
        block.participants.forEach((participant) => {
          participants.push(participant);
          participants = [...participants, ...this.collectAllApprovalParticipants(participant.redirectBlocks)];
        });
        block.signers.forEach((signer) => {
          participants.push(signer);
          participants = [...participants, ...this.collectAllApprovalParticipants(signer.redirectBlocks)];
        });
      });

    return participants;
  }
}
