import { Injectable } from '@angular/core';
import { RightAcceleratedReviewState } from '@store/right-accelerated-review/right-accelerated-review.state';
import { RightsForRedirectionExpeditedReviewState } from '@store/right-for-redirection-expedited-review/rights-for-redirection-expedited-review.state';
import { StatusCurrentUserAssistantState } from '@store/status-current-user-assistant/status-current-user-assistant.state';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { SettingsState } from '@store/settings/settings.state';
import { NetworkService } from '@shared/services/network.service';
import { HasDssCurrentUserState } from '@store/has-dss-current-user/has-dss-current-user.state';
import { CurrentUserModel } from '@oogShared/models/current-user/current-user.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { AuthorizationInformationService } from '@shared/services/authorization-information.service';

@Injectable({
  providedIn: 'root',
})
export class UsersDataService {
  constructor(
    private userApi: UserInfoApiService,
    private userAissdStore: CurrentUserAissdStoreService,
    private userIsproStore: CurrentUserIsproStoreService,
    private store: Store,
    private network: NetworkService,
    private authorizationInformationService: AuthorizationInformationService,
  ) {}

  /** Метод отдает пользователя NPA из локального хранилища, если он там есть, если его нет, то идет за ним на сервер */
  public getNpaUser$(fromApi: boolean = false): Observable<UserInfoResponseModel | null> {
    if (this.authorizationInformationService.isLoggedOut) {
      return of(null);
    }

    const currentUserFromStore$: Observable<UserInfoResponseModel | null> = this.userAissdStore.getCurrentUser();
    const currentUserFromBackend$: Observable<UserInfoResponseModel> = this.userApi.getAisSdInfo();

    return currentUserFromStore$.pipe(
      first(),
      switchMap((currentUser: UserInfoResponseModel) => {
        if (currentUser && !fromApi) {
          return of(currentUser);
        }
        if (this.network.connectionToNpaStatus$.getValue() && this.network.connectionToOogStatus$.getValue()) {
          return currentUserFromBackend$.pipe(catchError(() => of(null)));
        }
        return of(null);
      }),
    );
  }

  /** @deprecated
   * TODO: NPA-16522 отключение запросов ИСПРО
   * Метод отдает пользователя OOG из локального хранилища, если он там есть, если его нет, то идет за ним на сервер
   */
  public getOogUser$(fromApi: boolean = false): Observable<CurrentUserModel | null> {
    const currentUserFromStore$: Observable<EmployeeModel | null> = this.userIsproStore.getCurrentUser();
    const currentUserFromBackend$: Observable<CurrentUserModel> = this.userApi.getIsProInfo();

    const userInfo: CurrentUserModel = {
      employee: this.userIsproStore.getCurrentUserSnapshot(),
      settings: this.store.selectSnapshot(SettingsState.settingsInBackendModel),
      acceleratedReviewEmployees: this.store.selectSnapshot(RightsForRedirectionExpeditedReviewState.getRights),
      hasAssistant: this.store.selectSnapshot(StatusCurrentUserAssistantState.getStatus),
      hasAcceleratedReview: this.store.selectSnapshot(RightAcceleratedReviewState.getRight),
      hasDss: this.store.selectSnapshot(HasDssCurrentUserState.hasDss),
    };

    return currentUserFromStore$.pipe(
      first(),
      switchMap((currentUser: EmployeeModel) => {
        if (currentUser && !fromApi) {
          return of(userInfo);
        }
        if (this.network.connectionToNpaStatus$.getValue() && this.network.connectionToOogStatus$.getValue()) {
          return currentUserFromBackend$.pipe(catchError(() => of(null)));
        }
        return of(null);
      }),
    );
  }
}
