import { Pipe, PipeTransform } from '@angular/core';
import { SignerCategoryEnum } from '@npaDP/enums/signer-category.enum';
import { SignerCategory } from '@npaShared/models/route/signer-category.model';

@Pipe({
  name: 'getIsSomeSignerCategoryByParam',
})
export class GetIsSomeSignerCategoryByParamPipe implements PipeTransform {
  public transform(categoryParam: SignerCategoryEnum, signerCategories: SignerCategory[]): boolean {
    return signerCategories.some((category) => category.name === categoryParam);
  }
}
