import { Pipe, PipeTransform } from '@angular/core';

import { PersonalFolder } from '../../models/folders/personal-folders.model';

@Pipe({
  name: 'findDocumentInFolders',
})
export class FindDocumentInFoldersPipe implements PipeTransform {
  public transform(documentId: number, allFolders: PersonalFolder[], folder?: PersonalFolder): PersonalFolder[] {
    return this.findDocumentInFolders(documentId, allFolders, folder);
  }

  public findDocumentInFolders(
    documentId: number,
    allFolders: PersonalFolder[],
    folder?: PersonalFolder,
  ): PersonalFolder[] {
    const folderIds = [];
    if (folder) {
      const newFolder = folder.personalFolderDocuments.find((doc) => doc.documentId === documentId) ? folder : null;
      folderIds.push(newFolder);
      folder.folders &&
        folder.folders.forEach((f) => {
          folderIds.push(...this.findDocumentInFolders(documentId, null, f));
        });

      return folderIds.filter((val) => !!val);
    }

    allFolders?.forEach((f) => {
      folderIds.push(...this.findDocumentInFolders(documentId, null, f));
    });
    return folderIds.filter((val) => !!val);
  }
}
