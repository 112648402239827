import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-on-main-screen',
  templateUrl: './filter-on-main-screen.component.html',
  styleUrls: ['./filter-on-main-screen.component.scss'],
})
export class FilterOnMainScreenComponent {
  @Output()
  public toggleFilter = new EventEmitter<boolean>();

  @HostBinding('class.open')
  @Input()
  public activeFilter = false;

  @Input()
  public set applyIcon(value: boolean) {
    this.allowIcon = value;
  }

  public allowIcon = false;

  /** открыть список фильтров */
  @HostListener('click')
  public toggle(state = this.activeFilter): void {
    this.toggleFilter.emit(!state);
  }
}
