import { Pipe, PipeTransform } from '@angular/core';
import { MenuListNpaItemModel } from '@models/main-screen/menu-list-npa-item.model';

@Pipe({
  name: 'disableSubcategoryArrow',
})
export class DisableSubcategoryArrowPipe implements PipeTransform {
  public transform(value: MenuListNpaItemModel): boolean {
    return value?.units?.every((el) => !el?.count) && !value?.count;
  }
}
