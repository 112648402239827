import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-sign',
  templateUrl: './confirm-sign.component.html',
  styleUrls: ['./confirm-sign.component.scss'],
})
export class ConfirmSignComponent {
  constructor(private modalController: ModalController) {}

  public cancel(): void {
    this.modalController.dismiss();
  }
}
