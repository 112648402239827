import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetDossier } from '@store/resolution-store/dossier/dossier.action';
import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { FamiliarizationModel } from '@oogShared/models/familiarization/familiarization.model';
import { EsignOogService } from '../esign-oog.service';
import { DocumentNavigationService } from '../document-navigation.service';
import { routeNames } from '../../../../app-routing/route-names.const';

@Injectable({
  providedIn: 'root',
})
export class FamiliarizationResolutionService {
  constructor(
    private store: Store,
    private router: Router,
    private resolutionInterop: ResolutionInteropService,
    private currentUser: CurrentUserIsproStoreService,
    private documentNavigationService: DocumentNavigationService,
    private esignService: EsignOogService,
  ) {}

  public familiarization(): void {
    if (this.esignService.isInvalidCertificateSigningResolution()) {
      this.esignService.showModalInvalidCertificate();
      return;
    }

    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    const user = this.currentUser.getCurrentUserSnapshot();
    const ids = this.store.selectSnapshot(ResolutionState.addresseeId(user.id));

    const data: FamiliarizationModel = {
      addresseeIds: ids,
      curatorAddresseeIds: [],
      execution: {
        esign: null,
        executionFiles: [],
        executionComment: 'Ознакомлен',
        executionType: {
          id: 1,
          name: 'Исполнение завершено',
          archiveDate: null,
        },
        executionDocuments: [],
      },
      isGroup: card.groupProjects?.groupProjects?.isActive,
    };
    this.sendFamiliarization(data, card, user);
  }

  private sendFamiliarization(data: FamiliarizationModel, card: CardDocumentModel, user: EmployeeModel): void {
    this.resolutionInterop
      .reviewResolution(data, card, user)
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() => {
        this.documentNavigationService.nextDocument(true);
        this.store.dispatch([ResetResolution, ResetDossier]);
        this.router.navigate([routeNames.documents]).then();
      });
  }
}
