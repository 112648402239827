import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActionFiltersEnum } from '@enums/action-filters.enum';

import { SortEnum } from '@enums/sort.enum';
import { Store } from '@ngxs/store';
import { DateHelperService } from '@shared/services/date-helper.service';
import { SetFilterAction } from '@store/menu/sidebar/sidebar.action';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { DEFAULT_REVIEW_TYPE, DEFAULT_SORT_TYPE } from '../consts/default-filters.consts';
import { FilterCodesEnum } from '../enums/side-bar/filter-codes.enum';
import { retrieveDocumentsInDepth } from '../functions/document-folder/document-folder.function';
import { FiltersRequest } from '../models/filter/filter-request.model';
import { PersonalFolderDocuments } from '../models/folders/personal-folder-documents.model';
import { EmployeeModel } from '../models/resolutions/employee/employee.model';
import { FoldersStateService } from './folders/folders-state.service';
import { MenuHelperService } from './menu-helper.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public form = new FormGroup({});
  public userList: EmployeeModel[] = [];

  /** необходимо ли открыть форму со старыми значениями */
  private isNeedToOpenFormWithOldValues = false;
  constructor(
    private dateHelper: DateHelperService,
    private fb: FormBuilder,
    private menuHelper: MenuHelperService,
    private store: Store,
    private foldersState: FoldersStateService,
  ) {}

  public changeFilterDates(code: FilterCodesEnum): void {
    this.form.patchValue({ startDate: null, endDate: null });

    switch (code) {
      case FilterCodesEnum.clear:
        this.form.patchValue({ createTasks: FilterCodesEnum.all });
        break;
      case FilterCodesEnum.today:
        this.form.patchValue({
          startDate: this.dateHelper.today(),
          endDate: this.dateHelper.today(),
        });
        break;
      case FilterCodesEnum.week:
        this.form.patchValue({
          startDate: this.dateHelper.subtractDays(7, 'd'),
          endDate: this.dateHelper.today(),
        });
        break;
    }
  }

  public initForm(): void {
    if (this.isNeedToOpenFormWithOldValues) {
      return;
    }

    this.form = this.fb.group(
      {
        onlyDsp: [false],
        isControl: [false],
        needAttention: [false],
        isNew: [false],
        reviewType: [DEFAULT_REVIEW_TYPE],
        authorId: [null],
        authorOrganizationId: [null],
        fromEmployeeId: [null],
        fromOrganizationId: [null],
        startDate: [null],
        endDate: [null],
        documentKind: [null],
        signerId: [null],
        signerOrganizationId: [null],
        createTasks: [null],
        sortType: [DEFAULT_SORT_TYPE],
      },
      {
        validators: this.dateDiffValidator.bind(this),
      },
    );
  }

  public submit(): void {
    this.isFilterDataChange()
      ? this.store.dispatch(new SetFilterAction(ActionFiltersEnum.apply))
      : this.store.dispatch(new SetFilterAction(ActionFiltersEnum.cancel));
    this.isNeedToOpenFormWithOldValues = true;
    const request = this.prepareFilterData();
    this.searchData(request).pipe(first()).subscribe();
  }

  public openFilters(): void {
    this.store.dispatch(new SetFilterAction(ActionFiltersEnum.openFilters));
  }

  public clear(): void {
    this.isNeedToOpenFormWithOldValues = false;
    this.form.reset();
    this.form.controls.reviewType?.setValue(DEFAULT_REVIEW_TYPE);
    this.form.controls.sortType?.setValue(DEFAULT_SORT_TYPE);
    this.form.markAsPristine();
  }

  public resetFilter(): void {
    this.close();
    this.searchData().pipe(first()).subscribe();
  }

  public close(): void {
    this.store.dispatch(new SetFilterAction(ActionFiltersEnum.cancel));
    this.isNeedToOpenFormWithOldValues = false;
    this.initForm();
  }

  public closeWithSaveFormValues(): void {
    if (!this.isNeedToOpenFormWithOldValues) {
      this.store.dispatch(new SetFilterAction(ActionFiltersEnum.cancel));
      return;
    }

    this.store.dispatch(new SetFilterAction(ActionFiltersEnum.apply));
    this.isNeedToOpenFormWithOldValues = true;
  }

  public prepareFilterData(): FiltersRequest {
    const formValue = this.form?.value;

    return {
      onlyDsp: formValue?.onlyDsp || false,
      needAttention: formValue?.needAttention || false,
      isControl: formValue?.isControl || false,
      isNew: formValue?.isNew || false,
      reviewType: formValue?.reviewType || FilterCodesEnum.all,
      authorId: formValue?.authorId?.id,
      authorOrganizationId: formValue?.authorOrganizationId?.id,
      fromEmployeeId: formValue?.fromEmployeeId?.id,
      fromOrganizationId: formValue?.fromOrganizationId?.id,
      startDate: this.dateHelper.dateToSmallFormat(formValue?.startDate),
      endDate: this.dateHelper.dateToSmallFormat(formValue?.endDate),
      documentKind: formValue?.documentKind?.id,
      signerId: formValue?.signerId?.id,
      signerOrganizationId: formValue?.signerOrganizationId?.id,
      sortType: formValue?.sortType || SortEnum.desc,
    };
  }

  public searchData(filterData?: FiltersRequest): Observable<void> {
    if (this.store.selectSnapshot(SidebarState.transitionFromFolders)) {
      const docs: PersonalFolderDocuments[] = [];
      retrieveDocumentsInDepth(this.foldersState.getFolderToViewSnapshot(), docs);
      return this.menuHelper.loadDocumentsInMenuFromPersonalFolder(docs, true, filterData || null);
    }
    return this.menuHelper.getSearchData(true, filterData || null);
  }

  private dateDiffValidator(group: FormGroup): void {
    const date1 = this.dateHelper.dateToSmallFormat(group.get('startDate')?.value);
    const date2 = this.dateHelper.dateToSmallFormat(group.get('endDate')?.value);
    if (date1 !== null && date2 !== null && date1 > date2) {
      group.controls['endDate'].setErrors({ incorrect: true });
    } else {
      group.controls['endDate'].setErrors(null);
    }
  }

  /** Фильтры отличаются от фильтров по умолчанию */
  private isFilterDataChange(): boolean {
    return (
      this.isFilterDataFilled() ||
      this.form?.value?.reviewType !== DEFAULT_REVIEW_TYPE ||
      this.form?.value?.sortType !== DEFAULT_SORT_TYPE
    );
  }

  // Проверка на пустые поля кроме предустановленных заранее
  private isFilterDataFilled(): boolean {
    const formValue = this.form?.value;

    const data = {
      onlyDsp: formValue?.onlyDsp,
      needAttention: formValue?.needAttention,
      isControl: formValue?.isControl,
      isNew: formValue?.isNew,
      authorId: formValue?.authorId?.id,
      authorOrganizationId: formValue?.authorOrganizationId?.id,
      fromEmployeeId: formValue?.fromEmployeeId?.id,
      fromOrganizationId: formValue?.fromOrganizationId?.id,
      startDate: this.dateHelper.dateToSmallFormat(formValue?.startDate),
      endDate: this.dateHelper.dateToSmallFormat(formValue?.endDate),
      signerId: formValue?.signerId?.id,
      signerOrganizationId: formValue?.signerOrganizationId?.id,
      documentKind: formValue?.documentKind,
    };

    return Object.values(data).filter((item) => !!item).length >= 1;
  }
}
