import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-toggle-item',
  templateUrl: './toggle-item.component.html',
  styleUrls: ['./toggle-item.component.scss'],
})
export class ToggleItemComponent {
  @Input() public value = '';
  @Input() public controlData = {};
  @Input() public type = 'single' || 'multiple';
  @Input() public form = new FormGroup({});
  @Input() public controlLabel = '';
  @Input() public disabled = false;
}
