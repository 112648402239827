import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addComma',
})
export class AddCommaPipe implements PipeTransform {
  public transform(value: string, isLast: boolean): string {
    if (isLast) {
      return value.trim();
    }
    return value.trim() + ', ';
  }
}
