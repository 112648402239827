import { ActualInfoTypeEnum } from '@npaShared/enums/actual-info-type.enum';
import {
  DocumentInfoResponseModel,
  DocumentVersionInfoModel,
} from '@npaShared/models/document/document-info.response.model';

/** Получить все актуальные версии всех документов */
export const getAllActualVersions = (
  documents: DocumentInfoResponseModel[],
  phaseId: number,
): DocumentVersionInfoModel[] => {
  const versions = documents.map((doc) => getActualDocumentVersion(doc.versions, phaseId)).filter(Boolean);
  return versions;
};

/** Надо ли показывать версию документа на переданном этапе */
export const needShowDocumentVersionOnPhase = (version: DocumentVersionInfoModel, phaseId: number): boolean =>
  (version.actualInfo.type === ActualInfoTypeEnum.sign && !!version.actualInfo.actual) ||
  (version.actualInfo.type === ActualInfoTypeEnum.list && version.actualInfo.phaseIds.includes(phaseId));

/** Получить актуальную версию документа */
export const getActualDocumentVersion = (
  versions: DocumentVersionInfoModel[],
  phaseId: number,
): DocumentVersionInfoModel | undefined => (versions || []).find((v) => needShowDocumentVersionOnPhase(v, phaseId));
