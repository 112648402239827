import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  public handleError(error: HttpErrorResponse | Error): void {
    if (!(error instanceof HttpErrorResponse)) {
      console.error(error);
      return;
    }

    if (error.url.indexOf('/oog-mobile/api') >= 0) {
      this.handleOogError(error);
    }

    if (error.url.indexOf('/npa/api') >= 0) {
      this.handleNpaError(error);
    }
  }

  private async presentModalError(text?: string): Promise<void> {
    const modalService = this.injector.get(ModalService);
    return await modalService.presentModalError(text).then();
  }

  private handleOogError(error: HttpErrorResponse): void {
    if (error.status === HttpStatusCode.InternalServerError) {
      this.presentModalError('Невозможно выполнить запрос в данный момент');
    }
  }

  private handleNpaError(error: HttpErrorResponse): void {
    if (error.status !== HttpStatusCode.NotAcceptable) {
      return;
    }

    if (!error?.error?.message) {
      return;
    }

    this.presentModalError(error.error.message);
  }
}
