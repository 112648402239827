import { AfterViewInit, Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Urls } from '@const/urls';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { OOG_FILE_VIEWER_NODE } from '@oogShared/components/file-viewer/consts/oog-file-viewer-node.const';
import { FileViewerPropsModel } from '@oogShared/components/file-viewer/models/file-viewer-props.model';
import { SizesInterface } from '@oogShared/components/file-viewer/models/size-interface.model';
import { FileViewerHelperService } from '@oogShared/components/file-viewer/services/file-viewer-helper.service';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';
import { BehaviorSubject, timer } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { CacheTimingState } from '@store/cache-timing/cache-timing.state';
import { zoomProperties } from '@const/zoom-property.const';
import { FileEventModel } from '@oogShared/models/resolutions/file-event.model';
import { zoomSettings } from './consts/zoom-settings.const';

@UntilDestroy()
@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements AfterViewInit {
  @Input() public topIndent = 0;
  @Input() public showingBookmarks = false;
  @Input() public file: FileEventModel = null;

  @Output() public createBookmark = new EventEmitter<void>();

  @ViewChild('viewer', { static: true }) public fileViewer: ElementRef;

  @Input() public set pages(pages: FilePoolModel[]) {
    this.isLoading = true;
    this.mapPagesToLinks(pages);
    this.currentPages = pages;
  }

  public readonly fileViewerNode = `${OOG_FILE_VIEWER_NODE}-${Math.random()}`;
  public readonly zoomProperties = zoomProperties;

  public currentPages: FilePoolModel[] = [];

  private fileViewerParams$$ = new BehaviorSubject<FileViewerPropsModel>(null);
  private fileViewerParams$ = this.fileViewerParams$$.asObservable();

  public isLoading = true;

  private lastTap = 0;
  private inZoom = false;
  private touchStartDist = 0;
  private timer = timer(1000);
  constructor(private fileViewerHelperService: FileViewerHelperService, private store: Store) {}

  public ngAfterViewInit(): void {
    this.fetchFileViewerParams();
    this.listenResizeElementRef();
    this.initEventListeners();
  }

  public createBookmarkEvent(): void {
    this.createBookmark.emit();
  }

  private fetchFileViewerParams(): void {
    this.fileViewerParams$.pipe(debounceTime(300), untilDestroyed(this)).subscribe((result) => {
      this.fileViewerMount(result);
    });
  }

  private fileViewerMount(params: FileViewerPropsModel): void {
    this.fileViewerHelperService.fileViewerMount(this.fileViewerNode, params);
  }

  private mapPagesToLinks(pages: FilePoolModel[]): void {
    const uniqueKey = this.store.selectSnapshot(CacheTimingState.getUniqueKey);
    const fileLinks =
      pages && pages.length
        ? pages.map((p) => `${Urls.resolutions.previewFile}/${p.storageFileId}/${p.id}?frl=${uniqueKey}`)
        : [];
    const sizes = this.mapSizes(pages);
    const params: Partial<FileViewerPropsModel> = {
      fileLinks,
      sizes,
      onLoad: () => (this.isLoading = false),
    };

    this.setFileViewerParams(params);
  }

  private mapSizes(pages: FilePoolModel[]): SizesInterface[] {
    return (pages || []).map((p) => ({ width: p.width, height: p.height })).filter((i) => i.width && i.height);
  }

  private setFileViewerParams(partialParams: Partial<FileViewerPropsModel>): void {
    const params: FileViewerPropsModel = {
      ...this.fileViewerParams$$.getValue(),
      ...partialParams,
    };

    this.fileViewerParams$$.next(params);
  }

  private listenResizeElementRef(): void {
    this.store
      .select(SidebarState.isHidden)
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe(() => this.fileViewerRefresh());
  }

  private fileViewerRefresh(): void {
    this.fileViewerHelperService.fileViewerRefresh(this.fileViewerNode, this.fileViewerParams$$.value);
  }

  private initEventListeners(): void {
    this.timer.pipe(take(1)).subscribe(() => {
      const elem = document.querySelectorAll('.oog-file-viewer__content');
      if (elem.length) {
        elem[0].addEventListener('touchstart', (e: TouchEvent) => {
          if (e.touches.length === 2) {
            this.touchStartDist = Math.hypot(
              e.touches[0].pageX - e.touches[1].pageX,
              e.touches[0].pageY - e.touches[1].pageY,
            );
          }

          if (e.touches.length === 1) {
            const currentTime = new Date().getTime();
            const tapLength = currentTime - this.lastTap;

            if (tapLength < 500 && tapLength > 0) {
              this.inZoom ? this.decreaseZoom() : this.increaseZoom();
            }
            this.lastTap = currentTime;
          }
        });

        elem[0].addEventListener('touchmove', (e: TouchEvent) => {
          if (e.touches.length === 2) {
            const dist = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
            const newZoomValue =
              this.fileViewerHelperService.returnCurrentZoom() !== 0
                ? this.fileViewerHelperService.returnCurrentZoom() * (dist / this.touchStartDist)
                : (50 * dist) / this.touchStartDist;
            this.fileViewerHelperService.setZoom(newZoomValue);
          }
        });
      }
    });
  }

  private increaseZoom(): void {
    this.fileViewerHelperService.setZoom(zoomSettings.increased);
    this.inZoom = true;
  }

  private decreaseZoom(): void {
    this.fileViewerHelperService.setZoom(zoomSettings.default);
    this.inZoom = false;
  }
}
