import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SelectTypeModel } from '@models/dictionaries/select-type.model';

import { BaseControl } from '../control-base';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends BaseControl implements OnInit, OnDestroy {
  @Input() public options: SelectTypeModel[] = [];
  @Input() public disabled = false;
  @Input() public boldOptions = false;

  @Input() public showClearButton = false;
  @Input() public showClearOption = false;
  @Input() public controlStatus = false;

  @Output() public selectItem: EventEmitter<SelectTypeModel> = new EventEmitter<SelectTypeModel>();

  public activeOption = false;
  public showLabel = '';

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private elementRef: ElementRef) {
    super();
  }

  @HostListener('document:click', ['$event.target'])
  public hideSelect(event: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(event)) {
      this.activeOption = false;
    }
  }

  public ngOnInit(): void {
    this.setShowLabel();
    this.subscribeToSelect();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Переключает видимость дропдауна */
  public toggleOptions(): void {
    if (!this.disabled) {
      this.activeOption = !this.activeOption;
    }
  }

  /** Выбор значения из списка */
  public selectOption(value: SelectTypeModel): void {
    this.setControlValue(value);
    this.showLabel = value.name;
    this.activeOption = false;
    this.selectItem.emit(value);
  }

  private subscribeToSelect(): void {
    const control = this.parentFormGroup.controls[this.controlName];
    control.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => (this.showLabel = data?.name || ''));
  }

  /** Установить значение контрола */
  private setControlValue(value: SelectTypeModel): void {
    const control = this.parentFormGroup.controls[this.controlName];
    control.setValue(value);
    control.markAsDirty();
  }

  private setShowLabel(): void {
    const control = this.parentFormGroup.controls[this.controlName];
    this.showLabel = control.value?.name;
  }

  public clearControl(): void {
    this.parentFormGroup?.controls?.[this.controlName]?.reset();
  }

  public clearOption(): void {
    this.clearControl();
    this.showLabel = '';
    this.activeOption = false;
    this.selectItem.emit(undefined);
  }
}
