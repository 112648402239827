import { Pipe, PipeTransform } from '@angular/core';

/** Пайп преобразует таймер из 1,2,3... в формат мм:сс (ex. 00:01) */
@Pipe({
  name: 'timerFormatter',
})
export class TimerFormatPipe implements PipeTransform {
  public transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}
