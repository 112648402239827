import { InputLoginComponent } from './input-login/input-login.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { InputSearchingAndChoosingComponent } from './input-searching-and-choosing/input-searching-and-choosing.component';
import { InputComponent } from './input/input.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { SimpleButtonComponent } from './simple-button/simple-button.component';

export const Controls = [
  InputComponent,
  InputLoginComponent,
  InputPasswordComponent,
  InputSearchComponent,
  SimpleButtonComponent,
  RadioButtonComponent,
  InputSearchingAndChoosingComponent,
];
