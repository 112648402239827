import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';

import { PersonalFolder } from '../../models/folders/personal-folders.model';
import { FoldersInteropService } from '../../rest/folders-interop.service';

@Injectable({ providedIn: 'root' })
export class FoldersStateService {
  private folders$ = new BehaviorSubject<PersonalFolder[]>(null);

  private selectedFolders$ = new BehaviorSubject<PersonalFolder[]>(null);

  private clickedFolder$ = new BehaviorSubject<PersonalFolder>(null);

  private moveToFolderState$ = new BehaviorSubject<boolean>(false);

  private editMode$ = new BehaviorSubject<boolean>(false);

  private folderToView$ = new BehaviorSubject<PersonalFolder>(null);

  constructor(private loggedUserService: AuthUserIsproStoreService, private foldersApi: FoldersInteropService) {}

  public getFoldersFromApi(preloaderFlag: boolean = false): Observable<PersonalFolder[]> {
    const loggedUser = this.loggedUserService.getCurrentUserSnapshot()?.id;
    // Если нет юзера, то запрос уходит с undefined вместо id
    if (!loggedUser) {
      return of([]);
    }
    return this.foldersApi.getUserFolders(loggedUser, preloaderFlag).pipe(tap((folders) => this.setFolders(folders)));
  }

  public editOrCreateNewFolder(folder: Partial<PersonalFolder>): Observable<void> {
    const loggedUser = this.loggedUserService.getCurrentUserSnapshot()?.id;
    return this.foldersApi.editOrCreateNewFolder({ ...folder, ownerId: loggedUser });
  }

  public setEditMode(mode: boolean): void {
    this.editMode$.next(mode);
  }

  public getEditMode(): Observable<boolean> {
    return this.editMode$;
  }

  public getEditModeSnapshot(): boolean {
    return this.editMode$.value;
  }

  public setFolders(folders: PersonalFolder[]): void {
    this.folders$.next(folders);
  }

  public getFolders(): Observable<PersonalFolder[]> {
    return this.folders$;
  }

  public getFoldersSnapshot(): PersonalFolder[] {
    return this.folders$.value;
  }

  public setMoveToFolderState(state: boolean): void {
    this.moveToFolderState$.next(state);
  }

  public getMoveToFolderState(): Observable<boolean> {
    return this.moveToFolderState$;
  }

  public getMoveToFolderStateSnapshot(): boolean {
    return this.moveToFolderState$.value;
  }

  public setSelectedFolders(folder: PersonalFolder[]): void {
    this.selectedFolders$.next(folder);
  }

  public getSelectedFolders(): Observable<PersonalFolder[]> {
    return this.selectedFolders$;
  }

  public getSelectedFoldersSnapshot(): PersonalFolder[] {
    return this.selectedFolders$.value;
  }

  public setClickedFolder(folder: PersonalFolder): void {
    this.clickedFolder$.next(folder);
  }

  public resetClickedFolder(): void {
    this.clickedFolder$.next(null);
  }

  public getClickedFolder(): Observable<PersonalFolder> {
    return this.clickedFolder$;
  }

  public getClickedFolderSnapshot(): PersonalFolder {
    return this.clickedFolder$.value;
  }

  public setFolderToView(folder: PersonalFolder): void {
    this.folderToView$.next(folder);
  }

  public getFolderToViewSnapshot(): PersonalFolder {
    return this.folderToView$.value;
  }
}
