import { Pipe, PipeTransform } from '@angular/core';
import { ReportsItemExtendedModel } from '@oogShared/models/reports/reports.model';

@Pipe({
  name: 'reportsSearchPipe',
})
/** Пайп отображающий отчеты согласно поисковому запросу */
export class ReportsSearchPipe implements PipeTransform {
  public transform(reports: ReportsItemExtendedModel[], searchValue: string): ReportsItemExtendedModel[] {
    if (!searchValue) {
      return reports;
    }

    return reports.filter((r) => r.file.fileName.toLowerCase().includes(searchValue.toLowerCase()));
  }
}
