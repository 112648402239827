/**
 * Фукнция-хэлпер имитирующая работу flatMap
 * Только с 12 ноды можно использовать flatMap. В пайплайнах версия ниже, поэтому есть необходимость в этой функции
 * Иначе, могут не проходить тесты
 *  */
export const flatMap = <T, R>(arrayOfArray: T[], callback: (el: T) => R[]): R[] => {
  const result = [];

  arrayOfArray.forEach((el) => {
    const callBackResult = callback(el);

    result.push(...callBackResult);
  });

  return result;
};
