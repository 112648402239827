import { CertificateItemModel } from '@models/esigns/certificate-item.model';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { EsignRequestModel } from '@oogShared/models/esign/esign-request.model';
import { EsignTransactionStatusModel } from '@oogShared/models/esign/esign-transaction-status.model';
import { SigningRequestModel } from '@oogShared/models/esign/signing-request.model';
import { ResolutionESignResponseType } from '@oogShared/type/resolution/resolution-e-sign-response.type';
import { OogEsignInteropService } from '@oogShared/rest/oog-esign-interop.service';
import { FileCertificateHelperService } from '@shared/services/file-certificate-helper.service';
import { from, interval, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { EmployeeSimpleModel } from '@oogShared/models/esign/employee-simple.model';
import { ExecutionModel } from '@oogShared/models/familiarization/execution.model';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { ModalService } from '@shared/services/modal.service';
import { ConfirmSignComponent } from '@components/confirm-sign/confirm-sign.component';
import { DepartmentBaseModel } from '@oogShared/models/resolutions/department/department-base.model';
import { PositionModel } from '@oogShared/models/resolutions/position.model';

const GET_STATUS_INTERVAL = 10000;

export abstract class ResolutionESignBaseService {
  private stopInterval$: Subject<void> = new Subject();

  constructor(
    protected oogEsignInteropService: OogEsignInteropService,
    protected currentUser: CurrentUserIsproStoreService,
    protected fileCertificateHelperService: FileCertificateHelperService,
    protected modalService: ModalService,
  ) {}

  /**
   * Метод для подписания
   *
   * @param model Модель с подписанными данными
   * @param certificate Сертификат
   * @param resolutionJson Данные
   * @param resolutionSignDssInfo Данные для подписанния
   * @returns Успешное подписание
   */
  public eSign(
    model: ExecutionModel | ResolutionModel | CreateResolutionModel,
    certificate: CertificateItemModel,
    resolutionJson: string,
    resolutionSignDssInfo: string,
  ): Observable<boolean> {
    if (!certificate) {
      throw new Error('Не удалось найти сертификат');
    }
    const req = certificate.isCloud
      ? this.signResolutionByCloud(resolutionSignDssInfo, resolutionJson, certificate)
      : this.signResolutionByLocal(resolutionJson, certificate);

    return req.pipe(
      tap(([data]) => {
        if (data) {
          model.esign = data;

          model.esign.isValid = true;
          model.esign.validError = null;
          model.esign.currentData = resolutionJson;
        }
      }),
      map(([_, close]) => close),
      catchError(() => of(false)),
      first(),
    );
  }

  public mapToEmployeeSimpleModel(employee: EmployeeModel): EmployeeSimpleModel | null {
    if (!employee) {
      return null;
    }

    const department = employee.department || ({} as DepartmentBaseModel);
    const position = employee.position || ({} as PositionModel);

    return {
      id: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
      middleName: employee.middleName,
      department: {
        id: department.id,
        fullName: department.fullName || department.name,
      },
      position: {
        id: position.id,
        fullName: position.fullName || position.name,
      },
    } as EmployeeSimpleModel;
  }

  protected signResolutionByCloud(
    resolutionSignDssInfo: string,
    resolutionJson: string,
    certificate: CertificateItemModel,
  ): Observable<ResolutionESignResponseType> {
    let modal: HTMLIonModalElement = null;
    this.modalService.showAnyModal(ConfirmSignComponent, 'confirm-cloud-sign', true).then((m) => (modal = m));

    const data: EsignRequestModel = this.mapSignData(resolutionSignDssInfo, resolutionJson, certificate);

    return this.oogEsignInteropService.createSign(data).pipe(
      // Если транзакция не удалась, завершить поток
      map((esignData) => {
        if (!esignData?.transactionId) {
          throw new Error();
        }
        return esignData;
      }),
      switchMap((esignData) => this.waitingSigning(esignData)),
      filter((value) => value.isFinal),
      tap(
        () => {
          this.stopInterval();
        },
        () => {
          this.stopInterval();
        },
      ),
      finalize(() => modal?.dismiss()),
      map((res: EsignTransactionStatusModel) => {
        if (!res) {
          throw Error('Ошибка подписания');
        }

        if (!res.signature) {
          return [null, false];
        }

        const eSign: SigningRequestModel = {
          certificateOwner: certificate.name,
          certificateNumber: certificate.serialNumber,
          certificateIssuer: certificate.certificateIssuer,
          validFrom: certificate.validFrom,
          validUntil: certificate.validUntil,
          creationDate: new Date(),
          data: resolutionJson,
          signed: res.signature,
          signer: this.currentUser.getCurrentUserSnapshot(),
        };

        return [eSign, true];
      }),
    );
  }

  protected signResolutionByLocal(
    resolution: string,
    certificate: CertificateItemModel,
  ): Observable<ResolutionESignResponseType> {
    return from(this.fileCertificateHelperService.writeToFileString(resolution)).pipe(
      map(
        (signed) =>
          [
            {
              certificateNumber: certificate.id,
              certificateOwner: certificate.name,
              certificateIssuer: certificate.certificateIssuer,
              validFrom: certificate.validFrom,
              creationDate: new Date(),
              validUntil: certificate.validUntil,
              data: resolution,
              signed,
              signer: this.currentUser.getCurrentUserSnapshot(),
            },
            true,
          ] as ResolutionESignResponseType,
      ),
      catchError(() => of([null, false] as ResolutionESignResponseType)),
    );
  }

  /**
   * серилизация JSON для подписания
   *
   * @private
   * @param curtailResolution
   */
  protected getJson(curtailResolution: Partial<ResolutionModel>): string {
    return JSON.stringify(curtailResolution);
  }

  protected prepareDssData(array: { key: string; value: string }[], dssFieldsNames: { [key: string]: string }): string {
    return array
      .map((i) => (!i.value ? { ...i, value: 'не указано' } : i))
      .map((i) => `${dssFieldsNames[i.key]}: ${i.value}`)
      .join('\r\n');
  }

  private waitingSigning(status: EsignTransactionStatusModel): Observable<EsignTransactionStatusModel> {
    return interval(GET_STATUS_INTERVAL).pipe(
      switchMap(() => this.oogEsignInteropService.getSignStatus(status.transactionId)),
    );
  }

  private mapSignData(
    resolutionSignDssInfo: string,
    resolutionJson: string,
    certificate: CertificateItemModel,
  ): EsignRequestModel {
    return {
      dataToSign: resolutionJson,
      documentName: resolutionSignDssInfo,
      certificate: {
        isDefault: certificate.isDefault,
        validUntil: certificate.validUntil,
        validFrom: certificate.validFrom,
        serialNumber: certificate.serialNumber,
        certificateIssuer: certificate.certificateIssuer,
        id: +certificate.id,
        dname: certificate.name,
      },
    };
  }

  private stopInterval(): void {
    this.stopInterval$.next();
    this.stopInterval$.complete();
  }
}
