import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { DischargeOfDutiesModalEnum } from '@oogShared/enums/discharge-of-duties-modal.enum';
import { DischargeOfDutiesModel } from '@oogShared/models/discharge-of-duties/discharge-of-duties.model';
import { ResponseModalModel } from '@oogShared/models/discharge-of-duties/response-modal.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { DischargeOfDutiesInteropService } from '@oogShared/rest/discharge-of-duties-interop.service';
import { SettingsState } from '@store/settings/settings.state';
import { ModalDischargeOfDutiesComponent } from '../../../document-forms/components/modal-discharge-of-duties/modal-discharge-of-duties.component';

@Injectable({
  providedIn: 'root',
})
export class DischargeDutiesService {
  constructor(
    private dischargeService: DischargeOfDutiesInteropService,
    private modalCtrl: ModalController,
    private store: Store,
  ) {}

  /** Найти ИО сотрудника, которого выбрали */
  public dischargeOfDuties(
    form: FormGroup,
    id: number | undefined,
    formArray: FormArray,
    index: number,
    controlName: string,
  ): void {
    if (!id) {
      return;
    }

    this.dischargeService
      .listOfDuties(id)
      .pipe(
        take(1),
        filter((r) => !!r.length),
      )
      .subscribe((r: DischargeOfDutiesModel[]) => {
        const subActing = this.store.selectSnapshot(SettingsState.settingsSubstitute).autoCorrectSubActingName;
        if (r.length > 1 || !subActing) {
          this.showModalDuties(form, r, formArray, index, controlName).then();
          return;
        }
        this.applyReposponseModelWithAutoChangeFioSettings(form, r[0], formArray, index, controlName);
      });
  }

  /** Применить если есть настройки "замена фио исполнителя по-умолчанию" */
  private applyReposponseModelWithAutoChangeFioSettings(
    form: FormGroup,
    performer: DischargeOfDutiesModel,
    formArray: FormArray,
    index: number,
    controlName: string,
  ): void {
    const data = { status: DischargeOfDutiesModalEnum.change, performer: performer.employeeTo || null };
    this.applyResponseModal(data, form, formArray, index, controlName);
  }

  /** Отобразить модальное окно со списком ИО */
  private async showModalDuties(
    form: FormGroup,
    performers: DischargeOfDutiesModel[],
    formArray: FormArray,
    index: number,
    controlName: string,
  ): Promise<void> {
    const modal = this.modalCtrl.create({
      componentProps: {
        listDuties: performers,
      },
      component: ModalDischargeOfDutiesComponent,
      cssClass: 'custom-background',
      animated: false,
      backdropDismiss: false,
    });
    modal.then((m) => m.present());
    const { data } = await modal.then((m) => m.onWillDismiss());
    this.applyResponseModal(data, form, formArray, index, controlName);
  }

  private applyResponseModal(
    data: ResponseModalModel,
    form: FormGroup,
    formArray: FormArray,
    index: number,
    controlName: string,
  ): void {
    switch (data?.status) {
      case DischargeOfDutiesModalEnum.current:
        break;
      case DischargeOfDutiesModalEnum.change:
        form.controls[controlName].setValue(data.performer);
        this.checkForClone(form, formArray, data.performer, controlName);
        break;
      case DischargeOfDutiesModalEnum.cancel:
        formArray.removeAt(index);
        break;
    }
  }

  private checkForClone(
    performerGroup: FormGroup,
    performerGroups: FormArray,
    performer: EmployeeModel,
    controlName: string,
  ): void {
    const performers = performerGroups.controls.map((p: FormGroup) => p.controls[controlName]);
    const performerClones = performers.filter((p) => p.value?.id === performer?.id);
    if (performerClones.length > 1) {
      performerGroup.addControl('clone', new FormControl(true));
      return;
    }
    performerGroup.removeControl('clone');
  }
}
