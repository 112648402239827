export class OpenContextMenuInButtonsDecision {
  public static readonly type = '[Visibility context menu in buttons decision] Open';
}

export class CloseContextMenuInButtonsDecision {
  public static readonly type = '[Visibility context menu in buttons decision] Close';
}

export class ToggleContextMenuInButtonsDecision {
  public static readonly type = '[Visibility context menu in buttons decision] Toggle';
  constructor(public value: boolean) {}
}
