import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ModalController, NavController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { SettingsState } from '@store/settings/settings.state';
import { NewFolderModalModel } from '../../models/folders/new-folder-modal.model';
import { FoldersStateService } from '../../services/folders/folders-state.service';
import { EditCreateFolderComponent } from '../folder-modals/new-folder/edit-create-folder.component';
import { routeNames } from '../../../../app-routing/route-names.const';

/** Компонент для отображения левого меню */
@Component({
  selector: 'app-side-bar-folders',
  templateUrl: './side-bar-folders.component.html',
  styleUrls: ['./side-bar-folders.component.scss'],
})
export class SideBarFoldersComponent {
  @Input() public disableButtons = false;

  @Select(SettingsState.editFolders)
  public editFolders$: Observable<boolean>;

  public namePage = 'Личные папки';

  public editMode$ = this.foldersState.getEditMode();

  public folders$ = this.foldersState.getFolders();

  public loggedUserId = this.loggedUserService.getCurrentUserSnapshot()?.id;

  constructor(
    private navController: NavController,
    private modalController: ModalController,
    private loggedUserService: AuthUserIsproStoreService,
    private foldersState: FoldersStateService,
  ) {}

  public previousPage(): void {
    this.navController.navigateBack([routeNames.mainScreen]).then();
    this.foldersState.resetClickedFolder();
  }

  public async createNewFolder(): Promise<void> {
    const modal = await this.modalController.create({
      componentProps: { title: 'Новая папка' },
      backdropDismiss: false,
      component: EditCreateFolderComponent,
      cssClass: 'modal-new-folder',
    });

    await modal.present();

    await modal.onDidDismiss().then((res: NewFolderModalModel) => {
      if (res.data.name) {
        this.foldersState
          .editOrCreateNewFolder({ ownerId: this.loggedUserId, name: res.data.name })
          .pipe(
            switchMap(() => this.foldersState.getFoldersFromApi()),
            first(),
          )
          .subscribe();
      }
    });
  }

  public toggleEditingMode(): void {
    this.foldersState.setEditMode(!this.foldersState.getEditModeSnapshot());
  }

  public doRefresh(event: any): void {
    this.foldersState
      .getFoldersFromApi()
      .pipe(first())
      .subscribe(() => {
        event.target.complete();
      });
  }
}
