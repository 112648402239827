import { Route, RoutePoint } from '@npaShared/models/route/route.models';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';

import { getAllSidePointsOfPoint } from './point-helper';

/** получить точки текущего пользователя */
export const getPointsCurrentUser = (route: Route, currentUser: UserInfoResponseModel): RoutePoint[] => {
  const userPoints = [];

  route?.route?.phases?.forEach((arrayPhases) =>
    arrayPhases?.forEach((phase) => {
      phase.iterations.forEach((i) =>
        i.subPhases.forEach((sp) =>
          sp.points.forEach((pointsArray) => {
            pointsArray.forEach((rootPoint) => {
              const sidePoints = getAllSidePointsOfPoint(rootPoint);
              [rootPoint, ...sidePoints].forEach((point) => {
                if (currentUser?.currentUser?.employee?.id === point?.approver?.id) {
                  userPoints.push(point);
                }
              });
            });
          }),
        ),
      );
    }),
  );

  return userPoints;
};
