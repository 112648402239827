import { Pipe, PipeTransform } from '@angular/core';

import { ApprovalModel } from '@oogShared/models/approval-list/approval.model';
import { OogUserShortNamePipe } from '@shared/pipes/oog-user-short-name/oog-user-short-name.pipe';

import { ActingTypeEnum } from '../../enums/main-screen/acting-type-names.enum';

@Pipe({
  name: 'behalfOfDisplayApproval',
})
export class BehalfOfDisplayApprovalPipe implements PipeTransform {
  constructor(private shortNamePipe: OogUserShortNamePipe) {}

  public transform(value: string, data: ApprovalModel): string {
    return this.getResolutionActingName(value, data);
  }

  private getResolutionActingName(value: string, data: ApprovalModel): string {
    const participant = data.initiatorParticipant;
    switch (participant.whoGaveRightsType) {
      case ActingTypeEnum.fromName:
        return `${value} / ${this.shortNamePipe.transform(participant.whoGaveRights)}`;
      case ActingTypeEnum.assistant:
        return `${value} / ${this.shortNamePipe.transform(participant.whoGaveRights)}`;
      case ActingTypeEnum.dischargeOfDuties:
        return participant.whoGaveRightsTemplateSignature;
      default:
        return value;
    }
  }
}
