import { DocumentProhibitionStatus } from '@npaShared/enums/document-prohibition-status.enum';
import { getActualDocumentVersion } from '@npaShared/helpers/document-version.helper';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';

/** фильтрация пустых черновиков перед совершением какого-либо действия с ПД */
export const filteringBlankDocuments = (
  documents: DocumentInfoResponseModel[],
  phaseId: number,
): DocumentInfoResponseModel[] =>
  documents
    .filter((doc) => Boolean(doc))
    .filter((doc) => {
      const actualVersion = getActualDocumentVersion(doc.versions, phaseId);
      return actualVersion && !doc.isBlank;
    });

/** фильтрация документов для подписания - документы доступные для текущего пользователя  */
export const filteringDocumentsAvailableToCurrentUser = (
  documents: DocumentInfoResponseModel[],
): DocumentInfoResponseModel[] =>
  documents.filter((doc) => Boolean(doc)).filter((doc) => doc.prohibitionStatus !== DocumentProhibitionStatus.CLOSED);

/** поиск первого доступного документа */
export const firstAvailableDocument = (documents: DocumentInfoResponseModel[]): DocumentInfoResponseModel | null =>
  documents.find((doc) => doc.prohibitionStatus !== DocumentProhibitionStatus.CLOSED) || null;
