import { ModalConnectionComponent } from '@components/modal-connection/modal-connection.component';
import { ModalUpdateNotificationComponent } from '@components/modal-update-notification/modal-update-notification.component';

import { ConfirmFamiliarizationEsignComponent } from './confirm-familiarization-esign/confirm-familiarization-esign.component';
import { ConfirmSignComponent } from './confirm-sign/confirm-sign.component';
import { DocumentDetailsHeaderComponent } from './document-details-header/document-details-header.component';
import { DocumentSearchComponent } from './document-search/document-search.component';
import { FilterOnMainScreenComponent } from './filter-on-main-screen/filter-on-main-screen.component';
import { FooterWithArrowComponent } from './footer-with-arrow/footer-with-arrow.component';
import { CustomSpinnerComponent } from './loading/custom-spinner/custom-spinner.component';
import { MessageNoResultSearchAndFilterComponent } from './message-no-result-search-and-filter/message-no-result-search-and-filter.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { RadioItemFilterComponent } from './radio-group-buttons/filter-item/radio-item-filter.component';
import { RadioGroupButtonsComponent } from './radio-group-buttons/radio-group-buttons.component';
import { RollArrowComponent } from './roll-arrow/roll-arrow.component';
import { ScrollArrowsComponent } from './scroll-arrows/scroll-arrows.component';
import { TemplateModalWithIconAlarmComponent } from './template-modal-with-icon-alarm/template-modal-with-icon-alarm.component';
import { TemplateModalComponent } from './template-modal/template-modal.component';
import { TitlePageComponent } from './title-page/title-page.component';
import { UpdateNotificationComponent } from './update-notification/update-notification.component';
import { UserCardComponent } from './user-card/user-card.component';
import { UserGuidePageComponent } from './user-guide-page/user-guide-page.component';

export const Components = [
  TitlePageComponent,
  DocumentSearchComponent,
  DocumentDetailsHeaderComponent,
  FooterWithArrowComponent,
  ScrollArrowsComponent,
  RollArrowComponent,
  FilterOnMainScreenComponent,
  RadioGroupButtonsComponent,
  RadioItemFilterComponent,
  UserCardComponent,
  MessageNoResultSearchAndFilterComponent,
  ModalErrorComponent,
  UserGuidePageComponent,
  ConfirmFamiliarizationEsignComponent,
  ModalConnectionComponent,
  TemplateModalComponent,
  UpdateNotificationComponent,
  ConfirmSignComponent,
  TemplateModalWithIconAlarmComponent,
  ModalUpdateNotificationComponent,
  CustomSpinnerComponent,
];
