import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetDictionariesSpecialEmployee } from '@store/dictionaries/dictionaries-special-employee/dictionaries-special-employee.action';

import { SpecialEmployeeModel } from '../../../../oog/shared/models/dictionaries/special-employee.model';
import { DictionariesInteropService } from '../../../../oog/shared/rest/dictionaries-interop.service';

@Injectable()
@State<SpecialEmployeeModel[]>({
  name: 'dictionariesSpecialEmployee',
  defaults: [],
})
export class DictionariesSpecialEmployeeState {
  constructor(private dictionariesInterop: DictionariesInteropService) {}

  @Selector()
  public static specialEmployees(state: SpecialEmployeeModel[]): SpecialEmployeeModel[] {
    return state;
  }

  @Action(SetDictionariesSpecialEmployee)
  public setDictionariesSpecialEmployee({ getState, setState }: StateContext<SpecialEmployeeModel[]>): void {
    if (getState().length) {
      return;
    }
    this.dictionariesInterop.getSpecialEmployees().pipe(first()).subscribe(setState);
  }
}
