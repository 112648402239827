import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SettingName } from '@npaShared/enums/setting-name.enum';
import { UserSettingDto } from '@npaShared/models/user-setting.model';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsStoreService {
  private settings$ = new BehaviorSubject([]);

  public setSettings(settings: UserSettingDto[]): void {
    this.settings$.next(settings);
  }

  public getSettings(): Observable<UserSettingDto[]> {
    return this.settings$.asObservable();
  }

  public getSettingsSnapshot(): UserSettingDto[] {
    return this.settings$.getValue();
  }

  public canApproveWithoutEsign(): boolean {
    return this.getBooleanValueByUserSetting(SettingName.SEND_BUTTON);
  }

  public canGetFinalUser(): boolean {
    return this.getBooleanValueByUserSetting(SettingName.LEGAL_ACT_FINAL_USER);
  }

  private getBooleanValueByUserSetting(settingParam: SettingName): boolean {
    const userSettings = this.getSettingsSnapshot();
    const requiredSetting = userSettings.find((setting) => setting.code === settingParam);
    if (!requiredSetting) {
      return false;
    }
    return requiredSetting.booleanValue;
  }
}
