import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { finalize, first } from 'rxjs/operators';

import { CheckboxThemeEnum } from '@enums/checkbox-theme.enum';
import { ModalController } from '@ionic/angular';
import { SelectTypeModel } from '@models/dictionaries/select-type.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';

import { defaultValueUrgency } from '@oogShared/consts/default-value-urgency.const';
import { changeUserSession } from '@oogShared/functions/change-user-session.function';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { UrgencyReviewTypeModel } from '@oogShared/models/resolutions/urgency-review-type.model';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { ConfirmDialogService } from '@oogShared/services/confirm-dialog.service';
import { DischargeDutiesService } from '@oogShared/services/discharge-of-duties/discharge-duties.service';
import { EsignOogService } from '@oogShared/services/esign-oog.service';
import { FormRedirectResolutionService } from '@oogShared/services/forms/form-redirect-resolution.service';
import { DictionariesTemplateState } from '@store/dictionaries/dictionaries-templates/dictionaries-template.state';
import { Observable } from 'rxjs';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { RestoreResolutionFileService } from '@oogShared/services/forms/restore-resolution-file.service';
import { RedirectResolutionDraftService } from '@oogShared/services/forms/draft/redirect-resolution-draft.service';
import { FormModalService } from '@oogShared/services/forms/form-modal.service';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { DictionaryTemplateTypes } from '@enums/dictionary-template-types/dictionary-template-types.enums';
import { AudioFormService } from '../audio-form/services/audio-form.service';
import { GraphicFormService } from '../graphic-form/services/graphic-form.service';
import { GraphicHelperService } from '../graphic-form/services/graphic-helper.service';
import { RedirectFormBase } from '../redirect-form-base';

@UntilDestroy()
@Component({
  selector: 'app-redirect-resolution',
  templateUrl: './redirect-resolution.component.html',
  styleUrls: ['./redirect-resolution.component.scss'],
})
export class RedirectResolutionPage extends RedirectFormBase implements OnInit, AfterViewInit {
  @Input() public draft: CreateResolutionModel | null = null;

  @Select(DictionariesTemplateState.templates([DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.heading]))
  public templates$!: Observable<SelectTypeModel[]>;

  public checkboxTheme = CheckboxThemeEnum;

  constructor(
    dialog: ConfirmDialogService,
    formRedirect: FormRedirectResolutionService,
    dictionariesInterop: DictionariesInteropService,
    modalCtrl: ModalController,
    store: Store,
    currentUser: CurrentUserIsproStoreService,
    dutiesService: DischargeDutiesService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private graphicHelperService: GraphicHelperService,
    private graphicFormService: GraphicFormService,
    private audioFormService: AudioFormService,
    private esignService: EsignOogService,
    private cardService: ResolutionInteropService,
    private restoreFileService: RestoreResolutionFileService,
    private restoreFormService: RedirectResolutionDraftService,
    private formModalService: FormModalService,
  ) {
    super(store, currentUser, formRedirect, dialog, dictionariesInterop, dutiesService, modalCtrl);
  }

  public ngOnInit(): void {
    changeUserSession(this.store, this.currentUser);
    this.userInfo();
    this.headerData = this.formRedirect.mapRedirectFormHeader();
    this.initForm();
    this.subscribeToRedirectPicture();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public submitForm(): void {
    if (this.esignService.isInvalidCertificateSigningResolution()) {
      this.esignService.showModalInvalidCertificate();
      return;
    }

    if (this.audioFormService.getAudioFromStore()) {
      this.audioFormService
        .sendFile()
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.audioFormService.saveFileData(res, this.form);
          setTimeout(() => {
            this.formRedirect.redirectResolution();
          }, 100);
        });
      return;
    }

    if (this.form.controls?.picture?.value) {
      this.formRedirect.redirectResolution();
      return;
    }
    this.onlyFamiliarization() ? this.emptyPerformer() : this.formRedirect.redirectResolution();
  }

  /** Получить список адресатов */
  public getAddresseeArray(): FormArray {
    return this.form.get('addressee') as FormArray;
  }

  /** Вставить выбранного пользователя в форму */
  public chooseAddressee(addressee: EmployeeModel): void {
    const addresseesFormArray = this.form.controls.addressee as FormArray;
    addresseesFormArray.push(this.createAddresseeFormGroup(addressee, defaultValueUrgency));
    const employees = addresseesFormArray.value.map((p) => p.addressee).filter((p) => p.id === addressee.id);
    this.form.controls.performer.setValue(addressee);
    this.showChooseAddressee = false;

    this.setCloneControl(addresseesFormArray, employees);

    this.dutiesService.dischargeOfDuties(
      addresseesFormArray.controls[addresseesFormArray.length - 1] as FormGroup,
      addressee.id,
      addresseesFormArray,
      addresseesFormArray.length - 1,
      'addressee',
    );
  }

  /** Отследить переключение признака "Без исполнителей" */
  public handleExecutorSignChange(formGroup: FormGroup): void {
    const addressee = formGroup.controls.addressee as FormArray;
    addressee.controls.forEach((p: FormGroup) => {
      const executorSign = formGroup.controls.executorSign.value;
      p.controls.familiarization.setValue(executorSign);
      this.removeAndAddControls(p);
    });
  }

  /** Создать графическую форму */
  public createGraphicForm(edit: boolean): void {
    this.graphicHelperService.createGraphicForm(edit, this.headerData, this.form);
  }

  /** Удалить рисунок с формы */
  public async deletePicture(): Promise<void> {
    this.graphicHelperService.deletePicture(this.form);
  }

  /** Сохранить текст поручения в отдельный контрол формы, чтобы загрузить его когда удалили изображение */
  public saveCommissionText(value: string): void {
    this.graphicHelperService.saveCommissionText(value, this.form);
  }

  /** Инициализация формы */
  private initForm(): void {
    if (this.draft) {
      this.restoreFormFromDraft();
      return;
    }

    const hasProjectLetter = this.store.selectSnapshot(ResolutionState.getResolutionHasProjectLetter);
    const projectLetterAuthor = this.store.selectSnapshot(ResolutionState.getResolutionProjectLetterAuthor);

    this.form = this.fb.group({
      text: [''],
      performer: [''],
      executorSign: false,
      needAttention: false,
      addressee: this.fb.array([]),
      picture: [''],
      savedText: [''],
      fileId: [],
      resolutionKind: [null],
      hasProjectLetter: [hasProjectLetter || false],
      projectLetterAuthor: [projectLetterAuthor || null],
    });
  }

  /** Восстанавливает данные формы из черновика this.draft */
  private restoreFormFromDraft(): void {
    const mission = this.draft.missions[0];

    this.form = this.restoreFormService.restoreCommissionGroup(mission, this.draft?.fileResolutionId);

    if (this.draft?.fileResolutionId) {
      this.addFileToFormFromDraft();
    }
  }

  /** Создать группу контролов для добавления адресата */
  private createAddresseeFormGroup(addressee: EmployeeModel, urgency: UrgencyReviewTypeModel): FormGroup {
    const familiarization = this.form.controls.executorSign?.value;
    const group = this.fb.group({
      addressee: [addressee || ''],
      urgency: [urgency || ''],
      familiarization: [familiarization || false],
      notes: [''],
    });

    this.removeAndAddControls(group);

    return group;
  }

  /** Проверить, что в поручении исполнители только для ознакомления */
  private onlyFamiliarization(): boolean {
    const executorSign = this.form.controls.executorSign?.value;
    const emptyAddressee = this.getAddresseeArray().controls.filter((c) => !!c.get('familiarization').value);
    return !executorSign && emptyAddressee.length === this.getAddresseeArray().length;
  }

  private subscribeToRedirectPicture(): void {
    this.graphicFormService.submitForm$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.formRedirect.redirectResolution());
  }

  private addFileToFormFromDraft(): void {
    const card = this.store.selectSnapshot(ActiveCardState);

    this.formModalService.showLoading();

    this.cardService
      .showFile(this.draft?.fileResolutionId, card.appealMovementId)
      .pipe(
        first(),
        finalize(() => this.formModalService.dismissLoading()),
      )
      .subscribe((file) => this.restoreFileService.restoreFile(file, this.form));
  }
}
