import { Component, Input, OnInit } from '@angular/core';

import { TreeHelperService } from '@oogShared/components/resolution-tree/tree-helper.service';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectTypeModel } from '@models/dictionaries/select-type.model';
import * as _ from 'lodash';
import { ResolutionStatusEnum } from '@oogShared/enums/resolution-status/resolution-status.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-resolution-tree',
  templateUrl: './resolution-tree.component.html',
  styleUrls: ['./resolution-tree.component.scss'],
})
export class ResolutionTreeComponent implements OnInit {
  @Input() public tree: ResolutionModel[] = [];
  @Input() public titleText: string;

  public branchToggleId: number[] = [];
  public openedTree = true;
  public filterForm: FormGroup;
  public options: SelectTypeModel[] = [];
  public flatList: ResolutionModel[];
  public filteredList: ResolutionModel[];

  constructor(private treeHelper: TreeHelperService, private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.setHiddenTreeByDefault();

    this.initFilterForm();
  }

  public get isFiltered(): boolean {
    return this.filterForm?.controls['type'].value || this.filterForm?.controls['isExecute'].value;
  }

  public toggleBranch(data: { value: boolean; id: number }): void {
    this.setNewBranchIds(data.value, data.id);
    this.treeHelper.toggleTriggered.next();
  }

  private setNewBranchIds(value: boolean, id: number): void {
    if (!value) {
      this.branchToggleId = [...this.branchToggleId, id];
      return;
    }
    this.branchToggleId = this.branchToggleId.filter((b) => b !== id);
  }

  private setHiddenTreeByDefault(): void {
    this.tree?.forEach((t) => {
      if (t.depth === 0) {
        return;
      }
      this.branchToggleId = [...this.branchToggleId, t.id];
    });
  }

  private initFilterForm(): void {
    if (!this.tree) {
      return;
    }
    this.filterForm = this.fb.group({
      type: [null],
      isExecute: [null],
    });

    const fn = (list: ResolutionModel[]): ResolutionModel[] =>
      list.reduce((acc, el) => {
        if (!el.childResolutions || !el.childResolutions.length) {
          return [...acc, el];
        }

        return [...acc, el, ...fn(el.childResolutions)];
      }, []);

    this.flatList = fn(this.tree).map((res) => ({ ...res, depth: 0 }));
    this.options = _.uniqBy(
      this.flatList.map((res) => res.type),
      'id',
    );
    this.options.forEach((typeModel) => (typeModel.name = typeModel.name.toLowerCase()));

    this.filterForm.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((value) => value.type || value.isExecute),
      )
      .subscribe(() => this.filterList());
  }

  private filterList(): void {
    const { type, isExecute } = this.filterForm.value;

    this.filteredList = this.flatList.filter(
      (res) => (!type || res.type.id === type.id) && (!isExecute || res.status.id === ResolutionStatusEnum.executed),
    );
  }
}
