import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { WordCasesEnum } from '@enums/word-cases.enum';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ActionUrgencyEnum } from '@oogShared/enums/action-urgency/action-urgency.enum';
import { BehalfAccessTypeEnum } from '@oogShared/enums/behalf-access-type.enum';
import { ActingTypeEnum } from '@oogShared/enums/main-screen/acting-type-names.enum';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { QuestionMissionModel } from '@oogShared/models/resolutions/mission/question-mission.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { GroupDocumentsService } from '@oogShared/services/group-documents.service';
import { SwipeMenuService } from '@oogShared/services/swipe-menu.service';
import { EmployeeReplacedState } from '@store/main-screen/employee-replaced/employee-replaced.state';
import { textareaTextConsts } from '@oogShared/consts/textarea-text.consts';
import { ModalService } from '@shared/services/modal.service';
import { CacheImageService } from '@oogShared/services/cache-image.service';
import { combinedResolutionTypeIdsConst } from '@oogShared/consts/combined-resolution-type-ids.const';
import { ResolutionBase } from '../../../resolution-base';

@Component({
  selector: 'app-resolution-info-history-element',
  templateUrl: 'resolution-info-history-element.component.html',
  styleUrls: ['resolution-info-history-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/** Компонент, отвечающий за отображение элемента истории резолюций в списке */
export class ResolutionInfoHistoryElementComponent extends ResolutionBase implements OnInit {
  @Input() public resolution: ResolutionModel;
  @Input() public resolutionId = 0;
  @Input() public shownButtonToggleBranch = false;

  @Output() public toggleBranchEvent = new EventEmitter<{ value: boolean; id: number }>();

  public currentUser: EmployeeModel;

  // Пользак под которым залогинены в систему
  public loggedInUser: EmployeeModel;
  public employeeList: number;
  public actionUrgency = ActionUrgencyEnum;
  public actingTypeEnum = ActingTypeEnum;
  public behalfAccessTypeEnum = BehalfAccessTypeEnum;
  public wordCasesEnum = WordCasesEnum;
  public employeeReplaced = this.store.selectSnapshot(EmployeeReplacedState.replacedUser);

  public branchOpened = false;

  public questionMissionTextShow = false;
  public selectedQuestionMissionTextId: number = null;
  public arrQuestionMission: QuestionMissionModel[] = [];

  constructor(
    store: Store,
    cardService: ResolutionInteropService,
    router: Router,
    modalCtrl: ModalController,
    modalService: ModalService,
    groupDocuments: GroupDocumentsService,
    swipeMenu: SwipeMenuService,
    cacheImageService: CacheImageService,
    private userService: CurrentUserIsproStoreService,
    private isProUserService: AuthUserIsproStoreService,
  ) {
    super(router, store, modalCtrl, modalService, cardService, groupDocuments, swipeMenu, cacheImageService);
  }

  public get nowDate(): Date {
    return new Date();
  }

  public get employee(): EmployeeModel | null {
    return this.resolution ? this.resolution?.missions[0]?.addressee[0]?.employee : null;
  }

  public get mission(): MissionModel | null {
    return this.resolution ? this.resolution?.missions.slice(-1)[0] : null;
  }

  public ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUserSnapshot();
    this.loggedInUser = this.isProUserService.getCurrentUserSnapshot();
    this.employeeList = this.getEmployeeListSize();
    this.getCardInfoQuestion();
  }

  public daysCount(addressee: ResolutionAddresseeModel): Date | null {
    if (this.resolution && addressee.dueDate) {
      return new Date(addressee.dueDate);
    }
    return null;
  }

  public reportDate(addressee: ResolutionAddresseeModel): Date | null {
    if (this.resolution && addressee.reportDate) {
      return new Date(addressee.reportDate);
    }
    return null;
  }

  public dateExpired(addressee: ResolutionAddresseeModel): boolean | null {
    return this.daysCount(addressee) ? this.daysCount(addressee) < new Date() : false;
  }

  public shouldShowConfidentialComment(mission: MissionModel): boolean {
    return mission.addressee.some(
      (a) =>
        (a.employee?.id === this.loggedInUser?.id && mission.isCommentConfidential) ||
        this.resolution.author.id === this.loggedInUser?.id ||
        !mission.isCommentConfidential,
    );
  }

  public getPrivateCommentCap(resolutionTypeId?: number): string {
    if (!resolutionTypeId) {
      return '';
    }
    if (combinedResolutionTypeIdsConst.resolutionLikeTypeIds.includes(resolutionTypeId)) {
      return textareaTextConsts.hiddenCommissionText;
    }
    return textareaTextConsts.hiddenCommentText;
  }

  public toggleBranch(): void {
    this.branchOpened = !this.branchOpened;
    this.toggleBranchEvent.emit({ value: this.branchOpened, id: this.resolution.id });
  }

  public getCardInfoQuestion(): void {
    if (!this.resolution) {
      return;
    }

    const clonedCardInfoMissions: MissionModel[] = JSON.parse(JSON.stringify(this.resolution.missions));

    if (clonedCardInfoMissions?.length) {
      this.arrQuestionMission = this.getQuestionMissionResponse(clonedCardInfoMissions);

      return;
    }

    this.arrQuestionMission = [];
  }

  public toggleFullQuestionMissionText(event: MouseEvent, selectedId: number): void {
    event.stopPropagation();
    this.selectedQuestionMissionTextId = selectedId;
    this.questionMissionTextShow = !this.questionMissionTextShow;
  }

  public closeFullQuestionMissionText(): void {
    this.questionMissionTextShow = false;
  }

  private getEmployeeListSize(): number | null {
    return this.resolution ? this.resolution?.missions[0]?.addressee.length : null;
  }
}
