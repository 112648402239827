import { Component, Input } from '@angular/core';
import { delay, first } from 'rxjs/operators';

import { Platform } from '@ionic/angular';

import { BaseControl } from '@components/controls/control-base';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent extends BaseControl {
  @Input() public rows = 4;
  /** флаг выключения активности */
  @Input() public disabled = false;
  @Input() public title = '';
  @Input() public maxlength: number | undefined;

  constructor(private platform: Platform) {
    super();
  }

  /** убрали фокус с контрола */
  public onBlurAction(): void {
    this.trimControlValue();
  }

  public scrollIntoView(): void {
    const keyboardShow$ = this.platform.keyboardDidShow.pipe(
      first((ev) => ev['keyboardHeight'] > 0),
      delay(400),
      untilDestroyed(this),
    );

    keyboardShow$.subscribe(() => {
      document.activeElement.scrollIntoView();
    });
  }
}
