import { Injectable } from '@angular/core';
import { DateHelperConst } from '@const/date-helper.const';
import { ProjectEnum } from '@enums/project.enum';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ResolutionAddresseeTypeEnum } from '@oogShared/enums/resolution-addressee/resolution-addressee-type.enum';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { OogEsignInteropService } from '@oogShared/rest/oog-esign-interop.service';
import { SettingsCertificateService } from '@shared/services/certificates/settings-certificate.service';
import { DateHelperService } from '@shared/services/date-helper.service';
import { FileCertificateHelperService } from '@shared/services/file-certificate-helper.service';
import { ModalService } from '@shared/services/modal.service';
import { Observable } from 'rxjs';
import { eSignDssFieldsNames, getESignDssFieldsNames } from '../../consts/resolution-e-sign.const';
import { ResolutionESignBaseService } from './resolution-e-sign-base-service';

@Injectable({
  providedIn: 'root',
})
export class ResolutionESignService extends ResolutionESignBaseService {
  constructor(
    private settingsCertificateService: SettingsCertificateService,
    private dateHelperService: DateHelperService,
    oogEsignInteropService: OogEsignInteropService,
    currentUserIsproStoreService: CurrentUserIsproStoreService,
    fileCertificateHelperService: FileCertificateHelperService,
    modalService: ModalService,
  ) {
    super(oogEsignInteropService, currentUserIsproStoreService, fileCertificateHelperService, modalService);
  }

  public injectESign(resolution: ResolutionModel | CreateResolutionModel): Observable<boolean> {
    const certificate = this.settingsCertificateService.getDefaultCertificate(ProjectEnum.oog);
    const resolutionJson = this.getJson(this.curtailResolution(resolution));
    const resolutionSignDssInfo = this.getResolutionSignDssInfo(resolution);
    return this.eSign(resolution, certificate, resolutionJson, resolutionSignDssInfo);
  }

  /**
   * Упрощенная модель резолюции
   * Только данные для подписания
   *
   * @param resolution
   */
  public curtailResolution(resolution: ResolutionModel | CreateResolutionModel): Partial<ResolutionModel> {
    const author = this.mapToEmployeeSimpleModel(resolution.author);
    const missions = resolution.missions.map((m) => ({
      privateComment: m.privateComment ? m.privateComment : null,
      addressee: m.addressee
        .filter((a) => a.addresseeType.id !== ResolutionAddresseeTypeEnum.reviewer)
        .map((a) => ({
          employee: this.mapToEmployeeSimpleModel(a.employee),
        })),
    }));
    const childResolutions = (() => {
      const childResolutionsCount = resolution && resolution.childResolutions && resolution.childResolutions.length;
      if (!childResolutionsCount) {
        return null;
      }
      return resolution.childResolutions.map(this.curtailResolution.bind(this));
    })();

    return { author, missions, childResolutions } as Partial<ResolutionModel>;
  }

  /**
   * Формирует информацию о резолюции для отображения в myDSS
   *
   * @param resolution
   * @private
   */
  protected getResolutionSignDssInfo(resolution: ResolutionModel | CreateResolutionModel): string {
    const result = [];

    const resolutionDate = this.dateHelperService.dateFormatting(
      new Date().toLocaleString(),
      DateHelperConst.dateTimeSecFormat,
    );
    const signDate = this.dateHelperService.dateFormatting(new Date().toLocaleString(), DateHelperConst.dateFormat);

    const signTitle = `Подпись резолюции: ${resolution.number} от ${resolutionDate}`;
    const signFooter = `Дата отправки на подписание: ${signDate}`;
    const getFullName = (person: EmployeeModel): string => {
      if (!person) {
        return '';
      }
      return [person?.lastName, person?.firstName, person?.middleName].filter(Boolean).join(' ') || '';
    };

    const dssFieldsNames = getESignDssFieldsNames(resolution.type);
    const resolutionMapFn = (r: ResolutionModel | CreateResolutionModel): { key: string; value: string }[] =>
      [{ key: 'author', value: getFullName(r.author) }].concat(
        r.missions.reduce((acc, m) => {
          acc.push(...m.addressee.map((a) => ({ key: 'addressee', value: getFullName(a.employee) })));
          acc.push({ key: 'privateComment', value: m.privateComment });
          return acc;
        }, []),
      );

    const signBody = this.prepareDssData(resolutionMapFn(resolution), dssFieldsNames);
    const signSubBody = (resolution.childResolutions || []).map((child) =>
      this.prepareDssData(resolutionMapFn(child), eSignDssFieldsNames.resolutionLike),
    );

    result.push(signTitle, signBody, ...signSubBody, signFooter);

    return result.join('\r\n');
  }
}
