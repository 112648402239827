import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';

export class SetResolverDocuments {
  public static readonly type = '[Resolver Documents] set resolver documents';
  constructor(public docs: DocumentInfoResponseModel[]) {}
}

export class ResetResolverDocuments {
  public static readonly type = '[Resolver Documents] reset resolver documents';
}
