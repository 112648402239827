import { Pipe, PipeTransform } from '@angular/core';

import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';
import { EmployeeBaseModel } from '@npaShared/models/employee/employee-base.model';

@Pipe({
  name: 'aboutEmployeeNpa',
})
export class AboutEmployeeNpaPipe implements PipeTransform {
  public transform(employee: EmployeeBaseModel): SharedInfoAboutEmployee {
    return {
      firstName: employee?.firstName,
      lastName: employee?.lastName,
      middleName: employee?.patronymic || '',
      phone: employee?.phone || '',
      photoUrl: employee?.photoUrl || '',
      jobPosition: {
        name: employee?.jobPosition?.name || '',
      },
      organization: {
        name: employee?.organization?.name || '',
      },
    };
  }
}
