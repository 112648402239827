import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { catchError, concatMapTo, filter } from 'rxjs/operators';
import { ModalController, NavController, PickerController, PopoverController } from '@ionic/angular';
import { NetworkConnectionModel } from '@models/network-connection/network-connection.model';
import { NetworkConnectionInteropService } from '@rest/network-connection-interop.service';
import { CredentialStorageService } from '@shared/services/credential-storage.service';
import { ModalService } from '@shared/services/modal.service';
import { routeNames } from '../../app-routing/route-names.const';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public connectionToOogStatus$ = new BehaviorSubject<boolean>(true);
  public connectionToNpaStatus$ = new BehaviorSubject<boolean>(true);
  public readonly droppedConnectForOneOrBothSystems$ = new BehaviorSubject<boolean>(false);

  private interval = 5000;

  constructor(
    private networkConnection: NetworkConnectionInteropService,
    private credentials: CredentialStorageService,
    private modalService: ModalService,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private navController: NavController,
    private pickerController: PickerController,
  ) {}

  public startIntervalForCheckConnectionOogServer(): void {
    interval(this.interval)
      .pipe(
        filter(() => this.credentials.hasOogCredentials()),
        concatMapTo(this.checkConnectionForOogServer()),
      )
      .subscribe((response) => {
        response ? this.connectionToOogStatus$.next(true) : this.connectionToOogStatus$.next(false);
        this.checkDualUsersConnection();
      });
  }

  public startIntervalForCheckConnectionNpaServer(): void {
    interval(this.interval)
      .pipe(
        filter(() => this.credentials.hasNpaCredentials()),
        concatMapTo(this.checkConnectionForNpaServer()),
      )
      .subscribe((response) => {
        response ? this.connectionToNpaStatus$.next(true) : this.connectionToNpaStatus$.next(false);
        this.checkDualUsersConnection();
      });
  }

  public toggleModalDialog(connected: boolean): void {
    if (!connected) {
      this.modalService.showModalConnection()?.then(async (modal) => {
        modal?.onDidDismiss().then(async (eventDetail) => {
          this.modalService.modalConnectionExists = false;
          if (eventDetail.data) {
            await Promise.all([
              // закрываем 4 модалки метод modalController.getTop() не возвращает undefined, когда модальных окон больше нет, поэтому ставим ограничение в 4 окна
              this.closeFourOtherModal(() => this.modalController.getTop()),
              // закрываем 4 поповевера
              this.closeFourOtherModal(() => this.popoverController.getTop()),
              // закрываем 4 пикера
              this.closeFourOtherModal(() => this.pickerController.getTop()),
            ]);

            // Уходим на главный экран
            return this.navController.navigateBack(routeNames.mainScreen).then();
          }
        });
      });

      return;
    }

    this.modalService.checkOpenConnectionModel();
  }

  public checkConnectionForNpaServer(): Observable<NetworkConnectionModel | null> {
    return this.networkConnection.checkConnectionNpa().pipe(catchError(() => of(null)));
  }

  /** Метод проверяющий залогиненность пользаков и их соединения с бэком */
  private checkDualUsersConnection(): void {
    const oogUser = this.credentials.hasOogCredentials();
    const npaUser = this.credentials.hasNpaCredentials();

    /** Если залогинены в две системы и упало соединение к обеим системам - возвращаем true */
    if (oogUser && npaUser) {
      this.droppedConnectForOneOrBothSystems$.next(
        !(this.connectionToOogStatus$.value || this.connectionToNpaStatus$.value),
      );
      return;
    }

    /** Если залогинены в одной из систем и коннект есть - все ок */
    if ((oogUser && this.connectionToOogStatus$.value) || (npaUser && this.connectionToNpaStatus$.value)) {
      this.droppedConnectForOneOrBothSystems$.next(false);
      return;
    }

    /** Если залогинены в одной из систем и коннект с ней отвалился */
    this.droppedConnectForOneOrBothSystems$.next(true);
  }

  /** Метод закрывает 4 другие модальных окра, чтобы навигация корректно отработала */
  private async closeFourOtherModal(getTop: () => Promise<{ dismiss(): Promise<unknown> }>): Promise<void> {
    for (let i = 0; i < 4; i++) {
      const topModal = await getTop();
      await topModal?.dismiss();
    }
  }

  private checkConnectionForOogServer(): Observable<NetworkConnectionModel | null> {
    return this.networkConnection.checkConnectionOog().pipe(catchError(() => of(null)));
  }
}
