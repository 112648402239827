import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SelectCertificateThemeEnum } from '@enums/select-certificate-theme.enum';
import { CertificateItemModel } from '@models/esigns/certificate-item.model';

@Component({
  selector: 'app-select-certificate',
  templateUrl: './select-certificate.component.html',
  styleUrls: ['./select-certificate.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCertificateComponent),
      multi: true,
    },
  ],
})
export class SelectCertificateComponent implements ControlValueAccessor {
  @Input() public options: CertificateItemModel[] = [];
  @Input() public displayName = '';

  @Output() public certificateChoose = new EventEmitter<void>();

  @HostBinding('attr.theme')
  @Input()
  public theme: SelectCertificateThemeEnum;

  public activeOption = false;
  public value: CertificateItemModel = null;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public hideSelect(event: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(event)) {
      this.activeOption = false;
    }
  }

  public onChange = (_value: CertificateItemModel): any => {};

  public onTouch = (): any => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(value: CertificateItemModel): void {
    this.value = value;
    this.onChange(value);
  }

  /** Переключает видимость дропдауна */
  public toggleOptions(): void {
    this.activeOption = !this.activeOption;
  }

  /** Выбор значения из списка */
  public selectOption(value: CertificateItemModel): void {
    this.writeValue(value);
    this.activeOption = false;
    this.certificateChoose.emit();
  }
}
