import { Store } from '@ngxs/store';
import { DocumentPackageApiService } from '@npaApi/document-package-info.api.service';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { AgreementService } from '../../../../document-agreement/services/agreement.service';
import { BaseAgreements } from './base-agreements';
import { AgreementLuz } from './logic-agreement-luz';
import { AgreementSimple } from './logic-agreement-simple';
import { AgreementSendToRemarksToAuthor } from './logic-send-to-remarks-to-author';

export const AgreementFactory = (
  type: ApproveTypeEnum,
  agreementService: AgreementService,
  store: Store,
  documentPackageApi: DocumentPackageApiService,
): BaseAgreements => {
  switch (type) {
    case ApproveTypeEnum.approve:
      return new AgreementSimple(agreementService, store, documentPackageApi);
    case ApproveTypeEnum.approveLuz:
      return new AgreementLuz(agreementService);
    case ApproveTypeEnum.sendRemarksToAuthor:
      return new AgreementSendToRemarksToAuthor(agreementService);
  }
};
