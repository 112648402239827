import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfirmDialogComponent } from '../components/confirm/confirm-dialog/confirm-dialog.component';
import { GlobalConfirmDialogModel } from '../models/dialog/global-confirm-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  private dialogComponentRef!: ComponentRef<ConfirmDialogComponent>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
  ) {}

  public show(
    component: Type<ConfirmDialogComponent>,
    parent: string,
    dialog?: GlobalConfirmDialogModel,
  ): Observable<boolean> {
    const factory = this.resolver.resolveComponentFactory(component);
    this.dialogComponentRef = factory.create(this.injector);
    this.dialogComponentRef.instance.dialogInfo = dialog || {};
    this.applicationRef.attachView(this.dialogComponentRef.hostView);

    const domElem = (this.dialogComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    const parentElem = document.querySelector(parent);
    parentElem.insertBefore(domElem, parentElem.firstChild);

    return this.dialogComponentRef.instance.result.pipe(tap(() => this.close()));
  }

  /** уничтожаем инстанс диалоговой компоненты */
  public dialogDestroy(): void {
    if (!this.dialogComponentRef) {
      return;
    }
    this.applicationRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

  /** закрываем окно */
  private close(): void {
    this.dialogDestroy();
  }
}
