import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-details-header',
  templateUrl: './document-details-header.component.html',
  styleUrls: ['./document-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDetailsHeaderComponent {
  @Input() public title = '';
  @Input() public isOpen = false;
}
