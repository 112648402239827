import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mayorAgreementNumber',
})
export class MayorAgreementNumberPipe implements PipeTransform {
  public transform(id: number): string {
    if (!id) {
      return '';
    }
    return `№ ${id}`;
  }
}
