import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { DocumentPackageShortResponseModel } from '@npaShared/models/document-package/document-package-short.response.model';
import { DocumentPackagesModel } from '@npaShared/models/document-package/document-packages.model';
import {
  AppendDocumentPackages,
  ClearDocumentPackages,
  DeleteDocumentPackage,
  SetDocumentPackages,
  SetTotalCountDocumentPackages,
  UpdateDocumentInList,
} from './document-packages.action';

/** список пакетов документов */
@Injectable()
@State<DocumentPackagesModel>({
  name: 'documentPackages',
  defaults: {
    packages: [],
    totalCount: 0,
  },
})
export class DocumentPackagesState {
  public static getFirstPackage(): (state: DocumentPackagesModel) => DocumentPackageShortResponseModel | null {
    return createSelector([DocumentPackagesState], (state: DocumentPackagesModel) => {
      if (!state.packages.length) {
        return null;
      }

      return state.packages[0];
    });
  }

  public static getNextPackage(
    currentDP: DocumentPackageShortResponseModel,
  ): (state: DocumentPackagesModel) => DocumentPackageShortResponseModel | null {
    return createSelector([DocumentPackagesState], (state: DocumentPackagesModel) => {
      const nextIndex = state.packages.findIndex((dp) => dp.id === currentDP.id);

      if (nextIndex === state.packages.length - 1) {
        return null;
      }

      return state.packages[nextIndex + 1];
    });
  }

  public static getPrevPackage(
    currentDP: DocumentPackageShortResponseModel,
  ): (state: DocumentPackagesModel) => DocumentPackageShortResponseModel | null {
    return createSelector([DocumentPackagesState], (state: DocumentPackagesModel) => {
      const prevIndex = state.packages.findIndex((dp) => dp.id === currentDP.id);

      if (prevIndex === 0) {
        return null;
      }

      return state.packages[prevIndex - 1];
    });
  }

  /** Номер по счету текущего открытого ПД в общем списке */
  public static getNumberCurrentPackage(
    dp: DocumentPackageShortResponseModel,
  ): (state: DocumentPackagesModel) => number {
    return createSelector(
      [DocumentPackagesState],
      (state: DocumentPackagesModel) => state.packages.findIndex((i) => i.id === dp.id) + 1,
    );
  }

  /** получить список ПД */
  @Selector()
  public static getList(state: DocumentPackagesModel): DocumentPackageShortResponseModel[] {
    return state.packages;
  }

  /** Количество ПД в списке */
  @Selector()
  public static getQuantity(state: DocumentPackagesModel): number {
    return state.packages.length;
  }

  @Selector()
  public static getTotalCount(state: DocumentPackagesModel): number {
    return state.totalCount;
  }

  @Action(SetDocumentPackages)
  public setDocumentPackages(
    { patchState }: StateContext<DocumentPackagesModel>,
    { documents }: SetDocumentPackages,
  ): void {
    patchState({ packages: documents });
  }

  @Action(AppendDocumentPackages)
  public appendDocumentPackages(
    { patchState, getState }: StateContext<DocumentPackagesModel>,
    { documents }: AppendDocumentPackages,
  ): void {
    patchState({ packages: [...getState().packages, ...documents] });
  }

  @Action(ClearDocumentPackages)
  public clearDocumentPackages({ patchState }: StateContext<DocumentPackagesModel>): void {
    patchState({ packages: [] });
  }

  @Action(UpdateDocumentInList)
  public updatePackageInList(
    { getState, patchState }: StateContext<DocumentPackagesModel>,
    { dpId, updates }: UpdateDocumentInList,
  ): void {
    let dpList = getState().packages;

    if (!dpList.find((item) => item.id)) {
      return;
    }

    dpList = dpList.map((item) => {
      if (item.id === dpId) {
        return { ...item, ...updates };
      }
      return item;
    });

    patchState({ packages: dpList });
  }

  @Action(DeleteDocumentPackage)
  public deleteDocumentPackage(
    { patchState, getState }: StateContext<DocumentPackagesModel>,
    { dpId }: DeleteDocumentPackage,
  ): void {
    const packagesWithoutDeletedDP = getState().packages.filter((p) => p.id !== dpId);
    patchState({ packages: packagesWithoutDeletedDP });
  }

  @Action(SetTotalCountDocumentPackages)
  public setTotalDocumentPackagesCount(
    { patchState }: StateContext<DocumentPackagesModel>,
    { totalCount }: SetTotalCountDocumentPackages,
  ): void {
    patchState({ totalCount });
  }
}
