import { Pipe, PipeTransform } from '@angular/core';
import { ProjectGroupTypeEnum } from '@enums/project-group-type.enum';
import { OriginOivEnum } from '@npaShared/enums/document-package-origin-oiv.enum';
import { DocumentPackageShortResponseModel } from '@npaShared/models/document-package/document-package-short.response.model';

@Pipe({
  name: 'approvalPeriodHeader',
})
export class ApprovalPeriodHeaderPipe implements PipeTransform {
  public transform(value: DocumentPackageShortResponseModel): string {
    if (value?.projectType?.projectGroupType?.id === ProjectGroupTypeEnum.LOCAL_LEGAL_ACTS) {
      return 'Предельный срок согласования';
    }
    return value?.typeOriginOiv === OriginOivEnum.internal
      ? 'Плановый срок согласования'
      : 'Регламентный срок согласования';
  }
}
