import { Store } from '@ngxs/store';
import { DocumentPackageApiService } from '@npaApi/document-package-info.api.service';
import { RouteState } from '@npaCore/store/route/route.state';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { getPhasesForRequestOuterApprovement } from '@npaShared/functions/get-phases-for-request-outer-approvement';
import { getPointByIdWithAdditionalInfo } from '@npaShared/helpers/route/point-helper';
import { getSubphaseById } from '@npaShared/helpers/route/subphase-helper';
import { DocumentPackageApproveModel } from '@npaShared/models/document-package/document-package-approve.model';
import { DocumentPackageStoreModel } from '@npaShared/models/document-package/document-package-store.model';
import { SendToOuterApprovementRequestModel } from '@npaShared/models/document-package/send-to-outer-approvement.request.model';
import { ModalReturnConfirmationModel } from '@npaShared/models/modal/modal-return-confirmation.model';
import { Route } from '@npaShared/models/route/route.models';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ParamsForPreparationSigning } from '../agreement-sign.service';
import { AgreementService } from '../../../../document-agreement/services/agreement.service';
import { RoutePhaseTypes } from '../../../../document-agreement/enums/route-phase-types.enum';
import { BaseAgreements } from './base-agreements';

export class AgreementSimple implements BaseAgreements {
  constructor(
    protected agreementService: AgreementService,
    protected store: Store,
    protected documentPackageApi: DocumentPackageApiService,
  ) {}

  public getRequest(
    params: ParamsForPreparationSigning,
    paramsForRequest: DocumentPackageApproveModel,
  ): Observable<Route> {
    const route = this.store.selectSnapshot(RouteState.route);
    const pointWithAdditionalInfo = getPointByIdWithAdditionalInfo(route, paramsForRequest.pointId);
    const subPhase = getSubphaseById(route, pointWithAdditionalInfo.subPhaseId);

    // для руководителя на Проект создан Согласование должен вызваться запрос "Отправить на внешнее согласование"
    if (
      subPhase.phaseTypeId === RoutePhaseTypes.projectCreatedApprovement &&
      pointWithAdditionalInfo.pointRoleId === EmployeeRouteTypes.leader
    ) {
      return this.getRequestOnProjectCreated(route.route.id, paramsForRequest);
    }

    return this.agreementService.approveDocument(params.documentPackage.id, paramsForRequest);
  }

  public getParamsForModalConfirmation(
    selectedDocumentPackage: DocumentPackageStoreModel,
    description: string,
  ): ModalReturnConfirmationModel {
    return {
      approveType: ApproveTypeEnum.approve,
      documentPackageName: selectedDocumentPackage.documentPackage.documentPackageName,
      packageNumber: selectedDocumentPackage.documentPackageAdditionalInfo.packageNumber,
      redirectToFio: null,
      description,
    };
  }

  private getRequestOnProjectCreated(
    routeId: number,
    paramsForRequest: DocumentPackageApproveModel,
  ): Observable<Route> {
    return this.documentPackageApi.getOuterApprovementsInfo(routeId).pipe(
      switchMap((info) => {
        const data: SendToOuterApprovementRequestModel = {
          phases: getPhasesForRequestOuterApprovement(info),
          revisions: paramsForRequest.revisions,
          signature: paramsForRequest.signature,
          descriptionForNextApprovers: '',
          substitutionPointId: null,
        };

        return this.documentPackageApi.startOuterApprovement(routeId, data);
      }),
    );
  }
}
