import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'developmentReasonNumber',
})
export class DevelopmentReasonNumberPipe implements PipeTransform {
  public transform(id: number): string {
    if (!id) {
      return '';
    }
    return `Поручение № ${id}`;
  }
}
