import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'castToFormGroup',
})
export class CastToFormGroupPipe implements PipeTransform {
  public transform(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }
}
