import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AppealResolutionEditModel,
  AppealResolutionRedirectModel,
  ResolutionEditModel,
  ResolutionRedirectModel,
} from '@oogShared/models/forms/forms.model';
import { ResolutionFormModel } from '@oogShared/models/forms/resolution-form.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ResetForm } from '@store/forms/forms.action';

@Injectable()
@State<ResolutionFormModel | null>({
  name: 'forms',
})
export class FormsState {
  @Selector()
  public static getFormEditResolution(state: ResolutionFormModel): ResolutionEditModel {
    return state.resolutionForm.editData.model;
  }

  @Selector()
  public static getFormRedirectResolution(state: ResolutionFormModel): ResolutionRedirectModel {
    return state.resolutionForm.redirectData.model;
  }

  @Selector()
  public static getFormEditAppealResolution(state: ResolutionFormModel): AppealResolutionEditModel {
    return state.resolutionForm.appealEditData.model;
  }

  @Selector()
  public static getFormRedirectAppealResolution(state: ResolutionFormModel): AppealResolutionRedirectModel {
    return state.resolutionForm.appealRedirectData.model;
  }

  @Selector()
  public static cloneAddresseeRedirectResolution(state: ResolutionFormModel): boolean {
    if (!Array.isArray(state.resolutionForm.redirectData.model.addressee)) {
      return false;
    }
    const addressees = state.resolutionForm.redirectData.model.addressee;
    const clones = addressees.filter((a) => a.clone);
    return !!clones.length;
  }

  @Selector()
  public static cloneReviewersRedirectProject(state: ResolutionFormModel): boolean {
    const reviewers = state.resolutionForm.redirectData.model.reviewers;
    const clones = reviewers.filter((r) => r.clone);
    return !!clones.length;
  }

  @Selector()
  public static clonePerformerCreateResolution(state: ResolutionFormModel): boolean {
    const commissions = state.resolutionForm.editData.model.commissions;
    const performers = commissions.map((c) => c.performers).reduce((acc, val) => acc.concat(val), []);
    const clones = performers.filter((p) => p.clone);
    return !!clones.length;
  }

  @Selector()
  /**
   * @param state хранилище формы
   * @returns boolean проверяет наличие клонов исполнителей во всех поручениях
   * */
  public static clonePerformerCreateAppealResolution(state: ResolutionFormModel): boolean {
    // список вопросов
    const questions = state.resolutionForm.appealEditData.model.questions;
    // список поручений
    const commissions = questions.reduce((acc, val) => acc.concat(val.commissions), []);
    const performers = commissions.map((c) => c.performers).reduce((acc, val) => acc.concat(val), []);
    const clones = performers.filter((p) => p.clone);
    return !!clones.length;
  }

  @Selector()
  public static disabledByWhiteListAddressee(state: ResolutionFormModel): boolean {
    const addressees = state.resolutionForm.redirectData.model.addressee;
    if (!Array.isArray(addressees)) {
      return addressees.disabledByWhiteList;
    }
    return addressees.some((s) => s.addressee.disabledByWhiteList);
  }

  @Selector()
  public static disabledByWhiteListPerformer(state: ResolutionFormModel): boolean {
    const commissions = state.resolutionForm.editData.model.commissions;
    const performers = commissions.map((c) => c.performers).reduce((acc, val) => acc.concat(val), []);
    return performers.some((p) => p.performer.disabledByWhiteList);
  }

  @Selector()
  public static appealDisabledByWhiteListPerformer(state: ResolutionFormModel): boolean {
    const questions = state.resolutionForm.appealEditData.model.questions;
    const commissions = questions.reduce((acc, val) => acc.concat(val.commissions), []);
    const performers = commissions.map((c) => c.performers).reduce((acc, val) => acc.concat(val), []);
    return performers.some((p) => p.performer.disabledByWhiteList);
  }

  @Selector()
  public static isProjectWithAddressee(state: ResolutionFormModel): boolean {
    return !!(state.resolutionForm.redirectData.model.addressee as EmployeeModel);
  }

  @Selector()
  public static getAddresseeForRedirection(state: ResolutionFormModel): EmployeeModel {
    return state.resolutionForm.redirectData.model.addressee as EmployeeModel;
  }

  @Action(ResetForm)
  public resetForm({ setState }: StateContext<ResolutionFormModel>): void {
    setState(null);
  }
}
