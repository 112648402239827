import { Component } from '@angular/core';

import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent extends DialogBase {
}
