import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResetArrowState, SetLeftArrowState, SetRightArrowState } from '@store/menu/arrow-buttons/arrow-buttons.action';

import { ArrowButtonsModel } from '../../../../oog/shared/models/arrow-buttons/arrow-buttons.model';

@Injectable()
@State<ArrowButtonsModel>({
  name: 'arrowButtonsState',
  defaults: {
    arrowLeftActive: true,
    arrowRightActive: true,
  },
})
export class ArrowButtonsState {
  @Selector()
  public static getLeftArrowButtonsState(state: ArrowButtonsModel): boolean {
    return state.arrowLeftActive;
  }

  @Selector()
  public static getRightArrowButtonsState(state: ArrowButtonsModel): boolean {
    return state.arrowRightActive;
  }

  @Action(SetLeftArrowState)
  public setLeftArrowState({ patchState }: StateContext<ArrowButtonsModel>, { value }: SetLeftArrowState): void {
    patchState({ arrowLeftActive: value });
  }

  @Action(SetRightArrowState)
  public setRightArrowState({ patchState }: StateContext<ArrowButtonsModel>, { value }: SetRightArrowState): void {
    patchState({ arrowRightActive: value });
  }

  @Action(ResetArrowState)
  public resetArrowState({ patchState }: StateContext<ArrowButtonsModel>): void {
    patchState({ arrowRightActive: false, arrowLeftActive: false });
  }
}
