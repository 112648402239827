/** Константы для работы с датой */
export const DateHelperConst = {
  /** строковый формат даты */
  dateFormat: 'DD.MM.YYYY',
  datePipeFormat: 'dd.MM.yyyy',
  dateControlFormat: 'DD-MM-YYYY',
  dateUrgencyFormat: 'YYYY-MM-DD',
  datePipeUrgencyFormat: 'yyyy-MM-dd',
  /** маска контрола даты */
  dateMask: '00.00.0000',
  /** минимально возможная дата */
  minDate: '01.01.1900',
  /** фомат даты со временем до секунд */
  dateTimeSecFormat: 'DD.MM.YYYY HH:mm:ss',
  /** фомат времени с датой */
  timeDateFormat: 'HH:mm DD.MM.YYYY',
  /** формат даты dayjs MM/DD/YYYY */
  dateFormatDayjsL: 'L',

  dayjsParseFormat: 'DD-MM-YYYY HH:mm:ss',
};
