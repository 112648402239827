import { Directive, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { DictionariesHotEmployeeState } from '@store/dictionaries/dictionaries-hot-employee/dictionaries-hot-employee.state';
import { FormsState } from '@store/forms/forms.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { defaultValueUrgency } from '@oogShared/consts/default-value-urgency.const';
import { FormHeaderModel } from '@oogShared/models/forms-input/form-header.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { ConfirmDialogService } from '@oogShared/services/confirm-dialog.service';
import { DischargeDutiesService } from '@oogShared/services/discharge-of-duties/discharge-duties.service';
import { CommissionForSpread } from '@oogShared/models/resolutions/mission/mission-for-spread.model';
import { FormRedirectAppealResolutionService } from '@oogShared/services/forms/form-redirect-appeal-resolution.service';
import { QuestionModel } from '@oogShared/models/resolutions/question/question.model';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { RedirectResolutionDraftService } from '@oogShared/services/forms/draft/redirect-resolution-draft.service';
import { SelectUserTypesEnum } from '@oogShared/enums/select-user-types.enum';
import { FormHelper } from './form-helper';

@Directive()
export abstract class AppealRedirectFormBase extends FormHelper {
  @Input() public draft: CreateResolutionModel | null = null;

  @Select(DictionariesHotEmployeeState.getHotListEmployee)
  public hotListEmployee$!: Observable<EmployeeModel[]>;

  @Select(ResolutionState.getResolutionProjectCard)
  public resolution$!: Observable<ResolutionModel>;

  @Select(FormsState.cloneAddresseeRedirectResolution)
  public cloneAddressee$!: Observable<boolean>;

  @Select(FormsState.disabledByWhiteListAddressee)
  public disabledByWhitelist$!: Observable<boolean>;

  public user!: EmployeeModel;
  public headerData!: FormHeaderModel;
  public form: FormGroup = new FormGroup({});
  public userList: EmployeeModel[] = [];
  public commissionWithoutPerformers: number[] = [];
  public showChooseAddressee = false;

  public questionIds: number[] = [];
  public selectUserTypes = SelectUserTypesEnum;

  constructor(
    protected store: Store,
    protected currentUser: CurrentUserIsproStoreService,
    protected formRedirect: FormRedirectAppealResolutionService,
    protected dialog: ConfirmDialogService,
    protected dictionariesInterop: DictionariesInteropService,
    protected dutiesService: DischargeDutiesService,
    protected modalCtrl: ModalController,
    protected fb: FormBuilder,
    protected restoreFormService: RedirectResolutionDraftService,
  ) {
    super();
  }

  /** Уйти назад */
  public previousStep(): void {
    this.modalCtrl.dismiss().then();
  }

  /** Получить список пользователей по поиску */
  public getUserList(value: string): void {
    if (value.length < 3) {
      return;
    }
    const params = {
      search: value,
      whitelistEmployeeId: this.currentUser.getCurrentUserSnapshot()?.id,
      whitelistDepartmentId: this.currentUser.getCurrentUserSnapshot()?.department?.id,
    };

    this.dictionariesInterop
      .getUserList(params)
      .pipe(first())
      .subscribe((u) => (this.userList = u));
  }

  public getQuestionArray(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  public getCommissionByQuestion(question: AbstractControl): FormGroup {
    return question.get('commission') as FormGroup;
  }

  /** Очистить поручение */
  public clearCommission(question: AbstractControl): void {
    const commission = this.getCommissionByQuestion(question);
    commission.controls.text.reset();
  }

  /** Вставить предыдущий */
  public previousCommission(question: AbstractControl): void {
    const resolution = this.store.selectSnapshot(ResolutionState.getResolutionProjectCard);
    if (!resolution.missions?.length) {
      return;
    }

    const commission = this.getCommissionByQuestion(question);
    const text = commission.controls.text;
    text.setValue(resolution.missions[0].privateComment || '');
    commission.controls.savedText?.setValue(resolution.missions[0].privateComment);
  }

  /** Вставить из шаблона */
  public chooseCommissionTemplate(question: AbstractControl, value: string): void {
    const commission = this.getCommissionByQuestion(question);

    const text = commission.controls.text;
    text.setValue(`${text.value || ''} ${value}`);
    commission.controls.savedText?.setValue(`${text.value || ''} ${value}`);
  }

  public removeAndAddControls(formGroup: FormGroup): void {
    if (formGroup.controls.familiarization?.value) {
      this.removeControls(formGroup, ['notes']);
      formGroup.controls.urgency.setValue(defaultValueUrgency);
      return;
    }
    this.addControls(formGroup, [{ name: 'notes', value: '', validators: [] }]);
  }

  /** Получить список вопросов по резолюции */
  public getAllQuestions(): QuestionModel[] {
    const questions: QuestionModel[] = [];
    const currentUser = this.currentUser.getCurrentUserSnapshot();

    this.store.selectSnapshot(ResolutionState.getAllMissions)?.forEach((mission) => {
      const missionAddressee = mission.addressee.map((a) => a.employee?.id);
      if (!this.questionIds.includes(mission.questions[0].id) && missionAddressee?.includes(currentUser.id)) {
        questions.push(mission.questions[0]);
        this.questionIds.push(mission.questions[0].id);
      }
    });

    return questions.sort((a, b) => Number(a?.serialNumber) - Number(b?.serialNumber));
  }

  protected userInfo(): void {
    this.user = this.currentUser.getCurrentUserSnapshot();
  }

  /** Создать форму "вопросы" */
  protected createQuestionsArray(): FormGroup[] {
    const questions: QuestionModel[] = this.getAllQuestions();

    return questions.map((question) =>
      this.fb.group({
        question: [question],
        commission: this.draft ? this.restoreCommissionFromDraft(question) : this.createNewCommissionGroup(),
      }),
    );
  }

  protected createNewCommissionGroup(): FormGroup {
    return this.fb.group({
      text: [''],
      performer: [''],
      executorSign: false,
      needAttention: false,
      addressee: this.fb.array([]),
      picture: [''],
      savedText: [''],
      fileId: [],
      resolutionKind: [null],
    });
  }

  protected commissionsForSpreadExecutor(): CommissionForSpread[] {
    const questions = (this.form.get('questions') as FormArray).controls;

    const commissionsForSpread: CommissionForSpread[] = questions.map((question) => ({
      question: question.get('question').value,
    }));

    commissionsForSpread.shift();

    return commissionsForSpread;
  }

  private restoreCommissionFromDraft(question: QuestionModel): FormGroup {
    const mission = this.draft?.missions?.find((m) => m.questions[0]?.id === question.id);

    return this.restoreFormService.restoreCommissionGroup(mission);
  }
}
