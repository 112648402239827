import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';
import { Urls } from '@const/urls';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';
import { LinkFilesModel } from '@oogShared/models/resolutions/resolution/link-files.model';
import { CacheTimingState } from '@store/cache-timing/cache-timing.state';

@Injectable({ providedIn: 'root' })
/** Сервис для кэширования картинок для просмоторщика */
export class CacheImageService {
  constructor(private store: Store, private http: HttpClient) {}

  /** Получить текущий и 2 следующих документа */
  public async getActiveAndNextCards(): Promise<void> {
    const cards = this.store.selectSnapshot(DocumentsCardsState.getCurrentAndTwoNextCards);
    const appealIds = cards.map((c) => c.appealId);

    this.cachePages(appealIds);

    const appealMovementIds = cards.map((c) => c.appealMovementId);
    const result = await this.getFileIds(appealMovementIds).pipe(first()).toPromise();

    this.cacheImages(result);
  }

  /** Получить данные для формирования ссылок */
  private getFileIds(ids: number[]): Observable<LinkFilesModel[]> {
    const url = Urls.resolutions.links;
    return this.http.post<PaginationBaseModel<LinkFilesModel>>(url, ids).pipe(map((val) => val.data.content));
  }

  /** Кэширование картинок */
  private cacheImages(data: LinkFilesModel[]): void {
    const uniqueKey = this.store.selectSnapshot(CacheTimingState.getUniqueKey);
    data?.forEach((item) => {
      const image = new Image();
      image.onload = image.onerror = image.onabort = (): void => {
        image.remove();
      };
      image.src = `${Urls.resolutions.previewFile}/${item.storageFileId}/${item.fileId}?frl=${uniqueKey}`;
    });
  }

  /** Кэширование запроса pages */
  private cachePages(ids: number[]): void {
    ids.forEach((i) => {
      const uniqueKey = this.store.selectSnapshot(CacheTimingState.getUniqueKey);
      const url = `${Urls.resolutions.listImages(i)}?frl=${uniqueKey}`;
      this.http.get(url).pipe(first()).subscribe();
    });
  }
}
