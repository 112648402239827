/** Группа, в которую входит документ */
export enum DocumentGroupsEnum {
  /** Проект, приложение и пояснительная записка */
  main = 'MAIN',
  /** Согласования */
  additional = 'ADDITIONAL',
  /** Сопроводительные документы */
  accompanying = 'ACCOMPANYING',
  /** Документы для опубликования */
  oau = 'OAU',
  /** Раздел: "Документы для опубликования" для ЛПА */
  localLegalPublication = 'LOCAL_LEGAL_PUBLICATION',
  /** Оригиналы импортированных документов */
  source = 'SOURCE',
  /** Согласительные документы */
  conciliation = 'CONCILIATION',
  /** Сопроводительные материалы */
  accompanyingMaterials = 'ACCOMPANYING_MATERIALS',
}
