import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { Route, RoutePoint } from '@npaShared/models/route/route.models';
import { getPointsCurrentUser } from '@npaShared/helpers/route/get-points-current-user';

export abstract class RootActsLogic {
  constructor(private route: Route, private user: UserInfoResponseModel) {}

  public getUserPoints(): RoutePoint[] {
    return getPointsCurrentUser(this.route, this.user);
  }

  public getActivePointsList(): RoutePoint[] {
    throw new Error('not implemented logic');
  }
}
