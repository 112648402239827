import { DelegatingByUserInfoPipe } from './delegating-by-user-info.pipe';
import { DevelopmentReasonNumberPipe } from './development-reason-number.pipe';
import { DisplayingMessageAboutTaskCompletionUnavailablePipe } from './displaying-message-about-task-completion-unavailable.pipe';
import { DocumentInfoHeaderCountPipe } from './document-info-header-count.pipe';
import { EmployeeNamePipe } from './employee-name.pipe';
import { HighLightDocumentPackageIncludeSymbolsPipe } from './highlight-document-package-include-symbols.pipe';
import { HighLightEmployeeIncludeSymbolsPipe } from './highlight-employee-include-symbols.pipe';
import { IsTodayPipe } from './is-today.pipe';
import { MayorAgreementNumberPipe } from './mayor-agreement-number.pipe';
import { NpaDelegatingTypePipe } from './npa-delegating-type.pipe';
import { SelectFilterPipe } from './select-filter.pipe';
import { TextForConfirmActionPipe } from './text-for-confirm-action.pipe';

export const Pipes = [
  HighLightEmployeeIncludeSymbolsPipe,
  HighLightDocumentPackageIncludeSymbolsPipe,
  DevelopmentReasonNumberPipe,
  MayorAgreementNumberPipe,
  EmployeeNamePipe,
  NpaDelegatingTypePipe,
  DelegatingByUserInfoPipe,
  IsTodayPipe,
  SelectFilterPipe,
  DocumentInfoHeaderCountPipe,
  TextForConfirmActionPipe,
  DisplayingMessageAboutTaskCompletionUnavailablePipe,
];
