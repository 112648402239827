import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { performerType } from '@oogShared/consts/performer-type.const';
import { reviewerType } from '@oogShared/consts/reviewer-type.const';
import { FormHeaderEnum } from '@oogShared/enums/resolution-form/form-header.enum';
import { returnAuthUserSession } from '@oogShared/functions/return-user-session.function';
import { CreateChildResolutionModel } from '@oogShared/models/create-resolution/create-child-resolution.model';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { DossierResponseModel } from '@oogShared/models/dossier/dossier-response.model';
import { FormHeaderModel } from '@oogShared/models/forms-input/form-header.model';
import { AddresseeFormModel } from '@oogShared/models/forms/addressee-form.model';
import { AppealResolutionRedirectModel, ResolutionRedirectModel } from '@oogShared/models/forms/forms.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { ResolutionTypeModel } from '@oogShared/models/resolutions/resolution/resolution-type.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { ResetForm } from '@store/forms/forms.action';
import { FormsState } from '@store/forms/forms.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetDossier } from '@store/resolution-store/dossier/dossier.action';
import { DossierState } from '@store/resolution-store/dossier/dossier.state';
import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { filter, first } from 'rxjs/operators';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { DocumentNavigationService } from '../document-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class FormRedirectAppealResolutionService {
  constructor(
    private store: Store,
    private resolutionInterop: ResolutionInteropService,
    private currentUser: CurrentUserIsproStoreService,
    private modalCtrl: ModalController,
    private authUser: AuthUserIsproStoreService,
    private documentNavigationService: DocumentNavigationService,
  ) {}

  /** Смапить данные в модель шапки формы */
  public mapRedirectFormHeader(): FormHeaderModel {
    const date = this.store.selectSnapshot(ActiveCardState.dateResolution);
    const number = this.store.selectSnapshot(ActiveCardState.numberResolution);

    return {
      title: FormHeaderEnum.redirect,
      numberCardDocument: number,
      date,
    };
  }

  /** Перенаправить проект резолюции */
  public redirectResolution(questionIds: number[]): void {
    const resolution = this.createResolution();
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);

    this.resolutionInterop
      .createAppealResolution(resolution, card, questionIds)
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() => {
        returnAuthUserSession(this.store, this.currentUser, this.authUser);
        this.documentNavigationService.nextDocument();
        this.store.dispatch([ResetForm, ResetResolution, ResetDossier]);
        this.modalCtrl.dismiss().then();
      });
  }

  /** Смаппить данные в модель бэка при создании резолюции */
  private createResolution(): CreateResolutionModel {
    const resolution = JSON.parse(JSON.stringify(this.store.selectSnapshot(ResolutionState.getResolutionProjectCard)));
    const form = this.store.selectSnapshot(FormsState.getFormRedirectAppealResolution);

    return {
      author: this.currentUser.getCurrentUserSnapshot(),
      childResolutions: resolution.childResolutions ? this.childResolutionRedirect(resolution) : null,
      creationDate: new Date(),
      files: resolution.files,
      isDraft: resolution.isDraft,
      missions: this.missionsRedirect(form),
      number: resolution.number,
      options: {},
      parentResolutionId: resolution.id,
      type: this.resolutionType(resolution.type),
      employeeDepartmentIdSendOnAcceleratedReview: null,
      isOnAcceleratedReview: false,
    };
  }

  private resolutionType(type: ResolutionTypeModel): ResolutionTypeModel {
    switch (type.id) {
      case ResolutionTypeEnum.resolutionProject:
        return type;
      case ResolutionTypeEnum.requestProject:
        return type;
      default:
        return {
          id: ResolutionTypeEnum.heading,
          name: 'Направление',
        };
    }
  }

  private childResolutionRedirect(resolution: ResolutionModel): CreateChildResolutionModel[] {
    return resolution.childResolutions?.map((r) => ({
      author: r.author,
      childResolutions: r.childResolutions,
      files: r.files,
      fromEmployee: r.fromEmployee,
      missions: [...r.missions.map((m: MissionModel, index: number) => this.cloneMissions(m, index))],
      type: r.type,
    }));
  }

  private missionsRedirect(form: AppealResolutionRedirectModel): MissionModel[] {
    const dossiers = this.store.selectSnapshot(DossierState.getDossier);
    const copyDossiers = JSON.parse(JSON.stringify(dossiers)) as DossierResponseModel;
    let newDossier = null;
    if (copyDossiers) {
      newDossier = {
        id: null,
        comment: copyDossiers.comment,
        dossierAppealMovementLinks: copyDossiers.dossierLinks,
      };
    }
    return form.questions.reduce((acc, cur, index) => {
      const questions = [cur.question];
      const executorSign = cur.commission.executorSign;
      const mission = {
        addressee: Array.isArray(cur.commission.addressee)
          ? cur.commission.addressee.map((p: AddresseeFormModel, j: number) => this.addresseeRedirect(p, j))
          : this.addresseeRedirectProject(cur.commission, 0) || [],
        dossiers: newDossier ? [newDossier] : [],
        forPrepareAnswer: true,
        hasProjectLetter: null,
        isCommentConfidential: false,
        isWithoutExecutorOrAddressee: executorSign || null,
        needAttention: cur.commission.needAttention,
        missionAction: null,
        privateComment: cur.commission.text,
        projectLetterAuthor: null,
        questions,
        serialNumber: index + 1,
      };
      if (cur.commission.executorSign) {
        mission.addressee.push({
          addresseeType: {
            id: 1,
            name: 'Исполнитель',
          },
          checkDateType: null,
          decontrolDate: null,
          dueDate: null,
          employee: null,
          forPrepareAnswer: null,
          initialDueDate: null,
          isControl: false,
          isResponsibleByQuestion: false,
          missionAction: null,
          reportDate: null,
          serialNumber: mission.addressee.length + 1,
        });
      }
      return acc.concat(mission);
    }, []);
  }

  private addresseeRedirect(form: AddresseeFormModel, index: number): ResolutionAddresseeModel {
    const addresseeType = form.familiarization ? reviewerType : performerType;
    return {
      addresseeType,
      employee: form.addressee,
      isControl: false,
      personalNote: form.notes || null,
      serialNumber: index + 1,
      urgencyReviewType: form.urgency || null,
    };
  }

  private addresseeRedirectProject(form: ResolutionRedirectModel, index: number): ResolutionAddresseeModel[] | void {
    if (!form.addressee) {
      return;
    }
    return [
      {
        addresseeType: performerType,
        employee: form.addressee as EmployeeModel,
        isControl: false,
        personalNote: null,
        serialNumber: index + 1,
        urgencyReviewType: form.urgency || null,
      },
    ];
  }

  private cloneMissions(mission: MissionModel, index: number): MissionModel {
    return {
      addressee: this.removeAddresseeId(mission),
      curator: mission.curator,
      dossiers: mission.dossiers,
      forPrepareAnswer: mission.forPrepareAnswer,
      isCommentConfidential: mission.isCommentConfidential,
      isWithoutExecutorOrAddressee: mission.isWithoutExecutorOrAddressee,
      missionAction: mission.missionAction,
      missionAnswer: mission.missionAnswer,
      privateComment: mission.privateComment,
      serialNumber: index + 1,
      questions: mission?.questions,
    };
  }

  private removeAddresseeId(mission: MissionModel): ResolutionAddresseeModel[] {
    return mission.addressee.map((a) => ({
      ...a,
      id: null,
    }));
  }
}
