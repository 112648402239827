import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { FormsState } from '@store/forms/forms.state';
import { SetRightsForRedirectionExpeditedReview } from '@store/right-for-redirection-expedited-review/rights-for-redirection-expedited-review.action';

@Injectable()
@State<EmployeeModel[]>({
  name: 'rightsForRedirectionExpeditedReview',
  defaults: [],
})
export class RightsForRedirectionExpeditedReviewState {
  @Selector()
  public static getRights(state: EmployeeModel[]): EmployeeModel[] {
    return state;
  }

  @Selector([FormsState.getAddresseeForRedirection])
  public static getRightsForRedirection(state: EmployeeModel[], addressee: EmployeeModel): boolean {
    return state.some((e) => e.id === addressee.id);
  }

  @Action(SetRightsForRedirectionExpeditedReview)
  public setRightsForRedirectionExpeditedReview(
    { setState }: StateContext<EmployeeModel[]>,
    { data }: SetRightsForRedirectionExpeditedReview,
  ): void {
    setState(data);
  }
}
