import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[appHideHeader]',
})
/** Директива, которая скрывает блок статус-бара */
export class HideHeaderDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private platform: Platform) {}

  public ngAfterViewInit(): void {
    if (!this.platform.is('capacitor') || this.platform.is('electron')) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
