/** Обновить состояние кнопок навигации по документам */
export class SetLeftArrowState {
  public static readonly type = '[Arrow Buttons State] Set Left Arrow Button State';
  constructor(public value: boolean) {}
}

export class SetRightArrowState {
  public static readonly type = '[Arrow Buttons State] Set Right Arrow Button state';
  constructor(public value: boolean) {}
}

/** Сбрасывает состояние кнопок навигации */
export class ResetArrowState {
  public static readonly type = '[Arrow Buttons State] Reset Arrow State';
}
