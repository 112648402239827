import { Directive, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ApprovalModel } from '@oogShared/models/approval-list/approval.model';
import { FormHeaderModel } from '@oogShared/models/forms-input/form-header.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ApprovalHelperService } from '@oogShared/services/approval-helper.service';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { FormHelper } from '../../../../document-forms/form-helper';

@Directive()
export class ApprovalModalBase extends FormHelper implements OnInit {
  @Input() public approvalData!: ApprovalModel;

  public previousComment = '';
  public headerData!: FormHeaderModel;
  public user!: EmployeeModel;

  constructor(
    protected currentUser: CurrentUserIsproStoreService,
    protected modalCtrl: ModalController,
    protected approvalHelperService: ApprovalHelperService,
    protected store: Store,
  ) {
    super();
  }

  public ngOnInit(): void {
    const number = this.store.selectSnapshot(ActiveCardState.numberResolution);
    const date = this.store.selectSnapshot(ActiveCardState.dateResolution);
    this.headerData = {
      title: '',
      numberCardDocument: number,
      date,
    };
    this.user = this.currentUser.getCurrentUserSnapshot();
  }

  /** Уйти назад */
  public previousStep(): void {
    this.modalCtrl.dismiss().then();
  }

  public getPreviousComment(): void {
    const employees = this.approvalHelperService.createRedirectList(this.approvalData);
    let emp = null;
    if (employees?.length) {
      emp = employees[0];
    }
    if (emp) {
      const participant = this.approvalHelperService.findParticipantByEmployeeId(this.approvalData, emp.id);
      if (participant.approvalComments?.length) {
        this.previousComment = participant.approvalComments[participant.approvalComments.length - 1].comment;
      }
    }
  }
}
