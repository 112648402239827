import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetRightAcceleratedReview } from '@store/right-accelerated-review/right-accelerated-review.action';

@Injectable()
@State<boolean>({
  name: 'rightAcceleratedReview',
  defaults: false,
})
export class RightAcceleratedReviewState {
  @Selector()
  public static getRight(state: boolean): boolean {
    return state;
  }

  @Action(SetRightAcceleratedReview)
  public setRightsForRedirectionExpeditedReview(
    { setState }: StateContext<boolean>,
    { value }: SetRightAcceleratedReview,
  ): void {
    setState(value);
  }
}
