import { RoutePhaseTypes } from '../../document-agreement/enums/route-phase-types.enum';

/** порядок отображения этапов на маршруте */
export const PhaseSortOrder: { [key in RoutePhaseTypes]?: number } = {
  [RoutePhaseTypes.projectCreated]: 10,
  [RoutePhaseTypes.preliminaryApprovement]: 20,
  [RoutePhaseTypes.responsibleExecutor]: 30,
  [RoutePhaseTypes.questionProvided]: 35,
  [RoutePhaseTypes.projectProvided]: 35,
  [RoutePhaseTypes.projectApproved]: 40,
  [RoutePhaseTypes.conciliation]: 45,
  [RoutePhaseTypes.puPm]: 50,
  [RoutePhaseTypes.oauPm]: 60,
  [RoutePhaseTypes.oauRegistration]: 70,
};
