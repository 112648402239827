export enum ApprovalCommentTypeNamesEnum {
  // Добавление
  adding = 'ADDING',
  // Перенаправление
  redirection = 'REDIRECTION',
  // Возврат
  comeback = 'COMEBACK',
  // Согласование
  approval = 'APPROVAL',
  // Подписание
  signing = 'SIGNING'
}
