import { Component, Input } from '@angular/core';

import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';

import { ResolutionAddresseeTypeEnum } from '../../../enums/resolution-addressee/resolution-addressee-type.enum';

/**Компонент для отображения лиц из направленческой части */
@Component({
  selector: 'app-card-reviewers',
  templateUrl: './card-reviewers.component.html',
  styleUrls: ['./card-reviewers.component.scss'],
})
export class CardReviewersComponent {
  @Input() public reviewers: ResolutionAddresseeModel[] = [];

  public addresseeType = ResolutionAddresseeTypeEnum;
  public openedReviewers = false;

  /** Метод отвечает за сворачивание/раскрытие блока для ознакомления*/
  public clickOnCollapsedReviewers(event: Event): void {
    event.stopPropagation();
    this.openedReviewers = !this.openedReviewers;
  }
}
