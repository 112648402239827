import { Pipe, PipeTransform } from '@angular/core';
import { SortEnum } from '@enums/sort.enum';
import { ReportsItemExtendedModel } from '@oogShared/models/reports/reports.model';

@Pipe({
  name: 'reportsSortPipe',
})
/** Пайп сортирующий список отчетов по дате */
export class ReportsSortPipe implements PipeTransform {
  public transform(reports: ReportsItemExtendedModel[], sortOrder: SortEnum): ReportsItemExtendedModel[] {
    return reports.sort((a, b) => {
      if (sortOrder === SortEnum.asc) {
        return +new Date(a.creationDate) - +new Date(b.creationDate);
      }
      if (sortOrder === SortEnum.desc) {
        return +new Date(b.creationDate) - +new Date(a.creationDate);
      }
    });
  }
}
