import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayOnlyNumber',
})
export class DisplayOnlyNumberPipe implements PipeTransform {
  public transform(value: string | number): number {
    if (!value) {
      return 0;
    }

    const matchStringWithOnlyNumbers = value.toString().match(/\d+/g);

    const onlyNumber = Number(matchStringWithOnlyNumbers);
    if (!onlyNumber) {
      return 0;
    }

    return onlyNumber;
  }
}
