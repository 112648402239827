import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { EmployeeModel } from '../../models/resolutions/employee/employee.model';

@Pipe({
  name: 'compareAuthor',
})
/** Сравнить автора проекта с адресатом */
export class CompareAuthorPipe implements PipeTransform {
  constructor(private store: Store, private currentUser: CurrentUserIsproStoreService) {}

  public transform(employee: EmployeeModel): boolean {
    const addressee = this.store.selectSnapshot(ResolutionState.addresseeResolution);
    const user = this.currentUser.getCurrentUserSnapshot();
    return employee.id === addressee.employee.id && user.id === addressee.employee.id;
  }
}
