import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { ToArrayNumericEnum } from '@npaShared/helpers/enums.helper';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { RoutePoint } from '@npaShared/models/route/route.models';
import { RootActsLogic } from './root-acts-logic';

/** Логика работы с несколькими активными точками для категории "Незамедлительные" */
export class ImmediatelyActsLogic extends RootActsLogic {
  private availableTaskStatuses = [...EqualActiveResolutions];
  private availableRoles = ToArrayNumericEnum(EmployeeRouteTypes);

  public getActivePointsList(): RoutePoint[] {
    return this.getUserPoints()
      .filter((point) => this.availableTaskStatuses.includes(point.taskResolution))
      .filter((point) => this.availableRoles.includes(point.pointRoleId));
  }
}
