import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CredentialStorageService {
  public setNpaCredentials(credentials: any): void {
    this.setItem('npaCredentials', credentials);
  }

  public setOogCredentials(credentials: any): void {
    this.setItem('oogCredentials', credentials);
  }

  public getNpaCredentials(): any | null {
    return this.getItem('npaCredentials');
  }

  public getOogCredentials(): any | null {
    return this.getItem('oogCredentials');
  }

  public removeNpaCredentials(): void {
    localStorage.removeItem('npaCredentials');
  }

  public removeOogCredentials(): void {
    localStorage.removeItem('oogCredentials');
  }

  public hasNpaCredentials(): boolean {
    return Boolean(this.getNpaCredentials());
  }

  public hasOogCredentials(): boolean {
    return Boolean(this.getOogCredentials());
  }

  private setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  private getItem(key: string): string | null {
    return localStorage.getItem(key);
  }
}
