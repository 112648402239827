import { Pipe, PipeTransform } from '@angular/core';

import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { textareaTextConsts } from '@oogShared/consts/textarea-text.consts';

/**
 * Пайп проверяет имеет ли право текущий пользователь на просмотр комментария поручения
 * и если да - возвращает комментарий, в остальных случаях возвращает сообщение о недоступности текста
 * Код для данного пайпа переносил из веб-версии ИС ПРО. Перенести удалось не все, но описанную в баге проблему это решило.
 */
@Pipe({
  name: 'viewPrivateComment',
})
export class ViewPrivateCommentPipe implements PipeTransform {
  constructor(private authUserService: AuthUserIsproStoreService) {}

  public transform(mission: MissionModel, resolution: ResolutionModel): string {
    if (mission && this.checkMissionCommentAccess(mission, resolution)) {
      return mission.privateComment;
    }

    return textareaTextConsts.hiddenCommissionText;
  }

  public checkMissionCommentAccess(mission: MissionModel, resolution: ResolutionModel): boolean {
    const authUser = this.authUserService.getCurrentUserSnapshot();

    if (!mission.isCommentConfidential) {
      return true;
    }

    const commentViewers: EmployeeModel[] = this.getResolutionEmployees(resolution);

    const recipients = [...mission.addressee.map(({ employee }) => employee)];
    commentViewers.push(...recipients);

    return commentViewers.some((e) => e && e.id === authUser.id);
  }

  private getResolutionEmployees(resolution: ResolutionModel): EmployeeModel[] {
    const employees: EmployeeModel[] = [];

    resolution.creatorEmployee && employees.push(resolution.creatorEmployee);
    resolution.author && employees.push(resolution.author);
    resolution.fromEmployee && employees.push(resolution.fromEmployee);

    return employees;
  }
}
