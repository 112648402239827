import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { SelectTypeModel } from '@models/dictionaries/select-type.model';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { SetTemplatesList } from '@store/dictionaries/dictionaries-templates/dictionaries-template.action';

import { TemplateModel } from '@oogShared/models/dictionaries/template.model';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { DictionaryTemplateTypes } from '@enums/dictionary-template-types/dictionary-template-types.enums';

@Injectable()
@State<TemplateModel[]>({
  name: 'dictionariesTemplates',
  defaults: [],
})
export class DictionariesTemplateState {
  constructor(private dictionariesUrgency: DictionariesInteropService) {}

  public static templates(
    availableTypes: DictionaryTemplateTypes[] = [],
  ): (state: TemplateModel[]) => SelectTypeModel[] {
    return createSelector([DictionariesTemplateState], (state: TemplateModel[]) =>
      state
        .filter((t) => availableTypes.includes(Number(t.type.id)))
        .map((t) => ({
          id: t.id,
          name: t.text,
        })),
    );
  }

  @Selector([ResolutionState.isResolutionRequestProject])
  public static templatesForProject(state: TemplateModel[], isRequest: boolean): SelectTypeModel[] {
    const availableTypes = isRequest
      ? [DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.request]
      : [DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.resolution];

    return state
      .filter((t) => availableTypes.includes(Number(t.type.id)))
      .map((t) => ({
        id: t.id,
        name: t.text,
      }));
  }

  @Selector([ResolutionState.isResolutionRequestProject])
  public static templatesForProjectRedirect(state: TemplateModel[], isRequest: boolean): SelectTypeModel[] {
    const availableTypes = isRequest
      ? [DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.requestProject]
      : [DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.resolutionProject];

    return state
      .filter((t) => availableTypes.includes(Number(t.type.id)))
      .map((t) => ({
        id: t.id,
        name: t.text,
      }));
  }

  @Action(SetTemplatesList)
  public setTemplatesList({ getState, setState }: StateContext<TemplateModel[]>): void {
    if (getState().length) {
      return;
    }
    this.dictionariesUrgency.getTemplatesList().pipe(first()).subscribe(setState);
  }
}
