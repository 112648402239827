import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DictionaryModel } from '@npaShared/models/dictionary.model';

/** компонент для поиска и выбора в выпадающем списке */
@Component({
  selector: 'app-input-searching-and-choosing',
  templateUrl: './input-searching-and-choosing.component.html',
  styleUrls: ['./input-searching-and-choosing.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputSearchingAndChoosingComponent), multi: true },
  ],
})
export class InputSearchingAndChoosingComponent implements OnInit, OnDestroy, ControlValueAccessor {
  /** заголовок */
  @Input() public title = '';
  @Input() public placeholder = '';
  /** Список элементов */
  @Input() public items: DictionaryModel[];
  @Input() public controlInvalid = false;
  @Input() public canBlockedFiledClearBt = false;

  /** Оповещение о событии изменения текста поиска */
  @Output() public searchTextChanged = new EventEmitter<string>();
  /** выбрали пункт из списка */
  @Output() public selectEvent = new EventEmitter<DictionaryModel>();

  /** Выбранное значение */
  public value: DictionaryModel;
  /** Форма для инпута */
  public inputControl = new FormControl(null);
  /** Флаг показывается ли список */
  public isListShowed: boolean;
  public optionLabel = 'name';

  private unsubscribe$ = new Subject<void>();

  public ngOnInit(): void {
    this.subscribeToSearchTextChanged();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public writeValue(value: DictionaryModel): void {
    this.close();
    this.value = value;
    this.onChange(value);
    this.inputControl.setValue(value?.name || null);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisable: boolean): void {
    isDisable ? this.inputControl.disable() : this.inputControl.enable();
  }

  /** Показать список */
  public showList(): void {
    if (this.inputControl.disabled) {
      return;
    }

    this.inputControl.setValue(this.value?.name, { emitEvent: false });
    this.isListShowed = true;
  }

  /** Скрыть список */
  public close(): void {
    this.isListShowed = false;
  }

  /** Выполнить очистку контрола */
  public clear(): void {
    this.value = null;
    this.inputControl.setValue(null);
    this.onChange(null);
    this.selectEvent.emit(null);
  }

  public choose(value: DictionaryModel): void {
    this.writeValue(value);
    this.selectEvent.emit(value);
  }

  public blur(): void {
    this.onTouched();
  }

  private onChange = (value: DictionaryModel): any => {};

  private onTouched = (): any => {};

  private subscribeToSearchTextChanged(): void {
    this.inputControl.valueChanges
      .pipe(
        filter((res) => !!res),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((value) => this.searchTextChanged.emit(value));
  }
}
