import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DocumentPackagesStoreService } from '@npaCore/store/document-packages-store.service';
import { isLocalLegalActDocumentPackage } from '@npaShared/functions/project-type-document-package.functions';

/** Отобразит элемент, если текущий ПД в сторе является Локальным Правовым Актом (ЛПА) */
@Directive({
  selector: '[appShowIfLocalLegalAct]',
})
export class ShowIfLocalLegalActDirective implements OnInit {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private packagesStore: DocumentPackagesStoreService,
  ) {}

  public ngOnInit(): void {
    const dp = this.packagesStore.getSelectedDocumentPackageSnapshot();
    const isLocalLegalActCurrentDocumentPackage = isLocalLegalActDocumentPackage(dp?.documentPackage);

    if (!isLocalLegalActCurrentDocumentPackage) {
      this.viewContainer.clear();
      this.hasView = false;
      return;
    }

    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }
}
