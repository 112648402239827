import { DocumentPackageApproveModel } from '@npaShared/models/document-package/document-package-approve.model';
import { ParamsForSigning } from '../agreement-sign.service';
import { BaseSign } from './base-signing';

export class WithoutSigning extends BaseSign {
  public async getModalOrLoading(): Promise<HTMLIonLoadingElement | HTMLIonModalElement> {
    return await this.createApproveLoader();
  }

  public async getParams(data: ParamsForSigning): Promise<DocumentPackageApproveModel> {
    return {
      certificateId: null,
      pointId: data.pointId,
      text: data.text,
      revisions: data.revisions,
    };
  }
}
