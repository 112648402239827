import { MenuListNpaItemModel } from '@models/main-screen/menu-list-npa-item.model';
import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';
import { routeNames } from '../../app-routing/route-names.const';

export const menuItemsAisSd: MenuListNpaItemModel[] = [
  {
    name: 'Все проекты актов',
    type: StatisticsEnum.mobileOfficeStatisticDraftActs,
    count: 0,
    newCount: 0,
    url: routeNames.documentPackage,
    units: [
      {
        name: 'Все проекты актов',
        type: StatisticsEnum.mobileOfficeStatisticDraftActs,
        count: 0,
        newCount: 0,
        url: routeNames.documentPackage,
      },
      {
        name: 'Правовые акты Мэра Москвы и ПМ',
        type: StatisticsEnum.mobileLegalActs,
        count: 0,
        newCount: 0,
        url: routeNames.documentPackage,
      },
      {
        name: 'Локальные правовые акты и документы',
        type: StatisticsEnum.mobileLocalLegalActs,
        count: 0,
        newCount: 0,
        url: routeNames.documentPackage,
      },
    ],
  },
  {
    name: 'Незамедлительные',
    type: StatisticsEnum.mobileOfficeImmediately,
    count: 0,
    url: routeNames.documentPackage,
  },
  {
    name: 'Срочные',
    type: StatisticsEnum.mobileOfficeUrgently,
    count: 0,
    url: routeNames.documentPackage,
  },
  {
    name: 'Расписать',
    type: StatisticsEnum.mobileOfficeApprovementWrite,
    count: 0,
    url: routeNames.documentPackage,
  },
  {
    name: 'На исполнении',
    type: StatisticsEnum.mobileOfficeWorkingExecutor,
    count: 0,
    url: routeNames.documentPackage,
  },
  {
    name: 'Вынести решение',
    type: StatisticsEnum.mobileOfficeMakeDecision,
    count: 0,
    url: routeNames.documentPackage,
    units: [
      {
        name: 'На подпись',
        type: StatisticsEnum.mobileOfficeMakeDecisionWithSign,
        count: 0,
        url: routeNames.documentPackage,
      },
      {
        name: 'Без подписи',
        type: StatisticsEnum.mobileOfficeMakeDecisionWithoutSign,
        count: 0,
        url: routeNames.documentPackage,
      },
    ],
  },
  {
    name: 'На ознакомлении',
    type: StatisticsEnum.mobileOfficeIntroducer,
    count: 0,
    url: routeNames.documentPackage,
  },
];
