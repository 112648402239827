import { ConfirmSignComponent } from '@components/confirm-sign/confirm-sign.component';
import { LoadingController, ModalController } from '@ionic/angular';
import { DocumentPackageApproveModel } from '@npaShared/models/document-package/document-package-approve.model';
import { ParamsForSigning } from '../agreement-sign.service';

export abstract class BaseSign {
  constructor(protected loadingController: LoadingController, protected modalController: ModalController) {}

  protected async createConfirmEsignPopup(): Promise<HTMLIonModalElement> {
    const confirmEsignPopup = await this.modalController.create({
      backdropDismiss: false,
      component: ConfirmSignComponent,
      cssClass: 'confirm-cloud-sign',
    });

    return confirmEsignPopup;
  }

  protected async createApproveLoader(): Promise<HTMLIonLoadingElement> {
    return await this.loadingController.create({ cssClass: 'custom-spinner' });
  }

  protected abstract getModalOrLoading(hasCloudEsign: boolean): Promise<HTMLIonLoadingElement | HTMLIonModalElement>;

  protected abstract getParams(data: ParamsForSigning): Promise<DocumentPackageApproveModel>;
}
