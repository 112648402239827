import { Pipe, PipeTransform } from '@angular/core';

import { EmployeeModel } from '../../models/resolutions/employee/employee.model';

@Pipe({
  name: 'performerShortNameGenitive',
})
export class PerformerShortNameGenitivePipe implements PipeTransform {
  public transform(employee: EmployeeModel): string {
    if (!employee) {
      return '';
    }

    if (!employee.fioGenitive) {
      return employee.lastName;
    }

    const fio = employee.fioGenitive.split(' ');
    const lastName = fio[0] || '';
    const shortFirstName = fio[1] ? `${fio[1][0]}.` : '';
    const shortPatronymic = fio[2] ? `${fio[2][0]}.` : '';

    // Добавил неразрывный пробел между фамилией и инициалами для избежания переноса последних отдельно от фамилии
    return `${lastName} ${shortFirstName}${shortPatronymic}`;
  }
}
