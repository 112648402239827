import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';
import { InterceptorErrorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorCheckInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!(req.params instanceof InterceptorHttpParams)) {
      return next.handle(req);
    }

    if (!(req.params?.interceptorConfig as InterceptorErrorConfig)?.ignoreError) {
      return next.handle(req);
    }

    return next.handle(req).pipe(catchError(() => EMPTY));
  }
}
