import { Component, Input, OnInit } from '@angular/core';

import { ModalController, PopoverController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { getPathToCurrentFolder } from '@oogShared/functions/build-path-in-personal-folders.functions';
import { ResetActiveDocumentCard } from '@store/menu/active-card/active-card.action';
import { ResetDocumentsCards } from '@store/menu/documents-cards/documents-cards.action';
import {
  SetFolderId,
  SetFolderName,
  SetShownFolders,
  SetTransitionFromFolders,
} from '@store/menu/sidebar/sidebar.action';
import { ResetDossier } from '@store/resolution-store/dossier/dossier.action';
import { ResetResolutionFiles } from '@store/resolution-store/resolution-files/resolution-files.action';
import { ResetResolutionTree } from '@store/resolution-store/resolution-tree/resolution-tree.action';
import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { first } from 'rxjs/operators';

import { retrieveDocumentsInDepth } from '../../functions/document-folder/document-folder.function';
import { PersonalFolderDocuments } from '../../models/folders/personal-folder-documents.model';
import { PersonalFolder } from '../../models/folders/personal-folders.model';
import { FoldersStateService } from '../../services/folders/folders-state.service';
import { MenuHelperService } from '../../services/menu-helper.service';
import { FoldersEditMenuComponent } from './folders-edit-menu/folders-edit-menu.component';

@Component({
  selector: 'app-folders-item',
  templateUrl: './folders-item.component.html',
  styleUrls: ['./folders-item.component.scss'],
})
export class FoldersItemComponent implements OnInit {
  @Input()
  public folders: PersonalFolder[];

  @Input()
  public editMode = false;

  @Input()
  public openedFolders = new Set<number>();

  public clickedFolder$ = this.foldersState.getClickedFolder();

  public selectedFolder$ = this.foldersState.getSelectedFolders();

  constructor(
    private foldersState: FoldersStateService,
    private popoverController: PopoverController,
    private modalCtrl: ModalController,
    private store: Store,
    private menuHelper: MenuHelperService,
  ) {}

  public ngOnInit(): void {
    this.expandBranchWithSelectedFolder();
  }

  public toggleFolderState(event: Event, folderId: number, disabled: boolean): void {
    event.stopPropagation();
    if (disabled) {
      return;
    }
    this.openedFolders.has(folderId) ? this.openedFolders.delete(folderId) : this.openedFolders.add(folderId);
  }

  public async selectFolder(folder: PersonalFolder, event: any): Promise<void> {
    await this.chooseFolder(folder, event);
    this.transitionOnFolder(folder);
  }

  private expandBranchWithSelectedFolder(): void {
    const selectedFolder = this.foldersState.getSelectedFoldersSnapshot();

    if (selectedFolder) {
      selectedFolder.forEach((folder) => {
        const path = getPathToCurrentFolder(this.folders, folder);

        // удаляем последний элемент в пути, т.к. он равен folder
        path.pop();
        this.expandCurrentBranch(path, this.folders);
      });
    }
  }

  /** Проходим по пути папок и добавляем всю ветку в открытые папки */
  private expandCurrentBranch(path: number[], currentLevel: PersonalFolder[]): void {
    const currentIndex = path.shift();

    if (currentIndex === undefined) {
      return;
    }

    const currentFolder = currentLevel[currentIndex];
    this.openedFolders.add(currentFolder.id);

    this.expandCurrentBranch(path, currentFolder.folders);
  }

  private async chooseFolder(folder: PersonalFolder, event: any): Promise<void> {
    if (this.editMode) {
      const popover = await this.popoverController.create({
        componentProps: { folder },
        component: FoldersEditMenuComponent,
        cssClass: 'edit-menu',
        event,
        translucent: false,
      });
      return await popover.present();
    }

    if ((await this.modalCtrl.getTop())?.nodeName) {
      const clickedFolder = this.foldersState.getClickedFolderSnapshot();

      if (clickedFolder?.id === folder.id) {
        this.foldersState.setClickedFolder(null);
        return;
      }

      this.foldersState.setClickedFolder(folder);
    }
  }

  private transitionOnFolder(folder: PersonalFolder): void {
    const docs: PersonalFolderDocuments[] = [];
    retrieveDocumentsInDepth(folder, docs);

    if (!docs.length || this.editMode || this.foldersState.getSelectedFoldersSnapshot()) {
      return;
    }

    this.menuHelper.loadDocumentsInMenuFromPersonalFolder(docs).pipe(first()).subscribe();
    this.foldersState.setFolderToView(folder);

    this.store.dispatch([
      ResetDocumentsCards,
      ResetResolution,
      ResetDossier,
      ResetResolutionFiles,
      ResetResolutionTree,
      ResetActiveDocumentCard,
      new SetShownFolders(false),
      new SetTransitionFromFolders(true),
      new SetFolderName(folder.name),
      new SetFolderId(folder.id),
    ]);
  }
}
