import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { removeItem } from '@ngxs/store/operators';
import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { ActiveProjectExpeditedReviewState } from '@store/expedited-review/active-project/active-project-expedited-review.state';
import {
  DeleteCardExpeditedReview,
  DeleteGroupExpeditedReview,
  ResetMenuExpeditedReview,
  SetMenuExpeditedReview,
} from '@store/expedited-review/menu/menu-expedited-review.action';

@Injectable()
@State<ExpeditedReviewModel[]>({
  name: 'menuExpeditedReview',
  defaults: [],
})
export class MenuExpeditedReviewState {
  @Selector()
  public static getExpeditedMenu(state: ExpeditedReviewModel[]): ExpeditedReviewModel[] {
    return state;
  }

  @Selector([ActiveProjectExpeditedReviewState.getActiveProjectId])
  public static getNextProjectId(state: ExpeditedReviewModel[], active: number): number {
    const index = state.findIndex((c) => c?.resolutionDto.id === active);
    return state[index + 1]?.resolutionDto.id;
  }

  @Selector()
  public static getFirstProjectId(state: ExpeditedReviewModel[]): number {
    return state[0]?.resolutionDto.id;
  }

  @Selector([ActiveProjectExpeditedReviewState.getActiveProjectId])
  public static getActiveCard(state: ExpeditedReviewModel[], active: number): ExpeditedReviewModel {
    return state.find((c) => c?.resolutionDto.id === active);
  }

  @Selector([ActiveProjectExpeditedReviewState.getActiveProjectId])
  public static getFilesActiveCard(state: ExpeditedReviewModel[], active: number): FilePoolModel[] {
    const card = state.find((c) => c?.resolutionDto.id === active);
    return card?.files;
  }

  @Action(SetMenuExpeditedReview)
  public setMenuExpeditedReview(
    { setState }: StateContext<ExpeditedReviewModel[]>,
    { data }: SetMenuExpeditedReview,
  ): void {
    setState(data);
  }

  @Action(ResetMenuExpeditedReview)
  public resetMenuExpeditedReview({ setState }: StateContext<ExpeditedReviewModel[]>): void {
    setState([]);
  }

  @Action(DeleteCardExpeditedReview)
  public deleteCardExpeditedReview(
    { setState }: StateContext<ExpeditedReviewModel[]>,
    { id }: DeleteCardExpeditedReview,
  ): void {
    setState(removeItem((r) => r.resolutionDto.id === id));
  }

  @Action(DeleteGroupExpeditedReview)
  public deleteGroupExpeditedReview(
    { setState, getState }: StateContext<ExpeditedReviewModel[]>,
    { id }: DeleteGroupExpeditedReview,
  ): void {
    const cards = [...getState()];
    const filterCards = cards.filter((r) => r.resolutionDto?.groupProjects?.groupProjects.mainAppealMovementId !== id);
    setState(filterCards);
  }
}
