import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { SetDictionariesAllUrgency } from '@store/dictionaries/dictionaries-all-urgency/dictionaries-all-urgency.action';

import { UrgencyModel } from '../../../../oog/shared/models/dictionaries/urgency.model';
import { CheckDateModel } from '../../../../oog/shared/models/resolutions/check-date.model';
import { DictionariesInteropService } from '../../../../oog/shared/rest/dictionaries-interop.service';

@Injectable()
@State<CheckDateModel[]>({
  name: 'dictionariesAllUrgency',
  defaults: [],
})
export class DictionariesAllUrgencyState {
  constructor(private dictionariesUrgency: DictionariesInteropService) {}

  public static currentUrgency(id: number): (state: CheckDateModel[]) => CheckDateModel {
    return createSelector(
      [DictionariesAllUrgencyState],
      (state: CheckDateModel[]): CheckDateModel => state.find((item) => item.id === id),
    );
  }

  @Selector()
  public static allUrgency(state: CheckDateModel[]): UrgencyModel[] {
    return state.map((u) => ({
      id: u.id,
      name: u.name,
    }));
  }

  @Action(SetDictionariesAllUrgency)
  public setDictionariesAllUrgency({ getState, setState }: StateContext<CheckDateModel[]>): void {
    if (getState().length) {
      return;
    }
    this.dictionariesUrgency
      .getDictionariesAllUrgency()
      .pipe(first())
      .subscribe((data) => {
        const urgency = data.filter((u) => u.id !== 8);
        setState(urgency);
      });
  }
}
