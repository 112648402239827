import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { SettingsState } from '@store/settings/settings.state';

import { ModalDischargeOfDutiesComponent } from '../../../document-forms/components/modal-discharge-of-duties/modal-discharge-of-duties.component';
import { DischargeOfDutiesModalEnum } from '../../enums/discharge-of-duties-modal.enum';
import { DischargeOfDutiesModel } from '../../models/discharge-of-duties/discharge-of-duties.model';
import { ResponseModalModel } from '../../models/discharge-of-duties/response-modal.model';
import { DischargeOfDutiesInteropService } from '../../rest/discharge-of-duties-interop.service';

@Injectable({
  providedIn: 'root',
})
export class DischargeDutiesProjectService {
  constructor(
    private dischargeService: DischargeOfDutiesInteropService,
    private modalCtrl: ModalController,
    private store: Store,
  ) {}

  /** Найти ИО сотрудника, которого выбрали */
  public dischargeOfDuties(form: FormGroup, id: number): void {
    this.dischargeService
      .listOfDuties(id)
      .pipe(
        take(1),
        filter((r) => !!r.length),
      )
      .subscribe((r) => {
        const subActing = this.store.selectSnapshot(SettingsState.settingsSubstitute).autoCorrectSubActingName;
        if (r.length > 1 || !subActing) {
          this.showModalDuties(form, r).then();
          return;
        }
        this.applyReposponseModelWithAutoChangeFioSettings(form, r[0]);
      });
  }

  /** Применить если есть настройки "замена фио исполнителя по-умолчанию" */
  private applyReposponseModelWithAutoChangeFioSettings(form: FormGroup, performer: DischargeOfDutiesModel): void {
    const data = { status: DischargeOfDutiesModalEnum.change, performer: performer.employeeTo || null };
    this.applyResponseModal(data, form);
  }

  /** Отобразить модальное окно со списком ИО */
  private async showModalDuties(form: FormGroup, performers: DischargeOfDutiesModel[]): Promise<void> {
    const modal = this.modalCtrl.create({
      componentProps: {
        listDuties: performers,
      },
      component: ModalDischargeOfDutiesComponent,
      cssClass: 'custom-background',
      animated: false,
      backdropDismiss: false,
    });
    modal.then((m) => m.present());
    const { data } = await modal.then((m) => m.onWillDismiss());
    this.applyResponseModal(data, form);
  }

  private applyResponseModal(data: ResponseModalModel, form: FormGroup): void {
    switch (data.status) {
      case DischargeOfDutiesModalEnum.current:
        break;
      case DischargeOfDutiesModalEnum.change:
        form.controls.addressee.setValue(data.performer);
        break;
      case DischargeOfDutiesModalEnum.cancel:
        form.controls.addressee.reset();
        break;
    }
  }
}
