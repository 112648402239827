import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ResetActiveCardProjectResolution,
  SetActiveCardProjectResolution,
} from '@store/resolution-store/active-resolution-project/active-resolution-project.action';

@Injectable()
@State<number | null>({
  name: 'activeResolutionProject',
  defaults: null,
})
export class ActiveResolutionProjectState {
  @Selector()
  public static activeProject(state: number): number {
    return state;
  }

  @Action(SetActiveCardProjectResolution)
  public setActiveCardProjectResolution(
    { setState }: StateContext<number>,
    { numberCard }: SetActiveCardProjectResolution,
  ): void {
    setState(numberCard);
  }

  @Action(ResetActiveCardProjectResolution)
  public resetActiveCardProjectResolution({ setState }: StateContext<number>): void {
    setState(null);
  }
}
