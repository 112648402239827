import { ChoiceMainProjectModel } from '@oogShared/components/card-expedited-review/choice-main-project.model';

/** Сохранить выбранный проект в группе */
export class SetChosenDocumentInGroup {
  public static readonly type = '[ExpeditedReview] Set Chosen Document In Group';
  constructor(public data: ChoiceMainProjectModel) {}
}

/** Сбросить проект группы */
export class ResetChosenDocumentInGroup {
  public static readonly type = '[ExpeditedReview] Reset Chosen Document In Group';
}
