import { Component } from '@angular/core';

import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-ban-redirect-mayor',
  templateUrl: './ban-redirect-mayor.component.html',
  styleUrls: ['./ban-redirect-mayor.component.scss'],
})
export class BanRedirectMayorComponent extends DialogBase {}
