import { Injectable } from '@angular/core';

type DataKey = 'aissdData';

interface Values {
  aissd: {
    data: DataKey;
    username: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LoginPassStorageService {
  private values: Values = {
    aissd: { data: 'aissdData', username: 'aissdUsername' },
  };

  public setAisSdUsername(username: string): void {
    this.setItem(this.values.aissd.username, username);
  }

  public clearAisSdUsername(): void {
    this.setItem(this.values.aissd.username, '');
  }

  public setAisSdPassword(password: string): void {
    this.updateData(this.values.aissd.data, { password });
  }

  public clearAisSdPassword(): void {
    this.updateData(this.values.aissd.data, { password: null });
  }

  public getAisSdUsername(): string {
    return this.getItem(this.values.aissd.username);
  }

  public getAisSdPassword(): string {
    return this.getData(this.values.aissd.data)?.password ?? null;
  }

  public removeAisSdUsername(): void {
    localStorage.removeItem(this.values.aissd.username);
  }

  private setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  private getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  private setData(key: DataKey, data: any): void {
    this.setItem(key, btoa(unescape(encodeURIComponent(JSON.stringify(data)))));
  }

  private getData(key: DataKey): any {
    if (this.getItem(key)) {
      return JSON.parse(atob(this.getItem(key)));
    }

    return null;
  }

  private updateData(key: DataKey, data: any): void {
    this.setData(key, {
      ...this.getData(key),
      ...data,
    });
  }
}
