import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ResetRelevantWorkers, SetRelevantWorkers } from '@store/main-screen/relevant-workers/relevant-workers.action';
import { WorkForEveryoneState } from '@store/main-screen/work-for-everyone/work-for-everyone.state';

@Injectable()
@State<EmployeeModel[]>({
  name: 'relevantWorkers',
  defaults: [],
})
export class RelevantWorkersState {
  constructor(
    private user: CurrentUserIsproStoreService,
    private authUser: AuthUserIsproStoreService,
    private store: Store,
  ) {}

  @Selector()
  public static relevantWorkers(state: EmployeeModel[]): EmployeeModel[] {
    return state;
  }

  @Action(SetRelevantWorkers)
  public setRelevantWorkers({ setState }: StateContext<EmployeeModel[]>, { workers }: SetRelevantWorkers): void {
    const workForEveryone = this.store.selectSnapshot(WorkForEveryoneState.workForEveryone);
    const currentUser = workForEveryone ? this.authUser.getCurrentUserSnapshot() : this.user.getCurrentUserSnapshot();
    setState([currentUser, ...workers]);
  }

  @Action(ResetRelevantWorkers)
  public resetRelevantWorkers({ setState }: StateContext<EmployeeModel[]>): void {
    setState([]);
  }
}
