/** Перечень иконок кнопки */
export enum ButtonIconEnum {
  /** Иконка с галочкой */
  buttonApply = 'button-apply',
  /** Иконка для редиректа */
  buttonRedirect = 'button-redirect',
  /** Иконка со стрелкой вниз */
  buttonArrowButton = 'button-arrow-button',
  /** Иконка редактирования */
  buttonEdit = 'button-edit',
  /** Иконка возврата */
  buttonReturn = 'button-return',
  /** Иконка утверждения */
  buttonAccepted = 'button-accepted',
  /** Иконка удаления */
  buttonDeleted = 'button-deleted',
}
