import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  RemoveDocumentFromList,
  RemoveDocumentsFromList,
  ResetDocumentList,
  SetCountDocuments,
} from '@store/menu/count-documents-menu/count-documents-menu.action';

@Injectable()
@State<number>({
  name: 'countDocumentsMenu',
  defaults: 0,
})
export class CountDocumentsMenuState {
  @Selector()
  public static countDocuments(state: number): number {
    return state;
  }

  @Action(SetCountDocuments)
  public setCountDocuments({ setState }: StateContext<number>, { count }: SetCountDocuments): void {
    setState(count);
  }

  @Action(RemoveDocumentFromList)
  public removeDocumentFromList({ getState, setState }: StateContext<number>): void {
    const count = getState();
    setState(count - 1);
  }

  @Action(RemoveDocumentsFromList)
  public removeDocumentsFromList(
    { getState, setState }: StateContext<number>,
    { quantity }: RemoveDocumentsFromList,
  ): void {
    const count = getState();
    setState(count - quantity);
  }

  @Action(ResetDocumentList)
  public resetDocumentList({ setState }: StateContext<number>): void {
    setState(0);
  }
}
