import { Injectable } from '@angular/core';

import { ArrowMovementModel } from '@models/documents/arrow-movement.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResetArrowMovement, SetArrowMovement } from '@store/arrow-movement/arrow-movement.action';

@Injectable()
@State<ArrowMovementModel | null>({
  name: 'arrowMovementAction',
  defaults: null,
})
export class ArrowMovementState {
  @Selector()
  public static getArrowMovementAction(state: ArrowMovementModel): ArrowMovementModel {
    return state;
  }

  @Action(SetArrowMovement)
  public setArrowMovement({ setState }: StateContext<ArrowMovementModel>, { action }: SetArrowMovement): void {
    setState(action);
  }

  @Action(ResetArrowMovement)
  public resetArrowMovement({ setState }: StateContext<ArrowMovementModel>): void {
    setState(null);
  }
}
