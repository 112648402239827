import { BehaviorSubject, Observable } from 'rxjs';

export class AbstractCurrentUserStore<T> {
  private currentUser$ = new BehaviorSubject<T>(null);

  public setCurrentUser(value: T): void {
    this.currentUser$.next(value);
  }

  public getCurrentUser(): Observable<T> {
    return this.currentUser$;
  }

  public getCurrentUserSnapshot(): T {
    return this.currentUser$.value;
  }

  public resetCurrentUser(): void {
    return this.currentUser$.next(null);
  }
}
