import { CertificateItemModel } from '@models/esigns/certificate-item.model';

/**
 * возвращает сертификат по умолчанию - это первый локальный если он есть
 * или первый в списке, если локальных нет
 *
 * @param certificates список сертификатов
 * @returns сертификат по умолчанию если есть
 */
export const getDefaultCertificate = (certificates: CertificateItemModel[]): CertificateItemModel | null => {
  const firstLocalCert = certificates.find((c) => !c.isCloud);
  const firstCert = certificates?.length ? certificates[0] : null;
  return firstLocalCert || firstCert;
};
