import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { mediaCommissionText } from '@const/commissions-text.const';
import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { ModalController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { FormHeaderModel } from '@oogShared/models/forms-input/form-header.model';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { FormsState } from '@store/forms/forms.state';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { ResolutionKindEnum } from '@oogShared/enums/resolution-kind/resolution-kind.enum';
import { GraphicFormService } from './services/graphic-form.service';

/** Графическая форма для рисования */
@UntilDestroy()
@Component({
  selector: 'app-graphic-form',
  templateUrl: './graphic-form.component.html',
  styleUrls: ['./graphic-form.component.scss'],
})
export class GraphicFormComponent {
  @Input() public headerData!: FormHeaderModel;
  @Input() public form: FormGroup = new FormGroup({});
  @Input() public edit = false;
  @Input() public editedPicture = '';
  @Input() public createForm = false;

  @Select(FormsState.disabledByWhiteListAddressee)
  public disabledByWhiteList$!: Observable<boolean>;

  @Select(FormsState.clonePerformerCreateResolution)
  public clonePerformer$!: Observable<boolean>;

  public buttonTheme = ButtonThemeEnum;

  constructor(
    private modalCtrl: ModalController,
    private graphicFormService: GraphicFormService,
    private resolutionService: ResolutionInteropService,
  ) {}

  /** Уйти назад */
  public goToPreviousStep(): void {
    this.modalCtrl.dismiss().then();
  }

  /** Сохранить форму */
  public saveForm(): void {
    this.graphicFormService.savedForm$.next();
    this.sendFile()
      .pipe(untilDestroyed(this))
      .subscribe((result) => this.saveFileData(result));
  }

  /** Отправить форму */
  public submitForm(): void {
    this.graphicFormService.savedForm$.next();
    this.sendFile()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.saveFileData(result);
        /** FIXE ME: Почему-то нужна задержка пока в контрол засетится id */
        setTimeout(() => {
          this.graphicFormService.blobImage$.next(null);
          this.graphicFormService.submitForm$.next();
        }, 100);
      });
  }

  public savePicture(value: string): void {
    const imgControl = this.form.controls.picture;
    imgControl.setValue(value);
    this.changeCommissionText();
  }

  private saveFileData(data: FilePoolModel): void {
    this.form.controls.fileId?.setValue(data.id);
    this.form.controls.resolutionKind?.setValue(ResolutionKindEnum.Graphic);
    this.modalCtrl.dismiss();
  }

  private sendFile(): Observable<FilePoolModel> {
    const blobImage = this.graphicFormService.blobImage$;
    return blobImage.pipe(
      filter((blob) => !!blob),
      first(),
      switchMap((blob) => this.resolutionService.uploadFile(blob, this.headerData.numberCardDocument, 'png')),
    );
  }

  private changeCommissionText(): void {
    const textControl = this.form.controls.text;
    textControl.setValue(mediaCommissionText);
  }
}
