import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';
import { Route } from '@npaShared/models/route/route.models';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { StatisticDraftActsLogic } from './statistic-draft-acts.logic';
import { ImmediatelyActsLogic } from './immediately-acts.logic';
import { UrgentlyActsLogic } from './urgently-acts.logic';
import { ApprovementWriteActsLogic } from './approvement-write-acts.logic';
import { WorkingExecutorActsLogic } from './working-executor-acts.logic';
import { IntroducerActsLogic } from './introducer-acts.logic';

export const getMultipleActivePointLogic = (
  actsCategory: StatisticsEnum,
  route: Route,
  user: UserInfoResponseModel,
): any => {
  switch (actsCategory) {
    case StatisticsEnum.mobileOfficeStatisticDraftActs:
      return new StatisticDraftActsLogic(route, user);

    case StatisticsEnum.mobileOfficeImmediately:
      return new ImmediatelyActsLogic(route, user);

    case StatisticsEnum.mobileOfficeUrgently:
      return new UrgentlyActsLogic(route, user);

    case StatisticsEnum.mobileOfficeApprovementWrite:
      return new ApprovementWriteActsLogic(route, user);

    case StatisticsEnum.mobileOfficeWorkingExecutor:
      return new WorkingExecutorActsLogic(route, user);

    case StatisticsEnum.mobileOfficeIntroducer:
      return new IntroducerActsLogic(route, user);

    // FIXME: нужна актуальная реализация логики
    case StatisticsEnum.mobileOfficeMakeDecision:
      return new StatisticDraftActsLogic(route, user);

    // FIXME: нужна актуальная реализация логики
    case StatisticsEnum.mobileOfficeMakeDecisionWithSign:
      return new StatisticDraftActsLogic(route, user);

    // FIXME: нужна актуальная реализация логики
    case StatisticsEnum.mobileOfficeMakeDecisionWithoutSign:
      return new StatisticDraftActsLogic(route, user);

    default:
      throw new Error('Для данной категории не предусмотрена реализация работы с несколькими активными точками');
  }
};
