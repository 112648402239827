import { DocumentInfoResponseModel } from '../document/document-info.response.model';
import { Route } from '../route/route.models';
import { DocumentPackageAdditionalInfoResponseModel } from './document-package-additional-info.response.model';
import { DocumentPackageShortResponseModel } from './document-package-short.response.model';
import { DocumentPackageResponseModel } from './document-package.response.model';

export class DocumentPackageStoreModel {
  public id: number;
  public documentPackage: DocumentPackageResponseModel;
  public documentPackageAdditionalInfo: DocumentPackageAdditionalInfoResponseModel;
  public documents: DocumentInfoResponseModel[];
  public shortResponseModel: DocumentPackageShortResponseModel;
  public route: Route;
}
