import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';
import { ClearActiveCategory, SetActiveCategory } from './active-category.action';

/** активная категория */
@Injectable()
@State<StatisticsEnum | null>({
  name: 'activeCategory',
  defaults: null,
})
export class ActiveCategoryState {
  @Selector()
  public static getActiveCategory(state: StatisticsEnum): StatisticsEnum {
    return state;
  }

  @Action(SetActiveCategory)
  public setActiveCategory({ setState }: StateContext<StatisticsEnum>, { category }: SetActiveCategory): void {
    setState(category);
  }

  @Action(ClearActiveCategory)
  public clearActiveCategory({ setState }: StateContext<StatisticsEnum>): void {
    setState(null);
  }
}
