import { Directive } from '@angular/core';
import { AgreementSignService } from '@npaCore/services/signing/agreement-sign.service';
import { DocumentPackagesStoreService } from '@npaCore/store/document-packages-store.service';
import { UserSettingsStoreService } from '@npaCore/store/user-settings-store.service';
import { SelectedRoutePointService } from '@npaDP/services/selected-route-point.service';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { checkApproveWithoutSignature, getPhaseByPointId } from '@npaShared/helpers/route/phases-helper';
import { getPointByIdWithAdditionalInfo, nextPointOrCurrentPointIsLeader } from '@npaShared/helpers/route/point-helper';
import { Route, RoutePhase, RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';
import { RoutePhaseTypes } from 'src/app/npa/document-agreement/enums/route-phase-types.enum';

@Directive()
export abstract class DecideModalsHelperBaseService {
  /** В данных этапах должны согласовывать ПД только с ЭП (руководитель или согласующий, включённый в ЛС) */
  protected readonly phasesWithRequiredSignature = [
    RoutePhaseTypes.responsibleExecutor,
    RoutePhaseTypes.questionProvided,
    RoutePhaseTypes.projectApproved,
    RoutePhaseTypes.puPm,
    RoutePhaseTypes.oauPm,
  ];

  protected route: Route;
  protected selectedPoint: RoutePointWithAdditionalInfo;
  protected phase: RoutePhase;

  protected phaseIs: {
    projectCreated: boolean;
    preliminaryApprovement: boolean;
  } = {
    projectCreated: false,
    preliminaryApprovement: false,
  };

  protected isLeader = false;
  /** есть ли категория подписанта */
  protected hasSignerCategory = false;
  /** можно ли согласовать без ЭП */
  protected canApproveWithoutSignatureOnPhase = false;
  /** есть ли ЭП */
  protected hasSignature = false;
  /** нужна ли блокировка кнопок, в зависимости от наличия/отсутствия замечаний */
  protected disabledSubmitToDependingRemarks = false;
  /** является ли точка последней перед точкой руководителя или она сама последняя точка, то есть руководитель */
  protected isNextPointLeader = false;

  constructor(
    private dpStoreService: DocumentPackagesStoreService,
    private selectedActivePointService: SelectedRoutePointService,
    private agreementSignService: AgreementSignService,
    private userSettingsStoreService: UserSettingsStoreService,
  ) {}

  protected calculate(
    isLocalLegalActsType: boolean,
    hasSignature: boolean,
    disabledSubmitToDependingRemarks: boolean = false,
  ): boolean {
    this.init(isLocalLegalActsType, hasSignature, disabledSubmitToDependingRemarks);

    if (isLocalLegalActsType) {
      return this.lpa();
    }

    return this.npa();
  }

  protected init(
    isLocalLegalActsType: boolean,
    hasSignature: boolean,
    disabledSubmitToDependingRemarks: boolean = false,
  ): void {
    this.hasSignature = hasSignature;
    this.disabledSubmitToDependingRemarks = disabledSubmitToDependingRemarks;

    this.route = this.dpStoreService.getSelectedDocumentPackageSnapshot()?.route;
    const point = this.selectedActivePointService.getSelectedPoint();
    this.selectedPoint = getPointByIdWithAdditionalInfo(this.route, point.id);
    this.hasSignerCategory = this.agreementSignService.hasSignerCategory(this.selectedPoint);

    this.phase = getPhaseByPointId(this.route, this.selectedPoint.id);
    this.phaseIs.projectCreated = this.phase.phaseTypeId === RoutePhaseTypes.projectCreated;
    this.phaseIs.preliminaryApprovement = this.phase.phaseTypeId === RoutePhaseTypes.preliminaryApprovement;

    this.isLeader = [EmployeeRouteTypes.leader, EmployeeRouteTypes.executor].includes(this.selectedPoint.pointRoleId);
    this.isNextPointLeader = nextPointOrCurrentPointIsLeader(this.route, this.selectedPoint);

    const canApproveWithoutSign = this.userSettingsStoreService.canApproveWithoutEsign();
    this.canApproveWithoutSignatureOnPhase = checkApproveWithoutSignature(
      isLocalLegalActsType,
      canApproveWithoutSign,
      this.route,
      this.selectedPoint,
    );
  }

  protected abstract lpa(): boolean;

  protected abstract npa(): boolean;
}
