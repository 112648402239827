import { Injectable } from '@angular/core';
import { AndroidPermissionResponse } from '@awesome-cordova-plugins/android-permissions';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

@Injectable({ providedIn: 'root' })
export class AndroidPermissionService {
  constructor(private androidPermissions: AndroidPermissions) {}

  public hasPermissionWriteExternalStorage(): Promise<AndroidPermissionResponse> {
    return this.androidPermissions.hasPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
  }

  public requestPermissionWriteExternalStorage(): Promise<AndroidPermissionResponse> {
    return this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
  }
}
