import { Injectable } from '@angular/core';

import { State } from '@ngxs/store';
import { ActiveResolutionProjectState } from '@store/resolution-store/active-resolution-project/active-resolution-project.state';
import { BookmarksState } from '@store/resolution-store/bookmarks/bookmarks.state';
import { DossierState } from '@store/resolution-store/dossier/dossier.state';
import { ResolutionFilesState } from '@store/resolution-store/resolution-files/resolution-files.state';
import { ResolutionTreeState } from '@store/resolution-store/resolution-tree/resolution-tree.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

@Injectable()
@State<[]>({
  name: 'resolutionStore',
  children: [
    ResolutionState,
    ResolutionTreeState,
    ResolutionFilesState,
    ActiveResolutionProjectState,
    DossierState,
    BookmarksState,
  ],
})
export class ResolutionStoreState {}
