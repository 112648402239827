import { Component } from '@angular/core';
import { Urls } from '@const/urls';
import { NavController } from '@ionic/angular';
import { routeNames } from '../../../app-routing/route-names.const';

@Component({
  selector: 'app-user-guide-page',
  templateUrl: './user-guide-page.component.html',
  styleUrls: ['./user-guide-page.component.scss'],
})
export class UserGuidePageComponent {
  public readonly userGuideUrl = Urls.userGuide;

  constructor(private navController: NavController) {}

  public navigateToMainScreen(): void {
    this.navController.navigateRoot(`/${routeNames.mainScreen}`);
  }

  public navigateToSettings(): void {
    this.navController.back();
  }
}
