import { Route, RoutePoint, RouteSubPhase } from '@npaShared/models/route/route.models';
import { getPhaseByPointId } from './phases-helper';

import { getPointByIdWithAdditionalInfo } from './point-helper';

/** получить подэтап по его идентификатору */
export const getSubphaseById = (route: Route, subphaseId: number): RouteSubPhase | null => {
  let subphase = null;
  route.route.phases.forEach((arrayPhase) =>
    arrayPhase.forEach((p) =>
      p.iterations.forEach((i) => {
        if (!subphase) {
          subphase = i.subPhases.find((sp) => sp.id === subphaseId) || null;
        }

        return subphase;
      }),
    ),
  );
  return subphase;
};

/** получить список подэтапов, которые на одной итерации вместе с подэтапом передаваемой точки */
export const getSubPhasesBelongingToIterationOfPoint = (route: Route, pointId: number): RouteSubPhase[] => {
  const pointWithAdditionalInfo = getPointByIdWithAdditionalInfo(route, pointId);

  if (!pointWithAdditionalInfo) {
    return [];
  }

  const phase = getPhaseByPointId(route, pointId);

  if (!phase) {
    return [];
  }

  const iteration = phase.iterations.find((i) => i.number === pointWithAdditionalInfo.phaseIterationNumber);

  return iteration?.subPhases || [];
};

/**
 * Вернуть массив точек на главной вертикали подэтапа
 *
 * @param subphase подэтап
 */
export const getMainVerticalPointsOnSubPhase = (subphase: RouteSubPhase): RoutePoint[] => {
  if (!subphase) {
    return [];
  }

  const points = [];
  subphase.points.forEach((arrayPoints) => arrayPoints.forEach((p) => points.push(p)));
  return points;
};

/** Получение всех точек находящихся на подэтапе с текущей, исключаю передаваемую */
export const getOtherMainVerticalPointsOnSubphaseByPointId = (route: Route, pointId: number): RoutePoint[] =>
  getMainVerticalPointsFlatOnSubPhaseByPointId(route, pointId).filter((point) => point.id !== pointId);

/**
 * получить список точек подэтапа, на котором находится передаваемая точка
 *
 * @param route
 * @param pointId идентификатор точки
 */
export const getMainVerticalPointsOnSubPhaseByPointId = (route: Route, pointId: number): RoutePoint[][] => {
  const pointWithAdditionalInfo = getPointByIdWithAdditionalInfo(route, pointId);

  if (!pointWithAdditionalInfo) {
    return [];
  }

  const subphase = getSubphaseById(route, pointWithAdditionalInfo.subPhaseId);
  return subphase.points;
};

/**
 * получить список точек подэтапа, на котором находится передаваемая точка
 *
 * @param route
 * @param pointId идентификатор точки
 */
export const getMainVerticalPointsFlatOnSubPhaseByPointId = (route: Route, pointId: number): RoutePoint[] =>
  getMainVerticalPointsOnSubPhaseByPointId(route, pointId).flat();

/** Получить следующую точку на подэтапе для переданной */
export const getNextPointOnSubPhase = (route: Route, pointId: number): RoutePoint[] => {
  const pointWithAdditionalInfo = getPointByIdWithAdditionalInfo(route, pointId);
  const subPhase = getSubphaseById(route, pointWithAdditionalInfo.subPhaseId);
  const pointsOnSubPhase = subPhase?.points || [];
  const indexCurrentPoint = indexOfPointOnSubPhase(pointsOnSubPhase, pointId);

  if (indexCurrentPoint === pointsOnSubPhase.length - 1) {
    return [];
  }

  return pointsOnSubPhase?.[indexCurrentPoint + 1] || [];
};

export const indexOfPointOnSubPhase = (points: RoutePoint[][], pointId: number): number => {
  let indexArrayPoint = -1;

  points.forEach((ps, index) => {
    if (ps.some((p) => p.id === pointId)) {
      indexArrayPoint = index;
    }
  });

  return indexArrayPoint;
};
