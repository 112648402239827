import { Pipe, PipeTransform } from '@angular/core';
import { FileTypeEnum } from '@enums/file-type.enum';

@Pipe({
  name: 'documentType',
})

/** Убирает расширение из оригинального наименования файла */
export class DocumentTypePipe implements PipeTransform {
  private readonly showType = {
    [FileTypeEnum.additional]: 'ДОП.',
  };

  public transform(type: string): string {
    if (type === FileTypeEnum['additional']) {
      return this.showType[FileTypeEnum['additional']];
    }
    return '';
  }
}
