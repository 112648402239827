import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SuggestEmployeeParams, Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { PaginationBaseModel } from '@npaShared/models/pagination-base.model';

import { InterceptorErrorConfig } from '@shared/interceptors/loader/interceptor-config.model';
import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';
import { DeadLineDateModel } from '../models/dictionaries/dead-line-date.model';
import { SpecialEmployeeModel } from '../models/dictionaries/special-employee.model';
import { TemplateModel } from '../models/dictionaries/template.model';
import { UrgencyModel } from '../models/dictionaries/urgency.model';
import { CheckDateModel } from '../models/resolutions/check-date.model';
import { DepartmentModel } from '../models/resolutions/department/department.model';
import { EmployeeModel } from '../models/resolutions/employee/employee.model';

@Injectable({
  providedIn: 'root',
})
export class DictionariesInteropService {
  constructor(private http: HttpClient) {}

  /** Получить горячий список пользователей */
  public getHotEmployee(userId?: number, departmentId?: number): Observable<EmployeeModel[]> {
    const url = Urls.dictionaries.hotEmployee(userId, departmentId);
    return this.http.get<PaginationBaseModel<EmployeeModel>>(url).pipe(map((val) => val.data.content));
  }

  /** Получить словарь срочности */
  public getDictionariesUrgency(): Observable<UrgencyModel[]> {
    const url = Urls.dictionaries.urgency;
    return this.http.get<PaginationBaseModel<UrgencyModel>>(url).pipe(map((val) => val.data.content));
  }

  /** Получить полный список срочности */
  public getDictionariesAllUrgency(): Observable<CheckDateModel[]> {
    const url = Urls.dictionaries.allUrgency;
    return this.http.get<PaginationBaseModel<CheckDateModel>>(url).pipe(map((val) => val.data.content));
  }

  /** Получить список пользователей по поиску */
  public getUserList(params: SuggestEmployeeParams): Observable<EmployeeModel[]> {
    const url = Urls.dictionaries.suggestEmployee(params);
    return this.http.get<PaginationBaseModel<EmployeeModel>>(url).pipe(map((val) => val.data.content));
  }

  /** Получить список организаций по поиску */
  public getDeparmentsList(value: string): Observable<DepartmentModel[]> {
    const url = Urls.dictionaries.searchDepartment;
    const params = {
      fullName: value,
      skip: '0',
      take: '100',
    };
    return this.http
      .get<PaginationBaseModel<DepartmentModel>>(url, {
        params: new InterceptorHttpParams(new InterceptorErrorConfig(true), params),
      })
      .pipe(map((val) => val.data.content));
  }

  /** Получить список шаблонов */
  public getTemplatesList(): Observable<TemplateModel[]> {
    const url = Urls.dictionaries.templates;
    return this.http.get<PaginationBaseModel<TemplateModel>>(url).pipe(map((val) => val.data.content));
  }

  /** Получить дату для типа срочности */
  public getDeadLineDate(typeId: number, authorId?: number): Observable<DeadLineDateModel> {
    const url = authorId
      ? Urls.dictionaries.deadLineDateWithAuthor(typeId, authorId)
      : Urls.dictionaries.deadLineDate(typeId);
    return this.http.get<BaseApiResponseModel<DeadLineDateModel>>(url).pipe(map((val) => val.data));
  }

  /** Получить выходные дни */
  public getWeekends(): Observable<string[]> {
    const url = Urls.dictionaries.weekends;
    return this.http.get<PaginationBaseModel<string>>(url).pipe(map((val) => val.data.content));
  }

  /** Получить список специальных пользователей */
  public getSpecialEmployees(): Observable<SpecialEmployeeModel[]> {
    const url = Urls.dictionaries.specialEmployee;
    return this.http.get<PaginationBaseModel<SpecialEmployeeModel>>(url).pipe(map((val) => val.data.content));
  }

  public getEmployeeBrief(id: number): Observable<EmployeeModel> {
    const url = Urls.dictionaries.getEmployeeBrief(id);
    return this.http.get<BaseApiResponseModel<EmployeeModel>>(url).pipe(map((value) => value.data));
  }
}
