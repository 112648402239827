import { Injectable } from '@angular/core';

import * as cprocsp from 'cordova-plugin-cprocsp/www/cprocsp.js';

import { File } from '@awesome-cordova-plugins/file/ngx';
import { Filesystem } from '@capacitor/filesystem';
import { SettingsResponseCertificateModel } from '@models/settings/settings-response/settings-response-certificate.model';

import { Base64ToBlobService } from './base64-to-blob.service';
import { FileHelperService } from './file-helper.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class FileCertificateHelperService {
  public readonly pfxFolder = 'pfx';
  public pathAppDocuments = '';
  public pathAppWithFolderInDocuments = '';

  /** код ошибки - нажали Отмена при вводе пароля подписания документа */
  private readonly codeErrorCanceledSignInCryptoPro = '2148532334';
  /** код ошибки - не установили лицензию */
  private readonly codeErrorDontInstalledLicense = '2148073501';
  /** код ошибки - нажали Отмена при добавлении сертификата */
  private readonly codeErrorCanceledWhenImportingCertificate = '2146434962';

  constructor(
    private file: File,
    private fileHelper: FileHelperService,
    private base64ToBlobService: Base64ToBlobService,
    private modalService: ModalService,
  ) {
    this.pathAppDocuments = this.file.documentsDirectory;
    this.pathAppWithFolderInDocuments = this.pathAppDocuments + this.pfxFolder + '/';
  }

  public async openLaunchPane(): Promise<void> {
    try {
      await cprocsp.launchPane();
    } catch (e) {
      console.log('open launchPane ERROR', e);
    }
  }

  public async clear(): Promise<void> {
    try {
      await cprocsp.clear();
    } catch (e) {}
  }

  public async importPxf(fileName: string, password: string): Promise<void> {
    await cprocsp.importPxf(this.pfxFolder, fileName, password);
  }

  public async getListCertificates(): Promise<SettingsResponseCertificateModel[]> {
    try {
      return await cprocsp.getCertificates();
    } catch (e) {
      return [];
    }
  }

  public async writeToFileString(data: string): Promise<string | null> {
    const blob = this.base64ToBlobService.convertString(data);
    return this.writeAndSign(blob);
  }

  public async writeToFileAndSign(base64: string): Promise<string | null> {
    const blob = this.base64ToBlobService.convertBase64(base64);
    return this.writeAndSign(blob);
  }

  public showErrorMessageWhenSign(code: string): boolean {
    const listCodeErrorWhenDontShowMessage = [
      this.codeErrorCanceledSignInCryptoPro,
      this.codeErrorDontInstalledLicense,
    ];

    return !listCodeErrorWhenDontShowMessage.includes(code);
  }

  public showErrorMessageWhenImportingCertificate(code: string): boolean {
    return code !== this.codeErrorCanceledWhenImportingCertificate;
  }

  private async writeAndSign(blob: Blob): Promise<string | null> {
    const tmpFileName = 'tmp.pdf';
    const tmpFileNameSig = tmpFileName + '.sig';
    const path = `${this.pathAppDocuments}${tmpFileNameSig}`;

    try {
      await this.file.writeFile(this.pathAppDocuments, tmpFileName, blob);
      await cprocsp.sign(tmpFileName);
      const signed = await Filesystem.readFile({ path });

      if (signed) {
        const dataToSend = signed.data;
        return dataToSend;
      }
    } catch (e) {
      console.log('writeToFile catch ERROR', e);

      const listCodeErrorWhenDontShowMessage = [
        this.codeErrorCanceledSignInCryptoPro,
        this.codeErrorDontInstalledLicense,
      ];

      if (!listCodeErrorWhenDontShowMessage.includes(e.code)) {
        this.modalService.presentModalError('Произошла ошибка. Повторите снова');
      }
      return null;
    } finally {
      await this.fileHelper.checkAndRemoveFile(this.pathAppDocuments, tmpFileName);
      await this.fileHelper.checkAndRemoveFile(this.pathAppDocuments, tmpFileNameSig);
    }
  }
}
