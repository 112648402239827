import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ApprovalHelperService } from '@oogShared/services/approval-helper.service';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';

import { ApprovalParticipantTypeNamesEnum } from '@oogShared/enums/approval-list/approval-participant-type-names.enum';
import { DocumentNavigationService } from '@oogShared/services/document-navigation.service';
import { ApprovalModalBase } from '../approval-modal-base/approval-modal-base';

@Component({
  selector: 'app-approval-modal-return',
  templateUrl: './approval-modal-return.component.html',
  styleUrls: ['./approval-modal-return.component.scss'],
})
export class ApprovalModalReturnComponent extends ApprovalModalBase implements OnInit {
  public form: FormGroup = new FormGroup({});

  public userList: EmployeeModel[] = [];
  public showChooseAddressee = false;

  constructor(
    currentUser: CurrentUserIsproStoreService,
    modalCtrl: ModalController,
    approvalHelperService: ApprovalHelperService,
    store: Store,
    private fb: FormBuilder,
    private documentNavigationService: DocumentNavigationService,
  ) {
    super(currentUser, modalCtrl, approvalHelperService, store);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.headerData.title = 'Возврат';
    this.initForm();
    this.initUsersList();
    super.getPreviousComment();
  }

  public submitForm(): void {
    this.approvalHelperService
      .returnApproval(this.form.value, this.approvalData)
      .pipe(first())
      .subscribe(() => {
        this.documentNavigationService.nextDocument();
        this.previousStep();
      });
  }

  /** Вставить предыдущий */
  public getPreviousComment(): void {
    const text = this.form.controls.text;
    text.setValue(this.previousComment);
  }

  /** Очистить комментарий */
  public clearComment(): void {
    this.form.controls.text.reset();
  }

  /** Инициализация формы */
  private initForm(): void {
    this.form = this.fb.group({
      addressee: ['', Validators.required],
      confidential: [false],
      text: [''],
    });
  }

  /** Список сотрудников, которым можно вернуть лист согласования */
  private initUsersList(): void {
    const currentUserId = this.currentUser?.getCurrentUserSnapshot()?.id;
    let users = this.store.selectSnapshot(ActiveCardState.previousParticipantsEmployee).reverse();

    if (users.some((user) => user.type === ApprovalParticipantTypeNamesEnum.initiator)) {
      users = users.filter(
        (user) => user.id !== currentUserId || user.type === ApprovalParticipantTypeNamesEnum.initiator,
      );
    }

    for (const item of users) {
      this.userList.push(item);
    }
  }
}
