import { Pipe, PipeTransform } from '@angular/core';

import { PersonalFolder } from '../../models/folders/personal-folders.model';

@Pipe({
  name: 'folderLength',
})
export class FolderLengthPipe implements PipeTransform {
  public transform(folder: PersonalFolder): number {
    return this.getLengthOfFolder(folder);
  }

  private getLengthOfFolder(folder: PersonalFolder): number {
    if (folder.folders?.length) {
      return (
        folder.personalFolderDocuments.length +
        folder.folders.reduce((acc, subFolder) => acc + this.getLengthOfFolder(subFolder), 0)
      );
    }
    return folder.personalFolderDocuments.length;
  }
}
