import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

import { RadioButtonThemeEnum } from '@enums/radio-button-theme.enum';

@Component({
  selector: 'app-radio-item-filter',
  templateUrl: './radio-item-filter.component.html',
  styleUrls: ['./radio-item-filter.component.scss'],
})
export class RadioItemFilterComponent {
  @Input() public value: any;

  @HostBinding('class.disabled')
  @Input()
  public disabled: boolean;

  @HostBinding('attr.theme')
  @Input()
  public theme: RadioButtonThemeEnum;

  @Output() public selectEvent = new EventEmitter<any>();

  @HostBinding('class.active') public isActive = false;

  @HostListener('click')
  public onSelect(): void {
    this.selectEvent.emit(this.value);
  }
}
