import { Directive } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

import { AddControlWithGroupModel } from '../shared/models/forms/add-control-with-group.model';

@Directive()
export class FormHelper {
  /** Отключить контролы на форме */
  protected disableControls(group: FormGroup, controls: string[]): void {
    controls.forEach((c) => group.controls[c]?.disable());
  }

  protected enableControls(group: FormGroup, controls: string[]): void {
    controls.forEach((c) => group.controls[c]?.enable());
  }

  /** Удалить контролы из формы */
  protected removeControls(group: FormGroup, controls: string[]): void {
    controls.forEach((c) => group.removeControl(c));
  }

  /** Добавить контролы в форму */
  protected addControls(group: FormGroup, controls: AddControlWithGroupModel[]): void {
    controls.forEach((c) => group.addControl(c.name, new FormControl(c.value, c.validators)));
  }

  /** Проверить, является ли переданный аргумент FormGroup */
  protected checkFormGroup(item: FormGroup | AbstractControl): item is FormGroup {
    return !!(item as FormGroup)?.controls;
  }

  /** Перемещение элементов массива между собой */
  protected swapElements(form: FormGroup, array: FormArray, index: number, action: string): void {
    if (action === 'down') {
      this.incrementElements(array, index);
    } else {
      this.decrementElements(array, index);
    }
    form.updateValueAndValidity();
    array.updateValueAndValidity();
  }

  /** Добавить контрол, который устанавливает исполнителя как клона */
  protected setCloneControl(performers: FormArray, employees: EmployeeModel[]): void {
    if (employees?.length > 1) {
      this.addControls(performers.controls[performers.length - 1] as FormGroup, [
        { name: 'clone', value: true, validators: [] },
      ]);
    }
  }

  private incrementElements(array: FormArray, index: number): void {
    const current = array.controls[index].get('serialNumber')?.value;
    array.controls[index].get('serialNumber')?.setValue(current + 1);
    array.controls[index + 1].get('serialNumber')?.setValue(current);
    [array.controls[index], array.controls[index + 1]] = [array.controls[index + 1], array.controls[index]];
  }

  private decrementElements(array: FormArray, index: number): void {
    const current = array.controls[index].get('serialNumber')?.value;
    array.controls[index].get('serialNumber')?.setValue(current - 1);
    array.controls[index - 1].get('serialNumber')?.setValue(current);
    [array.controls[index], array.controls[index - 1]] = [array.controls[index - 1], array.controls[index]];
  }
}
