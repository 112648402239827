import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';

export const TextMessageByActionTypeMap = {
  [ApproveTypeEnum.approve]:
    'Согласование без замечаний недоступно. В документах раздела «Проект, приложения и пояснительная записка» есть замечания/альтернативы/комментарии',
  [ApproveTypeEnum.approveLuz]:
    'Согласование с замечаниями недоступно. В документах раздела «Проект, приложения и пояснительная записка» отсутствуют замечания/альтернативы/комментарии',
  [ApproveTypeEnum.startApprovement]:
    'Отправка на согласование без замечаний недоступна. В документах раздела «Проект, приложения и пояснительная записка» есть замечания/альтернативы/комментарии',
  [ApproveTypeEnum.startApprovementWithRemarks]:
    'Отправка на согласование с замечаниями недоступна. В документах раздела «Проект, приложения и пояснительная записка» отсутствуют замечания/альтернативы/комментарии',
  [ApproveTypeEnum.sendToOauLeader]:
    'Направление руководителю недоступно. В документах раздела «Проект, приложения и пояснительная записка» есть замечания/альтернативы/комментарии',
} as const;
