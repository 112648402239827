export const GENERAL_TEXT_ERRORS = {
  systemError: 'Системная ошибка',
  unknownError: 'Неизвестная ошибка',
  actionCannotBePerformed: 'Выполнить действие невозможно',
} as const;

export const DOCUMENTS_TEXT_ERRORS = {
  versionsNotExist:
    'Отсутствуют доступные версии документов раздела «Проект, приложения и пояснительная записка». Обратитесь к сотруднику ОИВ, ответственному за передачу прав доступа к документам в системе.',
} as const;
