import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import * as dayjs from 'dayjs';

import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class BackgroundHelperService {
  public appIsActive$: Observable<boolean>;

  private time = null;
  /** время, через которое надо перекинуть на главный экран */
  private millisecondsForUpdate = 60 * 60 * 2 * 1000;
  private canUpdate$ = new Subject<void>();

  private appIsActiveSubject$ = new BehaviorSubject<boolean>(true);

  constructor(private platform: Platform) {
    this.appIsActive$ = this.appIsActiveSubject$.asObservable();
  }

  public init(): void {
    this.platform.pause.subscribe(() => {
      this.appIsActiveSubject$.next(false);
      this.time = dayjs();
    });

    this.platform.resume.subscribe(() => {
      this.appIsActiveSubject$.next(true);

      const now = dayjs();
      if (now.diff(this.time) > this.millisecondsForUpdate) {
        this.canUpdate$.next();
      }
      this.time = null;
    });
  }

  public canUpdate(): Observable<void> {
    return this.canUpdate$.asObservable();
  }
}
