import { AddresseeForReviewerPipe } from '@oogShared/pipes/addressee-for-reviewer/addressee-for-reviewer.pipe';
import { BranchHiddenPipe } from '@oogShared/pipes/branch-hidden/branch-hidden.pipe';
import { ChildDocumentInGroupPipe } from '@oogShared/pipes/child-document-in-group/child-document-in-group.pipe';
import { CollectEmployeeFromAddresseePipe } from '@oogShared/pipes/collect-employee-from-addressee/collect-employee-from-addressee.pipe';
import { FilterArrayStringPipe } from '@oogShared/pipes/filter-array-string.pipe';
import { FindActiveFilePipe } from '@oogShared/pipes/find-active-file/find-active-file.pipe';
import { FindMainFilePipe } from '@oogShared/pipes/find-main-file/find-main-file.pipe';
import { MainDocumentInGroupPipe } from '@oogShared/pipes/main-document-in-group/main-document-in-group.pipe';

import { ReportsSearchPipe } from '@oogShared/pipes/reports-search/reports-search.pipe';
import { ReportsSortPipe } from '@oogShared/pipes/reports-sort/reports-sort.pipe';
import { DepartmentShortPipe } from '@oogShared/pipes/department-short/department-short.pipe';
import { TimeOfAgendaPipe } from '@oogShared/pipes/time-of-agenda/time-of-agenda.pipe';
import { RequestHotListEmployeePipe } from '@oogShared/pipes/request-hot-list-employee/request-hot-list-employee.pipe';
import { ActingTypePipe } from './acting-type/acting-type.pipe';
import { ApprovalParallelStatusPipe } from './approvals/approval-parallel-status.pipe';
import { ApprovalRedirectWithCommentPipe } from './approvals/approval-redirect-with-comment/approval-redirect-with-comment.pipe';
import { BehalfOfDisplayAddresseePipe } from './behalf-of-display-addresse/behalf-of-display-addressee.pipe';
import { BehalfOfDisplayApprovalPipe } from './behalf-of-display-approval/behalf-of-display-approval.pipe';
import { BehalfOfDisplayPipe } from './behalf-of-display.pipe';
import { CompareAuthorPipe } from './compare-author/compare-author.pipe';
import { CompareCurrentUserWithAcquaintancePipe } from './compare-current-user-with-acquaintance/compare-current-user-with-acquaintance.pipe';
import { CountDaysStringPipe } from './count-days-string/count-days-string.pipe';
import { DateExpiredPipe } from './date-expired/date-expired.pipe';
import { DocumentNamePipe } from './document-name/document-name.pipe';
import { DocumentShowRightDaysPipe } from './document-right-days/document-show-right-days.pipe';
import { DocumentTypePipe } from './document-type/document-type.pipe';
import { DownloadDocumentPipe } from './download-document.pipe';
import { EmployeeReviewersResolverPipe } from './employee-reviewers/employee-reviewers.pipe';
import { EmployeeShortNamePipe } from './employee-short-name/employee-short-name.pipe';
import { ExistInSelectedFoldersPipe } from './exist-in-selected-folders/exist-in-selected.pipe';
import { ExpiredDaysStringPipe } from './expired-days-string/expired-days-string.pipe';
import { FindDocumentInFoldersPipe } from './find-document-in-folders/find-document-in-folder.pipe';
import { FolderLengthPipe } from './folder-length/folder-length.pipe';
import { PerformerShortNameGenitivePipe } from './performer-short-name-genetive/performer-short-name-genitive.pipe';
import { PhotoAddresseePipe } from './photo-addressee/photo-addressee.pipe';
import { PrivateCommentResolutionPipe } from './private-comment-resolution/private-comment-resolution.pipe';
import { ReturnAuthorPipe } from './return-author/return-author.pipe';
import { ReversePipe } from './reverse.pipe';
import { ViewPrivateCommentPipe } from './view-private-comment.pipe';
import { CommissionTypePipe } from './commission-type.pipe';
import { IsMainCardInGroupPipe } from './is-main-card-in-group/is-main-card-in-group.pipe';
import { ResolutionProjectTypePipe } from './resolution-project-type-pipe';

export const PipesOog = [
  PrivateCommentResolutionPipe,
  ActingTypePipe,
  CountDaysStringPipe,
  DocumentNamePipe,
  DocumentTypePipe,
  DocumentShowRightDaysPipe,
  EmployeeReviewersResolverPipe,
  CompareAuthorPipe,
  PhotoAddresseePipe,
  BehalfOfDisplayPipe,
  ReturnAuthorPipe,
  EmployeeShortNamePipe,
  PerformerShortNameGenitivePipe,
  ExpiredDaysStringPipe,
  ReversePipe,
  FolderLengthPipe,
  ApprovalParallelStatusPipe,
  ExistInSelectedFoldersPipe,
  FindDocumentInFoldersPipe,
  CompareCurrentUserWithAcquaintancePipe,
  DateExpiredPipe,
  BehalfOfDisplayAddresseePipe,
  ApprovalRedirectWithCommentPipe,
  BehalfOfDisplayApprovalPipe,
  AddresseeForReviewerPipe,
  ChildDocumentInGroupPipe,
  MainDocumentInGroupPipe,
  FindActiveFilePipe,
  FindMainFilePipe,
  ViewPrivateCommentPipe,
  DownloadDocumentPipe,
  BranchHiddenPipe,
  ReportsSearchPipe,
  ReportsSortPipe,
  DepartmentShortPipe,
  CollectEmployeeFromAddresseePipe,
  FilterArrayStringPipe,
  CommissionTypePipe,
  IsMainCardInGroupPipe,
  TimeOfAgendaPipe,
  ResolutionProjectTypePipe,
  RequestHotListEmployeePipe,
];
