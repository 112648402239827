import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-template-modal-with-icon-alarm',
  templateUrl: './template-modal-with-icon-alarm.component.html',
  styleUrls: ['./template-modal-with-icon-alarm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateModalWithIconAlarmComponent {
  @Input() public title = '';
  @Input() public text = '';
  @Input() public buttonLabel = '';

  constructor(private modalController: ModalController) {}

  public close(): void {
    this.modalController.dismiss();
  }
}
