import { Pipe, PipeTransform } from '@angular/core';

/** Пайп времени проведения, срезает таймзону */
@Pipe({
  name: 'timeOfAgenda',
})
export class TimeOfAgendaPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return '';
    }

    return value.split('+')[0];
  }
}
