import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Urls } from '@const/urls';
import { throwError } from 'rxjs';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { catchError, first } from 'rxjs/operators';

/** Сервис для сохранения черновиков */
@Injectable({
  providedIn: 'root',
})
export class DraftsInteropService {
  constructor(private http: HttpClient) {}

  public saveDraft(body: ResolutionModel): void {
    const url = Urls.drafts.save;
    this.http
      .post<BaseApiResponseModel<void>>(url, body)
      .pipe(
        first(),
        catchError((error) => throwError(error)),
      )
      .subscribe();
  }
}
