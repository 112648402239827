import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent {
  @Input() public dateOfAgenda = '';
  @Input() public timeOfAgenda = '';
  @Input() public placeOfAgenda = '';
}
