import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';

import { ApprovementDefaultLogic } from '../approvement-default-logic';
import { PointLogicModel } from '../point-logic.interface';

/** Логика точки согласования "Оформление в ОАУ. Согласование" */
export class OauRegistrationApprovmentPoint extends ApprovementDefaultLogic implements PointLogicModel {
  public canStartApprovement(): boolean {
    return false;
  }

  public canSendRemarksToAuthor(): boolean {
    return false;
  }

  public canApprove(): boolean {
    const allowedRoles = [EmployeeRouteTypes.coordinator, EmployeeRouteTypes.leader];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    return true;
  }

  public canApproveWithRemarks(): boolean {
    const disallowedRoles = [EmployeeRouteTypes.leader];

    if (this.isContainsPointRole(disallowedRoles)) {
      return false;
    }

    return this.canApprove();
  }
}
