import { Injectable } from '@angular/core';

import { State } from '@ngxs/store';
import { RelevantWorkersState } from '@store/main-screen/relevant-workers/relevant-workers.state';
import { StatisticEmployeeFromState } from '@store/main-screen/statistic-employee-from/statistic-employee-from.state';
import { WorkForEveryoneState } from '@store/main-screen/work-for-everyone/work-for-everyone.state';

@Injectable()
@State<[]>({
  name: 'mainScreen',
  children: [RelevantWorkersState, WorkForEveryoneState, StatisticEmployeeFromState],
})
export class MainScreenState {}
