import { Pipe, PipeTransform } from '@angular/core';

import { EmployeeBaseModel } from '@npaShared/models/employee/employee-base.model';

@Pipe({
  name: 'employeeShortName',
})
export class EmployeeShortNamePipe implements PipeTransform {
  public transform(employee: EmployeeBaseModel): string {
    if (!employee) {
      return '';
    }

    const lastName = employee.lastName || '';
    const shortFirstName = employee.firstName ? `${employee.firstName[0]}.` : '';
    const shortPatronymic = employee.patronymic ? `${employee.patronymic[0]}.` : '';

    // Добавил неразрывный пробел между фамилией и инициалами для избежания переноса последних отдельно от фамилии
    return `${lastName} ${shortFirstName}${shortPatronymic}`;
  }
}
