import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterceptorHttpParams } from '@shared/interceptors/loader/interceptor-http-params.model';

@Injectable()
export class HttpInterceptorCacheService implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!(req.params instanceof InterceptorHttpParams)) {
      return next.handle(req);
    }

    if (!req.params.interceptorConfig.cache) {
      const httpRequest = req.clone({
        url: `${req.url}?frl=${Math.random()}`,
      });

      return next.handle(httpRequest);
    }

    return next.handle(req);
  }
}
