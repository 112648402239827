import { CompletedState } from '@npaShared/consts/completed-state.const';
import { VertexState } from '@npaShared/enums/vertex-state.enum';
import { Route, RoutePhase, RoutePoint, RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';

import { RoutePhaseTypes } from 'src/app/npa/document-agreement/enums/route-phase-types.enum';
import { getAllSidePointsOfPoint, getPointByIdWithAdditionalInfo } from './point-helper';

/** Получение всех параллельных этапов на маршруте */
export const getAllParallelPhases = (route: Route): RoutePhase[] => {
  const phases = [];
  route.route.phases
    .filter((arrayPhases) => arrayPhases.length > 1)
    .forEach((arrayPhases) => phases.push(...arrayPhases));

  return phases;
};

/** Определяет есть ли у параллельных этапов на маршруте хоть одна активная точка */
export const isSomeActiveParallelPhase = (route: Route): boolean => {
  const parallelPhases = getAllParallelPhases(route);
  const allPointsFromParallelPhases: RoutePoint[] = [];

  parallelPhases.forEach((parallelPhase) => {
    const points = getAllPointsOfPhase(parallelPhase, false);
    allPointsFromParallelPhases.push(...points);
  });

  return allPointsFromParallelPhases.some((p) => p.taskResolution === VertexState.active);
};

/** Получение параллельных этапов для передваемого этапа */
export const getParallelPhasesOfPhase = (route: Route, phase: RoutePhase): RoutePhase[] =>
  route.route.phases
    .find((arrayPhase) => arrayPhase.find((p) => p.id === phase.id))
    .filter((clusterPhase) => clusterPhase.id !== phase.id);

/** @returns true, если этап параллельный */
export const phaseIsParallel = (route: Route, phaseId: number): boolean => {
  let isParallel = false;

  route.route.phases.forEach((arrayPhase) => {
    const phase = arrayPhase.find((p) => p.id === phaseId);
    if (phase) {
      isParallel = arrayPhase.length > 1;
    }
  });

  return isParallel;
};

/** Возврат этапа, в котором находится передаваемая точка */
export const getPhaseByPointId = (route: Route, pointId: number): RoutePhase | undefined => {
  const pointWithAdditionalInfo = getPointByIdWithAdditionalInfo(route, pointId);

  if (!pointWithAdditionalInfo) {
    throw new Error('Не удалось найти точку!');
  }

  const phasesCluster = route.route.phases.find((arrayPhase) =>
    arrayPhase.find((p) => p.id === pointWithAdditionalInfo.phaseId),
  );

  return phasesCluster?.find((phase) => phase.id === pointWithAdditionalInfo.phaseId);
};

/** Определяет имеют ли статус завершенности паралельные данному этапы */
export const isAllParallelPhasesCompleted = (route: Route, phase: RoutePhase): boolean => {
  const parallelPhases = getParallelPhasesOfPhase(route, phase);
  const allPointsFromParallelPhases: RoutePoint[] = [];

  parallelPhases.forEach((parallelPhase) => {
    const points = getAllPointsOfPhase(parallelPhase);
    allPointsFromParallelPhases.push(...points);
  });

  /** этапы считаю завершенными, если все точки параллельных этапов, которые дошли на фронт имеют статус выполненных */
  return allPointsFromParallelPhases.every((p) => CompletedState.includes(p.taskResolution));
};

/** получить все точки этапа */
export const getAllPointsOfPhase = (phase: RoutePhase, includeSidePoints = true): RoutePoint[] => {
  const points: RoutePoint[] = [];

  phase?.iterations?.forEach((iteration) =>
    iteration.subPhases.forEach((subphase) => {
      subphase.points.forEach((pointsCluster) => {
        pointsCluster.forEach((mainVerticalPoint) => {
          points.push(mainVerticalPoint);
          if (includeSidePoints) {
            const sidePoints = getAllSidePointsOfPoint(mainVerticalPoint);
            points.push(...sidePoints);
          }
        });
      });
    }),
  );

  return points;
};

/**
 * Может ли активная точка на своём этапе согласовать без подписи
 *
 * @param isLocalLegalActsType является ли переданный ПД локально правовым актом - ЛПА
 * @param canApproveWithoutSignatureToUserSettings не обязательно согласовывать с ЭП из глобальной настройки
 * @param route маршрут
 * @param activePoint активная точка
 * @returns
 */
export const checkApproveWithoutSignature = (
  isLocalLegalActsType: boolean,
  canApproveWithoutSignatureToUserSettings: boolean,
  route: Route,
  activePoint: RoutePointWithAdditionalInfo,
): boolean => {
  if (!isLocalLegalActsType) {
    return canApproveWithoutSignatureToUserSettings;
  }

  const phase = getPhaseByPointId(route, activePoint.id);
  const isProjectCreatedPhase = phase.phaseTypeId === RoutePhaseTypes.projectCreated;

  if (isProjectCreatedPhase) {
    return canApproveWithoutSignatureToUserSettings;
  }

  return Boolean(phase.phaseTemplate?.approveWithoutSignatureAllowed);
};
