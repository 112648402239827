import { Injectable } from '@angular/core';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { LocalStorageItems } from '@enums/local-storage-items.enum';

/** Сервис для управлениями черновиками СПР/СПЗ в локальном хранилище */
@Injectable({ providedIn: 'root' })
export class ResolutionDraftStorageService {
  public saveResolutionDraft(resolution: ResolutionModel): void {
    const state = localStorage.getItem(LocalStorageItems.DraftResolutions);
    const jsonState = JSON.parse(state) || [];
    const currentIndex = jsonState.findIndex((item) => item.uniqueId === resolution.uniqueId);

    if (currentIndex === -1) {
      jsonState.push(resolution);
    } else {
      jsonState[currentIndex] = resolution;
    }

    localStorage.setItem(LocalStorageItems.DraftResolutions, JSON.stringify(jsonState));
  }

  public removeResolutionDraft(resolution: ResolutionModel): void {
    const state = localStorage.getItem(LocalStorageItems.DraftResolutions);
    const jsonState = JSON.parse(state) || [];
    const newState = jsonState.filter((item) => item.uniqueId !== resolution.uniqueId);

    localStorage.setItem(LocalStorageItems.DraftResolutions, JSON.stringify(newState));
  }

  public clear(): void {
    localStorage.removeItem(LocalStorageItems.DraftResolutions);
  }
}
