import { Pipe, PipeTransform } from '@angular/core';

import { UserInfoResponseModel } from '@npaShared/models/user-info.model';

@Pipe({
  name: 'delegatingByUserInfo',
})
export class DelegatingByUserInfoPipe implements PipeTransform {
  public transform(userInfo: UserInfoResponseModel): string | null {
    // не может быть никакого делегирования, если текущий пользователь равен реальному
    if (userInfo?.currentUser?.employee?.id === userInfo?.realUser?.employee?.id) {
      return null;
    }

    return userInfo.isActingUser ? 'исполнение обязанностей' : 'работа от имени';
  }
}
