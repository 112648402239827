import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { CssOptionsModel } from '@models/css-options.model';
import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { AboutEmployeeOogPipe } from '@shared/pipes/about-employee-oog/about-employee-oog.pipe';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { BaseUnsubscribe } from '@oogShared/base-classes/base-unsubscribe';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent extends BaseUnsubscribe implements OnInit {
  @Input() public options: CssOptionsModel;
  @Input() public employee: SharedInfoAboutEmployee;
  @Input() public employeeId: number;

  private userCardWidth: number; // Ширина карточки
  private userCardHeight: number; // Высота карточки
  private gapX = 20; // Отступ по Х если карточка не влезает по ширине
  private gapY = 50; // Отступ по Y если карточка не влезает по ширине

  public employee$: BehaviorSubject<SharedInfoAboutEmployee> = new BehaviorSubject<SharedInfoAboutEmployee>(null);

  constructor(
    private elementRef: ElementRef,
    private dictionariesInterop: DictionariesInteropService,
    private aboutEmployeePipe: AboutEmployeeOogPipe,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.employee) {
      this.employee$.next(this.employee);
    }
    if (this.employeeId) {
      this.dictionariesInterop
        .getEmployeeBrief(this.employeeId)
        .pipe(
          takeUntil(this.unsubscribe),
          map((employee) => this.aboutEmployeePipe.transform(employee)),
        )
        .subscribe((aboutEmployee) => this.employee$.next(aboutEmployee));
    }
    this.userCardWidth = this.getWidth();
    this.userCardHeight = this.getHeight();

    this.setCardPosition();
  }

  // Получить ширину карточки
  private getWidth(): number {
    return this.elementRef.nativeElement.childNodes[0].clientWidth;
  }

  // Получить высоту карточки
  private getHeight(): number {
    return this.elementRef.nativeElement.childNodes[0].clientHeight;
  }

  // Установить положение карточки
  private setCardPosition(): void {
    if (this.options.x + this.userCardWidth >= window.innerWidth) {
      this.options.x = window.innerWidth - this.userCardWidth - this.gapX;
    }

    if (this.options.y + this.userCardHeight >= window.innerHeight) {
      this.options.y = window.innerHeight - this.userCardHeight - this.gapY;
    }
  }
}
