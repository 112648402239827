import { CertificatesSettingsNpaModel } from '@models/settings/certificates-settings.model';
import { BaseSettingsCertificates } from './base-settings';

export class NpaSettingsCertificates extends BaseSettingsCertificates {
  public getAllSettings(): CertificatesSettingsNpaModel {
    return {
      agreedNpa: this.certificateAgreed,
      defaultCertificateNpa: this.defaultCertificate,
      singingWithoutConfirmationNpa: this.singingWithoutConfirmation,
    };
  }

  protected getItem(key: string): string {
    return localStorage.getItem(`npa-settingsCertificate-${key}`);
  }

  protected setItem(key: string, value: string): void {
    localStorage.setItem(`npa-settingsCertificate-${key}`, value);
  }
}
