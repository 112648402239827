import { LoadingController, ModalController } from '@ionic/angular';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { FileCertificateHelperService } from '@shared/services/file-certificate-helper.service';
import { SigningEnum } from '@npaShared/enums/signing.enum';
import { AgreementService } from '../../../../document-agreement/services/agreement.service';
import { CloudSigning } from './logic-cloud-signing';
import { LocalSigning } from './logic-local-signing';
import { WithoutSigning } from './logic-without-signing';

/** получить нужный класс подписи */
export const SignFactory = (
  sign: SigningEnum,
  agreementHelper: AgreementService,
  modalController: ModalController,
  loadingController: LoadingController,
  fileCertificateHelper: FileCertificateHelperService,
  userStore: CurrentUserAissdStoreService,
): any => {
  switch (sign) {
    case SigningEnum.withoutSign:
      return new WithoutSigning(loadingController, modalController);
    case SigningEnum.local:
      return new LocalSigning(loadingController, modalController, agreementHelper, fileCertificateHelper);
    case SigningEnum.cloud:
      return new CloudSigning(loadingController, modalController, userStore);
  }
};
