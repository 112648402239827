import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { Store } from '@ngxs/store';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';

import { GroupViewFlagsModel } from '@oogShared/models/group-view-flags/group-view-flags.model';
import { ConfirmGroupDocumentComponent } from '../components/confirm/confirm-group-document/confirm-group-document.component';
import { CardDocumentModel } from '../models/documents/card-document.model';
import { ResolutionInteropService } from '../rest/resolution-interop.service';
import { ConfirmDialogService } from './confirm-dialog.service';

/** Сервис работы с групповой резолюцией */
@Injectable({
  providedIn: 'root',
})
export class GroupDocumentsService {
  public chooseMainDocument$ = new BehaviorSubject<CardDocumentModel>(null);

  constructor(
    private store: Store,
    private resolutionInterop: ResolutionInteropService,
    private dialog: ConfirmDialogService,
  ) {}

  public isViewedAllInGroup(): Observable<boolean> {
    const id = this.store.selectSnapshot(ActiveCardState.appealMovementId);
    return this.resolutionInterop.isViewedAll(id).pipe(first());
  }

  public groupViewFlags(): Observable<GroupViewFlagsModel[]> {
    const id = this.store.selectSnapshot(ActiveCardState.appealMovementId);
    return this.resolutionInterop.groupViewFlags(id).pipe(first());
  }

  /** Проверить, что выбранный документ является групповым */
  public isGroupDocument(): boolean {
    const activeCard = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    return !!activeCard.groupProjects;
  }

  /** Проверить, что группа не расформирована */
  public isGroupDocumentActive(): boolean {
    const activeCard = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    if (!activeCard.groupProjects) {
      return false;
    }
    return activeCard.groupProjects.groupProjects.isActive;
  }

  public showConfirmDialogInGroup(componentName: string): Observable<boolean> {
    return this.dialog.show(ConfirmGroupDocumentComponent, componentName).pipe(
      first(),
      filter((r) => !!r),
    );
  }
}
