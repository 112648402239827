import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'branchHidden',
})
export class BranchHiddenPipe implements PipeTransform {
  public transform(ids: number[], id: number): boolean {
    return ids.includes(id);
  }
}
