import { Component } from '@angular/core';
import { BaseControl } from '@components/controls/control-base';

@Component({
  selector: 'app-input-with-removal',
  templateUrl: './input-with-removal.component.html',
  styleUrls: ['./input-with-removal.component.scss'],
})
/** Кастомный текстовый контрол с кнопкой удаления текста из контрола */
export class InputWithRemovalComponent extends BaseControl {
  /** убрали фокус с контрола */
  public onBlurAction(): void {
    this.trimControlValue();
  }

  public clearControl(): void {
    this.parentFormGroup.controls[this.controlName].reset();
  }
}
