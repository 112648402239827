import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { GlobalConfirmDialogModel } from '../../models/dialog/global-confirm-dialog.model';

@Directive()
export class DialogBase {
  @Input() public dialogInfo: GlobalConfirmDialogModel = {};

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public result = new EventEmitter<boolean>();

  public confirm(): void {
    this.result.emit(true);
  }

  public close(): void {
    this.result.emit(false);
  }
}
