import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { BaseAuthGuardService } from '@shared/services/base-auth-guard.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { UsersDataService } from '@npaCore/services/users-data.service';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { SettingsCertificateService } from '@shared/services/certificates/settings-certificate.service';
import { RelevantWorkersService } from '@oogShared/services/relevant-workers.service';
import { AuthorizationInformationService } from '@shared/services/authorization-information.service';
import { routeNames } from 'src/app/app-routing/route-names.const';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BaseAuthGuardService implements CanActivate {
  constructor(
    userApi: UserInfoApiService,
    userAissdStore: CurrentUserAissdStoreService,
    userIsproStore: CurrentUserIsproStoreService,
    authUserIspro: AuthUserIsproStoreService,
    store: Store,
    settingsCertificateService: SettingsCertificateService,
    relevantWorkersService: RelevantWorkersService,
    private navController: NavController,
    private userService: UsersDataService,
    private authorizationInformationService: AuthorizationInformationService,
  ) {
    super(
      userApi,
      // userIsproStore,
      // authUserIspro,
      store,
      settingsCertificateService,
      userAissdStore,
      relevantWorkersService,
    );
  }

  public canActivate(): Observable<boolean> {
    return this.getUsers().pipe(
      switchMap((userAissd) => {
        if (this.authorizationInformationService.isLoggedOut) {
          this.goToLoginPage();
          return of(false);
        }

        if (!userAissd) {
          this.goToLoginPage();
          return of(false);
        }
        return of(true);
      }),
    );
  }

  private goToLoginPage(): void {
    this.navController.navigateRoot([routeNames.auth, routeNames.login]);
  }

  // TODO: NPA-16522 отключение запросов ИСПРО
  private getUsers(): Observable<UserInfoResponseModel> {
    // const isPro$: Observable<CurrentUserModel | null> = this.userService.getOogUser$();
    const aisSd$: Observable<UserInfoResponseModel | null> = this.userService.getNpaUser$();

    return this.getUsersFromStoreOrApi(aisSd$);
    // return this.getUsersFromStoreOrApi(isPro$, aisSd$);
  }
}
