import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ButtonFontSizeEnum } from '@enums/button-font-size.enum';
import { ButtonIconEnum } from '@enums/button-icon.enum';
import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { PositionRadiusEnum } from '@enums/position-radius.enum';
import { ChoiceMainProjectModel } from '@oogShared/components/card-expedited-review/choice-main-project.model';
import { UrgencyTypeEnum } from '@oogShared/enums/document-card/urgency-type.enum';
import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';

@Component({
  selector: 'app-card-expedited-review',
  templateUrl: './card-expedited-review.component.html',
  styleUrls: ['./card-expedited-review.component.scss'],
})
export class CardExpeditedReviewComponent implements OnInit {
  @Input() public card: ExpeditedReviewModel;
  @Input() public activeId: number | null = null;
  @Input() public isSelected: boolean;

  @Output() public delete = new EventEmitter<ExpeditedReviewModel>();
  @Output() public accept = new EventEmitter<ExpeditedReviewModel>();
  @Output() public chooseCard = new EventEmitter<number>();
  @Output() public chooseMainCardInGroup = new EventEmitter<ChoiceMainProjectModel>();
  @Output() public toggleSelect = new EventEmitter<ExpeditedReviewModel>();

  public buttonTheme = ButtonThemeEnum;
  public buttonIcon = ButtonIconEnum;
  public radiusPosition = PositionRadiusEnum;
  public urgencyReview = UrgencyTypeEnum;
  public fontSizeButton = ButtonFontSizeEnum;
  public heightThreeLines = 59;
  public openedComment = false;
  public reviewers: ResolutionAddresseeModel[] = [];
  public resolutionTypeEnum = ResolutionTypeEnum;

  public ngOnInit(): void {
    this.reviewers = this.card.resolutionDto.missions
      .map((mission) => mission.addressee)
      .reduce((acc, prev) => [...prev, ...acc], []);
  }

  /** Метод отвечает за сворацивание/раскрытие бллока коментариев*/
  public clickOnCollapsedComment(event: Event): void {
    event.preventDefault();
    this.openedComment = !this.openedComment;
  }

  public clickOnMainProject(event: Event): void {
    this.chooseMainCardInGroup.emit({
      id: this.card.resolutionDto.groupProjects?.groupProjects.id,
      mainAppealMovement: this.card.resolutionDto.groupProjects?.groupProjects.mainAppealMovementId,
      event,
    });
  }
}
