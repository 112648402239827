import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';

/** Проверка на то, что передаваемая дата равна сегодняшней */
@Pipe({
  name: 'isToday',
})
export class IsTodayPipe implements PipeTransform {
  public transform(value: string): boolean {
    if (!value) {
      return null;
    }
    const now = dayjs();
    const date = dayjs(value);
    return date.isSame(now, 'day');
  }
}
