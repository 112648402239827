import { Pipe, PipeTransform } from '@angular/core';

import { WordCasesEnum } from '@enums/word-cases.enum';
import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';
import { OogShortEmployeeModel } from './oog-short-employee.model';

/** пайп возвращает строку с фамилией и инициалами пользователя ИС ПРО */
@Pipe({
  name: 'oogUserShortName',
})
export class OogUserShortNamePipe implements PipeTransform {
  public transform(
    employee: OogShortEmployeeModel | SharedInfoAboutEmployee,
    wordCase = WordCasesEnum.nominative,
  ): string {
    if (!employee) {
      return '';
    }

    let lastName = employee?.lastName || '';

    if (this.isOogShortEmployee(employee)) {
      switch (wordCase) {
        case WordCasesEnum.genitive:
          if (employee?.fioGenitive?.trim()) {
            lastName = employee.fioGenitive.split(' ')[0];
          }
          break;
        case WordCasesEnum.dative:
          if (employee?.fioDative?.trim()) {
            lastName = employee.fioDative.split(' ')[0];
          }
          break;
        case WordCasesEnum.accusative:
          if (employee?.fioDative?.trim()) {
            lastName = employee.fioAccusative.split(' ')[0];
          }
      }
    }

    const nameInitials = this.getNameInitials(employee?.firstName, employee?.middleName);

    if (!nameInitials) {
      return lastName;
    }

    // Добавил неразрывный пробел между фамилией и инициалами для избежания переноса последних отдельно от фамилии
    return `${lastName} ${nameInitials}`;
  }

  private getNameInitials(firstName: string, middleName: string): string {
    const shortFirstName = firstName ? `${firstName[0]}.` : '';
    const shortPatronymic = middleName ? `${middleName[0]}.` : '';
    return `${shortFirstName}${shortPatronymic}`;
  }

  private isOogShortEmployee(
    employee: OogShortEmployeeModel | SharedInfoAboutEmployee,
  ): employee is OogShortEmployeeModel {
    return (
      !!(employee as OogShortEmployeeModel).fioAccusative ||
      !!(employee as OogShortEmployeeModel).fioDative ||
      !!(employee as OogShortEmployeeModel).fioGenitive
    );
  }
}
