import { Pipe, PipeTransform } from '@angular/core';

import { MenuFolderModel } from '@models/menu-folder/menu-folder.model';

@Pipe({
  name: 'countDocumentsFolder',
})
export class CountDocumentsFolderPipe implements PipeTransform {
  public transform(folders: MenuFolderModel[], id: number): number {
    return folders.find((f) => f.id === id)?.count;
  }
}
