import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-roll-arrow',
  templateUrl: './roll-arrow.component.html',
  styleUrls: ['./roll-arrow.component.scss'],
})
export class RollArrowComponent {
  @Input() public collapseLeft = false;
}
