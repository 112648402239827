import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { BaseControl } from '@components/controls/control-base';

@Component({
  selector: 'app-oog-select',
  templateUrl: './oog-select.component.html',
  styleUrls: ['./oog-select.component.scss'],
})
export class OogSelectComponent extends BaseControl implements OnInit {
  @Input() public options: any[] = [];
  @Input() public displayName = '';

  @Output() public selectItem: EventEmitter<any> = new EventEmitter<any>();

  public activeOption = false;
  public showLabel = '';

  constructor(private elementRef: ElementRef) {
    super();
  }

  @HostListener('document:click', ['$event.target'])
  public hideSelect(event: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(event)) {
      this.activeOption = false;
    }
  }

  public ngOnInit(): void {
    this.setShowLabel();
  }

  /** Переключает видимость дропдауна */
  public toggleOptions(): void {
    this.activeOption = !this.activeOption;
  }

  /** Выбор значения из списка */
  public selectOption(value: any): void {
    this.setControlValue(value);
    this.showLabel = value[this.displayName];
    this.activeOption = false;
    this.selectItem.emit(value);
  }

  /** Установить значение контрола */
  private setControlValue(value: any): void {
    const control = this.parentFormGroup.controls[this.controlName];
    control.setValue(value);
    control.markAsDirty();
  }

  private setShowLabel(): void {
    const control = this.parentFormGroup.controls[this.controlName];
    if (control.value) {
      this.showLabel = control.value[this.displayName];
    }
  }
}
