import { Pipe, PipeTransform } from '@angular/core';

import { ActingTypeEnum } from '../../enums/main-screen/acting-type-names.enum';

@Pipe({
  name: 'actingType',
})
export class ActingTypePipe implements PipeTransform {
  public transform(actingType: ActingTypeEnum): string {
    switch (actingType) {
      case ActingTypeEnum.dischargeOfDuties:
        return 'исполнение обязанностей';
      case ActingTypeEnum.fromName:
        return 'работа от имени';
      default:
        return '';
    }
  }
}
