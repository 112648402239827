import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, first, map, mergeMap, takeUntil } from 'rxjs/operators';

import { File } from '@awesome-cordova-plugins/file/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { DocumentApiService } from '@npaApi/document.api.service';
import { SocketChannels } from '@npaCore/services/websocket/channel-names';
import { WebSocketService } from '@npaCore/services/websocket/websocket.service';
import { DocumentProhibitionStatus } from '@npaShared/enums/document-prohibition-status.enum';
import { CompositionDownloadDocumentRequestModel } from '@npaShared/models/document-composition/composition-download-document-request.model';
import { DocumentDownloadWebsocketResponseModel } from '@npaShared/models/document-composition/document-download-websocket-response.model';
import { ExportFormatType } from '@npaShared/models/document/export-format-type.model';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { DeviceTypeEnum } from '@enums/device-type.enum';
import { DocumentPackageViewerService } from 'src/app/npa/document-package/services/document-package-viewer.service';
import {
  DocumentInfoResponseModel,
  DocumentVersionInfoModel,
} from '../../models/document/document-info.response.model';

@Component({
  selector: 'app-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListItemComponent implements OnDestroy {
  /** показывать иконку только в полноэкранном режиме */
  @Input() public showIcon = false;

  @Input()
  @HostBinding('class.active')
  public isActive: boolean;

  @Input()
  @HostBinding('class.disabled')
  public disabled: boolean;

  /** закрыть список документов */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close = new EventEmitter<void>();

  @Input() public set document(document: DocumentInfoResponseModel) {
    if (document) {
      this.documentProp = document;
      this.activeVersion$ = this.documentPackageViewerService.getActiveVersionFromDocument$(document);
    }
  }

  public loading = false;
  public readonly documentProhibitionStatus = DocumentProhibitionStatus;
  public activeVersion$: Observable<DocumentVersionInfoModel>;
  public documentProp: DocumentInfoResponseModel;

  private readonly downloadFormat: ExportFormatType = 'pdf';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private documentApiService: DocumentApiService,
    private websocket: WebSocketService,
    private cdr: ChangeDetectorRef,
    private fileService: File,
    private socialSharing: SocialSharing,
    private deviceInfo: DeviceInfoService,
    private documentPackageViewerService: DocumentPackageViewerService,
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Общий метод скачивания файла на свой девайс */
  public download(event: MouseEvent, document: DocumentInfoResponseModel): void {
    if (document.isBlank) {
      return;
    }

    event.stopPropagation();
    if (this.deviceInfo.getDeviceType() === DeviceTypeEnum.IOS) {
      this.downloadIOS(document);
      return;
    }
    this.downloadWeb(document);
  }

  /** Получение ссылки на скачивание документа */
  private getDocumentDownloadLink(document: DocumentInfoResponseModel): Observable<any> {
    const actualVersion = this.documentPackageViewerService.getActiveVersionFromDocument(document);

    const reqBody: CompositionDownloadDocumentRequestModel = {
      ldeDocumentId: document.ldeDocumentId,
      ldeRevisionId: actualVersion.revision,
      format: this.downloadFormat,
    };
    return this.documentApiService.postDownloadDocumentRequest(reqBody).pipe(
      mergeMap(() => this.websocket.selectChanel<DocumentDownloadWebsocketResponseModel>(SocketChannels.exportChanel)),
      first(),
      map((response: DocumentDownloadWebsocketResponseModel) => response.downloadLink),
    );
  }

  /** Реализация загрузки файла на ios-устройстве */
  private downloadIOS(document: DocumentInfoResponseModel): void {
    this.loading = true;
    this.getDocumentDownloadLink(document)
      .pipe(
        mergeMap((link: string) => this.documentApiService.loadFile(link)),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((file) => {
        const path = this.fileService.tempDirectory;
        this.fileService
          .writeExistingFile(path, `${document.name}.${this.downloadFormat}`, file)
          ?.then((saved: any) => {
            this.share(path, saved);
          });
      });
  }

  /** Реализация загрузки файла в веб-интерфейсе */
  private downloadWeb(document: DocumentInfoResponseModel): void {
    this.loading = true;
    this.getDocumentDownloadLink(document)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((url) => {
        const link = this.documentApiService.getLink(url);
        window.open(link, '_self');
      });
  }

  /** "Костыль" для сохранения документа в локальном хранилище через "Поделиться"
   *
   * (по другому не получается сохранить документ на памяти ios-устройства)
   */
  private share(path: string, file: any): void {
    this.socialSharing
      .shareWithOptions({
        files: [decodeURI(file.toURL())],
      })
      .finally(() => {
        this.fileService.removeFile(path, file.name);
      });
  }
}
