import { Component } from '@angular/core';

import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-confirm-sign',
  templateUrl: './confirm-sign.component.html',
  styleUrls: ['./confirm-sign.component.scss'],
})
export class ConfirmSignComponent extends DialogBase {}
