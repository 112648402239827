import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';
import { OpUnitType } from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as utc from 'dayjs/plugin/utc';

import { DateHelperConst } from '@const/date-helper.const';
import { Store } from '@ngxs/store';
import { DictionariesWeekendsState } from '@store/dictionaries/dictionaries-weekends/dictionaries-weekends.state';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor(private store: Store) {}
  /**
   * Преобразует дату к виду dateFormat
   *
   * @param date в формате DDMMYYYY
   * @param dateFormat строка формата даты
   */
  public dateFormatting(date: string, dateFormat: string = DateHelperConst.dateUrgencyFormat): string {
    dayjs.extend(customParseFormat);
    dayjs.extend(localizedFormat);
    const dateValue = dayjs(date, DateHelperConst.dayjsParseFormat);

    return dateValue.isValid() ? dateValue.format(dateFormat) : '';
  }

  /** Возвращает количество дней (отнимаем от даты, которую передаём текущую дату)) */
  public subtractDate(date: Date): number {
    const now = dayjs();
    const cardDate = dayjs(date);
    return cardDate.diff(now, 'day') + 1;
  }

  /** Возвращает количество дней (отнимаем от даты, которую передаём текущую дату)) без +1 */
  public subtractClearDays(date: Date): number {
    const now = dayjs();
    const cardDate = dayjs(date);
    return cardDate.diff(now, 'day');
  }

  /** Возвращает новую дату (Прибавляем число дней, месяцев или недель к текущей дате) */
  public addCountDays(count: number, param: OpUnitType): string {
    const now = dayjs();
    const newDate = now.add(count, param);
    return this.dateFormatting(newDate.format(DateHelperConst.dateFormat));
  }

  /** Возвращает новую дату (вычитаем число дней, месяцев или недель от текущей даты) */
  public subtractDays(count: number, param: OpUnitType): string {
    const now = dayjs();
    const newDate = now.subtract(count, param);
    return this.dateFormatting(newDate.format(DateHelperConst.dateFormat));
  }

  /** Возвращает сегодняшнюю дату в формате (DD.MM.YYYY) */
  public today(): string {
    const now = dayjs();
    return this.dateFormatting(now.format(DateHelperConst.dateFormat));
  }

  /**
   * Преобразует дату в ISO формат
   *
   * @param date в формате YYYY-MM-DD
   */
  public dateToISO(date: string): string {
    dayjs.extend(customParseFormat);
    dayjs.extend(utc);
    return dayjs.utc(date, DateHelperConst.dateUrgencyFormat).toJSON();
  }

  public dateToSmallFormat(date: Date): string | null {
    if (!date) {
      return null;
    }

    if (date.toString().split('.').length > 2) {
      return date.toString().split('.').reverse().join('-');
    }

    const newDate = dayjs(date);
    return newDate.format(DateHelperConst.dateUrgencyFormat);
  }

  /**
   * Преобразует дату из ISO в формат контрола
   *
   * @param date в ISO формате
   */
  public dateFromISO(date: string): string {
    return dayjs(date).format(DateHelperConst.dateUrgencyFormat);
  }

  public differenceDates(date1: Date, date2: Date): number {
    const dayjsDate1 = dayjs(date1);
    const dayjsDate2 = dayjs(date2);
    return dayjsDate1.diff(dayjsDate2);
  }

  public subtractDateFromToday(date: Date): number {
    const dayMilliseconds = 60 * 60 * 24 * 1000;
    return (date?.setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) / dayMilliseconds;
  }

  /**
   * Вычитает нужное количество рабочих дней из переданной даты
   *
   * @param date дата из который будут производится вычитания
   * @param workingDayCount количество рабочих дней, которое необходимо вычесть
   */
  public subtractWorkdays(date: Date, workingDayCount: number): void {
    const weekends = this.store.selectSnapshot(DictionariesWeekendsState.weekends);

    for (let i = 0; i < workingDayCount; i++) {
      do {
        this.subtractOneDate(date);
      } while (this.isWeekend(date, weekends));
    }
  }

  private isWeekend(resultDate: Date, weekends: string[]): boolean {
    const formattedResultDate = this.dateFromISO(resultDate.toISOString());

    return weekends.some((el) => el === formattedResultDate);
  }

  private subtractOneDate(resultDate: Date): void {
    resultDate.setDate(resultDate.getDate() - 1);
  }
}
