import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlsService {
  private readonly localStorageName = 'hostUrl';

  public get getHost(): string {
    return localStorage.getItem(this.localStorageName);
  }

  public setUrls(url: string): void {
    localStorage.setItem(this.localStorageName, url);
  }

  public setUrlInCaseOfUnavailability(): void {
    this.setUrls(environment.inCaseOfUnavailabilityRedirectToHost);
  }

  public clear(): void {
    localStorage.removeItem(this.localStorageName);
  }
}
