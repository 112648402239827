/** Сохранить активную карточку документа */

import { NotificationDataModel } from '@models/notifications/notifications-data.model';

export class SetNotifications {
  public static readonly type = '[Notifications] Set Notifications';

  constructor(public notifications: NotificationDataModel[]) {}
}

export class AddNotification {
  public static readonly type = '[Notifications] Add Notification';

  constructor(public notification: NotificationDataModel) {}
}
