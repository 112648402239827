import { Pipe, PipeTransform } from '@angular/core';

import { FileTypeEnum } from '@enums/file-type.enum';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';

@Pipe({
  name: 'findMainFile',
})
export class FindMainFilePipe implements PipeTransform {
  public transform(files: FilePoolModel[]): FilePoolModel {
    return files.find((f) => f.fileType === FileTypeEnum.main);
  }
}
