import { Pipe, PipeTransform } from '@angular/core';

import { DateHelperService } from '@shared/services/date-helper.service';

@Pipe({
  name: 'countDaysString',
})
export class CountDaysStringPipe implements PipeTransform {
  constructor(private dateHelper: DateHelperService) {}

  public transform(date: Date | null): void | string {
    if (!date) {
      return;
    }

    const difference = Math.abs(this.dateHelper.subtractDateFromToday(date));
    const endingWord = Math.round(difference % 10);
    let dayString = '';
    let leftString = 'осталось:';

    if (Math.round(this.dateHelper.subtractDateFromToday(date)) === this.dateHelper.subtractDateFromToday(new Date())) {
      return '(истекает сегодня)';
    }

    if (difference >= 11 && difference < 15) {
      dayString = 'дней';
    } else {
      if (endingWord === 1) {
        leftString = 'остался';
        dayString = 'день';
      }
      if (endingWord > 1 && endingWord < 5) {
        dayString = 'дня';
      }
      if (endingWord === 0 || endingWord >= 5) {
        dayString = 'дней';
      }
    }

    return date > new Date()
      ? `(${leftString} ${Math.round(difference)} ${dayString})`
      : `(просрочено: ${Math.round(difference)} ${dayString})`;
  }
}
