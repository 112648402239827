/** Сохранить значение "Работа за всех" */
export class SetWorkForEveryone {
  public static readonly type = '[Work For Everyone] Set Work For Everyone';
  constructor(public value: boolean) {}
}

/** Удалить значение и стора */
export class ResetWorkForEveryone {
  public static readonly type = '[Work For Everyone] Reset Work For Everyone';
}
