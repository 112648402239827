import { CertificatesSettingsOogModel } from '@models/settings/certificates-settings.model';
import { BaseSettingsCertificates } from './base-settings';

export class OogSettingsCertificates extends BaseSettingsCertificates {
  public getAllSettings(): CertificatesSettingsOogModel {
    return {
      agreedOog: this.certificateAgreed,
      defaultCertificateOog: this.defaultCertificate,
      singingWithoutConfirmationOog: this.singingWithoutConfirmation,
      signingResolutionOog: this.signingResolutions,
    };
  }

  protected getItem(key: string): string {
    return localStorage.getItem(`oog-settingsCertificate-${key}`);
  }

  protected setItem(key: string, value: string): void {
    localStorage.setItem(`oog-settingsCertificate-${key}`, value);
  }
}
