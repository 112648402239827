import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize, first, map, startWith } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { SelectTypeModel } from '@models/dictionaries/select-type.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { changeUserSession } from '@oogShared/functions/change-user-session.function';
import { FormResolutionModel } from '@oogShared/models/form-pages/form-resolution.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';

import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { DischargeDutiesService } from '@oogShared/services/discharge-of-duties/discharge-duties.service';
import { EsignOogService } from '@oogShared/services/esign-oog.service';
import { FormActionControlService } from '@oogShared/services/forms/form-action-control.service';
import { FormCreateResolutionService } from '@oogShared/services/forms/form-create-resolution.service';
import { flatMap } from '@shared/functions/flat-map.function';
import { DateHelperService } from '@shared/services/date-helper.service';
import { ModalService } from '@shared/services/modal.service';
import { DictionariesTemplateState } from '@store/dictionaries/dictionaries-templates/dictionaries-template.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { SettingsState } from '@store/settings/settings.state';
import { StatusCurrentUserAssistantState } from '@store/status-current-user-assistant/status-current-user-assistant.state';
import { combineLatest, Observable } from 'rxjs';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { RestoreResolutionFileService } from '@oogShared/services/forms/restore-resolution-file.service';
import { CreateResolutionDraftService } from '@oogShared/services/forms/draft/create-resolution-draft.service';
import { FormModalService } from '@oogShared/services/forms/form-modal.service';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { MinDateAndControlModel } from '@oogShared/models/create-resolution/minDateAndControl.model';
import { DictionaryTemplateTypes } from '@enums/dictionary-template-types/dictionary-template-types.enums';
import { CommissionFormModel } from '@oogShared/models/forms/commission-form.model';
import { AudioFormService } from '../audio-form/services/audio-form.service';
import { FormBase } from '../form-base';
import { GraphicFormService } from '../graphic-form/services/graphic-form.service';
import { GraphicHelperService } from '../graphic-form/services/graphic-helper.service';
import { ParentMissionData } from './parent-mission-data.model';

// Название контрола с данными из родительского поручения
const PARENT_COMMISSION_DATA = 'parentCommissionData';

@UntilDestroy()
@Component({
  selector: 'app-resolution-create',
  templateUrl: './resolution-create.page.html',
  styleUrls: ['./resolution-create.page.scss'],
})
export class ResolutionCreatePage extends FormBase implements OnInit, AfterViewInit {
  @Input() public draft: CreateResolutionModel | null = null;

  @Select(ResolutionState.resolutionIsDirectionType)
  public resolutionIsDirection$: Observable<boolean>;

  @Select(ResolutionState.getResolutionProjectCard)
  public resolution$: Observable<ResolutionModel>;

  @Select(
    DictionariesTemplateState.templates([DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.resolution]),
  )
  public templates$!: Observable<SelectTypeModel[]>;

  @Select(DictionariesTemplateState.templates([DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.request]))
  public templatesForRequest$!: Observable<SelectTypeModel[]>;

  @Select(StatusCurrentUserAssistantState.getStatus)
  public statusAssistant$!: Observable<boolean>;

  public showInvalidPerformers = false;

  public statusShowingGraphicFromSettings = this.store.selectSnapshot(SettingsState.getGraphicStatus);
  public statusShowingAudioFromSettings = this.store.selectSnapshot(SettingsState.getAudioStatus);
  public filteredEmployeesHotList$: Observable<EmployeeModel[]>;
  public filteredResolutionTemplates$: Observable<SelectTypeModel[]>;

  /** значение настройки Автоматического предзаполнения минимального срока исполнения */
  private readonly minimumDueDateAutoPreFillSetting = this.store.selectSnapshot(
    SettingsState.minimumDueDateAutoPreFill,
  );

  /** значение настройки Копирования всех блоков родительских поручений */
  private readonly copyAllResolutionMissionBlocksSetting = this.store.selectSnapshot(
    SettingsState.copyAllResolutionMissionBlocks,
  );

  /** значение настройки Копирования срока исполнения */
  private readonly executionPeriodInheritance = this.store.selectSnapshot(SettingsState.executionPeriodInheritance);

  constructor(
    private formService: FormCreateResolutionService,
    actionControlService: FormActionControlService,
    fb: FormBuilder,
    store: Store,
    dateHelper: DateHelperService,
    modalCtrl: ModalController,
    dictionariesInterop: DictionariesInteropService,
    cd: ChangeDetectorRef,
    dischargeService: DischargeDutiesService,
    currentUser: CurrentUserIsproStoreService,
    private authUser: AuthUserIsproStoreService,
    private graphicHelperService: GraphicHelperService,
    private graphicFormService: GraphicFormService,
    private modalService: ModalService,
    private audioFormService: AudioFormService,
    private esignService: EsignOogService,
    private cardService: ResolutionInteropService,
    private restoreFileService: RestoreResolutionFileService,
    private restoreFormService: CreateResolutionDraftService,
    private formModalService: FormModalService,
    private cdr: ChangeDetectorRef,
  ) {
    super(
      store,
      fb,
      dateHelper,
      currentUser,
      modalCtrl,
      actionControlService,
      dictionariesInterop,
      cd,
      dischargeService,
    );
  }

  /** Возвращает данные формы */
  private get commissionsForms(): FormArray {
    return this.form.controls['commissions'] as FormArray;
  }

  public get isRequest(): boolean {
    return this.form.controls['isRequest'].value;
  }

  public ngOnInit(): void {
    changeUserSession(this.store, this.currentUser);
    this.formService.checkHasAssistant();
    this.formService.mapDataToForm();
    this.headerData = this.formService.mapFormHeader(this.formService.dataForm);
    this.initForm(this.formService.dataForm);
    this.subscribeToSubmitFormFromGraphic();
    this.initFilteredEmployeesHotList();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  /** Создать резолюцию */
  public createResolution(): void {
    this.dispatchForm = true;
    this.clearFormDispatching();

    if (this.someCommissionInRequestHasSameDep()) {
      this.modalService.presentModalError('Сотрудник должен быть из другого департамента');
      return;
    }

    if (this.audioFormService.getAudioFromStore()) {
      this.audioFormService
        .sendFile()
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          const commissions = this.getCommissionArray();
          this.audioFormService.saveFileData(res, commissions?.controls[0] as FormGroup);
          setTimeout(() => {
            this.formService.createResolution();
          }, 100);
        });
      return;
    }

    if (this.formHavePictureOrAudio()) {
      this.formService.createResolution();
      return;
    }

    if (this.form.invalid) {
      this.showInvalidPerformers = true;
      setTimeout(() => (this.showInvalidPerformers = false), 2000);
      return;
    }

    if (this.esignService.isInvalidCertificateSigningResolution()) {
      this.esignService.showModalInvalidCertificate();
      return;
    }

    this.addresseeInCommissions() ? this.emptyCommissions() : this.formService.createResolution();
  }

  public formHavePictureOrAudio(): boolean {
    const commissions = this.form.controls.commissions as FormArray;
    const picture = commissions.controls[0]?.get('picture')?.value;
    const audio = commissions.controls[0]?.get('audio')?.value;

    return !!picture || !!audio;
  }

  /** Возвращает true, если хотя бы в одном поручении есть контрол с индексом родительской резолюции */
  public someCommissionFormContainParentMissionIndexControl(): boolean {
    return this.commissionsForms.controls.some((commissionForm) =>
      this.parentMissionIndexExist(commissionForm as FormGroup),
    );
  }

  /** Заменить данные поручения данными из родительской резолюции */
  public replaceResolutionWithParentResolution(commissionForm: AbstractControl): void {
    this.patchDataToForm(commissionForm, this.parentMissionData(commissionForm), true);

    this.removeParentCommissionIndex(commissionForm);
  }

  /** Дополнить данные поручения данными из родительской резолюции */
  public supplementResolutionWithParentResolution(commissionForm: AbstractControl): void {
    this.patchDataToForm(commissionForm, this.parentMissionData(commissionForm), false, true);

    this.removeParentCommissionIndex(commissionForm);
  }

  /** Не копировать данные родительской резолюции */
  public notCopyResolutionWithParentResolution(commissionForm: AbstractControl): void {
    this.removeParentCommissionIndex(commissionForm);
  }

  /** индекс соответствующей родительской резолюции */
  public parentMissionData(commissionForm: AbstractControl): ParentMissionData {
    return commissionForm?.get(PARENT_COMMISSION_DATA)?.value?.parentMissionData as ParentMissionData;
  }

  public parentMissionIndexExist(commissionForm: AbstractControl): boolean {
    const parentMissionData = this.parentMissionData(commissionForm);

    return !!parentMissionData;
  }

  /**
   * Копирует текст из предыдущего поручения в текущее
   *
   * @param commissionIdx индекс текущего поручения
   */
  public copyPreviousCommission(commissionIdx: number): void {
    const currentCommission = this.getTextControlFromCommissionFormGroup(commissionIdx);
    const previousCommission = this.getTextControlFromCommissionFormGroup(commissionIdx - 1);

    if (previousCommission) {
      currentCommission.setValue(previousCommission.value);
    }
  }

  public copyParentResolutionCommissions(): void {
    const parentResolutionMissions = this.getMissionFromParentResolution();
    parentResolutionMissions.sort((a, b) => Number(a?.serialNumber) - Number(b?.serialNumber));

    parentResolutionMissions.forEach((parentResolutionMission, parentMissionIndex) => {
      // Форма соответствующая по порядку родительскому поручению
      let commissionForm = this.commissionsForms.controls[parentMissionIndex] as FormGroup;

      if (!commissionForm) {
        this.commissionsForms.push(this.createNewCommissionGroup());

        commissionForm = this.commissionsForms.controls[parentMissionIndex] as FormGroup;
      }

      // Создаем форму исполнителя, если она не существует
      this.createFirstPerformerFormIfNotExist(parentMissionIndex);

      if (this.hasPermissionToComment(parentResolutionMission)) {
        commissionForm.patchValue({
          parentResolutionCommentText: parentResolutionMission.privateComment,
        });
      }

      const firstPerformerFormGroup = this.getFirstPerformerFormGroupFromExistingCommissionForms(
        this.commissionsForms,
        parentMissionIndex,
      );

      const parentMissionData = this.getParentMissionData(parentResolutionMissions, parentMissionIndex);

      if (!this.hasPermissionToComment(parentResolutionMission)) {
        // Если выполняется работа от ИО, убираем текст личного поручения
        parentMissionData.parentMissionText = '';
      }

      const currentCommissionTextValue = commissionForm.get('text')?.value ?? '';
      const currentCommissionDateValue = firstPerformerFormGroup.get('date')?.value ?? '';
      const currentCommissionControl = firstPerformerFormGroup.get('control');

      if (this.minimumDueDateAutoPreFillSetting) {
        let minDate = '';
        const minDateFromParentResolution = this.getMinDateFromParentResolution(this.getMissionFromParentResolution());
        const control = this.getControlAddressee(this.getMissionFromParentResolution(), minDateFromParentResolution);
        minDate = this.getSubtractedDate(minDateFromParentResolution);

        const performers = commissionForm.value.performers;
        performers.forEach((p) => {
          p.dueDate = minDate;
          p.control = control;
        });

        commissionForm.patchValue({
          performers,
        });
      }

      if (
        // Если включена настройка выбора действий при копировании
        this.store.selectSnapshot(SettingsState.choiceActionOnCopyMission) &&
        // Если какое либо из полей нуждается в заполнении и оно уже заполнено и данные отличаются
        ((!!currentCommissionTextValue &&
          currentCommissionTextValue.trim() !== parentMissionData.parentMissionText.trim()) ||
          (parentMissionData.parentMissionDate.needsFilling &&
            !!currentCommissionDateValue &&
            // сравниваем даты только учитывая только дату. Иначе в кейсе, когда мы вставляем текущую дату, при следующем нажатии отличаются миллисекунды
            new Date(currentCommissionDateValue).toDateString() !==
              new Date(parentMissionData.parentMissionDate.value).toDateString()) ||
          (parentMissionData.parentMissionControl.needsFilling &&
            currentCommissionControl?.dirty &&
            currentCommissionControl?.value !== parentMissionData.parentMissionControl.value))
      ) {
        commissionForm.addControl(
          PARENT_COMMISSION_DATA,
          new FormControl({
            parentMissionData,
          }),
        );
      } else {
        this.patchDataToForm(commissionForm, parentMissionData);
      }
    });
  }

  /** Удаляет соответствующий индекс родительской резолюции из формы */
  private removeParentCommissionIndex(commissionForm: AbstractControl): void {
    (commissionForm as FormGroup).removeControl(PARENT_COMMISSION_DATA);
  }

  private hasPermissionToComment(mission: MissionModel): boolean {
    if (!mission.isCommentConfidential) {
      return true;
    }
    const authUser = this.authUser.getCurrentUserSnapshot();

    return mission.addressee.some((a) => a.employee.id === authUser.id);
  }

  // Рассчитывает данные на основе данных из родительских поручений
  private getParentMissionData(
    parentResolutionMissions: MissionModel[],
    parentMissionIndex: number,
  ): ParentMissionData {
    const parentMissionText = parentResolutionMissions[parentMissionIndex].privateComment;

    // получаем рассчитанную дату на основе родительских поручений и значений настроек
    const parentMissionCalculatedDate = this.getPrefilledFieldDateFromParentMissions(
      parentResolutionMissions,
      parentMissionIndex,
    );
    // получаем признак контроля рассчитанный на основе родительских поручений и значений настроек
    const parentMissionCalculatedControl = this.getPrefilledFieldControlFromParentMissions(
      parentResolutionMissions,
      parentMissionIndex,
    );

    return {
      parentMissionIndex,
      parentMissionText,
      parentMissionDate: {
        value: parentMissionCalculatedDate,
        // нужно заполнять дату, только если соответствующая настройка включена
        needsFilling: this.executionPeriodInheritance,
      },
      parentMissionControl: {
        value: parentMissionCalculatedControl,
        // нужно заполнять дату, только если соответствующая настройка включена
        needsFilling: this.store.selectSnapshot(SettingsState.controlTypeInheritance),
      },
    };
  }

  /**
   * Рассчитывает и вставляет данные в форму
   *
   * @param commissionForm форма поручения в которую необходимо вставить данные
   * @param parentMissionData данные из родительской резолюции
   * @param replaceData стоит ли заменять данные формы безусловно
   * @param supplementParentResolutionText стоил ли "дополнять" текст поручения данными из родительского. Дополнение означает, что текст родительского поручения будет добавлен в конец
   */
  private patchDataToForm(
    commissionForm: AbstractControl,
    parentMissionData: ParentMissionData,
    replaceData: boolean = false,
    supplementParentResolutionText: boolean = false,
  ): void {
    if (replaceData) {
      const performers = commissionForm.value.performers;

      performers.forEach((performer) => {
        performer.urgency = '';
      });
      commissionForm.patchValue({
        performers,
      });
    }

    if ((replaceData || !commissionForm.value.text) && !supplementParentResolutionText) {
      commissionForm.patchValue({
        text: parentMissionData.parentMissionText,
        savedText: parentMissionData.parentMissionText,
      });
    }

    if (supplementParentResolutionText) {
      // Обрезаем пробелы справа у текущего текста поручения
      const rtrimCurrentComissionText = commissionForm.get('text').value.replace(/~*$/, '');
      // соединяем строки родительского поручения и текущего через пробел
      const supplementResolutionText = `${rtrimCurrentComissionText} ${parentMissionData.parentMissionText}`;

      commissionForm.patchValue({
        text: supplementResolutionText,
        savedText: supplementResolutionText,
      });
    }

    if (parentMissionData.parentMissionDate.needsFilling) {
      const firstPerformerFormGroup = this.getFirstPerformerFormGroupFromExistingCommissionForms(
        this.commissionsForms,
        parentMissionData.parentMissionIndex,
      );

      if (replaceData || !firstPerformerFormGroup.value.date) {
        firstPerformerFormGroup.patchValue({
          date: parentMissionData.parentMissionDate.value,
        });
      }
    }

    if (parentMissionData.parentMissionControl.needsFilling) {
      const firstPerformerFormGroup = this.getFirstPerformerFormGroupFromExistingCommissionForms(
        this.commissionsForms,
        parentMissionData.parentMissionIndex,
      );

      if (replaceData || !firstPerformerFormGroup.value.control) {
        firstPerformerFormGroup.patchValue({
          control: parentMissionData.parentMissionControl.value,
        });
      }
    }
  }

  /** Создать графическую форму */
  public createGraphicForm(edit: boolean, commission: AbstractControl): void {
    const commissions = this.getCommissionArray();
    if (commissions.length > 1) {
      this.modalService.presentModalError(
        'Для добавления графической или аудио резолюции удалите все поручения кроме одного',
      );
      return;
    }
    const commissionForm = commission as FormGroup;
    this.graphicHelperService.createGraphicForm(edit, this.headerData, commissionForm, true);
  }

  /** Удалить рисунок с формы */
  public async deletePicture(commission: AbstractControl): Promise<void> {
    const commissionForm = commission as FormGroup;
    this.graphicHelperService.deletePicture(commissionForm);
  }

  /** Сохранить текст поручения в отдельный контрол формы, чтобы загрузить его когда удалили изображение */
  public saveCommissionText(value: string, commission: AbstractControl): void {
    const commissionForm = commission as FormGroup;
    this.graphicHelperService.saveCommissionText(value, commissionForm);
  }

  public chooseUser(commissionIdx: number, employee: EmployeeModel): void {
    let minDate = '';

    if (this.minimumDueDateAutoPreFillSetting && this.executionPeriodInheritance) {
      const minDateFromParentResolution = this.getMinDateFromParentResolution(this.getMissionFromParentResolution());
      minDate = this.getSubtractedDate(minDateFromParentResolution);
    }

    this.addPerformer(commissionIdx, employee, minDate);
  }

  // Создает новую первую форму исполнителя, если она не существует
  private createFirstPerformerFormIfNotExist(parentMissionIndex: number): void {
    const firstPerfomerFormGroup = this.getFirstPerformerFormGroupFromExistingCommissionForms(
      this.commissionsForms,
      parentMissionIndex,
    );

    // Если первая форма уже есть, то ничего не делаем
    if (firstPerfomerFormGroup) {
      return;
    }

    // Добавляем исполнителя к этому поручению
    this.addPerformer(parentMissionIndex, null);
  }

  // Возвращает перую форму исполнителя из формы поручения
  private getFirstPerformerFormGroupFromExistingCommissionForms(
    commissionsForms: FormArray,
    parentMissionIndex: number,
  ): FormGroup {
    const performerFormArray = this.getPerformerFormArray(commissionsForms, parentMissionIndex);

    return performerFormArray.controls[0] as FormGroup;
  }

  // Возвращает признак контроля на основе данных из родительских поручений и с учетом пользовательских настроек
  private getPrefilledFieldControlFromParentMissions(parentMissions: MissionModel[], commissionIdx: number): boolean {
    if (!parentMissions?.length) {
      return false;
    }

    const isControlFromSettings = this.store.selectSnapshot(SettingsState.controlTypeInheritance);
    const currentUser = this.currentUser.getCurrentUserSnapshot();
    // Получаем всех исполнителей в которых указан текущий пользователь
    const currentAddresses = this.findCurrentUserInAddresseeMission(parentMissions, commissionIdx, currentUser);

    /** Если копируем все блоки поручений, а так же копируем контроли и нашего пользака нет в списке исполнителей,
        то берем контоль из адресата у которого самый близкий срок исполнения
     */
    if (
      this.copyAllResolutionMissionBlocksSetting &&
      this.minimumDueDateAutoPreFillSetting &&
      isControlFromSettings &&
      !currentAddresses
    ) {
      return this.getMinDateAndControlFromAddresses(parentMissions[commissionIdx]?.addressee)?.isControl;
    }

    if (!currentAddresses) {
      return false;
    }

    if (!isControlFromSettings) {
      return false;
    }

    return currentAddresses.isControl;
  }

  // Возвращает дату на основе данных из родительских поручений учитывая пользовательские настройки
  private getPrefilledFieldDateFromParentMissions(parentMissions: MissionModel[], commissionIdx: number): string {
    // Если отключена настройка копирования периода исполнения, то возвращаем пустую строку
    if (!this.executionPeriodInheritance) {
      return '';
    }

    if (!parentMissions?.length) {
      return '';
    }

    const date = this.getCurrentDate(parentMissions, commissionIdx);

    return this.getSubtractedDate(date);
  }

  private getSubtractedDate(date: Date): string {
    if (!date) {
      return '';
    }

    if (date) {
      // Вычитаем из даты рабочие дни
      const reduceExecutionPeriod = this.store.selectSnapshot(SettingsState.reduceExecutionPeriod);
      this.dateHelper.subtractWorkdays(date, reduceExecutionPeriod);
    }

    if (this.dateHelper.subtractDateFromToday(date) < 0) {
      return new Date().toDateString();
    }

    return date.toDateString();
  }

  private getCurrentDate(parentMissions: MissionModel[], commissionIdx: number): Date {
    const currentAddressee = this.findCurrentUserInAddresseeMission(
      parentMissions,
      commissionIdx,
      this.currentUser.getCurrentUserSnapshot(),
    );

    // Если пользователь указан исполнителем в текущем поручении и имеет дату, то всегда возвращаем ее
    if (currentAddressee && currentAddressee?.dueDate) {
      return new Date(currentAddressee.dueDate);
    }

    // Если предзаполнение минимальной даты отключено, то возвращаем дату, где указан текущий пользователь, даже если она не указана
    if (!this.minimumDueDateAutoPreFillSetting) {
      const date = currentAddressee?.dueDate;

      // Если предзаполнение минимальной даты отключено, то возвращаем
      return date ? new Date(date) : date;
    }

    return this.getMinDateFromParentResolution(parentMissions);
  }

  private getMinDateFromParentResolution(parentMissions: MissionModel[]): Date {
    // Если включено копирование всех поручений из родительской резолюции, то находим минимальную дату среди всех исполнителей во всех поручениях
    if (this.copyAllResolutionMissionBlocksSetting) {
      const allAddresses = flatMap(parentMissions, (parentMission) => parentMission.addressee);

      return this.getMinDateAndControlFromAddresses(allAddresses)?.dueDate;
    }

    // Если копирование минимальной даты включено, но копирование всех поручений - отключено, то находим минимальную дату из тех поручений, где текущий пользователь указан как исполнитель
    const allCurrentEmployeeAddressee = this.getCurrentUserAddressesFromParentMissions(
      this.currentUser.getCurrentUserSnapshot(),
      parentMissions,
    );

    return this.getMinDateAndControlFromAddresses(allCurrentEmployeeAddressee)?.dueDate;
  }

  private getControlAddressee(parentMissions: MissionModel[], date: Date): boolean {
    if (this.copyAllResolutionMissionBlocksSetting) {
      const allAddressees = flatMap(parentMissions, (parentMission) => parentMission.addressee);
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const addressee = allAddressees.filter((a) => new Date(a.dueDate) === date);
      return addressee[0]?.isControl;
    }
    const allCurrentEmployeeAddressee = this.getCurrentUserAddressesFromParentMissions(
      this.currentUser.getCurrentUserSnapshot(),
      parentMissions,
    );
    const addressee = allCurrentEmployeeAddressee.filter((a) => new Date(a.dueDate) === date);
    return addressee[0]?.isControl;
  }

  /** Возвращает исполнителей где текущий пользователь указан исполнителем */
  private getCurrentUserAddressesFromParentMissions(
    currentUser: EmployeeModel,
    parentMissions: MissionModel[],
  ): ResolutionAddresseeModel[] {
    // Функция, которая возвращает true, если текущий пользователь является исполнителем
    const addresseeIsCurrentEmployee = (addressee: ResolutionAddresseeModel): boolean =>
      addressee.employee?.id === currentUser.id;

    // все родительские поручения, где текущий пользователь указан как исполнитель
    const allParentMissionsWhereCurrentEmployeeExist = parentMissions?.filter((mission) =>
      mission.addressee.some(addresseeIsCurrentEmployee),
    );

    // все сущности "Исполнитель" в которых указан текущий пользователь
    return flatMap(allParentMissionsWhereCurrentEmployeeExist, (mission) => mission.addressee).filter(
      addresseeIsCurrentEmployee,
    );
  }

  /** Возвращает минимальную дату из переданных поручений */
  private getMinDateAndControlFromAddresses(
    allCurrentEmployeeAddressee: ResolutionAddresseeModel[],
  ): MinDateAndControlModel {
    const data: MinDateAndControlModel[] = allCurrentEmployeeAddressee
      .filter((a) => a.dueDate)
      .reduce((acc, cur) => acc.concat({ dueDate: new Date(cur.dueDate), isControl: cur.isControl }), []);

    // Сортируем даты исполнения по возрастанию
    const sortedDueDates = data.sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime());

    // Берем самую маленькую дату (самую ближнюю)
    return sortedDueDates[0];
  }

  // Находит текущего пользователя среди текущего поручения (по индексу поручения)
  private findCurrentUserInAddresseeMission(
    parentMissions: MissionModel[],
    commissionIdx: number,
    currentUser: EmployeeModel,
  ): ResolutionAddresseeModel {
    const currentMission = parentMissions[commissionIdx];

    return currentMission?.addressee?.find((performer) => performer.employee?.id === currentUser?.id);
  }

  /** Возвращает контрол с текстом поручения из формы поручения по индексу */
  private getTextControlFromCommissionFormGroup(commissionIdx: number): AbstractControl {
    const commissionFormGroup = this.getCommissionArray().controls[commissionIdx] as FormGroup;

    return commissionFormGroup.controls['text'];
  }

  private getMissionFromParentResolution(): MissionModel[] {
    const resolution = this.store.selectSnapshot(ResolutionState.getResolutionProjectCard);

    // Возвращает текущего пользователя. При работе от ИО возвращет ИО
    const currentUser = this.currentUser.getCurrentUserSnapshot();

    // Если включена настройка копирования всех родительских поручений - то отдаем все поручения
    // кроме личных поручений, где пользователь не является исполнителем
    if (this.copyAllResolutionMissionBlocksSetting) {
      return resolution.missions.filter(
        (mission) => !(!this.userIsAddresseeInMission(currentUser, mission) && mission.isCommentConfidential),
      );
    }

    // Отдаем только те поручения, где пользователь указан исполнителем
    return resolution.missions.filter((mission) => this.userIsAddresseeInMission(currentUser, mission));
  }

  /** Определяет является ли пользователь исполнителем в поручении */
  private userIsAddresseeInMission(user: EmployeeModel, mission: MissionModel): boolean {
    return mission.addressee.some((a) => a?.employee?.id === user.id);
  }

  /** Инициализация формы */
  private initForm(data: FormResolutionModel): void {
    this.form = this.fb.group({
      isRequest: [false],
      author: [data?.author, Validators.required],
      onBehalfOf: [data?.onBehalfOf, Validators.required],
      commissions: this.fb.array(this.draft ? this.restoreCommissionsFromDraft() : [this.createNewCommissionGroup()]),
    });

    if (this.draft?.fileResolutionId) {
      this.addFileToFormFromDraft();
    }
  }

  private addFileToFormFromDraft(): void {
    const card = this.store.selectSnapshot(ActiveCardState);

    this.formModalService.showLoading();

    this.cardService
      .showFile(this.draft?.fileResolutionId, card.appealMovementId)
      .pipe(
        first(),
        finalize(() => this.formModalService.dismissLoading()),
      )
      .subscribe((file) => {
        const commissionForm = (this.form.get('commissions') as FormArray).at(0) as FormGroup;
        this.restoreFileService.restoreFile(file, commissionForm);
      });
  }

  /** Создать контрол поручения из данных черновика*/
  private restoreCommissionsFromDraft(): FormGroup[] {
    return this.draft?.missions.map((mission) =>
      this.restoreFormService.restoreCommissionGroup(mission, false, this.draft.fileResolutionId),
    );
  }

  private subscribeToSubmitFormFromGraphic(): void {
    this.graphicFormService.submitForm$.pipe(untilDestroyed(this)).subscribe(() => this.formService.createResolution());
  }

  private initFilteredEmployeesHotList(): void {
    this.filteredEmployeesHotList$ = combineLatest([
      this.hotListEmployee$,
      this.form.controls['isRequest'].valueChanges.pipe(startWith(false)),
      this.currentUser.getCurrentUser(),
    ]).pipe(
      untilDestroyed(this),
      map(([employees, isRequest, user]) => {
        if (!user || !isRequest) {
          return employees || [];
        }

        return employees.filter((emp) => emp.department?.id !== user.department?.id);
      }),
    );
  }

  private someCommissionInRequestHasSameDep(): boolean {
    const isRequest = this.form.controls['isRequest'].value;
    if (!isRequest) {
      return false;
    }

    const currentUser = this.currentUser.getCurrentUserSnapshot();

    const currentUserSameDepartment = (performer?: EmployeeModel): boolean =>
      currentUser.department?.id === performer?.department?.id;

    const commissions = this.getCommissionArray().value;
    const performers = flatMap(commissions, (com: CommissionFormModel) =>
      com.performers.filter((performer) => !performer.inPlus),
    );

    return performers.some((performer) => currentUserSameDepartment(performer.performer));
  }
}
