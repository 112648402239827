import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { ModalController } from '@ionic/angular';
import { modalIdDeleteAudio } from '@const/modals-id.const';
import { ConfirmModalComponent } from '@oogShared/components/confirm-modal/confirm-modal.component';
import { BUTTON_ROLE } from '@oogShared/consts/button-role.const';
import { AudioFormService } from './services/audio-form.service';
import { settings } from './const/audio-form.settings';

/** Интерфейс записи/воспроизведения аудиорезолюций */
@UntilDestroy()
@Component({
  selector: 'app-audio-form',
  templateUrl: './audio-form.component.html',
  styleUrls: ['./audio-form.component.scss'],
})
export class AudioFormComponent implements OnInit, OnDestroy {
  @Input() public form: FormGroup = new FormGroup({});

  @Output() public hideUi = new EventEmitter<void>();

  public recording = false;
  public playing = false;
  public duration = 0;
  public durationText = '';
  public audioRef!: HTMLAudioElement;
  public audioFileName: string | null = null;
  private timeout: number | null = null;

  constructor(private audioFormService: AudioFormService, private modalCtrl: ModalController) {}

  public ngOnInit(): void {
    this.init();
  }

  public ngOnDestroy(): void {
    this.audioFormService.deleteAudio();
  }

  public startRecording(): void {
    if (this.recording) {
      return;
    }

    this.recording = true;
    this.audioFormService.addAudioControlToForm(this.form);
    this.audioFormService.changeCommissionText(this.form);

    VoiceRecorder.startRecording();
    this.calculateDuration();
  }

  public async stopRecording(): Promise<void> {
    if (!this.recording) {
      return;
    }
    const audioRecord = await VoiceRecorder.stopRecording();
    this.recording = false;
    clearTimeout(this.timeout);
    this.form.controls.audio.setErrors(null);

    if (audioRecord.value?.recordDataBase64) {
      await this.deleteFile();
      await this.saveAudio(audioRecord.value.recordDataBase64);
      await this.loadFiles();
    }
  }

  public async loadFiles(): Promise<void> {
    const result = await Filesystem.readdir({
      path: '',
      directory: Directory.Data,
    });

    this.audioFileName = result.files[0];
    await this.audioFormService.convertAudioToBlob(this.audioFileName);
  }

  public async playFile(fileName: string): Promise<void> {
    const audioFile = await Filesystem.readFile({
      path: fileName,
      directory: Directory.Data,
    });

    const base64Sound = audioFile.data;

    this.audioRef = new Audio(`data:audio/wav;base64, ${base64Sound}`);
    this.audioRef.oncanplaythrough = () => this.audioRef.play();
    this.audioRef.load();

    this.audioRef.onended = () => {
      this.stopPlaying();
    };

    this.playing = true;
    this.calculatePlayDuration(this.duration);
  }

  public stopPlaying(): void {
    this.audioRef.oncanplaythrough = () => this.audioRef.pause();
    this.audioRef.load();
    this.playing = false;
    clearTimeout(this.timeout);
  }

  public async deleteFile(): Promise<void> {
    if (!this.audioFileName) {
      return;
    }

    this.duration = 0;
    this.durationText = '';

    const dir = await Filesystem.readdir({
      path: '',
      directory: Directory.Data,
    });

    dir.files.forEach((file) =>
      Filesystem.deleteFile({
        path: file,
        directory: Directory.Data,
      }),
    );
  }

  public async deleteAndCloseUi(): Promise<void> {
    const result = await this.showDeleteConfirmDialog();
    if (result === BUTTON_ROLE.cancel) {
      return;
    }

    this.form.removeControl('audio');
    this.audioFormService.setCommissionText(this.form);

    await this.deleteFile();
    this.audioFormService.deleteAudio();
    this.hideUi.emit();
  }

  private async saveAudio(recordData: string): Promise<void> {
    const fileName = new Date().getTime() + '.wav';
    await Filesystem.writeFile({
      path: fileName,
      directory: Directory.Data,
      data: recordData,
    });
  }

  private calculateDuration(): void {
    const minutes = Math.floor(this.duration / 60);
    const seconds = (this.duration % 60).toString().padStart(2, '0');
    this.durationText = `${minutes}:${seconds}`;
    if (!this.recording || this.duration === settings.audioDurationLimit) {
      return;
    }

    this.timeout = window.setTimeout(() => {
      this.duration += 1; // необходимо таймер запускать тут чтобы при остановке записи не прибавлялась лишняя секунда
      this.calculateDuration();
    }, 1000);
  }

  private calculatePlayDuration(duration): void {
    const minutes = Math.floor(duration / 60);
    const seconds = (duration % 60).toString().padStart(2, '0');
    this.durationText = `${minutes}:${seconds}`;
    if (!this.playing || duration === 0) {
      return;
    }

    duration -= 1;
    this.timeout = window.setTimeout(() => this.calculatePlayDuration(duration), 1000);
  }

  private async restoreAudioFIleFromStore(): Promise<boolean> {
    const audio = this.audioFormService.getAudioFromStore();
    if (!audio) {
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(audio);
    reader.onloadend = async () => {
      await this.saveAudio(reader.result as string);
      await this.loadFiles();
    };

    return true;
  }

  private async showDeleteConfirmDialog(): Promise<string> {
    const modal = await this.modalCtrl.create({
      component: ConfirmModalComponent,
      componentProps: {
        text: 'Подтвердите удаление аудио резолюции',
      },
      animated: false,
      cssClass: 'modal-new-folder',
      id: modalIdDeleteAudio,
    });

    await modal.present();

    const result = await modal.onDidDismiss();
    return result.data.action;
  }

  private async init(): Promise<void> {
    await Filesystem.requestPermissions();

    if (await this.restoreAudioFIleFromStore()) {
      return;
    }

    const canDeviceVoiceRecord = await VoiceRecorder.canDeviceVoiceRecord();
    if (!canDeviceVoiceRecord.value) {
      this.hideUi.emit();
    }

    const requestRecordPermission = await VoiceRecorder.requestAudioRecordingPermission();
    if (requestRecordPermission.value) {
      await this.deleteFile();
      this.startRecording();
    } else {
      this.hideUi.emit();
    }
  }
}
