import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';

/** Сохранить активную карточку документа */
export class SetActiveDocumentCard {
  public static readonly type = '[Document Card] Set Active Document Card';
  constructor(public card: CardDocumentModel) {}
}

/** Очистить стейт */
export class ResetActiveDocumentCard {
  public static readonly type = '[Document Card] Reset Active Document Card';
}
