import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectBase } from '@oogShared/components/controls/select-base';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

@UntilDestroy()
@Component({
  selector: 'app-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss'],
})
export class SelectWithSearchComponent extends SelectBase implements OnInit {
  @Input() public options: EmployeeModel[] = [];

  @Output() public selectUser = new EventEmitter<EmployeeModel>();

  public ngOnInit(): void {
    this.subscribeToSelect();
  }

  /** Выбор значения из списка */
  public selectOption(value: EmployeeModel): void {
    this.setControlValue(value);
    this.filterInput = '';
    this.showValue = `${value?.lastName} ${value?.firstName} ${value?.middleName}`;
    this.selectUser.emit(value);
    this.activeOption = false;
  }

  /** Показать дропдаун */
  public openDropdownWithSearch(): void {
    this.filterInput = '';
    if (!this.activeOption) {
      this.focusField.emit(this.inputControl.nativeElement);
      // нужен для установки фокуса
      setTimeout(() => this.inputControl.nativeElement.focus(), 0);
    }
    this.activeOption = true;
  }

  private subscribeToSelect(): void {
    const control = this.parentFormGroup.controls[this.controlName];

    if (this.showValueOnInit && !!control.value) {
      this.showValue = `${control.value.lastName} ${control.value.firstName} ${control.value.middleName}`;
    }

    control.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
      this.showValue = data ? `${data.lastName} ${data.firstName} ${data.middleName}` : '';
    });
  }
}
