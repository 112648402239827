import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ConfirmModalComponent } from '@oogShared/components/confirm-modal/confirm-modal.component';
import { ConfirmModalModel } from '@oogShared/models/folders/confirm-modal.model';
import { FormHeaderModel } from '@oogShared/models/forms-input/form-header.model';
import { mediaCommissionText } from '@const/commissions-text.const';
import { modalIdDeletePicture } from '@const/modals-id.const';
import { ResolutionKindEnum } from '@oogShared/enums/resolution-kind/resolution-kind.enum';
import { GraphicFormComponent } from '../graphic-form.component';
import { GraphicFormService } from './graphic-form.service';

@Injectable({ providedIn: 'root' })
export class GraphicHelperService {
  constructor(private modalCtrl: ModalController, private graphicFormService: GraphicFormService) {}

  /** Создать графическую форму */
  public createGraphicForm(
    edit: boolean,
    headerData: FormHeaderModel,
    form: FormGroup,
    createForm: boolean = false,
  ): void {
    this.modalCtrl
      .create({
        component: GraphicFormComponent,
        componentProps: {
          headerData,
          form,
          edit,
          editedPicture: form.controls.picture?.value,
          createForm,
        },
        animated: false,
        cssClass: 'modal-fullscreen',
      })
      .then((modal) => modal.present());
  }

  /** Удалить рисунок с формы */
  public async deletePicture(form: FormGroup): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ConfirmModalComponent,
      componentProps: {
        text: 'Подтвердите удаление графической резолюции',
      },
      animated: false,
      cssClass: 'modal-new-folder',
      id: modalIdDeletePicture,
    });

    await modal.present();

    await modal.onDidDismiss().then((res: ConfirmModalModel) => {
      if (res.data.action === 'cancel') {
        return;
      }
      this.setCommissionText(form);
      const pictureControl = form.controls.picture;
      const fileId = form.controls.fileId;
      pictureControl.reset();
      fileId.reset();
      this.graphicFormService.blobImage$.next(null);
    });
  }

  public saveCommissionText(value: string, form: FormGroup): void {
    form.controls.savedText.setValue(value);
  }

  public restoreImage(image: Blob, form: FormGroup): void {
    const reader = new FileReader();
    const setPicture = (value): void => {
      form.get('picture').setValue(value);
      form.get('text')?.setValue(mediaCommissionText);
      form.get('resolutionKind')?.setValue(ResolutionKindEnum.Graphic);
      this.graphicFormService.blobImage$.next(value);
    };

    reader.onloadend = (): void => {
      setPicture(reader.result);
    };
    reader.readAsDataURL(image);
  }

  private setCommissionText(form: FormGroup): void {
    const textControl = form.controls.text;
    const saveTextControl = form.controls.savedText;
    textControl.setValue(saveTextControl.value);
  }
}
