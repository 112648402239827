import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { SetWorkForEveryone } from '@store/main-screen/work-for-everyone/work-for-everyone.action';
import { WorkForEveryoneState } from '@store/main-screen/work-for-everyone/work-for-everyone.state';

/** Возвращает сессию в "Авторизованного пользователя" */
export const returnAuthUserSession = (
  store: Store,
  currentUser: CurrentUserIsproStoreService,
  authUser: AuthUserIsproStoreService,
): void => {
  const workForEveryone = store.selectSnapshot(WorkForEveryoneState.workForEveryone);
  if (workForEveryone) {
    store.dispatch(new SetWorkForEveryone(true));
    currentUser.setCurrentUser(authUser.getCurrentUserSnapshot());
  }
};
