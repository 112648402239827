import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentInfoHeaderCount',
})
export class DocumentInfoHeaderCountPipe implements PipeTransform {
  public transform(length: number): string {
    if (length > 1) {
      return `Состав пакета (${length})`;
    }
    return 'Состав пакета';
  }
}
