import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { SearchMissionResolutionAddresseeModel } from '@oogShared/models/resolutions/mission/search-mission-resolution-addressee.model';

@Pipe({
  name: 'collectEmployeeFromAddressee',
})
/** Собрать модель работников из модели связанного поручения */
export class CollectEmployeeFromAddresseePipe implements PipeTransform {
  public transform(card: SearchMissionResolutionAddresseeModel[]): EmployeeModel[] {
    return card.map((c) => c.employee);
  }
}
