import { FilterCodesEnum } from '../enums/side-bar/filter-codes.enum';
import { ItemFilters } from '../models/filter/item-filters.model';

/**
 * статус срочности: Незамедлительные, Срочные, Все
 */
export const filtersUrgency: ItemFilters[] = [
  { title: 'срочные', code: FilterCodesEnum.urgently },
  { title: 'незамедлительные', code: FilterCodesEnum.immediately },
  { title: 'все', code: FilterCodesEnum.all },
];
