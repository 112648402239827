import { Injectable } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
@Injectable({ providedIn: 'root' })
export class PermissionPushNotificationService {
  public requestPermission(): void {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });
  }
}
