import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showExtension',
})
/** Пайп, делающий буквы в расширении большими */
export class ShowExtensionPipe implements PipeTransform {
  public transform(extension: string): string {
    return extension.toUpperCase();
  }
}
