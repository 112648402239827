import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HideBackdrop, ShowBackdrop } from '@store/visibility-backdrop/visibility-backdrop.action';

@Injectable()
@State<boolean>({
  name: 'visibilityBackdrop',
  defaults: false,
})
export class VisibilityBackdropState {
  @Selector()
  public static isVisibleBackdrop(state: boolean): boolean {
    return state;
  }

  @Action(ShowBackdrop)
  public showBackdrop({ setState }: StateContext<boolean>): void {
    setState(true);
  }

  @Action(HideBackdrop)
  public HideBackdrop({ setState }: StateContext<boolean>): void {
    setState(false);
  }
}
