import { Directive, HostListener, Input } from '@angular/core';

import { CssOptionsModel } from '@models/css-options.model';
import { SharedInfoAboutEmployee } from '@models/shared-info-about-employee.model';
import { UserCardService } from '@shared/services/user-card.service';

@Directive({
  selector: '[appUserCard]',
})
export class UserCardDirective {
  @Input() public employee: SharedInfoAboutEmployee;
  @Input() public employeeId: number;

  constructor(private userCardService: UserCardService) {}

  @HostListener('document:click')
  public onClickAway(): void {
    this.userCardService.destroyCard();
  }

  @HostListener('click', ['$event'])
  public async onClick(event: MouseEvent): Promise<void> {
    const options = this.createOptions(event);
    setTimeout(() => this.userCardService.show(this.employee, this.employeeId, options));
  }

  private createOptions(event: MouseEvent): CssOptionsModel {
    return { y: event.clientY, x: event.clientX };
  }
}
