import { BanRedirectMayorComponent } from './ban-redirect-mayor/ban-redirect-mayor.component';
import { ConfirmApprovalComponent } from './confirm-approval/confirm-approval.component';
import { ConfirmApproveComponent } from './confirm-approve/confirm-approve.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmFamiliarizationComponent } from './confirm-familiarization/confirm-familiarization.component';
import { ConfirmGroupDocumentComponent } from './confirm-group-document/confirm-group-document.component';
import { ConfirmMayorWithCommentComponent } from './confirm-mayor-with-comment/confirm-mayor-with-comment.component';
import { ConfirmRedirectProjectComponent } from './confirm-redirect-project/confirm-redirect-project.component';
import { ConfirmSignComponent } from './confirm-sign/confirm-sign.component';

export const ConfirmDialog = [
  ConfirmDialogComponent,
  ConfirmDeleteComponent,
  ConfirmFamiliarizationComponent,
  ConfirmApprovalComponent,
  ConfirmSignComponent,
  ConfirmRedirectProjectComponent,
  ConfirmMayorWithCommentComponent,
  BanRedirectMayorComponent,
  ConfirmApproveComponent,
  ConfirmGroupDocumentComponent,
];
