import { Injectable } from '@angular/core';

import { State } from '@ngxs/store';
import { ActiveProjectExpeditedReviewState } from '@store/expedited-review/active-project/active-project-expedited-review.state';
import { ChosenDocumentInGroupState } from '@store/expedited-review/choosen-document-in-group/chosen-document-in-group.state';
import { MenuExpeditedReviewState } from '@store/expedited-review/menu/menu-expedited-review.state';

@Injectable()
@State<[]>({
  name: 'expeditedReview',
  children: [MenuExpeditedReviewState, ActiveProjectExpeditedReviewState, ChosenDocumentInGroupState],
})
export class ExpeditedReviewState {}
