import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ResetDossier, SetDossier } from '@store/resolution-store/dossier/dossier.action';

import { DossierResponseModel } from '@oogShared/models/dossier/dossier-response.model';

@Injectable()
@State<DossierResponseModel | null>({
  name: 'dossier',
  defaults: null,
})
export class DossierState {
  @Selector()
  public static getDossier(state: DossierResponseModel): DossierResponseModel {
    return state;
  }

  @Action(SetDossier)
  public setDossier({ setState }: StateContext<DossierResponseModel>, { dossier }: SetDossier): void {
    setState(dossier);
  }

  @Action(ResetDossier)
  public resetDossier({ setState }: StateContext<DossierResponseModel>): void {
    setState(null);
  }
}
