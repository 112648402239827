/** идентификаторы категорий подписанта */

export enum SignerCategoryEnum {
  /**
   * Ответственный за подготовку проекта
   */
  projectManager = 'PROJECT_MANAGER',
  /**
   * Ответственный за правовое обеспечение подготовки проекта
   */
  legalExpertiseManager = 'LEGAL_EXPERTISE_MANAGER',
  /**
   * Начальник юридической службы
   */
  legalExpertiseHeader = 'LEGAL_EXPERTISE_HEAD',
  /**
   * Статс-секретарь
   */
  documentManager = 'DOCUMENT_MANAGER',
  /**
   * Руководитель
   */
  leader = 'LEADER',
  /**
   * Внешний согласующий
   */
  externalApprover = 'EXTERNAL_APPROVER',
  /**
   * Согласующий
   */
  approver = 'APPROVER',
}
