import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { RoutePoint } from '@npaShared/models/route/route.models';
import { RoutePhaseTypes } from '../../../document-agreement/enums/route-phase-types.enum';
import { RootActsLogic } from './root-acts-logic';

/** Логика работы с несколькими активными точками для категории "На исполнении" */
export class WorkingExecutorActsLogic extends RootActsLogic {
  private availableTaskStatuses = [...EqualActiveResolutions];

  public getActivePointsList(): RoutePoint[] {
    return this.getUserPoints()
      .filter((point) => this.availableTaskStatuses.includes(point.taskResolution))
      .filter((point) => this.executorOrCoexecutor(point) || this.authorOnProjectCreatedRework(point))
      .filter((point) => point.phaseTypeId !== RoutePhaseTypes.projectCreatedInitiation);
  }

  private executorOrCoexecutor(point: RoutePoint): boolean {
    return [EmployeeRouteTypes.executor, EmployeeRouteTypes.coexecutor].includes(point.pointRoleId);
  }

  private authorOnProjectCreatedRework(point: RoutePoint): boolean {
    return (
      point.pointRoleId === EmployeeRouteTypes.author && point.phaseTypeId === RoutePhaseTypes.projectCreatedRework
    );
  }
}
