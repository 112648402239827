import { Pipe, PipeTransform } from '@angular/core';

import { OogUserShortNamePipe } from '@shared/pipes/oog-user-short-name/oog-user-short-name.pipe';

import { ActingTypeEnum } from '../enums/main-screen/acting-type-names.enum';
import { ResolutionModel } from '../models/resolutions/resolution/resolution.model';

@Pipe({
  name: 'behalfOfDisplay',
})
export class BehalfOfDisplayPipe implements PipeTransform {
  constructor(private shortNamePipe: OogUserShortNamePipe) {}

  public transform(value: string, resolution: ResolutionModel): string {
    return this.getResolutionActingName(value, resolution);
  }

  private getResolutionActingName(value: string, resolution: ResolutionModel): string {
    switch (resolution.whoWorkedAsAuthorType) {
      case ActingTypeEnum.fromName:
        return `${value} / ${this.shortNamePipe.transform(resolution.whoWorkedAsAuthor)}`;
      case ActingTypeEnum.assistant:
        return `${value} / ${this.shortNamePipe.transform(resolution.whoWorkedAsAuthor)}`;
      case ActingTypeEnum.dischargeOfDuties:
        return resolution.whoWorkedAsAuthorTemplateSignature;
      default:
        return value;
    }
  }
}
