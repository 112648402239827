import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { ModalReturnConfirmationModel } from '@npaShared/models/modal/modal-return-confirmation.model';
import { modalIdConfirmation } from '@const/modals-id.const';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
})
export class ModalConfirmationComponent implements OnInit, OnDestroy {
  @Input() public data: ModalReturnConfirmationModel;

  public title = '';

  private readonly typeTitleMap = new Map<ApproveTypeEnum, string>([
    [ApproveTypeEnum.approve, 'Проект согласован'],
    [ApproveTypeEnum.approveLuz, 'Проект согласован с замечаниями'],
    [ApproveTypeEnum.return, 'Проект отправлен на доработку'],
    [ApproveTypeEnum.sendRemarksToAuthor, 'Замечания направлены без согласования'],
    [ApproveTypeEnum.sendToOauLeader, 'Проект направлен руководителю'],
  ]);

  private unsubscribe$ = new Subject();

  constructor(private modalController: ModalController) {}

  public ngOnInit(): void {
    this.title = this.typeTitleMap.get(this.data.approveType);

    timer(3000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.modalController.dismiss(undefined, undefined, modalIdConfirmation));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
