import { Pipe, PipeTransform } from '@angular/core';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';

/** вернет текст для модалки подтверждения действия */
@Pipe({
  name: 'textForConfirmAction',
})
export class TextForConfirmActionPipe implements PipeTransform {
  public transform(type: ApproveTypeEnum): string {
    switch (type) {
      case ApproveTypeEnum.approve:
        return 'Вы подтверждаете подписание согласования проекта';
      case ApproveTypeEnum.approveLuz:
        return 'Вы подтверждаете подписание согласования с замечаниями проекта';
      case ApproveTypeEnum.sendRemarksToAuthor:
        return 'Вы подтверждаете подписание направления замечания без согласования проекта';
      default:
        return '';
    }
  }
}
