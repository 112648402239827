import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@npaShared/shared.module';

import { HttpErrorInterceptorProvider } from './interceptors/http-error.interceptor';
import { SessionCookieInterceptorProvider } from './interceptors/session-cookie.interceptor';
import { StoreList } from './store';

const components = [];
@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule, SharedModule, NgxsModule.forFeature(StoreList)],
  providers: [HttpErrorInterceptorProvider, SessionCookieInterceptorProvider],
  exports: [...components],
  declarations: [...components],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export const throwIfAlreadyLoaded = (parentModule: any, moduleName: string): void => {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
};
