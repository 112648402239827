import { Pipe, PipeTransform } from '@angular/core';
import { menuItemsAisSd } from '@const/main-screen-categories-aissd.const';
import { MenuListNpaItemModel } from '@models/main-screen/menu-list-npa-item.model';
import { ProjectGroupTypeEnum } from '@npaShared/enums/project-group-type.enum';
import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';

@Pipe({
  name: 'sideMenuPageName',
})
export class SideMenuPageNamePipe implements PipeTransform {
  public transform(activeCategory: MenuListNpaItemModel, projectGroupTypeId: number): string {
    if (activeCategory?.type !== StatisticsEnum.mobileOfficeStatisticDraftActs) {
      return !!activeCategory.name ? activeCategory.name : StatisticsEnum.mobileOfficeStatisticDraftActs;
    }

    const realCategory = menuItemsAisSd.find((el) => el.type === StatisticsEnum.mobileOfficeStatisticDraftActs);
    const activeProjectType = realCategory?.units?.find((el) => el?.type === ProjectGroupTypeEnum[projectGroupTypeId]);

    return !!activeProjectType ? activeProjectType.name : activeCategory.name;
  }
}
