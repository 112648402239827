import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UrlsService } from '@shared/services/urls.service';

@Injectable()
export class HttpInterceptorHostChangeService implements HttpInterceptor {
  constructor(@Inject('BASE_API_URL') private baseUrl: string, private urlsService: UrlsService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.urlsService.getHost) {
      const newRequest =
        request.url.indexOf(this.baseUrl) !== -1
          ? request.url.replace(this.baseUrl, this.urlsService.getHost)
          : this.urlsService.getHost + '/' + request.url;

      if (!newRequest.match(/https?:.*/)) {
        throw new HttpErrorResponse({ status: 404 });
      }

      const apiReq = request.clone({ url: newRequest });
      return next.handle(apiReq);
    }
    return next.handle(request);
  }
}
