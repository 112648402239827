import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { modalIdNotificationNoDocument } from '@const/modals-id.const';

@Component({
  selector: 'app-notification-no-document',
  templateUrl: './notification-no-document.component.html',
  styleUrls: ['./notification-no-document.component.scss'],
})
/** Модалка уведомления, что документ из оповещения уже не существует */
export class NotificationNoDocumentComponent {
  @Input() public documentId = '';

  constructor(private modalCtrl: ModalController) {}

  public closeModal(): void {
    this.modalCtrl.dismiss(undefined, undefined, modalIdNotificationNoDocument).then();
  }
}
