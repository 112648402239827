import { EqualActiveResolutions } from '@npaShared/consts/equal-active-resolutions.const';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { isSomeActiveParallelPhase } from '@npaShared/helpers/route/phases-helper';
import { getSubPhasesBelongingToIterationOfPoint } from '@npaShared/helpers/route/subphase-helper';
import { PointLogicModel } from '../point-logic.interface';
import { RootPointLogic } from '../root-point-logic';
import { RoutePhaseTypes } from '../../../document-agreement/enums/route-phase-types.enum';

/** Логика точки согласования "Проект создан. Доработка" */
export class ProjectCreatedReworkPoint extends RootPointLogic implements PointLogicModel {
  public canStartRework(): boolean {
    const allowedRoles = [EmployeeRouteTypes.author];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    const haveActivePointOnParallelPhases = isSomeActiveParallelPhase(this.getRoute());
    if (haveActivePointOnParallelPhases) {
      return false;
    }

    const subPhases = getSubPhasesBelongingToIterationOfPoint(this.getRoute(), this.getPoint().id);
    const hasApprovement = subPhases.find((s) => s.phaseTypeId === RoutePhaseTypes.projectCreatedApprovement);
    /** если после Доработки есть Согласование, то не показывать кнопку */
    if (hasApprovement) {
      return false;
    }

    return true;
  }

  public canStartOuterApprovement(): boolean {
    const allowedRoles = [EmployeeRouteTypes.author];

    if (!this.isContainsPointResolution(EqualActiveResolutions)) {
      return false;
    }

    if (!this.isContainsPointRole(allowedRoles)) {
      return false;
    }

    const haveActivePointOnParallelPhases = isSomeActiveParallelPhase(this.getRoute());
    if (haveActivePointOnParallelPhases) {
      return false;
    }

    return true;
  }
}
