import { Injectable } from '@angular/core';
import { filter, first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { performerType } from '@oogShared/consts/performer-type.const';
import { reviewerType } from '@oogShared/consts/reviewer-type.const';
import { FormHeaderEnum } from '@oogShared/enums/resolution-form/form-header.enum';
import { returnAuthUserSession } from '@oogShared/functions/return-user-session.function';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { FormResolutionModel } from '@oogShared/models/form-pages/form-resolution.model';
import { FormHeaderModel } from '@oogShared/models/forms-input/form-header.model';
import { AppealCommissionForm } from '@oogShared/models/forms/commission-form.model';
import { AppealResolutionEditModel } from '@oogShared/models/forms/forms.model';
import { PerformerFormModel } from '@oogShared/models/forms/performer-form.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';

import { modalIdCreateResolution } from '@const/modals-id.const';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { DateHelperService } from '@shared/services/date-helper.service';
import { DictionariesAllUrgencyState } from '@store/dictionaries/dictionaries-all-urgency/dictionaries-all-urgency.state';
import { ResetForm } from '@store/forms/forms.action';
import { FormsState } from '@store/forms/forms.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetDossier } from '@store/resolution-store/dossier/dossier.action';
import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { AppealResolutionEnum } from '@oogShared/enums/resolution-form/appeal-resolution-type.enum';
import { DraftsInteropService } from '@oogShared/rest/drafts-interop.service';
import { ResolutionDraftStorageService } from '@oogShared/rest/resolution-draft-storage.service';
import { DocumentNavigationService } from '../document-navigation.service';
import { MapperBaseService } from './mapper-base.service';

@Injectable({
  providedIn: 'root',
})
export class FormCreateAppealResolutionService extends MapperBaseService {
  public dataForm!: FormResolutionModel;

  constructor(
    store: Store,
    resolutionInterop: ResolutionInteropService,
    modalCtrl: ModalController,
    draftsInteropService: DraftsInteropService,
    documentNavigationService: DocumentNavigationService,
    resolutionDraftStorageService: ResolutionDraftStorageService,
    private dateHelper: DateHelperService,
    private currentUser: CurrentUserIsproStoreService,
    private authUser: AuthUserIsproStoreService,
  ) {
    super(
      resolutionInterop,
      store,
      modalCtrl,
      draftsInteropService,
      documentNavigationService,
      resolutionDraftStorageService,
    );
  }

  /** Смапить данные в модель шапки формы */
  public mapFormHeader(data: FormResolutionModel): FormHeaderModel {
    return {
      title: FormHeaderEnum.create,
      numberCardDocument: data.numberResolution,
      date: data.resolutionDate,
    };
  }

  /** Смапить данные в модель формы */
  public mapDataToForm(): void {
    const date = this.store.selectSnapshot(ActiveCardState.dateResolution);
    const number = this.store.selectSnapshot(ActiveCardState.numberResolution);
    const user = this.currentUser.getCurrentUserSnapshot();

    this.dataForm = {
      author: user,
      onBehalfOf: user,
      resolutionDate: date,
      numberResolution: number,
    };
  }

  public createResolution(questionIds: number[]): void {
    const resolution = this.newResolution();
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);

    this.resolutionInterop
      .createAppealResolution(resolution, card, questionIds)
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() => {
        returnAuthUserSession(this.store, this.currentUser, this.authUser);
        this.documentNavigationService.nextDocument();
        this.store.dispatch([ResetForm, ResetResolution, ResetDossier]);
        this.modalCtrl.dismiss(undefined, undefined, modalIdCreateResolution);
      });
  }

  private newResolution(): CreateResolutionModel {
    const resolution = JSON.parse(JSON.stringify(this.store.selectSnapshot(ResolutionState.getResolutionProjectCard)));
    const form = this.store.selectSnapshot(FormsState.getFormEditAppealResolution);

    const newResolution = {
      author: form.author,
      fromEmployee: form.onBehalfOf,
      childResolutions: resolution.childResolutions,
      creationDate: new Date(),
      files: resolution.files,
      isDraft: resolution.isDraft,
      missions: [],
      number: resolution.number,
      options: {},
      parentResolutionId: resolution.id,
      type: {
        id: 2,
        name: 'Резолюция',
      },
    };

    newResolution.missions = this.commissionResolution(form);
    return newResolution;
  }

  private commissionResolution(form: AppealResolutionEditModel): MissionModel[] {
    const commissions = form.questions.reduce((acc, cur) => {
      const questions = [cur.question];
      const commissionsForQuestion = cur.commissions
        .map((c: AppealCommissionForm, i: number) => ({
          ...(c.isCommentConfidential && {
            isCommentConfidential: c.isCommentConfidential,
          }),
          curator: null,
          dossiers: [],
          privateComment: c.fileId ? '' : c.text,
          serialNumber: i + 1,
          forPrepareAnswer: c.type === AppealResolutionEnum.resolution ? c.forPrepareAnswer : true,
          missionAction: null,
          isWithoutExecutorOrAddressee: c.executorSign || null,
          addressee: c.performers.map((p: PerformerFormModel, j: number) => this.addresseeResolution(j, p)),
          questions,
          type: this.getCommissionType(c.type),
        }))
        .flat();
      return acc.concat(commissionsForQuestion);
    }, []);

    return this.addEmptyExecutorToAppealMissions(form, commissions);
  }

  private addresseeResolution(j: number, performer: PerformerFormModel): ResolutionAddresseeModel {
    const addresseeType = performer.inPlus ? reviewerType : performerType;
    const date = this.dateHelper.dateToSmallFormat(performer.date);
    const urgency = this.store.selectSnapshot(DictionariesAllUrgencyState.currentUrgency(performer.urgency?.id));
    return {
      isControl: performer.control || false,
      personalNote: performer.note,
      serialNumber: j + 1,
      isResponsibleByQuestion: performer.responsible,
      checkDateType: performer.inPlus ? null : urgency || null,
      urgencyReviewType: !performer.inPlus ? null : performer.urgency || null,
      addresseeType,
      dueDate: date ? new Date(date) : null,
      initialDueDate: null,
      decontrolDate: null,
      employee: performer.performer,
      forPrepareAnswer: null,
    };
  }
}
