import { EmployeeBaseModel } from '@npaShared/models/employee/employee-base.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';

type GetUserNameParam = Partial<Pick<EmployeeBaseModel, 'lastName' | 'firstName' | 'patronymic'>> &
  Partial<Pick<EmployeeModel, 'middleName'>>;

export const getUserName = (employee: GetUserNameParam): string => {
  const arr = [employee.lastName, employee.firstName, employee.patronymic ?? employee.middleName];

  return arr.filter(Boolean).join(' ');
};
