import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

import { ModalService } from '@shared/services/modal.service';
import { MAX_FILE_SIZE } from '@oogShared/consts/max-file-sizes.consts';
import { FilePoolModel } from '@oogShared/models/resolutions/file-pool.model';
import { GENERAL_TEXT_ERRORS } from '@const/global-text-errors.const';

/** Компонент, отвечающий за отображение файла в списке и взаимодействие с ним */
@Component({
  selector: 'app-resolution-file',
  templateUrl: './resolution-file.component.html',
  styleUrls: ['./resolution-file.component.scss'],
})
export class ResolutionFileComponent implements OnInit {
  @Input() public file!: FilePoolModel;
  @Input() public isChosen = false;
  @Input() public isCollapsed = true;
  @Input() public maxDownloadDocumentSize: null | number = MAX_FILE_SIZE; // null - нет ограничений на скачивание

  @Output() public showDocument: EventEmitter<FilePoolModel> = new EventEmitter<FilePoolModel>();
  @Output() public downloadDocumentId: EventEmitter<FilePoolModel> = new EventEmitter<FilePoolModel>();
  @Output() public expandFileList: EventEmitter<void> = new EventEmitter<void>();

  private fileSize: number | null = null;
  private downloadFileDisabled = false;

  constructor(private modalService: ModalService, private platform: Platform) {}

  public ngOnInit(): void {
    this.fileSize = this.getFileSize();
    this.downloadFileDisabled = this.isDisableDownloadFile();
  }

  public showFile(): void {
    this.showDocument.emit(this.file);
  }

  public downloadFile(): void {
    if (this.downloadFileDisabled) {
      this.showWarningModal();
      return;
    }
    this.downloadDocumentId.emit(this.file);
  }

  private getFileSize(): number | null {
    return (
      this.file.fileSize ||
      this.file.pages
        ?.map((page) => page.fileSize)
        ?.filter(Boolean)
        ?.reduce((acc, item) => acc + item, 0) ||
      null
    );
  }

  private isDisableDownloadFile(): boolean {
    return (
      !!this.maxDownloadDocumentSize &&
      ['ios', 'android'].some((item: Platforms) => this.platform.is(item)) &&
      !!this.fileSize &&
      this.fileSize > this.maxDownloadDocumentSize
    );
  }

  private showWarningModal(): void {
    this.modalService.presentTemplateModal({
      title: GENERAL_TEXT_ERRORS.systemError,
      text: 'Размер файла превышает 200мб, скачивание файла невозможно',
      buttonLabel: 'OK',
    });
  }
}
