import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateHelperConst } from '@const/date-helper.const';
import * as dayjs from 'dayjs';
import * as Pikaday from 'pikaday';
import { Subject, Subscription } from 'rxjs';
import { filter, map, skip, takeUntil, tap } from 'rxjs/operators';
import { DictionariesWeekendsState } from '@store/dictionaries/dictionaries-weekends/dictionaries-weekends.state';
import { Store } from '@ngxs/store';
import { DateHelperService } from '@shared/services/date-helper.service';
import { FilterService } from '@oogShared/services/filter.service';
import { DatePipe } from '@angular/common';
import { DateInputService } from '@shared/services/date-input.service';

@Component({
  selector: 'app-date-calendar-input',
  templateUrl: './date-calendar-input.component.html',
  styleUrls: ['./date-calendar-input.component.scss'],
})
export class DateCalendarInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public form = new FormGroup({});
  @Input() public controlName: FormControl;
  @Input() public placeholder = '';
  @Input() public controlsForReset = [];

  @Input() public disabled = false;
  @Input() public showIcon = true;
  @Input() public showMessage = true;
  @Input() public allowClearButton = false;
  @Input() public allowOldDates = true;
  // TODO: NPA-16522 отключение запросов ИСПРО
  @Input() public weekends: string[] = [];
  @Input() public isInvalid = false;
  @Input() public isChangeDeadline = false;

  @Output() public clearDate = new EventEmitter<void>();
  @Output() public countDaysChange = new EventEmitter<number>();

  @ViewChild('datepicker') public datepicker: ElementRef;
  @ViewChild('datepickerButton', { static: true }) public datepickerButton = new ElementRef({});
  @ViewChild('datepickerContainer', { static: true }) public datepickerContainer = new ElementRef({});

  @Input() public set changeMessage(value: boolean) {
    if (!value && !this.controlName.value) {
      this.message = '';
    }
  }

  public dateFormat: string = DateHelperConst.datePipeFormat;
  public minDate: string = DateHelperConst.minDate;

  public message = '';
  public date = '';

  private showingPikaday = false;
  private unsubscribe$ = new Subject<void>();
  private pikaday = new Pikaday({});
  private ruDate = {
    previousMonth: '',
    nextMonth: '',
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    weekdaysShort: ['В', 'П', 'В', 'С', 'Ч', 'П', 'С'],
  };

  private selectedDateFromPikaday = null;
  private subscription = new Subscription();
  private controlControl: FormControl | null = null;

  constructor(
    private store: Store,
    private dateHelper: DateHelperService,
    private filterService: FilterService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private dateService: DateInputService,
  ) {}

  public ngOnInit(): void {
    // TODO: NPA-16522 отключение запросов ИСПРО
    // this.weekends = this.store.selectSnapshot(DictionariesWeekendsState.weekends);
    this.getControlControl();
    this.subscribeToDateChange();
    this.subscribeToControlFlagChanges();
    this.checkRequiredField();
  }

  public ngAfterViewInit(): void {
    this.initPikaday();
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.pikaday.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription?.unsubscribe();
  }

  public togglePikaday(event: Event): void {
    event.stopPropagation();
    this.showingPikaday ? this.hidePikaday() : this.showPikaday();
  }

  /** Метод для отображения календаря */
  public showPikaday(): void {
    this.dateService.closePikaday.next(true);
    this.showingPikaday = true;
    this.pikaday.show();
    this.setCalendarPosition();
    this.pikaday.gotoDate(this.pikaday.toString() ? new Date(this.pikaday.toString()) : new Date());
    this.subscribeOnPikadayClose();
  }

  /** Метод для скрытия календаря */
  public hidePikaday(): void {
    this.showingPikaday = false;
    this.pikaday.hide();
    this.subscription?.unsubscribe();
  }

  public clearValue(): void {
    if (this.controlName.disabled) {
      return;
    }
    if (this.controlName.value) {
      this.controlName.setValue(null);
      this.date = '';
      this.setMessage(null);
    }
    this.resetControls();
    this.clearDate.emit();
  }

  /** Инициализация календаря */
  private initPikaday(): void {
    const onSelect = (value: Date): void => {
      const date = value.toISOString();
      this.resetControls();
      this.filterService.form?.controls?.createTasks?.setValue(null);
      this.date = date;
      this.selectedDateFromPikaday = date;
      this.controlName.setValue(date);
    };

    const onDraw = (container: any): void => {
      const pikaContainer = container.el;
      pikaContainer.appendChild(buttonCancel);
      recalculatePosition();
    };

    const onClose = (): void => {
      datepicker.blur();
      hide();
      resetDate();
    };

    const resetDate = (): void =>
      this.pikaday.gotoDate(this.pikaday.toString() ? new Date(this.pikaday.toString()) : new Date());

    const recalculatePosition = (): void => this.setCalendarPosition();

    const hide = (): void => this.hidePikaday();

    const validFormatWeekends = this.weekends.map((w) => new Date(w).toDateString());
    const buttonCancel = document.createElement('div');
    const datepicker = this.datepicker.nativeElement;

    this.pikaday = new Pikaday({
      disableWeekends: false,
      field: this.datepicker.nativeElement,
      trigger: this.controlName.disabled ? null : this.datepickerButton?.nativeElement,
      container: document.querySelector('ion-app'),
      minDate: dayjs(this.minDate, this.dateFormat).toDate(),
      i18n: this.ruDate,
      firstDay: 1,
      blurFieldOnSelect: false,
      numberOfMonths: 2,
      events: validFormatWeekends,
      onSelect,
      onDraw,
      onClose,
    });

    buttonCancel.className = 'button-cancel';
    const el = buttonCancel.appendChild(document.createElement('button'));
    el.type = 'button';
    el.appendChild(document.createTextNode('Отмена'));
    el.addEventListener('click', () => {
      this.hidePikaday();
    });
    el.addEventListener('touchstart', () => {
      this.hidePikaday();
    });
    this.pikaday.el.style.visibility = 'hidden';

    if (this.controlName.value) {
      this.date = this.controlName.value;
    }
  }

  /** Устанавливаем календарю абсолютную позицию и координаты */
  private setCalendarPosition(): void {
    this.pikaday.el.style.visibility = 'hidden';
    this.pikaday.el.style.position = 'absolute';
    const node = this.datepickerContainer.nativeElement as HTMLElement;
    const { top, left } = node.getBoundingClientRect();
    this.pikaday.el.style.top = top + 'px';
    this.pikaday.el.style.left = left + 'px';

    const pikadayRect = this.pikaday.el.getBoundingClientRect();

    if (pikadayRect.bottom > window.innerHeight) {
      this.pikaday.el.style.top = pikadayRect.top - (pikadayRect.bottom - window.innerHeight) - 10 + 'px';
    }
    if (pikadayRect.right > window.innerWidth) {
      this.pikaday.el.style.left = pikadayRect.left - (pikadayRect.right - window.innerWidth) - 10 + 'px';
    }

    this.pikaday.el.style.visibility = 'visible';
  }

  /** Подписка на изменение даты контрола */
  private subscribeToDateChange(): void {
    const control = this.controlName;

    control.valueChanges
      .pipe(
        tap((date) => {
          if (!date) {
            this.date = '';
            this.setMessage(date);
            this.pikaday.setDate(null);
          }
        }),
        filter((data) => !!data),
        map((data) => new Date(data).toISOString()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((date) => {
        this.date = date;
        this.pikaday.setDate(date, { emitEvent: false });

        if (control?.value !== date) {
          this.controlName.setValue(date);
          this.checkRequiredField();
          return;
        }

        /** изменение countDays нужно только при изменении даты из календаря */
        if (this.selectedDateFromPikaday === date) {
          this.countDaysChange.emit(0);
        }

        control.markAsPristine();
        this.setMessage(date);
      });
  }

  private resetControls(): void {
    this.controlsForReset.forEach((control) => {
      if (this.form.controls[control]) {
        this.form.controls[control].reset();
      }
    });
  }

  /** Устанавливает сообщение под календарь
   *
   * @param date дата в формате dd.mm.yyyy
   * */
  private setMessage(date: string): void {
    if (!date && !this.controlName.errors) {
      this.message = '';
    }

    const weekends = this.store.selectSnapshot(DictionariesWeekendsState.weekends);
    const selectedDate = this.datePipe.transform(date, DateHelperConst.datePipeUrgencyFormat);
    const today = this.dateHelper.today();
    const weekend = weekends.find((d) => d === selectedDate);

    this.message = weekend ? 'Выбран выходной день' : '';

    if (selectedDate < today && !this.allowOldDates) {
      this.message = 'Дата не должна быть меньше текущей';
      return;
    }

    if (!this.message && weekend) {
      this.message = 'Выбран выходной день';
    }

    this.checkRequiredField();
  }

  private checkRequiredField(): void {
    const isControlInvalid = !!this.controlControl?.value && !this.controlName?.value;
    this.isInvalid = this.isInvalid || isControlInvalid;
    if (isControlInvalid) {
      this.message = 'Поле обязательно для заполнения';
    }
  }

  private subscribeToControlFlagChanges(): void {
    if (!!this.controlControl) {
      this.controlControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.checkRequiredField());
    }
  }

  private subscribeOnPikadayClose(): void {
    this.subscription = this.dateService
      .returnClosePikadayFlag()
      .pipe(
        skip(1),
        filter((res) => res),
      )
      .subscribe(() => this.hidePikaday());
  }

  private getControlControl(): void {
    if (!this.form) {
      return;
    }
    const control = this.form.get('control') || this.form.get('actionControl');
    if (!control) {
      return;
    }
    this.controlControl = control as FormControl;
  }
}
