/** Обновить количество документов в реестре */
export class SetCountDocuments {
  public static readonly type = '[Count Documents Menu] Set Count Documents Menu';
  constructor(public count: number) {}
}

/** Отнять документ из списка */
export class RemoveDocumentFromList {
  public static readonly type = '[Count Documents Menu] Remove Document From List';
}

/** Удалить несколько документов из списка */
export class RemoveDocumentsFromList {
  public static readonly type = '[Count Documents Menu] Remove Documents From List';
  constructor(public quantity: number) {}
}

/** Сброс количества доков в реестре */
export class ResetDocumentList {
  public static readonly type = '[Count Documents Menu] Reset Document List';
}
