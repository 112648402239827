import { ApprovalModalRedirectComponent } from '../../documents-page/approval-list/components/approval-modal-redirect/approval-modal-redirect.component';
import { ApprovalModalReturnComponent } from '../../documents-page/approval-list/components/approval-modal-return/approval-modal-return.component';
import { ApprovalModalSignComponent } from '../../documents-page/approval-list/components/approval-modal-sign/approval-modal-sign.component';
import { ApprovalModalWithComponent } from '../../documents-page/approval-list/components/approval-modal-with/approval-modal-with.component';
import { ApprovalActionBtnTypeEnum } from '../enums/approval-list/approval-action-btn-type.enum';

export const ApprovalModalType = {
  [ApprovalActionBtnTypeEnum.approveRedirect]: ApprovalModalRedirectComponent,
  [ApprovalActionBtnTypeEnum.approveWithComment]: ApprovalModalWithComponent,
  [ApprovalActionBtnTypeEnum.approveReturn]: ApprovalModalReturnComponent,
  [ApprovalActionBtnTypeEnum.approveSign]: ApprovalModalSignComponent
};
