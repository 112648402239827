import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { ResetResolverDocuments, SetResolverDocuments } from './public-resolver-documents.action';

@Injectable()
@State<DocumentInfoResponseModel[]>({
  name: 'publicResolverDocuments',
  defaults: null,
})
export class PublicResolverDocumentsState {
  @Selector()
  public static getResolverDocuments(state: DocumentInfoResponseModel[]): DocumentInfoResponseModel[] {
    return state;
  }

  @Action(SetResolverDocuments)
  public SetResolverDocuments(
    { setState }: StateContext<DocumentInfoResponseModel[]>,
    { docs }: SetResolverDocuments,
  ): void {
    setState(docs);
  }

  @Action(ResetResolverDocuments)
  public ResetResolverDocuments({ setState }: StateContext<DocumentInfoResponseModel[]>): void {
    setState(null);
  }
}
