import { Component, Input } from '@angular/core';

import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { ModalController } from '@ionic/angular';

import { DischargeOfDutiesModalEnum } from '../../../shared/enums/discharge-of-duties-modal.enum';
import { DischargeOfDutiesModel } from '../../../shared/models/discharge-of-duties/discharge-of-duties.model';
import { EmployeeModel } from '../../../shared/models/resolutions/employee/employee.model';

@Component({
  selector: 'app-modal-discharge-of-duties',
  templateUrl: './modal-discharge-of-duties.component.html',
  styleUrls: ['./modal-discharge-of-duties.component.scss'],
})
export class ModalDischargeOfDutiesComponent {
  @Input() public listDuties: DischargeOfDutiesModel[] = [];

  public buttonTheme = ButtonThemeEnum;
  public dischargeOfDuties = DischargeOfDutiesModalEnum;

  constructor(private modalCtrl: ModalController) {}

  public close(status: string, performer?: EmployeeModel): void {
    this.modalCtrl.dismiss({ status, performer: performer || null }).then();
  }
}
