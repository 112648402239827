import { Injectable } from '@angular/core';

import { NotificationDataModel } from '@models/notifications/notifications-data.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { AddNotification, SetNotifications } from './notifications.action';

@Injectable()
@State<NotificationDataModel[] | null>({
  name: 'notifications',
  defaults: [],
})
export class NotificationsState {
  @Selector()
  public static getNotifications(state: NotificationDataModel[]): NotificationDataModel[] {
    return state;
  }

  @Action(AddNotification)
  public addNotification(
    { setState, getState }: StateContext<NotificationDataModel[]>,
    { notification }: AddNotification,
  ): void {
    const isNotificationExist = getState().some((n) => notification.id === n.id);
    if (!isNotificationExist) {
      setState([notification, ...getState()]);
    }
  }

  @Action(SetNotifications)
  public setNotifications(
    { setState }: StateContext<NotificationDataModel[]>,
    { notifications }: SetNotifications,
  ): void {
    setState(notifications);
  }
}
