import { Component } from '@angular/core';
import { modalIdDeleteBookmark, modalIdCreateBookmark, modalIdEditBookmark } from '@const/modals-id.const';
import { ModalController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { BookmarksModel } from '@oogShared/models/bookmarks/bookmarks.model';
import { BookmarksState } from '@store/resolution-store/bookmarks/bookmarks.state';
import { Observable } from 'rxjs';
import { BookmarkCreateComponent } from '../bookmark-create/bookmark-create.component';
import { BookmarkDeleteComponent } from '../bookmark-delete/bookmark-delete.component';

@Component({
  selector: 'app-bookmark-selection',
  templateUrl: './bookmark-selection.component.html',
  styleUrls: ['./bookmark-selection.component.scss'],
})
/** Компонент с модальным окном для выбора закладки */
export class BookmarkSelectionComponent {
  @Select(BookmarksState.getBookmarks)
  public bookmarks$: Observable<BookmarksModel[]>;

  constructor(private modalCtrl: ModalController) {}

  public cancel(): void {
    this.modalCtrl.dismiss({
      confirm: false,
    });
  }

  public chooseBookmark(bookmark: BookmarksModel): void {
    this.modalCtrl.dismiss({
      confirm: true,
      pageId: bookmark.pageId,
    });
  }

  public addBookmark(): void {
    this.createModalForCreateBookmark();
  }

  public editBookmark(bookmark: BookmarksModel): void {
    this.createModalForEditBookmark(bookmark);
  }

  public deleteBookmark(bookmark: BookmarksModel): void {
    this.createModalForDeleteBookmark(bookmark);
  }

  private async createModalForDeleteBookmark(bookmark: BookmarksModel): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: BookmarkDeleteComponent,
      cssClass: 'full-screen-with-background',
      componentProps: { bookmark },
      id: modalIdDeleteBookmark,
    });
    modal.present();
  }

  private async createModalForEditBookmark(bookmark: BookmarksModel): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: BookmarkCreateComponent,
      cssClass: 'full-screen-with-background',
      componentProps: { bookmark, isEdit: true },
      id: modalIdEditBookmark,
    });
    modal.present();
  }

  private async createModalForCreateBookmark(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: BookmarkCreateComponent,
      cssClass: 'full-screen-with-background',
      componentProps: { isEdit: false },
      id: modalIdCreateBookmark,
    });
    modal.present();
  }
}
