import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Urls } from '@const/urls';
import { CompositionDownloadDocumentRequestModel } from '@npaShared/models/document-composition/composition-download-document-request.model';
import { CompositionDownloadDocumentResponseModel } from '@npaShared/models/document-composition/composition-download-document-response.model';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { DocumentPrintRequestModel } from '@npaShared/models/document/document-print.request.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {
  constructor(private http: HttpClient) {}

  public searchByDocumentPackageId(documentPackageId: number): Observable<DocumentInfoResponseModel[]> {
    const url = Urls.npaDocumentEndpoints.searchByDocumentPackageId;

    return this.http.get<DocumentInfoResponseModel[]>(url, { params: { documentPackageId: `${documentPackageId}` } });
  }

  /**
   * Получить список id документов на подпись
   *
   * @param documentPackageId id пакета документов
   * @param pointId           id выбранной точки пользователя
   */
  public getSignableByDocumentPackageAndActivePointId(
    documentPackageId: number,
    pointId: number,
  ): Observable<number[]> {
    const url = Urls.npaDocumentEndpoints.signableDocuments;

    return this.http.get<number[]>(url, {
      params: {
        documentPackageId: `${documentPackageId}`,
        pointId: `${pointId}`,
      },
    });
  }

  public exportRequest(data: DocumentPrintRequestModel): Observable<CompositionDownloadDocumentResponseModel> {
    const url = Urls.npaDocumentEndpoints.exportRequest;

    return this.http.post<CompositionDownloadDocumentResponseModel>(url, data);
  }

  /** Отправка запроса на создание документа редактором
   *
   * ( потом необходимо подписаться по вебсокету на канал экспорта документов
   * /user/ldeExportEvents и в нем получить ссылку на скачивание файла )
   */
  public postDownloadDocumentRequest(
    options: CompositionDownloadDocumentRequestModel,
  ): Observable<CompositionDownloadDocumentResponseModel> {
    const url = Urls.npaDocumentEndpoints.postDownloadDocumentRequest;
    let params: HttpParams = new HttpParams();
    for (const key in options) {
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        params = params.append(key, options[key]);
      }
    }

    return this.http.post<CompositionDownloadDocumentResponseModel>(url, {}, { params });
  }

  /** Загрузка файла */
  public loadFile(url: string): Observable<Blob> {
    const link = this.getLink(url);
    return this.http.get(link, { responseType: 'blob' });
  }

  /** Получение ссылки для скачивания файла */
  public getLink(url: string): string {
    // FIXME: здесь вне зависимости от хоста в ссылке хост будет заменятся на тот, который живет в environment
    // могут возникнуть трудности и баги при реализации переключения хостов NPA-12175
    return url.replace(/(http|https):\/\/.*?\//, environment.host + '/');
  }
}
