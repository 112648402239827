import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { LoadStatusEnum } from '@enums/load-status.enum';

import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';

@Component({
  selector: 'app-horizontal-document-navigation',
  templateUrl: './horizontal-document-navigation.component.html',
  styleUrls: ['./horizontal-document-navigation.component.scss'],
})
export class HorizontalDocumentNavigationComponent {
  @Input() public currentDoc: DocumentInfoResponseModel;
  @Input() public prevDoc: DocumentInfoResponseModel;
  @Input() public nextDoc: DocumentInfoResponseModel;
  /** статус загрузки документа */
  @Input() public status: LoadStatusEnum;
  @HostBinding('class.disabled')
  @Input()
  public disabled = false;

  @Output() public changeDocument = new EventEmitter<DocumentInfoResponseModel>();

  public changeDoc(newDocument: DocumentInfoResponseModel): void {
    if (this.status === LoadStatusEnum.loading) {
      return;
    }

    this.changeDocument.emit(newDocument);
  }
}
