import { Pipe, PipeTransform } from '@angular/core';

import { EmployeeBaseModel } from '../models/employee/employee-base.model';

@Pipe({
  name: 'employeeName',
})
export class EmployeeNamePipe implements PipeTransform {
  public transform(employee: EmployeeBaseModel): string {
    if (!employee) {
      return '';
    }

    return `${employee.lastName || ''} ${employee.firstName || ''} ${employee.patronymic || ''}`;
  }
}
