import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { SelectTypeModel } from '@models/dictionaries/select-type.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InvalidRedirectModalComponent } from '@oogShared/components/invalid-redirect-modal/invalid-redirect-modal.component';
import { DictionariesTemplateState } from '@store/dictionaries/dictionaries-templates/dictionaries-template.state';
import { FormsState } from '@store/forms/forms.state';
import { RightsForRedirectionExpeditedReviewState } from '@store/right-for-redirection-expedited-review/rights-for-redirection-expedited-review.state';
import { Observable } from 'rxjs';
import { filter, finalize, first, map } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { BanRedirectMayorComponent } from '@oogShared/components/confirm/ban-redirect-mayor/ban-redirect-mayor.component';
import { ConfirmMayorWithCommentComponent } from '@oogShared/components/confirm/confirm-mayor-with-comment/confirm-mayor-with-comment.component';
import { ConfirmRedirectProjectComponent } from '@oogShared/components/confirm/confirm-redirect-project/confirm-redirect-project.component';
import { changeUserSession } from '@oogShared/functions/change-user-session.function';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { ResolutionInteropService } from '@oogShared/rest/resolution-interop.service';
import { ConfirmDialogService } from '@oogShared/services/confirm-dialog.service';
import { DischargeDutiesProjectService } from '@oogShared/services/discharge-of-duties/discharge-duties-project.service';
import { DischargeDutiesService } from '@oogShared/services/discharge-of-duties/discharge-duties.service';
import { FormRedirectResolutionService } from '@oogShared/services/forms/form-redirect-resolution.service';
import { NotificationRedirectProjectService } from '@oogShared/services/notification-redirect-project/notification-redirect-project.service';
import { EsignOogService } from '@oogShared/services/esign-oog.service';
import { CreateResolutionModel } from '@oogShared/models/create-resolution/create-resolution.model';
import { RestoreResolutionFileService } from '@oogShared/services/forms/restore-resolution-file.service';
import { FormModalService } from '@oogShared/services/forms/form-modal.service';
import { SelectUserTypesEnum } from '@oogShared/enums/select-user-types.enum';
import { defaultValueUrgency } from '@oogShared/consts/default-value-urgency.const';
import { ModalService } from '@shared/services/modal.service';
import { AudioFormService } from '../audio-form/services/audio-form.service';
import { GraphicFormService } from '../graphic-form/services/graphic-form.service';
import { GraphicHelperService } from '../graphic-form/services/graphic-helper.service';
import { RedirectFormBase } from '../redirect-form-base';

/** Страница для перенаправления проекта/резолюции */
@UntilDestroy()
@Component({
  selector: 'app-document-redirect',
  templateUrl: './document-redirect.page.html',
  styleUrls: ['./document-redirect.page.scss'],
})
export class DocumentRedirectPage extends RedirectFormBase implements OnInit, AfterViewInit {
  @Input() public draft: CreateResolutionModel | null = null;

  @Select(DictionariesTemplateState.templatesForProjectRedirect)
  public templates$!: Observable<SelectTypeModel[]>;

  @Select(FormsState.cloneReviewersRedirectProject)
  public reviewerClones$!: Observable<boolean>;

  public disabledByWhiteList = false;
  public selectUserTypes = SelectUserTypesEnum;

  constructor(
    private fb: FormBuilder,
    private dischargeDuties: DischargeDutiesProjectService,
    private notificationRedirect: NotificationRedirectProjectService,
    formRedirect: FormRedirectResolutionService,
    dialog: ConfirmDialogService,
    dictionariesInterop: DictionariesInteropService,
    modalCtrl: ModalController,
    store: Store,
    currentUser: CurrentUserIsproStoreService,
    dutiesService: DischargeDutiesService,
    private graphicHelperService: GraphicHelperService,
    private graphicFormService: GraphicFormService,
    private audioFormService: AudioFormService,
    private esignService: EsignOogService,
    private cardService: ResolutionInteropService,
    private restoreFileService: RestoreResolutionFileService,
    private formModalService: FormModalService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, currentUser, formRedirect, dialog, dictionariesInterop, dutiesService, modalCtrl);
  }

  public ngOnInit(): void {
    changeUserSession(this.store, this.currentUser);
    this.userInfo();
    this.headerData = this.formRedirect.mapRedirectFormHeader();
    this.initForm();
    this.subscribeToRedirectPicture();
  }

  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public submitForm(): void {
    if (this.someCommissionInRequestHasSameDep()) {
      this.modalService.presentModalError('Сотрудник должен быть из другого департамента');
      return;
    }

    if (this.audioFormService.getAudioFromStore()) {
      this.audioFormService
        .sendFile()
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.audioFormService.saveFileData(res, this.form);
          setTimeout(() => {
            this.formRedirect.redirectResolution();
          }, 100);
        });
      return;
    }

    if (this.esignService.isInvalidCertificateSigningResolution()) {
      this.esignService.showModalInvalidCertificate();
      return;
    }

    if (this.form.controls?.picture?.value) {
      this.formRedirect.redirectResolution();
      return;
    }
    if (this.notificationRedirect.compareAuthorWithAddressee(this.form, this.user)) {
      this.showDialogWithAuthor();
      return;
    }
    if (this.notificationRedirect.compareMayorAuthorResolution(this.form.controls.addressee?.value)) {
      this.showDialogBanMayor(this.form.controls.addressee?.value);
      return;
    }
    if (this.notificationRedirect.compareRedirectMayor(this.form, this.form.controls.addressee?.value)) {
      this.showDialogMayor(this.form.controls.addressee?.value);
      return;
    }
    this.addresseeInCommissions() ? this.formRedirect.redirectResolution() : this.emptyPerformer();
  }

  /** Вставить выбранного пользователя в форму */
  public chooseAddressee(addressee: EmployeeModel): void {
    this.showChooseAddressee = false;
    this.form.controls.addressee.setValue(addressee);
    this.dischargeDuties.dischargeOfDuties(this.form, addressee.id);
    this.disabledByWhiteList = addressee.disabledByWhiteList;
  }

  /** Нажатие на кнопку "Вернуть автору" - в форму подставляется автор резолюции */
  public returnAuthor(): void {
    const card = this.store.selectSnapshot(ActiveCardState);
    this.cardService
      .getResolution(card.mainId)
      .pipe(
        map((res) => res.author),
        untilDestroyed(this),
      )
      .subscribe((author) => {
        this.form.controls.addressee.setValue(author);
        this.disabledByWhiteList = author.disabledByWhiteList;
        this.showChooseAddressee = false;
      });
  }

  /** Изменение флага Ускоренное рассмотрение */
  public changeSignExpeditedReview(): void {
    const projectWithAddressee = this.store.selectSnapshot(FormsState.isProjectWithAddressee);
    if (!projectWithAddressee) {
      this.form.controls.expeditedReview.setValue(false);
      return;
    }

    const singleProject = this.store.selectSnapshot(ResolutionState.isOneProjectForRedirect);
    const oneAuthorAndAddressee = this.store.selectSnapshot(ResolutionState.compareAuthorWithAddressee);
    const rights = this.store.selectSnapshot(RightsForRedirectionExpeditedReviewState.getRightsForRedirection);

    if (!singleProject || !oneAuthorAndAddressee || !rights) {
      this.form.controls.expeditedReview.setValue(false);
      this.modalCtrl
        .create({
          componentProps: {
            rightsValid: rights,
            singleProjectValid: singleProject,
            authorAndAddresseeValid: oneAuthorAndAddressee,
            isRequest: this.store.selectSnapshot(ResolutionState.isResolutionRequestProject),
          },
          component: InvalidRedirectModalComponent,
          cssClass: 'full-screen-with-background',
        })
        .then((modal) => modal.present());
    }
  }

  /** Создать графическую форму */
  public createGraphicForm(edit: boolean): void {
    this.graphicHelperService.createGraphicForm(edit, this.headerData, this.form);
  }

  /** Удалить рисунок с формы */
  public async deletePicture(): Promise<void> {
    this.graphicHelperService.deletePicture(this.form);
  }

  /** Сохранить текст поручения в отдельный контрол формы, чтобы загрузить его когда удалили изображение */
  public saveCommissionText(value: string): void {
    this.graphicHelperService.saveCommissionText(value, this.form);
  }

  public getReviewersFormArray(): FormArray {
    return this.form.get('reviewers') as FormArray;
  }

  public chooseReviewer(employee: EmployeeModel): void {
    const reviewers = this.getReviewersFormArray();
    const reviewerFb = this.fb.group({
      performer: [employee, Validators.required],
      inPlus: [true],
      urgency: [defaultValueUrgency],
    });

    reviewers.push(reviewerFb);

    const employees = reviewers.value.map((p) => p.performer).filter((p) => p?.id === employee?.id);
    this.setCloneControl(reviewers, employees);

    this.dutiesService.dischargeOfDuties(reviewerFb, employee?.id, reviewers, reviewers.length - 1, 'performer');
  }

  /** Инициализация формы */
  private initForm(): void {
    const mission = this.draft?.missions[0];
    const addressee = mission ? [...mission.addressee] : [];

    if (mission?.isWithoutExecutorOrAddressee) {
      // удалить последнего пользователя,
      // который был добавлен перед отправкой
      addressee?.pop();
    }

    const urgency = this.store.selectSnapshot(ResolutionState.addresseeResolution).urgencyReviewType;
    const isResolutionRequestProject = this.store.selectSnapshot(ResolutionState.isResolutionRequestProject);
    const hasProjectLetter = this.store.selectSnapshot(ResolutionState.getResolutionHasProjectLetter);
    const projectLetterAuthor = this.store.selectSnapshot(ResolutionState.getResolutionProjectLetterAuthor);

    this.form = this.fb.group({
      addressee: [mission?.addressee[0]?.employee || ''],
      urgency: [mission?.addressee[0]?.urgencyReviewType || urgency],
      needAttention: [mission?.needAttention || false],
      expeditedReview: [this.draft?.isOnAcceleratedReview || false],
      text: [mission?.privateComment || ''],
      picture: [''],
      savedText: [''],
      fileId: [this.draft?.fileResolutionId],
      resolutionKind: [this.draft?.resolutionKind],
      privateComment: [mission?.isCommentConfidential || false],
      ...(!isResolutionRequestProject && {
        hasProjectLetter: [mission?.hasProjectLetter || hasProjectLetter || false],
        projectLetterAuthor: [mission?.projectLetterAuthor || projectLetterAuthor || null],
      }),
      reviewers: this.fb.array([]),
    });

    if (this.draft?.fileResolutionId) {
      this.addFileToFormFromDraft();
    }
  }

  /** Есть ли исполнители в поручении */
  private addresseeInCommissions(): boolean {
    return !!this.form.controls.addressee.value;
  }

  private showDialogWithAuthor(): void {
    this.dialog
      .show(ConfirmRedirectProjectComponent, 'app-document-redirect')
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() =>
        this.addresseeInCommissions() ? this.formRedirect.redirectResolution() : this.emptyPerformer(),
      );
  }

  private showDialogMayor(employee: EmployeeModel): void {
    this.dialog
      .show(ConfirmMayorWithCommentComponent, 'app-document-redirect', { mayor: employee })
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() =>
        this.addresseeInCommissions() ? this.formRedirect.redirectResolution() : this.emptyPerformer(),
      );
  }

  private showDialogBanMayor(employee: EmployeeModel): void {
    this.dialog
      .show(BanRedirectMayorComponent, 'app-document-redirect', { mayor: employee })
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() => (this.showChooseAddressee = true));
  }

  private subscribeToRedirectPicture(): void {
    this.graphicFormService.submitForm$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.formRedirect.redirectResolution());
  }

  private addFileToFormFromDraft(): void {
    const card = this.store.selectSnapshot(ActiveCardState);

    this.formModalService.showLoading();

    this.cardService
      .showFile(this.draft?.fileResolutionId, card.appealMovementId)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.formModalService.dismissLoading()),
      )
      .subscribe((file) => this.restoreFileService.restoreFile(file, this.form));
  }

  private someCommissionInRequestHasSameDep(): boolean {
    if (!this.store.selectSnapshot(ResolutionState.isResolutionRequestProject)) {
      return false;
    }

    const currentUser = this.currentUser.getCurrentUserSnapshot();
    const missionAddressees = this.store.selectSnapshot(ResolutionState.getResolutionProjectChildResolutionsAddressee);

    return missionAddressees.some((addressee) => addressee.employee?.department?.id === currentUser.department?.id);
  }
}
