import { Component, Input } from '@angular/core';

import { BaseControl } from '@components/controls/control-base';
import { SortEnum } from '@enums/sort.enum';

@Component({
  selector: 'app-filter-sort-buttons',
  templateUrl: './filter-sort-buttons.component.html',
  styleUrls: ['./filter-sort-buttons.component.scss'],
})
export class FilterSortButtonsComponent extends BaseControl {
  @Input() public currentItem = SortEnum.desc;
  public sortButtonsEnum = SortEnum;

  public selectionOptionSort(item: SortEnum): void {
    this.currentItem = item;
  }
}
