import { DocumentGroupsEnum } from '@npaShared/enums/document-group.enum';
import { DocumentProhibitionStatus } from '@npaShared/enums/document-prohibition-status.enum';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';

/** есть ли хотя бы один доступный документ из основного раздела «Проект, приложения и пояснительная записка» */
export const hasAnyDocumentInMainDocumentGroup = (documents: DocumentInfoResponseModel[]): boolean => {
  const documentsInMainDocumentGroup = documents
    .filter((doc) => doc.documentGroup === DocumentGroupsEnum.main)
    .filter((doc) => doc.prohibitionStatus !== DocumentProhibitionStatus.CLOSED);
  return Boolean(documentsInMainDocumentGroup.length);
};
