import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/** Сервис для прокрутки скролла к активному элементу реестра */
@Injectable({
  providedIn: 'root',
})
export class SidebarScrollService {
  private indexToScroll$ = new BehaviorSubject<number>(0);

  public onScroll$(): Observable<number> {
    return this.indexToScroll$.asObservable();
  }

  public setElementToScroll(index: number): void {
    this.indexToScroll$.next(index);
  }

  public resetElement(): void {
    this.indexToScroll$.next(0);
  }
}
